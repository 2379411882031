import { faCheckCircle, faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, Switch } from 'antd';
import React, { useState } from 'react'
import Headers from '../../../Screens/Headers/Headers';
import { NxTitleTab } from '@afiplfeed/itr-app';
import { useHistory } from "react-router-dom";

export default function Federation() {
    let history = useHistory()
    const [step, setStep] = useState(1);
    const [number, setNumber] = useState('');
    const [otp, setOtp] = useState('');
    const [loader, setLoader] = useState(false);
    const [enableFederation, setEnableFederation] = useState(false);

    const [federationValues, setFederationValues] = useState({
        IDProvide: "",
        name: "",
        clientID: "",
        clientSecret: "",
        customIDProvide: "",
        customName: "",
        providerProtocol: "",
        domainName: "",
        issuerUri: "",
        authenticationEndpoint: "",
        certificate: "",
        metaData: ""
    });

    const changeName = (name) => {
        if (name == "Azure AAD") return "Azure";
        else if (name == "Google IDP") return "Google";
        else if (name == "Amazon IAM") return "Amazon";
        else if (name == "Custom IDP") return "Custom"
        return ""
    }

    return (
        <div>
            <Headers />
            <NxTitleTab className="title-tab" title="Federation" onCloseClick={() => history.goBack()} />
            <div className="card password-policy-main mt-0">
                <div className="card-body p-0">
                    <div className="twoFactorModal" style={{ height: "100%" }}>
                        <div className="header security-two-factor text-start">
                            <div>
                                <h4 className='m-0 p-0'>
                                    Enable Federation Services
                                </h4>
                                <p>Once you enable federation services you will not be able to use the default IAM provided with Adap.</p>
                            </div>
                            <div>
                                <Switch checked={enableFederation} onChange={(e) => { setEnableFederation(e) }} />
                            </div>
                        </div>
                        {enableFederation && <div className='row'>
                            <div className='col-12 col-md-8 col-sm-12 col-lg-6 xol-xl-6'>
                                <div className='security-factor-authentication-forms'>
                                    <div className='mt-2 form-label'>
                                        <label>Select ID provide</label>
                                        <select className='form-select form-select-sm'
                                            value={federationValues.IDProvide}
                                            onChange={(e) => {
                                                setFederationValues({
                                                    ...federationValues, IDProvide: e.target.value,
                                                    name: e.target.value
                                                });
                                                changeName(e.target.value)
                                                console.log({
                                                    ...federationValues, IDProvide: e.target.value,
                                                    name: e.target.value
                                                })
                                            }}
                                        >
                                            <option value="Azure AAD">Azure AAD</option>
                                            <option value="Google IDP">Google IDP</option>
                                            <option value="Amazon IAM">Amazon IAM</option>
                                            <option value="Custom IDP">Custom IDP</option>
                                        </select>
                                    </div>
                                    {/* Not-custom-IDP */}
                                    {federationValues.name != "Custom IDP" && <div>
                                        <div className='mt-2 form-label'>
                                            <label>Name</label>
                                            <input className='form-control form-control-sm' readOnly value={federationValues.name == "Azure AAD" ? "Azure" : federationValues.name == "Google IDP" ? "Google" : federationValues.name == "Amazon IAM" ? "Amazon" : federationValues.name == "Custom IDP" ? "Custom" : ""} />
                                        </div>
                                        <div className='mt-2 form-label'>
                                            <label>Client ID</label>
                                            <input className='form-control form-control-sm' value={federationValues.clientID}
                                                onChange={(e) => {
                                                    setFederationValues({
                                                        ...federationValues, clientID: e.target.value,
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className='mt-2 form-label'>
                                            <label>Client Secret</label>
                                            <input className='form-control form-control-sm' value={federationValues.clientSecret}
                                                onChange={(e) => {
                                                    setFederationValues({
                                                        ...federationValues, clientSecret: e.target.value,
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>}

                                    {/* custom-IDP */}
                                    {federationValues.name == "Custom IDP" && < div >
                                        <div className='mt-2 form-label'>
                                            <label>Name</label>
                                            <input className='form-control form-control-sm'
                                                value={federationValues.customName}
                                                onChange={(e) => {
                                                    setFederationValues({
                                                        ...federationValues, customName: e.target.value,
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className='mt-2 form-label'>
                                            <label>Identity provider protocol</label>
                                            <select className='form-select form-select-sm'
                                                value={federationValues.providerProtocol}
                                                onChange={(e) => {
                                                    setFederationValues({
                                                        ...federationValues, providerProtocol: e.target.value,
                                                    })
                                                }}
                                            >
                                                <option value="LDAP">LDAP</option>
                                                <option value="SAML">SAML</option>
                                                <option value="SCIM">SCIM</option>
                                                <option value="OAuth">OAuth</option>
                                                <option value="OpenID">OpenID</option>
                                                <option value="XACML">XACML</option>
                                            </select>
                                        </div>
                                        <div className='mt-2 form-label'>
                                            <label>Domain Name of Federation IDP</label>
                                            <input className='form-control form-control-sm' value={federationValues.domainName}
                                                onChange={(e) => {
                                                    setFederationValues({
                                                        ...federationValues, domainName: e.target.value,
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className='mt-2 form-label'>
                                            <label>Issuer URI</label>
                                            <input className='form-control form-control-sm' value={federationValues.issuerUri}
                                                onChange={(e) => {
                                                    setFederationValues({
                                                        ...federationValues, issuerUri: e.target.value,
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className='mt-2 form-label'>
                                            <label>Passive authentication endppoint</label>
                                            <input className='form-control form-control-sm' value={federationValues.authenticationEndpoint}
                                                onChange={(e) => {
                                                    setFederationValues({
                                                        ...federationValues, authenticationEndpoint: e.target.value,
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className='mt-2 form-label'>
                                            <label>Certificate</label>
                                            <input className='form-control form-control-sm' value={federationValues.certificate}
                                                onChange={(e) => {
                                                    setFederationValues({
                                                        ...federationValues, certificate: e.target.value,
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className='mt-2 form-label'>
                                            <label>Metadata URL</label>
                                            <input className='form-control form-control-sm' value={federationValues.metaData}
                                                onChange={(e) => {
                                                    setFederationValues({
                                                        ...federationValues, metaData: e.target.value,
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>}

                                    <div>
                                        <div className="footer-profile-tab">
                                            <button className='btn'
                                                onClick={() => {
                                                    history.goBack();
                                                }}
                                            >
                                                Cancel
                                            </button>

                                            <button
                                                disabled={loader}
                                                className='btn btn-primary'
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </div >
    )
}
