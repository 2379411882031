import React, { useEffect, useState } from "react";
import './style.css';
import { useHistory } from 'react-router-dom';
import { NxLoader, NxBreadcrumb, NxTitleTab, NxActionTab, NxGridList, NxRightDrawer, NxFooter } from '@afiplfeed/itr-app';
import { ItrApiService } from '@afiplfeed/itr-ui';
import Headers from "../../Screens/Headers/Headers";
import { message } from 'antd';
import { NotificationManager } from "react-notifications";

const splits = async (dests, setEnquiry, setLoad, refresh, setExport) => {
    let arr = [];
    let arr2 = [];
    for (let value of dests) {
        let data = [
            value.FullName ? value.FullName : '-',
            value.CompanyName ? value.CompanyName : '-',
            value.Email ? value.Email : '-',
            value.Phone ? value.Phone : '-',
            <div>
                <button className="btn btn-sm"
                    style={{ fontSize: '11px', color: value.StatusName == 'Contact in future' ? 'black' : 'white', backgroundColor: value.StatusName == 'Active' ? 'green' : value.StatusName == 'Pre qualified' ? 'lightgreen' : value.StatusName == 'Invalid Contact' ? 'blue' : value.StatusName == 'Contact in future' ? 'lightgreen' : value.StatusName == 'Market Failed' ? 'red' : 'red' }}
                >
                    {value.StatusName ? value.StatusName : '-'}
                </button>
            </div>,
            // value.StatusName ? value.StatusName : '-',
            <div><button style={{ fontSize: '11px' }} className="btn btn-sm btn-primary" onClick={(e) => {
                e.stopPropagation();
                let body = { ...value, IsMovedToOnboarding: true };
                setLoad(true)
                ItrApiService.POST({
                    url: 'RevOps/Enquiry/ConvertToCustomer',
                    data: body
                }).then(res => {
                    setLoad(false);
                    if (res.Success == true) {
                        if (res.result.isSuccess == true) {
                            message.success('Successfully Completed');
                            // window.location.reload();
                            refresh()
                        }
                    }
                });
            }}>Onboard</button></div>];
        let obj = { sendItem: value, data: data };
        let obj2 = {
            "Full Name": value.FullName ? value.FullName : '-',
            "Email": value.Email ? value.Email : '-',
            "Phone": value.Phone ? value.Phone : '-',
            "Status": value.StatusName ? value.StatusName : '-',
        }
        arr2 = [...arr2, obj2];
        arr = [...arr, obj]
    }
    setExport(arr2);
    setEnquiry(arr);
}

export default function Enquiry() {
    let history = useHistory();
    const [load, setLoad] = useState(false);
    const [handel, setHandel] = useState(false);
    const [handel2, setHandel2] = useState(false);
    const [exportVal, setExport] = useState([]);

    const [enquiry, setEnquiry] = useState([]);
    const [enquiryEdit, setEnquiryEdit] = useState('');

    const refresh = async () => {
        let result = await ItrApiService.GET({
            url: 'RevOps/Enquiry/GetEnquires'
        });
        if (result.Success == true) {
            let getEnquiryDetails = result.result.data;
            if (getEnquiryDetails === null) {
                // NotificationManager.error(result.result.message);
            } else if (getEnquiryDetails.length == 0) {
                setEnquiry([])
                setEnquiryEdit([]);
            }
            else {

                await splits(getEnquiryDetails, setEnquiry, setLoad, getEnquiry, setExport);
                setEnquiryEdit(getEnquiryDetails)
            }
            // setEnquiry(getEnquiryDetails);
        }
        else if (result.result.isSuccess == false) {
            NotificationManager.error(result.result.message);
            // sessionStorage.clear();
            // history.push('/')
        }
    }

    const getEnquiry = async () => {
        setLoad(true);
        let result = await ItrApiService.GET({
            url: 'RevOps/Enquiry/GetEnquires'
        });
        if (result.Success == true) {
            let getEnquiryDetails = result.result.data;
            if (getEnquiryDetails === null || !getEnquiryDetails.length) {
                // NotificationManager.error(result.result.message);
            }
            else {
                await splits(getEnquiryDetails, setEnquiry, setLoad, refresh, setExport);
                await setEnquiryEdit(getEnquiryDetails)
            }
            // setEnquiry(getEnquiryDetails);
        }
        else if (result.result.isSuccess == false) {
            NotificationManager.error(result.result.message);
            // sessionStorage.clear();
            // history.push('/')
        }
        setLoad(false);
    }

    useEffect(() => {
        getEnquiry();
        // await splits(dest, setEnquiry);
    }, [])

    return (
        <div>
            <NxLoader state={load} />
            {/* <Headers > */}
            {/* <NxBreadcrumb className="navigation-breadcrumb" data={[
                {
                    name: 'Home',
                    onClick: () => {
                        history.push('/partner-board');
                    }
                }
            ]} /> */}
            <div style={{ flex: 1 }}>
                {/* <NxTitleTab className="title-tab" title='Enquiry' onCloseClick={() => {
                    history.push('/customers');
                }} /> */}
                {/* <div className="d-flex justify-content-between align-items-center flex-wrap"> */}
                <NxActionTab className="enquiry-action-tab px-0"
                    onColumnsClick={() => { setHandel(true) }}
                    exportValue={exportVal}
                    fileName='Enquiry'
                    onRefreshClick={async () => {
                        setLoad(true)
                        await getEnquiry();
                        setLoad(false);
                    }}
                    onNewEntryClick={() => { history.push('/enquiry/add') }}
                    enableNewEntry={true}
                />
                {/* <button className="btn btn-primary btn-sm"> New Enquiry</button> */}
                {/* </div> */}
                <div className="px-0 pt-0">
                    <NxGridList
                        enableSearch={true}
                        autoSearch={true}
                        enableAutoPagination={true}
                        showShowing={true}
                        header={[
                            {
                                name: 'Full Name',
                                sort: true,
                                search: true
                            },
                            {
                                name: 'Company Name',
                                sort: false,
                                search: false
                            },
                            {
                                name: 'Email',
                                search: true,
                                sort: false,
                            },
                            {
                                name: 'Phone',
                                search: true,
                                sort: false,
                            },
                            {
                                name: 'Status',
                                sort: false,
                            },
                            {
                                name: 'Action',
                            }
                        ]}
                        rowClick={(data) => {

                            history.push({
                                pathname: '/enquiry/edit',
                                state: {
                                    editData: data
                                }
                            });
                        }}
                        data={enquiry}
                    // {enquiry}
                    // {[
                    //     { sendItem: '1', data: ['test', 'user', 'ss@mail.com user', '9002000201', 'bytes', 'active'] },
                    //     // { sendItem: '2', data: ['test1', 'user1', 'ss1@mail.com user', '9002000201', 'bytes', 'active'] }
                    // ]} 
                    />
                </div>
                <NxRightDrawer onClose={() => setHandel(false)} show={handel} drawerContent={() => <div className="qwerty">

                </div>} ondrawer1Close={() => { setHandel2(false) }} />
            </div>

            <NxFooter ExtraContent={[
                { name: 'Terms of Service' },
                { name: 'privacy' },
            ]} />
        </div>
    )
}