import { NxFooter, NxLoader, NxTitleTab } from '@afiplfeed/itr-app';
import { ItrApiService } from '@afiplfeed/itr-ui';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { useHistory } from 'react-router-dom'
import Headers from '../../../Screens/Headers/Headers';

function AddManageAccess() {
    const history = useHistory();
    const [loader, setLoader] = useState(false);

    // Partners
    const [allPartners, setAllPartners] = useState([]);
    const getPartners = async () => {
        let result = await ItrApiService.GET({
            url: 'RevOps/PartnerInformation'
        });
        if (result.result.isSuccess == true) {
            let getPartnerDetails = result.result.data;
            setAllPartners(getPartnerDetails);
        }
        else if (result.result.isSuccess == false) {
            NotificationManager.error(result.result.message);
        }
    }

    const [newManageAccess, setNewManageAccess] = useState({
        portalAccessID: 0,
        accessTypeID: "",
        entityCode: "",
        userCode: "",
        userName: "",
        password: "",
        displayName: "",
        isAccess: true,
        isFirstLogin: true,
        tenantCode: "",
        customerCode: "",
        emailAddress: "",
        isActive: true,
        isEnable2FA: true
    })


    useEffect(() => {
        getPartners()
    }, [])
    return (
        <div>
            <Headers />
            <NxLoader state={loader} />
            <NxTitleTab className="title-tab" title="Add Privileged User" onCloseClick={() => history.goBack()} />
            <div className="card password-policy-main mt-0">
                <div className="card-body p-0 mb-5" >
                    <div className="row p-3 mx-1">
                        <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-6'>
                            <div className="row">
                                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2'>
                                    <div className='row'>
                                        <div className='col-12 col-sm-12 col-md-5 col-lg-5 col-xl-4'>
                                            <label className='f-badge-title'>Access Type</label>
                                        </div>

                                        <div className='col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 flex-start' >
                                            <select className='form-select form-select-sm' value={newManageAccess.accessTypeID}
                                                onChange={(e) => setNewManageAccess({ ...newManageAccess, accessTypeID: e.target.value })}>
                                                <option value="" selected disabled> -- Please Select -- </option>
                                                <option value="1">Administrator  </option>
                                                <option value="2">Tech Support</option>
                                                <option value="3">DevOps Eng.</option>
                                                <option value="4">Finance</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2' hidden={newManageAccess.accessTypeID != 2 ? true : false}>
                                    <div className='row'>
                                        <div className='col-12 col-sm-12 col-md-5 col-lg-5 col-xl-4'>
                                            <label className='f-badge-title'>Partner</label>
                                        </div>

                                        <div className='col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 flex-start' >
                                            <select class="form-select form-select-sm" aria-label="Default select example"
                                                value={newManageAccess.tenantCode}
                                                onChange={(e) => setNewManageAccess({ ...newManageAccess, tenantCode: e.target.value })}>
                                                <option value="" selected hidden></option>
                                                <option disabled>--Please-select--</option>
                                                {allPartners && allPartners.map((data, index) => {
                                                    return <option key={index} value={data.partnerCode}> {data.partnerName} </option>
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2'>
                                    <div className='row'>
                                        <div className='col-12 col-sm-12 col-md-5 col-lg-5 col-xl-4'>
                                            <label className='f-badge-title'>User Code</label>
                                        </div>

                                        <div className='col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 flex-start' >
                                            <input className='form-control form-control-sm' value={newManageAccess.userCode}
                                                onChange={(e) => setNewManageAccess({ ...newManageAccess, userCode: e.target.value })} />
                                        </div>
                                    </div>
                                </div> */}

                                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2'>
                                    <div className='row'>
                                        <div className='col-12 col-sm-12 col-md-5 col-lg-5 col-xl-4'>
                                            <label className='f-badge-title'>Username</label>
                                        </div>

                                        <div className='col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 flex-start' >
                                            <input className='form-control form-control-sm' value={newManageAccess.userName}
                                                onChange={(e) => setNewManageAccess({ ...newManageAccess, userName: e.target.value })} />
                                        </div>
                                    </div>
                                </div>

                                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2'>
                                    <div className='row'>
                                        <div className='col-12 col-sm-12 col-md-5 col-lg-5 col-xl-4'>
                                            <label className='f-badge-title'>Email</label>
                                        </div>

                                        <div className='col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 flex-start' >
                                            <input className='form-control form-control-sm' value={newManageAccess.emailAddress}
                                                onChange={(e) => setNewManageAccess({ ...newManageAccess, emailAddress: e.target.value })} />
                                        </div>
                                    </div>
                                </div>

                                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2'>
                                    <div className='row'>
                                        <div className='col-12 col-sm-12 col-md-5 col-lg-5 col-xl-4'>
                                            <label className='f-badge-title'>Password</label>
                                        </div>

                                        <div className='col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 flex-start' >
                                            <input className='form-control form-control-sm' value={newManageAccess.password}
                                                type="password"
                                                onChange={(e) => setNewManageAccess({ ...newManageAccess, password: e.target.value })} />
                                        </div>
                                    </div>
                                </div>

                                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2'>
                                    <div className='row'>
                                        <div className='col-12 col-sm-12 col-md-5 col-lg-5 col-xl-4'>
                                            <label className='f-badge-title'>Display Name</label>
                                        </div>

                                        <div className='col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 flex-start' >
                                            <input className='form-control form-control-sm' value={newManageAccess.displayName}
                                                onChange={(e) => setNewManageAccess({ ...newManageAccess, displayName: e.target.value })} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='text-end'>
                            <button className='btn btn-primary btn-sm mt-4 mb-1' onClick={() => {
                                // let dataVal = { ...newBillingRules, selectDay: newBillingRules.selectDay == '' ? 0 : parseInt(newBillingRules.selectDay), isRecurringJob: newBillingRules.isRecurringJob == '' ? false : newBillingRules.isRecurringJob == 'true' ? true : false };
                                setLoader(true);
                                ItrApiService.POST({ url: 'PAM/SavePortalAccess', data: newManageAccess }).then(res => {
                                    if (res.Success == true) {
                                        if (res.result.isSuccess == true) {
                                            setLoader(false);
                                            history.goBack();
                                            setNewManageAccess({ ...newManageAccess, accessTypeID: "", userCode: "", userName: "", emailAddress: "", displayName: "", tenantCode: "" })
                                        } else {
                                            NotificationManager.error(res.result.message);
                                        }
                                    } else {
                                        NotificationManager.error(res.message);
                                    }
                                })
                            }}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
            <NxFooter
                className="verscio-footer" companyName="© 2022 Ithred Solutions" ExtraContent={[
                    { name: 'Terms of Service' },
                    { name: 'Privacy' },
                ]} />
        </div>
    );
}

export default AddManageAccess;