import React, { useEffect, useState } from 'react';
import './LoginTheme.css';
import pinwheel from '../../assets/pinwheel.png';
import nexap from '../../assets/nexap_logo1.png';
import ithredLogo from '../../assets/iTHRED.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faUser, faArrowLeft, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { noticeBoard } from '../../general/general';
// import {portalLogin,createPassword} from '../../config/authApi';
import { ItrApiService, ItrAuthService } from '@afiplfeed/itr-ui';
import { NxLoader } from '@afiplfeed/itr-app';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import OtpInput from 'react-otp-input';
import '../../Screens/Profile/TwoFactor.scss';
import ReactOtpResender from 'react-otp-resender';
import adapLogo from '../../assets/adap_logo.svg';

export default function LoginTheme() {

    let history = useHistory();
    const [otp, setOtp] = useState('');

    // for-show/hide password
    const [forEmail, setForEmail] = useState('')
    const [showPass, setShowPass] = useState(false);
    const visiblePassword = () => {
        if (showPass == false) {
            setShowPass(true);
        }
        else {
            setShowPass(false);
        }
    }

    

    // update/create-password
    const [check, setCheck] = useState('');
    const [checkPage, setCheckPage] = useState(false);

    // show-login-form
    const [showLogin, setShowLogin] = useState(false);

    // show-reset-form
    const [showReset, setShowReset] = useState(true);
    const resetPassword = () => {
        if (showReset == true) {
            setShowReset(false);
        }
        else {
            setShowReset(true);
        }
    }

    // portal-login
    var getLogin = () => {
        return {
            email: document.getElementById('Email').value,
            password: document.getElementById('password').value,
            agent: 'console'
        }
    }

    const [seconds, setSeconds] = useState(0);
    const [minutes, setMinutes] = useState(0);

    let dd = async (secs, mins) => {
        let sec = secs;
        let min = mins;
        setSeconds(sec);
        setMinutes(min);
        let myInterval = setInterval(() => {
            if (sec > 0) {
                sec = sec - 1;
                setSeconds(sec)
            }
            if (sec === 0) {
                if (min === 0) {
                    // clearInterval(myInterval)
                } else {
                    min = min - 1;
                    setMinutes(min)
                    sec = 59;
                    setSeconds(59);
                }
            }
        }, 1000);
    }


    const [checkFactor, setCheckFactor] = useState({
        isEnable2FA: false
    });


    const [name, setName] = useState();
    var loginAuth = async () => {
        setCheck(true);
        let userInfo = await getLogin();
        var body = {
            userName: userInfo.email,
            password: userInfo.password,
            agent: userInfo.agent
        }
        let result = await ItrAuthService.Login({ data: body });
        // var result = await portalLogin(body, history);
        if (result.Success == false) {
            NotificationManager.error(result.message);
        } else {
            // NotificationManager.success(result.message);
            var data1 = result.result.data;
            var userName = result.result.data.userName;
            var type = result.result.data.accessType;
            setName(userName);
            if (result.result.isSuccess == true) {
                if (data1.isFirstLogin == true) {
                    setCheckPage(true);
                } else if (result.result.data.isEnable2FA == true) {
                    setCheckFactor(result.result.data);
                    sendOtp(result.result.data);
                } else {
                    setCheckPage(false);
                    // if (result.result.data.accessType == 'PARTNER'){
                    //     history.replace('/partner-dashboard');
                    // }
                    history.replace('/dashboard');
                    // else{
                    // }
                }
            }
            else {
                return null
            }
        }
        setCheck(false);
    }


    // for-update-password
    var getUpdatePassword = () => {
        return {
            current: document.getElementById('current').value,
            password: document.getElementById('new').value,
            confirm: document.getElementById('confirm').value
        }
    }

    var updatepassword = async () => {
        setCheck(true);
        let userPassword = await getUpdatePassword();
        let { password } = await getLogin();

        let current = document.getElementById('current').value;
        if (password != current) {
            NotificationManager.error('Please Check the password');
        } else if (userPassword.password != userPassword.confirm) {
            NotificationManager.error('Password Mismatches');
        } else {
            let result = await ItrApiService.POST({ data: { userName: name, password: userPassword.password }, url: 'PAM/CreatePassword' })
            if (result.Success == true) {
                if (result.result.isSuccess == true) {
                    history.replace('/dashboard');
                    setCheck(false);
                } else {
                    NotificationManager.error(result.result.message);
                }
            } else {
                NotificationManager.error(result.message);
            }
        }
    }

    const resendEvent = () => {
        // You can do what ever you want & reset timer too.
    }

    // for-reset-password

    var forPassword = () => {
        return {
            reset: document.getElementById('reset').value
        }
    }

    var forgetPassword = async () => {
        let getUserEmail = forPassword();
        let checkUserEmail = document.getElementById('reset').value;

        if (getUserEmail.reset != checkUserEmail) {
            NotificationManager.error('Invalid Email Id');
            return
        }
        let result = await ItrApiService.POST({ data: { userName: getUserEmail.reset }, url: 'PAM/ResetPassword' });
    }


    const [resetOptions, setResetOptions] = useState('Email');

    const click = (event) => {
        // if (event.keyCode === 49 && event.shiftKey) {
        //   }
        // if( event.keyCode === 65 && event.shiftKey ) {
        //         NotificationManager.error('you pressed SHIFT+A');
        // }
        // if (e.which) {
        //   NotificationManager.error('wow');
        // } else {
        //   NotificationManager.error('noooooo');
        // }
    }

    const keys = (e) => {
        var x = e.key;
        switch (x) {
            case 37:
                dir = 'left';
                break;

            case 39:
                dir = 'right';
                break;

            case 38:
                dir = 'up';
                break;

            case 40:
                dir = 'down';
                break;

        }
    }

    const sendOtp = async (datas) => {
        await ItrApiService.POST({
            url: 'PAM/SendSMSVerification',
            data: {
                userCode: datas.userCode,
                userName: datas.userName,
                phonenumbers: datas.phoneNumber,
                otpKey: "",
                isEnable2FA: datas.isEnable2FA,
                adminPin: ""
            }
        }).then(res => {
            if (res.Success == true) {
                if (res.result.isSuccess == true) {
                    // setSeconds(180);
                    dd(59, 2)
                    NotificationManager.success(res.result.message);
                } else {
                    NotificationManager.error(res.result.message);
                }
            } else {
                NotificationManager.error(res.message);
            }
        })
    }
    useEffect(() => {
        window.addEventListener('keypress', keys,);
    }, [])

    return (
        <>
            <NotificationContainer />
            <NxLoader state={check} />
            <div className="fixed-background"></div>
            <main>
                <div className="container">
                    <div className="row h-100">
                        <div className="col-12 col-md-10 mx-auto my-auto">
                            <div className="card auth-card">
                                <div className="position-relative image-side d-flex align-items-end">
                                    {/* <h2 class="text-white h2">MAGIC IS IN THE DETAILS</h2>

                                <p class="white mb-0">
                                    Please use your credentials to login.
                                    <br/>If you are not a member, please &nbsp;
                                    <a class="text-white register-text">register</a>.
                                </p> */}
                                    {/* <h3> {noticeBoard.title} </h3>
                                    <p> {noticeBoard.info} </p> */}
                                    <p className="copy-rights">© 2023 Altascio Technologies (I) Pvt. Ltd.,</p>
                                </div>

                                <div className="form-side" hidden={(checkPage == true || checkFactor.isEnable2FA == true) ? true : false}>
                                    <div hidden={showLogin}>
                                        <a>
                                            <span className="logo-single">
                                                <img src={adapLogo} width="30%" alt="ithred-logo" />
                                            </span>
                                        </a>

                                        {/* login-user-form */}
                                        <form className="mt-5" hidden={showReset == false ? true : false}>
                                            <div className="mt-2">
                                                <h6 className=" fw-bold labels">Login</h6>
                                            </div>

                                            <label className="form-group has-float-label mt-2">
                                                <span className="labels">Email Address</span>
                                                <input className="form-control" type="text" id="Email" title="Enter Email Address" required />
                                            </label>

                                            <label className="form-group has-float-label mb-4 mt-1">
                                                <span className="labels">Password</span>
                                                <input className="form-control" type={showPass ? 'text' : 'password'} title="Enter Password" placeholder="" id="password" required />
                                            </label>
                                            {/* <span class="input-group-text passwordIcon" id="basic-addon1" onClick={visiblePassword}>
                                                        {showPass ? <FontAwesomeIcon icon={faEye} size="xs" color="gray" /> : <FontAwesomeIcon icon={faEyeSlash} size="xs" color="gray" />}
                                                    </span> */}
                                            <div className="d-flex justify-content-between align-items-center action-footer" hidden={showReset == false ? true : false}>
                                                <a onClick={resetPassword} >Forgot Password</a>
                                                <button disabled={check} className="btn btn-primary btn-lg btn-shadow" type="submit"
                                                    onClick={async () => {
                                                        setCheck(true);
                                                        await loginAuth();
                                                        setCheck(false);
                                                    }}>Login</button>
                                            </div>

                                            <footer className="fs-6 footer-copy-rights d-flex justify-content-center align-items-center">
                                                <p className="copy-rights mb-0 mt-3">© 2023 Altascio Technologies (I) Pvt. Ltd.,</p>
                                            </footer>
                                        </form>
                                    </div>


                                    {/* forget-password-form */}
                                    <form className="mt-2" hidden={showReset}>
                                        <label className="form-group has-float-label mb-4">
                                            <span className="labels fw-bold fs-6">Reset Password</span>
                                            <p className="reser-info">Enter the email associated with your account and we’ll send an email with instructions to reset your password</p>
                                            {/* <select className="form-select form-select-lg" onChange={(e) => setResetOptions(e.target.value)}>
                                                <option value="Email">Email</option>
                                                <option value="Username">UserName</option>
                                            </select> */}
                                        </label>

                                        {
                                            resetOptions == 'Email' ? <>
                                                <label className="form-group has-float-label mb-4">
                                                    <span className="labels" >Email Address</span>
                                                    <input className="form-control w-100" type="text" id="reset" title="Enter Email Address" value={forEmail} onChange={(e) => {
                                                        setForEmail(e.target.value)
                                                    }} />
                                                </label>
                                                <div className="d-flex justify-content-between align-items-center action-footer">
                                                    <a onClick={resetPassword}>Login</a>
                                                    <button disabled={check} className="btn btn-primary btn-lg btn-shadow" type="submit" onClick={() => {
                                                        if (forEmail == '') {
                                                            NotificationManager.error('Enter the email')
                                                        } else {
                                                            setCheck(true);
                                                            ItrApiService.ForgotPassword({
                                                                data: {
                                                                    userName: forEmail
                                                                }
                                                            }).then(res => {
                                                                setCheck(false);
                                                                if (res.Success == true) {
                                                                    if (res.result.isSuccess == true) {
                                                                        NotificationManager.success('Email Sent!');
                                                                        setShowReset(true);
                                                                    } else {
                                                                        NotificationManager.error(res.result.message)
                                                                    }
                                                                } else {
                                                                    NotificationManager.error(res.message)
                                                                }
                                                            })
                                                        }
                                                    }}>Reset Password</button>
                                                </div>
                                            </> :
                                                <>
                                                    <label className="form-group has-float-label mb-4">
                                                        <span className="labels">UserName</span>
                                                        <input className="form-control w-100" type="text" id="reset" />
                                                    </label>

                                                    <div className="d-flex justify-content-between align-items-center action-footer">
                                                        <a onClick={resetPassword}>Back</a>
                                                        <button disabled={check} className="btn btn-primary btn-lg btn-shadow" type="submit">Request Administrator</button>
                                                    </div>
                                                </>}
                                        {/* <span class="input-group-text passwordIcon" id="basic-addon1" onClick={visiblePassword}>
                                                {showPass ? <FontAwesomeIcon icon={faEye} size="xs" color="gray" /> : <FontAwesomeIcon icon={faEyeSlash} size="xs" color="gray" />}
                                            </span> */}
                                    </form>

                                </div>



                                {/* 2FA - check */}
                                <div className="form-side" hidden={checkFactor.isEnable2FA == false ? true : false}>
                                    <div>
                                        <a>
                                            <span className="logo-single">
                                                <img src={adapLogo} width="30%" alt="ithred-logo" />
                                            </span>
                                        </a>

                                        <div className="mt-3 feilds2">
                                            <div className="mt-2">
                                                <h6 className=" fw-bold labels">Enter the OTP</h6>
                                            </div>

                                            <div className='mb-5 mt-3'>
                                                <OtpInput
                                                    containerStyle={{}}
                                                    value={otp}
                                                    onChange={(ot) => { setOtp(ot) }}
                                                    numInputs={6}
                                                    separator={<span> - </span>}
                                                />
                                            </div>

                                            <div className="d-flex justify-content-between align-items-center action-footer mt-2">
                                                {/* <div className='mx-0                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               '>
                                                    </div> */}
                                                {/* <ReactOtpResender
                                                        seconds={seconds}
                                                        textStyle={{
                                                            color: 'black',
                                                            margin: '0px'
                                                        }}
                                                        resendBtnText={'Send OTP again'}
                                                        resendBtnStyle={{
                                                            color: 'black',
                                                            display: 'contents',
                                                            backgroundColor: 'black',
                                                            outline: 'none',
                                                            border: 1,
                                                            cursor: 'pointer',
                                                            fontSize: '15px'
                                                        }}
                                                        resendFunc={() => sendOtp(checkFactor)}
                                                    /> */}


                                                {minutes == 0 && seconds == 0 ? <>
                                                    <button className='btn btn-secondary btn-lg btn-shadow'
                                                        onClick={() =>
                                                            sendOtp(checkFactor)
                                                        }>
                                                        Resend OTP
                                                    </button>
                                                </> : <button disabled className='btn btn-secondary btn-lg btn-shadow' style={{ color: 'black', backgroundColor: 'white' }}> {minutes == 0 ? '00' : '0' + minutes} : {seconds == 0 ? '00' : seconds.toString().length == 1 ? '0' + seconds : seconds} </button>}
                                                <button disabled={check} className="btn btn-primary btn-lg btn-shadow"
                                                    onClick={async () => {
                                                        setCheck(true);
                                                        await ItrApiService.POST({
                                                            url: 'PAM/SecretKeyVerification',
                                                            data: {
                                                                userCode: checkFactor.userCode,
                                                                userName: checkFactor.userName,
                                                                phonenumbers: checkFactor.phoneNumber.toString(),
                                                                otpKey: otp,
                                                                isEnable2FA: checkFactor.isEnable2FA,
                                                                adminPin: ""
                                                            }
                                                        }).then(res => {
                                                            setCheck(false)
                                                            if (res.Success == true) {
                                                                if (res.result.isSuccess == true) {
                                                                    history.replace('/dashboard');
                                                                    NotificationManager.success('Successfully Verified');
                                                                } else {
                                                                    NotificationManager.error(res.result.message);
                                                                }
                                                            } else {
                                                                NotificationManager.error(res.message);
                                                            }
                                                        })
                                                        // await loginAuth();
                                                        setCheck(false);
                                                    }}>VERIFY OTP</button>
                                            </div>

                                            <footer className="fs-6 footer-copy-rights d-flex justify-content-center align-items-center">
                                                <p className="copy-rights mb-0 mt-3">© 2022 Ithred Solutions.com</p>
                                            </footer>
                                        </div>
                                    </div>
                                </div>




                                <div className="form-side" hidden={checkPage == true ? false : true}>
                                    {/* <div hidden={showLogin}> */}
                                    <a>
                                        <span className="logo-single">
                                            <img src={adapLogo} width="30%" alt="nexap-logo" />
                                        </span>
                                    </a>

                                    {/* login-update-form */}
                                    <form className="mt-4" hidden={showReset == false ? true : false}>
                                        <div className="mt-5">
                                            <h6>Update Your Password</h6>
                                        </div>

                                        <label className="form-group has-float-label mt-4 mb-4">
                                            <span className="labels">Current Password</span>
                                            <input className="form-control" type={showPass ? 'text' : 'password'} id="current" />
                                        </label>

                                        <label className="form-group has-float-label mb-4">
                                            <span className="labels">New Password</span>
                                            <input className="form-control" type={showPass ? 'text' : 'password'} placeholder="" id="new" />
                                        </label>

                                        <label className="form-group has-float-label mb-4">
                                            <span className="labels">Confirm Password</span>
                                            <input className="form-control" type={showPass ? 'text' : 'password'} placeholder="" id="confirm" />
                                        </label>
                                        {/* <span class="input-group-text passwordIcon" id="basic-addon1" onClick={visiblePassword}>
                                                        {showPass ? <FontAwesomeIcon icon={faEye} size="xs" color="gray" /> : <FontAwesomeIcon icon={faEyeSlash} size="xs" color="gray" />}
                                                    </span> */}
                                        <div className="d-flex justify-content-between align-items-center action-footer" hidden={showReset == false ? true : false}>
                                            <a onClick={() => setCheckPage(false)} >Cancel</a>
                                            <button disabled={check} className="btn btn-primary btn-lg btn-shadow" type="submit"
                                                onClick={async () => {
                                                    setCheck(true);
                                                    await updatepassword();
                                                    setCheck(false);
                                                }}>Update</button>
                                        </div>
                                    </form>
                                    {/* </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main >
        </>
    )
}