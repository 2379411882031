import React, { useState, useEffect, useRef } from "react";
import './OnBoardingEdit.css';
import { useHistory, useLocation } from 'react-router-dom';
import { ItrApiService, NxApiService } from '@afiplfeed/itr-ui';
import { NxBreadcrumb, NxTitleTab, NxCustomTab, NxLoader, NxFooter } from '@afiplfeed/itr-app';
import Headers from "../../Screens/Headers/Headers";
import { message, Steps, Button } from 'antd';
import { NotificationManager } from "react-notifications";
import { emailValid } from '../../config/Validation';

export default function OnBoardingEdit() {
    const fileRef = useRef();

    const [copyTo, setCopyTo] = useState(false);

    const Buttons = () => {
        return <div className="form-button nx-enquiry-buttons mt-3 mb-5">
            <button className="btn btn-sm btn-primary buttons" > Save</button>
            <button className="btn btn-sm btn-primary buttons"> Onboard</button>
            <button className="btn btn-sm btn-primary buttons"> Previous</button>
            <button className="btn btn-sm btn-primary buttons"> Next</button>
            <button className="btn btn-sm close btn-outline-primary" onClick={() => history.goBack()}> Cancel</button>
        </div>
    }

    const [loading, setLoading] = useState(false);

    // cities
    const [allCities, setAllCities] = useState([]);
    const getCities = async () => {
        let result = await ItrApiService.GET({
            url: 'Platform/City/GetAllCities'
        });
        if (result.result.isSuccess == true) {
            let getCustomerDetails = result.result.data;
            if (result.result.data) {
                setAllCities(getCustomerDetails);
            }
        }
        else if (result.result.isSuccess == false) {
            NotificationManager.error(result.result.message);
            // sessionStorage.clear();
            // history.push('/')
        }
    }


    // states
    const [allStates, setAllStates] = useState([]);
    const getStates = async () => {
        let result = await ItrApiService.GET({
            url: 'Platform/State/GetAllStates'
        });
        if (result.result.isSuccess == true) {
            let getCustomerDetails = result.result.data;
            if (result.result.data) {
                setAllStates(getCustomerDetails);
            }

        }
        else if (result.result.isSuccess == false) {
            NotificationManager.error(result.result.message);
            // sessionStorage.clear();
            // history.push('/')
        }
    }

    const [currencyData, setCurrencyData] = useState([]);

    const getCurrency = () => {
        ItrApiService.GET({
            url: 'RevOps/CurrencyExchangeRate/GetAllCurrencyExchangeRate'
        }).then(res => {
            if (res.Success == true) {
                if (res.result.isSuccess == true) {
                    if (res.result.data) {
                        setCurrencyData(res.result.data);
                    }
                } else {
                    NotificationManager.error(res.result.message);
                }
            } else {
                NotificationManager.error(res.message);
            }
            setLoading(false);
        });
    }

    // country
    const [allCountries, setAllCountries] = useState([]);
    const getCountry = async () => {
        let result = await ItrApiService.GET({
            url: 'RevOps/Country/GetCountries'
        });
        if (result.result.isSuccess == true) {
            let getCustomerDetails = result.result.data;
            if (result.result.data) {
                setAllCountries(getCustomerDetails);
            }
        }
        else if (result.result.isSuccess == false) {
            NotificationManager.error(result.result.message);
            // sessionStorage.clear();
            // history.push('/')
        }
    }

    // pincode
    const [allPinCode, setAllPinCode] = useState([]);
    const getZipCodes = async () => {
        let result = await ItrApiService.GET({
            url: 'Platform/ZipCode/GetAllZipCodes'
        });
        if (result.result.isSuccess == true) {
            let getCustomerDetails = result.result.data;
            if (result.result.data) {
                setAllPinCode(getCustomerDetails);
            }
        }
        else if (result.result.isSuccess == false) {
            NotificationManager.error(result.result.message);
            // sessionStorage.clear();
            // history.push('/')
        }
    }

    const [billingTemplate, setBillingTemplate] = useState([]);
    const getTemplate = async () => {
        let result = await ItrApiService.GET({
            url: 'RevOps/BillingRule/GetBillingRules'
        }).then(res => {
            if (res.Success == true) {
                if (res.result.isSuccess == true) {
                    if (res.result.data) {
                        setBillingTemplate(res.result.data);
                    }
                }
            }
            setLoading(false);
        })
    }

    // AllUDo`s
    let UdoData = ["Enquiry Status", "Nature Of Business", "Industry", "Ownership", "Source"];
    const [allUdo, setAllUdo] = useState([]);
    const [allUdoIndustry, setAllUdoIndustry] = useState([]);
    const [allUdoOwnership, setAllUdoOwnership] = useState([]);
    const getUdo = async () => {
        let result = await ItrApiService.POST({
            url: 'Platform/UserDefined/AllUDOs',
            data: UdoData
        });
        if (result.result.isSuccess == true) {
            let getCustomerDetails = result.result.data;
            let getOptions = getCustomerDetails[1].Options;
            for (let val of getCustomerDetails) {
                if (val.CategoryName == 'Industry') setAllUdoIndustry(val.Options);
                if (val.CategoryName == 'Ownership') setAllUdoOwnership(val.Options);
            }
            setAllUdo(getOptions);

        }
        else if (result.result.isSuccess == false) {
            NotificationManager.error(result.result.message);
            // sessionStorage.clear();
            // history.push('/')
        }
    }

    const [customers, setCustomers] = useState([]);
    const getparent = async () => {
        await ItrApiService.GET({ url: 'RevOps/Entity/GetEntities/true' }).then((resCustomers) => {
            if (resCustomers.Success == true) {
                if (resCustomers.result.isSuccess == true) {
                    if (resCustomers.result.data) {
                        setCustomers(resCustomers.result.data);
                    }
                }
            }
        });

    };
    // Partners
    const [allPartners, setAllPartners] = useState([]);
    const getPartners = async () => {
        let result = await ItrApiService.GET({
            url: 'RevOps/PartnerInformation'
        });
        if (result.result.isSuccess == true) {
            let getPartnerDetails = result.result.data;
            setAllPartners(getPartnerDetails);
        }
        else if (result.result.isSuccess == false) {
            NotificationManager.error(result.result.message);
        }
    }

    const [currency, setCurrency] = useState({
        currency: 0,
        currencyName: ""
    });

    const [editOnboarding, setEditOnboarding] = useState({});
    const [editEntityId, setEditEntityId] = useState();
    const [editEntityCode, setEditEntityCode] = useState();
    const [editEnquiryCode, setEditEnquiryCode] = useState();
    const [editEntityBoardingID, setEditEntityBoardingID] = useState();

    const initiate = async () => {
        setEditEntityId(location.state.passData.entityID);
        setEditEntityCode(location.state.passData.entityCode);
        setEditEnquiryCode(location.state.passData.enquiryID);
        setEditEntityBoardingID(location.state.passData.entityBoardingID);
        setLoading(true);
        await getUdo();
        await getPartners();
        await getparent();
        await getCities();
        await getCountry();
        await getZipCodes();
        await getStates();
        await getTemplate();
        await getCurrency();
        let obj = location.state.passData
        if (!location.state.passData.administrativeContact) {
            obj.administrativeContact = {
                entityContactID: 0,
                entityID: editEntityId,
                firstName: "",
                lastName: "",
                displayName: "",
                designation: "",
                department: "",
                phoneNo: "",
                emailID: ""
            }
        }
        if (!location.state.passData.technicalContact) {
            obj.technicalContact = {
                entityContactID: 0,
                entityID: editEntityId,
                firstName: "",
                lastName: "",
                displayName: "",
                designation: "",
                department: "",
                phoneNo: "",
                emailID: ""
            }
        }
        if (!location.state.passData.billingContact) {
            obj.billingContact = {
                entityContactID: 0,
                entityID: 0,
                firstName: "",
                lastName: "",
                displayName: "",
                designation: "",
                department: "",
                phoneNo: "",
                emailID: ""
            }
        }
        if (!location.state.passData.entityBoarding) {
            obj.entityBoarding = {
                entityBoardingID: editEntityBoardingID,
                entityID: editEntityId,
                agreement: "",
                ncnda: "",
                termsofuse: "",
                customerType: "",
                hostingType: "",
                isActive: true
            }
        }
        setEditOnboarding(obj);
        setLoading(false);
    }

    useEffect(() => {
        initiate();
    }, []);


    const { Step } = Steps;
    const [current, setCurrent] = useState(0);

    const next = () => {
        if (current == 0) {
            if (editOnboarding.email == '' || vald1.email1 == false || editOnboarding.companyName == '' || editOnboarding.companyName == false || !editOnboarding.phone || editOnboarding.phone == '' || !editOnboarding.fullName || editOnboarding.fullName == '') {
                let obj = {
                    email: true,
                    companyName: true,
                    phone: true,
                    fullName: true
                }
                if (editOnboarding.email == '' || vald1.email1 == false) {
                    obj = { ...obj, email: false };
                }
                if (editOnboarding.companyName == '' || editOnboarding.companyName == false) {
                    obj = { ...obj, companyName: false };
                }
                if (!editOnboarding.phone || editOnboarding.phone == '') {
                    obj = { ...obj, phone: false };
                }
                if (!editOnboarding.fullName || editOnboarding.fullName == '') {
                    obj = { ...obj, fullName: false }
                }
                setVald({ ...vald1, ...obj });
            } else {
                let obj = {
                    email: true,
                    companyName: true,
                    phone: true,
                    fullName: true
                }
                setCurrent(current + 1);
                setVald({ ...vald1, ...obj })
            }
        } else if (current == 1) {
            if (editOnboarding.companyName == '' || editOnboarding.companyName == false || !editOnboarding.country || editOnboarding.country == '') {
                let obj = {
                    companyName: true,
                    country: true
                }
                if (editOnboarding.companyName == '' || editOnboarding.companyName == false) {
                    obj = { ...obj, companyName: false }
                }
                if (editOnboarding.country == '' || editOnboarding.country == false) {
                    obj = { ...obj, country: false }
                }
                setVald({ ...vald1, ...obj })
            } else {
                let obj = {
                    companyName: true,
                    country: true
                }
                setCurrent(current + 1);
                setVald({ ...vald1, ...obj })
            }
        } else if (current == 3) {
            let obj = {
                tcFirstName: true,
                tcLastName: true,
                tcDesignation: true,
                tcDepartment: true,
                tcPhone: true,
                tcEmail: true,

                acFirstName: true,
                acLastName: true,
                acDesignation: true,
                acDepartment: true,
                acPhone: true,
                acEmail: true,

                bcFirstName: true,
                bcLastName: true,
                bcDesignation: true,
                bcDepartment: true,
                bcPhone: true,
                bcEmail: true,

            }
            if (!editOnboarding.technicalContact) {
                obj = {
                    tcFirstName: false,
                    tcLastName: false,
                    tcDesignation: false,
                    tcDepartment: false,
                    tcPhone: false,
                    tcEmail: false,

                    acFirstName: false,
                    acLastName: false,
                    acDesignation: false,
                    acDepartment: false,
                    acPhone: false,
                    acEmail: false,

                    bcFirstName: false,
                    bcLastName: false,
                    bcDesignation: false,
                    bcDepartment: false,
                    bcPhone: false,
                    bcEmail: false
                }
                setVald({ ...vald1, ...obj })
            } else {
                if (!editOnboarding.technicalContact.firstName
                    || editOnboarding.technicalContact.firstName == ''
                    || !editOnboarding.technicalContact.lastName
                    || editOnboarding.technicalContact.lastName == ''
                    || !editOnboarding.administrativeContact.designation
                    || editOnboarding.administrativeContact.designation == ''
                    || !editOnboarding.administrativeContact.department
                    || editOnboarding.administrativeContact.department == ''
                    || !editOnboarding.technicalContact.phoneNo
                    || editOnboarding.technicalContact.phoneNo == ''
                    || !editOnboarding.technicalContact.emailID
                    || editOnboarding.technicalContact.emailID == ''
                    || !editOnboarding.administrativeContact.firstName
                    || editOnboarding.administrativeContact.firstName == ''
                    || !editOnboarding.administrativeContact.lastName
                    || editOnboarding.administrativeContact.lastName == ''
                    || !editOnboarding.administrativeContact.designation
                    || editOnboarding.administrativeContact.designation == ''
                    || !editOnboarding.administrativeContact.department
                    || editOnboarding.administrativeContact.department == ''
                    || !editOnboarding.administrativeContact.phoneNo
                    || editOnboarding.administrativeContact.phoneNo == ''
                    || !editOnboarding.administrativeContact.emailID
                    || editOnboarding.administrativeContact.emailID == ''

                    || !editOnboarding.billingContact.firstName
                    || editOnboarding.billingContact.firstName == ''
                    || !editOnboarding.billingContact.lastName
                    || editOnboarding.billingContact.lastName == ''
                    || !editOnboarding.billingContact.designation
                    || editOnboarding.billingContact.designation == ''
                    || !editOnboarding.billingContact.department
                    || editOnboarding.billingContact.department == ''
                    || !editOnboarding.billingContact.phoneNo
                    || editOnboarding.billingContact.phoneNo == ''
                    || !editOnboarding.billingContact.emailID
                    || editOnboarding.billingContact.emailID == ''
                ) {
                    if (!editOnboarding.technicalContact.firstName || editOnboarding.technicalContact.firstName == '') obj = { ...obj, tcFirstName: false }
                    if (!editOnboarding.technicalContact.lastName || editOnboarding.technicalContact.lastName == '') obj = { ...obj, tcLastName: false }
                    if (!editOnboarding.technicalContact.designation || editOnboarding.technicalContact.designation == '') obj = { ...obj, tcDesignation: false }
                    if (!editOnboarding.technicalContact.department || editOnboarding.technicalContact.department == '') obj = { ...obj, tcDepartment: false }
                    if (!editOnboarding.technicalContact.phoneNo || editOnboarding.technicalContact.phoneNo == '') obj = { ...obj, tcPhone: false }
                    if (!editOnboarding.technicalContact.emailID || editOnboarding.technicalContact.emailID == '') obj = { ...obj, tcEmail: false }

                    if (!editOnboarding.administrativeContact.firstName || editOnboarding.administrativeContact.firstName == '') obj = { ...obj, acFirstName: false }
                    if (!editOnboarding.administrativeContact.lastName || editOnboarding.administrativeContact.lastName == '') obj = { ...obj, acLastName: false }
                    if (!editOnboarding.administrativeContact.designation || editOnboarding.administrativeContact.designation == '') obj = { ...obj, acDesignation: false }
                    if (!editOnboarding.administrativeContact.department || editOnboarding.administrativeContact.department == '') obj = { ...obj, acDepartment: false }
                    if (!editOnboarding.administrativeContact.phoneNo || editOnboarding.administrativeContact.phoneNo == '') obj = { ...obj, acPhone: false }
                    if (!editOnboarding.administrativeContact.emailID || editOnboarding.administrativeContact.emailID == '') obj = { ...obj, acEmail: false }

                    if (!editOnboarding.billingContact.firstName || editOnboarding.billingContact.firstName == '') obj = { ...obj, bcFirstName: false }
                    if (!editOnboarding.billingContact.lastName || editOnboarding.billingContact.lastName == '') obj = { ...obj, bcLastName: false }
                    if (!editOnboarding.billingContact.designation || editOnboarding.billingContact.designation == '') obj = { ...obj, bcDesignation: false }
                    if (!editOnboarding.billingContact.department || editOnboarding.billingContact.department == '') obj = { ...obj, bcDepartment: false }
                    if (!editOnboarding.billingContact.phoneNo || editOnboarding.billingContact.phoneNo == '') obj = { ...obj, bcPhone: false }
                    if (!editOnboarding.billingContact.emailID || editOnboarding.billingContact.emailID == '') obj = { ...obj, bcEmail: false }
                    setVald({ ...vald1, ...obj });
                } else {
                    setCurrent(current + 1);
                    setVald({ ...vald1, ...obj });
                }
            }
        } else if (current == 3) {

        } else {
            setCurrent(current + 1);
        }
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    const titles = [
        { title: "Enquriy" },
        { title: "Company" },
        { title: "Billing" },
        { title: "Contacts" },
        { title: "Onboarding" },
    ]

    const [vald1, setVald] = useState({
        email1: true,
        companyName: true,
        phone: true,
        fullName: true,
        country: true,
        tcFirstName: true,
        tcLastName: true,
        tcPhone: true,
        tcDesignation: true,
        tcDepartment: true,
        tcEmail: true,

        acFirstName: true,
        acLastName: true,
        acPhone: true,
        acDesignation: true,
        acDepartment: true,
        acEmail: true,

        bcFirstName: true,
        bcLastName: true,
        bcPhone: true,
        bcDesignation: true,
        bcDepartment: true,
        bcEmail: true,


        cType: true,
        bTemp: true,
        trailAccess: true,
        isDiscountApply: true,
        discount: true
    })

    let history = useHistory();
    let location = useLocation();
    const [handel, setHandel] = useState(false);
    return (
        <div>
            <NxLoader state={loading} />
            <Headers />
            <div className="customer-dynamic-pages pt-4 ">
                <NxTitleTab className="title-tab page-title" title='Edit Onboarding' onCloseClick={() => {
                    history.goBack();
                }} />
                <div className="card mx-4 mt-0 newenrolemententry-global">
                    <div className="card-body p-0">
                        <Steps labelPlacement='vertical' current={current} responsive={true} size="small" >
                            {titles.map((item, index) => {
                                return <Step
                                    icon={current == index ? <div style={{ backgroundColor: '#1890ff', padding: 5, borderRadius: '24px', height: '24px', width: '24px', fontSize: '12px', display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white' }}>{index + 1}</div>
                                        : current > index && <div style={{ backgroundColor: '#73b504', padding: 5, borderRadius: '24px', height: '24px', width: '24px', fontSize: '12px', display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white' }}>{index + 1}</div>
                                        // : current < index && <div style={{ borderColor: 'rgba(0,o,0,0.25)', border: '1px solid', backgroundColor: 'white', padding: 5, borderRadius: 29, height: 29, width: 29, fontSize: 15, display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'rgba(0,o,0,0.25)' }}>{index + 1}</div>
                                    }
                                    key={index}
                                    title={item.title} />
                            })}
                        </Steps>
                        {current == 0 ?
                            <div className="container-form mx-3">
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <div className="row">
                                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                <label htmlFor="firstName">Full Name</label>
                                                <input className="form-control form-control-sm" id="firstName" type="text" value={editOnboarding.fullName}
                                                    onChange={(e) => setEditOnboarding({ ...editOnboarding, fullName: e.target.value })} />
                                                <div class={vald1.fullName == false ? "invalid-data" : 'd-none'}>
                                                    Please Enter Full Name.
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                <label htmlFor="lastName">Company Name</label>
                                                <input className="form-control form-control-sm" id="lastName" type="text" value={editOnboarding.companyName}
                                                    onChange={(e) => setEditOnboarding({ ...editOnboarding, companyName: e.target.value })} />
                                                <div class={vald1.companyName == false ? "invalid-data" : 'd-none'}>
                                                    Please Enter Company Name.
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                <label htmlFor="Email">Email</label>
                                                <input className="form-control form-control-sm" id="Email" type="text" value={editOnboarding.email}
                                                    onChange={(e) => {
                                                        setEditOnboarding({ ...editOnboarding, email: e.target.value });
                                                        emailValid(e.target.value).then(res => {
                                                            if (!res) {
                                                                setVald({
                                                                    ...vald1, email1: false
                                                                })
                                                            } else {
                                                                setVald({
                                                                    ...vald1, email1: true
                                                                })
                                                            }
                                                        });
                                                    }} />
                                                <div class={vald1.email1 == false ? "invalid-data" : 'd-none'}>
                                                    Enter the correct Email Formate.
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                <label htmlFor="Phone">Phone</label>
                                                <input className="form-control form-control-sm" id="Phone" type='number' value={editOnboarding.phone}
                                                    onChange={(e) => setEditOnboarding({ ...editOnboarding, phone: e.target.value })} />
                                                <div class={vald1.phone == false ? "invalid-data" : 'd-none'}>
                                                    Please Enter Phone Name.
                                                </div>
                                            </div>

                                            {/* <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                    <label htmlFor="aboutus">Where did you hear about us?</label>
                                    <select class="form-select form-select-sm" id="aboutus">
                                        <option value="" selected hidden></option>
                                        <option disabled>--Please-select--</option>
                                        <option value="Google" >Google</option>
                                        <option value="Events" >Events</option>
                                        <option value="Conferance" >Conferance</option>
                                        <option value="Blog" >Blog</option>
                                        <option value="Referance" >Referance</option>
                                    </select>
                                </div> */}

                                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                <label htmlFor="role">Role</label>
                                                <select class="form-select form-select-{allUdo && allUdo.map((data, index) => {
                                                        return <option key={index} value={data.UDOptionName}> {data.UDOptionName} </option>
                                                    })}sm" aria-label="Default select example" id="role" value={editOnboarding.role}
                                                    onChange={(e) => setEditOnboarding({ ...editOnboarding, role: e.target.value })}>
                                                    <option disabled>--Please-select--</option>
                                                    <option value="Consultant" >Consultant</option>
                                                    <option value="Influencer" >Influencer</option>
                                                    <option value="Decision maker" >Decision maker</option>
                                                    <option value="Approver" >Approver</option>
                                                    <option value="Sponser" >Sponser</option>
                                                </select>
                                            </div>

                                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                <label htmlFor="timeToBuy">Time to Buy</label>
                                                <select class="form-select form-select-sm" id="timeToBuy" value={editOnboarding.timeToBuy}
                                                    onChange={(e) => setEditOnboarding({ ...editOnboarding, timeToBuy: e.target.value })}>
                                                    <option disabled>--Please-select--</option>
                                                    <option value="Immediately" >Immediately</option>
                                                    <option value="Within 30 days" >Within 30 days</option>
                                                    <option value="Within 60 days" >Within 60 days</option>
                                                    <option value="Within 90 days" >Within 90 days</option>
                                                    <option value="Just enquiring" >Just Enquiring</option>
                                                </select>
                                            </div>

                                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                <label htmlFor="firstName">Source</label>
                                                <select class="form-select form-select-sm" aria-label="Default select example" value={editOnboarding.sourcedBy}
                                                    onChange={(e) => setEditOnboarding({ ...editOnboarding, sourcedBy: e.target.value })}>
                                                    <option disabled>--Please-select--</option>
                                                    <option value="Advertisement" >Advertisement</option>
                                                    <option value="Cold Call" >Cold Call</option>
                                                    <option value="Employee Referal" >Employee Referal</option>
                                                    <option value="External Referal" >External Referal</option>
                                                    <option value="Online" >Online</option>
                                                    <option value="Partner" >Partner</option>
                                                    <option value="Public Relations" >Public Relations</option>
                                                    <option value="Sales Mail" >Sales Mail</option>
                                                    <option value="Seminar Partner" >Seminar Partner</option>
                                                    <option value="Seminar Internal" >Seminar Internal</option>
                                                    <option value="Trade Show" >Trade Show</option>
                                                    <option value="Database" >Database</option>
                                                </select>
                                            </div>

                                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                <label htmlFor="firstName">Status</label>
                                                <select class="form-select form-select-sm" aria-label="Default select example" value={editOnboarding.status}
                                                    onChange={(e) => setEditOnboarding({ ...editOnboarding, status: e.target.value })}>
                                                    <option disabled>--Please-select--</option>
                                                    {allUdo && allUdo.map((data, index) => {
                                                        return <option key={index} value={data.UDOptionName}> {data.UDOptionName} </option>
                                                    })}
                                                </select>
                                            </div>

                                            <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2" hidden={editOnboarding.sourcedBy == 'Partner' ? false : true}>
                                                <label htmlFor="partner">Partner</label>
                                                <select class="form-select form-select-sm" aria-label="Default select example" value={editOnboarding.partnerID}
                                                    onChange={(e) => setEditOnboarding({ ...editOnboarding, partnerID: e.target.value })}>
                                                    <option value="0">--Please-select--</option>
                                                    {allPartners && allPartners.map((data, index) => {
                                                        return <option key={index} value={data.partnerInformationID}> {data.partnerName} </option>
                                                    })}
                                                </select>
                                            </div>

                                            <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                                <label htmlFor="feedback">Feedback</label>
                                                <textarea className="form-control form-select-sm" id="feedback" value={editOnboarding.feedback}
                                                    onChange={(e) => setEditOnboarding({ ...editOnboarding, feedback: e.target.value })} />
                                            </div>
                                            <div>
                                            </div>

                                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <Buttons /> */}
                            </div>
                            :
                            current == 1 ?
                                <div className="container-form mx-3">
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <div className="row">
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                                                    <label htmlFor="Account-Name">Company Name</label>
                                                    <input className="form-control form-control-sm" id="Account-Name" type="text" value={editOnboarding.companyName}
                                                        onChange={(e) => setEditOnboarding({ ...editOnboarding, companyName: e.target.value })} />
                                                    <div class={vald1.companyName == false ? "invalid-data" : 'd-none'}>
                                                        Please Enter Company Name.
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                    <label htmlFor="firstName">Parent Account</label>
                                                    <select class="form-select form-select-sm" aria-label="Default select example" value={editOnboarding.parentID}
                                                        onChange={(e) => setEditOnboarding({ ...editOnboarding, parentID: parseInt(e.target.value) })}>
                                                        <option value="0" selected>--Please-select--</option>
                                                        {customers && customers.map((data, index) => {
                                                            return <option key={index} value={data.entityID}> {data.entityName} </option>
                                                        })}
                                                    </select>
                                                </div>

                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                    <label htmlFor="firstName">Ownership</label>
                                                    <select class="form-select form-select-sm" aria-label="Default select example" value={editOnboarding.ownership}
                                                        onChange={(e) => setEditOnboarding({ ...editOnboarding, ownership: parseInt(e.target.value) })}>
                                                        <option value="" selected disabled>--Please-select--</option>
                                                        {/* <option value="Government">Government</option>
                                                        <option value="Public">Public</option>
                                                        <option value="Private">Private</option>
                                                        <option value="Partnership">Partnership</option>
                                                        <option value="Proprietary">Proprietary</option>
                                                        <option value="Subsidiary">Subsidiary</option> */}
                                                        {allUdoOwnership && allUdoOwnership.map((data, index) => {
                                                            return <option key={index} value={data.UDOptionID}> {data.UDOptionName} </option>
                                                        })}
                                                    </select>
                                                </div>

                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                    <label htmlFor="firstName">Industry</label>
                                                    <select class="form-select form-select-sm" aria-label="Default select example" value={editOnboarding.industry}
                                                        onChange={(e) => setEditOnboarding({ ...editOnboarding, industry: parseInt(e.target.value) })}>
                                                        <option value="" selected disabled>--Please-select--</option>
                                                        {allUdoIndustry && allUdoIndustry.map((data, index) => {
                                                            return <option key={index} value={data.UDOptionID}> {data.UDOptionName} </option>
                                                        })}
                                                        {/* <option value="Education">Education</option>
                                                        <option value="Engineering">Engineering</option>
                                                        <option value="Facilities Management">Facilities Management</option>
                                                        <option value="Finance">Finance</option>
                                                        <option value="FMCG">FMCG</option>
                                                        <option value="Government">Government</option>
                                                        <option value="Healthcare">Healthcare</option>
                                                        <option value="Hospitality /Travel">Hospitality /Travel</option>
                                                        <option value="Infrastructure">Infrastructure</option>
                                                        <option value="Large Corporations">Large Corporations</option>
                                                        <option value="Logistics">Logistics</option>
                                                        <option value="Manufacturing">Manufacturing</option>
                                                        <option value="Media / Advertising / PR">Media / Advertising / PR</option>
                                                        <option value="Professional Services">Professional Services</option>
                                                        <option value="Real Estate">Real Estate</option>
                                                        <option value="Services">Services</option>
                                                        <option value="Small/Medium Enterprise">Small/Medium Enterprise</option>
                                                        <option value="Software">Software</option>
                                                        <option value="Telecom">Telecom</option> */}
                                                    </select>
                                                </div>

                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                    <label htmlFor="Account-Name">Website</label>
                                                    <input className="form-control form-control-sm" id="Account-Name" type="text" value={editOnboarding.officialWebSite}
                                                        onChange={(e) => setEditOnboarding({ ...editOnboarding, officialWebSite: e.target.value })} />
                                                </div>

                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                    <label htmlFor="firstName">No Of Employees</label>
                                                    <input className="form-control form-control-sm" id="firstName" type="text" value={editOnboarding.noOfEmployees}
                                                        onChange={(e) => setEditOnboarding({ ...editOnboarding, noOfEmployees: e.target.value })} />
                                                </div>

                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                    <label htmlFor="firstName">Annual Revenue</label>
                                                    <input className="form-control form-control-sm" id="firstName" type="text" value={editOnboarding.annualRevenue}
                                                        onChange={(e) => setEditOnboarding({ ...editOnboarding, annualRevenue: e.target.value })} />
                                                </div>

                                                <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                                    <label htmlFor="firstName">Additional Details</label>
                                                    <textarea className="form-control" id="firstName" value={editOnboarding.additionalDetails}
                                                        onChange={(e) => setEditOnboarding({ ...editOnboarding, additionalDetails: e.target.value })} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6'>
                                            <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12  mt-2'>
                                                <label htmlFor="address1" >Address Line 1</label>
                                                <textarea className="form-control form-control-sm " id='address1' rows="1"
                                                    value={editOnboarding?.addressLine1}
                                                    onChange={(e) => setEditOnboarding({ ...editOnboarding, addressLine1: e.target.value })}
                                                />
                                            </div>

                                            <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12  mt-2'>
                                                <label htmlFor="address2" >Address Line 2</label>
                                                <textarea className="form-control form-control-sm " id='address2' rows="1"
                                                    value={editOnboarding?.addressLine2}
                                                    onChange={(e) => setEditOnboarding({ ...editOnboarding, addressLine2: e.target.value })}
                                                />
                                            </div>

                                            <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2'>
                                                <div className='row'>
                                                    <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6'>
                                                        <label htmlFor="state" >State</label>
                                                        <input className="form-control form-control-sm " type="text" id='state'
                                                            value={editOnboarding?.state}
                                                            onChange={(e) => setEditOnboarding({ ...editOnboarding, state: e.target.value })}
                                                        />
                                                    </div>

                                                    <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6'>
                                                        <label htmlFor="zip" >Zip</label>
                                                        <input className="form-control form-control-sm " type='tel' id='zip'
                                                        // value={editOnboarding?.state}
                                                        // onChange={(e) => setEditOnboarding({ ...editOnboarding, state: e.target.value })}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2'>
                                                <label htmlFor="state" >City</label>
                                                <input className="form-control form-control-sm " type="text" id='city'
                                                    value={editOnboarding?.city}
                                                    onChange={(e) => setEditOnboarding({ ...editOnboarding, city: e.target.value })}
                                                />
                                            </div>

                                            <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2'>
                                                <label htmlFor="country" >Country</label>
                                                <select className="form-select form-select-sm"
                                                    value={editOnboarding.country ? editOnboarding.country : ''}
                                                    onChange={(e) => setEditOnboarding({ ...editOnboarding, country: e.target.value })}
                                                >
                                                    <option value='' selected disabled> -- Please Select -- </option>
                                                    {allCountries.map((country, index) => {
                                                        return <option key={index} value={country.countryID}> {country.countryName} </option>
                                                    })}
                                                </select>
                                                <div class={vald1.country == false ? "invalid-data" : 'd-none'}>
                                                    Please Choose The Country.
                                                </div>
                                            </div>
                                        </div>

                                        {/* <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-2">
                            <div className="row">
                                <div className="col-6 col-sm-6 col-lg-6 col-xl-6 col-md-6">
                                    <label htmlFor="firstName">City</label>
                                    <select class="form-select mt-1" aria-label="Default select example" placeholder="">
                                        <option value="" selected hidden></option>
                                        <option value="" >--Please-select--</option>
                                        {allCities && allCities.map((data,index) => {
                                            return <option key={index} value={data.cityName}> {data.cityName} </option> 
                                        })}
                                    </select>
                                </div>

                                <div className="col-6 col-sm-6 col-lg-6 col-xl-6 col-md-6">
                                    <label htmlFor="firstName">State</label>
                                    <select class="form-select mt-1" aria-label="Default select example" placeholder="">
                                        <option value="" selected hidden></option>
                                        <option value="" >--Please-select--</option>
                                        {allStates && allStates.map((data,index) => {
                                            return <option key={index} value={data.stateName}> {data.stateName} </option> 
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div> */}



                                        {/* <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-2">
                            <div className="row">
                                <div className="col-6 col-sm-6 col-lg-6 col-xl-6 col-md-6">
                                    <label htmlFor="firstName">Country</label>
                                    <select class="form-select mt-1" aria-label="Default select example">
                                        <option value="" selected hidden></option>
                                        <option value="" >--Please-select--</option>
                                        {allCountries && allCountries.map((data,index) => {
                                            return <option key={index} value={data.countryName}> {data.countryName} </option> 
                                        })}
                                    </select>
                                </div>

                                <div className="col-6 col-sm-6 col-lg-6 col-xl-6 col-md-6">
                                    <label htmlFor="firstName">Pin Code</label>
                                    <select class="form-select mt-1" aria-label="Default select example">
                                        <option value="" selected hidden></option>
                                        <option value="" >--Please-select--</option>
                                        {allPinCode && allPinCode.map((data,index) => {
                                            return <option key={index} value={data.zipcode}> {data.zipcode} </option> 
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div> */}
                                    </div>
                                    {/* <Buttons /> */}
                                </div>
                                : current == 2 ?
                                    <div className="container-form mx-3">
                                        <div className="row">
                                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                <div className="row">
                                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                        <label htmlFor="Account-Name">Company identification number (CIN)</label>
                                                        <input className="form-control form-control-sm" id="Account-Name" type="text" value={editOnboarding?.cin}
                                                            onChange={(e) => setEditOnboarding({ ...editOnboarding, cin: e.target.value })} />
                                                    </div>

                                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">

                                                    </div>

                                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                        <label htmlFor="pan">Business PAN (Permanent Account Number)</label>
                                                        <input className="form-control form-control-sm" id="pan" type="text" value={editOnboarding?.pan}
                                                            onChange={(e) => setEditOnboarding({ ...editOnboarding, pan: e.target.value })} />
                                                    </div>

                                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">

                                                    </div>

                                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                        <label htmlFor="gst">Goods & Services Tax (GST) Identification number</label>
                                                        <input className="form-control form-control-sm" id="gst" type="text" value={editOnboarding.gst || ''}
                                                            onChange={(e) => setEditOnboarding({ ...editOnboarding, gst: e.target.value })} />
                                                    </div>

                                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">

                                                    </div>

                                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                        <label htmlFor="tan">Tax deduction account number (TAN)</label>
                                                        <input className="form-control form-control-sm" id="tan" type="text" value={editOnboarding.tan || ''}
                                                            onChange={(e) => setEditOnboarding({ ...editOnboarding, tan: e.target.value })} />
                                                    </div>

                                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">

                                                    </div>

                                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                        <label htmlFor="tan">Billing Currency</label>
                                                        <select className="form-select form-select-sm"
                                                            value={editOnboarding.currency}
                                                            onChange={(e) => setEditOnboarding({ ...editOnboarding, currency: e.target.value })}
                                                        >
                                                            <option value="0" selected>--Please-select--</option>
                                                            {currencyData && currencyData.map((data, index) => {
                                                                return <option key={index} value={data.currency.currencyID}> {data.currency.currencyName} </option>
                                                            })}
                                                        </select>
                                                        {/* <input className="form-control form-control-sm" id="tan" type="text"
                                                            value={editOnboarding.tan || ''}
                                                            onChange={(e) => setEditOnboarding({ ...editOnboarding, tan: e.target.value })} /> */}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">

                                            </div>

                                            {/* <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-2">
                            <div className="row">
                                <div className="col-6 col-sm-6 col-lg-6 col-xl-6 col-md-6">
                                    <label htmlFor="firstName">City</label>
                                    <select class="form-select mt-1" aria-label="Default select example" placeholder="">
                                        <option value="" selected hidden></option>
                                        <option value="" >--Please-select--</option>
                                        {allCities && allCities.map((data,index) => {
                                            return <option key={index} value={data.cityName}> {data.cityName} </option> 
                                        })}
                                    </select>
                                </div>

                                <div className="col-6 col-sm-6 col-lg-6 col-xl-6 col-md-6">
                                    <label htmlFor="firstName">State</label>
                                    <select class="form-select mt-1" aria-label="Default select example" placeholder="">
                                        <option value="" selected hidden></option>
                                        <option value="" >--Please-select--</option>
                                        {allStates && allStates.map((data,index) => {
                                            return <option key={index} value={data.stateName}> {data.stateName} </option> 
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div> */}



                                            {/* <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-2">
                            <div className="row">
                                <div className="col-6 col-sm-6 col-lg-6 col-xl-6 col-md-6">
                                    <label htmlFor="firstName">Country</label>
                                    <select class="form-select mt-1" aria-label="Default select example">
                                        <option value="" selected hidden></option>
                                        <option value="" >--Please-select--</option>
                                        {allCountries && allCountries.map((data,index) => {
                                            return <option key={index} value={data.countryName}> {data.countryName} </option> 
                                        })}
                                    </select>
                                </div>

                                <div className="col-6 col-sm-6 col-lg-6 col-xl-6 col-md-6">
                                    <label htmlFor="firstName">Pin Code</label>
                                    <select class="form-select mt-1" aria-label="Default select example">
                                        <option value="" selected hidden></option>
                                        <option value="" >--Please-select--</option>
                                        {allPinCode && allPinCode.map((data,index) => {
                                            return <option key={index} value={data.zipcode}> {data.zipcode} </option> 
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div> */}
                                        </div>
                                        {/* <Buttons /> */}
                                    </div>
                                    : current == 3 ?
                                        <div className="container-form enquiry-contacts mx-3">
                                            <div className="mt-3">
                                                <div className="row">
                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-2">
                                                        <div className="d-flex justify-content-between align-content-center">
                                                            <label className="contacts1 contacts">Technical Contact <span style={{ color: 'red' }}> * </span></label>
                                                            <div className="d-flex align-items-center copy-content">
                                                                <span style={{ fontSize: '11px' }} className="text-primary">Copy to All</span>
                                                                <input type="checkbox" className="mx-1 chx form-check-input" checked={copyTo}
                                                                    onChange={(e) => {
                                                                        setCopyTo(e.target.checked);
                                                                        let vals = editOnboarding.technicalContact;
                                                                        if (e.target.checked == true) {
                                                                            setEditOnboarding({
                                                                                ...editOnboarding, administrativeContact: {
                                                                                    ...editOnboarding.administrativeContact, firstName: editOnboarding.technicalContact.firstName,
                                                                                    lastName: editOnboarding.technicalContact.lastName, designation: editOnboarding.technicalContact.designation, department: editOnboarding.technicalContact.department,
                                                                                    phoneNo: editOnboarding.technicalContact.phoneNo, emailID: editOnboarding.technicalContact.emailID
                                                                                }, billingContact: {
                                                                                    ...editOnboarding.billingContact, firstName: editOnboarding.technicalContact.firstName,
                                                                                    lastName: editOnboarding.technicalContact.lastName, designation: editOnboarding.technicalContact.designation, department: editOnboarding.technicalContact.department,
                                                                                    phoneNo: editOnboarding.technicalContact.phoneNo, emailID: editOnboarding.technicalContact.emailID
                                                                                }

                                                                            })

                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                                                <label htmlFor="firstName">First Name</label>
                                                                <input className="form-control form-control-sm" id="firstName" type="text" value={editOnboarding?.technicalContact?.firstName}
                                                                    onChange={(e) => {
                                                                        if (copyTo == true) {
                                                                            setEditOnboarding({ ...editOnboarding, technicalContact: { ...editOnboarding.technicalContact, firstName: e.target.value }, administrativeContact: { ...editOnboarding.administrativeContact, firstName: e.target.value }, billingContact: { ...editOnboarding.billingContact, firstName: e.target.value } })
                                                                        } else {
                                                                            setEditOnboarding({ ...editOnboarding, technicalContact: { ...editOnboarding.technicalContact, firstName: e.target.value } })
                                                                        }
                                                                    }
                                                                    } />
                                                                <div className={vald1.tcFirstName == false ? "invalid-data" : 'd-none'}>
                                                                    Please Enter First Name.
                                                                </div>
                                                            </div>

                                                            <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                                                <label htmlFor="lastName">Last Name</label>
                                                                <input className="form-control form-control-sm " id="lastName" type="text" value={editOnboarding?.technicalContact?.lastName}
                                                                    onChange={(e) => {
                                                                        if (copyTo == true) {
                                                                            setEditOnboarding({ ...editOnboarding, technicalContact: { ...editOnboarding.technicalContact, lastName: e.target.value }, administrativeContact: { ...editOnboarding.administrativeContact, lastName: e.target.value }, billingContact: { ...editOnboarding.billingContact, lastName: e.target.value } })
                                                                        } else {
                                                                            setEditOnboarding({ ...editOnboarding, technicalContact: { ...editOnboarding.technicalContact, lastName: e.target.value } })
                                                                        }
                                                                    }
                                                                    } />
                                                                <div className={vald1.tcLastName == false ? "invalid-data" : 'd-none'}>
                                                                    Please Enter Last Name.
                                                                </div>
                                                            </div>

                                                            <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                                                <label htmlFor="firstName">Designation</label>
                                                                <input className="form-control form-control-sm" id="firstName" type="text" value={editOnboarding?.technicalContact?.designation}
                                                                    onChange={(e) => {
                                                                        if (copyTo == true) {
                                                                            setEditOnboarding({ ...editOnboarding, technicalContact: { ...editOnboarding.technicalContact, designation: e.target.value }, administrativeContact: { ...editOnboarding.administrativeContact, designation: e.target.value }, billingContact: { ...editOnboarding.billingContact, designation: e.target.value } })
                                                                        } else {
                                                                            setEditOnboarding({ ...editOnboarding, technicalContact: { ...editOnboarding.technicalContact, designation: e.target.value } })
                                                                        }
                                                                    }
                                                                    } />
                                                                <div className={vald1.tcDesignation == false ? "invalid-data" : 'd-none'}>
                                                                    Please Enter Designation.
                                                                </div>
                                                            </div>

                                                            <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                                                <label htmlFor="firstName">Department</label>
                                                                <input className="form-control form-control-sm" id="firstName" type="text" value={editOnboarding?.technicalContact?.department}
                                                                    onChange={(e) => {
                                                                        if (copyTo == true) {
                                                                            setEditOnboarding({ ...editOnboarding, technicalContact: { ...editOnboarding.technicalContact, department: e.target.value }, administrativeContact: { ...editOnboarding.administrativeContact, department: e.target.value }, billingContact: { ...editOnboarding.billingContact, department: e.target.value } })
                                                                        } else {
                                                                            setEditOnboarding({ ...editOnboarding, technicalContact: { ...editOnboarding.technicalContact, department: e.target.value } })
                                                                        }
                                                                    }
                                                                    } />
                                                                <div className={vald1.tcDepartment == false ? "invalid-data" : 'd-none'}>
                                                                    Please Enter Department.
                                                                </div>
                                                            </div>

                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-12 col-md-12 mt-2">
                                                                <label htmlFor="firstName">Phone</label>
                                                                <input className="form-control form-control-sm" id="firstName" type='number' value={editOnboarding?.technicalContact?.phoneNo}
                                                                    onChange={(e) => {
                                                                        if (copyTo == true) {
                                                                            setEditOnboarding({ ...editOnboarding, technicalContact: { ...editOnboarding.technicalContact, phoneNo: e.target.value }, administrativeContact: { ...editOnboarding.administrativeContact, phoneNo: e.target.value }, billingContact: { ...editOnboarding.billingContact, phoneNo: e.target.value } })
                                                                        } else {
                                                                            setEditOnboarding({ ...editOnboarding, technicalContact: { ...editOnboarding.technicalContact, phoneNo: e.target.value } })
                                                                        }
                                                                    }
                                                                    } />
                                                                <div className={vald1.tcPhone == false ? "invalid-data" : 'd-none'}>
                                                                    Please Enter Phone.
                                                                </div>
                                                            </div>

                                                            <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                                                <label htmlFor="firstName">Email</label>
                                                                <input className="form-control form-control-sm" id="firstName" type="text" value={editOnboarding?.technicalContact?.emailID}
                                                                    onChange={(e) => {
                                                                        if (copyTo == true) {
                                                                            setEditOnboarding({ ...editOnboarding, technicalContact: { ...editOnboarding.technicalContact, emailID: e.target.value }, administrativeContact: { ...editOnboarding.administrativeContact, emailID: e.target.value }, billingContact: { ...editOnboarding.billingContact, emailID: e.target.value } })
                                                                        } else {
                                                                            setEditOnboarding({ ...editOnboarding, technicalContact: { ...editOnboarding.technicalContact, emailID: e.target.value } })
                                                                        }
                                                                    }
                                                                    } />
                                                                <div className={vald1.tcEmail == false ? "invalid-data" : 'd-none'}>
                                                                    Please Enter Email.
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-2">
                                                        <div className="d-flex justify-content-between align-content-center">
                                                            <label className="contacts1 contacts">Administrative Contact</label>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                                                <label htmlFor="firstName">First Name</label>
                                                                <input className="form-control form-control-sm" id="firstName" type="text" value={editOnboarding?.administrativeContact?.firstName}
                                                                    onChange={(e) => setEditOnboarding({ ...editOnboarding, administrativeContact: { ...editOnboarding.administrativeContact, firstName: e.target.value } })} />
                                                                <div className={vald1.acFirstName == false ? "invalid-data" : 'd-none'}>
                                                                    Please Enter First Name.
                                                                </div>
                                                            </div>

                                                            <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                                                <label htmlFor="lastName">Last Name</label>
                                                                <input className="form-control form-control-sm " id="lastName" type="text" value={editOnboarding?.administrativeContact?.lastName}
                                                                    onChange={(e) => setEditOnboarding({ ...editOnboarding, administrativeContact: { ...editOnboarding.administrativeContact, lastName: e.target.value } })} />
                                                                <div className={vald1.acLastName == false ? "invalid-data" : 'd-none'}>
                                                                    Please Enter Last Name.
                                                                </div>
                                                            </div>

                                                            <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                                                <label htmlFor="firstName">Designation</label>
                                                                <input className="form-control form-control-sm" id="firstName" type="text" value={editOnboarding?.administrativeContact?.designation}
                                                                    onChange={(e) => setEditOnboarding({ ...editOnboarding, administrativeContact: { ...editOnboarding.administrativeContact, designation: e.target.value } })} />
                                                                <div className={vald1.acDesignation == false ? "invalid-data" : 'd-none'}>
                                                                    Please Enter Designation.
                                                                </div>
                                                            </div>

                                                            <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                                                <label htmlFor="firstName">Department</label>
                                                                <input className="form-control form-control-sm" id="firstName" type="text" value={editOnboarding?.administrativeContact?.department}
                                                                    onChange={(e) => setEditOnboarding({ ...editOnboarding, administrativeContact: { ...editOnboarding.administrativeContact, department: e.target.value } })} />
                                                                <div className={vald1.tcDepartment == false ? "invalid-data" : 'd-none'}>
                                                                    Please Enter Department.
                                                                </div>
                                                            </div>

                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-12 col-md-12 mt-2">
                                                                <label htmlFor="firstName">Phone</label>
                                                                <input className="form-control form-control-sm" id="firstName" type="number" value={editOnboarding?.administrativeContact?.phoneNo}
                                                                    onChange={(e) => setEditOnboarding({ ...editOnboarding, administrativeContact: { ...editOnboarding.administrativeContact, phoneNo: e.target.value } })} />
                                                                <div className={vald1.tcPhone == false ? "invalid-data" : 'd-none'}>
                                                                    Please Enter phoneNo.
                                                                </div>
                                                            </div>

                                                            <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                                                <label htmlFor="firstName">Email</label>
                                                                <input className="form-control form-control-sm" id="firstName" type="text" value={editOnboarding?.administrativeContact?.emailID}
                                                                    onChange={(e) => setEditOnboarding({ ...editOnboarding, administrativeContact: { ...editOnboarding.administrativeContact, emailID: e.target.value } })} />
                                                                <div className={vald1.acEmail == false ? "invalid-data" : 'd-none'}>
                                                                    Please Enter Email.
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-2">
                                                        <label className="contacts1 contacts">Billing Contact</label>
                                                        <div className="row">
                                                            <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                                                <label htmlFor="firstName">First Name</label>
                                                                <input className="form-control form-control-sm" id="firstName" type="text" value={editOnboarding?.billingContact?.firstName}
                                                                    onChange={(e) => setEditOnboarding({ ...editOnboarding, billingContact: { ...editOnboarding.billingContact, firstName: e.target.value } })} />
                                                                <div className={vald1.bcFirstName == false ? "invalid-data" : 'd-none'}>
                                                                    Please Enter First Name.
                                                                </div>
                                                            </div>

                                                            <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                                                <label htmlFor="lastName">Last Name</label>
                                                                <input className="form-control form-control-sm " id="lastName" type="text" value={editOnboarding?.billingContact?.lastName}
                                                                    onChange={(e) => setEditOnboarding({ ...editOnboarding, billingContact: { ...editOnboarding.billingContact, lastName: e.target.value } })} />
                                                                <div className={vald1.bcLastName == false ? "invalid-data" : 'd-none'}>
                                                                    Please Enter Last Name.
                                                                </div>
                                                            </div>

                                                            <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                                                <label htmlFor="firstName">Designation</label>
                                                                <input className="form-control form-control-sm" id="firstName" type="text" value={editOnboarding?.billingContact?.designation}
                                                                    onChange={(e) => setEditOnboarding({ ...editOnboarding, billingContact: { ...editOnboarding.billingContact, designation: e.target.value } })} />
                                                                <div className={vald1.bcDesignation == false ? "invalid-data" : 'd-none'}>
                                                                    Please Enter Designation.
                                                                </div>
                                                            </div>

                                                            <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                                                <label htmlFor="firstName">Department</label>
                                                                <input className="form-control form-control-sm" id="firstName" type="text" value={editOnboarding?.billingContact?.department}
                                                                    onChange={(e) => setEditOnboarding({ ...editOnboarding, billingContact: { ...editOnboarding.billingContact, department: e.target.value } })} />
                                                                <div className={vald1.bcDepartment == false ? "invalid-data" : 'd-none'}>
                                                                    Please Enter Department.
                                                                </div>
                                                            </div>

                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-12 col-md-12 mt-2">
                                                                <label htmlFor="firstName">Phone</label>
                                                                <input className="form-control form-control-sm" id="firstName" type="number" value={editOnboarding?.billingContact?.phoneNo}
                                                                    onChange={(e) => setEditOnboarding({ ...editOnboarding, billingContact: { ...editOnboarding.billingContact, phoneNo: e.target.value } })} />
                                                                <div className={vald1.bcPhone == false ? "invalid-data" : 'd-none'}>
                                                                    Please Enter Phone.
                                                                </div>
                                                            </div>

                                                            <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                                                <label htmlFor="firstName">Email</label>
                                                                <input className="form-control form-control-sm" id="firstName" type="text" value={editOnboarding?.billingContact?.emailID}
                                                                    onChange={(e) => setEditOnboarding({ ...editOnboarding, billingContact: { ...editOnboarding.billingContact, emailID: e.target.value } })} />
                                                                <div className={vald1.bcEmail == false ? "invalid-data" : 'd-none'}>
                                                                    Please Enter Email.
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* buttons */}
                                            {/* <Buttons /> */}
                                        </div> :
                                        <div className="container-form enquiry-contacts mx-3">
                                            <div className="mt-3">
                                                <div className="row">
                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-2">
                                                        <div className="row">
                                                            <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                                                <label htmlFor="firstName">Agreement</label>
                                                                <input className="form-control form-control-sm" id="firstName" type="file"
                                                                    onChange={(e) => {
                                                                        let img = e.target.files[0];
                                                                        const fileReader = new FileReader();
                                                                        fileReader.readAsDataURL(img)
                                                                        fileReader.onload = async () => {
                                                                            setEditOnboarding({ ...editOnboarding, entityBoarding: { ...editOnboarding.entityBoarding, agreement: fileReader.result } })
                                                                            return fileReader.result
                                                                        }
                                                                        fileReader.onerror = async () => {
                                                                            return false
                                                                        }
                                                                    }}
                                                                />
                                                            </div>

                                                            <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                                                <label htmlFor="firstName">NCNDA</label>
                                                                <input className="form-control form-control-sm" id="firstName" type="file"
                                                                    onChange={(e) => {
                                                                        let img = e.target.files[0];
                                                                        const fileReader = new FileReader();
                                                                        fileReader.readAsDataURL(img)
                                                                        fileReader.onload = async () => {
                                                                            setEditOnboarding({ ...editOnboarding, entityBoarding: { ...editOnboarding.entityBoarding, ncnda: fileReader.result } })
                                                                            return fileReader.result
                                                                        }
                                                                        fileReader.onerror = async () => {
                                                                            return false
                                                                        }
                                                                    }}
                                                                />
                                                            </div>

                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-12 col-md-6 mt-2">
                                                                <label htmlFor="firstName">Terms of use</label>
                                                                <input className="form-control form-control-sm" id="firstName" type="file"
                                                                    onChange={(e) => {
                                                                        let img = e.target.files[0];
                                                                        const fileReader = new FileReader();
                                                                        fileReader.readAsDataURL(img)
                                                                        fileReader.onload = async () => {
                                                                            setEditOnboarding({ ...editOnboarding, entityBoarding: { ...editOnboarding.entityBoarding, termsofuse: fileReader.result } })
                                                                            return fileReader.result
                                                                        }
                                                                        fileReader.onerror = async () => {
                                                                            return false
                                                                        }
                                                                    }}
                                                                />
                                                            </div>

                                                            <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                                                <label className="contacts1 contacts">Customer Type</label>
                                                                <select class="form-select form-select-sm" aria-label="Default select example" value={editOnboarding.entityBoarding && editOnboarding.entityBoarding.customerType ? editOnboarding.entityBoarding.customerType : ''}
                                                                    onChange={(e) => setEditOnboarding({ ...editOnboarding, entityBoarding: { ...editOnboarding.entityBoarding, customerType: e.target.value } })}>
                                                                    <option value="" selected hidden></option>
                                                                    <option disabled>--Please-select--</option>
                                                                    <option value="Paid Customer" >Paid Customer</option>
                                                                    <option value="Trail Access" >Trail Access</option>
                                                                    <option value="Demo Access" > Demo Access </option>
                                                                </select>
                                                                <div className={vald1.cType == false ? "invalid-data" : 'd-none'}>
                                                                    Please Chose Customer Type.
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2" hidden={editOnboarding?.entityBoarding?.customerType == 'Trail Access' ? false : true}>
                                                                <label className="contacts1 contacts">Trail Access Period</label>
                                                                <select class="form-select form-select-sm" aria-label="Default select example" value={editOnboarding?.trailAccess}
                                                                    onChange={(e) => setEditOnboarding({ ...editOnboarding, trailAccess: e.target.value })}>
                                                                    <option value="" selected disabled> -- Please Select -- </option>
                                                                    <option value="15"> 15 Days </option>
                                                                    <option value="30"> 30 Days  </option>
                                                                    <option value="45"> 45 Days </option>
                                                                    <option value="60"> 60 Days  </option>
                                                                    <option value="75"> 75 Days </option>
                                                                    <option value="90"> 90 Days  </option>
                                                                </select>
                                                                <div className={vald1.trailAccess == false ? "invalid-data" : 'd-none'}>
                                                                    Please Choose Trail Access Period.
                                                                </div>
                                                            </div>

                                                            <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2" hidden={editOnboarding?.entityBoarding?.customerType == 'Paid Customer' ? false : true}>
                                                                <label className="contacts1 contacts">Billing Template</label>
                                                                <select class="form-select form-select-sm" aria-label="Default select example" value={editOnboarding?.billingTemplateId}
                                                                    onChange={(e) => setEditOnboarding({ ...editOnboarding, billingTemplateId: e.target.value })}>
                                                                    <option value='0' selected> --Please Select --  </option>
                                                                    {billingTemplate.map((data, index) => {
                                                                        return <option key={index} value={data.billingRuleID}> {data.billingTemplateName} </option>
                                                                    })}
                                                                </select>
                                                                <div className={vald1.bTemp == false ? "invalid-data" : 'd-none'}>
                                                                    Please Choose Billing Template.
                                                                </div>
                                                            </div>

                                                            <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2" hidden={editOnboarding?.entityBoarding?.customerType == 'Paid Customer' ? false : true}>
                                                                <label className="contacts1 contacts">Discount Apply</label>
                                                                <select class="form-select form-select-sm" aria-label="Default select example" value={editOnboarding?.isDiscountApply}
                                                                    onChange={(e) => {if (e.target.value == 'true') {
                                                                        setEditOnboarding({ ...editOnboarding, isDiscountApply: true })
                                                                    }
                                                                    else {
                                                                        setEditOnboarding({ ...editOnboarding, isDiscountApply: false })
                                                                    }
                                                                    }}>
                                                                    // <option value="" selected > -- Please Select -- </option>
                                                                    <option value={true}> Yes </option>
                                                                    <option value={false} selected> No  </option>
                                                                </select>
                                                            </div>

                                                            <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2" hidden={editOnboarding?.entityBoarding?.customerType == 'Paid Customer' ? false : true}>
                                                                <label className="contacts1 contacts">Discount</label>
                                                                <input className="form-control form-control-sm" id="discount" type="number" value={editOnboarding?.discount}
                                                                    onChange={(e) => setEditOnboarding({ ...editOnboarding, discount: e.target.value })} /> %
                                                                <div className={vald1.discount == false ? "invalid-data" : 'd-none'}>
                                                                    Enter Discount .
                                                                </div>
                                                            </div>


                                                            <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                                                <Button type="primary" onClick={() => {
                                                                    let obj = {
                                                                        cType: true,
                                                                        bTemp: true,
                                                                        trailAccess: true,
                                                                        discount: true
                                                                    }
                                                                    if (!editOnboarding.entityBoarding) {
                                                                        obj = {
                                                                            cType: false,
                                                                            bTemp: true,
                                                                            trailAccess: true,
                                                                            discount: true
                                                                        };
                                                                        setVald({ ...vald1, ...obj });
                                                                    } else if (!editOnboarding.entityBoarding.customerType || editOnboarding.entityBoarding.customerType == '') {
                                                                        obj = {
                                                                            cType: false,
                                                                            bTemp: true,
                                                                            trailAccess: true,
                                                                            discount: true
                                                                        };
                                                                        setVald({ ...vald1, ...obj });
                                                                    } else if ((editOnboarding.entityBoarding.customerType == "Paid Customer") && (!editOnboarding.billingTemplateId || editOnboarding.billingTemplateId == '0')) {
                                                                        obj = {
                                                                            cType: true,
                                                                            bTemp: false,
                                                                            trailAccess: true,
                                                                            discount: true
                                                                        };
                                                                        setVald({ ...vald1, ...obj });
                                                                    } else if ((editOnboarding.entityBoarding.customerType == "Paid Customer") && editOnboarding.isDiscountApply && (!editOnboarding.discount || editOnboarding.discount == '' || editOnboarding.discount == '0')) {
                                                                        obj = {
                                                                            cType: true,
                                                                            bTemp: true,
                                                                            trailAccess: true,
                                                                            discount: false
                                                                        };
                                                                        setVald({ ...vald1, ...obj });
                                                                    } else if ((editOnboarding.entityBoarding.customerType == "Trail Access") && (!editOnboarding.trailAccess || editOnboarding.trailAccess == '')) {
                                                                        obj = {
                                                                            cType: true,
                                                                            bTemp: true,
                                                                            trailAccess: false,
                                                                            discount: true
                                                                        };
                                                                        setVald({ ...vald1, ...obj });
                                                                    } else {
                                                                        setVald({ ...vald1, ...obj });
                                                                        // alert('Done!')
                                                                        setLoading(true);
                                                                        let saveEditOnboarding = {
                                                                            ...editOnboarding, entityID: editEntityId, enquiryID: editEnquiryCode, entityCode: editEntityCode, isOnboardingCompleted: true, currency: 0, currencyName: ""
                                                                        }
                                                                        ItrApiService.POST({
                                                                            url: 'RevOps/Entity/CustomerOnboarded',
                                                                            data: saveEditOnboarding
                                                                        }).then((res) => {
                                                                            if (res.Success == true) {
                                                                                if (res.result.isSuccess == true) {
                                                                                    message.success('Successfully Updated');
                                                                                    history.push('/customers');
                                                                                }
                                                                            }
                                                                            setLoading(false);
                                                                        })
                                                                    }
                                                                }}>
                                                                    Onboard
                                                                </Button>
                                                            </div>

                                                            {/* <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                                <label htmlFor="firstName">Hosting Type</label>
                                                <select class="form-select form-select-sm" aria-label="Default select example" value={editOnboarding?.entityBoarding?.hostingType}
                                                    onChange={(e) => setEditOnboarding({ ...editOnboarding, entityBoarding: { ...editOnboarding.entityBoarding, hostingType: e.target.value } })}>
                                                    <option value="" selected hidden></option>
                                                    <option disabled>--Please-select--</option>
                                                    <option value="Public Cloud" >Public Cloud</option>
                                                    <option value="Private Cloud" >Private Cloud</option>
                                                </select>
                                            </div> */}
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                            {/* buttons */}
                                            {/* <Buttons /> */}
                                        </div>

                        }

                        <div className="steps-action container" style={{ textAlign: 'right' }}>
                            {current > 0 && (
                                <Button className="mb-5" style={{ margin: '0 8px' }} onClick={() => prev()}>
                                    Previous
                                </Button>
                            )}
                            {current > 0 && (
                                <Button style={{ margin: '0 8px' }} onClick={() => history.push('/customers')}>
                                    Close
                                </Button>
                            )}
                            {current >= 0 && current != 4 && (<Button className="mb-5" style={{ marginLeft: 10 }} type="primary"
                                onClick={() => next()}>
                                {'Next'}
                            </Button>)}


                        </div>
                    </div>
                </div>
                {/* <NxCustomTab
                    data={[
                        {
                            name: "Basic Information",
                            Components: () => {
                                return (
                                    
                                )
                            }
                        },
                        {
                            name: "Company",
                            Components: () => 
                           

                        },
                        {
                            name: "Tax Information",
                            Components: () => 
                            

                        },

                        {
                            name: "Contacts",
                            Components: () => 
                            
                        },
                        {
                            name: "Onboarding",
                            Components: () => 
                            
                        },
                    ]}
                /> */}
            </div>
            <NxFooter className="verscio-footer" companyName="© 2022 Ithred Solutions" ExtraContent={[
                { name: 'Terms of Service' },
                { name: 'Privacy' },
            ]} />
        </div>
    )
}