import { NxFooter, NxTitleTab } from '@afiplfeed/itr-app';
import CustomerSidebar from '../../Screens/CustomerSidebar/CustomerSidebar';
import Headers from '../../Screens/Headers/Headers';
import { useHistory } from 'react-router-dom';
import billingEngine from '../../assets/billingEngine.png';
import gstTax from '../../assets/gst.png';

export default function MarketplaceHome(){
    let history = useHistory();
    return (
        <div className="conatiner dashboard ">
            <Headers />
            <div className='d-flex custom-body-container'>
                <div className='body-sidebar'>
                    <CustomerSidebar visible={true}  />
                </div>
                <div className='body-workspace'>
                    <NxTitleTab className="title-tab" title='Marketplace' onCloseClick={() =>  history.push('/dashboard')} />
                    <div className="mb-5 mt-0 customer-configuration-main">
                        <div className="container-fluid">
                            <div className="CustomerUsersTab">
                                <div className="row cus-configuration-tab">
                                    <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mt-2 mt-4">
                                        <div className="card">
                                            <div className="card-header d-flex align-items-center">
                                                {/* <img src={billingEngine} width="30" /> */}
                                                <h6 className="fw-bold m-1 text-primary">StoreFront</h6>
                                            </div>
                                            <div className="card-body">
                                                <div className="card-details">
                                                    <p>Company setup features including financial details that will be used in the billing, options to add new users to the console system as principals or partners.</p>
                                                </div>
                                                <div className="text-end mt-5">
                                                    <button className="btn mx-1 btn-sm btn-primary border-0 config-buttons"
                                                        onClick={() => {
                                                            sessionStorage.setItem('ike', '1');
                                                            history.push('/marketplace/storefront')
                                                        }}>
                                                        StoreFront
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mt-2 mt-4">
                                        <div className="card">
                                            <div className="card-header d-flex align-items-center">
                                                {/* <img src={billingEngine} width="30" /> */}
                                                <h6 className="fw-bold m-1 text-primary">Subscriptions</h6>
                                            </div>
                                            <div className="card-body">
                                                <div className="card-details">
                                                    <p>Company setup features including financial details that will be used in the billing, options to add new users to the console system as principals or partners.</p>
                                                </div>
                                                <div className="text-end mt-5">
                                                    <button className="btn mx-1 btn-sm btn-primary border-0 config-buttons"
                                                        onClick={() => {
                                                            sessionStorage.setItem('ike', '1');
                                                            history.push('/marketplace/subscriptions')
                                                        }}>
                                                        Subscriptions
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mt-2 mt-4">
                                        <div className="card">
                                            <div className="card-header d-flex align-items-center">
                                                {/* <img src={billingEngine} width="30" /> */}
                                                <h6 className="fw-bold m-1 text-primary">Invoices</h6>
                                            </div>
                                            <div className="card-body">
                                                <div className="card-details">
                                                    <p>Company setup features including financial details that will be used in the billing, options to add new users to the console system as principals or partners.</p>
                                                </div>
                                                <div className="text-end mt-5">
                                                    <button className="btn mx-1 btn-sm btn-primary border-0 config-buttons"
                                                        onClick={() => {
                                                            sessionStorage.setItem('ike', '1');
                                                            history.push('/marketplace/invoices')
                                                        }}>
                                                        Manage Invoices
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mt-2 mt-4">
                                        <div className="card">
                                            <div className="card-header d-flex align-items-center">
                                                {/* <img src={billingEngine} width="30" /> */}
                                                <h6 className="fw-bold m-1 text-primary">Billing</h6>
                                            </div>
                                            <div className="card-body">
                                                <div className="card-details">
                                                    <p>Company setup features including financial details that will be used in the billing, options to add new users to the console system as principals or partners.</p>
                                                </div>
                                                <div className="text-end mt-5">
                                                    <button className="btn mx-1 btn-sm btn-primary border-0 config-buttons"
                                                        onClick={() => {
                                                            sessionStorage.setItem('ike', '1');
                                                            history.push('/marketplace/billing-configuration')
                                                        }}>
                                                        Billing Configuration
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                    
                                </div>
                            </div>
                        </div>
                        <div style={{ height: 50 }} />
                    </div>
                </div>
            </div>
            <NxFooter companyName="© 2023 Altascio Technologies (I) Pvt. Ltd.," ExtraContent={[
                { name: 'Terms of Service' },
                { name: 'Privacy' },
            ]} />
        </div>
    )
}