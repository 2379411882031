import { faInfo, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NxLoader } from '@afiplfeed/itr-app';
import { ItrApiService } from '@afiplfeed/itr-ui';
import { Button, Popover, Progress } from 'antd';
import React, { useEffect, useState } from 'react';
import PricingHistory from './PricingHistory';
import './Style.css'
import VersionHistory from './VersionHistory';

function Versioning({ datas, setdatas, onRetireClick = () => { }, onCloseClick = () => { }, onPublishClick = () => { }, onEditClick = () => { } }) {
    const [show, setShow] = useState(true);
    const [entity, setEntity] = useState({});
    const [vh, setVh] = useState({});
    const [st, setSt] = useState('');

    const [organisation, setOrganisation] = useState({
        Organizations: '',
        Subscriptions: '',
        Assigned: ''
    });
    useEffect(() => {
        setLoader(true);
        // RevOps/StoreConnect/StoreConnectReviewByCode/{serviceCode}
        ItrApiService.GET({
            url: `DevOps/Enrollment/GetByEnrollmentID/${datas.EnrollmentId}`,
        }).then(resp => {
            if (resp.Success == true) {
                if (resp.result.isSuccess == true) {
                    setEntity(resp.result.data);
                    ItrApiService.POST({
                        url: 'Platform/UserDefined/AllUDOs',
                        data: ["APP Type"]
                    }).then(rest => {
                        if (rest.Success == true) {
                            if (rest.result.isSuccess) {
                                if (rest.result.data && rest.result.data.length > 0) {
                                    let arr = [];
                                    arr = rest.result.data[0].Options;
                                    let stype = resp.result.data.serviceType
                                    for (let val of rest.result.data[0].Options) {
                                        if (val.UDOptionID == parseInt(stype)) {
                                            setSt(val.UDOptionName)
                                        }
                                    }
                                }
                            }
                        }
                    })
                }
            }
            ItrApiService.GET({
                url: `RevOps/StoreConnect/StoreConnectReviewByCode/${datas.ServiceCode}`
            }).then(res => {
                if (res.Success == true) {
                    if (res.result.isSuccess == true) {
                        if (res.result.data) {
                            setVh(res.result.data);
                        }
                    }

                }
                setLoader(false)
            })


            // dashboard-count
            ItrApiService.GET({
                url: `RevOps/StoreConnect/GetStoreConnectAppDashBoard/${datas.ServiceCode}`
            }).then(res => {
                if (res.Success == true) {
                    if (res.result.isSuccess == true) {
                        let obj = { ...organisation }
                        for (let data of res.result.data) {
                            if (data.categoryName == 'Organizations') {
                                obj = { ...obj, Organizations: data.categoryCount }
                            }
                            else if (data.categoryName == 'Subscriptions') {
                                obj = { ...obj, Subscriptions: data.categoryCount }
                            }
                            else if (data.categoryName == 'Assigned Users') {
                                obj = { ...obj, Assigned: data.categoryCount }
                            }

                        }
                        setOrganisation(obj)
                    }

                }
                setLoader(false)
            })
        })
    }, [datas]);
    const [loader, setLoader] = useState(false)
    return (
        <div className='p-3 f.top.class'>
            <NxLoader state={loader} />
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12  mt-2">
                <div className='mx-5'>
                    <div className='row'>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4  mt-2">
                            <h5>{entity.serviceName}</h5>
                            <div className='f-virision-pad2'>
                                <img src={entity.appIcon} style={{ height: 100, width: 100 }} />
                            </div>
                            <table className='mt-4'>
                                {/* <tr>
                            <td>
                                <div className='f-virision-pad2'>
                                    <label htmlFor="ServiceName">Service Name </label>
                                </div>
                            </td>
                            <td>
                                <div className='f-virision-pad'>
                                    <label htmlFor="ServiceName">{datas.serviceName}</label>
                                </div>
                            </td>

                            </tr> */}
                                <tr>
                                    <td>
                                        <div className='f-virision-pad2'>
                                            <label className='f-badge-title' htmlFor="ServiceName">Service Type </label>
                                        </div>
                                    </td>
                                    <td>
                                        <div className='f-virision-pad'>
                                            <label className='f-badge-value' htmlFor="ServiceName">{st}</label>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='f-virision-pad2'>
                                            <label className='f-badge-title' htmlFor="ServiceName">Description </label>
                                        </div>
                                    </td>
                                    <td>
                                        <div className='f-virision-pad'>
                                            <label className='f-badge-value' htmlFor="ServiceName">{entity.serviceDescription}</label>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='f-virision-pad2'>
                                            <label className='f-badge-title' htmlFor="ServiceName">Version </label>
                                        </div>
                                    </td>
                                    <td>
                                        <div className='f-virision-pad'>
                                            <label className='f-badge-value' htmlFor="ServiceName">{entity.version}</label>
                                        </div>
                                    </td>

                                </tr>
                                <tr>
                                    <td>
                                        <div className='f-virision-pad2'>
                                            <label className='f-badge-title' htmlFor="ServiceName">Build No </label>
                                        </div>
                                    </td>
                                    <td>
                                        <div className='f-virision-pad'>
                                            <label className='f-badge-value' htmlFor="ServiceName">{entity.buildNo}</label>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='f-virision-pad2'>
                                            <label className='f-badge-title' htmlFor="ServiceName">Developer </label>
                                        </div>
                                    </td>
                                    <td>
                                        <div className='f-virision-pad'>
                                            <label className='f-badge-value' htmlFor="ServiceName">{entity.developerName}</label>
                                        </div>
                                    </td>
                                </tr>
                                {/* <tr>
                                <td>
                                    <div className='f-virision-pad2'>
                                        <label htmlFor="AppStoreVersion">App Store Version</label>
                                    </div>
                                </td>
                                <td>
                                    <div className='f-virision-pad'>
                                        <input className="form-control form-control-sm  mt-1" value={datas.apstoreVersion || ''} id="AppStoreVersion" type="text" disabled={false} onChange={(e) => {
                                            setdatas({ ...datas, apstoreVersion: e.target.value })
                                        }} />
                                    </div>
                                </td>
                                <td>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', paddingLeft: 10 }} className='f-virision-pad'>
                                        <Popover content={<div className='p-2'>Pls enter a unique / incremental version number</div>} >
                                            <FontAwesomeIcon icon={faInfoCircle} size='lg' color='#1890ff' style={{ cursor: 'pointer' }} onClick={() => {

                                            }} />
                                        </Popover>
                                    </div>
                                </td>
                            </tr> */}
                            </table>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 mt-2">
                            <h5>Version History</h5>
                            <VersionHistory vh={vh} />
                            <div className='mt-2'>
                                <h5>Pricing History</h5>
                                <PricingHistory vh={vh} />
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 mt-2">
                            <div className="mt-0">
                                <h5>App Status</h5>
                                <div className="container1">
                                    <div className="nx-body nx-billing">
                                        <div className="row">
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 counts count-two">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <label className="nx-badge-title">Organizations</label>
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 mt-1">
                                                            {datas.StoreStatus == 'Published' ? <h6> {organisation.Organizations} </h6> : <h6>N/A</h6>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 counts count-two">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <label className="nx-badge-title">Subscriptions</label>
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 mt-1">
                                                            {datas.StoreStatus == 'Published' ? <h6> {organisation.Subscriptions} </h6> : <h6>N/A</h6>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 mt-1 counts count-two">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <label className="nx-badge-title">Active Users</label>
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 mt-1">
                                                            {datas.StoreStatus == 'Published' ? <h6> {organisation.Assigned} </h6> : <h6>N/A</h6>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 mt-1 counts count-two">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <label className="nx-badge-title">Last 30 Days Login</label>
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 mt-1">
                                                            {datas.StoreStatus == 'Published' ? <h6>$ 83.40</h6> : <h6>N/A</h6>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 mt-1 counts count-two">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <label className="nx-badge-title">Last 30 Days Sessions</label>
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 mt-1">
                                                            {datas.StoreStatus == 'Published' ? <h6>$ 83.40</h6> : <h6>N/A</h6>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 mt-1 counts count-two">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <label className="nx-badge-title">Last Active Session</label>
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 mt-1">
                                                            {datas.StoreStatus == 'Published' ? <h6>$ 83.40</h6> : <h6>N/A</h6>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Only For Publish */}
                    {datas.StoreStatus == 'Published' ? <div className="steps-action container" style={{ textAlign: 'right' }}>
                        {/* <label style={{ fontSize: 15, marginBottom: 10, marginLeft: 10 }}>This Service has been published, if you wish to retire the service</label><br /> */}
                        <Button style={{ marginLeft: 10 }} type="primary"
                            onClick={onRetireClick}>
                            Retire
                        </Button>
                        <Button style={{ marginLeft: 10 }} type="primary"
                            onClick={onEditClick}>
                            Edit
                        </Button>
                    </div> : <div className="steps-action container" style={{ textAlign: 'right' }}>
                        {/* {datas.serviceName == '' ? <div><label style={{ fontSize: 15, marginBottom: 10, marginLeft: 10 }}>This Service has not been published yet, if you wish to publish the service</label><br /></div> // Available
                            : <div><label style={{ fontSize: 15, marginBottom: 10, marginLeft: 10 }}>This Service has been retired, if you wish to re-publish the service</label><br /></div> // Retired
                        } */}
                        <Button style={{ marginLeft: 10 }} type="primary"
                            onClick={onPublishClick}>
                            Publish
                        </Button>
                    </div>}

                </div>
                {/* <label htmlFor="ServiceName">Service Name : {datas.serviceName} </label>
                <input className="form-control form-control-sm  mt-1 bg-white" value={datas.serviceName} id="ServiceName" type="text" disabled={true} /> */}
            </div>
            {/* <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12  mt-2">
                <label htmlFor="Version">Version: {datas.version}</label>
                <input className="form-control form-control-sm  mt-1 bg-white" value={datas.version} id="Version" type="text" disabled={true} />
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12  mt-2">
                <label htmlFor="BuildNo">Build No: {datas.buildNo}</label>
                <input className="form-control form-control-sm  mt-1 bg-white" value={datas.buildNo} id="BuildNo" type="text" disabled={true} />
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12  mt-2">
                <label htmlFor="Developer">Developer: {datas.developerName}</label>
                <input className="form-control form-control-sm  mt-1 bg-white" value={datas.developerName} id="Developer" type="text" disabled={true} />
            </div> */}
            {/* <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12  mt-2 f-virision-pad2">
                <label htmlFor="AppStoreVersion">App Store Version</label>
                <input className="form-control form-control-sm  mt-1" value={datas.apstoreVersion || ''} id="AppStoreVersion" type="text" disabled={false} onChange={(e) => {
                    setdatas({ ...datas, apstoreVersion: e.target.value })
                }} />
            </div> */}
        </div>
    );
}

export default Versioning;