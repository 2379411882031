import React, { useState, useEffect } from 'react';
import { Tabs, Switch, Rate, Modal, PageHeader, Tag, Button, Tooltip, Alert, Space } from 'antd';
import OtpInput from 'react-otp-input';
import './Style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCross, faTimes } from '@fortawesome/free-solid-svg-icons';
import { ItrApiService, ItrAuthService } from '@afiplfeed/itr-ui';
import { NotificationManager } from 'react-notifications';
function CancelModal({ onCanceled = () => { }, cancelVisible, onOk = () => { }, title = '' }) {

    const [user, setUser] = useState({})
    useEffect(() => {
        ItrAuthService.GetUserInfo().then(res => {
            if (res.Success == true) {
                let getUserDetails = res.result;
                setUser(getUserDetails);
                // setUserName(getUserDetails.userName);
            }
            else if (res.Success == false) {
                NotificationManager.error(res.message);
                // sessionStorage.clear();
            }
        });
    }, [])
    const [pin, setPin] = useState('');
    return (
        <div>
            <Modal footer={[
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row' }}>
                    <button className='btn btn-primary' style={{ width: '48%', borderRadius: 0 }} onClick={() => {
                        ItrApiService.POST({
                            url: 'PAM/AdminPinVerification',
                            data: {
                                userCode: user.userCode,
                                userName: user.userName,
                                phonenumbers: "",
                                otpKey: "",
                                isEnable2FA: true,
                                adminPin: pin
                            }
                        }).then(res => {
                            if (res.Success == true) {
                                if (res.result.isSuccess == true) {
                                    onOk();
                                } else {
                                    NotificationManager.error(res.result.message)
                                }
                            } else {
                                NotificationManager.error(res.message)
                            }
                        })
                    }}>Authorize</button>
                    <button className='btn btn-primary' style={{ width: '48%', borderRadius: 0 }} onClick={onCanceled}>Close</button>
                </div>
            ]}
                className='modClass'
                title={<div style={{ padding: 20, width: '100%', backgroundColor: '#ADD8E6' }}>
                    {/* <div style={{ position: 'absolute', right: 20, top: 20 }}>
                        <FontAwesomeIcon icon={faTimes} style={{ cursor: 'pointer' }} onClick={onCanceled} />
                    </div> */}
                    <div>
                        <label style={{ fontSize: 14 }}>Admin Action Control</label><br />
                        <label style={{ fontSize: 17, marginTop: 10, fontWeight: 'bold' }}>Some of the actions will be irrevocable! <br />Do you want to continue with this operation?</label>
                    </div>

                </div>} width='400px' visible={cancelVisible} centered={true}
                onCancel={onCanceled}
            >
                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                    <label className='f-badge-title' style={{ textAlign: 'center' }}>To continue, enter the admin pin.</label>
                    {/* <div style={{ height: 80, width: '100%', backgroundColor: 'blue' }}>

                    </div> */}
                    <div className="feilds2 mt-5" style={{}}>
                        <div>
                            {/* <input type='number' /> */}
                            <OtpInput
                                containerStyle={{ borderColor: '#666666' }}
                                className='otpinp'
                                inputStyle={{ width: 40, height: 40, borderColor: '#666666', shadow: 'none' }}
                                value={pin}
                                onChange={(ot) => { setPin(ot) }}
                                numInputs={6}
                                separator={<span> - </span>}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default CancelModal;