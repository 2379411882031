export const requireValid = async (val) => {
    try {
        if (val == '') return false;
        return true
    } catch (error) {
        return false
    }
}

export const emailValid = async (email = '') => {
    if (email == '') {
        return false;
    }
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return true;
    }
    return false;
}