import React from 'react';
import Card from '../../../components/card/card';
import { useHistory } from 'react-router-dom';

// icons
import user from '../../../assets/User.svg';
import enquiry from '../../../assets/Enquiry.svg';
import customer from '../../../assets/Customer.svg';
import vault from '../../../assets/Key Vault.svg';
import billing from '../../../assets/Billing.svg';
import support from '../../../assets/Support.svg';
import subscription from '../../../assets/Subscriptions.svg';
import feedback from '../../../assets/Feedback (1).svg';
import notification from '../../../assets/Notification Hub.svg';
import widget from '../../../assets/Widget.svg';
import { NxCard, NxTitleTab, NxFooter } from '@afiplfeed/itr-app';
import Headers from '../../../Screens/Headers/Headers';


export default function ConfigurationTab() {

    let history = useHistory();
    return (
        <div className="conatiner">
            <Headers />
            <NxTitleTab className="title-tab" title='Configuration' onCloseClick={() => history.push('/partner-board')} />
            <div className="m-4 mt-0">
                <NxCard className="card-tab" datas={[
                    {
                        data: [
                            { image: enquiry, heding: 'Password Policy', info: 'Monitor Your apps and infrastructure', onClick: () => history.push('/Password-Policy') },
                            { image: enquiry, heding: 'Platform User', info: 'Monitor your apps and infrastructure', onClick: () => history.push('/platformuser') },
                            { image: customer, heding: 'Retention Policy', info: 'Monitor your apps and infrastructure', url: '' },
                            { image: customer, heding: 'Notification', info: 'Monitor your apps and infrastructure', onClick: () => history.push('/notification') },
                        ]
                    },
                ]} />
            </div>
            {/* <Card
                datas={[
                    {
                        data: [
                            { image: enquiry, heding: 'Platform User', info: 'Monitor your apps and infrastructure', url: '/platformuser' },
                            { image: customer, heding: 'Retention Policy', info: 'Monitor your apps and infrastructure', url: '' },
                            { image: customer, heding: 'Password Policies', info: 'Monitor your apps and infrastructure', url: '' },
                            { image: subscription, heding: 'Device Management', info: 'Monitor your apps and infrastructure', url: '' },
                            { image: vault, heding: 'IP Whitelisting', info: 'Monitor your apps and infrastructure', url: '' },
                            { image: billing, heding: 'Configuration Management', info: 'Monitor your apps and infrastructure', url: '' },
                        ]
                    },
                ]}
            /> */}

            <NxFooter ExtraContent={[
                { name: 'Terms of Service' },
                { name: 'privacy' },
            ]} />
        </div>
    )
}
