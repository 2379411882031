import React, { useEffect, useState } from "react";
import './AzureContainerRegister.css';
import { useHistory } from 'react-router-dom';
import { NxLoader, NxTitleTab, NxActionTab, NxGridList, NxFooter } from '@afiplfeed/itr-app';
import { ItrApiService } from '@afiplfeed/itr-ui';
import Headers from "../../Screens/Headers/Headers";


const splits = async (dests, setData, setExport) => {
    let arr = [];
    let arr2 = [];
    for (let value of dests) {
        let data = [value.acrName, value.osType, value.sku, value.crType];
        let obj = { sendItem: value, data: data };
        let obj2 = {
            'ACR Name': value.acrName,
            'OS Type': value.osType,
            'SKU': value.sku,
            'CR Type': value.crType,
        }
        arr2 = [...arr2, obj2];
        arr = [...arr, obj];
    }
    setExport(arr2);
    setData(arr)
}


export default function AzureContainerRegister() {
    let history = useHistory();
    const [load, setLoad] = useState(false);
    const [exportVal, setExport] = useState([]);
    const [handel, setHandel] = useState(false);
    useEffect(() => {
        setLoad(true)
        ItrApiService.GET({
            url: 'CloudConnect/Configuration/AzureContainerRegistry'
        }).then(res => {
            if (res.Success == true) {
                if (res.result.isSuccess == true) {
                    // setVale(res.result.data);
                    if (res.result.data) {
                        splits(res.result.data, setData, setExport)
                    }
                }
            }
            setLoad(false)
        })
    }, []);

    const [vals, setVale] = useState([])
    const [data, setData] = useState([]);
    return (
        <div>
            <NxLoader state={load} />
            {/* <Headers /> */}
            {/* <VxBreadcrumb className="navigation-breadcrumb" data={[
                {
                    name: 'Home',
                    onClick: () => {
                        history.push('/partner-board');
                    }
                }
            ]} /> */}
            <div className="customer-dynamic-pages pt-0">
                {/* <NxTitleTab className="title-tab page-title" title='Azure Connect' onCloseClick={() => {
                    history.goBack();
                }} /> */}

                <div className="onboarding-main mt-1">
                    <div className="d-flex justify-content-between align-items-center">
                        <NxActionTab className="px-0"
                            exportValue={exportVal}
                            fileName='Azure Container Registry'
                            // onColumnsClick={() => { setHandel(true) }}
                            onRefreshClick={() => {
                                setLoad(true)
                                ItrApiService.GET({
                                    url: 'CloudConnect/Configuration/AzureContainerRegistry'
                                }).then(res => {
                                    if (res.Success == true) {
                                        if (res.result.isSuccess == true) {
                                            // setVale(res.result.data);
                                            if (res.result.data) {
                                                splits(res.result.data, setData, setExport)
                                            } else {
                                                setData([]);
                                            }
                                        }
                                    }
                                    setLoad(false)
                                })
                            }}
                            onNewEntryClick={() => {
                                history.push('/azure-container-register/add')
                            }}
                            enableNewEntry={false}
                        />
                        <button className='btn btn-sm' style={{ backgroundColor: 'rgb(82, 196, 26)', color: 'white', fontSize: '14px' }} onClick={() => history.push('/azure-container-register/add')}>Add ACR </button>
                    </div>

                    <div className="mb-5">
                        <NxGridList
                            enableSearch={true}
                            autoSearch={true}
                            enableAutoPagination={true}
                            showShowing={true}
                            header={[
                                {
                                    name: 'ACR Name',
                                    sort: false,
                                    search: true
                                },
                                {
                                    name: 'OS Type',
                                    sort: true,
                                    search: true
                                },
                                {
                                    name: 'SKU',
                                    sort: false,
                                },
                                {
                                    name: 'CR Type',
                                    sort: false,
                                },

                            ]}
                            rowClick={(data) => {
                                history.push({
                                    pathname: '/azure-container-register/edit',
                                    state: data
                                })
                            }}
                            data={data}
                        />
                    </div>
                </div>
            </div>

            {/* <NxFooter className="verscio-footer" companyName="© 2022 Ithred Solutions" ExtraContent={[
                { name: 'Terms of Service' },
                { name: 'Privacy' },
            ]} /> */}
        </div>
    )
}