import React, { useEffect, useState } from "react";
import './ResetPassword.css';
import { useHistory } from 'react-router-dom';
import { NxLoader, NxBreadcrumb, NxTitleTab, NxCustomTab, NxGridList, NxActionTab, NxRightDrawer, NxFooter } from '@afiplfeed/itr-app';
import { ItrApiService } from "@afiplfeed/itr-ui";
import Headers from "../../../../Screens/Headers/Headers";
import { NotificationManager } from "react-notifications";
const splits = async (dests, setDatas) => {
    let arr = [];
    for (let value of dests) {
        let data = [value.firstName, value.lastName, value.emailID, value.mobile];
        let obj = { sendItem: value, data: data };
        arr = [...arr, obj]
    }
    setDatas(arr);
}


export default function ResetPassword() {

    let history = useHistory();
    const [load, setLoad] = useState(false);
    const [sea, setSea] = useState('');
    const [datas, setDatas] = useState([])



    const getIdentity = async () => {
        setLoad(true);
        let result = await ItrApiService.GET({
            url: 'EntityPlatform/EntityUser/GetEntityUsers'
        });
        if (result.Success == true) {
            let IdentityAccess = result.result.data;
            if (IdentityAccess === null || !IdentityAccess.length) {
                NotificationManager.error(result.result.message);
            }
            else {
                await splits(IdentityAccess, setDatas);
            }
            // setEnquiry(getEnquiryDetails);
        }
        else if (result.result.isSuccess == false) {
            NotificationManager.error(result.result.message);
            // sessionStorage.clear();
            // history.push('/')
        }
        setLoad(false);
    }

    useEffect(() => {
        getIdentity();
    }, [])


    return (
        <div >
            <Headers />
            <NxLoader state={load} />

            <div>
                <NxTitleTab className="title-tab" title="Reset Password" onCloseClick={() => {
                    history.push('/users');
                }} />

                <NxActionTab className="users-action-tab"
                    onColumnsClick={() => { setHandel(true) }}
                    onRefreshClick={() => {
                        setLoad(true)
                        setTimeout(function () {
                            setLoad(false)
                        }, 2000);
                    }}
                    onNewEntryClick={() => { history.push('/framework/iam/add') }}
                    enableNewEntry={false}
                />
                <div className="p-4 pt-0">

                    <NxGridList
                        header={[
                            {
                                name: 'First Name',
                                sort: true,
                                search: true
                            },
                            {
                                name: 'Last Name',
                                sort: true,
                                search: true
                            },
                            {
                                name: 'Email',
                                // sort: true,
                            },
                            {
                                name: 'Phone',
                                sort: false,
                            },
                        ]}
                        enableSearch={true}
                        autoSearch={true}
                        enableAutoPagination={true}
                        showShowing={true}
                        rowClick={(e) => {
                            sessionStorage.setItem('editUserId', e.userCode)
                            history.push('/framework/iam/edit');
                        }}
                        data={datas}
                    />
                </div>
            </div>

            <NxFooter companyName="© 2022 Ithred Solutions" ExtraContent={[
                { name: 'Terms of Service' },
                { name: 'Privacy' },
            ]} />
        </div>
    )
}


