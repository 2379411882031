import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import './emailSetup.css';
import { NxLoader, NxTitleTab, NxFooter } from '@afiplfeed/itr-app';
import Headers from '../../../../Screens/Headers/Headers';
import { ItrApiService } from '@afiplfeed/itr-ui';

export default function EmailSetup() {
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    // sms-gate-way
    // create-email-setup
    const createEmailSetup = async () => {
        setLoading(true);
        let res = await ItrApiService.POST({
            url: 'Platform/Configuration/Email',
            data: emailInfo
        });
        setLoading(false);
    }

    // Email-setup
    const [emailInfo, setEmailInfo] = useState({
        emailID: 0,
        mail: "",
        password: "",
        host: "",
        port: '',
        type: ""
    });

    const getEmailSetup = async () => {
        setLoading(true);
        let result = await ItrApiService.GET({
            url: 'Platform/Configuration/Email',
        });
        if (result.result.isSuccess == true) {
            let getEmailDetails = result.result.data;
            if (result.result.data) {
                setEmailInfo(getEmailDetails);
            }
        }
        else if (result.result.isSuccess == false) {
        }
        setLoading(false);
    }

    useEffect(() => {
        getEmailSetup();
    }, [])
    return (
        <div>
            <NxLoader state={loading} />
            <div className="card mt-0 email-setup-form mx-0">
                <div className="card-body">

                    <div className="container-form mb-5">
                        <div className="row">
                            <h6>Authenticate your email directly with the email service provider, and send mail using SMTP AUTH Client Submission</h6>
                            {/* <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"> */}
                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-5">
                                <label htmlFor="email">Email</label>
                                <input className="form-control mt-1" id="email" type="text"
                                    value={emailInfo.mail}
                                    onChange={(e) => setEmailInfo({ ...emailInfo, mail: e.target.value })} />
                            </div>

                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-5">
                                <label htmlFor="password">Password</label>
                                <input className="form-control mt-1" id="password" type="password"
                                    value={emailInfo.password} onChange={(e) => setEmailInfo({ ...emailInfo, password: e.target.value })} />
                            </div>

                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-5">
                                <label htmlFor="serverhost">Server/smart host</label>
                                <input className="form-control mt-1" id="serverhost" type="text"
                                    value={emailInfo.host}
                                    onChange={(e) => setEmailInfo({ ...emailInfo, host: e.target.value })} />
                                <label className="text-secondary example-title">Eg. smtp.office365.com</label>
                            </div>

                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-5">
                                <label htmlFor="port">Port</label>
                                <input className="form-control mt-1" id="port" type="number"
                                    value={emailInfo.port} onChange={(e) => { setEmailInfo({ ...emailInfo, port: parseInt(e.target.value) }); }} />
                                <label className="text-secondary example-title">Eg. Port 587 (recommended) or port 25</label>
                            </div>
                        </div>

                        <div className="form-button nx-enquiry-buttons mt-3 mb-5">
                            <button className="btn btn-primary buttons" onClick={createEmailSetup}> Save</button>
                        </div>
                    </div>

                </div>
            </div>
            <NxFooter companyName="© 2022 Ithred Solutions" ExtraContent={[
                { name: 'Terms of Service' },
                { name: 'Privacy' },
            ]} />
        </div>
    )
}
