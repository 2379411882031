import { NxFooter, NxLoader, NxTitleTab } from '@afiplfeed/itr-app';
import { ItrApiService } from '@afiplfeed/itr-ui';
import { faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useEffect, useState, } from 'react';
import { NotificationManager } from 'react-notifications';
import { useHistory, useLocation } from 'react-router-dom'
import Headers from '../../../Screens/Headers/Headers';

function EditCommisionPlan() {
    const history = useHistory();
    const location = useLocation()

    const [loader, setLoader] = useState(false);

    const [commisionPlan, setCommisionPlan] = useState({
        commissionPlanID: 0,
        commissionPlanName: "",
        commissionStructure: "",
        percentage: 0,
        baseTarget: 0,
        commissionPlanPricing: [
            // {
            //     commissionPlanPricingID: 0,
            //     commissionPlanID: 0,
            //     from: 0,
            //     to: 0,
            //     splitPercentage: 0
            // }
        ]
    });

    const [tiered, setTiered] = useState({
        commissionPlanPricingID: 0,
        commissionPlanID: 0,
        from: 0,
        to: 0,
        splitPercentage: 0
    });

    const [datas, setDatas] = useState({});

    const getCurrencyInformation = () => {
        setLoader(true);
        ItrApiService.GET({ url: 'RevOps/BillerInformation' }).then(res => {
            setLoader(false);
            if (res.Success == true) {
                if (res.result.isSuccess == true) {
                    if (res.result.data) {
                        setLoader(false);
                        setDatas(res.result.data);
                    }
                }
            }
        });
    }


    useEffect(() => {
        if (!location.state || !location.state.commissionPlanID) {
            history.replace('/commision-plan')
        } else {
            setCommisionPlan(location.state);
        }
        getCurrencyInformation();
    }, [])
    return (
        <div>
            <Headers />
            <NxLoader state={loader} />
            <NxTitleTab className="title-tab" title="Edit Commision Plan" onCloseClick={() => history.goBack()} />
            <div className="card password-policy-main mt-0">
                <div className="card-body p-0 mb-5" >
                    <div className="row p-3 mx-1">
                        <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-6'>
                            <div className="row">
                                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2'>
                                    <div className='row'>
                                        <div className='col-12 col-sm-12 col-md-5 col-lg-5 col-xl-4'>
                                            <label className='f-badge-title'>Commsion Template Name</label>
                                        </div>

                                        <div className='col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 flex-start' >
                                            <input className='form-control form-control-sm'
                                                value={commisionPlan.commissionPlanName}
                                                onChange={(e) => setCommisionPlan({ ...commisionPlan, commissionPlanName: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2'>
                                    <div className='row'>
                                        <div className='col-12 col-sm-12 col-md-5 col-lg-5 col-xl-4'>
                                            <label className='f-badge-title'>Commision Structure</label>
                                        </div>

                                        <div className='col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 flex-start' >
                                            <select className='form-select form-select-sm'
                                                value={commisionPlan.commissionStructure}
                                                onChange={(e) => setCommisionPlan({ ...commisionPlan, commissionStructure: e.target.value })}>
                                                <option value="" selected disabled> -- Please Select -- </option>
                                                <option value="Tiered Commission Structure"> Tiered Commission Structure  </option>
                                                <option value="Residual Commission Structure"> Residual Commission Structure  </option>
                                                <option value="Multiplier Commission Structure"> Multiplier Commission Structure  </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2' hidden={commisionPlan.commissionStructure == "Residual Commission Structure" ? false : true}>
                                    <div className='row'>
                                        <div className='col-12 col-sm-12 col-md-5 col-lg-5 col-xl-4'>
                                            <label className='f-badge-title'>Commision Percentage</label>
                                        </div>

                                        <div className='col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 flex-start' >
                                            <input type='number'
                                                className='form-control form-control-sm'
                                                value={commisionPlan.percentage}
                                                onChange={(e) => setCommisionPlan({ ...commisionPlan, percentage: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2' hidden={commisionPlan.commissionStructure == "Multiplier Commission Structure" ? false : true}>
                                    <div className='row'>
                                        <div className='col-12 col-sm-12 col-md-5 col-lg-5 col-xl-4'>
                                            <label className='f-badge-title'>Commision Percentage</label>
                                        </div>

                                        <div className='col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 flex-start' >
                                            <input type='number'
                                                className='form-control form-control-sm'
                                                value={commisionPlan.percentage}
                                                onChange={(e) => setCommisionPlan({ ...commisionPlan, percentage: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2' hidden={commisionPlan.commissionStructure == "Multiplier Commission Structure" ? false : true}>
                                    <div className='row'>
                                        <div className='col-12 col-sm-12 col-md-5 col-lg-5 col-xl-4'>
                                            <label className='f-badge-title'>Base Target in
                                                {/* ({datas && datas.currencyName.split("-")[0]}) */}
                                            </label>
                                        </div>

                                        <div className='col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 flex-start' >
                                            <input type='number'
                                                className='form-control form-control-sm'
                                                value={commisionPlan.baseTarget}
                                                onChange={(e) => setCommisionPlan({ ...commisionPlan, baseTarget: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2' hidden={commisionPlan.commissionStructure == '' ? true : false}>
                                    <div className='row'>
                                        {/* <div className='col-12 col-sm-12 col-md-5 col-lg-5 col-xl-4'>
                                            <label className='f-badge-title'>Commision Structure</label>
                                        </div> */}

                                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 flex-start' >
                                            <div className='f-app-div-box w-100'>
                                                {commisionPlan.commissionStructure == 'Tiered Commission Structure' && <label className='f-badge-title'>Commision Values</label>}
                                                {commisionPlan.commissionStructure == 'Tiered Commission Structure' &&
                                                    <div class="table-wrapper-scroll-y my-custom-scrollbar mt-2">
                                                        <table class="table table-bordered mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th colSpan="2">Range In <span> ({datas && datas.currencyName && datas.currencyName.split("-")[0]}) </span> </th>
                                                                    <th scope="col">Commision (%) </th>
                                                                    <th scope="col">Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {commisionPlan.commissionPlanPricing.map((item, index) => {
                                                                    return <tr key={index}>
                                                                        <td> {item.from} </td>
                                                                        <td> {item.to} </td>
                                                                        <td> {item.splitPercentage} </td>
                                                                        <td align="center">
                                                                            <button className="btn" onClick={() => {
                                                                                let newArray = [...commisionPlan.commissionPlanPricing]
                                                                                newArray.splice(index, 1)
                                                                                setCommisionPlan({ ...commisionPlan, commissionPlanPricing: newArray })
                                                                            }} >
                                                                                <FontAwesomeIcon size="sm" icon={faMinusCircle} color="red" className="d-flex align-items-center justify-content-center" />
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                })}
                                                                <tr>
                                                                    <td>
                                                                        <input className="form-control form-control-sm" value={tiered.from} id="roleName" type='number'
                                                                            onChange={(e) => setTiered({ ...tiered, from: e.target.value })}
                                                                        />
                                                                    </td>

                                                                    <td>
                                                                        <input className="form-control form-control-sm" value={tiered.to} id="roleCode" type='number'
                                                                            onChange={(e) => setTiered({ ...tiered, to: e.target.value })}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <input className="form-control form-control-sm" value={tiered.splitPercentage} id="roleCode" type='number'
                                                                            onChange={(e) => setTiered({ ...tiered, splitPercentage: e.target.value })}
                                                                        />
                                                                    </td>
                                                                    <td align="center" className="d-flex align-items-center justify-content-center">
                                                                        <button className="btn" onClick={() => {
                                                                            if (tiered.from == '' || tiered.to == '') {
                                                                            } else {
                                                                                setCommisionPlan({
                                                                                    ...commisionPlan,
                                                                                    commissionPlanPricing: [...commisionPlan.commissionPlanPricing, tiered]
                                                                                })
                                                                                setTiered({
                                                                                    commissionPlanPricingID: 0,
                                                                                    commissionPlanID: 0,
                                                                                    from: 0,
                                                                                    to: 0,
                                                                                    splitPercentage: 0
                                                                                })
                                                                            }
                                                                        }}>
                                                                            <FontAwesomeIcon icon={faPlusCircle} size="sm" color="green" />
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>}
                                                {commisionPlan.commissionStructure == 'Multiplier Commission Structure' && <div class="table-wrapper-scroll-y my-custom-scrollbar mt-2">
                                                    <table class="table table-bordered mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th colSpan="2">Target Percentage  </th>
                                                                <th scope="col">Multiplier </th>
                                                                <th scope="col">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {commisionPlan.commissionPlanPricing.map((item, index) => {
                                                                return <tr key={index}>
                                                                    <td> {item.from} </td>
                                                                    <td> {item.to} </td>
                                                                    <td> {item.splitPercentage} </td>
                                                                    <td align="center">
                                                                        <button className="btn" onClick={() => {
                                                                            let newArray = [...commisionPlan.commissionPlanPricing]
                                                                            newArray.splice(index, 1)
                                                                            setCommisionPlan({ ...commisionPlan, commissionPlanPricing: newArray })
                                                                        }} >
                                                                            <FontAwesomeIcon size="sm" icon={faMinusCircle} color="red" className="d-flex align-items-center justify-content-center" />
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            })}
                                                            <tr>
                                                                <td>
                                                                    <input className="form-control form-control-sm" value={tiered.from} id="roleName" type='number'
                                                                        onChange={(e) => setTiered({ ...tiered, from: e.target.value })}
                                                                    />
                                                                </td>

                                                                <td>
                                                                    <input className="form-control form-control-sm" value={tiered.to} id="roleCode" type='number'
                                                                        onChange={(e) => setTiered({ ...tiered, to: e.target.value })}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input className="form-control form-control-sm" value={tiered.splitPercentage} id="roleCode" type='number'
                                                                        onChange={(e) => setTiered({ ...tiered, splitPercentage: e.target.value })}
                                                                    />
                                                                </td>
                                                                <td align="center" className="d-flex align-items-center justify-content-center">
                                                                    <button className="btn" onClick={() => {
                                                                        if (tiered.from == '' || tiered.to == '') {
                                                                        } else {
                                                                            setCommisionPlan({
                                                                                ...commisionPlan,
                                                                                commissionPlanPricing: [...commisionPlan.commissionPlanPricing, tiered]
                                                                            })
                                                                            setTiered({
                                                                                commissionPlanPricingID: 0,
                                                                                commissionPlanID: 0,
                                                                                from: 0,
                                                                                to: 0,
                                                                                splitPercentage: 0
                                                                            })
                                                                        }
                                                                    }}>
                                                                        <FontAwesomeIcon icon={faPlusCircle} size="sm" color="green" />
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='text-end'>
                            <button className='btn btn-primary btn-sm mt-4 mb-1' onClick={() => {
                                // let dataVal = { ...newBillingRules, selectDay: newBillingRules.selectDay == '' ? 0 : parseInt(newBillingRules.selectDay), isRecurringJob: newBillingRules.isRecurringJob == '' ? false : newBillingRules.isRecurringJob == 'true' ? true : false };
                                setLoader(true);
                                ItrApiService.POST({ url: 'RevOps/CommissionPlan/SaveCommissionPlan', data: commisionPlan }).then(res => {
                                    if (res.Success == true) {
                                        if (res.result.isSuccess == true) {
                                            setLoader(false);
                                            history.goBack();
                                            setCommisionPlan({
                                                ...commisionPlan, commissionPlanID: 0,
                                                commissionPlanName: "",
                                                commissionStructure: "",
                                                percentage: 0,
                                                commissionPlanPricing: []
                                            })
                                        } else {
                                            setLoader(false);
                                            NotificationManager.error(res.result.message);
                                        }
                                    } else {
                                        setLoader(false);
                                        NotificationManager.error(res.message);
                                    }
                                })
                            }}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
            <NxFooter
                className="verscio-footer" companyName="© 2022 Ithred Solutions" ExtraContent={[
                    { name: 'Terms of Service' },
                    { name: 'Privacy' },
                ]} />
        </div>
    );
}

export default EditCommisionPlan;