import React, { useState } from 'react';
import './CommsGateway.css';
import { useHistory } from 'react-router';
import Headers from '../../../../Screens/Headers/Headers';
import { NxTitleTab, NxFooter } from '@afiplfeed/itr-app';
import { DatePicker, Progress, Tabs } from 'antd';
import { DashboardOutlined, FormOutlined, DollarOutlined } from '@ant-design/icons';
import SmsGateway from '../../notification/smsGateway/smsGateway';
import EmailSetup from '../../notification/emailSetup/emailSetup';

const { RangePicker } = DatePicker;


export default function CommsGateway() {

    const { TabPane } = Tabs;

    let history = useHistory();

    const [keyPeriods, setKeyPeriods] = useState();

    return (
        <>
            <Headers />
            <NxTitleTab className="title-tab" title="Comms Gateway" onCloseClick={() => history.goBack()} />

            <div className="billing-tabs billing-main">
                <Tabs defaultActiveKey="1" type="card">
                    <TabPane tab={
                        <div className="d-flex justify-content-center">
                            {/* <span ><DashboardOutlined /></span> */}
                            <span style={{ fontSize: '16px',paddingLeft:"12px",paddingRight:"12px" }} >Email Config.</span>
                        </div>
                    } key="1">
                        <EmailSetup />
                    </TabPane>

                    <TabPane tab={<div className="d-flex justify-content-center">
                        {/* <span ><FormOutlined /></span> */}
                        <span style={{ fontSize: '16px',paddingLeft:"12px",paddingRight:"12px" }}>SMS Config.</span>
                    </div>} key="2">
                        <SmsGateway />
                    </TabPane>

                    <TabPane tab={<div className="d-flex justify-content-center">
                        {/* <span ><FormOutlined /></span> */}
                        <span style={{ fontSize: '16px',paddingLeft:"12px",paddingRight:"12px" }}>WhatsApp Config.</span>
                    </div>} key="3">
                        {/* <SmsGateway /> */}
                    </TabPane>
                    
                    <TabPane tab={<div className="d-flex justify-content-center">
                        {/* <span ><FormOutlined /></span> */}
                        <span style={{ fontSize: '16px',paddingLeft:"12px",paddingRight:"12px" }}>SignalR Config.</span>
                    </div>} key="3">
                        {/* <SmsGateway /> */}
                    </TabPane>
                </Tabs>
            </div>


            <NxFooter companyName="© 2022 Ithred Solutions" ExtraContent={[
                { name: 'Terms of Service' },
                { name: 'Privacy' },
            ]} />
        </>
    )
}
