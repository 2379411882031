import React, { useState } from 'react';
import { Checkbox, Switch, Rate, Modal, PageHeader, Tag, Button, Tooltip, Alert, Space } from 'antd';
import OtpInput from 'react-otp-input';
import './Style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faCross, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Radio } from 'antd';
import { NotificationManager } from 'react-notifications';
import { ItrApiService } from '@afiplfeed/itr-ui';
function ResetPasswordModal({ onCanceled = () => { }, visible, onOk = () => { }, title = '', datavalue = {} }) {
    const [value, setValue] = useState(1);
    const [value2, setValue2] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [tab, setTab] = useState(1);

    const [resetPassword, setResetPassword] = useState({
        userKey: datavalue.userCode,
        userName: datavalue.loginUserName,
        password: '',
        emailId: datavalue.emailID,
        additionalEmailId: "",
        isAutoGenerate: value,
        isRequiredSendEmail: value2
    });

    const saveResetPassword = async () => {
        let result = await ItrApiService.POST({
            url: 'Catalog/Users/UserResetPassword',
            data: {
                ...resetPassword, password: password, userName: datavalue.loginUserName,
                emailId: datavalue.emailID, userKey: datavalue.userCode, isRequiredSendEmail: value2
            }
        })
        if (result.Success == true) {
            if (result.result.isSuccess == true) {
                NotificationManager.success('Successfully Reseted');
                setTab(1);
                onCanceled();
                setConfirmPassword('');
                setPassword('');
            }
        }
        else {
            alert('Failed to Reseted')
        }
    }

    return (
        <div>
            <Modal footer={[
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row' }}>
                    <button className='btn btn-primary' style={{ width: '48%', borderRadius: 0 }} onClick={() => {
                        setTab(1);
                        onCanceled()
                        setConfirmPassword('')
                        setPassword('');
                    }}>Close</button>
                    <button className='btn btn-primary' style={{ width: '48%', borderRadius: 0 }} onClick={() => {
                        if (tab != 1 && tab != 2) {
                            onOk()
                        }
                        if (tab == 1 && value == 2) {
                            setPassword('');
                            setResetPassword({ ...resetPassword, isAutoGenerate: false });
                            setTab(2)
                        } else if (tab == 1 && value == 1) {
                            setResetPassword({ ...resetPassword, isAutoGenerate: true })
                            setPassword('Password@123');
                            setTab(3)
                        } else if (tab == 2) {
                            if (password == '' || confirmPassword == '') {
                                NotificationManager.error('Passwor and Confirm password is mandatory!');
                            } else if (password != confirmPassword) {
                                NotificationManager.error('Password Missmatch!');
                            } else {
                                // setPassword('');
                                // setTab(2)
                                setTab(3)
                            }
                        } else if (tab == 3) {
                            saveResetPassword();
                        } else {
                            setTab(3);
                        }
                    }}>{tab == 1 || tab == 2 ? 'Next' : "Reset"}</button>
                </div>
            ]}
                className='modClass_2'
                title={<div style={{ padding: 20, width: '100%', backgroundColor: '#ADD8E6' }}>
                    <div style={{ position: 'absolute', right: 20, top: 20 }}>
                        <FontAwesomeIcon icon={faTimes} style={{ cursor: 'pointer', color: 'grey' }} onClick={() => {
                            setTab(1);
                            setConfirmPassword('')
                            setPassword('');
                            onCanceled()
                        }} />
                    </div>
                    <div>
                        <label style={{ fontSize: 14 }}>Reset Password</label><br />
                        <label style={{ fontSize: 17, marginTop: 10, fontWeight: 'bold' }}>Reset Password For <br />{title}?</label>
                    </div>

                </div>} width='400px' visible={visible} centered={true}
                onCancel={() => {
                    setTab(1);
                    onCanceled();
                    setConfirmPassword('')
                    setPassword('');
                }}
            >
                {/* <div className='w-100'>
                    <FontAwesomeIcon icon={faAngleLeft} style={{ cursor: 'pointer', color: 'grey' }} onClick={() => {
                        setTab(1);
                        onCanceled()
                    }} />
                </div> */}
                {tab == 1 ? <div className='mt-2'>
                    <Radio.Group onChange={(e) => {
                        setValue(e.target.value)
                    }} value={value}>
                        <Space direction="vertical">
                            <Radio value={1}>Autogenerate Password</Radio>
                            <Radio value={2}>Let me create a password</Radio>
                        </Space>
                    </Radio.Group>
                    <br />
                    <div className='mt-3'>
                        <Checkbox checked={value2} onChange={(e) => {
                            setValue2(e.target.checked)
                        }}>
                            Make this user change their password when they first Sign in
                        </Checkbox>
                    </div>
                </div> : tab == 2 ? <div>
                    <div>
                        <label htmlFor="mobile">Password <span className='text-danger'>*</span> </label>
                        <input className="form-control form-control-sm mt-0" id="mobilenumber" type='text' value={password} onChange={(e) => {
                            setPassword(e.target.value);
                        }} />
                    </div>
                    <div className='mt-3'>
                        <label htmlFor="mobile">Confirm Password <span className='text-danger'>*</span></label>
                        <input className="form-control form-control-sm mt-0" id="mobilenumber" type='text' value={confirmPassword} onChange={(e) => {
                            setConfirmPassword(e.target.value);
                        }} />
                    </div>
                </div> : <div>
                    <table class="table table-hover table-responsive" >
                        <thead>
                            <tr>
                                <th>User Name</th>
                                <th>Password</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    {datavalue.emailID}
                                </td>
                                <td>
                                    <input value={password}
                                        style={{ border: '0px' }}
                                        className="form-control form-control-sm " type="password" />
                                    {/* {password} */}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <Checkbox checked={value2} onChange={(e) => {
                        setValue2(e.target.checked)
                    }}>
                        Send Password in Email
                    </Checkbox>
                    {/* <div className='mt-3'>
                        <label htmlFor="mobile">Confirm Password</label>
                        <input className="form-control form-control-sm mt-0" id="mobilenumber" type='text' value={password} onChange={(e) => {
                            setPassword(e.target.value);
                        }} />
                    </div> */}
                </div>}

            </Modal>
        </div>
    );
}

export default ResetPasswordModal;