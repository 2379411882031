import { NxFooter, NxTitleTab } from '@afiplfeed/itr-app';
import { useHistory } from 'react-router-dom';
import CustomerSidebar from '../../Screens/CustomerSidebar/CustomerSidebar';
import Headers from '../../Screens/Headers/Headers';
import billingEngine from '../../assets/billingEngine.png';
import partner from '../../assets/Partner.png';
import gstTax from '../../assets/gst.png';
import Privacy from '../../assets/password.png';
import ThirdPartyImg from '../../assets/third-party.png';
import clcd from '../../assets/eternity.png';
import Analytics from '../../assets/analytics.png';
import SecurityTwoFactor from './SecurityTwoFactor';
import { useState } from 'react';

export default function SecurityHome() {
    let history = useHistory();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const showModal = () => {
        setIsModalVisible(true);
    };
    const handleOk = () => {
        setIsModalVisible(false);
    };
    const handleCancel = () => {
        setIsModalVisible(false);
    };
    return (
        <div className="conatiner dashboard ">
            <Headers />
            <div className='d-flex custom-body-container'>
                <div className='body-sidebar'>
                    <CustomerSidebar visible={true} />
                </div>
                <div className='body-workspace'>
                    <NxTitleTab className="title-tab" title='Configuration' onCloseClick={() => history.goBack()} />
                    <div className="mb-5 mt-0 customer-configuration-main">
                        <div className="container-fluid">
                            <div className="CustomerUsersTab">
                                <div className="row cus-configuration-tab">
                                    {/* <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mt-2 mt-4">
                                        <div className="card">
                                            <div className="card-header d-flex align-items-center">
                                                <img src={billingEngine} width="30" />
                                                <h6 className="fw-bold m-1 text-primary">Provider Setup</h6>
                                            </div>
                                            <div className="card-body">
                                                <div className="card-details">
                                                    <p>Company setup features including financial details that will be used in the billing, options to add new users to the console system as principals or partners.</p>
                                                </div>
                                                <div className="text-end mt-5">
                                                    <button className="btn mx-1 btn-sm btn-primary border-0 config-buttons"
                                                        onClick={() => {
                                                            // sessionStorage.setItem('ike', '1');
                                                            history.push('/biller-information')
                                                        }}>
                                                        Company Setup
                                                    </button>                                                    
                                                    <button className="btn mx-1 btn-sm btn-primary border-0 config-buttons"
                                                        onClick={() => {
                                                            sessionStorage.setItem('ike', '1');
                                                            history.push('/manage-access')
                                                        }}>
                                                        Manage Access
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                    {/* <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mt-2">
                                        <div className="card">
                                            <div className="card-header d-flex align-items-center">
                                                <img src={gstTax} width="30" />
                                                <h6 className="fw-bold m-1 text-primary">Billing Configuration</h6>
                                            </div>
                                            <div className="card-body">
                                                <div className="card-details">
                                                    <p>User management facilitates the functionality for administrators to manage user access to various Apps & resources like platform/portal.</p>
                                                </div>
                                                <div className="text-end mt-5">


                                                    <button className="btn px-1 btn-sm btn-primary border-0 config-buttons"
                                                        onClick={() => {
                                                            // sessionStorage.setItem('ike', '1');
                                                            history.push('/taxation')
                                                        }}>
                                                        3D Taxation
                                                    </button>

                                                    <button className="btn mx-1 btn-sm btn-primary border-0 config-buttons"
                                                        onClick={() => {
                                                            // sessionStorage.setItem('ike', '1');
                                                            history.push('/paymentgateway')
                                                        }}>
                                                        Manage PGI
                                                    </button> 
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* 
                                    <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mt-4">
                                        <div className="card">
                                            <div className="card-header d-flex align-items-center">

                                                <img src={gstTax} width="30" />
                                                <h6 className="fw-bold m-1 text-primary">Billing & Subscription</h6>
                                            </div>
                                            <div className="card-body">
                                                <div className="card-details">
                                                    <p>Configuration of financial elements including currency conversion, billing template & taxation along with a vault feature that lets providers manage licenses.</p>
                                                </div>
                                                <div className="text-end mt-5">

                                                    <button className="btn mx-1 btn-sm btn-primary border-0 config-buttons"
                                                        onClick={() => {
                                                            sessionStorage.setItem('ike', '1');
                                                            history.push('/administrator/billing-configuration')
                                                        }}>
                                                        Billing Configuration
                                                    </button>

                                                    <button className="btn px-1 btn-sm btn-primary border-0 config-buttons"
                                                        onClick={() => {
                                                            sessionStorage.setItem('ike', '1');
                                                            history.push('/license-vault')
                                                        }}>
                                                        License Vault
                                                    </button>

                                                </div>
                                            </div>
                                        </div>
                                    </div> */}


                                    <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mt-4" >
                                        <div className="card">
                                            <div className="card-header d-flex align-items-center">
                                                {/* <img src={Privacy} width="30" /> */}
                                                <h6 className="fw-bold m-1 text-primary">Password Policy</h6>
                                            </div>
                                            <div className="card-body">
                                                <div className="card-details">
                                                    <p>Features include a password policy manager that let the providers setup org level policy for authentication. Timeout can be setup for app users.</p>
                                                </div>
                                                <div className="text-end mt-5">
                                                    <button className="btn mx-1 btn-sm btn-primary border-0 config-buttons"
                                                        onClick={() => {
                                                            sessionStorage.setItem('ike', '1');
                                                            history.push('/PasswordPolicy')
                                                        }}>
                                                        Password Policy
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mt-4" >
                                        <div className="card">
                                            <div className="card-header d-flex align-items-center">
                                                {/* <img src={Privacy} width="30" /> */}
                                                <h6 className="fw-bold m-1 text-primary">2-Factor Auth</h6>
                                            </div>
                                            <div className="card-body">
                                                <div className="card-details">
                                                    <p>Features include a password policy manager that let the providers setup org level policy for authentication. Timeout can be setup for app users.</p>
                                                </div>
                                                <div className="text-end mt-5">
                                                    <button className="btn mx-1 btn-sm btn-primary border-0 config-buttons"
                                                        onClick={() => {
                                                            // sessionStorage.setItem('ike', '1');
                                                            history.push('/security-two-factor');
                                                            // showModal();
                                                        }}>
                                                        2-Factor Auth
                                                    </button>
                                                </div>
                                                {/* <SecurityTwoFactor
                                                    isModalVisible={isModalVisible}
                                                    handleCancel={handleCancel}
                                                    handleOk={handleOk} /> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mt-4" >
                                        <div className="card">
                                            <div className="card-header d-flex align-items-center">
                                                {/* <img src={Privacy} width="30" /> */}
                                                <h6 className="fw-bold m-1 text-primary">API Token Policy</h6>
                                            </div>
                                            <div className="card-body">
                                                <div className="card-details">
                                                    <p>Features include a password policy manager that let the providers setup org level policy for authentication. Timeout can be setup for app users.</p>
                                                </div>
                                                <div className="text-end mt-5">
                                                    <button className="btn mx-1 btn-sm btn-primary border-0 config-buttons"
                                                        onClick={() => {
                                                            // sessionStorage.setItem('ike', '1');
                                                            history.push('/API-management')
                                                        }}>
                                                        API Token Policy
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mt-4" >
                                        <div className="card">
                                            <div className="card-header d-flex align-items-center">
                                                {/* <img src={Privacy} width="30" /> */}
                                                <h6 className="fw-bold m-1 text-primary">Session Management</h6>
                                            </div>
                                            <div className="card-body">
                                                <div className="card-details">
                                                    <p>Features include a password policy manager that let the providers setup org level policy for authentication. Timeout can be setup for app users.</p>
                                                </div>
                                                <div className="text-end mt-5">
                                                    <button className="btn mx-1 btn-sm btn-primary border-0 config-buttons"
                                                        onClick={() => {
                                                            // sessionStorage.setItem('ike', '1');
                                                            history.push('/idle-timeout')
                                                        }}>
                                                        Session Management
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mt-4">
                                        <div className="card">
                                            <div className="card-header d-flex align-items-center">
                                                {/* <img src={ThirdPartyImg} width="30" /> */}
                                                <h6 className="fw-bold m-1 text-primary">Notification Hub</h6>
                                                {/* <h6 className="fw-bold m-1 text-primary">3<sup>rd</sup> Party Integration</h6> */}
                                            </div>
                                            <div className="card-body">
                                                <div className="card-details">
                                                    <p>Providers will be able to manage 3rd party integrations like comm setup and payment gateway, this API system can be managed for multiple integrators. </p>
                                                </div>
                                                <div className="text-end mt-5">
                                                    <button className="btn px-1 btn-sm btn-primary border-0 config-buttons"
                                                        onClick={() => {
                                                            sessionStorage.setItem('ike', '1');
                                                            history.push('/administrator/comms-gateway')
                                                        }}>
                                                        Comms Gateways
                                                    </button>
                                                    {/* <button className="btn mx-1 btn-sm btn-primary border-0 config-buttons"
                                                        onClick={() => {
                                                            sessionStorage.setItem('ike', '1');
                                                            history.push('/paymentgateway')
                                                        }}>
                                                        Payment Gateway
                                                    </button> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mt-4">
                                        <div className="card">
                                            <div className="card-header d-flex align-items-center">
                                                {/* <img src={ThirdPartyImg} width="30" /> */}
                                                <h6 className="fw-bold m-1 text-primary">API Integration</h6>
                                                {/* <h6 className="fw-bold m-1 text-primary">3<sup>rd</sup> Party Integration</h6> */}
                                            </div>
                                            <div className="card-body">
                                                <div className="card-details">
                                                    <p>Providers will be able to manage 3rd party integrations like comm setup and payment gateway, this API system can be managed for multiple integrators. </p>
                                                </div>
                                                <div className="text-end mt-5">
                                                    <button className="btn mx-1 btn-sm btn-primary border-0 config-buttons"
                                                        onClick={() => {
                                                            sessionStorage.setItem('ike', '1');
                                                            history.push('/paymentgateway')
                                                        }}
                                                    >
                                                        API Integration
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mt-4">
                                        <div className="card">
                                            <div className="card-header d-flex align-items-center">
                                                <img src={Analytics} width="30" />
                                                <h6 className="fw-bold m-1 text-primary">Log Analytics</h6>
                                            </div>
                                            <div className="card-body">
                                                <div className="card-details">
                                                    <p>Access to the Platform along with the devices can be monitored in this, user audit along with the background transaction audit features are integrated.</p>
                                                </div>
                                                <div className="text-end mt-5">
                                                    <button className="btn mx-1 btn-sm btn-primary border-0 config-buttons"
                                                        onClick={() => {
                                                            sessionStorage.setItem('ike', '1');
                                                            history.push('/user-audit')
                                                        }}>
                                                        User Audit
                                                    </button>
                                                    <button className="btn mx-1 btn-sm btn-primary border-0 config-buttons"
                                                        onClick={() => {
                                                            sessionStorage.setItem('ike', '1');
                                                            history.push('/device-management')
                                                        }}>
                                                        Device Management
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                    <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mt-4" >
                                        <div className="card">
                                            <div className="card-header d-flex align-items-center">
                                                {/* <img src={Privacy} width="30" /> */}
                                                <h6 className="fw-bold m-1 text-primary">User Audit</h6>
                                            </div>
                                            <div className="card-body">
                                                <div className="card-details">
                                                    <p>Features include a password policy manager that let the providers setup org level policy for authentication. Timeout can be setup for app users.</p>
                                                </div>
                                                <div className="text-end mt-5">
                                                    <button className="btn mx-1 btn-sm btn-primary border-0 config-buttons"
                                                        onClick={() => {
                                                            sessionStorage.setItem('ike', '1');
                                                            history.push('/user-audit')
                                                        }}>
                                                        User Audit
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mt-4" >
                                        <div className="card">
                                            <div className="card-header d-flex align-items-center">
                                                {/* <img src={Privacy} width="30" /> */}
                                                <h6 className="fw-bold m-1 text-primary">Device Management</h6>
                                            </div>
                                            <div className="card-body">
                                                <div className="card-details">
                                                    <p>Features include a password policy manager that let the providers setup org level policy for authentication. Timeout can be setup for app users.</p>
                                                </div>
                                                <div className="text-end mt-5">
                                                    <button className="btn mx-1 btn-sm btn-primary border-0 config-buttons"
                                                        onClick={() => {
                                                            sessionStorage.setItem('ike', '1');
                                                            history.push('/device-management')
                                                        }}>
                                                        Device Management
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mt-4">
                                        <div className="card">
                                            <div className="card-header d-flex align-items-center">
                                                <img src={Analytics} width="30" />
                                                <h6 className="fw-bold m-1 text-primary">System Configuration</h6>
                                            </div>
                                            <div className="card-body">
                                                <div className="card-details">
                                                    <p>User management facilitates the functionality for administrators to manage user access to various Apps & resources like platform/portal.</p>
                                                </div>
                                                <div className="text-end mt-5">
                                                    <button className="btn mx-1 btn-sm btn-primary border-0 config-buttons"
                                                        onClick={() => {
                                                            // sessionStorage.setItem('ike', '1');
                                                            history.push('/idle-timeout')
                                                        }}>
                                                        Idle Timeout
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                    {/* <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mt-4">
                                        <div className="card">
                                            <div className="card-header d-flex align-items-center">
                                                <img src={clcd} width="30" />
                                                <h6 className="fw-bold m-1 text-primary">CI/CD Configuration</h6>
                                            </div>
                                            <div className="card-body">
                                                <div className="card-details">
                                                    <p>Developer version will have features to manage cloud resources and their secrets, users can manage their proprietary CI/CD tools and repos from here.</p>
                                                </div>
                                                <div className="text-end mt-5">
                                                    <button className="btn mx-1 btn-sm btn-primary border-0 config-buttons"
                                                        onClick={() => {
                                                            // sessionStorage.setItem('ike', '1');
                                                            history.push('/repo-connect')
                                                        }}>
                                                        Repo / Docker
                                                    </button>
                                                    <button className="btn mx-1 btn-sm btn-primary border-0 config-buttons"
                                                        onClick={() => {
                                                            // sessionStorage.setItem('ike', '1');
                                                            history.push('/docker-connect')
                                                        }}>
                                                        Cloud Connect
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mt-2 mt-4">
                                        <div className="card">
                                            <div className="card-header d-flex align-items-center">
                                                <img src={partner} width="30" />
                                                <h6 className="fw-bold m-1 text-primary">Partner Management</h6>
                                            </div>
                                            <div className="card-body">
                                                <div className="card-details">
                                                    <p>Manage partners and their benefits plans using this feature, you will be able to add multiple partners and design individual commission plan for each of them.</p>
                                                </div>
                                                <div className="text-end mt-5">
                                                    <button className="btn mx-1 btn-sm btn-primary border-0 config-buttons"
                                                        onClick={() => {
                                                            // sessionStorage.setItem('ike', '1');
                                                            history.push('/partner-management')
                                                        }}>
                                                        Partner Setup
                                                    </button>
                                                    <button className="btn mx-1 btn-sm btn-primary border-0 config-buttons"
                                                        onClick={() => {
                                                            // sessionStorage.setItem('ike', '1');
                                                            history.push('/commision-plan')
                                                        }}>
                                                        Commision Plan
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div style={{ height: 50 }} />
                    </div>
                </div>
            </div>
            <NxFooter companyName="© 2023 Altascio Technologies (I) Pvt. Ltd.," ExtraContent={[
                { name: 'Terms of Service' },
                { name: 'Privacy' },
            ]} />
        </div>
    )
}