import { useEffect, useState } from 'react';
import './partnerBoard.css'
import NxBarCharts from '../../components/nxCharts/nxBarCharts';
import NxDoughnutChart from '../../components/nxCharts/nxDoughnutCharts';
import { useHistory } from 'react-router';
import { ItrApiService, ItrAuthService } from '@afiplfeed/itr-ui';
import { NxGridList, NxLoader } from '@afiplfeed/itr-app';
import { Progress } from 'antd';
import { NotificationManager } from "react-notifications";
const splits = async (dests, setEnquiry) => {
    let arr = [];
    for (let value of dests) {
        let data = [value.entityCode, value.entityName, value.noOfEmployees];
        let obj = { sendItem: value, data: data };
        arr = [...arr, obj]
    }
    setEnquiry(arr);
}


export default function PartnerBoard() {

    const [customers, setCustomers] = useState([]);
    const [passCustomers, setpassCustomers] = useState();
    const [loading, setLoading] = useState(false);

    const getData = async (tenetcode) => {
        let result = await ItrApiService.GET({
            url: 'TenantPlatform/Entity/GetEntities',
            // customHeaders:[
            //     { "TenantID": tenetcode }
            // ]
        });
        if (result.result.isSuccess == true) {
            let getCustomerDetails = result.result.data;
            if (getCustomerDetails === null || !getCustomerDetails.length) {
                // NotificationManager.error(result.result.message);
            }
            else {
                await splits(getCustomerDetails, setCustomers);
                await setpassCustomers(getCustomerDetails);
            }
            // setCustomers(getCustomerDetails);
        }
        else if (result.result.isSuccess == false) {
            NotificationManager.error(result.result.message);
            // sessionStorage.clear();
            // history.push('/')
        }
    }
    const [checkAccessType, setCheckAccessType] = useState('');

    useEffect(() => {
        setLoading(true);
        ItrAuthService.GetUserInfo().then(res => {
            setCheckAccessType(res.result.accessType);
            getData(res.result.tenantCode).then(() => {
                setLoading(false);
            });
        })
    }, []);

    let history = useHistory();

    const navigateCustomerBoard = () => {
        history.push('/customer-board');
    }

    return (
        <>
            <NxLoader state={loading} />
            <div className="container1">
                <div className="counters partner-counters">
                    <div className="row">
                        <div className="col-12 col-sm-6 col-md-6 col-lg-3 mt-2 counts count-one">
                            <div className="card">
                                <div className="card-body">
                                    <h6 className="badge-one">Enquiry</h6>
                                    {/* <h5 className="mt-2">1452</h5> */}
                                    <div>
                                        <Progress strokeLinecap="square" type="circle" percent={60} />
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 col-md-6 col-lg-3 mt-2 counts count-one">
                            <div className="card">
                                <div className="card-body">
                                    <h6 className="badge-one">Customers</h6>
                                    {/* <h5 className="mt-2">344</h5> */}
                                    <div>
                                        <Progress strokeLinecap="square" type="circle" percent={75} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 col-md-6 col-lg-3 mt-2 counts count-one">
                            <div className="card">
                                <div className="card-body">
                                    <h6 className="badge-one">Revenue</h6>
                                    {/* <h5 className="mt-2">$56,870</h5> */}
                                    <div>
                                        <Progress strokeLinecap="square" type="circle" percent={64} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 col-md-6 col-lg-3 mt-2 counts count-one">
                            <div className="card">
                                <div className="card-body">
                                    <h6 className="badge-one">Support Health</h6>
                                    {/* <h5 className="mt-2">23</h5> */}
                                    <div>
                                        <Progress strokeLinecap="square" type="circle" percent={80} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-2 nx-partner-dashboard-chart">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 mt-2">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-center">
                                            <h4>Enquiry</h4>
                                            <div className="mt-5">
                                                <NxDoughnutChart />
                                            </div>
                                        </div>

                                        <div className="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-center">
                                            <h4>Customers</h4>
                                            <div className="mt-5">
                                                <NxDoughnutChart />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 mt-2">
                            <div className="card">
                                <div className="card-body text-center">
                                    <h4>Performance</h4>
                                    <div className="mt-1">
                                        <NxBarCharts />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-2 mb-5 nx-partner-table">
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <h4>Recent Customers</h4>
                            {/* <div className="table-responsive">
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th>Customer Code</th>
                                                <th>Customer Name</th>
                                                <th>Customer Employee</th>
                                            </tr>
                                        </thead>
                                        <tbody className="nx-recent-customers">
                                            {customers && customers.map((item,index) => <tr key={index} onClick={navigateCustomerBoard}>
                                                <td> {item?.entityCode ? item?.entityCode : ''} </td>
                                                <td> {item?.entityName ? item?.entityName : ''} </td>
                                                <td> {item?.noOfEmployees ? item?.noOfEmployees : ''} </td>
                                            </tr>)}
                                        </tbody>
                                    </table>
                                </div> */}

                            <NxGridList
                                enableSearch={true}
                                autoSearch={true}
                                enableAutoPagination={true}
                                showShowing={true}
                                header={[
                                    {
                                        name: 'Customer Code',
                                        sort: true,
                                    },
                                    {
                                        name: 'Customer Name',
                                        sort: true,
                                        search: true
                                    },
                                    {
                                        name: 'Employee',
                                        // sort: true,
                                    },
                                ]}
                                data={customers} />
                            {/* rowClick={false} */}
                        </div>




                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-2">
                            <h4>Key Financial Metrics</h4>

                            <div className="gridlist card table-responsive table-wrapper-scroll-y my-custom-scrollbar">
                                <div className="card-body">
                                    {/* {enableSearch == true && <div className=''>
                                    <input type='text' className={`${Styles.inpsty}`} placeholder={placeholder} onChange={(e) =>  onChangeText(e.target.value)} value={searchValue} />
                                </div>} */}
                                    <table id="table-nxs" className="table table-hover mt-3" >
                                        <thead>
                                            <tr className="thds">
                                                <th scope="col" className="tableheader">
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Billed</td>
                                                <td>$56,870</td>
                                                <td colSpan="2" className="text-center">Outstanding DPD</td>
                                            </tr>

                                            <tr>
                                                <td>Billed</td>
                                                <td>$56,870</td>
                                                <td className="text-center">&gt;30 D</td>
                                                <td className="text-center">$ 7,800</td>
                                            </tr>

                                            <tr>
                                                <td>Billed</td>
                                                <td>$56,870</td>
                                                <td className="text-center">&gt;30 D</td>
                                                <td className="text-center">$ 7,800</td>
                                            </tr>

                                            <tr>
                                                <td>Billed</td>
                                                <td>$56,870</td>
                                                <td className="text-center">&gt;30 D</td>
                                                <td className="text-center">$ 7,800</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {/* <div className="card"> */}
                            {/* <div className="card-body"> */}
                            {/* <div className="table-responsive">
                                    <table className="table">
                                        <tbody>
                                            <tr>
                                                <td>Billed</td>
                                                <td>$56,870</td>
                                                <td colSpan="2" className="text-center">Outstanding DPD</td>
                                            </tr>

                                            <tr>
                                                <td>Billed</td>
                                                <td>$56,870</td>
                                                <td className="text-center">&gt;30 D</td>
                                                <td className="text-center">$ 7,800</td>
                                            </tr>

                                            <tr>
                                                <td>Billed</td>
                                                <td>$56,870</td>
                                                <td className="text-center">&gt;30 D</td>
                                                <td className="text-center">$ 7,800</td>
                                            </tr>

                                            <tr>
                                                <td>Billed</td>
                                                <td>$56,870</td>
                                                <td className="text-center">&gt;30 D</td>
                                                <td className="text-center">$ 7,800</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div> */}
                            {/* </div>
                        </div> */}
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
