import { NxFooter, NxLoader, NxTitleTab } from '@afiplfeed/itr-app';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Headers from '../../../Screens/Headers/Headers';
import CustomerSidebar from '../../../Screens/CustomerSidebar/CustomerSidebar';
import { MdOutlineContentCopy } from "react-icons/md";
import { Button, message } from 'antd';

export default function AuthenticationKey(){
    const [loading, setLoading] = useState(false);
    let history = useHistory();
    useEffect(() => {
        let s = sessionStorage.getItem('sp');
        if (s) {
            setActiveString(s)
        }
    }, []);
    const copyText = () => {
        navigator.clipboard.writeText("--yjC@gg;2#5Jsp<(8$f#}aA");
        message.success("Developer Key Copied");
    }
    return (<>
        <Headers />
        <div className='d-flex custom-body-container'>
            <div className='body-sidebar'>
                <CustomerSidebar visible={true}  />
            </div>
            <div className='body-workspace'>
                <NxTitleTab className="title-tab" title="Authentication Key" onCloseClick={() => {
                    sessionStorage.removeItem('sp');
                    history.push('/developers');
                }} />
                <div className='support-main'>
                    <NxLoader state={loading} />
                    <div className="mt-0 support-main">
                        <div className='row'>
                            <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                <div className='card h-100'>
                                    <div className='card-body'>
                                        <div>
                                            <h6 className='text-dark fw-bold'>Developer Key : </h6>
                                            <p className='text-dark'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                            <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "10px" }}>
                                                <input value="--yjC@gg;2#5Jsp<(8$f#}aA" className='form-control form-control-sm w-25' disabled />
                                                <span style={{ cursor: "pointer" }}><MdOutlineContentCopy color='black' title='Copy key' fontSize="20px"
                                                    onClick={() => {
                                                        copyText()
                                                    }}
                                                /></span>
                                            </div>
                                        </div>

                                        <div className='mt-4'>
                                            <h6 className='text-dark fw-bold'>Support Key : </h6>
                                            <p className='text-dark'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                            <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "10px" }}>
                                                <input type="number" value="123456" className='form-control form-control-sm w-25' disabled />
                                                <span >
                                                    <Button style={{ backgroundColor: "#D33479", color: "white" }}
                                                    // onClick={() => {
                                                    //     window.open("http://vega.adap.io/swagger/index.html", "_blank")
                                                    // }}
                                                    >Refresh Key
                                                    </Button>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <NxFooter className="verscio-footer" companyName="© 2023 Altascio Technologies (I) Pvt. Ltd.," ExtraContent={[
            { name: 'Terms of Service' },
            { name: 'Privacy' },
        ]} />
    </>)
}