import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import './DockerHubEdit.css';
import { NxLoader, NxTitleTab, NxFooter } from '@afiplfeed/itr-app';
import Headers from '../../Screens/Headers/Headers';
import { ItrApiService } from '@afiplfeed/itr-ui';

export default function DockerHubEdit() {
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const location = useLocation()
    // const [gateway, setGateway] = useState({});
    const [data, setData] = useState({
        dockerServerID: 0,
        dockerServerAccountName: "",
        userName: "",
        password: "",
        storageAccountName: "",
        containerRegistryName: ""
    });

    useEffect(() => {
        if (!location.state || !location.state.dockerServerID) {
            history.replace('/repo-connect')
        } else {
            setData(location.state);
        }
    }, [])
    return (
        <div>
            <NxLoader state={loading} />
            <Headers />
            <NxTitleTab className="title-tab" title='Edit Docker Server' onCloseClick={() => history.goBack()} />
            <div className="card mt-0 sms-gateway-main">
                <div className="card-body">
                    <div className="container-form mb-3">
                        <div>
                            <div className="row mt-2">
                                {/* <h6>Payment Gateway API to send DLT Approved Mobile Verification Code for 2-Factor Authentication & Notifications. Contact software administrator for API End-Points & Notification Options.</h6> */}
                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-4">
                                    <label htmlFor="Organization">Docker Server Account Name</label>
                                    <input className="form-control mt-1" id="Organization" value={data.dockerServerAccountName} type="text" onChange={(e) => {
                                        setData({ ...data, dockerServerAccountName: e.target.value })
                                    }} />
                                </div>

                                <div className="col-12 col-sm-4 col-md-6 col-lg-6 col-xl-6 mt-4">
                                    <label>Username</label>
                                    <input className="form-control mt-1" id="Organization" value={data.userName} type="text" onChange={(e) => {
                                        setData({ ...data, userName: e.target.value })
                                    }} />
                                </div>

                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-4">
                                    <label htmlFor="Organization">Password</label>
                                    <input className="form-control mt-1" id="Organization" value={data.password} type="text" onChange={(e) => {
                                        setData({ ...data, password: e.target.value })
                                    }} />
                                </div>



                                <div className="col-12 col-sm-4 col-md-6 col-lg-6 col-xl-6 mt-4">
                                    <label>Storage Account Name</label>
                                    <input className="form-control mt-1" id="Organization" value={data.storageAccountName} type="text" onChange={(e) => {
                                        setData({ ...data, storageAccountName: e.target.value })
                                    }} />
                                </div>

                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-4">
                                    <label htmlFor="Organization"> Container Registry Name</label>
                                    <input className="form-control mt-1" id="Organization" value={data.containerRegistryName} type="text" onChange={(e) => {
                                        setData({ ...data, containerRegistryName: e.target.value })
                                    }} />
                                </div>


                            </div>


                            <div className="form-button nx-enquiry-buttons mt-5 mb-3">
                                <button className="btn btn-primary buttons" onClick={() => {
                                    setLoading(true);
                                    ItrApiService.POST({ url: 'CloudConnect/Configuration/DockerServer', data: data }).then(res => {
                                        if (res.Success == true) {
                                            if (res.result.isSuccess == true) {
                                                history.goBack();
                                            }
                                        }
                                        setLoading(false);
                                    })
                                }}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <NxFooter companyName="© 2022 Ithred Solutions" ExtraContent={[
                { name: 'Terms of Service' },
                { name: 'Privacy' },
            ]} />
        </div>
    )
}
