import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Redirect, HashRouter, Route } from 'react-router-dom';
import { Space } from 'antd';
import Login from '../Screens/login/login';
import Partner from '../partnerBoard/dashboard/dashboard';
import UserEntry from '../partnerBoard/userentry/index';
import Customer from '../customerBoard/dashboard/dashboard';
import Enquiry from '../partnerBoard/enquiry/index';
import Enrollment from '../customerBoard/enrollment/index';
import EnrollmentEntry from '../customerBoard/enrollmentEntry';
import Framework from '../customerBoard/framework/framework';
// import IdentityAccess from '../customerBoard/identityAccess/identityAccess';
import editUserEntry from '../partnerBoard/editUserEntry';
import Customers from '../partnerBoard/customers/customers';
import AddCustomers from '../partnerBoard/AddCustomers/AddCustomers';
import PartnerUsers from '../partnerBoard/PartnerUsers/PartnerUsers';
import AddPartnerUser from '../partnerBoard/AddPartnerUser/AddPartnerUser';
import OnBoarding from '../partnerBoard/OnBoarding/OnBoarding';
import Support from '../partnerBoard/Support/Support';
// import Feedback from '../partnerBoard/Feedback/Feedback';
import Feedback from '../customerBoard/nexTabs/feedback/feedback';
import LoginTheme from '../Screens/LoginTheme/LoginTheme';
// import EditIdentityAccess from '../customerBoard/editIdentityAccess/editIdentityAccess';
import EditEnrollmentEntry from '../customerBoard/editEnrollmentEntry';
import PlatformUser from '../customerBoard/nexTabs/PlatformUser/PlatformUser';
import CustomerPasswordPolicy from '../customerBoard/ConfigurationComponent/CustomerPasswordPolicy/CustomerPasswordPolicy';
import PartnerPasswordPolicy from '../partnerBoard/ConfigurstionComponent/PartnerPasswordPolicy/PartnerPasswordPolicy';
import AddPlatformUser from '../customerBoard/nexTabs/AddPlatformUser/AddPlatformUser';
import Notification from '../partnerBoard/ConfigurstionComponent/notification/notification';
import Audit from '../customerBoard/nexTabs/audit/audit';
import Configuration from '../customerBoard/nexTabs/Configuration/Adminstrator';
import ConfigurationTab from '../partnerBoard/NewTabs/ConfigurationTab/ConfigurationTab';
// import ManageUsers from '../customerBoard/nexTabs/users/ManageUsers/ManageUsers';
import ResetPassword from '../customerBoard/nexTabs/users/ResetPassword/ResetPassword';
import AppEnrollmentTab from '../partnerBoard/NewTabs/appEnrollmentTab/appEnrollmentTab';
import CarouselTab from '../partnerBoard/NewTabs/carouselTab/carouselTab';
import PartnerSubscriptionTab from '../partnerBoard/NewTabs/partnerSubscriptionTab/partnerSubscriptionTab';
import userAudit from '../customerBoard/nexTabs/logAnalytics/userAudit/userAudit';
import AccessLog from '../customerBoard/nexTabs/logAnalytics/accessLog/accessLog';
import DeviceManagement from '../customerBoard/nexTabs/logAnalytics/deviceManagement/deviceManagement';
import EmailSetup from '../customerBoard/nexTabs/notification/emailSetup/emailSetup';
import SmsGateway from '../customerBoard/nexTabs/notification/smsGateway/smsGateway';
import Profile from '../Screens/Profile/Profile';
import CustomerDashboard from '../customerBoard/nexTabs/customerBoard/customerBoardCounts/customerBoardCounts';
import CustomerUsers from '../customerBoard/nexTabs/users/users';
import CustomerMonitoring from '../customerBoard/nexTabs/monitoring/monitoring';
import CustomerSubscription from '../customerBoard/nexTabs/subscription/subscription';
import CustomerBilling from '../customerBoard/nexTabs/Billing/billing';
import CustomerSupport from '../customerBoard/nexTabs/support/support';
import PageCustomers from '../customerBoard/nexTabs/customers/customers';
import ResetForm from '../Screens/ResetPasswordForm/ResetPasswordForm';
import OnBoardingAdd from '../partnerBoard/OnBoardingAdd/OnBoardingAdd';
import AddSupport from '../customerBoard/nexTabs/AddSupport/AddSupport';
import BillerInformation from '../customerBoard/nexTabs/BillerInformation/BillerInformation';
import Taxation from '../customerBoard/nexTabs/Taxation/Taxation';
import AddTaxation from '../customerBoard/nexTabs/AddTaxation/AddTaxation';
import OnBoardingEdit from '../partnerBoard/OnBoardingEdit/OnBoardingEdit';
import StoreConnectList from '../customerBoard/StoreConnectList/StoreConnectList';
import StoreConnectEntry from '../customerBoard/StoreConnectEntry/StoreConnectEntry';
import ServiceDetails from '../customerBoard/ServiceDetails/ServiceDetails';
import CommsGateway from '../customerBoard/nexTabs/Configuration/CommsGateway/CommsGateway';
import Adminstrator from '../customerBoard/nexTabs/Configuration/Adminstrator';
import IdleTimeout from '../customerBoard/nexTabs/IdleTimeout/IdleTimeout';
import BillngConfiguration from '../customerBoard/nexTabs/Configuration/BillngConfiguration/BillngConfiguration';
import AddBillingRules from '../customerBoard/nexTabs/AddBillingRules/AddBillingRules';
import CloudConnect from '../customerBoard/CloudConnect/CloudConnect';
import CloudConnectAdd from '../customerBoard/CloudConnectAdd/CloudConnectAdd';
import DockerConnect from '../customerBoard/nexTabs/Configuration/DockerConnect/DockerConnect';
import RepoConnect from '../customerBoard/nexTabs/Configuration/RepoConnect/RepoConnect';
import GitRepoAdd from '../customerBoard/nexTabs/Configuration/RepoConnect/GitRepoAdd/GitRepoAdd';
import DockerHubAdd from '../customerBoard/DockerHubAdd/DockerHubAdd';
import AzureRepoAdd from '../customerBoard/nexTabs/Configuration/RepoConnect/AzureRepoAdd/AzureRepoAdd';
import AzureContainerRegisterAdd from '../customerBoard/AzureContainerRegisterAdd/AzureContainerRegisterAdd';
import Performance from '../customerBoard/nexTabs/Performance/Performance'
import DockerHubEdit from '../customerBoard/DockerHubEdit/DockerHubEdit';
import AzureContainerRegisterEdit from '../customerBoard/AzureContainerRegisterEdit/AzureContainerRegisterEdit';
import GitRepoEdit from '../customerBoard/nexTabs/Configuration/RepoConnect/GitRepoEdit/GitRepoEdit';
import AzureRepoEdit from '../customerBoard/nexTabs/Configuration/RepoConnect/AzureRepoEdit/AzureRepoEdit';
import ProtectedRoute from './ProtectedRoutes';
import BillingRulesEdit from '../customerBoard/nexTabs/BillingRulesEdit/BillingRulesEdit';
import TaxationEdit from '../customerBoard/nexTabs/TaxationEdit/TaxationEdit';
import CloudConnectEdit from '../customerBoard/CloudConnectEdit/CloudConnectEdit';
import EditSupport from '../customerBoard/nexTabs/EditSupport/EditSupport';
import DatabaseServerAdd from '../customerBoard/DatabaseServerAdd/DatabaseServerAdd';
import DatabaseServerEdit from '../customerBoard/DatabaseServerEdit/DatabaseServerEdit';
import EditProfile from '../Screens/Profile/EditProfile';
import CustomerSummary from '../partnerBoard/CustomerSummary/CustomerSummary';
import AddCurrencyConv from '../customerBoard/nexTabs/AddCurrencyConv/AddCurrencyConv';
import EditCurrencyConv from '../customerBoard/nexTabs/EditCurrencyConv/EditCurrencyConv';
import ManageAccess from '../customerBoard/nexTabs/ManageAccess/ManageAccess';
import AddManageAccess from '../customerBoard/nexTabs/AddManageAccess/AddManageAccess';
import EditManageAccess from '../customerBoard/nexTabs/EditManageAccess/EditManageAccess';
import PartnerManagement from '../customerBoard/nexTabs/PartnerManagement/PartnerManagement';
import AddPartnerManagement from '../customerBoard/nexTabs/AddPartnerManagement/AddPartnerManagement';
import EditPartnerManagement from '../customerBoard/nexTabs/EditPartnerManagement/EditPartnerManagement';
import CommisionPlan from '../customerBoard/nexTabs/CommisionPlan/CommisionPlan';
import AddCommisionPlan from '../customerBoard/nexTabs/AddCommisionPlan/AddCommisionPlan';
import EditCommisionPlan from '../customerBoard/nexTabs/EditCommisionPlan/EditCommisionPlan';
import PartnerDashboard from '../ParnterBoards/PartnerDashboards/PartnerDashboard';
import LicenseVault from '../customerBoard/nexTabs/Configuration/LicenseVault/LicenseVault';
import EditCustomerSummary from '../partnerBoard/EditCustomerSummary/EditCustomerSummary';
import DownloadHome from '../customerBoard/Download/downloadHome';
import AccessHome from '../customerBoard/Access/accessHome';
import ManageUsers from '../customerBoard/ManageUsers/ManageUsers';
import IdentityAccess from '../customerBoard/ManageUsers/identityAccess/identityAccess';
import EditIdentityAccess from '../customerBoard/ManageUsers/editIdentityAccess/editIdentityAccess';
import SecurityHome from '../customerBoard/Security/securityHome';
import EnrollmentHome from '../customerBoard/ManageEnrollment/enrollmentHome';
import AppStores from '../customerBoard/AppStore/appStore';
import OnlineHelpHome from '../customerBoard/Download/OnlineHelp/onlinehelpHome';
import AuthenticationKey from '../customerBoard/Download/AuthenticationKey/authenticationKey';
import AppServiceHome from '../customerBoard/AppServices/appServiceHome';
import MarketplaceHome from '../customerBoard/Marketplace/marketplaceHome';
import Repository from '../customerBoard/nexTabs/Configuration/Repositary/Repositary';
import DatabaseServer from '../customerBoard/nexTabs/Configuration/DatabaseServer/DatabaseServer';
import DatabaseServers from '../customerBoard/nexTabs/Configuration/DatabaseServer/DatabaseServer';
import Registry from '../customerBoard/nexTabs/Configuration/Registry/Registery';
import Registery from '../customerBoard/nexTabs/Configuration/Registry/Registery';
import Customintegration from '../customerBoard/nexTabs/PaymentGateway/Customintegration/Customintegration';
import Environment from '../customerBoard/nexTabs/Configuration/Environment/Environment';
import Versions from '../customerBoard/nexTabs/Configuration/Versions/Versions';
import SecurityTwoFactor from '../customerBoard/Security/SecurityTwoFactor';
import ApiManagement from '../customerBoard/nexTabs/ApiManagement/ApiManagement';
import Federation from '../customerBoard/nexTabs/Federation/Federation';
import Developers from '../customerBoard/nexTabs/Developers/Developers';
import AddDevelopers from '../customerBoard/nexTabs/AddDevelopers/AddDevelopers';
import VirtualMachineAdd from '../customerBoard/nexTabs/Configuration/ManageResources/VirtualMachine/VirtualMachineAdd/VirtualMachineAdd';
import StorageAdd from '../customerBoard/nexTabs/Configuration/ManageResources/Storage/StorageAdd/StorageAdd';
import ClusterAdd from '../customerBoard/nexTabs/Configuration/ManageResources/Cluster/ClusterAdd/ClusterAdd';
import ManageCluster from '../customerBoard/nexTabs/Configuration/ManageResources/ManageCluster/ManageCluster';
import SystemIntegratorHome from '../customerBoard/Access/SystemIntegrator/systemIntegratorHome';
import ServiceProviderHome from '../customerBoard/Access/ServiceProvider/serviceProviderHome';
import AddServiceProviderManagement from '../customerBoard/nexTabs/ServiceProviderManagement/AddServiceProviderManagement';
import LicenseManagement from '../customerBoard/LicenseManagement/licenseManagement';
import Invoice from '../customerBoard/nexTabs/Invoice/Invoice';

export default function Routes() {

    // let getAuth = sessionStorage.getItem('userInfo');

    // const checkAuth = () => {
    //     if (!getAuth) {
    //         <Redirect to='/dashboard' />
    //     }
    // }

    useEffect(() => {
        // checkAuth();
    }, [])

    return (
        <HashRouter>
            <Switch>
                <Route exact path='/' component={LoginTheme} />
                <Route exact path='/reset-password' component={ResetForm} />
                {/*customer-components */}
                {/* <ProtectedRoute exact path="/customer-board" component={Customer} /> */}
                <ProtectedRoute exact path="/dashboard" component={CustomerDashboard} />
                {/* <ProtectedRoute exact path="/partner-dashboard" component={ParnterBoards} /> */}
                <ProtectedRoute exact path="/users" component={CustomerUsers} />

                <ProtectedRoute exact path="/customers" component={PageCustomers} />

                {/* <ProtectedRoute exact path="/performance" component={CustomerMonitoring} /> */}
                <ProtectedRoute exact path="/performance" component={Performance} />
                <ProtectedRoute exact path="/billing" component={CustomerBilling} />
                <ProtectedRoute exact path="/biller-information" component={BillerInformation} />
                <ProtectedRoute exact path="/federation" component={Federation} />
                {/* <ProtectedRoute exact path="/paymentgateway" component={PaymentGateway} /> */}
                <ProtectedRoute exact path="/paymentgateway" component={Customintegration} />

                <ProtectedRoute exact path='/taxation' component={Taxation} />
                <ProtectedRoute exact path='/taxation/add' component={AddTaxation} />
                <ProtectedRoute exact path="/support" component={CustomerSupport} />
                <ProtectedRoute exact path="/support/add" component={AddSupport} />
                <ProtectedRoute exact path="/support/edit" component={EditSupport} />

                <ProtectedRoute exact path='/profile' component={Profile} />
                <ProtectedRoute exact path='/edit/profile' component={EditProfile} />

                <ProtectedRoute exact path="/appservices" component={AppServiceHome} />
                {/* <ProtectedRoute exact path="/apps-store" component={AppStores} /> */}
                {/* <ProtectedRoute exact path="/apps-store/manage-apps" component={Enrollment} />
                <ProtectedRoute exact path="/apps-store/store-connect" component={StoreConnectList} />
                <ProtectedRoute exact path="/apps-store/service-details" component={ServiceDetails} />
                <ProtectedRoute exact path="/apps-store/store-connect/add" component={StoreConnectEntry} /> */}

                <ProtectedRoute exact path="/apps-store/service-details" component={ServiceDetails} />
                <ProtectedRoute exact path="/apps-store/store-connect/add" component={StoreConnectEntry} />

                <ProtectedRoute exact path="/enrollment" component={EnrollmentHome} />
                <ProtectedRoute exact path="/enrollment/manage-apps" component={Enrollment} />
                <ProtectedRoute exact path="/enrollment/store-connect" component={StoreConnectList} />
                <ProtectedRoute exact path="/enrollment/service-details" component={ServiceDetails} />
                <ProtectedRoute exact path="/enrollment/store-connect/add" component={StoreConnectEntry} />

                {/* developers */}
                <ProtectedRoute exact path="/developers-access" component={Developers} />
                <ProtectedRoute exact path="/developers-access/add" component={AddDevelopers} />

                {/* Manage-Access-Management */}
                <ProtectedRoute exact path="/manage-access" component={ManageAccess} />
                <ProtectedRoute exact path="/manage-access/add" component={AddManageAccess} />
                <ProtectedRoute exact path="/manage-access/edit" component={EditManageAccess} />

                <ProtectedRoute exact path="/partner-management" component={PartnerManagement} />
                <ProtectedRoute exact path="/partner-management/add" component={AddPartnerManagement} />
                <ProtectedRoute exact path="/partner-management/edit" component={EditPartnerManagement} />

                
                <ProtectedRoute exact path="/serviceprovider-management/add" component={AddServiceProviderManagement} />


                {/* commision-plan */}
                <ProtectedRoute exact path="/commision-plan" component={CommisionPlan} />
                <ProtectedRoute exact path="/commision-plan/add" component={AddCommisionPlan} />
                <ProtectedRoute exact path="/commision-plan/edit" component={EditCommisionPlan} />


                <ProtectedRoute exact path="/partner-board" component={Partner} />

                {/* enquiry-form */}
                <ProtectedRoute exact path='/enquiry' component={Enquiry} />
                <ProtectedRoute exact path='/enquiry/add' component={UserEntry} />
                <ProtectedRoute exact path='/enquiry/edit' component={editUserEntry} />

                {/* customer */}
                <ProtectedRoute exact path='/customer' component={Customers} />
                <ProtectedRoute exact path='/customer/summary' component={CustomerSummary} />
                <ProtectedRoute exact path='/customer/summary/edit' component={EditCustomerSummary} />
                <ProtectedRoute exact path='/customer/add' component={AddCustomers} />

                {/* partner-users */}
                <ProtectedRoute exact path='/partner-user' component={PartnerUsers} />
                <ProtectedRoute exact path='/partner-user/add' component={AddPartnerUser} />

                {/* onBoarding */}
                <ProtectedRoute exact path='/onboarding' component={OnBoarding} />
                <ProtectedRoute exact path="/onboarding/add" component={OnBoardingAdd} />
                <ProtectedRoute exact path="/onboarding/edit" component={OnBoardingEdit} />

                {/* support */}
                {/* <ProtectedRoute exact path='/support' component={Support} /> */}

                {/* feedback */}
                {/* <ProtectedRoute exact path='/feedback' component={Feedback} /> */}
                <ProtectedRoute exact path='/feedback' component={Feedback} />

                {/* Enrollment-Tab */}
                <ProtectedRoute exact path="/enrollment" component={AppEnrollmentTab} />
                <ProtectedRoute exact path="/carousel" component={CarouselTab} />
                <ProtectedRoute exact path="/subscription" component={PartnerSubscriptionTab} />

                {/* enrollment */}
                <ProtectedRoute exact path="/distribution/appsenrollment" component={Enrollment} />
                <ProtectedRoute exact path="/enrollment/add" component={EnrollmentEntry} />
                <ProtectedRoute exact path="/enrollment/edit" component={EditEnrollmentEntry} />

                {/* framework */}
                {/* <ProtectedRoute exact path="/framework/iam" component={Framework} />
                <ProtectedRoute exact path="/framework/iam/add" component={IdentityAccess} />
                <ProtectedRoute exact path="/framework/iam/edit" component={EditIdentityAccess} /> */}

                <ProtectedRoute exact path='/platformUser' component={PlatformUser} />
                <ProtectedRoute exact path='/platformUser/add' component={AddPlatformUser} />
                {/* <ProtectedRoute exact path='/platformuser/edit' component={PlatformUser} /> */}

                <ProtectedRoute exact path='/PasswordPolicy' component={CustomerPasswordPolicy} />
                <ProtectedRoute exact path='/Password-Policy' component={PartnerPasswordPolicy} />

                {/* security-two-factor */}
                <ProtectedRoute exact path='/security-two-factor' component={SecurityTwoFactor} />

                {/* partner-notification-page */}
                <ProtectedRoute exact path='/notification' component={Notification} />
                <ProtectedRoute exact path='/log-analytics' component={Audit} />

                {/* customer-configuration */}
                <ProtectedRoute exact path='/administrator' component={Adminstrator} />
                <ProtectedRoute exact path='/billing-rules/add' component={AddBillingRules} />
                <ProtectedRoute exact path='/currency-conversion/add' component={AddCurrencyConv} />
                <ProtectedRoute exact path='/currency-conversion/edit' component={EditCurrencyConv} />
                <ProtectedRoute exact path='/administrator/comms-gateway' component={CommsGateway} />


                {/* partner-configuration */}
                <ProtectedRoute exact path='/partner-Configuration' component={ConfigurationTab} />

                {/* users-management */}
                {/* <ProtectedRoute exact path='/manage-users' component={ManageUsers} /> */}
                <ProtectedRoute exact path='/reset-users' component={ResetPassword} />

                {/* log-analytics */}
                <ProtectedRoute exact path="/user-audit" component={userAudit} />
                <ProtectedRoute exact path="/access-log" component={AccessLog} />
                <ProtectedRoute exact path="/device-management" component={DeviceManagement} />

                {/* notification-customer */}
                <ProtectedRoute exact path="/email-setup" component={EmailSetup} />
                <ProtectedRoute exact path="/sms-gateway" component={SmsGateway} />
                <ProtectedRoute exact path="/idle-timeout" component={IdleTimeout} />
                <ProtectedRoute exact path="/API-management" component={ApiManagement} />

                <ProtectedRoute exact path="/cloud-connect" component={CloudConnect} />
                <ProtectedRoute exact path="/cloud-connect/add" component={CloudConnectAdd} />
                <ProtectedRoute exact path="/cloud-connect/edit" component={CloudConnectEdit} />
                <ProtectedRoute exact path="/databaseserver/add" component={DatabaseServerAdd} />
                <ProtectedRoute exact path="/databaseserver/edit" component={DatabaseServerEdit} />
                <ProtectedRoute exact path='/azure-container-register/add' component={AzureContainerRegisterAdd} />

                <ProtectedRoute exact path="/docker-connect" component={DockerConnect} />
                <ProtectedRoute exact path="/repo-connect" component={RepoConnect} />
                <ProtectedRoute exact path="/cluster" component={ManageCluster} />

                {/* manage-resources */}
                <ProtectedRoute exact path="/virtual-machine/add" component={VirtualMachineAdd} />
                <ProtectedRoute exact path="/storage/add" component={StorageAdd} />
                <ProtectedRoute exact path="/cluster/add" component={ClusterAdd} />

                <ProtectedRoute exact path="/azure-repo/add" component={AzureRepoAdd} />
                <ProtectedRoute exact path="/git-repo/add" component={GitRepoAdd} />
                <ProtectedRoute exact path="/docker-hub/add" component={DockerHubAdd} />
                <ProtectedRoute exact path="/license-vault" component={LicenseVault} />
                {/* new */}
                <ProtectedRoute exact path="/repositary" component={Repository} />
                <ProtectedRoute exact path="/database-server" component={DatabaseServers} />
                <ProtectedRoute exact path="/registery" component={Registery} />
                <ProtectedRoute exact path="/environment" component={Environment} />
                <ProtectedRoute exact path="/version" component={Versions} />


                {/* New Routes */}
                <ProtectedRoute exact path="/docker-hub/edit" component={DockerHubEdit} />
                <ProtectedRoute exact path='/azure-container-register/edit' component={AzureContainerRegisterEdit} />
                <ProtectedRoute exact path="/git-repo/edit" component={GitRepoEdit} />
                <ProtectedRoute exact path="/azure-repo/edit" component={AzureRepoEdit} />

                <ProtectedRoute exact path='/billing-rules/edit' component={BillingRulesEdit} />
                <ProtectedRoute exact path='/taxation/edit' component={TaxationEdit} />

                <ProtectedRoute exact path='/security' component={SecurityHome} />

                <ProtectedRoute exact path='/developers' component={DownloadHome} />
                <ProtectedRoute exact path='/developers/onlinehelp' component={OnlineHelpHome} />
                <ProtectedRoute exact path='/developers/authenticationkey' component={AuthenticationKey} />

                <ProtectedRoute exact path='/access' component={AccessHome} />
                <ProtectedRoute exact path='/access/manage-user' component={ManageUsers} />
                <ProtectedRoute exact path='/access/manage-user/add' component={IdentityAccess} />
                <ProtectedRoute exact path="/access/manage-user/edit" component={EditIdentityAccess} />
                <ProtectedRoute exact path="/access/systemIntegrator" component={SystemIntegratorHome} />
                <ProtectedRoute exact path="/access/serviceProvider" component={ServiceProviderHome} />

                <ProtectedRoute exact path='/marketplace' component={MarketplaceHome} />
                <ProtectedRoute exact path='/marketplace/billing-configuration' component={BillngConfiguration} />
                <ProtectedRoute exact path='/marketplace/storefront' component={AppStores} />
                <ProtectedRoute exact path='/marketplace/subscriptions' component={LicenseManagement} />                
                <ProtectedRoute exact path='/marketplace/invoices' component={Invoice} />

                {/* New Routes */}

                {/* <ProtectedRoute exact path="/repo-connect" component={RepoConnect} /> */}


                {/* error-component */}
                {/* <ProtectedRoute exact path="*" component={IdentityAccess}  /> */}
            </Switch>

            {/* <div className='footer'>
                <Space>
                    <span> © 2022 Ithred Solutions. </span>
                </Space>
            </div> */}
        </HashRouter>
    )
}
