import React, { useEffect, useState } from 'react';
import { NxLoader, NxTitleTab, NxActionTab, NxGridList } from '@afiplfeed/itr-app';
import { useHistory } from 'react-router-dom';
import { ItrApiService, ItrAuthService } from '@afiplfeed/itr-ui';
import Headers from '../../../Screens/Headers/Headers';
import { NotificationManager } from 'react-notifications';
import moment from 'moment';

function EditSupport(props) {

    // edit-user-list
    const getEditData = async (getSupportId) => {
        if (!getSupportId) {
            history.push('/support-tickets');
            return false;
        }
        let result = await ItrApiService.GET({
            url: `ITOps/Support/${getSupportId}`,
        });
        if (result.result.isSuccess == true) {
            let data = result.result.data;
            if (data === null) {
                // alert(result.result.message);
            }
            else {
                setEditSupport(data);
            }
            // setCustomers(getCustomerDetails);
        }
        else if (result.result.isSuccess == false) {
            // alert(result.result.message);
            // sessionStorage.clear();
            // history.push('/')
        }
        return true;
    }

    const [load, setLoad] = useState(false);
    const [EditSupport, setEditSupport] = useState({
        subject: '',
        apps: '',
        priority: '',
        supportType: '',
        status: '',
        feedback: '',
        Attachments: ''
    });

    const [userApps, setUserApps] = useState([])

    let history = useHistory();
    useEffect(() => {
        let getSupportId = sessionStorage.getItem('editSupportId');
        getEditData(getSupportId);

        ItrApiService.GET({ url: 'DevOps/Enrollment/ListAllApp' }).then(res => {
            if (res.Success == true) {
                if (res.result.isSuccess == true) {
                    setUserApps(res.result.data)
                }
            }
        });

    }, []);

    return (
        <div >
            <NxLoader state={load} />
            <Headers />
            <div>
                <NxTitleTab title='Edit Support' onCloseClick={() => {
                    history.goBack();
                }} />
                <div className="px-5">
                    <div className="row" style={{ display: 'flex', width: '100%' }}>

                        <div className='col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 mt-3'>
                            <label>Details</label>
                            <hr />
                            <div className='row '>
                                <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2'>
                                    <div className='row  d-flex justify-content-between align-items-center'>
                                        <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                                            <h6 className='m-0'>Priority:</h6>
                                        </div>
                                        <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                                            <select className="form-select" id="prority" value={EditSupport.priority}
                                                onChange={(e) => setEditSupport({ ...EditSupport, priority: e.target.value })}>
                                                <option value="" disabled selected>--please select--</option>
                                                <option value="Critical">Critical</option>
                                                <option value="High">High</option>
                                                <option value="Low">Low</option>
                                                <option value="Medium">Medium</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2'>
                                    <div className='row  d-flex justify-content-between align-items-center'>
                                        <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                                            <h6 className='m-0'>Status:</h6>
                                        </div>
                                        <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                                            <select className="form-select" id="status" value={EditSupport.status}
                                                onChange={(e) => setEditSupport({ ...EditSupport, status: e.target.value })}>
                                                <option value="" disabled selected>--please select--</option>
                                                <option value="Open">Open</option>
                                                <option value="InProgress">InProgress</option>
                                                <option value="Close">Close</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2'>
                                    <div className='row  d-flex justify-content-between align-items-center'>
                                        <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                                            <h6 className='m-0'>Type:</h6>
                                        </div>
                                        <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                                            <select className="form-select" id="support-type" value={EditSupport.supportType}
                                                onChange={(e) => setEditSupport({ ...EditSupport, supportType: e.target.value })}>
                                                <option value="" disabled selected>--please select--</option>
                                                <option value="Bug">Bug</option>
                                                <option value="Change Request">Change Request</option>
                                                <option value="Enhancement">Enhancement</option>
                                                <option value="New Requirement">New Requirement</option>
                                                <option value="Service Request">Service Request</option>
                                                <option value="Story">Story</option>
                                                <option value="Task">Task</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className='col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 mt-3'>
                            <label>People</label>
                            <hr />
                            <div className='row '>
                                <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2'>
                                    <div className='row  d-flex justify-content-between align-items-center'>
                                        <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                                            <h6 className='m-0'>Assignee:</h6>
                                        </div>
                                        <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                                            <p className='m-0'>{EditSupport.assignee}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2'>

                                </div>
                                <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2'>
                                    <div className='row  d-flex justify-content-between align-items-center'>
                                        <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                                            <h6 className='m-0'>Reporter:</h6>
                                        </div>
                                        <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                                            <p className='m-0'>{EditSupport.reporterTo ? EditSupport.reporterTo : '-'}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className='col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 mt-3'>
                            <label>Feedback</label>
                            <hr />
                            <textarea rows="3" className="form-control" id="feedback" value={EditSupport.feedback}
                                onChange={(e) => setEditSupport({ ...EditSupport, feedback: e.target.value })} />
                        </div>

                        <div className='col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 mt-3'>
                            <label>Dates</label>
                            <hr />
                            <div className='row '>
                                <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2'>
                                    <div className='row  d-flex justify-content-between align-items-center'>
                                        <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                                            <h6 className='m-0'>Created:</h6>
                                        </div>
                                        <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                                            <p className='m-0'>{moment(EditSupport.createdDate).format('DD-MM-YYYY')}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2'>

                                </div>
                                <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2'>
                                    <div className='row  d-flex justify-content-between align-items-center'>
                                        <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                                            <h6 className='m-0'>Updated:</h6>
                                        </div>
                                        <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                                            <p className='m-0'>{moment(EditSupport.raisedDate).format('DD-MM-YYYY')}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2'>

                                </div>
                                <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2'>
                                    <div className='row  d-flex justify-content-between align-items-center'>
                                        <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                                            <h6 className='m-0'>Due Date:</h6>
                                        </div>
                                        <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                                            <p className='m-0'>{EditSupport.dueDate ? EditSupport.dueDate : '-' }</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 mt-3'>
                            <label>Attachments</label>
                            <hr />

                            <div className='row '>
                                <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2'>
                                    <div className='row  d-flex justify-content-between align-items-center'>
                                        <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-12'>
                                            <input type="file" className="form-control form-control-sm" id="attachments" />

                                        </div>
                                    </div>
                                </div>
                                <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-12 mt-3 mb-5'>
                                    <div className='table-hover'>
                                        <table className='table table-sm table-hover'>
                                            <thead>
                                                <tr>
                                                    <th>File Name</th>
                                                    <th>Date</th>
                                                    <th>Size</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>


                        {/* <div className="left-form" style={{ width: '50%' }}>
                            <div className="mt-2">
                                <label htmlFor="subject">Subject</label>
                                <input type="text" className="form-control" id="subject" value={EditSupport.subject}
                                    onChange={(e) => setEditSupport({ ...EditSupport, subject: e.target.value })} />
                            </div>

                            <div className="mt-2">
                                <label htmlFor="apps">Apps</label>
                                <select className="form-select" id="prority" value={EditSupport.appCode}
                                    onChange={(e) => setEditSupport({ ...EditSupport, appCode: e.target.value })}>
                                    <option value="" disabled selected>--please select--</option>
                                    {userApps.map((item, index) => {
                                        return <option key={index} value={item.serviceName}>{item.serviceName}</option>
                                    })}
                                </select>
                            </div>

                            <div className="mt-2">
                                <label htmlFor="prority">Prority</label>
                                <select className="form-select" id="prority" value={EditSupport.priority}
                                    onChange={(e) => setEditSupport({ ...EditSupport, priority: e.target.value })}>
                                    <option value="" disabled selected>--please select--</option>
                                    <option value="Critical">Critical</option>
                                    <option value="High">High</option>
                                    <option value="Low">Low</option>
                                    <option value="Medium">Medium</option>
                                </select>
                            </div>

                            <div className="mt-2">
                                <label htmlFor="support-type">Support Type</label>
                                <select className="form-select" id="support-type" value={EditSupport.supportType}
                                    onChange={(e) => setEditSupport({ ...EditSupport, supportType: e.target.value })}>
                                    <option value="" disabled selected>--please select--</option>
                                    <option value="Bug">Bug</option>
                                    <option value="Change Request">Change Request</option>
                                    <option value="Enhancement">Enhancement</option>
                                    <option value="New Requirement">New Requirement</option>
                                    <option value="Service Request">Service Request</option>
                                    <option value="Story">Story</option>
                                    <option value="Task">Task</option>
                                </select>
                            </div>

                            <div className="mt-2">
                                <label htmlFor="status">Status</label>
                                <select className="form-select" id="status" value={EditSupport.status}
                                    onChange={(e) => setEditSupport({ ...EditSupport, status: e.target.value })}>
                                    <option value="" disabled selected>--please select--</option>
                                    <option value="Open">Open</option>
                                    <option value="InProgress">InProgress</option>
                                    <option value="Close">Close</option>
                                </select>
                            </div>

                            <div className="mt-2">
                                <label htmlFor="feedback">Feedback</label>
                                <textarea rows="3" className="form-control" id="feedback" value={EditSupport.feedback}
                                    onChange={(e) => setEditSupport({ ...EditSupport, feedback: e.target.value })} />
                            </div>
                        </div>

                        <div className="right-form" style={{ width: '50%' }}>
                            <div className="mt-2">
                                <label htmlFor="attachments">Attachments</label>
                                <input type="file" className="form-control" id="attachments" />
                            </div>
                        </div> */}
                    </div>
                    {/* <hr /> */}
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }} className="px-5 pb-5">
                    <button className="btn btn-primary buttons" onClick={async () => {
                        setLoad(true);
                        let result = await ItrApiService.POST({
                            data: EditSupport,
                            url: "ITOps/Support/SaveSupport",
                        })
                        if (result.Success == true) {
                            if (result.result.isSuccess == true) {
                                setLoad(false);
                                NotificationManager.success('Successfully Updated');
                                sessionStorage.removeItem('editSupportId');
                                history.goBack();
                            } else {
                                NotificationManager.error('Failed to update');
                            }
                        }
                        else {
                            NotificationManager.error('Failed to update');
                        }
                        setLoad(false)
                    }}
                    > Save</button>
                    {/* <button className="btn close btn-outline-light" style={{ marginLeft: '20px',backgroundColor:'gray' }} > close</button> */}
                </div>
            </div >
        </div >
    );
}

export default EditSupport;