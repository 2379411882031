import { faInfo, faInfoCircle, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NxHeader, NxLoader, NxTitleTab } from '@afiplfeed/itr-app';
import React, { useEffect, useState, useRef } from 'react';
import Headers from '../Headers/Headers';
import './Profile.css'
import AvtImg from '../../assets/profile.png';
import { ReactComponent as Star } from '../../assets/Star.svg';
import { ReactComponent as StarFill } from '../../assets/StarFill.svg';
import { Tabs } from 'antd';
import avatar from '../../assets/avatar.png';
import { useHistory } from 'react-router';
import ChangePassword from './ChangePassword';
import TwoFactor from './TwoFactor';
import { ItrApiService, ItrAuthService } from '@afiplfeed/itr-ui';
import AdminPinGenerator from './AdminPinGenerator';
import moment, { utc } from 'moment';
import { img2base64 } from '../../config/ImageFunction';
import { NotificationManager } from 'react-notifications';
import defaultProfile from '../../assets/defaultProfile.png';


function EditProfile() {

    const fileRef = useRef();

    const [profileData, setProfileData] = useState(
        {
            profileDetailsID: 0,
            userCode: "",
            dob: "",
            gender: "",
            phoneNumber: "",
            photo: "",
            addressLine1: "",
            addressLine2: "",
            city: "",
            state: "",
            zipCode: "",
            country: "",
            site: "",
            lastPasswordUpdateOn: "",
            portalAccess: {
                portalAccessID: 0,
                accessTypeID: 0,
                entityCode: "",
                userCode: "",
                userName: "",
                password: "",
                displayName: "",
                isAccess: true,
                isFirstLogin: true,
                tenantCode: "",
                customerCode: "",
                emailAddress: "",
                isActive: true,
                isEnable2FA: true
            }
        }
    );

    const getProfileDetails = async () => {
        setLoading(true);
        let result = await ItrApiService.GET({
            url: 'PAM/ProfileDetails',
        });
        if (result.result.isSuccess == true) {
            let getPfofileDetails = result.result.data;
            if (result.result.data) {
                setProfileData(getPfofileDetails);
            }
        }
        else if (result.result.isSuccess == false) {
        }
        setLoading(false);
    }


    const saveProfile = async () => {
        setLoading(true);
        let res = await ItrApiService.POST({
            url: 'PAM/ProfileDetails/SaveProfileDetails',
            data: { ...profileData, dob: moment(profileData.dob).format() }
        });
        if (res.Success == true) {
            if (res.result.isSuccess == true) {
                NotificationManager.success('Profile Updated Successfully');
                history.push('/profile');
                setLoading(false);
            }
        }
        else {
            setLoading(false);
            NotificationManager.success(res.result.message);
        }
    }

    const [loading, setLoading] = useState(false);
    const { TabPane } = Tabs;
    const history = useHistory();

    const [changer, setChanger] = useState(false);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const showModal = () => {
        setIsModalVisible(true);
    };
    const handleOk = () => {
        setIsModalVisible(false);
    };
    const handleCancel = () => {
        setIsModalVisible(false);
    };

    // ChangePassword
    const [isChangePass, setIsChangePass] = useState(false);
    const showModal1 = () => {
        setIsChangePass(true);
    };
    const handleOk1 = () => {
        setIsChangePass(false);
    };
    const handleCancel1 = () => {
        setIsChangePass(false);
    };
    // ChangePassword


    const [isAdminPin, setIsAdminPin] = useState(false);
    const showModal2 = () => {
        setIsAdminPin(true);
        setChanger(!changer)
    };
    const handleOk2 = () => {
        setIsAdminPin(false);
    };
    const handleCancel2 = () => {
        setIsAdminPin(false);
    };


    useEffect(() => {
        getProfileDetails();
    }, [])

    return (
        <div>
            <NxLoader state={loading} />
            <ChangePassword
                isModalVisible={isChangePass}
                handleCancel={handleCancel1}
                handleOk={handleOk1}
                getProfileDetails={getProfileDetails}
            />

            <TwoFactor
                profileDetails={profileData}
                isModalVisible={isModalVisible}
                handleCancel={handleCancel}
                handleOk={handleOk}
                getProfileDetails={getProfileDetails}
            />

            <AdminPinGenerator
                profileDetails={profileData}
                isModalVisible={isAdminPin}
                handleCancel={handleCancel2}
                handleOk={handleOk2}
                changer={changer}
                getProfileDetails={getProfileDetails}
            />

            <NxLoader state={loading} />
            <Headers />
            <NxTitleTab className="title-tab" title='Edit Profile' onCloseClick={() => {
                history.push('/profile');
            }} />
            <div className='m-4 mt-0'>
                <div className='row w-100'>
                    <div className='col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 p-4'>
                        <div className='row'>
                            <div className='col-12 text-center'>
                                <img src={profileData?.photo ? profileData?.photo : defaultProfile} width='80%' />
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-12 d-flex justify-content-between">
                                <button className='btn btn-sm upload-image-buttons' onClick={() => fileRef.current.click()}>
                                    Choose Photo
                                </button>
                                <input
                                    id='profile'
                                    ref={fileRef}
                                    onChange={(e) => {
                                        let img = e.target.files[0];
                                        const fileReader = new FileReader();
                                        fileReader.readAsDataURL(img);
                                        fileReader.onload = async () => {
                                            setProfileData({ ...profileData, photo: fileReader.result })
                                            return fileReader.result
                                        }
                                        fileReader.onerror = async () => {
                                            return false
                                        }
                                    }}
                                    multiple={false}
                                    type="file"
                                    hidden
                                />
                                {/* <button className="btn btn-sm upload-image-buttons"
                                    onClick={() => {
                                        let img = e.target.files[0];
                                        const fileReader = new FileReader();
                                        fileReader.readAsDataURL(img)
                                        fileReader.onload = async () => {
                                            setProfileData({ ...profileData, photo: fileReader.result })
                                            return fileReader.result
                                        }
                                        fileReader.onerror = async () => {
                                            return false
                                        }
                                    }}
                                >Choose Photo</button> */}
                                <button className="btn btn-sm upload-image-buttons"
                                    onClick={(e) => {
                                        document.getElementById('profile').value = null;
                                        setProfileData({ ...profileData, photo: "" })
                                    }}
                                >Remove Photo</button>
                            </div>
                        </div>
                        <div className='row mt-4'>
                            <div className='col-12'>
                                <h5>
                                    {profileData.portalAccess.userName}
                                </h5>
                                <span className='profile-sub-name'>
                                    {profileData.portalAccess.displayName}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-sm-12 col-md-8 col-lg-9 col-xl-9 p-4 class-test-class'>
                        <Tabs defaultActiveKey="1">
                            <TabPane tab={
                                <span>Personal</span>
                            } key="1">
                                <div>

                                    <div className='pt-3'>
                                        <span className='profile-hr-heading'>Contact Info</span>
                                        <div className='mt-1 profile-contact-info'>
                                            <div className='row'>
                                                <div className='col-4 mt-2'>
                                                    <span className='frm-profile-heading'>Phone:</span>
                                                </div>
                                                <div className='col-4 mt-2'>
                                                    <span className='frm-profile-answer'>
                                                        <input className='form-control-sm form-control'
                                                            maxLength="15"
                                                            minLength="1"
                                                            value={profileData.phoneNumber}
                                                            onChange={(e) => setProfileData({ ...profileData, phoneNumber: e.target.value })}
                                                        />
                                                    </span>
                                                </div>
                                                <div className='col-4 mt-2' />

                                                <div className='col-4 mt-2'>
                                                    <span className='frm-profile-heading'>Address:</span>
                                                </div>
                                                <div className='col-4 mt-2'>
                                                    <span className='frm-profile-answer1'>
                                                        <textarea className='form-control form-control-sm w-100'
                                                            value={profileData.addressLine1}
                                                            onChange={(e) => setProfileData({ ...profileData, addressLine1: e.target.value })}
                                                        />
                                                    </span>
                                                </div>
                                                <div className='col-4 mt-2' />

                                                <div className='col-4 mt-2'>
                                                    <span className='frm-profile-heading'>Email:</span>
                                                </div>
                                                <div className='col-4 mt-2'>
                                                    <span className='frm-profile-answer' style={{ fontSize: '20px' }}>
                                                        {profileData.portalAccess.emailAddress}
                                                    </span>
                                                </div>
                                                <div className='col-4 mt-2' />

                                                <div className='col-4 mt-2'>
                                                    <span className='frm-profile-heading'>Site:</span>
                                                </div>
                                                <div className='col-4 mt-2'>
                                                    <span className='frm-profile-answer'>
                                                        <input className='form-control form-control-sm'
                                                            value={profileData.site}
                                                            onChange={(e) => setProfileData({ ...profileData, site: e.target.value })}
                                                        />
                                                    </span>
                                                </div>
                                                <div className='col-4 mt-2' />
                                            </div>
                                        </div>

                                    </div>

                                    <div className='pt-4'>
                                        <span className='profile-hr-heading'>Basic info</span>
                                        <div className='mt-1 profile-contact-info'>
                                            <div className='row'>
                                                <div className='col-4 mt-2'>
                                                    <span className='frm-profile-heading'>Birthday:</span>
                                                </div>
                                                <div className='col-4 mt-2'>
                                                    <span className='frm-profile-answer1'>
                                                        <input type="date" className='form-control form-control-sm'
                                                            value={moment(profileData.dob).utc().format('YYYY-MM-DD')}
                                                            onChange={(e) => {
                                                                setProfileData({ ...profileData, dob: e.target.value })
                                                            }
                                                            }
                                                        />
                                                    </span>
                                                </div>
                                                <div className='col-4 mt-2' />

                                                <div className='col-4 mt-2'>
                                                    <span className='frm-profile-heading'>Gender:</span>
                                                </div>
                                                <div className='col-4 mt-2'>
                                                    <span className='frm-profile-answer1'>
                                                        <select className='form-select form-select-sm'
                                                            value={profileData.gender}
                                                            onChange={(e) => setProfileData({ ...profileData, gender: e.target.value })}
                                                        >
                                                            <option value=''>Select Gender</option>
                                                            <option value="Male">Male</option>
                                                            <option value="Female">Female</option>
                                                        </select>
                                                    </span>
                                                </div>
                                                <div className='col-4 mt-2' />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </TabPane>

                            {/* <TabPane tab={
                                <span>Security</span>
                            } key="2">
                                <div>
                                    <div className='pt-4'>
                                        <span className='profile-hr-heading'>Password Updates</span>
                                        <div className='mt-2 profile-contact-info'>
                                            <div className='row'>
                                                <div className='col-4 mt-1'>
                                                    <span className='frm-profile-heading'>Last update on:</span>
                                                </div>
                                                <div className='col-4 mt-1'>
                                                    <span className='frm-profile-answer1'> {moment(profileData.lastPasswordUpdateOn).format('DD-MM-YYY') === '01-01-010001' ? '-' : moment(profileData.lastPasswordUpdateOn).format('DD-MM-YYYY')}</span>
                                                </div>
                                                <div className='col-4 mt-2' />
                                            </div>
                                        </div>

                                    </div>

                                    <div className='pt-4'>
                                        <span className='profile-hr-heading'>Change Password</span>
                                        <div className='mt-3 profile-contact-info'>
                                            <button className='btn btn-primary btn-sm' onClick={() => {
                                                showModal1();
                                            }}> Change Password </button>
                                        </div>

                                    </div>


                                    <div className='pt-3'>
                                        <span className='profile-hr-heading'>Two-Factor Authentication <FontAwesomeIcon title="Two factor authentication adds an extra layer of security to your account
                                            by requiring more than just a password to log in." icon={faInfoCircle} color="#007bff" size="sm" /></span>
                                        <div className='mt-2 profile-contact-info'>
                                            <span className='profile-form-twf'>
                                            </span>
                                            <div className='row'>
                                                <div className='col-4 mt-2'>
                                                    <span className='frm-profile-heading'>2-FA Status</span>
                                                </div>
                                                <div className='col-4 mt-2 '>
                                                    <span className='frm-profile-heading1 d-flex align-items-center'>{profileData.portalAccess.isEnable2FA == true ? 'Set' : 'Not Set'} &nbsp;  <div style={{ height: 10, width: 10, borderRadius: 10, backgroundColor: profileData.portalAccess.isEnable2FA == true ? 'green' : 'red' }}  ></div> </span>
                                                </div>
                                                <div className='col-4 mt-2' />
                                            </div>

                                            {profileData.portalAccess.isEnable2FA == true ?
                                                <button className='btn btn-primary btn-sm mt-3' onClick={() => {
                                                    showModal();
                                                }} >
                                                    Disable Two-Factor Authentication
                                                </button>
                                                :
                                                <button className='btn btn-primary btn-sm mt-3' onClick={() => {
                                                    showModal();
                                                }} >
                                                    Enable Two-Factor Authentication
                                                </button>
                                            }
                                        </div>
                                    </div>

                                    <div className='pt-4'>
                                        <span className='profile-hr-heading'>Set Admin Pin</span>
                                        <div className='mt-3 profile-contact-info'>
                                            <button className='btn btn-primary btn-sm' onClick={() => {
                                                showModal2();
                                            }}> Generate Pin </button>
                                        </div>

                                    </div>
                                </div>
                            </TabPane> */}

                            {/* <TabPane tab={
                                <span>Privileges</span>
                            } key='3'>

                            </TabPane>

                            <TabPane tab={
                                <span>Organization</span>
                            } key='4'>

                            </TabPane> */}

                        </Tabs>

                    </div>
                    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-4 mb-5'>
                        <div className='d-flex justify-content-end align-items-center'>
                            <div className='profile-work d-flex justify-content-end'>
                                <button className="btn btn-primary btn-sm buttons" onClick={saveProfile}>Update</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    );
}

export default EditProfile;










{/* <div className='pt-4'>
                                        <span className='profile-hr-heading'>Recent Activities</span>
                                        <div className='mt-3 profile-contact-info'>
                                            <div className='row'>
                                                <div className='col-4 mt-2'>
                                                    <span className='frm-profile-heading'> {moment().format('MMM D, YYYY')} </span>
                                                </div>
                                                <div className='col-4 mt-2'>
                                                    <span className='frm-profile-heading1'>Galaxy Tab A7</span>
                                                </div>
                                                <div className='col-4 mt-2' />
                                            </div>

                                            <div className='row'>
                                                <div className='col-4 mt-2'>
                                                    <span className='frm-profile-heading'>{moment().subtract(1,'days').format('MMM D, YYYY')}</span>
                                                </div>
                                                <div className='col-4 mt-2'>
                                                    <span className='frm-profile-heading1'>Chrome Browser</span>
                                                </div>
                                                <div className='col-4 mt-2' />
                                            </div>

                                            <div className='row'>
                                                <div className='col-4 mt-2'>
                                                    <span className='frm-profile-heading'>{moment().subtract(2,'days').format('MMM D, YYYY')}</span>
                                                </div>
                                                <div className='col-4 mt-2 '>
                                                    <span className='frm-profile-heading1 d-flex align-items-center'>Chrome Browser &nbsp;  <div style={{ height: 10, width: 10, borderRadius: 10, backgroundColor: 'greenyellow' }}  ></div> </span>
                                                </div>
                                                <div className='col-4 mt-2' />
                                            </div>
                                        </div>
                                    </div> */}