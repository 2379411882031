import React, { useEffect, useState } from "react";
import './DockerHub.css';
import { useHistory } from 'react-router-dom';
import { NxLoader, NxTitleTab, NxActionTab, NxGridList, NxFooter } from '@afiplfeed/itr-app';
import { ItrApiService } from '@afiplfeed/itr-ui';
import Headers from "../../Screens/Headers/Headers";

const splits = async (dests, setData, setExport) => {
    let arr = [];
    let arr2 = [];
    for (let value of dests) {
        let data = [value.dockerServerAccountName, value.userName, value.storageAccountName, value.containerRegistryName];
        let obj = { sendItem: value, data: data };
        let obj2 = {
            'Docker Server Account Name': value.dockerServerAccountName,
            'Username / email': value.userName,
            'Storage Account Name': value.storageAccountName,
            'Container Registry Name': value.containerRegistryName
        }
        arr2 = [...arr2, obj2]
        arr = [...arr, obj]
    }
    setExport(arr2);
    setData(arr)
}

export default function DockerHub() {
    let history = useHistory();
    const [load, setLoad] = useState(false);
    const [handel, setHandel] = useState(false);
    const [exportVal, setExport] = useState([])
    useEffect(() => {
        setLoad(true)
        ItrApiService.GET({ url: 'CloudConnect/Configuration/DockerServer' }).then(res => {
            if (res.Success == true) {
                if (res.result.isSuccess == true) {
                    // splits2(res.result.data, setData);
                    if (res.result.data) {
                        splits(res.result.data, setData, setExport);
                    }
                }
            }
            setLoad(false)
        })
    }, []);
    const [vals, setVale] = useState([])
    const [data, setData] = useState([]);
    return (
        <div>
            <NxLoader state={load} />
            {/* <Headers /> */}
            {/* <VxBreadcrumb className="navigation-breadcrumb" data={[
                {
                    name: 'Home',
                    onClick: () => {
                        history.push('/partner-board');
                    }
                }
            ]} /> */}
            <div className="customer-dynamic-pages pt-0">
                {/* <NxTitleTab className="title-tab page-title" title='Azure Connect' onCloseClick={() => {
                    history.goBack();
                }} /> */}

                <div className="onboarding-main mt-1">
                    <div className="d-flex justify-content-between align-items-center">
                        <NxActionTab className="px-0"
                            exportValue={exportVal}
                            fileName='Docker Hub'
                            // onColumnsClick={() => { setHandel(true) }}
                            onRefreshClick={() => {
                                setLoad(true)
                                ItrApiService.GET({ url: 'CloudConnect/Configuration/DockerServer' }).then(res => {
                                    if (res.Success == true) {
                                        if (res.result.isSuccess == true) {
                                            // splits2(res.result.data, setData);
                                            if (res.result.data) {
                                                splits(res.result.data, setData, setExport);
                                            } else {
                                                setData([]);
                                            }
                                        }
                                    }
                                    setLoad(false)
                                })
                            }}
                            onNewEntryClick={() => {
                                history.push('/docker-hub/add')
                            }}
                            enableNewEntry={false}
                        />
                        <button className='btn btn-sm' style={{ backgroundColor: 'rgb(82, 196, 26)', color: 'white', fontSize: '14px' }} onClick={() => history.push('/docker-hub/add')}>Add Docker Server </button>
                    </div>


                    <div className="mb-5">
                        <NxGridList
                            enableSearch={true}
                            autoSearch={true}
                            enableAutoPagination={true}
                            showShowing={true}
                            header={[
                                {
                                    name: 'Docker Server Account Name',
                                    search: true
                                },
                                {
                                    name: 'Username / email',
                                    search: true
                                },
                                {
                                    name: 'Storage Account Name',
                                    search: true
                                },
                                {
                                    name: 'Container Registry Name',
                                    search: true
                                }


                            ]}
                            rowClick={(data) => {
                                history.push({
                                    pathname: '/docker-hub/edit',
                                    state: data
                                })
                            }}
                            data={data}
                        />
                    </div>
                </div>
            </div>

            {/* <NxFooter className="verscio-footer" companyName="© 2022 Ithred Solutions" ExtraContent={[
                { name: 'Terms of Service' },
                { name: 'Privacy' },
            ]} /> */}
        </div>
    )
}