import React, { useState, useEffect } from 'react';
import './BillingRule.css';
import { useHistory } from 'react-router';
import { NxTitleTab, NxLoader, NxFooter, NxGridList, NxActionTab } from '@afiplfeed/itr-app';
import { ItrApiService } from '@afiplfeed/itr-ui';
import { Countries } from '../../../general/general';
import { Switch } from 'antd';
import Headers from '../../../Screens/Headers/Headers';
import moment from 'moment';
import { NotificationManager } from 'react-notifications';


const splits = async (dests, setData, setExport) => {
    let arr2 = [];
    let arr = [];
    for (let value of dests) {
        let data = [value.billingTemplateName, moment(value.dataCreated).format('DD/MM/YYYY'), value.author || '', value.noOfCustomer];
        let obj = { sendItem: value, data: data };
        let obj2 = {
            'Billing Template': value.billingTemplateName,
            'Saved Date': moment(value.dataCreated).format('DD/MM/YYYY'),
            'Author': value.author || '',
            'No of Customers applied': value.noOfCustomer
        }
        arr2 = [...arr2, obj2];
        arr = [...arr, obj]
    }
    setExport(arr2);
    setData(arr)
}


export default function BillingRule() {
    let history = useHistory();
    const [loading, setLoading] = useState(false);

    useEffect( () => {
        setLoading(true);
        ItrApiService.GET({ url: 'RevOps/BillingRule/GetBillingRules' }).then(res => {
            if (res.Success == true) {
                if (res.result.isSuccess == true) {
                    if (res.result.data) {
                        splits(res.result.data, setData, setExport)
                    }
                } else {
                    NotificationManager.error(res.result.message);
                }
            } else {
                NotificationManager.error(res.message);
            }
            setLoading(false);
        })
    }, []);

    const [data, setData] = useState([]);
    const [exportVal, setExport] = useState([]);

    return (
        <div>
            {/* <Headers /> */}
            <NxLoader state={loading} />
            {/* <NxTitleTab className="title-tab" title='Billing Rule' onCloseClick={() => history.goBack()} /> */}
            <>
                <div className="card1 mt-0">
                    <div className="card-body1">
                        <div className="container-form mb-5">

                            <div className='d-flex justify-content-end align-items-center'>
                                {/* <NxActionTab className="resource-groups-list-action-tab px-0"
                                    // onColumnsClick={() => { setHandel(true) }}
                                    exportValue={exportVal}
                                    fileName='Billing Template'
                                    onRefreshClick={() => {
                                        setLoading(true)
                                        ItrApiService.GET({ url: 'RevOps/BillingRule/GetBillingRules' }).then(res => {
                                            if (res.Success == true) {
                                                if (res.result.isSuccess == true) {
                                                    if (res.result.data) {
                                                        
                                                    }
                                                }
                                            }
                                            setLoading(false)
                                        })
                                    }}
                                    onNewEntryClick={() => {
                                        history.push('/billing-rules/add')
                                        // setNewTaxation(true)
                                    }}
                                    enableNewEntry={false}
                                /> */}
                                <button className='btn btn-sm' style={{ backgroundColor: 'rgb(82, 196, 26)', color: 'white', fontSize: '14px' }} onClick={() => history.push('/billing-rules/add')}>New Billing Template </button>
                            </div>
                            <div className="row mt-0 mx-0 mt-2">
                                <NxGridList
                                    header={[
                                        {
                                            name: 'Billing Template',
                                            search: true,
                                        },
                                        {
                                            name: 'Saved Date',
                                            sort: true,
                                        },
                                        {
                                            name: 'Author',
                                            search: true,
                                        },
                                        {
                                            name: 'No of Customers applied',
                                            sort: true,
                                        }
                                    ]}
                                    data={data}
                                    enableSearch={true}
                                    autoSearch={true}
                                    enableAutoPagination={true}
                                    showShowing={true}
                                    rowClick={(data) => {
                                        history.push({
                                            pathname: '/billing-rules/edit',
                                            state: data
                                        });
                                    }}
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </>
            {/* <NxFooter className="verscio-footer" companyName="© 2022 Ithred Solutions" ExtraContent={[
                { name: 'Terms of Service' },
                { name: 'Privacy' },
            ]} /> */}
        </div >
    )
}
