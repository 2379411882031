import React, { useState } from "react";
import './users.css';
import { useHistory } from 'react-router-dom';
import { NxRightDrawer, NxFooter, NxTitleTab } from '@afiplfeed/itr-app';
import manageIcon from '../../../assets/skills.png';
import userIcon from '../../../assets/addUser.png';
import passwordIcon from '../../../assets/password.png';
import Headers from "../../../Screens/Headers/Headers";


export default function Users() {

    let history = useHistory();
    const [handel, setHandel] = useState(false);
    const [handel2, setHandel2] = useState(false);

    return (
        <div className="mb-5">
            <Headers/>
            <NxTitleTab className="title-tab" title="Users" onCloseClick={() => history.push('/dashboard')} />
            <div className="m-4 mt-0">
                <div className="container-fluid">
                    <div className="CustomerUsersTab">
                        <div className="row">
                            {/* Manage-Users */}
                            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mt-2">
                                <div className="card">
                                    <div className="card-header d-flex align-items-center">
                                        <img src={manageIcon} width="30" />
                                        <h6 className="fw-bold m-1 text-primary">Manage Users</h6>
                                    </div>
                                    <div className="card-body">
                                        <div className="card-details">
                                            <p>User management facilitates the functionality for administrators to manage user access to various Apps & resources like platform/portal.</p>
                                        </div>
                                        <div className="text-end mt-5">
                                            <button className="btn btn-sm btn-secondary border-0" style={{backgroundColor:'#e8e8e8',color:'black'}} 
                                                onClick={() => {
                                                    sessionStorage.setItem('ike', '1');
                                                    history.push('/manage-users')
                                                }}>
                                                Manage User
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Add-Users */}
                            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mt-2" >
                                <div className="card">
                                    <div className="card-header d-flex align-items-center">
                                    <img src={userIcon} width="30" />
                                        <h6 className="fw-bold m-1 text-primary">User Wizard</h6>
                                    </div>
                                    <div className="card-body">
                                        <div className="card-details">
                                            <p>User management facilitates the functionality for administrators to manage user access to various Apps & resources like platform/portal.</p>
                                        </div>
                                        <div className="text-end mt-5">
                                            <button className="btn btn-sm btn-secondary border-0" style={{backgroundColor:'#e8e8e8',color:'black'}}
                                            onClick={() => {
                                                sessionStorage.setItem('ike', '1');
                                                history.push('/framework/iam/add')
                                            }}>
                                                Add User
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Reset-Password */}
                            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mt-2">
                                <div className="card">
                                    <div className="card-header d-flex align-items-center">
                                    <img src={passwordIcon} width="30" />
                                        <h6 className="fw-bold m-1 text-primary">Password Manager</h6>
                                    </div>
                                    <div className="card-body">
                                        <div className="card-details">
                                            <p>User management facilitates the functionality for administrators to manage user access to various Apps & resources like platform/portal.</p>
                                        </div>
                                        <div className="text-end mt-5">
                                            <button className="btn btn-sm btn-secondary border-0" style={{backgroundColor:'#e8e8e8',color:'black'}}
                                                onClick={() => {
                                                    sessionStorage.setItem('ike', '1');
                                                    history.push('/reset-users');
                                                    }}>
                                                Reset Password
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                            
            {/* <NxActionTab className="nx-action-tab"
                onColumnsClick={() => { setHandel(true) }}
                onRefreshClick={() => {
                    setLoad(true)
                    setTimeout(function () {
                        setLoad(false)
                    }, 2000);
                }}
                onNewEntryClick={() => { history.push('/framework/iam/add') }}
                enableNewEntry={false}
            /> */}

            {/* <NxGridList header={[
                    {
                        name: 'First Name',
                        sort: true,
                    },
                    {
                        name: 'Last Name',
                        sort: true,
                    },
                    {
                        name: 'Email',
                        // sort: true,
                    },
                    {
                        name: 'Phone',
                        sort: false,
                    },
                ]}
                    searchValue={sea}
                    onChangeText={(e) => setSea(e)}
                    rowClick={(e) => {
                        sessionStorage.setItem('editUserId',e.userCode)
                        history.push('/framework/iam/edit');
                    }}
                    data={datas} 
                /> */}
                {/* <NxCustomTab
                    data={[
                        {
                            name:'User Information',
                            Components:() => {
                                return <div className="mb-4 users-tab">
                                </div>
                            }
                        },
                        {
                            name:'Role',
                            Components:() => {
                                return <div className="mb-4">
                                <NxGridList header={[
                                    {
                                        name: 'Role Code',
                                        sort: true,
                                    },
                                    {
                                        name: 'Role Name',
                                        sort: true,
                                    },
                                ]}
                                    rowClick={(e) => {
                                    }}
                                    data={[
                                        { sendItem: '1', data: [] },
                                        { sendItem: '2', data: [] }
                                    ]} />
                                </div>
                            }
                        }
                    ]}
                /> */}

                <NxRightDrawer onClose={() => setHandel(false)} show={handel} drawerContent={() => <div>
                    <p onClick={() => {
                        setHandel2(true)
                    }}>click me</p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ac nibh turpis. Mauris vitae mi vitae risus vulputate rhoncus. Suspendisse dapibus diam a accumsan pharetra. Vestibulum facilisis eros vel tincidunt pharetra. Phasellus sapien nisl, iaculis et varius sed, ultricies at mauris. Maecenas id leo cursus, auctor leo eu, accumsan lorem. Sed viverra gravida feugiat.

                    Donec at molestie dui. Nam semper nunc et arcu maximus ullamcorper. Cras mi ante, semper vitae congue at, vulputate sollicitudin purus. Phasellus vulputate a mauris vel venenatis. Phasellus posuere leo purus, ut viverra orci semper ut. Quisque ac nunc iaculis, vulputate libero in, suscipit velit. Nullam orci justo, dignissim sed ligula quis, aliquam varius eros. Etiam vitae urna aliquet, elementum tortor accumsan, rutrum tellus. Nam eu nulla eu augue vehicula ultrices sit amet sed mi. Etiam viverra, ligula vitae maximus lobortis, orci tellus ornare felis, sed vulputate risus nisl non ante.

                    In interdum maximus lorem nec accumsan. Sed posuere aliquet elit quis efficitur. Proin tincidunt consequat mi, sit amet dapibus dui. Aenean et elementum nibh. Ut convallis scelerisque ultrices. Donec congue quis orci sit amet commodo. Quisque vehicula, nibh in tincidunt congue, nibh diam luctus lacus, et vulputate magna metus sed velit. Nunc venenatis gravida maximus. Fusce vehicula urna sit amet dui laoreet, lobortis bibendum enim consectetur. Ut commodo est sed orci tempus, sed fermentum mi volutpat. Duis sit amet arcu efficitur, gravida est eu, blandit mauris. Nulla quis congue velit, a dapibus dui. Quisque congue placerat vestibulum. Quisque laoreet ligula ac odio tempor efficitur eget nec arcu. Sed sit amet facilisis quam. Curabitur gravida efficitur quam, non volutpat ipsum aliquam sit amet.

                    Vivamus fringilla porttitor vulputate. Donec porttitor, justo at condimentum venenatis, tortor tortor imperdiet urna, vel finibus velit massa et ipsum. Duis sodales eros sit amet fermentum congue. Vestibulum a magna in arcu lacinia aliquet. Sed ut mattis nulla, eu vestibulum odio. Quisque maximus varius elit et semper. Suspendisse accumsan pharetra ante nec mattis. Pellentesque in sem vel orci feugiat congue id at leo. Duis eget mattis ligula. Sed sed est rhoncus, molestie metus a, facilisis ligula. Aenean semper ac eros sed efficitur. Curabitur pulvinar tincidunt massa, a maximus eros maximus eget. Aliquam in felis mi. Nam laoreet eget quam efficitur viverra. Sed consequat massa id congue maximus. Nunc viverra mattis dui eu varius.

                    Vivamus pulvinar orci turpis, vitae elementum lacus ullamcorper ac. Etiam auctor augue a elit consequat, eget placerat velit interdum. Donec finibus lacus dapibus, tempor nibh vel, commodo erat. Morbi sit amet congue dolor. Pellentesque sed quam id lectus porttitor rhoncus non vitae nibh. In dapibus consectetur odio, vel pellentesque lacus semper sed. Aliquam non sapien eu nulla suscipit hendrerit. Nunc tempor lectus sed laoreet cursus. Pellentesque neque dolor, facilisis in nunc at, tincidunt faucibus diam. Sed lectus urna, eleifend et sem in, imperdiet vehicula lectus. Maecenas semper ipsum eu libero volutpat, quis malesuada leo sagittis. Pellentesque in orci ipsum. Nulla condimentum, felis sed consectetur vulputate, leo magna placerat leo, pulvinar finibus ligula ligula sit amet mi.

                    Praesent elit metus, euismod a nibh eu, fringilla elementum turpis. Nunc iaculis posuere mauris, aliquam malesuada elit pharetra sit amet. Mauris ultricies fermentum nisi iaculis molestie. Suspendisse eget nunc scelerisque, elementum sem sed, auctor metus. Nam lacinia varius odio, malesuada interdum eros dignissim at. Sed laoreet vestibulum augue, vel ornare nunc iaculis in. Nunc accumsan turpis vitae orci elementum, sed ornare ligula ullamcorper. In sit amet aliquet nunc, et malesuada nibh. Sed dui tellus, molestie sed convallis a, suscipit ac lectus. Suspendisse leo nibh, ultrices at augue vel, faucibus vehicula justo. Quisque risus ex, lacinia consequat dignissim vitae, accumsan at mi.

                    Mauris vestibulum est a dictum tempor. Interdum et malesuada fames ac ante ipsum primis in faucibus. Phasellus sed malesuada nulla. Sed nec vehicula tellus. Praesent vel purus interdum, congue mi et, accumsan eros. Fusce maximus dictum fermentum. Ut commodo nulla lacus, non vulputate odio sodales vitae.

                    Cras pretium, sem at mattis pretium, tellus diam tempus felis, eget condimentum ex mi et orci. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Donec sit amet turpis neque. Sed ultrices lorem mauris, at lacinia libero placerat eu. Aliquam consequat finibus ante, condimentum laoreet lectus porta sit amet. Quisque vel rutrum metus, ac gravida arcu. Nulla vestibulum dolor nibh, quis mattis augue maximus nec. Quisque placerat erat arcu. Morbi sit amet lectus scelerisque, pharetra leo eu, suscipit dolor. Praesent varius augue vel erat vestibulum tempus. In sed rutrum dolor. Aenean lectus nunc, finibus in est eu, gravida molestie lectus. Nulla non neque in lorem condimentum porta. Nulla eu lorem tellus.

                    Nullam in feugiat dui. Nulla ac ex eget ex vestibulum sagittis non id enim. Curabitur urna elit, ultricies sit amet pretium vel, mollis ut enim. Suspendisse vestibulum, ipsum ut feugiat pulvinar, neque elit venenatis justo, id rhoncus leo mauris id libero. Vivamus lacus neque, faucibus vel facilisis nec, venenatis ut libero. Donec dapibus justo arcu, sit amet scelerisque velit pharetra vel. Duis molestie cursus eros vitae consectetur. Suspendisse potenti. Phasellus non tortor eget eros interdum varius id eleifend orci. Nullam vel tortor non nisl gravida eleifend a at lacus. Integer elit leo, maximus sed feugiat ut, venenatis ut purus. Integer id volutpat tortor.

                    Quisque ac placerat sapien. Ut ut ipsum eu augue vestibulum commodo in sed ex. Maecenas id nulla ullamcorper, fermentum elit eget, scelerisque mauris. Nulla molestie et augue nec vestibulum. Quisque fringilla risus tortor, ut fringilla ante fringilla in. Phasellus in nibh elementum, fringilla nulla in, blandit risus. Phasellus ut quam malesuada, condimentum velit a, tempus justo. Etiam a rutrum ligula. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae;
                </div>} drawer1={handel2} ondrawer1Close={() => { setHandel2(false) }} />
            {/* </div> */}
            <NxFooter companyName="© 2022 Ithred Solutions" ExtraContent={[
                {name:'Terms of Service'},
                {name:'Privacy'},
            ]} />
        </div>
    )
}
