import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'antd';
import './TwoFactor.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import OtpInput from 'react-otp-input';
import { ItrApiService, ItrAuthService } from '@afiplfeed/itr-ui';
import { NxLoader } from '@afiplfeed/itr-app';
import { NotificationManager } from "react-notifications";

const TwoFactor = ({ getProfileDetails, profileDetails, isModalVisible, handleOk, handleCancel }) => {
    const [step, setStep] = useState(1);
    const [number, setNumber] = useState('');
    const [otp, setOtp] = useState('');
    const [userCode, setUserCode] = useState('');
    const [userName, setUserName] = useState('');
    const [phone, setPhone] = useState('');

    useEffect(() => {
        setPhone(profileDetails)
        ItrAuthService.GetUserInfo().then(res => {
            if (res.Success == true) {
                let getUserDetails = res.result;
                setUserCode(getUserDetails.userCode);
                setUserName(getUserDetails.userName);
            }
            else if (res.Success == false) {
                NotificationManager.error(res.message);
                // sessionStorage.clear();
            }
        });
    }, [profileDetails])
    const nextStep = () => {
        if (step < 2) {
            // Api
            setStep(step + 1)
        }
        else {
            setStep(1);
            // Api
            handleOk();
        }
    }
    const prevStep = () => {
        if (step > 1) {
            setStep(step - 1)
        }
    }

    const [loader, setLoader] = useState(false);

    return (
        <>
            <NxLoader state={loader} />
            <Modal
                footer={false}
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <div className="twoFactorModal">
                    <div className="header">
                        <h4>
                            2 Step Authentication
                        </h4>
                        <p>You will need your Password and a Phone Verification code to setup 2-Factor Authentication

                        </p>
                    </div>
                    <div className="steps">

                        <div>
                            <FontAwesomeIcon icon={faCircle} style={{ color: "green", display: step != 1 && "none" }} />
                            <FontAwesomeIcon icon={faCheckCircle} style={{ color: "green", display: step == 1 && "none" }} />
                            Phone Number
                        </div>
                        <div>
                            <FontAwesomeIcon icon={faCircle} style={{ color: step == 1 ? "grey" : "green" }} />
                            Confirm
                        </div>
                    </div>
                    {step == 1 &&
                        <div>
                            <div className="content">
                                <p>
                                    <span>
                                        Let's set up you phone!
                                    </span>
                                    <br />
                                </p>
                            </div>
                            <div className="feilds">
                                <div>
                                    <label > Country </label>
                                    <select className>
                                        <option>India</option>
                                    </select>
                                    {/* <select className="form-select form-select-sm">
                                        <option value="India">India</option>
                                    </select> */}
                                </div>
                                <div>
                                    <label>Phone Number</label>
                                    <input type='tel' value={number} className='form-control' onChange={(e) => {
                                        setNumber(e.target.value);
                                    }} />
                                </div>
                            </div>
                            <div className="bottom">
                                <p>Your Phone number will be used for account security purpose only!</p>
                            </div>
                        </div>
                    }
                    {step == 2 &&
                        <div>
                            <div className="content">
                                <p>
                                    <span>
                                        Confirm that it works!
                                    </span>
                                    <br />
                                    Please enter the Phone Verification Code sent to your mobile number.
                                    <br />
                                </p>
                            </div>
                            <div className="feilds2" style={{ display: 'flex', justifyContent: 'center' }}>
                                <div>
                                    <label>Enter the code</label>
                                    {/* <input type='number' /> */}
                                    <OtpInput
                                        containerStyle={{}}
                                        value={otp}
                                        onChange={(ot) => { setOtp(ot) }}
                                        numInputs={6}
                                        separator={<span> - </span>}
                                    />
                                </div>
                            </div>
                            <div className="bottom">
                                <p>Did not get the code Resend Now</p>
                            </div>
                        </div>
                    }
                    <div className="footer-profile-tab">
                        <button className='btn'
                            onClick={() => {
                                if (step == 1) {
                                    handleCancel();
                                    setStep(1)
                                    setNumber('');
                                    setOtp('');
                                } else {
                                    prevStep()
                                }
                            }}
                        >
                            {step == 1 ? "Close" : "Back"}
                        </button>

                        <button
                            disabled={loader}
                            className='btn btn-primary'
                            onClick={() => {
                                if (phone.phoneNumber == number) {
                                    if (step == 1) {
                                        setLoader(true)
                                        ItrApiService.POST({
                                            url: 'PAM/SendSMSVerification',
                                            data: {
                                                userCode,
                                                userName,
                                                phonenumbers: number.toString()
                                            }
                                        }).then(res => {
                                            setLoader(false)
                                            if (res.Success == true) {
                                                if (res.result.isSuccess == true) {
                                                    nextStep()
                                                } else {
                                                    NotificationManager.error(res.result.message)
                                                }
                                            } else {
                                                NotificationManager.error(res.message)
                                            }
                                        })
                                    } else {
                                        setLoader(true)
                                        ItrApiService.POST({
                                            url: 'PAM/Enable2FA',
                                            data: {
                                                userCode,
                                                userName,
                                                phonenumbers: number,
                                                otpKey: otp,
                                                isEnable2FA: phone.portalAccess.isEnable2FA == true ? false : true
                                            }
                                        }).then(res => {
                                            setLoader(false)
                                            if (res.Success == true) {
                                                if (res.result.isSuccess == true) {
                                                    handleOk();
                                                    setStep(1)
                                                    setNumber('');
                                                    setOtp('');
                                                    getProfileDetails()
                                                    NotificationManager.success(res.result.message)

                                                } else {
                                                    NotificationManager.error(res.result.message)
                                                }
                                            } else {
                                                NotificationManager.error(res.message)
                                            }
                                        })
                                    }

                                }
                                else {
                                    NotificationManager.error('Invalid Mobile Number')
                                }
                            }}
                        >
                            {step == 1 ? "Continue" : phone.portalAccess.isEnable2FA == true ? "Turn Off" : "Turn On"}
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
};


export default TwoFactor