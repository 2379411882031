import React, { useState } from 'react';
import './DockerConnect.css';
import { useHistory } from 'react-router';
import { NxTitleTab, NxFooter } from '@afiplfeed/itr-app';
import { DatePicker, Tabs } from 'antd';
import { DashboardOutlined, FormOutlined } from '@ant-design/icons';
import CloudConnect from '../../../CloudConnect/CloudConnect';
import DockerHub from '../../../DockerHub/DockerHub';
import Headers from '../../../../Screens/Headers/Headers';
import DatabaseServer from '../../../DatabaseServer/DatabaseServer';

const { RangePicker } = DatePicker;


export default function DockerConnect() {
    const { TabPane } = Tabs;
    let history = useHistory();
    return (
        <>
            <Headers />
            <NxTitleTab className="title-tab" title="Cloud Connect" onCloseClick={() => history.goBack()} />
            <div className="billing-tabs billing-main">
                <Tabs defaultActiveKey="1" type="card">
                    <TabPane tab={
                        <div className="d-flex justify-content-center px-2">
                            <span ><DashboardOutlined /></span>
                            <span style={{ fontSize: '16px' }} >Azure Cloud</span>
                        </div>
                    } key="1">
                        <CloudConnect />
                    </TabPane>

                    {/* <TabPane tab={<div className="d-flex justify-content-center px-2">
                        <span ><FormOutlined /></span>
                        <span style={{ fontSize: '16px' }}>Database Server</span>
                    </div>} key="2">
                        <DatabaseServer/>
                    </TabPane> */}
                </Tabs>
            </div>
            <NxFooter companyName="© 2022 Ithred Solutions" ExtraContent={[
                { name: 'Terms of Service' },
                { name: 'Privacy' },
            ]} />
        </>
    )
}
