import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NxLoader } from '@afiplfeed/itr-app';
import { Button, Modal, Alert, Space, PageHeader, Tooltip, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { ItrApiService, ItrAuthService } from '@afiplfeed/itr-ui';
import Pricing from './Pricing';
import moment from 'moment';

function AddLicenseModel({ modalState, setModalState, selectedValue, subIds, refreshFunction = () => { } }) {
    const [loader, setLoader] = useState(false);
    const [confirmSubscribe, setConfirmSubscribe] = useState(false);
    const [priceDetails, setPricingDetails] = useState([]);
    const [noOfUnits, setNoOfUnit] = useState('0');

    const [serviceCode, setServiceCode] = useState();

    let getServiceCode = selectedValue.serviceCode;

    console.log(getServiceCode);
    const getPrice = async () => {
        let result = await ItrApiService.GET({
            url: `RevOps/StoreConnect/StoreConnectByServiceCode/${selectedValue.serviceCode}`
        });
        if (result.Success == true) {
            if (result.result.isSuccess == true) {
                let data = result.result.data;
                if (data === null || !data.storeConnectPricings.length) {
                    // alert(result.result.message);
                    setPricingDetails([]);
                }
                else {
                    setPricingDetails(data.storeConnectPricings);
                    setServiceCode(data.serviceCode);
                }
            }
            else if (result.result.isSuccess == false) {
            }
        }
    }

    const [userInfo, setUserInfo] = useState({
        currency: ""
    })

    const getUserDetails = async () => {
        let result = await ItrAuthService.GetUserInfo();
        if (result.Success == true) {
            let getUserDetails = result.result;
            setUserInfo(getUserDetails);
        }
        else if (result.Success == false) {
            alert(result.message);
        }
    }

    const [service, setService] = useState()

    const [estimateData, setEstimateData] = useState({
        serviceCode: service,
        entityCode: "",
        noofUnit: 0,
        unitPrice: 0,
        totalPrice: 0,
        currencyName: ""
    });


    useEffect(() => {
        setService(selectedValue.serviceCode)
        getPrice();
        getUserDetails()
    }, [selectedValue]);

    const [subscriptionValue, setSubscriptionValue] = useState();

    let rupeeIndian = Intl.NumberFormat("en-IN", {
        // style: "currency",
        currency: "INR",
    });

    return (
        <div>
            <NxLoader state={loader} />
            <Modal
                footer={[
                    <div>
                        <Button type="ghost" onClick={() => {
                            setModalState(false);
                        }
                        }>Close</Button>
                    </div>
                ]} visible={modalState} width={1000} bodyStyle={{ height: '500px', overflow: 'auto' }} centered={true} onCancel={() => {
                    setModalState(false);
                }}
            >
                <PageHeader
                    // onBack={() => setAppModalDetails(false)}
                    title={selectedValue.serviceName}
                // subTitle="Productivity"
                >
                    <div className=''>
                        <p className='my-0'><span>App Category :</span> {selectedValue.serviceCategory} </p>
                        <p className='my-0'><span>Publisher :</span> {selectedValue.developerName} </p>
                        <p className='my-0'><span>Currency :</span> {userInfo.currency ? userInfo.currency.split("-")[0] : ''} </p>
                    </div>
                </PageHeader>

                <div className='mt-3 row'>
                    <div className='col-12 col-sm-12 col-md 12 col-lg-6 col-xl-6 mt-2'>
                        <label className='fw-bold'>Add License</label>
                        <div className="row p-3 justify-content-start align-items-start">
                            <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                <div className='row align-items-start'>
                                    <label>Pls enter the number of licenses you would like to subscribes</label>
                                    <div className='col-12 col-sm-12 col-md-6 col-lg-8 col-xl-8 mt-2'>
                                        <input style={{ fontSize: '12px' }} type='number' className="form-control form-control-sm" placeholder=""
                                            value={estimateData.noofUnit}
                                            onChange={(e) => {
                                                setEstimateData({ ...estimateData, noofUnit: e.target.value });
                                                // setNoOfUnit(e.target.value);
                                            }}
                                        />
                                    </div>
                                    <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-2'>
                                        <button style={{ fontSize: '12px', borderRadius: '0' }} className="btn btn-primary btn-sm shadow-none w-100"
                                            // onClick={() => setConfirmSubscribe(true)}
                                            disabled={loader || estimateData.noofUnit == 0 || '' ? true : false}
                                            onClick={() => {
                                                setLoader(true);
                                                ItrApiService.POST({
                                                    url: 'RevOps/StoreConnect/GetEstimatePrice',
                                                    data: { ...estimateData, serviceCode: selectedValue.serviceCode }
                                                }).then(res => {
                                                    if (res.Success == true) {
                                                        if (res.result.isSuccess == true) {
                                                            setLoader(false);
                                                            setSubscriptionValue(res.result.data.totalPrice);
                                                            //setConfirmSubscribe(true);
                                                        }
                                                        else {
                                                            message.error(res.result.message);
                                                            setLoader(false);
                                                        }
                                                    }
                                                    else {
                                                        setLoader(false);
                                                    }
                                                    setConfirmSubscribe(true);
                                                })
                                            }}
                                        >Subscribe</button>
                                    </div>
                                </div>

                                <div className='row mt-3'>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" hidden={confirmSubscribe == false ? true : false}>
                                        <Alert
                                            style={{ height: '130px' }}
                                            message="Subscription Confirmation"
                                            // description={`Your monthly estimated bill : Rs ${priceDetails.length > 0 ? parseInt(noOfUnits) * parseInt(priceDetails[0].amount) : 0} /-`}
                                            description={`Your monthly estimated bill : ${subscriptionValue ? rupeeIndian.format(subscriptionValue) : ''} /- ${userInfo.currency ? userInfo.currency.split("-")[0] : ''} `}
                                            type="info"
                                            showIcon
                                            // banner={true}
                                            action={
                                                <>
                                                    <div style={{ position: 'absolute', bottom: '10px', right: '20px' }}>
                                                        <Space direction="horizontal">
                                                            <Button size="small" type="primary"
                                                                disabled={loader || estimateData.noofUnit == 0 || '' ? true : false}
                                                                onClick={() => {
                                                                    setLoader(true);
                                                                    ItrApiService.POST({
                                                                        url: 'RevOps/EntitySubscription/AddLicense',
                                                                        data: {
                                                                            entitySubscriptionUnitID: 0,
                                                                            entitySubscriptionID: subIds,
                                                                            purchasedUnits: parseInt(noOfUnits),
                                                                            activeUnits: 0,
                                                                            inactiveUnits: 0,
                                                                            purchasedDate: moment().format(),
                                                                            isActive: true
                                                                        }
                                                                    }).then((res) => {
                                                                        if (res.Success == true) {
                                                                            if (res.result.isSuccess == true) {
                                                                                message.success('Successfully Updated');
                                                                                setLoader(false);
                                                                                setModalState(false);
                                                                                refreshFunction();
                                                                            }
                                                                            else {
                                                                                message.error(res.result.message);
                                                                                setLoader(false);
                                                                            }
                                                                        }
                                                                        else {
                                                                            setLoader(false)
                                                                        }
                                                                    })
                                                                }}
                                                            >
                                                                Confirm
                                                            </Button>
                                                            <Button size="small" danger type="ghost" onClick={() => setConfirmSubscribe(false)}>
                                                                Cancel
                                                            </Button>
                                                        </Space>
                                                    </div>
                                                </>
                                            }
                                            closable
                                        >
                                        </Alert>
                                    </div>
                                </div>
                            </div>

                            <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                <p className='mt-0 fw-bold text-primary'>Subscription Information</p>
                                {/* <p>Monthly estimated bill value INR_____</p> */}
                                <p> <span className='text-danger'></span> The comprehensive billing for this will commence from the next billing cycle.<br /> Prorated bill will be generated for the current billing period.</p>

                                <p style={{ cursor: 'pointer' }} className='w-50'>
                                    <Tooltip overlayInnerStyle={{ color: 'black' }} color="#ffefbd" title="Subscription Information Monthly estimated bill value INR_____
                                                                    Note: The comprehensive billing for this will commence from the next billing cycle. Prorated bill will be generated for the current billing period.
                                                                    Billing Policy (I)">
                                        <span style={{ color: 'black', fontSize: '14px' }}><FontAwesomeIcon icon={faInfoCircle} color='red' /> Billing & Cancellation Policy</span>
                                    </Tooltip>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className='col-12 col-sm-12 col-md 12 col-lg-6 col-xl-6 mt-2'>
                        <label className='fw-bold'>Pricing</label>
                        <div className='table-wrapper-scroll-y my-custom-scrollbar'>
                            <table className='table table-sm'>
                                <thead>
                                    <tr>
                                        <th>Pricing Module</th>
                                        <th>Amount</th>
                                        <th hidden={!(priceDetails && priceDetails[0]?.pricingModule == 'Volume' || priceDetails && priceDetails[0]?.pricingModule == 'Tiered')} >From</th>
                                        <th hidden={!(priceDetails && priceDetails[0]?.pricingModule == 'Volume' || priceDetails && priceDetails[0]?.pricingModule == 'Tiered')} >To</th>
                                        <th hidden={priceDetails[0]?.pricingModule != 'Step-Up'} >UserCount</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {priceDetails.map((data, index) => {
                                        return <tr key={index}>
                                            <td> {data.pricingModule} </td>
                                            <td> {rupeeIndian.format(data.amount)} /- {userInfo.currency ? userInfo.currency.split("-")[0] : ''} </td>
                                            <td hidden={!(data?.pricingModule == 'Volume' || data?.pricingModule == 'Tiered')}> {data.from} </td>
                                            <td hidden={!(data?.pricingModule == 'Volume' || data?.pricingModule == 'Tiered')}> {data.to} </td>
                                            <td hidden={data?.pricingModule != 'Step-Up'}> {data?.userCount} </td>
                                        </tr>
                                    })}
                                </tbody>

                            </table>
                        </div>
                    </div>
                </div>

            </Modal>
        </div>
    );
}

export default AddLicenseModel;