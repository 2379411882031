import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { NxHeader, NxBreadcrumb, NxTitleTab, NxCustomTab, NxFooter, NxLoader } from '@afiplfeed/itr-app';
import Versioning from '../StoreConnectEntry/Versioning';
import Headers from '../../Screens/Headers/Headers';
import CancelModal from '../StoreConnectEntry/CancelModal';
import { ItrApiService } from '@afiplfeed/itr-ui';
function ServiceDetails({ }) {
    const [loading, setLoading] = useState(false);
    const [datas, setdatas] = useState({});
    let history = useHistory();
    const location = useLocation();
    useEffect(() => {
        setLoading(true);
        if (!location.state) {
            history.push('/enrollment')
        } else if (!location.state.editVal) {
            history.push('/enrollment')
        } else {
            setdatas(location.state.editVal);
        }
        setLoading(false);
    }, []);
    const [mod, setMod] = useState(false);
    return (
        <div>
            <NxLoader state={loading} />
            <Headers />
            <NxTitleTab className="title-tab" title='Service Detail' onCloseClick={() => {
                history.goBack();
            }} />
            <div className='newenrolemententry-global px-5'>
                <Versioning datas={datas} setdatas={setdatas} onCloseClick={() => { history.goBack() }}
                    onRetireClick={() => {
                        setMod(true);
                        ItrApiService.GET({
                            url: 'PAM/IsRequiredAdminPinVerification',
                        }).then(res => {
                            if (res.Success == true) {
                                if (res.result.isSuccess == true) {
                                    if (res.result.data.isRequiredAdminPinVerification == true) {
                                        setMod(true);
                                    }
                                    else {
                                        setLoading(true)
                                        ItrApiService.GET({ url: `RevOps/StoreConnect/UpdateRetiredStatus/${datas.StoreConnectId}` }).then(res2 => {
                                            if (res2.Success == true) {
                                                if (res2.result.isSuccess == true) {
                                                    history.goBack();
                                                }
                                            }
                                            setLoading(false)
                                        })
                                    }
                                }
                            }
                        })


                    }}
                    onEditClick={() => {
                        history.push({
                            pathname: '/apps-store/store-connect/add',
                            state: { editVal: datas }
                        });
                    }}
                    onPublishClick={() => {
                        history.push({
                            pathname: '/apps-store/store-connect/add',
                            state: { editVal: datas }
                        });
                    }} />
            </div>
            <CancelModal
                cancelVisible={mod}
                onCanceled={() => {
                    setMod(false)
                }} onOk={() => {
                    // RevOps/StoreConnect/UpdateRetiredStatus/{storeConnectId}
                    setMod(false);
                    setLoading(true)
                    ItrApiService.GET({ url: `RevOps/StoreConnect/UpdateRetiredStatus/${datas.StoreConnectId}` }).then(res => {
                        if (res.Success == true) {
                            if (res.result.isSuccess == true) {
                                history.goBack();
                            }
                        }
                        setLoading(false)
                    })
                }} />
            <NxFooter companyName="© 2023 Altascio Technologies (I) Pvt. Ltd.," ExtraContent={[
                { name: 'Terms of Service' },
                { name: 'Privacy' },
            ]} />
        </div>
    );
}

export default ServiceDetails;