import React, { useState, useEffect } from 'react';
import './appStore.css';
import { useHistory } from 'react-router';
import AppStore from '../../assets/appStore.png';
import Subscriptions from '../../assets/subscriptions.png';
import Assignments from '../../assets/assignments.png';
import { Tabs, Switch, Rate, Modal, PageHeader, Tag, Button, Tooltip, Alert, Space } from 'antd';
import { AppstoreOutlined, DashboardOutlined } from '@ant-design/icons';
import { ItrApiService } from '@afiplfeed/itr-ui'
import { NxLoader, NxTitleTab, NxFooter } from '@afiplfeed/itr-app';
import Headers from '../../Screens/Headers/Headers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard, faChartLine, faCode, faDollarSign, faIdBadge, faInfo, faInfoCircle, faStore, faTachometerAlt, faTicketAlt, faUserTag } from '@fortawesome/free-solid-svg-icons';
import SubModal from './SubModal';
import CustomerSidebar from '../../Screens/CustomerSidebar/CustomerSidebar';
// import CancelModal from './CancelModal';

export default function AppStores({ data = [{ image: '', heding: '', info: '' }] }) {
    const { TabPane } = Tabs;
    let history = useHistory();
    const [selectedValue, setSelectedValue] = useState({});
    const [cancelModals, setCancelModals] = useState(false);
    // subscription 
    const [filtersubscrption, setFiltersubscrption] = useState([
        { id: 1, name: "All Apps", isChecked: true },
        { id: 2, name: "Subscribed Apps", isChecked: false },
        { id: 3, name: "Available Apps", isChecked: false },
        { id: 4, name: "Unsubscribed Apps", isChecked: false },
    ]);
    const [filter1, setFilter1] = useState([]);
    const [subscriptionData, setSubscriptionData] = useState([]);
    const handleChange = async (e, index, id) => {
        if (e == true) {
            if (id == 1) {
                setFiltersubscrption([
                    { id: 1, name: "All Apps", isChecked: true },
                    { id: 2, name: "Available Apps", isChecked: false },
                    { id: 3, name: "Subscribed Apps", isChecked: false },
                    { id: 4, name: "Unsubscribed Apps", isChecked: false },
                ])
                setSubscriptionData(filter1);
            } else if (id == 2) {
                setFiltersubscrption([
                    { id: 1, name: "All Apps", isChecked: false },
                    { id: 2, name: "Available Apps", isChecked: true },
                    { id: 3, name: "Subscribed Apps", isChecked: false },
                    { id: 4, name: "Unsubscribed Apps", isChecked: false },
                ])
                let arr = []
                let arr2 = await filter1.map((res) => {
                    if (res.SubscriptionStatus == '') {
                        arr = [...arr, res];
                        return true
                    }
                });
                setSubscriptionData(arr);
            }
            else if (id == 3) {
                setFiltersubscrption([
                    { id: 1, name: "All Apps", isChecked: false },
                    { id: 2, name: "Available Apps", isChecked: false },
                    { id: 3, name: "Subscribed Apps", isChecked: true },
                    { id: 4, name: "Unsubscribed Apps", isChecked: false },
                ]);
                let arr = []
                let arr2 = await filter1.map((res) => {
                    if (res.SubscriptionStatus == 'S') {
                        arr = [...arr, res];
                        return true
                    }
                });
                setSubscriptionData(arr);
            } else {
                setFiltersubscrption([
                    { id: 1, name: "All Apps", isChecked: false },
                    { id: 2, name: "Available Apps", isChecked: false },
                    { id: 3, name: "Subscribed Apps", isChecked: false },
                    { id: 4, name: "Unsubscribed Apps", isChecked: true },
                ]);
                let arr = []
                let arr2 = await filter1.map((res) => {
                    if (res.SubscriptionStatus == 'U') {
                        arr = [...arr, res];
                        return true
                    }
                });
                setSubscriptionData(arr);
            }
        }
    }

    const [subscriptionId, setSubscriptionId] = useState('');
    const [clientId, setClientid] = useState('');
    const [enrollmentId, setEnrollmentId] = useState('');
    const [StoreStatus, setStoreStatus] = useState('');
    const [serviceCode, setServiceCode] = useState('');
    const [serviceName, setSserviceName] = useState('');
    const [loader, setLoader] = useState(false);

    // list-Subscription-Api
    const getData = async () => {
        let result = await ItrApiService.GET({
            url: 'RevOps/StoreConnect/AllPublishedApps'
        });
        if (result.Success == true) {
            if (result.result.isSuccess == true) {
                let data = result.result.data;
                if (data != null) {
                    for (let id of data) {
                        setSubscriptionId(id.SubscriptionId);
                        setClientid(id.clientID);
                        setEnrollmentId(id.id);
                        setStoreStatus(id.SubscriptionStatus);
                        setSserviceName(id.ServiceName);
                        setServiceCode(id.ServiceCode);
                    }
                    if (data === null || !data.length) {
                        setSubscriptionData([])
                        setFilter1([])
                        // alert(result.result.message);
                    }
                    else {
                        setSubscriptionData(data);
                        setFilter1(data);
                        // await splits(data,setDatas);
                        // await setpassCustomers(getCustomerDetails);
                    }
                }
                // setCustomers(getCustomerDetails);
            }
            else if (result.result.isSuccess == false) {
                // alert(result.result.message);
                // sessionStorage.clear();
                // history.push('/')
            }
        }
    }

    const [noOfUnit, setNoOfUnit] = useState();

    const [handel, setHandel] = useState(false);
    const [handel2, setHandel2] = useState(false);

    var saveSubscribe = {
        clientSubscriptionID: subscriptionId,
        enrollmentID: enrollmentId,
        clientID: clientId,
        noofUnit: parseInt(noOfUnit)
    }

    var unSubscribe = {
        clientSubscriptionID: subscriptionId,
        clientID: clientId
    }

    useEffect(() => {
        setLoader(true);
        getData();
        setLoader(false);
    }, [])


    const [showLicenseDetails, setShowLicenseDetails] = useState(false);

    const [selectedData, setSelectedData] = useState({});
    const showLicenseTable = (data) => {
        if (showLicenseDetails == true) {
            setShowLicenseDetails(false);
            setSelectedData({});
        }
        else {
            setShowLicenseDetails(true);
            setSelectedData(data);
        }
    }


    // App-details-modal
    const [appModaldetails, setAppModalDetails] = useState(false);
    const [appPolicydetails, setAppPolicyDetails] = useState(false);

    const [confirmSubscribe, setConfirmSubscribe] = useState(false);

    const [moveSubscribeTab, setMoveSubscibeTab] = useState('1');



    return (
        <>
            <NxLoader state={loader} />
            <Headers />
            <div className='d-flex custom-body-container'>
                <div className='body-sidebar'>
                    <CustomerSidebar visible={true}  />
                </div>
                <div className='body-workspace'>
                    <NxTitleTab className="title-tab" title="StoreFront" onCloseClick={() => history.push('/marketplace')} />
                    <div className="mt-0 apps-store-main ">
                        <div className="container1 mt-1 nx-subscription-page mb-5">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-9">
                                    <div className="row">
                                        <div className="nexap-subscriptions mt-1">
                                            <ul className='px-0'>
                                                {filtersubscrption.map((data, index) => {
                                                    return <li key={index} className='mx-0'>
                                                        <Switch size="small" key={data.id} id={data.id} defaultChecked={data.isChecked} onClick={(e) => {
                                                            handleChange(e, index, data.id)
                                                        }} checked={data.isChecked} />
                                                        <label htmlFor={data.id}> {data.name} </label>
                                                    </li>
                                                })}
                                            </ul>
                                        </div>

                                        {/* <div className="nx-card mb-5">
                                                    <div className="nx-card-body mt-2 nx-subscription-card">
                                                        <div className="row justify-content-start">
                                                            {subscriptionData.map((data, index) => {
                                                                return <>
                                                                    <div key={index} className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-3 nx-subscription-test flip-card" onClick={() => setHandel(true)}>
                                                                        <div className="card flip-card-inner">

                                                                            <div className="card-front flip-card-front">

                                                                                <div className='card-body'>
                                                                                    <div className="nx-subscription-img">
                                                                                        <img src={AppStore} width="40" />
                                                                                    </div>

                                                                                    <div className="nx-subscription-titles px-4">
                                                                                        <label>{data.serviceName}</label>
                                                                                        <div className="nx-subscription-info">
                                                                                            <p className='my-0'> Productivity </p>
                                                                                            <p className='my-0'> {data.publisher} </p>
                                                                                            <p >{data.description}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='d-flex justify-content-evenly align-items-center my-5 app-footer'>
                                                                                    <button className='btn btn-sm btn-outline-success app-footer-action' onClick={() => setAppModalDetails(true)}> View Details </button>
                                                                                    <button className='btn btn-sm btn-outline-primary app-footer-action'> Subscribe </button>
                                                                                </div>

                                                                                <div className="nx-subscription-info mt-5 container">
                                                                                    <hr />
                                                                                    <div className="row">
                                                                                        <div className="col-4 col-lg-4 col-sm-4 col-md-4 col-xl-4 text-center">
                                                                                            <label>License</label>
                                                                                            <p> {data.totalLicense} </p>
                                                                                        </div>

                                                                                        <div className="col-4 col-lg-4 col-sm-4 col-md-4 col-xl-4 text-center">
                                                                                            <label>Assigned</label>
                                                                                            <p> {data.assigned} </p>
                                                                                        </div>

                                                                                        <div className="col-4 col-lg-4 col-sm-4 col-md-4 col-xl-4 text-center">
                                                                                            <label>Open</label>
                                                                                            <p> {data.unassigned} </p>
                                                                                        </div>

                                                                                        <div className="col-3 col-lg-3 col-sm-3 col-md-3 col-xl-3 text-center">
                                                                                            <label>Cost</label>
                                                                                            <p> {data.castUnit} </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="flip-card-back">
                                                                                <div className="nx-card-subscription-details ">
                                                                                    <Tabs>
                                                                                        <TabPane tab="Description" key="1">
                                                                                            <div className="subscription-card-body">
                                                                                                {data.description}
                                                                                            </div>
                                                                                        </TabPane>
                                                                                        <TabPane tab="About Us" key="2">
                                                                                            <div className="subscription-card-body">
                                                                                                <h6> {data.overview} </h6>
                                                                                            </div>
                                                                                        </TabPane>
                                                                                        <TabPane tab="Developer" key="3">
                                                                                            <div className="subscription-card-body">
                                                                                                {data.publisher}<br />
                                                                                                {data.aboutthePublisher}
                                                                                            </div>
                                                                                        </TabPane>
                                                                                    </Tabs>
                                                                                    <div className="nx-card-subscription-button">
                                                                                        {data.clientSubscriptionID == 0 ?
                                                                                            <>
                                                                                                <div className="row p-3 justify-content-center align-items-center">
                                                                                                    <div className="col-6 col-sm-6 col-lg-6 col-xl-6 col-lg-6">
                                                                                                        <input className="form-control form-control-sm" placeholder="No of unit" value={noOfUnit}
                                                                                                            onChange={(e) => setNoOfUnit(e.target.value)} />
                                                                                                    </div>
                                                                                                    <div className="col-6 col-sm-6 col-lg-6 col-xl-6 col-lg-6">
                                                                                                        <button className="btn btn-primary" onClick={() => {
                                                                                                            ItrApiService.POST({
                                                                                                                url: 'EntityPlatform/ClientSubscription/SaveSubscription',
                                                                                                                data: saveSubscribe
                                                                                                            })
                                                                                                        }}>Subscribe</button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </> :
                                                                                            <>
                                                                                                <button className="btn btn-primary" onClick={() => {
                                                                                                    ItrApiService.POST({
                                                                                                        url: 'EntityPlatform/ClientSubscription/SaveUnsubscription',
                                                                                                        data: unSubscribe
                                                                                                    })
                                                                                                }}>Unsubscribe</button>
                                                                                                <button className="btn btn-primary">License Details</button>
                                                                                            </>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                </>
                                                            })}
                                                        </div>
                                                    </div>
                                                </div> */}
                                    </div>
                                </div>
                            </div>

                            <div className='row' style={{ marginBottom: '50px' }}>
                                {subscriptionData.map((data, index) => {
                                    return <>
                                        <div key={index} className='col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mt-2'>
                                            <div className='card'>
                                                <div className='card-header1 text-end'>
                                                    {/* subscribed-> blue
                                                                available -> green
                                                                unscribed -> red
                                                                */}
                                                    <button className='btn btn-sm' style={{ fontSize: '10px', borderRadius: '0', backgroundColor: data.SubscriptionStatus == '' ? '#73a562' : data.SubscriptionStatus == 'S' ? '#b8e2f2' : 'red', color: data.SubscriptionStatus == 'S' ? '#001b3a' : 'white', textTransform: 'uppercase' }}> {data.SubscriptionStatus == '' ? 'Available' : data.SubscriptionStatus == 'S' ? 'Subscribed' : data.SubscriptionStatus ? 'Unsubscribed' : ''} </button>
                                                </div>
                                                <div className='card-body'>
                                                    <div className='app-header d-flex align-items-start'>
                                                        <div className=''>
                                                            <img src={!data.appIcon ? AppStore : data.appIcon} width="40" />
                                                        </div>

                                                        <div className="nx-subscription-titles px-4">
                                                            <h6 className='fw-bold'>{data.ServiceName}</h6>
                                                            <div className="nx-subscription-info">
                                                                {/* <p className='my-0'> Productivity </p> */}
                                                                <p className='my-0' style={{ fontSize: '13px' }}> {data.DeveloperName} </p>
                                                                {/* <p >{data.description}</p> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='d-flex justify-content-end align-items-end mb-3 mt-5 px-3 app-footer'>
                                                    <button className='btn btn-sm btn-outline-primary app-footer-action shadow-none' onClick={() => {
                                                        setSubscriptionId(data.SubscriptionId);
                                                        setClientid(data.clientID);
                                                        setEnrollmentId(data.id);
                                                        setStoreStatus(data.SubscriptionStatus);
                                                        setSserviceName(data.ServiceName);
                                                        setServiceCode(data.ServiceCode);
                                                        setStoreStatus(data.SubscriptionStatus);
                                                        ItrApiService.GET({
                                                            url: `DevOps/Enrollment/GetByEnrollmentID/${data.EnrollmentId}`
                                                        }).then((res) => {
                                                            if (res.Success == true) {
                                                                if (res.result.isSuccess == true) {
                                                                    setSelectedValue(res.result.data)
                                                                }
                                                            }
                                                        })
                                                        setAppModalDetails(true);
                                                    }}> View Details </button>
                                                    {/* <button className='btn btn-sm btn-outline-primary app-footer-action shadow-none'> Subscribe </button> */}
                                                </div>
                                                <div>

                                                </div>
                                            </div>
                                        </div>


                                        <Modal className='policy-modal' footer={[
                                            <div style={{ backgroundColor: '#fae7ac' }}>
                                                <Button type="ghost" style={{ backgroundColor: 'white', border: '1px solid black' }} onClick={() => {
                                                    setMoveSubscibeTab('1')
                                                    setAppPolicyDetails(false)
                                                }
                                                }>Close</Button>
                                            </div>
                                        ]} visible={appPolicydetails} width={500} bodyStyle={{ height: '300px', backgroundColor: '#fae7ac' }} centered={true} onCancel={() => {
                                            setMoveSubscibeTab('4')
                                            setAppPolicyDetails(false)
                                        }}>
                                            <div>

                                            </div>
                                        </Modal>
                                    </>
                                })}
                                <SubModal setAppModalDetails={setAppModalDetails} status={StoreStatus} code={serviceCode} name={serviceName} subId={subscriptionId} setMoveSubscibeTab={setMoveSubscibeTab} appModaldetails={appModaldetails} moveSubscribeTab={moveSubscribeTab} data={selectedValue}
                                    setConfirmSubscribe={setConfirmSubscribe}
                                    setNoOfUnit={setNoOfUnit}
                                    noOfUnit={noOfUnit}
                                    closeFunc={async () => {
                                        setLoader(true);
                                        await getData();
                                        setLoader(false);
                                    }}
                                    confirmSubscribe={confirmSubscribe}
                                />
                            </div>
                        </div>
                    </div >
                </div>
            </div>
            <NxFooter companyName="© 2023 Altascio Technologies (I) Pvt. Ltd.," ExtraContent={[
                { name: 'Terms of Service' },
                { name: 'Privacy' },
            ]} />
        </>
    )
}
