import React from 'react';
import {Line} from 'react-chartjs-2';


export default function NxLineCharts() {
    
    return (
        <div>
            <Line 
                data={{
                    labels: ['gray','Blue','orange'],
                    datasets: [
                      {
                        data: [12, 19, 3],
                        backgroundColor: [
                          'gray',
                          'blue',
                          '#fd7e14',
                        ],
                      },
                    ],
                  }}
                  height={400}
                  width={500}
            />
        </div>
    )
}
