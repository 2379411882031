import React, { useState } from 'react';
import NxBarCharts from '../../../components/nxCharts/nxBarCharts';
import './billingTab.css';
import { useHistory } from 'react-router';
import { faCreditCard, faEye, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Headers from '../../../Screens/Headers/Headers';
import { NxTitleTab,NxFooter } from '@afiplfeed/itr-app';
import {ChartColors} from '../../../general/general';
import {Bar} from 'react-chartjs-2';
import {DatePicker,Progress,Tabs} from 'antd';
import { DashboardOutlined,FormOutlined,DollarOutlined } from '@ant-design/icons';

const {RangePicker} = DatePicker;


export default function BillingTab() {

    const {TabPane} = Tabs;

    let history = useHistory();

    const [keyPeriods,setKeyPeriods] = useState();

    const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July','Aug','Sep','Oct','Nov','Dec'];

    const data = {
        labels,
        datasets: [
          {
            label: 'Dataset 1',
            data: { min: 500, max: 5000 },
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
          },
          {
            label: 'Dataset 2',
            data: { min: 500, max: 5000 },
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
          },
        ],
      };

      const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top'
          },
        },
        scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: false,
                },
              },
            ],
          },
      };


    return (
        <>

        {/* <Headers />
        <NxTitleTab className="title-tab" title="Billing" onCloseClick={() => history.push('/dashboard')} /> */}

        <div className="billing-tabs billing-main">
           
                <div className="mt-0">
            <div className="container1">
                <div className="nx-body nx-billing mt-1">
                    <div className="row">
                        <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mt-1 counts count-two">
                            <div className="card">
                                <div className="card-body">
                                    <label className="nx-badge-title">TOTAL BILLED</label>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 mt-1">
                                        <h6>$ 83.40</h6>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mt-1 counts count-two">
                            <div className="card">
                                <div className="card-body">
                                    <label className="nx-badge-title">RECEIVED</label>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 mt-1">
                                        <h6>$ 83.40</h6>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mt-1 counts count-two">
                            <div className="card">
                                <div className="card-body">
                                    <label className="nx-badge-title">CURRENT OUTSTANDING</label>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 mt-1">
                                        <h6>$ 83.40</h6>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mt-1 counts count-two">
                            <div className="card">
                                <div className="card-body">
                                    <label className="nx-badge-title">PAST DUE</label>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 mt-1">
                                        <h6>$ 83.40</h6>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-5 mt-1 counts count-two">
                            <div className='card'>
                                <div className="card-body">
                                <label className="nx-badge-title">OVERDUE</label>
                                    <div className="row">
                                        <div className="col-6 col-sm-6 col-md-6 col-lg-6 mt-2">
                                            <h6>$ 38452</h6>
                                            <label>1 - 15 Days</label>
                                        </div>

                                        <div className="col-6 col-sm-6 col-md-6 col-lg-6 mt-2">
                                            <h6>$ 740.00</h6>
                                            <label>16 - 30 Days</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-5 mt-1 counts count-two">
                            <div className="card">
                                <div className="card-body">
                                <label className="nx-badge-title">DAYS PAST DUE</label>
                            <div className="row">
                                <div className="col-6 col-sm-6 col-md-6 col-lg-6 mt-2">
                                    <h6>$ 740.00</h6>
                                    <label>31 - 45 Days</label>
                                </div>

                                <div className="col-6 col-sm-6 col-md-6 col-lg-6 mt-1">
                                    <h6>$ 740.00</h6>
                                    <label>Above 45 Days</label>
                                </div>
                            </div>
                                </div>
                            </div>
                        </div> */}
                    </div>

                    <div className="mt-3">
                        <div className="nx-title title text-left">
                            <h2>TOTAL RECEIVABLE $ 1,00,000</h2>
                        </div>

                        <div className="nx-progress mt-4">
                            <div class="progress" style={{height:'30px'}}>
                                <div class="progress-bar" role="progressbar" style={{width: "30%",background:'#f0c518',color:'black'}} aria-valuenow="30" aria-valuemin="0" aria-valuemax="100">30 DPD ($30,000)</div>
                                <div class="progress-bar" role="progressbar" style={{width: "40%",background:'#ed741c',color:'black'}} aria-valuenow="40" aria-valuemin="0" aria-valuemax="100">60 DPD ($40,000)</div>
                                <div class="progress-bar" role="progressbar" style={{width: "30%",background:'#f04356',color:'black'}} aria-valuenow="30" aria-valuemin="0" aria-valuemax="100">90 DPD ($30,000)</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="nx-bar-chart nx-billing-chart mt-5 mb-5">
                    <div className="row">
                        <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 ">
                            <h4>Key Financial Indicators</h4>
                        </div>
                    </div>

                    <div className="card ">
                        <div className="card-body">
                            <div className="row text-center">
                                <div className="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                                    <Progress type="circle" percent={75} strokeWidth={15}  gapDegree={30} gapPosition="bottom"  /> <br/>
                                    <label className="key-financial-label mt-3">GROSS PROFIT MARGIN</label>
                                </div>

                                <div className="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                                    <Progress type="circle" percent={58} strokeWidth={15} gapDegree={30} gapPosition="bottom"/> <br/>
                                    <label className="key-financial-label mt-3">OPEX RATIO</label>
                                </div>

                                <div className="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                                    <Progress type="circle" percent={20} strokeWidth={15} gapDegree={30} gapPosition="bottom"/><br/>
                                    <label className="key-financial-label mt-3">OPERATING PROFIT MARGIN</label>
                                </div>

                                <div className="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                                    <Progress type="circle" percent={16} strokeWidth={15} gapDegree={30} gapPosition="bottom"/><br/>
                                    <label className="key-financial-label mt-3">NET PROFIT MARGIN</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
                <div className="nx-bar-chart nx-billing-chart mt-5 mb-5">
                    <div className="row">
                        <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 ">
                            <h4>Financial Performance</h4>
                        </div>

                        <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-end">
                            <label>Last</label>
                            <select value={keyPeriods} onChange={(e) => setKeyPeriods(e.target.value)}>
                                <option value="12 Months">12 Months</option>
                                <option value="3 Months">3 Months</option>
                                <option value="YTD">YTD</option>
                                <option value="MTD">MTD</option>
                                <option value="Period">Period</option>
                            </select><br/>
                            <div className="p-1">
                                {keyPeriods == 'Period' ?  <RangePicker /> : false}
                            </div>
                        </div>
                    </div>

                    <div className="card ">
                        <div className="card-body">
                            <div className="row mb-5">
                                    
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <NxBarCharts height="500" width="100%"/>
                                    {/* <Bar width={200} height={40} options={options} data={data} /> */}
                                </div>

                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-end nx-sales mt-5" >
                                    <div className="row">
                                        <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                            <div className="nx-sales-report pt-3 d-flex justify-content-evenly align-items-center" >
                                                <h6 className="nx-sales-report-1" style={{backgroundColor:ChartColors.Splash,padding:'8px',color:'white',fontWeight:'bold'}}>Total New Sale</h6>
                                                <h5 className="key-financial-counts">$ 3,123,123</h5>
                                            </div>
                                        </div>

                                        <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                            <div className="nx-sales-report pt-3 d-flex justify-content-evenly align-items-center" >
                                                <h6 className="nx-sales-report-1" style={{backgroundColor:ChartColors.Sky,padding:'8px',color:'white',fontWeight:'bold'}}>Total Renewals</h6>
                                                <h5 className="key-financial-counts">$ 3,123,123</h5>
                                            </div>
                                        </div>

                                        <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 ">
                                            <div className="nx-sales-report pt-3 d-flex justify-content-evenly align-items-center" >
                                                <h6 className="nx-sales-report-1" style={{backgroundColor:ChartColors.Moonshine,padding:'8px',color:'white',fontWeight:'bold'}}>Total Receivables</h6>
                                                <h5 className="key-financial-counts">$ 3,123,123</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="nx-billing-invoice mb-5">
                    <div className="billing">
                        <div>
                            <h4>Invoices</h4>
                        </div>
                        <div className="invoice-title">
                            <button className="btn btn-success btn-sm" type="button">Create Invoice</button>
                        </div>
                    </div>

                    <div className="mt-4">
                        <label>show </label>
                        <select className="mx-1">
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                    </div>

                    <div className="invoice-table">
                        <div class="table-wrapper-scroll-y table-responsive my-custom-scrollbar ">
                            <table class="table table-hover mb-5">
                                <thead>
                                    <tr>
                                    <th scope="col">Inv#</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Client</th>
                                    <th scope="col">Invoice Title</th>
                                    <th scope="col">Due Date</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Invoice Amount</th>
                                    <th scope="col">Balance Due</th>
                                    <th scope="col">Action</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr>
                                        <td>a quo magni similique perferendis</td>
                                        <td>30</td>
                                        <td>30</td>
                                        <td>30</td>
                                        <td>30</td>
                                        <td> <span className="dot-status"> </span>30</td>
                                        <td>30</td>
                                        <td>30</td>
                                        <td>
                                            <div style={{display:'flex',justifyContent:'space-around',alignItems:'center'}}>
                                                <a> <FontAwesomeIcon icon={faEye} color="palevioletred" size="sm" /></a>
                                                <a> <FontAwesomeIcon icon={faCreditCard} color="black" size="sm" className="billing-card" /></a>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>ad iusto omnis odit dolor voluptatibus	</td>
                                        <td>30</td>
                                        <td>30</td>
                                        <td>30</td>
                                        <td>30</td>
                                        <td> <span className="dot-status"> </span>30</td>
                                        <td>30</td>
                                        <td>30</td>
                                        <td >
                                            <div style={{display:'flex',justifyContent:'space-around',alignItems:'center'}}>
                                                <a > <FontAwesomeIcon icon={faEye} color="palevioletred" size="sm" /></a>
                                                <a> <FontAwesomeIcon icon={faCreditCard} color="black" size="sm"  className="billing-card"/> </a>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div> */}
            </div>
                </div>

              
        </div>

        
        <NxFooter className="verscio-footer" companyName="© 2022 Ithred Solutions" ExtraContent={[
                {name:'Terms of Service'},
                {name:'Privacy'},
            ]} />
    </>
    )
}
