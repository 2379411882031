import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import Headers from '../../../Screens/Headers/Headers';
import { NxTitleTab, NxFooter, NxGridList, NxLoader } from '@afiplfeed/itr-app';
import { ItrApiService } from '@afiplfeed/itr-ui';
import CustomerSidebar from '../../../Screens/CustomerSidebar/CustomerSidebar';

const splits = async (dests, setData, setExport) => {
    let arr = [];
    let arr2 = [];
    for (let value of dests) {
        let data = [value.partnerName, value.supportEmail, value.supportPhone, value.partnerWebsite];
        let obj = { sendItem: value, data: data };
        let obj2 = {
            'Partner Name': value.partnerName,
            'Support Email': value.supportEmail,
            'Support Phone': value.supportPhone,
            'Partner Website': value.partnerWebsite
        };
        arr2 = [...arr2, obj2];
        arr = [...arr, obj]
    }
    setExport(arr2);
    setData(arr);
}
export default function ServiceProviderManagement() {

    let history = useHistory();
    const [loading, setLoading] = useState(false);

    useEffect( () => {
        setLoading(true);
        ItrApiService.GET({ url: 'RevOps/PartnerInformation' }).then(res => {
            if (res.Success == true) {
                if (res.result.isSuccess == true) {
                    if (res.result.data) {
                        splits(res.result.data, setData, setExport);
                    } else {
                        setData([])
                    }
                } else {
                    NotificationManager.error(res.result.message);
                }
            } else {
                NotificationManager.error(res.message);
            }
            setLoading(false);
        })
    }, []);

    const [data, setData] = useState([]);
    const [exportData, setExport] = useState([]);

    return (
        <>
            {/* <NxTitleTab className="title-tab" title="Partner Management" onCloseClick={() => history.push('/access/systemIntegrator')} /> */}

<div className="card1 mt-0 mx-3">
    <div className="card-body1">
        <div className="container-form mb-5">
            <div className="d-flex justify-content-end align-items-center">
                {/* <NxActionTab className="resource-groups-list-action-tab px-0"
                    exportValue={exportData}
                    fileName='Partner-Management'
                    onRefreshClick={() => {
                        setLoad(true)
                        ItrApiService.GET({ url: 'RevOps/PartnerInformation' }).then(res => {
                            if (res.Success == true) {
                                if (res.result.isSuccess == true) {
                                    if (res.result.data) {
                                        splits(res.result.data, setData, setExport);
                                    }
                                    else {
                                        setData([]);setExport([]);
                                    }
                                }
                            }
                            setLoad(false)
                        })
                    }}
                    onNewEntryClick={() => {
                        history.push('/partner-management/add')
                    }}
                    enableNewEntry={false}
                /> */}
                <button className='btn btn-sm' style={{ backgroundColor: 'rgb(82, 196, 26)', color: 'white', fontSize: '14px' }} onClick={() => history.push('/serviceprovider-management/add')}>Add Service Provider </button>
            </div>

            <div className="row mt-0 mx-0 mt-2">
                <NxGridList
                    header={[
                        {
                            name: 'Partner Name',
                            sort: true,
                        },
                        {
                            name: 'Support Email',
                            search: true,
                        },
                        {
                            name: 'Support Phone',
                            sort: true,
                        },
                        {
                            name: 'Partner Website',
                            search: true,
                        },
                    ]}
                    data={data}
                    enableSearch={true}
                    autoSearch={true}
                    enableAutoPagination={true}
                    showShowing={true}
                    rowClick={(data) => {
                        history.push({
                            pathname: '/partner-management/edit',
                            state: data
                        });
                    }}
                />
            </div>

        </div>
    </div>
</div>
        </>
    )
}
