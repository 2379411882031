import React, { useEffect, useState } from 'react';
import './dashboard.css';
import PartnerBoard from '../../partnerBoard/partnerBoard/partnerBoard';
import Operations from '../../partnerBoard/operations/operations';
import { NxCustomTab, NxLoader, NxFooter } from '@afiplfeed/itr-app';
import { Redirect, useHistory } from 'react-router-dom';
import Headers from '../../Screens/Headers/Headers';
import Footers from '../../Screens/Footers/Footers';
import ConfigurationTab from '../NewTabs/ConfigurationTab/ConfigurationTab';
import AppEnrollmentTab from '../NewTabs/appEnrollmentTab/appEnrollmentTab';
import SubscriptionTab from '../NewTabs/subscriptionTab/subscriptionTab';
import BillingTab from '../NewTabs/billingTab/billingTab';
import SupportTab from '../NewTabs/supportTab/supportTab';
import MonitoringTab from '../NewTabs/monitoringTab/monitoringTab';
import { ItrAuthService } from '@afiplfeed/itr-ui';
import CustomerTab from '../NewTabs/customerTab/customerTab';
import AppStoreTab from '../NewTabs/AppSotreTab/AppSotreTab';


export default function Dashboard() {

    let getAuth = sessionStorage.getItem('userInfo');
    const checkValidUser = () => {
        if (!getAuth) {
            <Redirect to="/" />
        }
    }


    useEffect(() => {
        checkValidUser();
        sessionStorage.removeItem('ike');
    }, []);

    const [loading, setLoading] = useState(false);

    const [ikey, setIkey] = useState('0')

    return (
        <div className="dashboard">
            <NxLoader loader={loading} />
            <Headers isCustomer={true} onClickChange={(data) => {
                sessionStorage.setItem('ike', data)
                window.location.reload();
                setIkey(data)
            }} />
            <NxCustomTab initialKey={sessionStorage.getItem('ike') || '0'} data={[
                {
                    name: 'Dashboard',
                    Components: () => <PartnerBoard />
                },
                {
                    name: 'Customer',
                    Components: () => <CustomerTab />
                },
                {
                    name: 'App Store',
                    // Components: () => <AppEnrollmentTab/>
                    Components: () => <AppStoreTab />
                },
                // {
                //     name:'Subscription',
                //     Components: () => <SubscriptionTab/>
                // },
                {
                    name: 'Billing',
                    Components: () => <BillingTab />
                },
                {
                    name: 'Support',
                    Components: () => <SupportTab />
                },
                {
                    name: 'Performance',
                    Components: () => <MonitoringTab />
                },
                // {
                //     name:'Configuration',
                //     Components: () => <ConfigurationTab/>
                // },
            ]} />
            <NxFooter ExtraContent={[
                { name: 'Terms of Service' },
                { name: 'privacy' },
            ]} />
        </div>
    )
}





{/* <NxHeader 
            DrawerIcon={ () => <img src={pinwheelLogo} width="22" onClick={showDrawer} /> } 
            PortalHerderTitle={ () => <label style={{paddingLeft:'20px',fontSize:'15px',color:'white',fontWeight:'600'}}>
                2022 Ithred Solutions
            </label> }
            rightElements = {[
                {
                    elements: () => {
                        return <div>
                            <FontAwesomeIcon icon={faBell} color="white" size="md" />
                        </div> 
                    },
                },
                {
                    elements: () => {
                        return <Dropdown overlay={menu} trigger={['click']}>
                        <div>
                            <span style={{color:'white',fontSize:'15px',paddingRight:'10px'}}>{userInfo.userName}</span>
                            <FontAwesomeIcon icon={faUserCircle} color="white" size="md" />
                        </div>
                      </Dropdown>
                        // return <div style={{display:'flex',alignItems:'center'}} onClick={handleClick}>
                        //     <span style={{color:'white',fontSize:'15px',paddingRight:'10px'}}>developer@gmail.com</span>
                        //     <FontAwesomeIcon icon={faUserCircle} color="white" size="md" />
                        // </div> 
                    }
                }
            ]}
/> */}
