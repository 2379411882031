import React from 'react';
import { useHistory } from 'react-router-dom';
import './Adminstrator.css';
// import Card from '../../../components/card/card';
// icons
import Analytics from '../../../assets/analytics.png';
import Notification from '../../../assets/notification.png';

import vault from '../../../assets/vault.png';
import portalAccess from '../../../assets/protalAccess.png';
import payment from '../../../assets/paymentGateway.png';
import idleTimeout from '../../../assets/idleTimeout.png';
import billingEngine from '../../../assets/billingEngine.png';
import partner from '../../../assets/Partner.png';
import gstTax from '../../../assets/gst.png';

import Privacy from '../../../assets/password.png';
import ThirdPartyImg from '../../../assets/third-party.png';
import clcd from '../../../assets/eternity.png';
import { NxFooter, NxTitleTab } from '@afiplfeed/itr-app';
import Headers from '../../../Screens/Headers/Headers';
import CustomerSidebar from '../../../Screens/CustomerSidebar/CustomerSidebar';

export default function Adminstrator() {
    let history = useHistory();
    return (
        <div className="conatiner dashboard ">
            <Headers />
            <div className='d-flex custom-body-container'>
                <div className='body-sidebar'>
                    <CustomerSidebar visible={true} />
                </div>
                <div className='body-workspace'>
                    <NxTitleTab className="title-tab" title='Deployment' onCloseClick={() => history.goBack()} />
                    <div className="mb-5 mt-0 customer-configuration-main">
                        <div className="container-fluid">
                            <div className="CustomerUsersTab">
                                <div className="row cus-configuration-tab">
                                    {/* <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mt-2 mt-4">
                                        <div className="card">
                                            <div className="card-header d-flex align-items-center">
                                                <img src={billingEngine} width="30" />
                                                <h6 className="fw-bold m-1 text-primary">Provider Setup</h6>
                                            </div>
                                            <div className="card-body">
                                                <div className="card-details">
                                                    <p>Company setup features including financial details that will be used in the billing, options to add new users to the console system as principals or partners.</p>
                                                </div>
                                                <div className="text-end mt-5">
                                                    <button className="btn mx-1 btn-sm btn-primary border-0 config-buttons"
                                                        onClick={() => {
                                                            // sessionStorage.setItem('ike', '1');
                                                            history.push('/biller-information')
                                                        }}>
                                                        Company Setup
                                                    </button>
                                                    <button className="btn px-1 btn-sm btn-primary border-0 config-buttons"
                                                        onClick={() => {
                                                            // sessionStorage.setItem('ike', '1');
                                                            history.push('/biller-information')
                                                        }}>
                                                        Biller Information
                                                    </button>

                                                    <button className="btn mx-1 btn-sm btn-primary border-0 config-buttons"
                                                        onClick={() => {
                                                            sessionStorage.setItem('ike', '1');
                                                            history.push('/manage-access')
                                                        }}>
                                                        Manage Access
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                    {/* <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mt-2">
                                        <div className="card">
                                            <div className="card-header d-flex align-items-center">
                                                <img src={gstTax} width="30" />
                                                <h6 className="fw-bold m-1 text-primary">Billing Configuration</h6>
                                            </div>
                                            <div className="card-body">
                                                <div className="card-details">
                                                    <p>User management facilitates the functionality for administrators to manage user access to various Apps & resources like platform/portal.</p>
                                                </div>
                                                <div className="text-end mt-5">


                                                    <button className="btn px-1 btn-sm btn-primary border-0 config-buttons"
                                                        onClick={() => {
                                                            // sessionStorage.setItem('ike', '1');
                                                            history.push('/taxation')
                                                        }}>
                                                        3D Taxation
                                                    </button>

                                                    <button className="btn mx-1 btn-sm btn-primary border-0 config-buttons"
                                                        onClick={() => {
                                                            // sessionStorage.setItem('ike', '1');
                                                            history.push('/paymentgateway')
                                                        }}>
                                                        Manage PGI
                                                    </button> 
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                    {/* <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mt-4">
                                        <div className="card">
                                            <div className="card-header d-flex align-items-center">

                                                <img src={gstTax} width="30" />
                                                <h6 className="fw-bold m-1 text-primary">Billing & Subscription</h6>
                                            </div>
                                            <div className="card-body">
                                                <div className="card-details">
                                                    <p>Configuration of financial elements including currency conversion, billing template & taxation along with a vault feature that lets providers manage licenses.</p>
                                                </div>
                                                <div className="text-end mt-5">

                                                    <button className="btn mx-1 btn-sm btn-primary border-0 config-buttons"
                                                        onClick={() => {
                                                            sessionStorage.setItem('ike', '1');
                                                            history.push('/administrator/billing-configuration')
                                                        }}>
                                                        Billing Configuration
                                                    </button>

                                                    <button className="btn px-1 btn-sm btn-primary border-0 config-buttons"
                                                        onClick={() => {
                                                            sessionStorage.setItem('ike', '1');
                                                            history.push('/license-vault')
                                                        }}>
                                                        License Vault
                                                    </button>

                                                    <button className="btn mx-1 btn-sm btn-primary border-0 config-buttons"
                                                        onClick={() => {
                                                            sessionStorage.setItem('ike', '1');
                                                            // history.push('/user-audit')
                                                        }}>
                                                        Dunning
                                                    </button>

                                                </div>
                                            </div>
                                        </div>
                                    </div> */}


                                    {/* <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mt-4" >
                                        <div className="card">
                                            <div className="card-header d-flex align-items-center">
                                                <img src={Privacy} width="30" />
                                                <h6 className="fw-bold m-1 text-primary">Security</h6>
                                            </div>
                                            <div className="card-body">
                                                <div className="card-details">
                                                    <p>Features include a password policy manager that let the providers setup org level policy for authentication. Timeout can be setup for app users.</p>
                                                </div>
                                                <div className="text-end mt-5">
                                                    <button className="btn mx-1 btn-sm btn-primary border-0 config-buttons"
                                                        onClick={() => {
                                                            sessionStorage.setItem('ike', '1');
                                                            history.push('/PasswordPolicy')
                                                        }}>
                                                        Password Policy
                                                    </button>

                                                    <button className="btn mx-1 btn-sm btn-primary border-0 config-buttons"
                                                        onClick={() => {
                                                            // sessionStorage.setItem('ike', '1');
                                                            history.push('/idle-timeout')
                                                        }}>
                                                        Idle Timeout
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mt-4">
                                        <div className="card">
                                            <div className="card-header d-flex align-items-center">
                                                <img src={ThirdPartyImg} width="30" />
                                                <h6 className="fw-bold m-1 text-primary">3<sup>rd</sup> Party Integration</h6>
                                            </div>
                                            <div className="card-body">
                                                <div className="card-details">
                                                    <p>Providers will be able to manage 3rd party integrations like comm setup and payment gateway, this API system can be managed for multiple integrators. </p>
                                                </div>
                                                <div className="text-end mt-5">
                                                   
                                                    <button className="btn px-1 btn-sm btn-primary border-0 config-buttons"
                                                        onClick={() => {
                                                            sessionStorage.setItem('ike', '1');
                                                            history.push('/administrator/comms-gateway')
                                                        }}>
                                                        Comms Gateways
                                                    </button>
                                                    <button className="btn mx-1 btn-sm btn-primary border-0 config-buttons"
                                                        onClick={() => {
                                                            sessionStorage.setItem('ike', '1');
                                                            history.push('/paymentgateway')
                                                        }}>
                                                        Payment Gateway
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mt-4">
                                        <div className="card">
                                            <div className="card-header d-flex align-items-center">
                                                <img src={Analytics} width="30" />
                                                <h6 className="fw-bold m-1 text-primary">Log Analytics</h6>
                                            </div>
                                            <div className="card-body">
                                                <div className="card-details">
                                                    <p>Access to the Platform along with the devices can be monitored in this, user audit along with the background transaction audit features are integrated.</p>
                                                </div>
                                                <div className="text-end mt-5">
                                                    <button className="btn mx-1 btn-sm btn-primary border-0 config-buttons"
                                                        onClick={() => {
                                                            sessionStorage.setItem('ike', '1');
                                                            history.push('/user-audit')
                                                        }}>
                                                        User Audit
                                                    </button>
                                                    <button className="btn mx-1 btn-sm btn-primary border-0 config-buttons"
                                                        onClick={() => {
                                                            sessionStorage.setItem('ike', '1');
                                                            history.push('/device-management')
                                                        }}>
                                                        Device Management
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                    {/* <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mt-4">
                                        <div className="card">
                                            <div className="card-header d-flex align-items-center">
                                                <img src={Analytics} width="30" />
                                                <h6 className="fw-bold m-1 text-primary">System Configuration</h6>
                                            </div>
                                            <div className="card-body">
                                                <div className="card-details">
                                                    <p>User management facilitates the functionality for administrators to manage user access to various Apps & resources like platform/portal.</p>
                                                </div>
                                                <div className="text-end mt-5">
                                                    <button className="btn mx-1 btn-sm btn-primary border-0 config-buttons"
                                                        onClick={() => {
                                                            // sessionStorage.setItem('ike', '1');
                                                            history.push('/idle-timeout')
                                                        }}>
                                                        Idle Timeout
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                    <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mt-4">
                                        <div className="card">
                                            <div className="card-header d-flex align-items-center">
                                                {/* <img src={clcd} width="30" /> */}
                                                <h6 className="fw-bold m-1 text-primary">Provider</h6>
                                            </div>
                                            <div className="card-body">
                                                <div className="card-details">
                                                    <p>Developer version will have features to manage cloud resources and their secrets, users can manage their proprietary CI/CD tools and repos from here.</p>
                                                </div>
                                                <div className="text-end mt-5">
                                                    <button className="btn mx-1 btn-sm btn-primary border-0 config-buttons"
                                                        onClick={() => {
                                                            // sessionStorage.setItem('ike', '1');
                                                            history.push('/cloud-connect')
                                                        }}>
                                                        Manage Provider
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mt-4">
                                        <div className="card">
                                            <div className="card-header d-flex align-items-center">
                                                {/* <img src={clcd} width="30" /> */}
                                                <h6 className="fw-bold m-1 text-primary">Resources</h6>
                                            </div>
                                            <div className="card-body">
                                                <div className="card-details">
                                                    <p>Developer version will have features to manage cloud resources and their secrets, users can manage their proprietary CI/CD tools and repos from here.</p>
                                                </div>
                                                <div className="text-end mt-5">
                                                    <button className="btn mx-1 btn-sm btn-primary border-0 config-buttons"
                                                        onClick={() => {
                                                            // sessionStorage.setItem('ike', '1');
                                                            history.push('/repo-connect')
                                                        }}>
                                                        Manage Resources
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mt-4">
                                        <div className="card">
                                            <div className="card-header d-flex align-items-center">
                                                {/* <img src={clcd} width="30" /> */}
                                                <h6 className="fw-bold m-1 text-primary">Database</h6>
                                            </div>
                                            <div className="card-body">
                                                <div className="card-details">
                                                    <p>Developer version will have features to manage cloud resources and their secrets, users can manage their proprietary CI/CD tools and repos from here.</p>
                                                </div>
                                                <div className="text-end mt-5">
                                                    <button className="btn mx-1 btn-sm btn-primary border-0 config-buttons"
                                                        onClick={() => {
                                                            // sessionStorage.setItem('ike', '1');
                                                            // history.push('/repo-connect');
                                                            history.push('/database-server');
                                                        }}>
                                                        Manage Database
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mt-4">
                                        <div className="card">
                                            <div className="card-header d-flex align-items-center">
                                                {/* <img src={clcd} width="30" /> */}
                                                <h6 className="fw-bold m-1 text-primary">Cluster</h6>
                                            </div>
                                            <div className="card-body">
                                                <div className="card-details">
                                                    <p>Developer version will have features to manage cloud resources and their secrets, users can manage their proprietary CI/CD tools and repos from here.</p>
                                                </div>
                                                <div className="text-end mt-5">
                                                    <button className="btn mx-1 btn-sm btn-primary border-0 config-buttons"
                                                        onClick={() => {
                                                            // sessionStorage.setItem('ike', '1');
                                                            history.push('/cluster')
                                                        }}>
                                                        Manage Cluster
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mt-4">
                                        <div className="card">
                                            <div className="card-header d-flex align-items-center">
                                                {/* <img src={clcd} width="30" /> */}
                                                <h6 className="fw-bold m-1 text-primary">Registery</h6>
                                            </div>
                                            <div className="card-body">
                                                <div className="card-details">
                                                    <p>Developer version will have features to manage cloud resources and their secrets, users can manage their proprietary CI/CD tools and repos from here.</p>
                                                </div>
                                                <div className="text-end mt-5">
                                                    <button className="btn mx-1 btn-sm btn-primary border-0 config-buttons"
                                                        onClick={() => {
                                                            // sessionStorage.setItem('ike', '1');
                                                            history.push('/registery')
                                                        }}>
                                                        Manage Registery
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mt-4">
                                        <div className="card">
                                            <div className="card-header d-flex align-items-center">
                                                {/* <img src={clcd} width="30" /> */}
                                                <h6 className="fw-bold m-1 text-primary">Repository</h6>
                                            </div>
                                            <div className="card-body">
                                                <div className="card-details">
                                                    <p>Developer version will have features to manage cloud resources and their secrets, users can manage their proprietary CI/CD tools and repos from here.</p>
                                                </div>
                                                <div className="text-end mt-5">
                                                    <button className="btn mx-1 btn-sm btn-primary border-0 config-buttons"
                                                        onClick={() => {
                                                            // sessionStorage.setItem('ike', '1');
                                                            // history.push('/repo-connect');
                                                            history.push('/repositary');
                                                        }}>
                                                        Manage Repository
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mt-4">
                                        <div className="card">
                                            <div className="card-header d-flex align-items-center">
                                                {/* <img src={clcd} width="30" /> */}
                                                <h6 className="fw-bold m-1 text-primary">Environment</h6>
                                            </div>
                                            <div className="card-body">
                                                <div className="card-details">
                                                    <p>Developer version will have features to manage cloud resources and their secrets, users can manage their proprietary CI/CD tools and repos from here.</p>
                                                </div>
                                                <div className="text-end mt-5">
                                                    <button className="btn mx-1 btn-sm btn-primary border-0 config-buttons"
                                                        onClick={() => {
                                                            // sessionStorage.setItem('ike', '1');
                                                            history.push('/environment')
                                                        }}>
                                                        Manage Environment
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mt-4">
                                        <div className="card">
                                            <div className="card-header d-flex align-items-center">
                                                {/* <img src={clcd} width="30" /> */}
                                                <h6 className="fw-bold m-1 text-primary">Versions</h6>
                                            </div>
                                            <div className="card-body">
                                                <div className="card-details">
                                                    <p>Developer version will have features to manage cloud resources and their secrets, users can manage their proprietary CI/CD tools and repos from here.</p>
                                                </div>
                                                <div className="text-end mt-5">
                                                    <button className="btn mx-1 btn-sm btn-primary border-0 config-buttons"
                                                        onClick={() => {
                                                            // sessionStorage.setItem('ike', '1');
                                                            history.push('/version')
                                                        }}>
                                                        Manage Versions
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mt-2 mt-4">
                                        <div className="card">
                                            <div className="card-header d-flex align-items-center">
                                                <img src={partner} width="30" />
                                                <h6 className="fw-bold m-1 text-primary">Partner Management</h6>
                                            </div>
                                            <div className="card-body">
                                                <div className="card-details">
                                                    <p>Manage partners and their benefits plans using this feature, you will be able to add multiple partners and design individual commission plan for each of them.</p>
                                                </div>
                                                <div className="text-end mt-5">
                                                    <button className="btn mx-1 btn-sm btn-primary border-0 config-buttons"
                                                        onClick={() => {
                                                            // sessionStorage.setItem('ike', '1');
                                                            history.push('/partner-management')
                                                        }}>
                                                        Partner Setup
                                                    </button>
                                                    <button className="btn mx-1 btn-sm btn-primary border-0 config-buttons"
                                                        onClick={() => {
                                                            // sessionStorage.setItem('ike', '1');
                                                            history.push('/commision-plan')
                                                        }}>
                                                        Commision Plan
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div style={{ height: 50 }} />
                    </div>
                </div>
            </div>
            <NxFooter companyName="© 2023 Altascio Technologies (I) Pvt. Ltd.," ExtraContent={[
                { name: 'Terms of Service' },
                { name: 'Privacy' },
            ]} />
        </div>
    )
}
