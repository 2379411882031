import React, { useEffect, useState } from "react";
import './CloudConnect.css';
import { useHistory } from 'react-router-dom';
import { NxLoader, NxTitleTab, NxActionTab, NxGridList, NxFooter } from '@afiplfeed/itr-app';
import { ItrApiService } from '@afiplfeed/itr-ui';
import Headers from "../../Screens/Headers/Headers";
import CustomerSidebar from "../../Screens/CustomerSidebar/CustomerSidebar";


const splits = async (dests, setData, setExport) => {
    let arr = [];
    let arr2 = [];
    for (let value of dests) {
        let data = [value.name, value.clientID, value.azureID, value.isActive == true ? 'Active' : 'inActive'];
        let obj = { sendItem: value, data: data };
        let obj2 = {
            'Subscription Name': value.name,
            'Client ID': value.clientID,
            'Subscription ID': value.azureID,
            'Status': value.isActive == true ? 'Active' : 'inActive'
        }
        arr2 = [...arr2, obj2]
        arr = [...arr, obj]
    }
    setExport(arr2);
    setData(arr)
}

const splits1 = async (dests, setData, setExport) => {
    let arr = [];
    let arr2 = []
    // for (let value of dests) {
    let data = [dests.name, dests.clientID, dests.azureID, dests.isActive == true ? 'Active' : 'inActive'];
    let obj = { sendItem: dests, data: data };
    let obj2 = {
        'Subscription Name': dests.name,
        'Client ID': dests.clientID,
        'Subscription ID': dests.azureID,
        'Status': dests.isActive == true ? 'Active' : 'inActive'
    }
    arr2 = [...arr2, obj2];
    arr = [...arr, obj]
    // }
    setExport(arr2);
    setData(arr)
}


export default function CloudConnect() {
    let history = useHistory();
    const [load, setLoad] = useState(false);
    const [exportVal, setExport] = useState([]);
    const [handel, setHandel] = useState(false);
    useEffect(() => {
        setLoad(true)
        ItrApiService.GET({
            url: 'CloudConnect/Configuration/AzureSubscription'
        }).then(res => {
            if (res.Success == true) {
                if (res.result.isSuccess == true) {
                    if (res.result.data) {
                        setVale(res.result.data);
                        splits1(res.result.data, setData, setExport)
                    }
                }
            }
            setLoad(false)
        })
    }, []);



    const [vals, setVale] = useState([])
    const [data, setData] = useState([]);
    return (
        <div>
            <Headers />
            <NxTitleTab className="title-tab" title="Providers" onCloseClick={() => {
                sessionStorage.removeItem('rc');
                history.goBack()
            }} />
            <div className="billing-tabs billing-main">
                <NxLoader state={load} />
                {/* <Headers /> */}
                {/* <VxBreadcrumb className="navigation-breadcrumb" data={[
                {
                    name: 'Home',
                    onClick: () => {
                        history.push('/partner-board');
                    }
                }
            ]} /> */}
                <div className="customer-dynamic-pages pt-0">
                    {/* <NxTitleTab className="title-tab page-title" title='Azure Connect' onCloseClick={() => {
                    history.goBack();
                }} /> */}

                    <div className="onboarding-main mt-1">
                        <div className="d-flex justify-content-between align-items-center">
                            <NxActionTab className="px-0"
                                exportValue={exportVal}
                                fileName='Azure Cloud'
                                // onColumnsClick={() => { setHandel(true) }}
                                onRefreshClick={() => {
                                    setLoad(true)
                                    setTimeout(function () {
                                        setLoad(false)
                                    }, 2000);
                                }}
                                onNewEntryClick={() => {
                                    history.push('/cloud-connect/add')
                                }}
                                enableNewEntry={false}
                            />
                            <button className='btn btn-sm' style={{ backgroundColor: 'rgb(82, 196, 26)', color: 'white', fontSize: '14px' }} onClick={() => history.push('/cloud-connect/add')}>Add Azure Cloud </button>
                        </div>
                        <div className="mb-5">
                            <NxGridList
                                enableSearch={true}
                                autoSearch={true}
                                enableAutoPagination={true}
                                showShowing={true}
                                header={[
                                    {
                                        name: 'Subscription Name',
                                        sort: false,
                                        search: true
                                    },
                                    {
                                        name: 'Client ID',
                                        sort: false,
                                        search: true
                                    },
                                    {
                                        name: 'Subscription ID',
                                        sort: false,
                                    },
                                    {
                                        name: 'Status',
                                        sort: false,
                                    },

                                ]}
                                rowClick={(data) => {
                                    history.push({
                                        pathname: '/cloud-connect/edit',
                                        state: data
                                    })
                                }}
                                data={data}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/* <NxFooter className="verscio-footer" companyName="© 2022 Ithred Solutions" ExtraContent={[
                { name: 'Terms of Service' },
                { name: 'Privacy' },
            ]} /> */}
        </div>
    )
}