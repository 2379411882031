import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Popover } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import './Style.css'

function Version({ datas1, setDatas1, onPublishClick = () => { } }) {
    return (
        <div className='p-3'>
            <div className='f-virision-pad-static'>
                <label className='f-badge-title'>Looks Good!</label><br /><br />
                <label>You are now ready to publish the service in to App Store.

                    Pls add a Version number</label><br />
                <tr>
                    <td>
                        <div className='f-virision-pad3'>
                            <label htmlFor="AppStoreVersion">App Store Version</label>
                        </div>
                    </td>
                    <td>
                        <div className='f-virision-pad'>
                            <input className="form-control form-control-sm  mt-1" value={datas1.storeVersion} id="AppStoreVersion" type="text" disabled={false} onChange={(e) => {
                                setDatas1({ ...datas1, storeVersion: e.target.value })
                            }} />
                        </div>
                    </td>
                    <td>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', paddingLeft: 10 }} className='f-virision-pad'>
                            <Popover content={<div className='p-2'>Pls enter a unique / incremental version number</div>} >
                                <FontAwesomeIcon icon={faInfoCircle} size='lg' color='#1890ff' style={{ cursor: 'pointer' }} onClick={() => {

                                }} />
                            </Popover>
                        </div>
                    </td>
                </tr>
                <Button type='primary' style={{ marginLeft: 0, marginTop: 40 }} onClick={onPublishClick}>
                    Publish
                </Button>
            </div>
        </div>
    );
}

export default Version;