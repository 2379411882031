import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import './Customintegration.css';
import { NxLoader, NxTitleTab, NxFooter } from '@afiplfeed/itr-app';
import Headers from '../../../../Screens/Headers/Headers';
import { ItrApiService } from '@afiplfeed/itr-ui';
import { NotificationManager } from 'react-notifications';
// import Customintegration from '../PaymentGateway';

export default function Customintegration() {
    let history = useHistory();
    const [loading, setLoading] = useState(false);

    const [gateway, setGateway] = useState({});

    const [gatewayData, setGatewayData] = useState({
        smsGatewayID: 0,
        url: "https://www.fast2sms.com/dev/bulkV2",
        apiKey: ""
    });


    // save-api
    const saveSmsGateway = async () => {
        setLoading(true);
        let result = await ItrApiService.POST({
            url: 'Platform/Configuration/PaymentGateway',
            data: gatewayData
        });
        if (result.result.isSuccess == true) {
            NotificationManager.success(result.message);
        }
        else if (result.result.isSuccess == false) {
            NotificationManager.error(result.message);
        }
        setLoading(false);
    }


    const getPaymentGateway = async () => {
        setLoading(true);
        let result = await ItrApiService.GET({
            url: 'Platform/Configuration/PaymentGateway',
        });
        if (result.result.isSuccess == true) {
            let getGatewayDetails = result.result.data;
            if (result.result.data) {
                setGatewayData(getGatewayDetails);
            }
        }
        else if (result.result.isSuccess == false) {

        }
        setLoading(false);
    }

    useEffect(() => {
        getPaymentGateway();
    }, [])
    return (
        <div>
            <NxLoader state={loading} />
            <Headers />
            <NxTitleTab className="title-tab" title='Payment Gateway Intergration' onCloseClick={() => history.goBack()} />

            <div className="billing-tabs billing-main">
                <Tabs defaultActiveKey="1" type="card">
                    <TabPane tab={
                        <div className="d-flex justify-content-center">
                            {/* <span ><DashboardOutlined /></span> */}
                            <span style={{ fontSize: '16px', paddingLeft: "12px", paddingRight: "12px" }} >Custom Integrations.</span>
                        </div>
                    } key="1">
                        <Customintegration />
                    </TabPane>
                    <TabPane tab={
                        <div className="d-flex justify-content-center">
                            {/* <span ><DashboardOutlined /></span> */}
                            <span style={{ fontSize: '16px', paddingLeft: "12px", paddingRight: "12px" }} >Connected Apps.</span>
                        </div>
                    } key="1">
                    </TabPane>
                </Tabs>
            </div>

            <NxFooter companyName="© 2022 Ithred Solutions" ExtraContent={[
                { name: 'Terms of Service' },
                { name: 'Privacy' },
            ]} />
        </div>
    )
}
