import React, { useEffect, useState } from "react";
import './customers.css';
import { useHistory } from 'react-router-dom';
import { NxLoader, NxBreadcrumb, NxTitleTab, NxActionTab, NxGridList, NxRightDrawer, NxFooter } from '@afiplfeed/itr-app';
import { ItrApiService } from '@afiplfeed/itr-ui';
import Headers from "../../Screens/Headers/Headers";
import { message } from "antd";
import { NotificationManager } from "react-notifications";

const splits = async (dests, setEnquiry, refreshInvice) => {
    let arr = [];
    for (let value of dests) {
        let data = [value.entityCode, value.entityName, value.noOfEmployees, value.sourcedBy, value.partnerName ? value.partnerName : '-', value.entityBoarding ? value.entityBoarding.customerType : '-',
        // <div>
        //     <button className="btn btn-sm" style={{ fontSize: '14px', backgroundColor: 'rgb(115, 165, 98)', color: 'white' }}
        //         onClick={(e) => {
        //             e.stopPropagation();
        //             ItrApiService.POST({
        //                 url: `RevOps/ServiceBilling/GenerateServiceBill/${value.entityCode}`,
        //                 // data:
        //             }).then((res) => {
        //                 if (res.Success == true) {
        //                     if (res.result.isSuccess == true) {
        //                         refreshInvice();
        //                         message.success('Successfully Generated');
        //                     }
        //                 }
        //             })
        //         }}>Generate Invoice</button>
        // </div>,
        <div>
            <button className="btn btn-sm" style={{ fontSize: '12px', backgroundColor: value.isActive == true ? 'green' : 'red', color: 'white' }}>
                {value.isActive == true ? 'Active' : 'InActive'}
            </button>
        </div>];
        let obj = { sendItem: value, data: data };
        arr = [...arr, obj]
    }
    setEnquiry(arr);
}

export default function Customer() {
    let history = useHistory();
    const [load, setLoad] = useState(false);
    const [handel, setHandel] = useState(false);
    const [handel2, setHandel2] = useState(false);


    const [customers, setCustomers] = useState([]);
    const [passCustomers, setpassCustomers] = useState();
    const [loading, setLoading] = useState(false);



    const refreshInvice = async () => {
        setLoad(true);
        let result = await ItrApiService.GET({
            url: 'RevOps/Entity/GetEntities/true'
        });
        if (result.Success == true) {
            if (result.result.isSuccess == true) {
                let getCustomerDetails = result.result.data;
                if (getCustomerDetails === null || !getCustomerDetails.length) {
                    // NotificationManager.error(result.result.message);
                    setCustomers([]);
                }
                else {
                    await splits(getCustomerDetails, setCustomers, getData);
                    await setpassCustomers(getCustomerDetails);
                }
                // setCustomers(getCustomerDetails);
            }
            else if (result.result.isSuccess == false) {
                setCustomers([]);
                // NotificationManager.error(result.result.message);
                // sessionStorage.clear();
                // history.push('/')
            }
        }
        setLoad(false);
    }



    const getData = async () => {
        setLoad(true);
        let result = await ItrApiService.GET({
            url: 'RevOps/Entity/GetEntities/true'
        });
        if (result.Success == true) {
            if (result.result.isSuccess == true) {
                let getCustomerDetails = result.result.data;
                if (getCustomerDetails === null || !getCustomerDetails.length) {
                    // NotificationManager.error(result.result.message);
                    setCustomers([]);
                }
                else {
                    await splits(getCustomerDetails, setCustomers, refreshInvice);
                    await setpassCustomers(getCustomerDetails);
                }
                // setCustomers(getCustomerDetails);
            }
            else if (result.result.isSuccess == false) {
                setCustomers([]);
                // NotificationManager.error(result.result.message);
                // sessionStorage.clear();
                // history.push('/')
            }
        }
        setLoad(false);
    }

    useEffect(() => {
        getData();
        // await splits(dest, setEnquiry);
    }, []);

    return (
        <div>
            <NxLoader state={load} />
            {/* <Headers/> */}
            {/* <NxBreadcrumb className="navigation-breadcrumb" data={[
                {
                    name: 'Home',
                    onClick: () => {
                        history.push('/partner-board');
                    }
                }
            ]} /> */}
            <div style={{ flex: 1 }}>
                {/* <NxTitleTab className="title-tab" title='Customer' onCloseClick={() => {
                    history.push('/customers');
                }} /> */}
                {/* <NxActionTab className="px-0 py-0"
                    onColumnsClick={() => { setHandel(true) }}
                    onRefreshClick={() => {
                        setLoad(true)
                        setTimeout(function () {
                            setLoad(false)
                        }, 2000);
                    }}
                    onNewEntryClick={() => { history.push('/customer/add') }}
                    enableNewEntry={true}
                /> */}
                <div className="px-0 pt-0">
                    <NxGridList
                        enableSearch={true}
                        autoSearch={true}
                        enableAutoPagination={true}
                        showShowing={true}
                        header={[
                            {
                                name: 'Entity Code',
                                sort: true,
                            },
                            {
                                name: 'Entity Name',
                                search: true,
                                sort: true,
                            },
                            {
                                name: 'No Of Employees',
                                // sort: true,
                            },
                            {
                                name: 'Source Type',
                            },
                            {
                                name: 'Partner Name',
                            },
                            {
                                name: 'Customer Type',
                                // sort: true,
                            },
                            {
                                name: 'Customer Status',
                                // sort: true,
                            },
                            {
                                name: '',
                            },
                        ]}

                        rowClick={(data) => {
                            localStorage.setItem('passData', JSON.stringify(data));
                            history.push({
                                pathname: '/customer/summary',
                                state: {
                                    passData: data
                                }
                            });
                        }}
                        data={customers} />
                </div>

                <NxRightDrawer onClose={() => setHandel(false)} show={handel} drawerContent={() => <div>

                </div>} ondrawer1Close={() => { setHandel2(false) }} />
            </div>

            {/* <NxFooter ExtraContent={[
                {name:'Terms of Service'},
                {name:'privacy'},
            ]} /> */}
        </div>
    )
}