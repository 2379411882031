import React, { useState, useEffect } from 'react';
import './Taxation.css';
import { useHistory } from 'react-router';
import { NxTitleTab, NxLoader, NxFooter, NxGridList, NxActionTab } from '@afiplfeed/itr-app';
import { ItrApiService } from '@afiplfeed/itr-ui';
import { Countries } from '../../../general/general';
import { Switch } from 'antd';
import Headers from '../../../Screens/Headers/Headers';
import moment from 'moment';
import { NotificationManager } from 'react-notifications';



const splits = async (dests, setData, setExport) => {
    let arr = [];
    let arr2 = [];
    for (let value of dests) {
        let data = [value.billOriginCountryName, moment(value.recordedDate).format('DD/MM/YYYY'), moment(value.modifiedDate).format('DD/MM/YYYY'), value.recordedBy];
        let obj = { sendItem: value, data: data };
        let obj2 = {
            'Billing Origination Country': value.billOriginCountryName,
            'Created Date': moment(value.recordedDate).format('DD/MM/YYYY'),
            'Updated date': moment(value.modifiedDate).format('DD/MM/YYYY'),
            'Updated By': value.recordedBy
        }
        arr2 = [...arr2, obj2];
        arr = [...arr, obj]
    }
    setExport(arr2);
    setData(arr)
}


const splits2 = async (dests, setData) => {
    let arr = [];
    // for (let value of dests) {
    let data = [dests.billOriginCountryName, dests.pat];
    let obj = { sendItem: dests, data: data };
    arr = [...arr, obj]
    // }
    setData(arr)
}


export default function Taxation() {
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [exportVal, setExport] = useState([]);
    const [data, setData] = useState([])
    useEffect(() => {
        setLoading(true);
        ItrApiService.GET({ url: 'RevOps/Taxation/GetTaxations' }).then(res => {
            if (res.Success == true) {
                if (res.result.isSuccess == true) {
                    if (res.result.data) {
                        splits(res.result.data, setData, setExport)
                    }
                } else {
                    NotificationManager.error(res.result.message);
                }
            } else {
                NotificationManager.error(res.message);
            }
            setLoading(false);
        })
    }, [])
    return (
        <div>
            {/* <Headers /> */}
            <NxLoader state={loading} />
            {/* <NxTitleTab className="title-tab" title='3D Taxation' onCloseClick={() => history.goBack()} /> */}
            <>
                <div className="card1 mt-0">
                    <div className="card-body1">
                        <div className="container-form mb-5">
                            <div className='d-flex justify-content-between align-items-center'>
                                {/* <NxActionTab className="resource-groups-list-action-tab px-0"
                                    exportValue={exportVal}
                                    fileName='Taxation'
                                    onRefreshClick={() => {
                                        
                                    }}
                                    onNewEntryClick={() => {
                                        
                                    }}
                                    enableNewEntry={false}
                                /> */}
                                {/* <button className='btn btn-sm' style={{ backgroundColor: 'rgb(82, 196, 26)', color: 'white', fontSize: '14px' }} onClick={() => history.push('/taxation/add')}>New Tax Matrix </button> */}
                            </div>
                            <div className="row mt-0 mx-0">
                                <NxGridList
                                    enableSearch={false}
                                    autoSearch={false}
                                    enableAutoPagination={false}
                                    showShowing={false}
                                    header={[
                                        {
                                            name: 'Billing Origination Country',
                                            search: true,
                                            sort: true,
                                        },
                                        {
                                            name: 'Created Date',
                                            sort: true,
                                        },
                                        {
                                            name: 'Updated Date',
                                            sort: true,
                                        },
                                        {
                                            name: 'Updated By',
                                            search: true,
                                        },
                                    ]}
                                    data={data}
                                    rowClick={(e) => {
                                        history.push({
                                            pathname: '/taxation/edit',
                                            state: e
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
            {/* <NxFooter className="verscio-footer" companyName="© 2022 Ithred Solutions" ExtraContent={[
                { name: 'Terms of Service' },
                { name: 'Privacy' },
            ]} /> */}
        </div >
    )
}
