import { NxGridList } from '@afiplfeed/itr-app';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import './Style.css'
function PricingHistory({ vh }) {
    const splits = async (dests, setEnquiry) => {
        let arr = [];
        if (dests && dests.pricingHistories && dests.pricingHistories.length > 0) {
            for (let value of dests.pricingHistories) {
                let data = [ moment(value.revisionDate).format('MM/DD/YYYY'),value.pricingModule,value.amount,value.from,value.to,value.userCount];
                let obj = { sendItem: value, data: data };
                arr = [...arr, obj]
            }
            setEnquiry(arr)
        } else {
            setEnquiry([{ sendItem: '0', data: ['-', '-', '-', '-', '-', '-'] }])
        }
    }
    const [data, setData] = useState([])
    useEffect(() => {
        splits(vh, setData);
    }, [vh]);
    return (
        <NxGridList
            enableSearch={false}
            enableAutoPagination={false}
            showShowing={false}
            className='gr-pad p-0'
            header={[
                {
                    name: 'Revision Date',
                    sort: true,
                },
                {
                    name: 'Pricing Model',
                },
                {
                    name: 'Amount',
                },
				{
                    name: 'From',
                },
				{
                    name: 'To',
                },
				{
                    name: 'UserCount',
                },
            ]}
            data={data}
        />
    );
}

export default PricingHistory;