import { ItrApiService, ItrAuthService } from '@afiplfeed/itr-ui';
import React, { useEffect, useState } from 'react';

function Pricing({ code }) {

    const [priceDetails, setPricingDetails] = useState([]);

    console.log(priceDetails)
    // console.log('priceDetails', priceDetails)

    const getData = async () => {
        let result = await ItrApiService.GET({
            url: `RevOps/StoreConnect/StoreConnectByServiceCode/${code}`
        });
        if (result.Success == true) {
            if (result.result.isSuccess == true) {
                let data = result.result.data;
                console.log('subcritpion', data);
                if (data === null || !data.storeConnectPricings.length) {
                    // alert(result.result.message);
                    setPricingDetails([]);
                }
                else {
                    setPricingDetails(data.storeConnectPricings);
                    // console.log(data.storeConnectPricings)
                }
            }
            else if (result.result.isSuccess == false) {
                setPricingDetails([]);
            }
        } else {
            setPricingDetails([]);
        }
    }



    const [userInfo, setUserInfo] = useState({
        currency: ""
    })

    const getUserDetails = async () => {
        let result = await ItrAuthService.GetUserInfo();
        if (result.Success == true) {
            let getUserDetails = result.result;
            setUserInfo(getUserDetails);
        }
        else if (result.Success == false) {
            alert(result.message);
        }
    }

    useEffect(async () => {
        await getData();
        await getUserDetails();
    }, [code]);


    let rupeeIndian = Intl.NumberFormat("en-IN", {
        // style: "currency",
        currency: "INR",
    });

    return (
        <div>
            <div className='table-wrapper-scroll-y my-custom-scrollbar'>
                <table className='table table-sm'>
                    <thead>
                        <tr>
                            <th>Pricing Module</th>
                            <th>Amount</th>
                            <th hidden={!(priceDetails && priceDetails[0]?.pricingModule == 'Volume' || priceDetails && priceDetails[0]?.pricingModule == 'Tiered')} >From</th>
                            <th hidden={!(priceDetails && priceDetails[0]?.pricingModule == 'Volume' || priceDetails && priceDetails[0]?.pricingModule == 'Tiered')} >To</th>
                            <th hidden={priceDetails[0]?.pricingModule != 'Step-Up'} >UserCount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {priceDetails.map((data, index) => {
                            return <tr key={index}>
                                <td> {data.pricingModule} </td>
                                <td> {rupeeIndian.format(data.amount)} /- {userInfo.currency ? userInfo.currency.split("-")[0] : ''} </td>
                                <td hidden={!(data?.pricingModule == 'Volume' || data?.pricingModule == 'Tiered')}> {data.from} </td>
                                <td hidden={!(data?.pricingModule == 'Volume' || data?.pricingModule == 'Tiered')}> {data.to} </td>
                                <td hidden={data?.pricingModule != 'Step-Up'}> {data?.userCount} </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Pricing
