import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import './ApiManagement.css';
import { NxLoader, NxTitleTab, NxFooter } from '@afiplfeed/itr-app';
import Headers from '../../../Screens/Headers/Headers';
import { ItrApiService } from '@afiplfeed/itr-ui';
import { Switch, message } from 'antd';
import { NotificationManager } from 'react-notifications';

export default function ApiManagement() {
    let history = useHistory();
    const [loading, setLoading] = useState(false);

    const [gateway, setGateway] = useState({});
    const getIdleApi = async () => {
        setLoading(true);
        let result = await ItrApiService.GET({
            url: 'Platform/Configuration/IdleSessionSignOut',
        });
        if (result.Success == true) {
            if (result.result.isSuccess == true) {
                let vals = result.result.data;
                if (result.result.data) {
                    setData(vals);
                }
            } else {
                NotificationManager.error(result.result.message);
            }
        } else {
            NotificationManager.error(result.message);
        }
        setLoading(false);
    }
    const [loader, setLoader] = useState(false);

    const [data, setData] = useState({
        idleSessionSignOutID: 0,
        isIdleSessionSignOut: false,
        userSignOutTime: 0,
        userNoticeTime: 0
    });

    const [enableToken, setEnableToken] = useState(false);


    useEffect(() => {
        getIdleApi();
    }, [])
    return (
        <div>
            <Headers />
            <NxTitleTab className="title-tab" title="API token policy" onCloseClick={() => history.goBack()} />
            <div className="card password-policy-main mt-0">
                <div className="card-body p-0">
                    <div className="twoFactorModal">
                        <div className="header security-two-factor text-start">
                            <div>
                                <h4 className='m-0 p-0'>
                                    Enable token policy
                                </h4>
                                <p>you will be able to set the identity & apps tokens expiry and retention policy</p>
                            </div>
                            <div>
                                <Switch checked={enableToken} onChange={(e) => { setEnableToken(e) }} />
                            </div>
                        </div>
                        {enableToken && <div className='row'>
                            <div className='col-12 col-md-8 col-sm-12 col-lg-6 xol-xl-6'>
                                <div className='security-factor-authentication-forms'>
                                    <div className='mt-2 form-label'>
                                        <label>Identity token expiry (Mins)</label>
                                        <input className='form-control form-control-sm' value={""} />
                                    </div>
                                    <div className='mt-2 form-label'>
                                        <label>Identity refresh token (Mins)</label>
                                        <input className='form-control form-control-sm' value={""} />
                                    </div>
                                    <div className='mt-2 form-label'>
                                        <label>Apps token expiry (Mins)</label>
                                        <input className='form-control form-control-sm' value={""} />
                                    </div>
                                    <div className='mt-2 form-label'>
                                        <label>Apps refresh token (Mins)</label>
                                        <input className='form-control form-control-sm' value={""} />
                                    </div>
                                    <div className='mt-2 form-label'>
                                        <label>External apps token (Mins)</label>
                                        <input className='form-control form-control-sm' value={""} />
                                    </div>
                                    <div className='mt-2 form-label'>
                                        <label>Connected apps token (Mins)</label>
                                        <input className='form-control form-control-sm' value={""} />
                                    </div>

                                    <div>
                                        <div className="footer-profile-tab">
                                            <button className='btn'
                                                onClick={() => {
                                                    history.goBack();
                                                }}
                                            >
                                                Cancel
                                            </button>

                                            <button
                                                disabled={loader}
                                                className='btn btn-primary'
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    )
}
