import React, { useEffect, useState } from 'react';
import './deviceManagement.css';
import Headers from '../../../../Screens/Headers/Headers';
import { NxTitleTab, NxGridList, NxFooter, NxLoader } from '@afiplfeed/itr-app';
import { useHistory } from 'react-router';
import { ItrApiService } from '@afiplfeed/itr-ui';
import { NotificationManager } from "react-notifications";
export default function DeviceManagement() {
    let history = useHistory();
    useEffect(() => {
        setLoader(true)
        ItrApiService.GET({ url: 'Platform/Configuration/LogAnalytics/UsersDeviceLog' }).then(res => {
            setLoader(false)
            if (res.Success == true) {
                if (res.result.isSuccess == true) {
                    if (res.result.data) {
                        splits(res.result.data, setData);
                    }
                } else {
                    NotificationManager.error(res.result.message);
                }
            } else {
                NotificationManager.error(res.message);
            }
        })
    }, []);


    const splits = async (dests, setDatas) => {
        let arr = [];
        for (let value of dests) {
            let data = [value.entityName || '', value.displayName, value.emailAddress || value.userName, value.deviceName || '-', value.deviceType || '-', value.macid || '-', value.operatingSys || '-', value.browser || '-', value.status || '-'];
            let obj = { sendItem: value, data: data };
            arr = [...arr, obj]
        }
        setDatas(arr);
    }

    const [data, setData] = useState([]);
    const [loader, setLoader] = useState(false);

    return (
        <div>
            <NxLoader state={loader} />
            <Headers />
            <NxTitleTab className="title-tab" title='Device Management' onCloseClick={() => history.goBack()} />
            <div className="mt-0 device-main">
                <NxGridList
                    enableSearch={true}
                    autoSearch={true}
                    enableAutoPagination={true}
                    showShowing={true}
                    header={[
                        {
                            name: 'Customer Name',
                            search: true,
                            sort: true
                        },
                        {
                            name: 'Display Name',
                            search: true,
                            sort: true
                        },
                        {
                            name: 'Email / Username',
                            search: true
                        },
                        {
                            name: 'Device Name',
                            search: true
                        },
                        {
                            name: 'Device Type',
                            sort: true
                        },
                        {
                            name: 'MAC ID',
                        },
                        {
                            name: 'Operating Sys',
                            sort: true,
                            search: true
                        },
                        {
                            name: 'Browser',
                        },
                        {
                            name: 'Status',
                        },
                    ]}
                    data={data}
                />
            </div>
            <NxFooter companyName="© 2022 Ithred Solutions" ExtraContent={[
                { name: 'Terms of Service' },
                { name: 'Privacy' },
            ]} />
        </div>
    )
}
