import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import { NxHeader, NxBreadcrumb, NxTitleTab, NxCustomTab, NxFooter, NxLoader } from '@afiplfeed/itr-app';
import { ItrApiService } from '@afiplfeed/itr-ui'
import { Switch, Steps, Button, Upload, DatePicker } from 'antd';
import Headers from "../../Screens/Headers/Headers";
import { img2base64 } from "../../config/ImageFunction";
import Versioning from "./Versioning";
import FeaturedApps from "./FeaturedApps";
import Pricing from "./Pricing";
import VersionHistory from "./VersionHistory";
import PriceRevision from "./PriceRevision";
import './Style.css'
import Version from "./Version";
import CancelModal from "./CancelModal";
import moment from "moment";
import { NotificationManager } from "react-notifications";



export default function StoreConnectEntry() {

    const Buttons = () => {
        return <div className="form-button nx-enquiry-buttons mb-5">
            <button className="btn btn-primary rounded-pill buttons"> Save Draft</button>
            <button className="btn btn-primary rounded-pill"> Review</button>
            <button className="btn close btn-outline-primary rounded-pill"> Publish</button>
        </div>
    }

    const [showMod, setShowMod] = useState(false);

    const location = useLocation();
    const [datas, setdatas] = useState({})
    useEffect(() => {
        setLoading(true);
        if (!location.state) {
            history.push('/apps-store')
        } else if (!location.state.editVal) {
            history.push('/apps-store')
        } else {
            // setDatas1(location.state.editVal);
            ItrApiService.GET({
                url: `RevOps/StoreConnect/StoreConnectReviewByCode/${location.state.editVal.ServiceCode}`
            }).then(res => {
                if (res.Success == true) {
                    if (res.result.isSuccess == true) {
                        setDatas1({ ...datas1, ...res.result.data });
                        if (res.result.data) {
                            if (res.result.data.pricingModule) {
                                setPricingModule(res.result.data.pricingModule)
                            }
                            if (res.result.data.storeConnectPricings) {
                                setStoreConnectFeatureApps(res.result.data.storeConnectPricings);
                            }
                            if (res.result.data.isFeaturedApp == false || res.result.data.isFeaturedApp == true) {
                                setFeaturedApp(res.result.data.isFeaturedApp);
                            }
                        }
                    }
                }
            })
        }
        setLoading(false);
    }, []);

    const { Step } = Steps;
    const [current, setCurrent] = useState(0);

    const next = () => {
        if (current == 2) {

        } else {
            setCurrent(current + 1);
        }
    };

    const prev = () => {
        setCurrent(current - 1);
    };


    const titles = [
        { title: "Pricing" },
        { title: "Featured Apps" },
        { title: "Version" },
    ]

    const [pricingModule, setPricingModule] = useState('');
    const [storeConnectPricings, setStoreConnectPricings] = useState([])

    const [isFeaturedApp, setFeaturedApp] = useState(false);
    const [storeConnectFeatureApps, setStoreConnectFeatureApps] = useState([])


    const [datas1, setDatas1] = useState(
        {
            storeConnectID: 0,
            storeConnectCode: "",
            serviceCode: "",
            serviceName: "",
            publishedDate: moment().format(),
            serviceStatus: "",
            storeVersion: "",
            isFeaturedApp: true,
            pricingModule: "",
            storeConnectPricings: [],
            storeConnectVersions: null,
            storeConnectFeatureApps: [],
            isActive: true
        }
    )

    const [spfiles, setspfiles] = useState([]);

    const [loading, setLoading] = useState(true);
    let history = useHistory();
    return (
        <div>
            <NxLoader state={loading} />
            <Headers />
            <NxTitleTab className="title-tab" title='App Store Connect' onCloseClick={() => {
                history.goBack();
            }} />
            <div className="card mx-4 mt-0 newenrolemententry-global">
                <div className="card-body p-0">
                    <Steps labelPlacement='vertical' current={current} responsive={true} size="small" >
                        {titles.map((item, index) => {
                            return <Step
                                icon={current == index ? <div style={{ backgroundColor: '#1890ff', padding: 5, borderRadius: '24px', height: '24px', width: '24px', fontSize: '12px', display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white' }}>{index + 1}</div>
                                    : current > index && <div style={{ backgroundColor: '#73b504', padding: 5, borderRadius: '24px', height: '24px', width: '24px', fontSize: '12px', display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white' }}>{index + 1}</div>
                                    // : current < index && <div style={{ borderColor: 'rgba(0,o,0,0.25)', border: '1px solid', backgroundColor: 'white', padding: 5, borderRadius: 29, height: 29, width: 29, fontSize: 15, display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'rgba(0,o,0,0.25)' }}>{index + 1}</div>
                                }
                                key={index}
                                title={item.title} />
                        })}
                    </Steps>
                    {current == 0 ?
                        <div className="storeconnectentry-tab-body">
                            <Pricing pricingModule={pricingModule} setPricingModule={setPricingModule} storeConnectPricings={storeConnectPricings} setStoreConnectPricings={setStoreConnectPricings} />
                        </div> :
                        current == 1 ?
                            <div className="storeconnectentry-tab-body">
                                <FeaturedApps isFeaturedApp={isFeaturedApp} setFeaturedApp={setFeaturedApp} storeConnectFeatureApps={storeConnectFeatureApps} setStoreConnectFeatureApps={setStoreConnectFeatureApps} spfiles={spfiles} setspfiles={setspfiles} />
                            </div> :
                            <div className="storeconnectentry-tab-body">
                                <Version datas1={datas1} setDatas1={setDatas1} onPublishClick={() => {
                                    ItrApiService.GET({
                                        url: 'PAM/IsRequiredAdminPinVerification',
                                    }).then(res => {
                                        if (res.Success == true) {
                                            if (res.result.isSuccess == true) {
                                                if (res.result.data.isRequiredAdminPinVerification == true) {
                                                    setShowMod(true);
                                                }
                                                else {
                                                    let arr = [];
                                                    for (let val of spfiles) {
                                                        let obj = {
                                                            splashScreen: val.thumbUrl,
                                                        }
                                                        arr = [...arr, obj]
                                                    }
                                                    let body = {
                                                        ...datas1,
                                                        pricingModule: pricingModule,
                                                        storeConnectPricings: [...storeConnectPricings],
                                                        isFeaturedApp: isFeaturedApp,
                                                        storeConnectFeatureApps: arr,
                                                        serviceCode: datas1.serviceCode,
                                                        serviceName: datas1.serviceName,
                                                        storeConnectID: datas1.storeConnectID,
                                                        storeConnectCode: datas1.storeConnectCode,
                                                    }
                                                    // body = { ...body, storeConnectFeatureApps: arr };
                                                    
                                                    setLoading(true);
                                                    ItrApiService.POST({
                                                        url: 'RevOps/StoreConnect/SaveStoreConnect',
                                                        data: body
                                                    }).then(res => {
                                                        setLoading(false)
                                                        if (res.Success == true) {
                                                            if (res.result.isSuccess == true) {
                                                                history.push('/apps-store');
                                                                NotificationManager.success('Updated successfully');
                                                            } else {
                                                                NotificationManager.error(res.result.message);
                                                            }
                                                        } else {
                                                            NotificationManager.error(res.message)
                                                        }
                                                    })
                                                }
                                            }
                                        }
                                    })
                                }} />
                            </div>
                    }
                    <CancelModal title="" cancelVisible={showMod} onOk={() => {
                        setShowMod(false);

                        let arr = [];
                        for (let val of spfiles) {
                            let obj = {
                                splashScreen: val.thumbUrl,
                            }
                            arr = [...arr, obj]
                        }
                        let body = {
                            ...datas1,
                            pricingModule: pricingModule,
                            storeConnectPricings: [...storeConnectPricings],
                            isFeaturedApp: isFeaturedApp,
                            storeConnectFeatureApps: arr,
                            serviceCode: datas1.serviceCode,
                            serviceName: datas1.serviceName,
                            storeConnectID: datas1.storeConnectID,
                            storeConnectCode: datas1.storeConnectCode,
                        }
                        // body = { ...body, storeConnectFeatureApps: arr };
                        
                        setLoading(true);
                        ItrApiService.POST({
                            url: 'RevOps/StoreConnect/SaveStoreConnect',
                            data: body
                        }).then(res => {
                            setLoading(false)
                            if (res.Success == true) {
                                if (res.result.isSuccess == true) {
                                    history.push('/apps-store');
                                    NotificationManager.success('Secret Key Verification successfully');
                                } else {
                                    NotificationManager.error(res.result.message);
                                }
                            } else {
                                NotificationManager.error(res.message)
                            }
                        })

                        // history.push('/apps-store')
                    }}
                        onCanceled={() => {
                            setShowMod(false)
                        }}
                    />
                    <div className="steps-action container" style={{ textAlign: 'right' }}>
                        {current > 0 && (
                            <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                                Previous
                            </Button>
                        )}

                        {current >= 0 && current != 2 ? <Button style={{ marginLeft: 10 }} type="primary"
                            onClick={() => next()}>
                            {'Next'}
                        </Button> : ''}
                        <Button style={{ marginLeft: 10 }} onClick={() => history.goBack()}>
                            Close
                        </Button>

                    </div>
                </div>
            </div>
            <div style={{ height: 30 }} />
            {/* <div className="container enroll-button text-start">
                <Buttons />
            </div> */}
            <NxFooter ExtraContent={[
                { name: 'Terms of Service' },
                { name: 'privacy' },
            ]} />
        </div>
    )
}