import { faCreditCard, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NxGridList, NxLoader } from '@afiplfeed/itr-app';
import moment from 'moment';
import './InvoiceData.css';
import React, { useEffect, useState } from 'react';
import { Modal, TimePicker } from 'antd';
import logo from '../../../assets/logo1.png'
import { ItrApiService } from '@afiplfeed/itr-ui';
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

const splits = async (dests, setData) => {
    let arr = [];
    if (dests && dests.serviceBillingDetailModels) {
        for (let value of dests.serviceBillingDetailModels) {
            let data = [value.serviceName, moment(value.date).format('DD/MM/YYYY'), value.unitPrice, value.noofUnit, value.totalPrice];
            let obj = { sendItem: value, data: data };
            arr = [...arr, obj]
        }
        arr = [...arr, { sendItem: '', data: ['', '', '', <div style={{ fontWeight: 'bold' }}>Sub Total</div>, dests.totalBillAmount] }, { sendItem: '', data: ['', '', '', <div style={{ fontWeight: 'bold' }}>GST (18%)</div>, dests.totalGSTAmount] }, { sendItem: '', data: ['', '', '', <div style={{ fontWeight: 'bold' }}>Total</div>, dests.totalBillAmountWithGST] }]
        setData(arr);
    }
}

function InvoiceData({ companyData, invoiceData, isModalVisible, handleOk, handleCancel }) {
    const [dataVal, setDataVal] = useState([
        {
            invNo: "#13423AFR",
            date: moment().format(),
            client: 'Admin',
            invoiceTitle: 'INV TITLE',
            dueData: moment().add(1, 'month').format(),
            status: 'Active',
            invoiceAmount: 100,
            balanceDue: 100,
        }
    ]);
    const [data, setData] = useState([]);
    const [loader, setLoader] = useState(false);
    const [dataVal1, setDataVal2] = useState({})
    const [toBillersVal, setToBillersVal] = useState({})

    useEffect(() => {
        splits(invoiceData, setData)
        if (invoiceData && invoiceData.entityCode) {
            ItrApiService.GET({ url: `RevOps/Entity/GetEntityByCode/${invoiceData.entityCode}` }).then(res => {
                if (res.Success == true) {
                    if (res.result.isSuccess == true) {
                        if (res.result.data) {
                            setToBillersVal(res.result.data)

                        }
                    }
                }
            })
        }
    }, [invoiceData]);

    const printDocument = () => {
        setLoader(true);
        const input = document.getElementById('section-to-print');
        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF();
                console.log(imgData);
                console.log(canvas)
                pdf.addImage(imgData, 'JPEG', 0, 0);
                // pdf.output('dataurlnewwindow');
                pdf.save(`${invoiceData.invoiceNumber}.pdf`);
                setLoader(false);
            }).catch(err => {
                setLoader(false);
            });
    }

    return (
        <div>
            {/* <iframe id="ifmcontentstoprint" style={{ height: 0, width: 0, position: 'absolute' }}></iframe> */}
            <Modal
                footer={false}
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                width='75%'
            >
                {invoiceData && invoiceData.invoiceNumber && <div>
                    <NxLoader state={loader} />
                    <div className='d-flex justify-content-end'>
                        {/* <button className='btn btn-primary btnn-sm text-end' style={{ fontSize: '12px', padding: '5px' }}>Print</button> */}
                        <button className='btn btn-primary btnn-sm text-end mx-1' style={{ fontSize: '12px', padding: '5px' }} onClick={() => {
                            // var content = document.getElementById("print-func").innerHTML;
                            // var pri = document.getElementById("ifmcontentstoprint").contentWindow;
                            // pri.document.open();
                            // pri.document.write(content);
                            // pri.document.close();
                            // pri.focus();
                            // pri.print();
                            // window.print();
                            printDocument();
                        }}> {loader == true ? 'loading...' : 'Download'}</button>
                        {/* <button className='btn btn-primary btnn-sm text-end mx-1' style={{ fontSize: '12px', padding: '5px' }}
                            onClick={() => {
                                window.open('https://buy.stripe.com/28o8xP519g7G3n26os', '_blank');
                            }}
                        >Pay Now</button> */}
                    </div>
                    <div id='section-to-print' style={{ padding: '20px' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }} >
                                <img src={companyData.businessLogo} width={100} />
                                {/* <span style={{ fontSize: 25, color: 'gray', width: 'auto' }}>Ith-red</span> */}

                                <div className='mt-2 invoice'>
                                    <span className='my-0 invoice-details' style={{ fontSize: '11px', fontWeight: 'bold' }}> {companyData.businessName} </span><br />
                                    <span className='my-0 invoice-details' style={{ fontSize: '11px', color: 'gray' }}> {companyData.addressLine1} <br />{companyData.addressLine2},<br /> {companyData.city}, {companyData.state}, </span><br />
                                    <span className='my-0 invoice-details' style={{ fontSize: '11px', color: 'gray' }}> {companyData.pinCode}, {companyData.countryName}</span><br />
                                    {/* <span className='my-0 invoice-details' style={{ fontSize: '11px', color: 'gray' }}> </span><br /> */}
                                    <span className='my-0 invoice-details' style={{ fontSize: '11px', color: 'gray' }}> GST IN: {companyData.gst} </span><br />
                                    <span className='my-0 invoice-details' style={{ fontSize: '11px', color: 'gray' }}> CIN: {companyData.cin} </span><br />
                                </div>

                                <div className='mt-2'>
                                    <span className='my-0 invoice-details' style={{ fontSize: '11px', fontWeight: 'bold' }}> Bill to </span><br />
                                    <span className='my-0 invoice-details' style={{ fontSize: '11px', fontWeight: 'bold' }}>{toBillersVal.entityName}</span><br />
                                    <span className='my-0 invoice-details' style={{ fontSize: '11px', color: 'gray' }}>{toBillersVal.addressLine1 ? `${toBillersVal.addressLine1}, ` : ''}</span><br />
                                    <span className='my-0 invoice-details' style={{ fontSize: '11px', color: 'gray' }}>{toBillersVal.addressLine2 ? `${toBillersVal.addressLine2}, ` : '-'}</span><br />
                                    <span className='my-0 invoice-details' style={{ fontSize: '11px', color: 'gray' }}> {toBillersVal.city} , {toBillersVal.state}</span><br />
                                    <span className='my-0 invoice-details' style={{ fontSize: '11px', color: 'gray' }}> {toBillersVal.pinCode || '-'} </span><br />
                                    <span className='my-0 invoice-details' style={{ fontSize: '11px', color: 'gray' }}> {toBillersVal.countryName || '-'} </span><br />
                                    <span className='my-0 invoice-details' style={{ fontSize: '11px', color: 'gray' }}> GST IN: {toBillersVal.gst || '-'}</span><br />
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', width: '50%' }} >
                                <div>
                                    <h4>Tax Invoice</h4>
                                    {/* <button className='btn btn-primary shadow-none' onClick={() => {
                                    var content = document.getElementById("print-func").innerHTML;
                                    var pri = document.getElementById("ifmcontentstoprint").contentWindow;
                                    pri.document.open();
                                    pri.document.write(content);
                                    pri.document.close();
                                    pri.focus();
                                    pri.print();
                                    window.print();
                                }}>Print</button>
                                <button className='btn btn-success mx-3 shadow-none' disabled={true}>Paid</button> */}
                                    <div className='mt-2 invoice row' style={{ marginLeft: 35 }}>
                                        {/* <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                            <div className='row'>
                                                <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-5'>
                                                    <span className='my-0 invoice-details fw-bold' style={{ fontSize: '11px' }}> Account Number  </span>
                                                </div>

                                                <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-7'>
                                                    <span style={{ fontSize: '11px', color: 'gray' }}> acct_1K9q2eSH0Yh7CDWC </span>
                                                </div>
                                            </div>
                                        </div> */}

                                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 '>
                                            <div className='row'>
                                                <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-5 '>
                                                    <span className='my-0 invoice-details fw-bold' style={{ fontSize: '11px' }}> Invoice Number </span>
                                                </div>

                                                <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-7 '>
                                                    <span style={{ fontSize: '11px', color: 'gray' }}> {invoiceData.invoiceNumber} </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                            <div className='row'>
                                                <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-5'>
                                                    <span className='my-0 invoice-details fw-bold' style={{ fontSize: '11px' }}> Invoice Date </span>
                                                </div>

                                                <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-7 '>
                                                    <span style={{ fontSize: '11px', color: 'gray' }}> {moment(invoiceData.invoicedDate).format('ll')} </span><br />
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                            <div className='row'>
                                                <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-5'>
                                                    <span className='my-0 invoice-details fw-bold' style={{ fontSize: '11px' }}> Invoice Due Date </span>
                                                </div>

                                                <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-7 '>
                                                    <span style={{ fontSize: '11px', color: 'gray' }}> {moment(invoiceData.dueDate).format('ll')} </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                            <div className='row'>
                                                <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-5'>
                                                    <span className='my-0 invoice-details fw-bold' style={{ fontSize: '11px' }}> Service Month </span>
                                                </div>

                                                <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-7 '>
                                                    <span style={{ fontSize: '11px', color: 'gray' }}> {moment(invoiceData.invoicedDate).format('MMM YYYY')} </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                            <div className='row'>
                                                <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-5'>
                                                    <span className='my-0 invoice-details fw-bold' style={{ fontSize: '11px' }}> Payment Method </span>
                                                </div>
                                                <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-7 '>
                                                    <span style={{ fontSize: '11px', color: 'gray' }}> Online </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <span style={{ fontSize: 18, marginTop: 10 }}>#23RESE</span>
                            <span style={{ fontSize: 16, marginTop: 6 }}>7th june 2021</span> */}
                            </div>
                        </div>
                        {/* <div className='mt-4'>
                        <div className='card'>
                            <div className='card-body'>
                                <h4 style={{ color: 'grey', fontWeight: 'bold' }}>To</h4>
                                <span className='my-1 invoice-details' style={{ fontSize: '17px', color: "gray", fontWeight: 'bold' }}> Jack Perez </span><br />
                                <span className='my-1 invoice-details' style={{ fontSize: '15px', color: 'gray', fontWeight: '' }}> 1 Infinite Loop </span><br />
                                <span className='my-1 invoice-details' style={{ fontSize: '15px', color: 'gray', fontWeight: '' }}> 236543 India,IN</span><br />
                                <span className='my-1 invoice-details' style={{ fontSize: '15px', color: 'gray', fontWeight: '' }}> India</span><br />
                                <span className='my-1 invoice-details' style={{ fontSize: '15px', color: 'gray', fontWeight: '' }}> Phone : 8023420445</span><br />
                                <span className='my-1 invoice-details' style={{ fontSize: '15px', color: 'gray', fontWeight: '' }}> Email : test@mail.com</span><br />
                            </div>
                        </div>
                    </div> */}

                        <div className='mt-4'>
                            <table className='table wrapper-scroll'>

                            </table>
                        </div>

                        <div className='mt-0'>
                            <NxGridList
                                header={[
                                    {
                                        name: 'App',
                                        sort: false,
                                        search: true
                                    },
                                    {
                                        name: 'Period',
                                        sort: false,
                                    },
                                    {
                                        name: 'Unit Price',
                                        sort: false,
                                    },
                                    {
                                        name: 'Units',
                                        sort: false,
                                    },
                                    {
                                        name: 'Total',
                                        // sort: true,
                                    },

                                ]}
                                enableSearch={false}
                                autoSearch={true}
                                enableAutoPagination={false}
                                showShowing={true}
                                data={data}
                            />
                        </div>
                    </div>
                </div>}

            </Modal >
        </div >

    );
}

export default InvoiceData;