import { NxLoader, NxTitleTab } from '@afiplfeed/itr-app';
import { ItrApiService } from '@afiplfeed/itr-ui';
import React, { useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { useHistory } from 'react-router-dom'
import Headers from '../../../Screens/Headers/Headers';
import { Tabs } from 'antd';
import LicenseManagement from "../../LicenseManagement/licenseManagement";

function BillerInformation() {
    const { TabPane } = Tabs;

    // country
    const [allCountries, setAllCountries] = useState([]);
    const getCountry = async () => {
        let result = await ItrApiService.GET({
            url: 'RevOps/Country/GetCountries'
        });
        if (result.Success == true) {
            if (result.result.isSuccess == true) {
                let getCustomerDetails = result.result.data;
                if (result.result.data) {
                    setAllCountries(getCustomerDetails);
                }
            }
            else if (result.result.isSuccess == false) {
                NotificationManager.error(result.result.message);
            }
        } else {
            NotificationManager.error(result.message);
        }
    }



    // currency
    const [allCurrency, setAllCurrency] = useState([]);
    const getCurrency = async () => {
        let result = await ItrApiService.GET({
            url: 'RevOps/Currency/GetCurrencies'
        });
        if (result.Success == true) {
            if (result.result.isSuccess == true) {
                let getCustomerDetails = result.result.data;
                if (result.result.data) {
                    setAllCurrency(getCustomerDetails);
                }
            }
            else if (result.result.isSuccess == false) {
                NotificationManager.error(result.result.message);
            }
        } else {
            NotificationManager.error(result.message);
        }
    }



    const history = useHistory();
    const [loader, setLoader] = useState(false);
    const [datas, setDatas] = useState({
        "billerInfoID": 0,
        "businessName": "",
        "supportEmail": "",
        "supportPhone": "",
        "businessWebsite": "",
        "businessLogo": "",
        "addressLine1": "",
        "addressLine2": "",
        "city": "",
        "state": "",
        "pinCode": "",
        "country": 0,
        "cin": "",
        "pan": "",
        "gst": "",
        "tan": "",
        "currency": 0,
        "currencyName": ""
    });
    const [activeString, setActiveString] = useState('1')

    useEffect(() => {
        let s = sessionStorage.getItem('rc');
        if (s) {
            setActiveString(s)
        }
        setLoader(true);
        ItrApiService.GET({ url: 'RevOps/BillerInformation' }).then(res => {
            setLoader(false);
            if (res.Success == true) {
                if (res.result.isSuccess == true) {
                    if (res.result.data) {
                        setDatas(res.result.data);
                    }
                }
            }
        });
        getCountry();
        getCurrency();
    }, [])
    return (
        <div>
            <Headers />
            <NxLoader state={loader} />
            <NxTitleTab className="title-tab" title="Organization" onCloseClick={() => history.goBack()} />
            <div className="billing-tabs billing-main">
                <Tabs defaultActiveKey="1" type="card" activeKey={activeString} onChange={(e) => {
                    setActiveString(e);
                    sessionStorage.setItem('rc', e)
                }}>
                    <TabPane tab={<div className="d-flex justify-content-center px-2">
                        <span style={{ fontSize: '16px', paddingLeft: "12px", paddingRight: "12px" }}>Primary Information</span>
                    </div>} key="1">
                        <div className="card w-100 mt-0">
                            <div className="card-body p-0 mb-5" >
                                <div className="row p-3 mx-1">
                                    <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-2'>
                                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2'>
                                            <label htmlFor="billerName" className='f-badge-title'>Organization Name</label>
                                            <input className="form-control form-control-sm mt-1" type="text" id='billerName' value={datas.businessName} onChange={(e) => {
                                                setDatas({ ...datas, businessName: e.target.value })
                                            }} />
                                        </div>

                                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12  mt-2'>
                                            <label htmlFor="email" className='f-badge-title'>Email</label>
                                            <input className="form-control form-control-sm mt-1" type="email" id='email' value={datas.supportEmail} onChange={(e) => {
                                                setDatas({ ...datas, supportEmail: e.target.value })
                                            }} />
                                        </div>

                                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12  mt-2'>
                                            <label htmlFor="phNo" className='f-badge-title' >Phone Number</label>
                                            <input className="form-control form-control-sm mt-1" type="tel" id='phNo' value={datas.supportPhone} onChange={(e) => {
                                                setDatas({ ...datas, supportPhone: e.target.value })
                                            }} />
                                        </div>

                                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12  mt-2'>
                                            <label htmlFor="website" className='f-badge-title'>Website</label>
                                            <input className="form-control form-control-sm mt-1" type="url" id='website' value={datas.businessWebsite} onChange={(e) => {
                                                setDatas({ ...datas, businessWebsite: e.target.value })
                                            }} />
                                        </div>

                                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12  mt-2'>
                                            <label htmlFor="logo" className='f-badge-title'>Upload Logo</label>
                                            <input className="form-control form-control-sm mt-1" type="file" id='logo' onChange={(e) => {
                                                let img = e.target.files[0];
                                                const fileReader = new FileReader();
                                                fileReader.readAsDataURL(img)
                                                fileReader.onload = async () => {
                                                    setDatas({ ...datas, businessLogo: fileReader.result })
                                                }
                                                fileReader.onerror = async () => {
                                                    return false
                                                }
                                            }} />
                                        </div>
                                    </div>

                                    <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-2'>
                                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12  mt-2'>
                                            <label htmlFor="address1" className='f-badge-title'>Address Line 1</label>
                                            <textarea className="form-control form-control-sm mt-1" id='address1' rows="1" value={datas.addressLine1} onChange={(e) => {
                                                setDatas({ ...datas, addressLine1: e.target.value })
                                            }} />
                                        </div>

                                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12  mt-2'>
                                            <label htmlFor="address2" className='f-badge-title'>Address Line 2</label>
                                            <textarea className="form-control form-control-sm mt-1" id='address2' rows="1" value={datas.addressLine2} onChange={(e) => {
                                                setDatas({ ...datas, addressLine2: e.target.value })
                                            }} />
                                        </div>

                                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2'>
                                            <div className='row'>
                                                <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6'>
                                                    <label htmlFor="state" className='f-badge-title'>State</label>
                                                    <input className="form-control form-control-sm mt-1" type="text" id='state' value={datas.state} onChange={(e) => {
                                                        setDatas({ ...datas, state: e.target.value })
                                                    }} />
                                                </div>

                                                <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6'>
                                                    <label htmlFor="zip" className='f-badge-title'>Zip</label>
                                                    <input className="form-control form-control-sm mt-1" type='tel' id='zip' value={datas.pinCode} onChange={(e) => {
                                                        setDatas({ ...datas, pinCode: e.target.value })
                                                    }} />
                                                </div>
                                            </div>

                                        </div>

                                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2'>
                                            <label htmlFor="state" className='f-badge-title'>City</label>
                                            <input className="form-control form-control-sm mt-1" type="text" id='city' value={datas.city} onChange={(e) => {
                                                setDatas({ ...datas, city: e.target.value })
                                            }} />
                                        </div>

                                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2'>
                                            <label htmlFor="country" className='f-badge-title'>Country</label>
                                            <select className="form-select form-select-sm mt-1"
                                                value={datas.country} onChange={(e) => {
                                                    setDatas({ ...datas, country: e.target.value })
                                                }}
                                            >
                                                <option value="" selected disabled> -- Please Select -- </option>
                                                {allCountries.map((country, index) => {
                                                    return <option key={index} value={country.countryID}> {country.countryName} </option>
                                                })}
                                            </select>
                                        </div>
                                    </div>

                                    <div className='text-end'>
                                        <button className='btn btn-primary mt-4 mb-5' onClick={() => {
                                            setLoader(true);
                                            ItrApiService.POST({ data: datas, url: 'RevOps/BillerInformation' }).then(res => {
                                                if (res.Success == true) {
                                                    if (res.result.isSuccess == true) {
                                                        NotificationManager.success('Updated!');
                                                        setLoader(false);
                                                    }
                                                    else {
                                                        setLoader(false);
                                                        NotificationManager.error(res.result.message);
                                                    }
                                                }
                                                else {
                                                    setLoader(false);
                                                    NotificationManager.error(res.message);
                                                }
                                            })
                                        }}>Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TabPane>

                    <TabPane tab={<div className="d-flex justify-content-center px-2">
                        <span style={{ fontSize: '16px', paddingLeft: "12px", paddingRight: "12px" }}>Billing Information</span>
                    </div>} key="2">
                        <div className="card password-policy-main mt-0">
                            <div className="card-body p-0 mb-5">
                                <div className="row p-3 mx-1">
                                    <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-2'>
                                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2'>
                                            <label htmlFor="cin" className='f-badge-title'>Company identification number (CIN)</label>
                                            <input className="form-control form-control-sm mt-1" type="text" id='cin' value={datas.cin} onChange={(e) => {
                                                setDatas({ ...datas, cin: e.target.value })
                                            }} />
                                        </div>
                                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2'>
                                            <label htmlFor="cin" className='f-badge-title'>Business PAN (Permanent Account Number)</label>
                                            <input className="form-control form-control-sm mt-1" type="text" id='cin' value={datas.pan} onChange={(e) => {
                                                setDatas({ ...datas, pan: e.target.value })
                                            }} />
                                        </div>
                                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2'>
                                            <label htmlFor="gst" className='f-badge-title'>Goods & Services Tax (GST) Identification number</label>
                                            <input className="form-control form-control-sm mt-1" type="text" id='gst' value={datas.gst} onChange={(e) => {
                                                setDatas({ ...datas, gst: e.target.value })
                                            }} />
                                        </div>

                                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2'>
                                            <label htmlFor="tan" className='f-badge-title'>Tax deduction account number (TAN)</label>
                                            <input className="form-control form-control-sm mt-1" type="text" id='tan' value={datas.tan} onChange={(e) => {
                                                setDatas({ ...datas, tan: e.target.value })
                                            }} />
                                        </div>

                                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2'>
                                            <label htmlFor="tan" className='f-badge-title'>Base Currency</label>
                                            <select className="form-select form-select-sm mt-1"
                                                value={datas.currency}
                                                onChange={(e) => {
                                                    setDatas({ ...datas, currency: e.target.value })
                                                }}
                                            >
                                                {allCurrency && allCurrency.map((data, index) => {
                                                    return <option key={index} value={data.currencyID}> {data.currencyName} </option>
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TabPane>

                    <TabPane tab={<div className="d-flex justify-content-center px-2">
                        <span style={{ fontSize: '16px', paddingLeft: "12px", paddingRight: "12px" }}>License Information</span>
                    </div>} key="3">
                        <div className="card password-policy-main mt-0">
                            <div className="card-body p-0 mb-5">
                                <div className="row p-3 mx-1">
                                    <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-2'>
                                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2'>
                                            <label htmlFor="cin" className='f-badge-title'>Environment (Runtime) Licenses</label>
                                            <input className="form-control form-control-sm mt-1" type="text" id='cin' value={datas.cin} onChange={(e) => {
                                                setDatas({ ...datas, cin: e.target.value })
                                            }} />
                                        </div>
                                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2'>
                                            <label htmlFor="cin" className='f-badge-title'>Developer Seat Licenses</label>
                                            <input className="form-control form-control-sm mt-1" type="text" id='cin' value={datas.pan} onChange={(e) => {
                                                setDatas({ ...datas, pan: e.target.value })
                                            }} />
                                        </div>
                                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2'>
                                            <label htmlFor="gst" className='f-badge-title'>End User Licenses</label>
                                            <input className="form-control form-control-sm mt-1" type="text" id='gst' value={datas.gst} onChange={(e) => {
                                                setDatas({ ...datas, gst: e.target.value })
                                            }} />
                                        </div>

                                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2'>
                                            <label htmlFor="tan" className='f-badge-title'>Apps Enrollment</label>
                                            <input className="form-control form-control-sm mt-1" type="text" id='tan' value={datas.tan} onChange={(e) => {
                                                setDatas({ ...datas, tan: e.target.value })
                                            }} />
                                        </div>

                                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2'>
                                            <label htmlFor="tan" className='f-badge-title'>Support</label>
                                            <select className="form-select form-select-sm mt-1"
                                                value={datas.currency}
                                                onChange={(e) => {
                                                    setDatas({ ...datas, currency: e.target.value })
                                                }}
                                            >
                                                <option key={1} value="Basic"> Basic </option>
                                                <option key={2} value="Enterprise"> Enterprise </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TabPane>

                    {/* <TabPane tab={<div className="d-flex justify-content-center px-2">
                        <span style={{ fontSize: '16px', paddingLeft: "12px", paddingRight: "12px" }}>License Vault</span>
                    </div>} key="4">
                        <LicenseManagement />
                    </TabPane> */}
                </Tabs>
                {/* <div className="card password-policy-main mt-0">
                    <div className="card-body p-0 mb-5">
                        <div className="row p-3 mx-1">
                            <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-2'>
                                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2'>
                                    <label htmlFor="billerName" className='f-badge-title'>Organization Name</label>
                                    <input className="form-control form-control-sm mt-1" type="text" id='billerName'
                                        value={datas.businessName} onChange={(e) => {
                                            setDatas({ ...datas, businessName: e.target.value })
                                        }} />
                                </div>

                                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12  mt-2'>
                                    <label htmlFor="email" className='f-badge-title'>Email</label>
                                    <input className="form-control form-control-sm mt-1" type="email" id='email'
                                        value={datas.supportEmail} onChange={(e) => {
                                            setDatas({ ...datas, supportEmail: e.target.value })
                                        }} />
                                </div>

                                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12  mt-2'>
                                    <label htmlFor="phNo" className='f-badge-title'>Phone Number</label>
                                    <input className="form-control form-control-sm mt-1" type="tel" id='phNo' value={datas.supportPhone}
                                        onChange={(e) => {
                                            setDatas({ ...datas, supportPhone: e.target.value })
                                        }} />
                                </div>

                                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12  mt-2'>
                                    <label htmlFor="website" className='f-badge-title'>Website</label>
                                    <input className="form-control form-control-sm mt-1" type="url" id='website'
                                        value={datas.businessWebsite} onChange={(e) => {
                                            setDatas({ ...datas, businessWebsite: e.target.value })
                                        }} />
                                </div>

                                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12  mt-2'>
                                    <label htmlFor="logo" className='f-badge-title'>Upload Logo</label>
                                    <input className="form-control form-control-sm mt-1" type="file" id='logo' onChange={(e) => {
                                        let img = e.target.files[0];
                                        const fileReader = new FileReader();
                                        fileReader.readAsDataURL(img)
                                        fileReader.onload = async () => {
                                            setDatas({ ...datas, businessLogo: fileReader.result })
                                        }
                                        fileReader.onerror = async () => {
                                            return false
                                        }
                                    }} />
                                </div>
                            </div>

                            <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-2'>
                                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12  mt-2'>
                                    <label htmlFor="address1" className='f-badge-title'>Address Line 1</label>
                                    <textarea className="form-control form-control-sm mt-1" id='address1' rows="1"
                                        value={datas.addressLine1} onChange={(e) => {
                                            setDatas({ ...datas, addressLine1: e.target.value })
                                        }} />
                                </div>

                                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12  mt-2'>
                                    <label htmlFor="address2" className='f-badge-title'>Address Line 2</label>
                                    <textarea className="form-control form-control-sm mt-1" id='address2' rows="1" value={datas.addressLine2} onChange={(e) => {
                                        setDatas({ ...datas, addressLine2: e.target.value })
                                    }} />
                                </div>

                                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2'>
                                    <div className='row'>
                                        <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6'>
                                            <label htmlFor="state" className='f-badge-title'>State</label>
                                            <input className="form-control form-control-sm mt-1" type="text" id='state' value={datas.state} onChange={(e) => {
                                                setDatas({ ...datas, state: e.target.value })
                                            }} />
                                        </div>

                                        <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6'>
                                            <label htmlFor="zip" className='f-badge-title'>Zip</label>
                                            <input className="form-control form-control-sm mt-1" type='tel' id='zip' value={datas.pinCode} onChange={(e) => {
                                                setDatas({ ...datas, pinCode: e.target.value })
                                            }} />
                                        </div>
                                    </div>

                                </div>

                                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2'>
                                    <label htmlFor="state" className='f-badge-title'>City</label>
                                    <input className="form-control form-control-sm mt-1" type="text" id='city' value={datas.city} onChange={(e) => {
                                        setDatas({ ...datas, city: e.target.value })
                                    }} />
                                </div>

                                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2'>
                                    <label htmlFor="country" className='f-badge-title'>Country</label>
                                    <select className="form-select form-select-sm mt-1"
                                        value={datas.country} onChange={(e) => {
                                            setDatas({ ...datas, country: e.target.value })
                                        }}
                                    >
                                        <option value="" selected disabled> -- Please Select -- </option>
                                        {allCountries.map((country, index) => {
                                            return <option key={index} value={country.countryID}> {country.countryName} </option>
                                        })}
                                    </select>
                                </div>


                            </div>

                            <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-2'>
                                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2'>
                                    <label htmlFor="cin" className='f-badge-title'>Company identification number (CIN)</label>
                                    <input className="form-control form-control-sm mt-1" type="text" id='cin' value={datas.cin} onChange={(e) => {
                                        setDatas({ ...datas, cin: e.target.value })
                                    }} />
                                </div>
                                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2'>
                                    <label htmlFor="cin" className='f-badge-title'>Business PAN (Permanent Account Number)</label>
                                    <input className="form-control form-control-sm mt-1" type="text" id='cin' value={datas.pan} onChange={(e) => {
                                        setDatas({ ...datas, pan: e.target.value })
                                    }} />
                                </div>
                                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2'>
                                    <label htmlFor="gst" className='f-badge-title'>Goods & Services Tax (GST) Identification number</label>
                                    <input className="form-control form-control-sm mt-1" type="text" id='gst' value={datas.gst} onChange={(e) => {
                                        setDatas({ ...datas, gst: e.target.value })
                                    }} />
                                </div>

                                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2'>
                                    <label htmlFor="tan" className='f-badge-title'>Tax deduction account number (TAN)</label>
                                    <input className="form-control form-control-sm mt-1" type="text" id='tan' value={datas.tan} onChange={(e) => {
                                        setDatas({ ...datas, tan: e.target.value })
                                    }} />
                                </div>

                                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2'>
                                    <label htmlFor="tan" className='f-badge-title'>Base Currency</label>
                                    <select className="form-select form-select-sm mt-1"
                                        value={datas.currency}
                                        onChange={(e) => {
                                            setDatas({ ...datas, currency: e.target.value })
                                        }}
                                    >
                                        {allCurrency && allCurrency.map((data, index) => {
                                            return <option key={index} value={data.currencyID}> {data.currencyName} </option>
                                        })}
                                    </select>
                                </div>
                            </div>

                            <div className='text-end'>
                                <button className='btn btn-primary mt-4 mb-5' onClick={() => {
                                    setLoader(true);
                                    ItrApiService.POST({ data: datas, url: 'RevOps/BillerInformation' }).then(res => {
                                        if (res.Success == true) {
                                            if (res.result.isSuccess == true) {
                                                NotificationManager.success('Updated!');
                                                setLoader(false);
                                            }
                                            else {
                                                setLoader(false);
                                                NotificationManager.error(res.result.message);
                                            }
                                        }
                                        else {
                                            setLoader(false);
                                            NotificationManager.error(res.message);
                                        }
                                    })
                                }}>Save</button>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    );
}

export default BillerInformation;