import React, { useState, useEffect } from 'react'
import { Tabs, Switch, Rate, Modal, PageHeader, Tag, Button, Tooltip, Alert, Space, message } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode, faDollarSign, faInfo, faInfoCircle, faTicketAlt } from '@fortawesome/free-solid-svg-icons';
import { ItrApiService, ItrAuthService } from '@afiplfeed/itr-ui';
import Pricing from './Pricing';
import { NxGridList, NxLoader } from '@afiplfeed/itr-app';

export default function SubModal({ appModaldetails, status, code, name, subId, setAppModalDetails, setMoveSubscibeTab, data, moveSubscribeTab, setNoOfUnit, noOfUnit, setConfirmSubscribe, confirmSubscribe, closeFunc }) {
    const { TabPane } = Tabs;
    var saveSubscribe = {
        entitySubscriptionID: subId,
        entityCode: "",
        subscriptionCode: "",
        serviceCode: code,
        serviceName: name,
        subscribedDate: new Date(),
        unSubscribedDate: null,
        totalUnits: parseInt(noOfUnit),
        totalSubscribedUnits: 0,
        totalAssignedUnits: 0,
        isActive: true
    }

    var unSubscribe = {
        // clientSubscriptionID: subscriptionId,
        // clientID: clientId
        entitySubscriptionID: subId,
        entityCode: "",
        subscriptionCode: "",
        serviceCode: code,
        serviceName: name,
        subscribedDate: new Date(),
        unSubscribedDate: null,
        totalUnits: 0,
        totalSubscribedUnits: 0,
        totalAssignedUnits: 0,
        isActive: true
    }


    const [priceDetails, setPricingDetails] = useState({});

    const [loading, setLoading] = useState(false);


    const getData = async () => {
        let result = await ItrApiService.GET({
            url: `RevOps/StoreConnect/StoreConnectByServiceCode/${code}`
        });
        if (result.Success == true) {
            if (result.result.isSuccess == true) {
                let data = result.result.data;
                if (!data || data.storeConnectPricings == null || data.storeConnectPricings.length == 0) {
                    // alert(result.result.message);
                }
                else {
                    setPricingDetails(data.storeConnectPricings);
                }
            }
            else if (result.result.isSuccess == false) {
            }
        }
    }

    const [subscriptionData, setSubscriptionData] = useState({})

    const getData2 = async () => {
        let result = await ItrApiService.GET({
            url: 'RevOps/EntitySubscription/AllSubscriptionLicense'
        });
        if (result.result.isSuccess == true) {
            let dataVal = result.result.data;
            if (dataVal === null || !dataVal.length) {
                setSubscriptionData({});
            }
            else {
                let index = dataVal.findIndex(o => o.ServiceCode == code);
                if (index >= 0) setSubscriptionData(dataVal[index]);
                else setSubscriptionData({})
            }
        }
        else if (result.result.isSuccess == false) {
            setSubscriptionData({});
        }
    }


    const [userInfo, setUserInfo] = useState({
        currency: ""
    })

    const getUserDetails = async () => {
        let result = await ItrAuthService.GetUserInfo();
        if (result.Success == true) {
            let getUserDetails = result.result;
            setUserInfo(getUserDetails);
        }
        else if (result.Success == false) {
            alert(result.message);
        }
    }

    const [subscriptionValue, setSubscriptionValue] = useState();


    useEffect(() => {
        getData();
        getData2();
        getUserDetails();
    }, [code]);

    let rupeeIndian = Intl.NumberFormat("en-IN", {
        // style: "currency",
        currency: "INR",
    });



    return (
        <div>
            <NxLoader state={loading} />
            <Modal footer={[
                <div>
                    <Button type="ghost" onClick={() => {
                        setMoveSubscibeTab('1')
                        setAppModalDetails(false);
                        setNoOfUnit('');
                        setConfirmSubscribe(false);
                    }
                    }>Close</Button>
                </div>
            ]} visible={appModaldetails} width={1000} bodyStyle={{ height: '500px' }} centered={true} onCancel={() => {
                setMoveSubscibeTab('1')
                setAppModalDetails(false)
            }}>
                <PageHeader
                    // onBack={() => setAppModalDetails(false)}
                    title={data.serviceName}
                    extra={[
                        <button hidden={moveSubscribeTab == '4' ? true : false} className='btn btn-sm btn-outline-success shadow-none' style={{ fontSize: '12px', borderRadius: '0' }} onClick={() => setMoveSubscibeTab('4')}> Subscribe Now </button>
                    ]}
                    tags={<Tag color="blue"> {status == '' ? 'Available' : status == 'S' ? 'Subscribed' : status == 'U' ? 'Unsubscribed' : ''} </Tag>}
                // subTitle="Productivity"
                >
                    <div className=''>
                        <p className='my-0'> <span>App Category :</span> {data.serviceCategory} </p>
                        <p className='my-0'><span>Publisher :</span> {data.developerName} </p>
                        <p className='my-0'><span>Currency :</span> {userInfo.currency ? userInfo.currency : ''} </p>
                    </div>
                    {/* <Row>
                        <Statistic title="Status" value="Pending" />
                        <Statistic
                            title="Price"
                            prefix="$"
                            value={568.08}
                            style={{
                                margin: '0 32px',
                            }}
                        />
                        <Statistic title="Balance" prefix="$" value={3345.08} />
                    </Row> */}
                    <div className='mt-3'>
                        <Tabs activeKey={moveSubscribeTab} onTabClick={(e) => setMoveSubscibeTab(e.toString())}>
                            <TabPane key="1" tab={<div> <FontAwesomeIcon icon={faInfo} /> &nbsp; App Info </div>}>
                                <div className='row'>
                                    <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                                        <label className='fw-bold'>App Icon </label><br />
                                        <img className='img-thumbnail' src={data.appIcon} width="120" />
                                    </div>

                                    <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                                        <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2'>
                                            <label className='fw-bold'>App Name :</label> &nbsp; <span> {data.serviceName} </span>
                                        </div>

                                        <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2'>
                                            <label className='fw-bold'>App Type :</label> &nbsp; <span> {data.serviceType} </span>
                                        </div>

                                        <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2'>
                                            <label className='fw-bold'>Version :</label> &nbsp; <span> {data.version} </span>
                                        </div>

                                        <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2'>
                                            <label className='fw-bold'>Description :</label> &nbsp; <span> {data.serviceDescription} </span>
                                        </div>
                                    </div>
                                </div>
                            </TabPane>
                            <TabPane key="3" tab={<div> <FontAwesomeIcon icon={faDollarSign} /> &nbsp; Pricing </div>}>
                                <div className='row'>
                                    <Pricing code={code} />
                                    {/* {priceDetails.storeConnectPricings,map((data,index) => {
                                    return <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                                        <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2'>
                                            <label className='fw-bold'>Amount :</label> <span> {data.amount} </span>
                                        </div>

                                        <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2'>
                                            <label className='fw-bold'>From :</label> <span> {data.from} </span>
                                        </div>

                                        <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2'>
                                            <label className='fw-bold'>To :</label> <span> {data.to} </span>
                                        </div>

                                        <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2'>
                                            <label className='fw-bold'>pricingModule :</label> <span> {data.pricingModule} </span>
                                        </div>
                                    </div>})} */}
                                </div>
                            </TabPane>
                            <TabPane key="4" tab={<div> <FontAwesomeIcon icon={faTicketAlt} /> &nbsp; Subscribe </div>}>
                                <div className='subscribe-fields'>
                                    <div className="nx-card-subscription-button">
                                        {status == 'S' ? <>
                                            <div>
                                                <NxGridList
                                                    header={[
                                                        {
                                                            name: 'Total License',
                                                        },
                                                        {
                                                            name: 'Assigned',
                                                        },
                                                        {
                                                            name: 'Unassigned',
                                                            // sort: true,
                                                        },
                                                    ]}
                                                    enableSearch={true}
                                                    autoSearch={true}
                                                    enableAutoPagination={true}
                                                    showShowing={true}
                                                    rowClick={(e) => {

                                                    }}
                                                    data={[{ sendItem: '1', data: [subscriptionData.TotalSubscribed, subscriptionData.TotalAssigned, subscriptionData.Remaining] }]}
                                                />
                                            </div>
                                            <button style={{ fontSize: '12px', borderRadius: '0' }} className="btn btn-primary btn-sm shadow-none mt-2"
                                                onClick={() => {
                                                    setLoading(true);
                                                    ItrApiService.POST({
                                                        url: 'RevOps/EntitySubscription/CancelSubscription',
                                                        data: unSubscribe
                                                    }).then((res) => {
                                                        if (res.Success == true) {
                                                            if (res.result.isSuccess == true) {
                                                                message.success('Successfully Updated');
                                                                setLoading(false);
                                                                closeFunc();
                                                                setAppModalDetails(false)
                                                            }
                                                        }
                                                    })
                                                }}
                                            >Unsubscribe</button>
                                            <button style={{ fontSize: '12px', borderRadius: '0' }} className="btn btn-primary btn-sm shadow-none mx-2 mt-2">
                                                License Details
                                            </button>
                                        </> : status == 'U' ?
                                            <>
                                                <div className="row p-3 justify-content-start align-items-start">
                                                    <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                                                        <div className='row align-items-start'>
                                                            <label>Pls enter the number of licenses you would like to subscribe</label>
                                                            <div className='col-12 col-sm-12 col-md-6 col-lg-8 col-xl-8 mt-2'>
                                                                <input style={{ fontSize: '12px' }} className="form-control form-control-sm" placeholder="" value={noOfUnit}
                                                                    // onChange={(e) => {
                                                                    // setNoOfUnit(e.target.value)
                                                                    onChange={(e) => {
                                                                        setNoOfUnit(e.target.value);
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-2'>
                                                                <button style={{ fontSize: '12px', borderRadius: '0' }} className="btn btn-primary btn-sm shadow-none w-100"
                                                                    onClick={() => {
                                                                        setConfirmSubscribe(true);
                                                                        ItrApiService.POST({
                                                                            url: 'RevOps/StoreConnect/GetEstimatePrice',
                                                                            data: {
                                                                                serviceCode: data.serviceCode,
                                                                                noofUnit: noOfUnit,
                                                                            }
                                                                        }).then(res => {
                                                                            if (res.Success == true) {
                                                                                if (res.result.isSuccess == true) {
                                                                                    setLoader(false);
                                                                                    setSubscriptionValue(res.result.data.totalPrice)
                                                                                    //setConfirmSubscribe(true);
                                                                                }
                                                                                else {
                                                                                    message.error(res.result.message);
                                                                                    setLoader(false);
                                                                                }
                                                                            }
                                                                            else {
                                                                                setLoader(false);
                                                                            }
                                                                            setConfirmSubscribe(true);
                                                                        })
                                                                    }}
                                                                >Subscribe</button>
                                                            </div>
                                                        </div>

                                                        <div className='row mt-3'>
                                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" hidden={confirmSubscribe == false ? true : false}>
                                                                <Alert
                                                                    style={{ height: '130px' }}
                                                                    message="Subscription Confirmation"
                                                                    // description={`Your monthly estimated bill : Rs ${priceDetails.length > 0 ? parseInt(noOfUnit) * parseInt(priceDetails[0].amount) : 0} /-`}
                                                                    description={`Your monthly estimated bill : Rs ${subscriptionValue ? rupeeIndian.format(subscriptionValue) : ''} `}
                                                                    type="info"
                                                                    showIcon
                                                                    // banner={true}
                                                                    action={
                                                                        <>
                                                                            <div style={{ position: 'absolute', bottom: '10px', right: '20px' }}>
                                                                                <Space direction="horizontal">
                                                                                    <Button size="small" type="primary"
                                                                                        onClick={() => {
                                                                                            setLoading(true);
                                                                                            ItrApiService.POST({
                                                                                                url: 'RevOps/EntitySubscription/SaveSubscription',
                                                                                                data: saveSubscribe
                                                                                            }).then((res) => {
                                                                                                if (res.Success == true) {
                                                                                                    if (res.result.isSuccess == true) {
                                                                                                        message.success('Successfully Updated');
                                                                                                        closeFunc();
                                                                                                        setLoading(false);
                                                                                                        setAppModalDetails(false)
                                                                                                    }
                                                                                                }
                                                                                            })
                                                                                        }}
                                                                                    >
                                                                                        Confirm
                                                                                    </Button>
                                                                                    <Button size="small" danger type="ghost" onClick={() => setConfirmSubscribe(false)}>
                                                                                        Cancel
                                                                                    </Button>
                                                                                </Space>
                                                                            </div>
                                                                        </>
                                                                    }
                                                                    closable
                                                                >
                                                                </Alert>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                                                        <p className='mt-0 fw-bold text-primary'>Subscription Information</p>
                                                        {/* <p>Monthly estimated bill value INR_____</p> */}
                                                        <p> <span className='text-danger'></span> The comprehensive billing for this will commence from the next billing cycle.<br /> Prorated bill will be generated for the current billing period.</p>

                                                        <p style={{ cursor: 'pointer' }} className='w-50'>
                                                            <Tooltip overlayInnerStyle={{ color: 'black' }} color="#ffefbd" title="Subscription Information Monthly estimated bill value INR_____
                                                                    Note: The comprehensive billing for this will commence from the next billing cycle. Prorated bill will be generated for the current billing period.
                                                                    Billing Policy (I)">
                                                                <span style={{ color: 'black', fontSize: '14px' }}><FontAwesomeIcon icon={faInfoCircle} color='red' /> Billing & Cancellation Policy</span>
                                                            </Tooltip>
                                                        </p>
                                                    </div>
                                                </div>
                                            </> : status == '' ?
                                                <>
                                                    <div className="row p-3 justify-content-start align-items-start">
                                                        <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                                                            <div className='row align-items-start'>
                                                                <label>Pls enter the number of licenses you would like to subscribe</label>
                                                                <div className='col-12 col-sm-12 col-md-6 col-lg-8 col-xl-8 mt-2'>
                                                                    <input style={{ fontSize: '12px' }}
                                                                     className="form-control form-control-sm" 
                                                                     placeholder="Enter the number" value={noOfUnit}
                                                                        onChange={(e) => {
                                                                            setNoOfUnit(e.target.value);
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-2'>
                                                                    <button style={{ fontSize: '12px', borderRadius: '0' }}
                                                                        disabled={noOfUnit == 0 || '' ? true : false}
                                                                        className="btn btn-primary btn-sm shadow-none w-100"
                                                                        onClick={() => {
                                                                            setConfirmSubscribe(true);
                                                                            ItrApiService.POST({
                                                                                url: 'RevOps/StoreConnect/GetEstimatePrice',
                                                                                data: {
                                                                                    serviceCode: data.serviceCode,
                                                                                    noofUnit: noOfUnit,
                                                                                }
                                                                            }).then(res => {
                                                                                if (res.Success == true) {
                                                                                    if (res.result.isSuccess == true) {
                                                                                        setSubscriptionValue(res.result.data.totalPrice);
                                                                                        //setConfirmSubscribe(true);
                                                                                    }
                                                                                    else {
                                                                                        message.error(res.result.message);
                                                                                    }
                                                                                }
                                                                                else {
                                                                                }
                                                                                setConfirmSubscribe(true);
                                                                            })
                                                                        }}
                                                                    >Subscribe</button>
                                                                </div>
                                                            </div>

                                                            <div className='row mt-3'>
                                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" hidden={confirmSubscribe == false ? true : false}>
                                                                    <Alert
                                                                        style={{ height: '130px' }}
                                                                        message="Subscription Confirmation"
                                                                        // description={`Your monthly estimated bill : Rs ${priceDetails.length > 0 ? parseInt(noOfUnit) * parseInt(priceDetails[0].amount) : 0} /-`}
                                                                        description={`Your monthly estimated bill : ${subscriptionValue ? rupeeIndian.format(subscriptionValue) : ''} /- ${userInfo.currency ? userInfo.currency.split("-")[0] : ''}  `}
                                                                        type="info"
                                                                        showIcon
                                                                        // banner={true}
                                                                        action={
                                                                            <>
                                                                                <div style={{ position: 'absolute', bottom: '10px', right: '20px' }}>
                                                                                    <Space direction="horizontal">
                                                                                        <Button size="small" type="primary"
                                                                                            disabled={ loading || noOfUnit == 0 || '' ? true : false}
                                                                                            onClick={() => {
                                                                                                setLoading(true);
                                                                                                ItrApiService.POST({
                                                                                                    url: 'RevOps/EntitySubscription/SaveSubscription',
                                                                                                    data: saveSubscribe
                                                                                                }).then((res) => {
                                                                                                    if (res.Success == true) {
                                                                                                        if (res.result.isSuccess == true) {
                                                                                                            message.success('Successfully Updated');
                                                                                                            setLoading(false);
                                                                                                            closeFunc()
                                                                                                            setAppModalDetails(false);
                                                                                                        }
                                                                                                        else {
                                                                                                            message.error(res.result.message);
                                                                                                            setLoading(false);
                                                                                                        }
                                                                                                    }
                                                                                                    else {
                                                                                                        setLoading(false);
                                                                                                    }
                                                                                                })
                                                                                            }}
                                                                                        >
                                                                                            Confirm
                                                                                        </Button>
                                                                                        <Button size="small" danger type="ghost" onClick={() => { setConfirmSubscribe(false); setNoOfUnit(''); }}>
                                                                                            Cancel
                                                                                        </Button>
                                                                                    </Space>
                                                                                </div>
                                                                            </>
                                                                        }
                                                                        closable
                                                                    >
                                                                    </Alert>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                                                            <p className='mt-0 fw-bold text-primary'>Subscription Information</p>
                                                            {/* <p>Monthly estimated bill value INR_____</p> */}
                                                            <p> <span className='text-danger'></span> The comprehensive billing for this will commence from the next billing cycle.<br /> Prorated bill will be generated for the current billing period.</p>

                                                            <p style={{ cursor: 'pointer' }} className='w-50'>
                                                                <Tooltip overlayInnerStyle={{ color: 'black' }} color="#ffefbd" title="Subscription Information Monthly estimated bill value INR_____
                                                                    Note: The comprehensive billing for this will commence from the next billing cycle. Prorated bill will be generated for the current billing period.
                                                                    Billing Policy (I)">
                                                                    <span style={{ color: 'black', fontSize: '14px' }}><FontAwesomeIcon icon={faInfoCircle} color='red' /> Billing & Cancellation Policy</span>
                                                                </Tooltip>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </> : ''
                                        }
                                    </div>
                                </div>
                            </TabPane>
                            <TabPane key="5" tab={<div> <FontAwesomeIcon icon={faCode} /> &nbsp; Developer </div>}>
                                <div className='row'>
                                    <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                                        <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2'>
                                            <label className='fw-bold'>Developer Name :</label> <span> {data.developerName} </span>
                                        </div>

                                        <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2'>
                                            <label className='fw-bold'>Developer Website :</label> <span> {data.developerWebsite} </span>
                                        </div>

                                        <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2'>
                                            <label className='fw-bold'>Country :</label> <span> {data.country} </span>
                                        </div>

                                        <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2'>
                                            <label className='fw-bold'>About Developer :</label> <span> {data.aboutDeveloper} </span>
                                        </div>
                                    </div>
                                </div>
                            </TabPane>
                        </Tabs>
                    </div>
                </PageHeader>
            </Modal>
        </div>
    )
}
