import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import './style.css';
import { useHistory } from 'react-router-dom';
import { ItrApiService } from '@afiplfeed/itr-ui';
import { NxHeader, NxBreadcrumb, NxTitleTab, NxCustomTab, NxLoader, NxFooter } from '@afiplfeed/itr-app';
import Headers from "../../Screens/Headers/Headers";
import Footers from "../../Screens/Footers/Footers";
import { NotificationManager } from "react-notifications";

export default function EditUserEntry({ }) {

    const Buttons = () => {
        return <div className="form-button nx-enquiry-buttons mb-5">
            <button className="btn btn-primary rounded-pill buttons" onClick={() => {
                ItrApiService.POST({
                    url: 'RevOps/Enquiry/SaveEnquiry',
                    data: editEnquiry
                }).then(res => {
                    if (res.Success == true) {
                        if (res.result.isSuccess == true) {
                            history.goBack()
                        }
                    }
                })
            }} > Save</button>
            <button className="btn close btn-outline-primary rounded-pill" onClick={() => history.goBack()}> close</button>
        </div>
    }

    let history = useHistory();
    let location = useLocation();
    const [handel, setHandel] = useState(false);
    const [editEnquiry, setEditEnquiry] = useState('');
    const [loading, setLoading] = useState(false);
    const [edit, setEdit] = useState('');


    // AllUDo`s
    let UdoData = ["Enquiry Status", "Nature Of Business", "Industry", "Ownership", "Source"];
    const [allUdo, setAllUdo] = useState([]);
    const getUdo = async () => {
        let result = await ItrApiService.POST({
            url: 'Platform/UserDefined/AllUDOs',
            data: UdoData
        });
        if (result.result.isSuccess == true) {
            let getCustomerDetails = result.result.data;
            let getOptions = getCustomerDetails[1].Options;
            setAllUdo(getOptions);
        }
        else if (result.result.isSuccess == false) {
            NotificationManager.error(result.result.message);
            // sessionStorage.clear();
            // history.push('/')
        }
    }

    // Partners
    const [allPartners, setAllPartners] = useState([]);
    const getPartners = async () => {
        let result = await ItrApiService.GET({
            url: 'RevOps/PartnerInformation'
        });
        if (result.result.isSuccess == true) {
            let getPartnerDetails = result.result.data;
            setAllPartners(getPartnerDetails);
        }
        else if (result.result.isSuccess == false) {
            NotificationManager.error(result.result.message);
        }
    }

    useEffect(() => {
        setLoading(true);
        setEditEnquiry(location.state.editData);
        getUdo();
        getPartners();
        setLoading(false);
    }, [])

    return (
        <div>
            <NxLoader state={loading} />
            <Headers />
            {/* <NxBreadcrumb className="navigation-breadcrumb" data={[
                {
                    name: 'Home',
                    onClick: () => {
                        history.push('/partner-board')
                    }
                },
                {
                    name: 'Enquiry List',
                    onClick: () => {
                        history.goBack()
                    }
                }
            ]} /> */}
            <NxTitleTab className="title-tab" title='Edit Enquiry' onCloseClick={() => {
                history.goBack();
            }} />
            <NxCustomTab
                data={[
                    {
                        name: "Basic information",
                        Components: () => {
                            return (
                                <div className="container-form">
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <div className="row">
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                    <label htmlFor="firstName">Full Name</label>
                                                    <input className="form-control form-control-sm" id="firstName" type="text" value={editEnquiry.FullName} onChange={(e) => {
                                                        setEditEnquiry({ ...editEnquiry, FullName: e.target.value })
                                                    }} />
                                                </div>

                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                    <label htmlFor="lastName">Company Name</label>
                                                    <input className="form-control form-control-sm" id="lastName" type="text" value={editEnquiry.CompanyName} onChange={(e) => {
                                                        setEditEnquiry({ ...editEnquiry, CompanyName: e.target.value })
                                                    }} />
                                                </div>

                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                    <label htmlFor="Email">Email</label>
                                                    <input className="form-control form-control-sm" id="Email" type="text" value={editEnquiry.Email} onChange={(e) => {
                                                        setEditEnquiry({ ...editEnquiry, Email: e.target.value })
                                                    }} />
                                                </div>

                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                    <label htmlFor="Phone">Phone</label>
                                                    <input className="form-control form-control-sm" id="tel"
                                                        minLength="10" maxLength="15" type="text" value={editEnquiry.Phone} onChange={(e) => {
                                                            setEditEnquiry({ ...editEnquiry, Phone: e.target.value })
                                                        }} />
                                                </div>

                                                {/* <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                    <label htmlFor="aboutus">Where did you hear about us?</label>
                                                    <select class="form-select form-select-sm" id="aboutus">
                                                        <option value="" selected hidden></option>
                                                        <option disabled>--Please-select--</option>
                                                        <option value="Google" >Google</option>
                                                        <option value="Events" >Events</option>
                                                        <option value="Conferance" >Conferance</option>
                                                        <option value="Blog" >Blog</option>
                                                        <option value="Referance" >Referance</option>
                                                    </select>
                                                </div> */}

                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                    <label htmlFor="role">Role</label>
                                                    <select class="form-select form-select-sm" aria-label="Default select example" id="role" value={editEnquiry.Role} onChange={(e) => {
                                                        setEditEnquiry({ ...editEnquiry, Role: e.target.value })
                                                    }} >
                                                        <option value="" selected hidden></option>
                                                        <option disabled>--Please-select--</option>
                                                        <option value="Consultant" >Consultant</option>
                                                        <option value="Influencer" >Influencer</option>
                                                        <option value="Decision maker" >Decision maker</option>
                                                        <option value="Approver" >Approver</option>
                                                        <option value="Sponser" >Sponser</option>
                                                    </select>
                                                </div>

                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                    <label htmlFor="timeToBuy">Time to Buy</label>
                                                    <select class="form-select form-select-sm" id="timeToBuy" value={editEnquiry.TimeToBuy} onChange={(e) => {
                                                        setEditEnquiry({ ...editEnquiry, TimeToBuy: e.target.value })
                                                    }} >
                                                        <option value="" selected hidden></option>
                                                        <option disabled>--Please-select--</option>
                                                        <option value="Immediately" >Immediately</option>
                                                        <option value="Within 30 days" >Within 30 days</option>
                                                        <option value="Within 60 days" >Within 60 days</option>
                                                        <option value="Within 90 days" >Within 90 days</option>
                                                        <option value="Just enquiring" >Just Enquiring</option>
                                                    </select>
                                                </div>

                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                    <label htmlFor="firstName">Source</label>
                                                    <select class="form-select form-select-sm" aria-label="Default select example" value={editEnquiry.SourcedBy} onChange={(e) => {
                                                        setEditEnquiry({ ...editEnquiry, SourcedBy: e.target.value })
                                                    }} >
                                                        <option value="" selected hidden></option>
                                                        <option disabled>--Please-select--</option>
                                                        <option value="Advertisement" >Advertisement</option>
                                                        <option value="Cold Call" >Cold Call</option>
                                                        <option value="Employee Referal" >Employee Referal</option>
                                                        <option value="External Referal" >External Referal</option>
                                                        <option value="Online" >Online</option>
                                                        <option value="Partner" >Partner</option>
                                                        <option value="Public Relations" >Public Relations</option>
                                                        <option value="Sales Mail" >Sales Mail</option>
                                                        <option value="Seminar Partner" >Seminar Partner</option>
                                                        <option value="Seminar Internal" >Seminar Internal</option>
                                                        <option value="Trade Show" >Trade Show</option>
                                                        <option value="Database" >Database</option>
                                                    </select>
                                                </div>

                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                    <label htmlFor="firstName">Status</label>
                                                    <select class="form-select form-select-sm" aria-label="Default select example" value={editEnquiry.Status} onChange={(e) => {
                                                        setEditEnquiry({ ...editEnquiry, Status: e.target.value })
                                                    }} >
                                                        <option disabled>--Please-select--</option>
                                                        {allUdo && allUdo.map((data, index) => {
                                                            return <option key={index} hidden={data.UDOptionName == 'Acquired'} value={data.UDOptionID}> {data.UDOptionName} </option>
                                                        })}
                                                    </select>
                                                </div>

                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2" hidden={editEnquiry.SourcedBy == 'Partner' ? false : true}>
                                                    <label htmlFor="partner">Partner</label>
                                                    <select class="form-select form-select-sm" aria-label="Default select example" value={editEnquiry.PartnerID}
                                                        onChange={(e) => setEditEnquiry({ ...editEnquiry, PartnerID: e.target.value })}>
                                                        <option value="" selected hidden></option>
                                                        <option disabled>--Please-select--</option>
                                                        {allPartners && allPartners.map((data, index) => {
                                                            return <option key={index} value={data.partnerInformationID}> {data.partnerName} </option>
                                                        })}
                                                    </select>
                                                </div>

                                                <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                                    <label htmlFor="feedback">Feedback</label>
                                                    <textarea className="form-control" id="feedback" value={editEnquiry.Feedback} onChange={(e) => {
                                                        setEditEnquiry({ ...editEnquiry, Feedback: e.target.value })
                                                    }} />
                                                </div>
                                                <div>
                                                </div>

                                                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />

                                    <Buttons />
                                </div>
                            )
                        }
                    },
                ]}
            />
            <NxFooter ExtraContent={[
                { name: 'Terms of Service' },
                { name: 'privacy' },
            ]} />
        </div>
    )
}