import React, { useState, useEffect } from 'react';
import NxBarCharts from '../../../components/nxCharts/nxBarCharts';
import { ItrApiService } from '@afiplfeed/itr-ui';
import { NxLoader } from '@afiplfeed/itr-app';
import { NotificationManager } from "react-notifications";

export default function SupportTab() {

    const [customers, setCustomers] = useState([]);
    const [loading, setLoading] = useState(false);

    const getData = async (tenetcode) => {
        setLoading(true);
        let result = await ItrApiService.GET({
            url: 'TenantPlatform/Entity/GetEntities',
        });
        if (result.result.isSuccess == true) {
            setLoading(false);
            let getCustomerDetails = result.result.data;
            if (getCustomerDetails === null || !getCustomerDetails.length) {
                // NotificationManager.error(result.result.message);.
            }
            else {
                setCustomers(getCustomerDetails);
            }
        }
        else if (result.result.isSuccess == false) {
            setLoading(false);
            NotificationManager.error(result.result.message);
            // sessionStorage.clear();
            // history.push('/')
        }
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <div className="nx-support">
            <NxLoader state={loading} />
            <div className="row">
                <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-2 ">
                    <label>Customers</label>
                    <select className="form-select-sm form-select mt-1">
                        {customers.map((data, index) => {
                            return <option key={index} value={data.entityCode}> {data.entityName} </option>
                        })}
                    </select>
                </div>
            </div>

            <div className="nx-support-body">
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 mt-5 counts count-one">
                        {/* <label className="nx-badge-title">CURRENT</label> */}
                        <div className="row">
                            <div className="col-12 col-sm-4 col-md-4 col-lg-4 mt-4 text-center">
                                <div className="card">
                                    <div className="card-body">
                                        <label>New Cases</label>
                                        <h5>24</h5>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-sm-4 col-md-4 col-lg-4 mt-4 text-center">
                                <div className="card">
                                    <div className="card-body">
                                        <label>Working Cases</label>
                                        <h5>24</h5>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-sm-4 col-md-4 col-lg-4 mt-4 text-center">
                                <div className="card">
                                    <div className="card-body">
                                        <label>Waiting on Customer Cases</label>
                                        <h5>24</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 mt-3 counts count-one text-center">
                        <label className="nx-support-counts-title">Performance Metrics</label>
                        <div className="row mt-3">
                            <div className="col-12 col-sm-4 col-md-4 col-lg-4 mt-2 text-center">
                                <div className="card">
                                    <div className="card-body">
                                        <label>Closed Cases</label>
                                        <h5>24</h5>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-sm-4 col-md-4 col-lg-4 mt-2 text-center">
                                <div className="card">
                                    <div className="card-body">
                                        <label>Avg. CSAT</label>
                                        <h5>24</h5>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-sm-4 col-md-4 col-lg-4 mt-2 text-center">
                                <div className="card">
                                    <div className="card-body">
                                        <label>Avg. Response Time (mins)</label>
                                        <h5>24</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="nx-support-cases mt-5">
                    <h5> Cases by Priority </h5>
                    <div class="card">
                        <div class="card-body">
                            <div className="nx-progress mt-4">
                                <div class="progress">
                                    <div class="progress-bar" role="progressbar" style={{ width: "20%", background: '#d36a67' }} aria-valuenow="15" aria-valuemin="0" aria-valuemax="100">
                                        Critical6 <br />
                                        Cases
                                    </div>
                                    <div class="progress-bar" role="progressbar" style={{ width: "60%", background: '#e6994c' }} aria-valuenow="15" aria-valuemin="0" aria-valuemax="100">
                                        High <br />
                                        6 Cases
                                    </div>
                                    <div class="progress-bar" role="progressbar" style={{ width: "10%", background: '#e8cd67' }} aria-valuenow="15" aria-valuemin="0" aria-valuemax="100">
                                        Medium <br />
                                        6 Cases
                                    </div>
                                    <div class="progress-bar" role="progressbar" style={{ width: "20%", background: '#73a562' }} aria-valuenow="30" aria-valuemin="0" aria-valuemax="100">
                                        Low <br />
                                        6 Cases
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="nx-support-chart mt-3 mb-0">
                    <div className="row nx-support-case">
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-3">
                            <h5> Number of Opened Cases by Month </h5>
                            <div className="card">
                                <div className="card-body">
                                    <NxBarCharts height="300" width="200" />
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-3 ">
                            <h5> Average Hourly Case Duration by Week </h5>
                            <div className="card">
                                <div className="card-body">
                                    <div class="table-wrapper-scroll-y table-responsive my-custom-scrollbar">
                                        <table class="table table-bordered mb-0">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Apps</th>
                                                    <th scope="col"></th>
                                                    <th scope="col">Open Cases</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th scope="row">Compute</th>
                                                    <td>
                                                        <div class="progress table-progress">
                                                            <div class="progress-bar" role="progressbar" style={{ width: "70%", height: '100%', background: '#d36a67' }} aria-valuenow="15" aria-valuemin="0" aria-valuemax="100">70</div>
                                                        </div>
                                                    </td>
                                                    <td>10</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Compute</th>
                                                    <td>
                                                        <div class="progress table-progress">
                                                            <div class="progress-bar" role="progressbar" style={{ width: "60%", background: '#d36a67' }} aria-valuenow="15" aria-valuemin="0" aria-valuemax="100">60</div>
                                                        </div>
                                                    </td>
                                                    <td>10</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

                <div className="nx-case-details mt-4 mb-5">
                    <h5> Case Details </h5>
                    <div className="card">
                        <div className="card-body">
                            <div class="table-wrapper-scroll-y table-responsive my-custom-scrollbar">
                                <table class="table table-bordered mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col">Subject</th>
                                            <th scope="col">Created</th>
                                            <th scope="col">Priority <span className="text-end"></span></th>
                                            <th scope="col">Support Type</th>
                                            <th scope="col">Assignee</th>
                                            <th scope="col">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
