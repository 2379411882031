import React, { useEffect, useState } from "react";
import './CloudConnectEdit.css';
import { useHistory } from 'react-router-dom';
import { NxLoader, NxTitleTab } from '@afiplfeed/itr-app';
import { ItrApiService, ItrAuthService } from '@afiplfeed/itr-ui';
import Headers from "../../Screens/Headers/Headers";
import { NotificationManager } from "react-notifications";
import { useLocation } from "react-router-dom";


export default function CloudConnectEdit() {
    const Buttons = () => {
        return <div className="form-button nx-enquiry-buttons mt-4 mb-4">
            <button className="btn btn-sm btn-primary buttons" onClick={async () => {
                let pin = await loginUser();
                let aP = 0
                if (pin.pin != '') {
                    aP = parseInt(pin.pin)
                }
                ItrApiService.POST({
                    data: { ...data, adminPIN: aP },
                    url: 'AzureConnect/TestConnection'
                }).then(res => {
                })
            }}> Test Connection</button>
            <button className="btn btn-sm btn-primary buttons" onClick={async () => {
                let pin = await loginUser();
                let aP = 0
                if (pin.pin != '') {
                    aP = parseInt(pin.pin)
                }
                ItrApiService.POST({
                    data: { ...data, adminPIN: aP },
                    url: 'CloudConnect/Configuration/AzureSubscription'
                }).then(res => {
                    if (res.Success == true) {
                        if (res.result.isSuccess == true) {
                            history.goBack()
                        } else {
                            NotificationManager.error(res.result.message);
                        }
                    } else {
                        NotificationManager.error(res.message);
                    }
                })
            }} > Save</button>
            <button className="btn btn-sm close btn-outline-primary" onClick={() => history.goBack()}> Close</button>
        </div>
    }

    var loginUser = () => {
        return {
            pin: document.getElementById('adminPin').value
        }
    }

    const [data, setData] = useState({
        azureSubscriptionID: 0,
        clientID: "",
        clientSecret: "",
        adminPIN: 0,
        tenantID: "",
        azureID: "",
        name: "",
        customerCode: '',
        // customerID: 0,
        workSpaces: []
    })

    const location = useLocation()
    useEffect(() => {
        if (!location.state || !location.state.azureSubscriptionID) {
            history.replace('/docker-connect')
        } else {
            setData({ ...data, ...location.state });
        }
    }, []);

    const blockChar = () => {
        $("input[name='numonly']").on('input', function (e) {
            $(this).val($(this).val().replace(/[^0-9]/g, ''));
        });
    }

    const getUser = async () => {
        let result = await ItrAuthService.GetUserInfo();
        if (result.Success == true) {
            // setData({ ...data, customerID: result.customerCode });
        } else {
            NotificationManager.error(result.message);
        }
    }

    useEffect(() => {
        getUser()
    }, [])


    let history = useHistory();
    const [loading, setLoading] = useState(false)
    return (
        <div>
            <NxLoader state={loading} />
            <Headers />
            <div className="customer-dynamic-pages pt-4 ">
                <NxTitleTab className="title-tab page-title" title='Edit Azure Connect' onCloseClick={() => {
                    history.goBack();
                }} />
                <div className="resource-groups-add-main-form mt-2">
                    <div className="card resource-groups-add-card-form">
                        <div className="card-body">
                            <div className="container-form">
                                <div className="row">
                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                        <div className="row">

                                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                <label htmlFor="name" className='f-badge-title'>Subscription Name</label>
                                                <input className="form-control form-control-sm" id="name" type="text" value={data.name} onChange={(e) => {
                                                    setData({ ...data, name: e.target.value })
                                                }} />
                                            </div>

                                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                <label htmlFor="adminPin" className='f-badge-title'>Admin Pin</label>
                                                <input className="form-control form-control-sm" id="adminPin" name="numonly" type="password" onKeyPress={blockChar} value={data.adminPIN} onChange={(e) => {
                                                    setData({ ...data, adminPIN: e.target.value })
                                                }} maxLength={6} />
                                            </div>

                                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                <label htmlFor="SubscriptionId" className='f-badge-title'>Subscription ID</label>
                                                <input className="form-control form-control-sm" id="SubscriptionId" type="text" value={data.azureID} onChange={(e) => {
                                                    setData({ ...data, azureID: e.target.value })
                                                }} />
                                            </div>

                                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                <label htmlFor="ClientId" className='f-badge-title'>Client ID</label>
                                                <input className="form-control form-control-sm" id="ClientId" type="text" value={data.clientID} onChange={(e) => {
                                                    setData({ ...data, clientID: e.target.value })
                                                }} />
                                            </div>

                                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                <label htmlFor="clientSecret" className='f-badge-title'>Client Secret</label>
                                                <input className="form-control form-control-sm" id="clientSecret" type='password' value={data.clientSecret} onChange={(e) => {
                                                    setData({ ...data, clientSecret: e.target.value })
                                                }} />
                                            </div>

                                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                <label htmlFor="TenantId" className='f-badge-title'>Tenant ID</label>
                                                <input className="form-control form-control-sm" id="TenantId" type="text" value={data.tenantID} onChange={(e) => {
                                                    setData({ ...data, tenantID: e.target.value })
                                                }} />
                                            </div>


                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2"></div>
                                    <hr />
                                    <Buttons />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}