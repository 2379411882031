import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import './style.css';
import { Switch, Steps, Button, Upload, DatePicker, message } from 'antd';
import { NxHeader, NxBreadcrumb, NxTitleTab, NxCustomTab, NxFooter, NxLoader } from '@afiplfeed/itr-app';
import { ItrApiService } from '@afiplfeed/itr-ui'
import Footers from "../../Screens/Footers/Footers";
import Headers from "../../Screens/Headers/Headers";
import { Countries } from "../../general/general";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { NotificationManager } from "react-notifications";



export default function EditEnrollmentEntry() {

    const Buttons = () => {
        return <div className="form-button nx-enquiry-buttons mb-5">
            <button className="btn btn-primary rounded-pill buttons" onClick={() => createRole()}> Save</button>
            <button className="btn btn-primary rounded-pill"> Review</button>
            <button className="btn close btn-outline-primary rounded-pill"> Publish</button>
        </div>
    }

    const location = useLocation();

    const [roles, setRoles] = useState({
        // general
        serviceCode: '',
        serviceName: '',
        serviceType: '',
        commercialOptions: '',
        serviceDescription: '',
        enrollmentCategory: '',
        overView: '',
        publisher: '',
        aboutPublisher: '',
        publisherURL: '',
        // code-connect
        versionNo: '',
        setFrequency: '',
        sourceLocation: '',
        sourceFileName: '',
        sourceFileContent: '',
        repositoryType: '',
        gitHubPath: '',
        autoDeployment: '',
        repoUserName: '',
        repoPassword: '',
        repoRemember: '',
        apiBuildFilePath: '',
        uiBuildFilePath: '',
        customtime: '',
        // roles
        rollCode: '',
        rollName: '',
        description: '',
        additionalInfo: '',
        enrollmentRoles: [],
        // pricing
        enrollmentID: '',
        priceOption: '',
        unit: '',
        min: '',
        max: '',
        startDate: '',
        endDate: '',
        price: ''
    });

    const { Step } = Steps;
    const [current, setCurrent] = useState(0);

    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };


    var body1 = {
        serviceCode: roles.serviceCode,
        serviceName: roles.serviceName,
        serviceType: roles.serviceType,
        commercialOptions: roles.commercialOptions,
        description: roles.serviceDescription,
        overview: roles.overView,
        enrollmentCategory: roles.enrollmentCategory,
        publisher: roles.publisher,
        publisherURL: roles.publisherURL,
        aboutthePublisher: roles.aboutPublisher,
        versionNo: roles.versionNo,
        sourceLocation: roles.sourceLocation,
        repositoryType: roles.repositoryType,
        repoUserName: roles.repoUserName,
        repoPassword: roles.repoPassword,
        repoRemember: roles.repoRemember,
        setFrequency: roles.setFrequency,
        customtime: roles.customtime,
        enrollmentPricingSlabs: [
            {
                priceOption: roles.priceOption,
                unit: roles.unit,
                min: roles.min,
                max: roles.max,
                price: roles.price,
                startDate: roles.startDate,
                endDate: roles.endDate
            }
        ],
        enrollmentRoles: roles.enrollmentRoles
    }

    const createRole = async (states) => {
        let body = {
            ...datas
        }
        if (states && states == true) {
            body = {
                ...datas, isBuildNow: true
            }
        }
        let arr = [];
        for (let val of spfiles) {
            let obj = {
                enrollmentSplashScreenID: 0,
                enrollmentID: roles.id,
                splashScreen: val.thumbUrl,
                additionalInfo: "",
                isActive: true
            }
            arr = [...arr, obj]
        }
        body = { ...body, enrollmentSplashScreens: arr };
        let result = await ItrApiService.POST({
            url: 'DevOps/Enrollment/UpdateEnrollment',
            data: body
        });

        if (result.Success == true) {
            if (result.result.isSuccess == true) {
                setCurrent(0);
                setdatas({
                    enrollmentID: 0,
                    serviceCode: "",
                    serviceName: "",
                    serviceKey: "",
                    serviceType: "",
                    serviceTypeName: '',
                    serviceTypeValue: '',
                    serviceCategory: "",
                    serviceDescription: "",
                    developerName: "",
                    developerWebsite: "",
                    country: "",
                    aboutDeveloper: "",
                    appIcon: "",
                    version: "",
                    buildNo: "",
                    buildType: "",
                    source: "",
                    repositaryType: "",
                    gitAccessToken: "",
                    uiAccessToken: "",
                    apiAccessToken: "",
                    dbscriptAccessToken: "",
                    apiSourcepath: "",
                    uiSourcePath: "",
                    dbScriptPath: "",
                    autoDeployment: false,
                    setFrequency: "",
                    setTime: "",
                    apiBuildFilePath: '',
                    uiBuildFilePath: '',
                    isPublish: true,
                    expiryDate: "2022-01-05T08:17:44.881Z",
                    databaseServerID: 0,
                    rollCode: "",
                    rollName: "",					
					isBaseAppService: false,
                    enrollmentSplashScreens: [
                        //   {
                        //     enrollmentSplashScreenID: 0,
                        //     enrollmentID: 0,
                        //     splashScreen: "",
                        //     additionalInfo: "",
                        //     isActive: true
                        //   }
                    ],
                    enrollmentRoles: []
                });
                NotificationManager.success('Successfully Updated');
                history.push('/apps-store');
                let getCustomerDetails = result.result.data;
            } else {
                NotificationManager.error(result.result.message);
            }
        } else {
            NotificationManager.error(result.message);
        }
    }


    const [codeUpload, setCodeUpload] = useState();
    const [repositary, setRepositary] = useState();
    const [deployement, setDeployment] = useState();

    let history = useHistory();
    const [handel, setHandel] = useState(false);


    const [checkFields, setCheckFields] = useState(true);
    const checKValid = () => {
        if (datas.version == '' || datas.buildNo == '' || datas.buildType == '' || datas.source == '') {
            setCheckFields(true);
        } else if (datas.serviceTypeName == 'API' && datas.source == 'Code Upload') {
            if (datas.apiSourcepath == '' || datas.apiBuildFilePath == '') {
                setCheckFields(true);
            } else {
                setCheckFields(false);
            }
        } else if (datas.serviceTypeName == 'APP' && datas.source == 'Code Upload') {
            if (datas.apiSourcepath == '' || datas.apiBuildFilePath == '' || datas.uiSourcePath == '' || datas.uiBuildFilePath == '' || datas.dbScriptPath == '') {
                setCheckFields(true);
            } else {
                setCheckFields(false);
            }
        } else if (datas.serviceTypeName == 'Service' && datas.source == 'Code Upload') {
            if (datas.uiSourcePath == '') {
                setCheckFields(true);
            } else {
                setCheckFields(false);
            }
        } else if ((datas.serviceTypeName == 'API' || datas.serviceTypeName == 'APP' || datas.serviceTypeName == 'Service') && datas.source == 'Git Connect') {
            if (datas.repositaryType == '') {
                setCheckFields(true);
            } else {
                if (datas.repositaryType == 'Single' && datas.serviceTypeName == 'API') {
                    if (datas.apiAccessToken == '' || datas.apiSourcepath == '' || datas.apiBuildFilePath == '') {
                        setCheckFields(true);
                    }
                    else {
                        setCheckFields(false);
                    }
                } else if (datas.repositaryType == 'Multiple' && datas.serviceTypeName == 'API') {
                    if (datas.apiAccessToken == '' || datas.apiSourcepath == '' || datas.apiBuildFilePath == '') {
                        setCheckFields(true);
                    }
                    else {
                        setCheckFields(false);
                    }
                } else if (datas.repositaryType == 'Single' && datas.serviceTypeName == 'APP') {
                    if (datas.gitAccessToken == '' || datas.apiSourcepath == '' || datas.apiBuildFilePath == '' || datas.uiSourcePath == '' || datas.uiBuildFilePath == '' || datas.databaseServerID == '' || datas.dbScriptPath == '') {
                        setCheckFields(true);
                    }
                    else {
                        setCheckFields(false);
                    }
                } else if (datas.repositaryType == 'Multiple' && datas.serviceTypeName == 'APP') {
                    if (datas.gitAccessToken == '' || datas.apiSourcepath == '' || datas.apiBuildFilePath == '' || datas.uiSourcePath == '' || datas.uiBuildFilePath == '' || datas.uiAccessToken == '' || datas.databaseServerID == '' || datas.dbScriptPath == '' || datas.dbscriptAccessToken == '') {
                        setCheckFields(true);
                    }
                    else {
                        setCheckFields(false);
                    }
                }
                else if (datas.repositaryType == 'Single' && datas.serviceTypeName == 'Service') {
                    if (datas.uiAccessToken == '' || datas.uiSourcePath == '' || datas.uiBuildFilePath == '') {
                        setCheckFields(true);
                    }
                    else {
                        setCheckFields(false);
                    }
                } else if (datas.repositaryType == 'Multiple' && datas.serviceTypeName == 'Service') {
                    if (datas.uiAccessToken == '' || datas.uiSourcePath == '' || datas.uiBuildFilePath == '') {
                        setCheckFields(true);
                    }
                    else {
                        setCheckFields(false);
                    }
                } else {
                    setCheckFields(false)
                }
            }
        } else {
            setCheckFields(false);
        }
    }

    const [datas, setdatas] = useState({
        enrollmentID: 0,
        serviceCode: "",
        serviceName: "",
        serviceKey: "",
        serviceType: "",
        serviceTypeName: '',
        serviceTypeValue: '',
        serviceCategory: "",
        serviceDescription: "",
        developerName: "",
        developerWebsite: "",
        country: "",
        aboutDeveloper: "",
        appIcon: "",
        version: "",
        buildNo: "",
        buildType: "",
        source: "",
        repositaryType: "",
        gitAccessToken: "",
        uiAccessToken: "",
        apiAccessToken: "",
        dbscriptAccessToken: "",
        apiSourcepath: "",
        apiBuildFilePath: '',
        uiBuildFilePath: '',
        uiSourcePath: "",
        dbScriptPath: "",
        autoDeployment: true,
        setFrequency: "",
        setTime: "",
        isPublish: true,
        expiryDate: "2022-01-05T08:17:44.881Z",
        databaseServerID: 0,
        isBuildNow: false,
        isForcetoUpdate: false,
        enrollmentSplashScreens: [
            //   {
            //     enrollmentSplashScreenID: 0,
            //     enrollmentID: 0,
            //     splashScreen: "",
            //     additionalInfo: "",
            //     isActive: true
            //   }
        ],
        enrollmentRoles: []
    });
    useEffect(() => {
        checKValid()
    }, [datas])

    const titles = [
        { title: "App Details" },
        { title: "Code Connect" },
        { title: "Role" },
        // { title: "Pricing & Availability" },
        { title: "Publish" }
    ]

    const [appTypes, setAppTypes] = useState([]);
    const [commercialOption, setCommercialOption] = useState([]);
    const [appCategory, setAppCategory] = useState([]);
    const [appPricing, setAppPricing] = useState([]);
    const [priceUnit, setPriceUnit] = useState([]);

    const [spfiles, setspfiles] = useState([])

    const [loading, setLoading] = useState(false);


    const [databaseData, setDatabaseData] = useState([]);

    const getDatabaseServer = () => {
        ItrApiService.GET({
            url: 'CloudConnect/Configuration/DatabaseServer'
        }).then(res => {
            if (res.Success == true) {
                if (res.result.isSuccess == true) {
                    if (res.result.data) {
                        setDatabaseData(res.result.data);
                    }
                }
            }
        })
    }

    useEffect(() => {
        if (location.state.editVal) {
            // setdatas({ ...datas, ...location.state.editVal });
            setLoading(true);
            ItrApiService.GET({
                url: `DevOps/Enrollment/GetByEnrollmentID/${location.state.editVal.id || 0}`,
            }).then(res => {
                if (res.Success == true) {
                    if (res.result.isSuccess == true) {
                        setdatas({ ...datas, ...res.result.data });
                        if (res.result.data.enrollmentSplashScreens && res.result.data.enrollmentSplashScreens.length > 0) {
                            let ii = 1
                            let arr = []
                            for (let val of res.result.data.enrollmentSplashScreens) {
                                let obj = {
                                    name: 'test',
                                    uid: ii.toString(),
                                    thumbUrl: val.splashScreen
                                };
                                arr = [...arr, obj];
                                ii = ii + 1;
                            }
                            setspfiles(arr)
                        }
                    } else {
                        NotificationManager.error(res.result.message);
                    }
                } else {
                    NotificationManager.error(res.message);
                }
                ItrApiService.POST({
                    url: 'Platform/UserDefined/AllUDOs',
                    data: ["APP Type", "Commercial Options", "App Category", "App Pricing", "Pricing Unit"]
                }).then(resp => {
                    if (resp.Success == true) {
                        if (resp.result.isSuccess == true) {
                            let resData = resp.result.data;
                            for (let val of resData) {
                                if (val.CategoryName == 'APP Type') {
                                    let index = val.Options.findIndex(x => x.UDOptionID === parseInt(location.state.editVal.serviceType) || 0);
                                    if (index != -1) {
                                        let ss = val.Options[index];
                                        if (res.Success == true) {
                                            if (res.result.isSuccess == true) {
                                                setdatas({ ...datas, ...res.result.data, serviceTypeValue: JSON.stringify(val.Options[index]), serviceTypeName: ss.UDOptionName });
                                            } else {
                                                setdatas({ ...datas, ...location.state.editVal, serviceTypeValue: JSON.stringify(val.Options[index]), serviceTypeName: ss.UDOptionName })
                                            }
                                        } else {
                                            setdatas({ ...datas, ...location.state.editVal, serviceTypeValue: JSON.stringify(val.Options[index]), serviceTypeName: ss.UDOptionName })
                                        }
                                    }
                                    setAppTypes(val.Options);
                                } else if (val.CategoryName == 'Commercial Options') {
                                    setCommercialOption(val.Options);
                                } else if (val.CategoryName == 'App Category') {
                                    setAppCategory(val.Options);
                                } else if (val.CategoryName == 'App Pricing') {
                                    setAppPricing(val.Options);
                                } else if (val.CategoryName == 'Pricing Unit') {
                                    setPriceUnit(val.Options);
                                }
                            }
                        } else {
                            NotificationManager.error(resp.result.message);
                        }
                    }
                    else if (resp.Success == false) {
                        NotificationManager.error(resp.message);
                        // sessionStorage.clear();
                        // history.push('/')
                    }
                    setLoading(false);
                })
            })
        } else {
            history.goBack()
        }
        getDatabaseServer()
    }, [])
    return (
        <div>
            <NxLoader state={loading} />
            <Headers />
            {/* <NxBreadcrumb className="navigation-breadcrumb" data={[
                {
                    name: 'Home',
                    onClick: () => {
                        history.push('/Customer-board');
                    }
                },
            ]} /> */}
            <NxTitleTab className="title-tab" title='Edit App Enrollment' onCloseClick={() => {
                history.goBack();
            }} />
            <div className="card mx-4 mt-0 newenrolemententry-global">
                <div className="card-body p-0">
                    <Steps current={current} responsive={true} size="small" >
                        {titles.map((item, index) => {
                            return <Step key={index} title={item.title} />
                        })}
                    </Steps>
                    {current == 0 ?
                        <div className="container-form">
                            <div className="row p-3 pt-0">
                                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mt-2">
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12  mt-2">
                                            <label htmlFor="firstName">Service Name</label>
                                            <input className="form-control form-control-sm  mt-1" id="firstName" type="text" value={datas.serviceName}
                                                onChange={(e) => setdatas({ ...datas, serviceName: e.target.value })}
                                            />
                                        </div>

                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12  mt-2">
                                            <label htmlFor="appType">Service Type</label>
                                            <select className="form-select form-select-sm mt-1" value={datas?.serviceTypeValue} id="appType"
                                                onChange={(e) => { setdatas({ ...datas, serviceType: JSON.parse(e.target.value).UDOptionID, serviceTypeName: JSON.parse(e.target.value).UDOptionName, serviceTypeValue: e.target.value }); }}
                                            >
                                                <option value={0} selected disabled>--Please select--</option>
                                                {appTypes.map((item, index) => {
                                                    return <option key={index} value={JSON.stringify(item)} >{item.UDOptionName}</option>
                                                })}
                                            </select>
                                        </div>

                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                                            <label htmlFor="serviceCategory"> Service Category</label>
                                            <select className="form-select form-select-sm mt-1" value={datas?.serviceCategory} onChange={(e) => setdatas({ ...datas, serviceCategory: e.target.value })}>
                                                <option value={0} selected disabled>--Please select--</option>
                                                {appCategory && appCategory.map((data, index) =>
                                                    <option key={index}>{data?.UDOptionName}</option>
                                                )}
                                            </select>
                                            {/* <select className="form-select form-select-sm mt-2" value={roles.enrollmentCategory} id="enrollmentCategory"
                                            onChange={(e) => setRoles({ ...roles, enrollmentCategory: e.target.value })}
                                        >
                                            <option value={0} selected>--Please select--</option>
                                            {appCategory.map((item, index) => {
                                                return <option key={index} value={item.UDOptionID} >{item.UDOptionName}</option>
                                            })}
                                        </select> */}
                                        </div>

                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                                            <label htmlFor="firstName">Service Description</label>
                                            <textarea className="form-control form-control-sm mt-1" rows="7" id="firstName"
                                                value={datas.serviceDescription}
                                                onChange={(e) => setdatas({ ...datas, serviceDescription: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mt-2">
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                                            <label htmlFor="firstName">Developer Name</label>
                                            <input className="form-control form-control-sm mt-1" id="firstName" type="text"
                                                value={datas.developerName}
                                                onChange={(e) => setdatas({ ...datas, developerName: e.target.value })}
                                            />
                                        </div>

                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                                            <label htmlFor="firstName">Developer Website</label>
                                            <input className="form-control form-control-sm mt-1" id="firstName" type="text"
                                                value={datas.developerWebsite}
                                                onChange={(e) => setdatas({ ...datas, developerWebsite: e.target.value })}
                                            />
                                        </div>

                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                                            <label htmlFor="firstName">Country</label>
                                            <select className="form-select form-select-sm mt-1" value={datas?.country} id="appType"
                                                onChange={(e) => { setdatas({ ...datas, country: e.target.value }); }}
                                            >
                                                <option value={0} selected disabled>--Please select--</option>
                                                {Countries.map((item, index) => {
                                                    return <option key={index}  >{item.countryName}</option>
                                                })}
                                            </select>
                                        </div>

                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                                            <label htmlFor="firstName">About Developer</label>
                                            <textarea className="form-control form-control-sm mt-1" rows="7" id="firstName"
                                                value={datas.aboutDeveloper}
                                                onChange={(e) => setdatas({ ...datas, aboutDeveloper: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mt-2">

                                    <div className="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12 col-xl-12 mt-2">
                                        <label htmlFor="firstName">Service Key</label>
                                        <input type="text" className="form-control form-control-sm mt-1" id="firstName"
                                            value={datas.serviceKey}
                                            onChange={(e) => {
                                                setdatas({ ...datas, serviceKey: e.target.value })
                                            }}
                                        />
                                    </div>

                                    <div className="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12 col-xl-12 mt-2">
                                        <label htmlFor="firstName">App Icons</label>
                                        <input type="file" className="form-control form-control-sm mt-1" id="firstName" onChange={(e) => {
                                            let img = e.target.files[0];
                                            const fileReader = new FileReader();
                                            fileReader.readAsDataURL(img)
                                            fileReader.onload = async () => {
                                                setdatas({ ...datas, appIcon: fileReader.result })
                                            }
                                            fileReader.onerror = async () => {
                                                return false
                                            }
                                        }} />
                                    </div>

                                    <div className="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12 col-xl-12 mt-2">
                                        <label>Splash Screen</label>
                                        <div className="row">
                                            <div className="col-12 col-sm-4 col-md-4 col-xl-4 col-lg-4 mt-1">
                                                {/* <div className="nx-image-uploader">
                                            <div className="nx-image-container">
                                                <div className="nx-image-content">
                                                    <button type="button">
                                                        <input type="file" hidden />
                                                        <div className="nx-image-box">
                                                            <p>+</p>
                                                            <p>Click here to add image</p>
                                                            <p>Or drop image here</p>
                                                        </div>
                                                    </button>
                                                </div>
                                            </div>
                                        </div> */}
                                                <Upload
                                                    // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                                    listType="picture-card"
                                                    maxCount={3}
                                                    fileList={spfiles}
                                                    onChange={({ fileList }) => {
                                                        setspfiles(fileList)
                                                    }}
                                                // fileList={fileList}
                                                // onPreview={this.handlePreview}
                                                // onChange={this.handleChange}
                                                >
                                                    + upload
                                                    {/* {fileList.length >= 8 ? null : uploadButton} */}
                                                </Upload>
                                            </div>


                                        </div>
                                    </div>
                                </div>

                                {/* <div className="col-12 col-sm-6 col-md-6 col-lg-4  mt-2">
                            <label htmlFor="firstName">Description</label>
                            <textarea className="form-control form-control-sm mt-2" value={roles.serviceDescription} id="firstName"
                                onChange={(e) => setRoles({ ...roles, serviceDescription: e.target.value })}
                            />
                        </div> */}

                                <div className="col-12 col-sm-6 col-md-6 col-lg-4  mt-2">
                                    <div className="row">
                                        {/* <div className="col-6 col-sm-6 col-md-6 col-xl-6 col-lg-6 w-100">
                                    <label htmlFor="firstName">Commercial Options</label>
                                    <select className="form-select form-select-sm form-control-sm mt-2" value={roles.commercialOptions} id="firstName"
                                        onChange={(e) => setRoles({ ...roles, commercialOptions: e.target.value })}
                                    >
                                        <option value={0} selected>--Please select--</option>
                                        {commercialOption.map((item, index) => {
                                            return <option key={index} value={item.UDOptionID} >{item.UDOptionName}</option>
                                        })}
                                    </select>
                                </div> */}
                                    </div>
                                </div>
                            </div>

                            {/* <Buttons/> */}
                        </div> :
                        current == 1 ?
                            <div className="container-form">
                                <div className="row p-3 pt-0">
                                    <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mt-2">
                                        <div className="row">
                                            <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12">
                                                <label htmlFor="firstName">Version</label>
                                                <input className="form-control form-control-sm mt-1" value={datas?.version} id="firstName" type="text"
                                                    onChange={(e) => setdatas({ ...datas, version: e.target.value })}
                                                />
                                            </div>

                                            <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">
                                                <label htmlFor="firstName">Build No</label>
                                                <input className="form-control form-control-sm mt-1" value={datas?.buildNo} id="firstName" type="text"
                                                    onChange={(e) => setdatas({ ...datas, buildNo: e.target.value })}
                                                />
                                            </div>

                                            <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">
                                                <label htmlFor="firstName">Build Type</label>
                                                <select className="form-select form-select-sm mt-1" id="firstName" value={datas?.buildType}
                                                    onChange={(e) => setdatas({ ...datas, buildType: e.target.value })}
                                                >
                                                    <option value="Please select" selected disabled>--Please select--</option>
                                                    <option value="Dev" >Development</option>
                                                    <option value="stg" >Staging</option>
                                                    <option value="tst" >QA</option>
                                                    <option value="rvw" >Review</option>
                                                    <option value="uat" >UAT</option>
                                                    <option value="pre" >Pre-Prod</option>
                                                    <option value="pst" >Post-Prod</option>
                                                    <option value="Pro" >Production</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mt-2">
                                        <div className="row">
                                            <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12">
                                                <label htmlFor="firstName">Source</label>
                                                <select className="form-select form-select-sm mt-1" id="firstName" value={datas?.source}
                                                    onChange={(e) => setdatas({ ...datas, source: e.target.value })}
                                                >
                                                    <option value="" selected disabled>--Please select--</option>
                                                    <option value="Code Upload" >Code Upload</option>
                                                    <option value="Git Connect" >Git Connect</option>
                                                </select>

                                                {datas?.source == 'Code Upload' && datas?.serviceTypeName == "API" ? <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2" >
                                                    <label htmlFor="firstName">Upload API Source</label>
                                                    <input className="form-control mt-1 " id="firstName" type="file" />
                                                </div> :
                                                    datas?.source == 'Code Upload' && datas?.serviceTypeName == "APP" ?
                                                        <>
                                                            <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2" >
                                                                <label htmlFor="firstName">Upload API Source</label>
                                                                <input className="form-control mt-1 " id="firstName" onChange={(e) => {
                                                                    setdatas({ ...datas, apiSourcepath: e.target.value });
                                                                }} />
                                                            </div>
                                                            <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2" >
                                                                <label htmlFor="firstName">API Build Path</label>
                                                                <textarea className='form-control form-control-sm mt-1' rows="1" value={datas.apiBuildFilePath}
                                                                    onChange={(e) => setdatas({ ...datas, apiBuildFilePath: e.target.value })}
                                                                />
                                                            </div>
                                                            <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2"  >
                                                                <label htmlFor="firstName">Upload UI Source</label>
                                                                <input className="form-control mt-1 " id="firstName" type="file" onChange={(e) => {
                                                                    setdatas({ ...datas, uiSourcePath: e.target.value });
                                                                }} />
                                                            </div>
                                                            <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2" >
                                                                <label htmlFor="firstName">UI Build Path</label>
                                                                <textarea className='form-control form-control-sm mt-1' rows="1" value={datas.uiBuildFilePath}
                                                                    onChange={(e) => setdatas({ ...datas, uiBuildFilePath: e.target.value })}
                                                                />
                                                            </div>
                                                            <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2" >
                                                                <label htmlFor="firstName">Upload DB Scripts</label>
                                                                <input className="form-control mt-1 " id="firstName" type="file" onChange={(e) => {
                                                                    setdatas({ ...datas, dbScriptPath: e.target.value });
                                                                }} />
                                                            </div>
                                                        </> :
                                                        datas.source == "Code Upload" && datas.serviceTypeName == "Service" ?
                                                            <>
                                                                <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2" >
                                                                    <label htmlFor="firstName">Upload UI Source</label>
                                                                    <input className="form-control mt-1 " id="firstName" type="file" onChange={(e) => {
                                                                        setdatas({ ...datas, uiSourcePath: e.target.value });
                                                                    }} />
                                                                </div>
                                                                <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2" >
                                                                    <label htmlFor="firstName">UI Build Path</label>
                                                                    <textarea className='form-control form-control-sm mt-1' rows="1" value={datas.uiBuildFilePath}
                                                                        onChange={(e) => setdatas({ ...datas, uiBuildFilePath: e.target.value })}
                                                                    />
                                                                </div>
                                                            </> : ''
                                                }
                                            </div>

                                            <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2" hidden={datas?.source == 'Git Connect' ? false : true}>
                                                <label htmlFor="firstName">Repository Type</label>
                                                <select className="form-select form-select-sm mt-1" id="firstName" value={datas?.repositaryType}
                                                    onChange={(e) => setdatas({ ...datas, repositaryType: e.target.value })}
                                                >
                                                    <option value="Please select" selected>--Please select--</option>
                                                    <option value="Single">Single</option>
                                                    <option value="Multiple">Multiple</option>
                                                </select>
                                            </div>

                                            {/* { datas.serviceTypeName == "API"  ? <>
                                        <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">
                                            <label htmlFor="firstName">Git Access Token</label>
                                            <input className="form-control form-control-sm mt-1 " id="firstName" type="text" />
                                        </div>
                                        <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">
                                            <label htmlFor="firstName">API Source Path</label>
                                            <textarea className='form-control form-control-sm mt-1' rows="1" value={roles.enrollmentApiUrl}
                                                onChange={(e) => setRoles({ ...roles, enrollmentApiUrl: e.target.value })}
                                            />
                                        </div>
                                    </> : <>
                                        <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">
                                            <label htmlFor="firstName">API Source Path</label>
                                            <textarea className='form-control form-control-sm mt-1' rows="1" value={roles.enrollmentApiUrl}
                                                onChange={(e) => setRoles({ ...roles, enrollmentApiUrl: e.target.value })}
                                            />
                                        </div>
                                        <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">
                                            <label htmlFor="firstName">Git Access Token</label>
                                            <input className="form-control form-control-sm mt-1 " id="firstName" type="text" />
                                        </div>
                                    </>} */}

                                            {datas?.serviceTypeName == "API" && datas?.repositaryType == 'Single' ? <>
                                                <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">
                                                    <label htmlFor="firstName">Git Access Token</label>
                                                    <input className="form-control form-control-sm mt-1 " id="firstName" type="text" value={datas?.apiAccessToken} onChange={(e) => {
                                                        setdatas({ ...datas, apiAccessToken: e.target.value })
                                                    }} />
                                                </div>
                                                <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">
                                                    <label htmlFor="firstName">API Source Path</label>
                                                    <textarea className='form-control form-control-sm mt-1' rows="1" value={datas?.apiSourcepath}
                                                        onChange={(e) => setdatas({ ...datas, apiSourcepath: e.target.value })}
                                                    />
                                                </div>
                                                <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2" >
                                                    <label htmlFor="firstName">API Build Path</label>
                                                    <textarea className='form-control form-control-sm mt-1' rows="1" value={datas?.apiBuildFilePath}
                                                        onChange={(e) => setdatas({ ...datas, apiBuildFilePath: e.target.value })}
                                                    />
                                                </div>
                                            </> : datas?.serviceTypeName == "API" && datas?.repositaryType == 'Multiple' ? <>
                                                <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">
                                                    <label htmlFor="firstName">API Source Path</label>
                                                    <textarea className='form-control form-control-sm mt-1' rows="1" value={datas?.apiSourcepath}
                                                        onChange={(e) => setdatas({ ...datas, apiSourcepath: e.target.value })}
                                                    />
                                                </div>
                                                <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2" >
                                                    <label htmlFor="firstName">API Build Path</label>
                                                    <textarea className='form-control form-control-sm mt-1' rows="1" value={datas?.apiBuildFilePath}
                                                        onChange={(e) => setdatas({ ...datas, apiBuildFilePath: e.target.value })}
                                                    />
                                                </div>
                                                <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">
                                                    <label htmlFor="firstName">Git Access Token</label>
                                                    <input className="form-control form-control-sm mt-1 " id="firstName" type="text" value={datas?.apiAccessToken} onChange={(e) => {
                                                        setdatas({ ...datas, apiAccessToken: e.target.value })
                                                    }} />
                                                </div>
                                            </>
                                                : datas?.serviceTypeName == "APP" && datas?.repositaryType == 'Single' ? <>
                                                    <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">
                                                        <label htmlFor="firstName">Git Access Token</label>
                                                        <input className="form-control form-control-sm mt-1 " id="firstName" type="text" value={datas?.gitAccessToken} onChange={(e) => {
                                                            setdatas({ ...datas, gitAccessToken: e.target.value })
                                                        }} />
                                                    </div>

                                                    <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">
                                                        <label htmlFor="firstName">API Source Path</label>
                                                        <textarea className='form-control form-control-sm mt-1' rows="1" value={datas?.apiSourcepath}
                                                            onChange={(e) => setdatas({ ...datas, apiSourcepath: e.target.value })}
                                                        />
                                                    </div>
                                                    <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2" >
                                                        <label htmlFor="firstName">API Build Path</label>
                                                        <textarea className='form-control form-control-sm mt-1' rows="1" value={datas?.apiBuildFilePath}
                                                            onChange={(e) => setdatas({ ...datas, apiBuildFilePath: e.target.value })}
                                                        />
                                                    </div>

                                                    <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">
                                                        <label htmlFor="firstName">UI Source Path</label>
                                                        <textarea className='form-control form-control-sm mt-1' rows="1" value={datas.uiSourcePath}
                                                            onChange={(e) => setdatas({ ...datas, uiSourcePath: e.target.value })}
                                                        />
                                                    </div>
                                                    <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2" >
                                                        <label htmlFor="firstName">UI Build Path</label>
                                                        <textarea className='form-control form-control-sm mt-1' rows="1" value={datas.uiBuildFilePath}
                                                            onChange={(e) => setdatas({ ...datas, uiBuildFilePath: e.target.value })}
                                                        />
                                                    </div>

                                                    <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2" >
                                                        <label htmlFor="firstName">Database Server</label>
                                                        <select className="form-select form-select-sm mt-1" id="firstName" value={datas.databaseServerID}
                                                            onChange={(e) => {
                                                                setdatas({ ...datas, databaseServerID: e.target.value })
                                                            }
                                                            }
                                                        >
                                                            <option value="" selected disabled>--Please select--</option>
                                                            {databaseData.map((data, index) => { return <option value={data.databaseServerID} >{data.databaseServerDescription}</option> })}
                                                        </select>
                                                    </div>

                                                    <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">
                                                        <label htmlFor="firstName">DB Script path</label>
                                                        <textarea className='form-control form-control-sm mt-1' rows="1" value={datas.dbScriptPath}
                                                            onChange={(e) => setdatas({ ...datas, dbScriptPath: e.target.value })}
                                                        />
                                                    </div> </>

                                                    : datas.serviceTypeName == "APP" && datas.repositaryType == 'Multiple' ?
                                                        <>
                                                            <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">
                                                                <label htmlFor="firstName">API Source Path</label>
                                                                <textarea className='form-control form-control-sm mt-1' value={datas.apiSourcepath} rows="1"
                                                                    onChange={(e) => setdatas({ ...datas, apiSourcepath: e.target.value })}
                                                                />
                                                            </div>
                                                            <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2" >
                                                                <label htmlFor="firstName">API Build Path</label>
                                                                <textarea className='form-control form-control-sm mt-1' rows="1" value={datas.apiBuildFilePath}
                                                                    onChange={(e) => setdatas({ ...datas, apiBuildFilePath: e.target.value })}
                                                                />
                                                            </div>

                                                            <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">
                                                                <label htmlFor="firstName">Git Access Token</label>
                                                                <input className="form-control form-control-sm mt-1 " id="firstName" type="text" value={datas.apiAccessToken} onChange={(e) => {
                                                                    setdatas({ ...datas, apiAccessToken: e.target.value })
                                                                }} />
                                                            </div>

                                                            <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">
                                                                <label htmlFor="firstName">UI Source Path</label>
                                                                <textarea className='form-control form-control-sm mt-1' value={datas.uiSourcePath} rows="1"
                                                                    onChange={(e) => setdatas({ ...datas, uiSourcePath: e.target.value })}
                                                                />
                                                            </div>

                                                            <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2" >
                                                                <label htmlFor="firstName">API Build Path</label>
                                                                <textarea className='form-control form-control-sm mt-1' rows="1" value={datas.uiSourcePath}
                                                                    onChange={(e) => setdatas({ ...datas, uiSourcePath: e.target.value })}
                                                                />
                                                            </div>

                                                            <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">
                                                                <label htmlFor="firstName">Git Access Token</label>
                                                                <input className="form-control form-control-sm mt-1 " id="firstName" type="text" value={datas.uiAccessToken}
                                                                    onChange={(e) => setdatas({ ...datas, uiAccessToken: e.target.value })} />
                                                            </div>

                                                            <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2" >
                                                                <label htmlFor="firstName">Database Server</label>
                                                                <select className="form-select form-select-sm mt-1" id="firstName" value={datas.databaseServerID}
                                                                    onChange={(e) => {
                                                                        setdatas({ ...datas, databaseServerID: e.target.value })
                                                                    }
                                                                    }
                                                                >
                                                                    <option value="" selected disabled>--Please select--</option>
                                                                    {databaseData.map((data, index) => { return <option value={data.databaseServerID} >{data.databaseServerDescription}</option> })}
                                                                </select>
                                                            </div>

                                                            <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">
                                                                <label htmlFor="firstName">DB Script Path</label>
                                                                <textarea className='form-control form-control-sm mt-1' value={datas.dbScriptPath} rows="1"
                                                                    onChange={(e) => setdatas({ ...datas, dbScriptPath: e.target.value })}
                                                                />
                                                            </div>

                                                            <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">
                                                                <label htmlFor="firstName">Git Access Token</label>
                                                                <input className="form-control form-control-sm mt-1 " id="firstName" type="text" value={datas.dbscriptAccessToken}
                                                                    onChange={(e) => setdatas({ ...datas, dbscriptAccessToken: e.target.value })}
                                                                />
                                                            </div>
                                                        </> : datas.serviceTypeName == "Service" && datas.repositaryType == 'Single' ?
                                                            <>
                                                                <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">
                                                                    <label htmlFor="firstName">Git Access Token</label>
                                                                    <input className="form-control form-control-sm mt-1 " id="firstName" type="text" value={datas.uiAccessToken} rows="1"
                                                                        onChange={(e) => setdatas({ ...datas, uiAccessToken: e.target.value })}
                                                                    />
                                                                </div>
                                                                <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">
                                                                    <label htmlFor="firstName">UI Source Path</label>
                                                                    <textarea className='form-control form-control-sm mt-1' value={datas.uiSourcePath} rows="1"
                                                                        onChange={(e) => setdatas({ ...datas, uiSourcePath: e.target.value })}
                                                                    />
                                                                </div>
                                                                <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2" >
                                                                    <label htmlFor="firstName">UI Build Path</label>
                                                                    <textarea className='form-control form-control-sm mt-1' rows="1" value={datas.uiBuildFilePath}
                                                                        onChange={(e) => setdatas({ ...datas, uiBuildFilePath: e.target.value })}
                                                                    />
                                                                </div>
                                                            </> : datas.serviceTypeName == "Service" && datas.repositaryType == 'Multiple' ?
                                                                <>
                                                                    <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">
                                                                        <label htmlFor="firstName">UI Source Path</label>
                                                                        <textarea className='form-control form-control-sm mt-1' value={datas.uiSourcePath} rows="1"
                                                                            onChange={(e) => setdatas({ ...datas, uiSourcePath: e.target.value })}
                                                                        />
                                                                    </div>
                                                                    <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2" >
                                                                        <label htmlFor="firstName">UI Build Path</label>
                                                                        <textarea className='form-control form-control-sm mt-1' rows="1" value={datas.uiBuildFilePath}
                                                                            onChange={(e) => setdatas({ ...datas, uiBuildFilePath: e.target.value })}
                                                                        />
                                                                    </div>
                                                                    <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">
                                                                        <label htmlFor="firstName">Git Access Token</label>
                                                                        <input className="form-control form-control-sm mt-1 " id="firstName" type="text" value={datas.uiAccessToken} rows="1"
                                                                            onChange={(e) => setdatas({ ...datas, uiAccessToken: e.target.value })}
                                                                        />
                                                                    </div>

                                                                </> : ''
                                            }
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mt-2">
                                        <div className="row">
                                            <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12" hidden={datas.source == 'Git Connect' ? false : true}>
                                                <label htmlFor="firstName">Enable Auto Deployment</label>
                                                <select className="form-select form-select-sm form-control-sm mt-1" id="firstName" value={datas.autoDeployment}
                                                    onChange={(e) => {
                                                        if (e.target.value == 'true') {
                                                            setdatas({ ...datas, autoDeployment: true })
                                                        }
                                                        else {
                                                            setdatas({ ...datas, autoDeployment: false })
                                                        }
                                                    }
                                                    }
                                                >
                                                    <option value={false} selected>No</option>
                                                    <option value={true}>Yes</option>
                                                </select>
                                            </div>

                                            <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2" hidden={(datas.source == 'Git Connect' && datas.autoDeployment == true) ? false : true}>
                                                <label htmlFor="firstName">Set Frequency</label>
                                                <select className="form-select form-select-sm mt-1" id="firstName" value={datas.setFrequency}
                                                    onChange={(e) => setdatas({ ...datas, setFrequency: e.target.value })}
                                                >
                                                    <option value="Please select" selected>--Please select--</option>
                                                    <option value="1 hour">1 Hour</option>
                                                    <option value="4 hour">4 Hours</option>
                                                    <option value="8 hour">8 Hours</option>
                                                    <option value="12 hour">12 Hours</option>
                                                    <option value="24 hour">24 Hours</option>
                                                    <option value="Custom">Custom Time</option>
                                                    <option value="All check-In">Every Check-In</option>
                                                    <option value="imprompt">Imprompt</option>
                                                </select>
                                            </div>

                                            <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2" hidden={(datas.source == 'Git Connect' && datas.autoDeployment == true && datas.setFrequency == "Custom") ? false : true}>
                                                <label>Set Time</label><br />
                                                <DatePicker picker="time" className="w-100" format="HH:mm" onChange={(e) => {
                                                    setdatas({ ...datas, setTime: e })
                                                }} />
                                                {/* <input className="form-control form-control-sm mt-1" type="time" value="" /> */}
                                            </div>

                                            <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2 text-end" hidden={(datas.source == 'Git Connect' && datas.autoDeployment == true && datas.setFrequency == "imprompt") ? false : true}>
                                                {/* <input className="btn btn-primary text-end" type="button" value="Build Now" /> */}
                                                <button className="btn btn-primary btn-sm text-end" value={datas.isBuildNow}
                                                    onClick={async () => {
                                                        setLoading(true);
                                                        setdatas({ ...datas, isBuildNow: true });
                                                        await createRole(true);
                                                        setLoading(false);
                                                    }}
                                                >Build Now</button>
                                            </div>
                                        </div>
                                    </div>






                                    <div className="col-6 col-md-6 col-sm-6 col-lg-6 col-xl-6 mt-4" hidden={datas.source == 'Github' ? true : false}>

                                    </div>

                                    <div className="col-6 col-md-6 col-sm-6 col-lg-6 col-xl-6 mt-4" hidden={datas.autoDeployment == 'No' ? false : true}>

                                    </div>

                                    <div className="col-6 col-md-6 col-sm-6 col-lg-6 col-xl-6 mt-4" hidden={datas.repositaryType == 'Private' ? false : true}>

                                    </div>

                                    <div className="col-6 col-md-6 col-sm-6 col-lg-6 col-xl-6 mt-4" hidden={datas.repositaryType == 'Private' ? false : true}>

                                    </div>

                                    <div className="col-6 col-md-6 col-sm-6 col-lg-6 col-xl-6 mt-2" hidden={(datas.autoDeployment == 'Yes' && (datas.setFrequency == "Custom" || datas.setFrequency == "imprompt")) ? false : true}>

                                    </div>

                                    <div className="col-6 col-md-6 col-sm-6 col-lg-6 col-xl-6 mt-2 sdgsfg" hidden={(datas.setFrequency == 'Custom' || datas.setFrequency == "imprompt") ? false : true}>

                                    </div>


                                    {/* <div className="col-6 col-md-6 col-sm-6 col-lg-6 col-xl-6 mt-2" hidden={roles.repositoryType == 'Private' ? false : true}>

                            </div> */}


                                    {/* <div className="col-6 col-md-6 col-sm-6 col-lg-6 col-xl-6 mt-2" hidden={(datas.source == 'Github' && roles.repositoryType == 'Private') ? false : true}>
                                {(datas.setFrequency == 'Custom' || datas.setFrequency == "imprompt") ? <div>
                                    <label htmlFor="firstName">Password</label>
                                    <input className="form-control form-control-sm mt-2" value={roles.repoPassword} id="firstName" type="password"
                                        onChange={(e) => setRoles({ ...roles, repoPassword: e.target.value })}
                                    />
                                </div> : <div>
                                    <label htmlFor="firstName">User Name</label>
                                    <input className="form-control form-control-sm mt-2" value={roles.repoUserName} id="firstName" type="text"
                                        onChange={(e) => setRoles({ ...roles, repoUserName: e.target.value })}
                                    />
                                </div>}
                            </div> */}

                                    <div className="col-6 col-md-6 col-sm-6 col-lg-6 col-xl-6 mt-2" hidden={datas.setFrequency == 'Custom' ? false : true}>

                                    </div>

                                    <div className="col-6 col-md-6 col-sm-6 col-lg-6 col-xl-6 mt-2" hidden={datas.repositaryType == 'Private' ? false : true}>

                                    </div>

                                    <div className="col-6 col-md-6 col-sm-6 col-lg-6 col-xl-6 mt-2" hidden={datas.setFrequency == 'Custom' ? false : true}>

                                    </div>

                                    <div className="col-6 col-md-6 col-sm-6 col-lg-6 col-xl-6 mt-2" hidden={datas.repositaryType == 'Private' ? false : true}>

                                    </div>

                                    <div className="col-6 col-md-6 col-sm-6 col-lg-6 col-xl-6 mt-2">

                                    </div>
                                </div>

                                {/* <Buttons/> */}
                            </div> :
                            current == 2 ?
                                <div className="container-form">
                                    <div className="row p-3 pt-0">
                                        <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5 mt-2">
                                            <div className="nx-case-details mb-5 pt-0">
                                                <div className="row align-items-baseline">
                                                    <label className="fw-bold" style={{ color: '#172996' }}>Upload JSON</label>
                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                                                        <input type="file" className="form-control w-100" accept=".json" onChange={(e) => {

                                                            if (e.target.files[0].name == 'roles.json') {
                                                                let img = e.target.files[0];
                                                                const fileReader = new FileReader();
                                                                fileReader.readAsDataURL(img)
                                                                fileReader.onload = async () => {
                                                                    setJson({ ...json, name: e.target.files[0].name, image: fileReader.result })
                                                                }
                                                                fileReader.onerror = async () => {
                                                                    return false
                                                                }
                                                            }
                                                            else {
                                                                NotificationManager.error('invalid json file')
                                                            }
                                                        }} />
                                                        <p className="text-secondary">Upload the roles.json file in the prescribed format </p>
                                                    </div>
                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-end">
                                                        <button className="btn btn-sm" style={{ backgroundColor: '#172996', color: 'white', fontSize: '13px' }}>
                                                            Upload
                                                        </button>
                                                    </div>
                                                    {/* <p className="text-danger">(OR)</p> */}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 col-sm-12 col-md-6 col-lg-7 col-xl-7 mt-2">
                                            <label className="fw-bold" style={{ color: '#172996' }}>Update Roles Manualy</label>
                                            <div class="table-wrapper-scroll-y my-custom-scrollbar mt-2">
                                                <table class="table table-bordered mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Role Code</th>
                                                            <th scope="col">Role Name</th>
                                                            <th scope="col">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {datas.enrollmentRoles.map((item, index) => {
                                                            return <tr>
                                                                <td> {item.roleCode} </td>
                                                                <td> {item.roleName} </td>
                                                                <td align="center">
                                                                    <button className="btn" onClick={() => {
                                                                        let arr = datas.enrollmentRoles;
                                                                        arr.splice(index, 1);
                                                                        setdatas({
                                                                            ...datas, rollCode: '', rollName: "", enrollmentRoles: arr
                                                                        })
                                                                    }}>
                                                                        <FontAwesomeIcon size="sm" icon={faMinusCircle} color="red" className="d-flex align-items-center justify-content-center" />
                                                                    </button>
                                                                    {/* <button type="button" className='btn form-button text-center btn-danger w-50 btn-sm mt-2' onClick={() => {
                                                                    if (roles.rollCode == '' && roles.rollName == '') {

                                                                    } else {
                                                                        setRoles({
                                                                            ...roles, rollCode: '', rollName: "", enrollmentRoles: [...roles.enrollmentRoles, {
                                                                                roleCode: roles.rollCode,
                                                                                roleName: roles.rollName,
                                                                                description: "",
                                                                                additionalInfo: "",
                                                                                isActive: true
                                                                            }]
                                                                        })
                                                                    }
                                                                }}> - </button> */}
                                                                </td>
                                                            </tr>
                                                        })}
                                                        <tr>

                                                            <td>
                                                                <input className="form-control form-control-sm" value={datas.rollCode} id="roleName" type="text"
                                                                    onChange={(e) => setdatas({ ...datas, rollCode: e.target.value })}
                                                                />
                                                            </td>

                                                            <td>
                                                                <input className="form-control form-control-sm" value={datas.rollName} id="roleCode" type="text"
                                                                    onChange={(e) => setdatas({ ...datas, rollName: e.target.value })}
                                                                />
                                                            </td>
                                                            <td align="center" className="d-flex align-items-center justify-content-center">
                                                                <button className="btn" onClick={() => {
                                                                    if (datas.rollCode == '' && datas.rollName == '') {
                                                                    } else {
                                                                        setdatas({
                                                                            ...datas, rollCode: '', rollName: "", enrollmentRoles: [...datas.enrollmentRoles, {
                                                                                roleCode: datas.rollCode,
                                                                                roleName: datas.rollName,
                                                                                enrollmentID: datas.id,
                                                                                description: "",
                                                                                additionalInfo: "",
                                                                                isActive: true
                                                                            }]
                                                                        })
                                                                    }
                                                                }}>
                                                                    <FontAwesomeIcon icon={faPlusCircle} size="sm" color="green" />
                                                                </button>
                                                                {/* <button type="button" className='btn text-center btn-primary w-100 btn-sm mt-2' onClick={() => {
                                                                if (roles.rollCode == '' && roles.rollName == '') {
                                                                } else {
                                                                    setdatas({
                                                                        ...roles, rollCode: '', rollName: "", enrollmentRoles: [...roles.enrollmentRoles, {
                                                                            roleCode: roles.rollCode,
                                                                            roleName: roles.rollName,
                                                                            description: "",
                                                                            additionalInfo: "",
                                                                            isActive: true
                                                                        }]
                                                                    })
                                                                }
                                                            }}> + </button> */}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        {/* <div className="card mt-3">
                                        <div className="card-body">
                                            <div class="table-wrapper-scroll-y my-custom-scrollbar rtbl">
                                                <table class="table table-bordered mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Role Code</th>
                                                            <th scope="col">Role Name</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {roles.enrollmentRoles.map((item, index) => {
                                                            return <tr>
                                                                <td>
                                                                    {item.roleCode}
                                                                </td>
                                                                <td>
                                                                    {item.roleName}
                                                                </td>
                                                            </tr>
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div> */}
                                    </div>

                                    {/* buttons */}
                                    {/* <Buttons/> */}
                                </div> :
                                <div className="container-form">
                                    <div className='p-3 pt-0'>
                                        <h5>You have successfully enrolled an app for publishing.</h5>
                                        <pre style={{ fontSize: '16px', color: '#172996' }}>Service Name : <span style={{ fontStyle: 'normal', fontSize: '14px', color: 'black' }}> {datas.serviceName} </span> </pre>
                                        <pre style={{ fontSize: '16px', color: '#172996' }}>Developer : <span style={{ fontStyle: 'normal', fontSize: '14px', color: 'black' }}> {datas.developerName} </span> </pre>
                                        <pre style={{ fontSize: '16px', color: '#172996' }}>Version : <span style={{ fontStyle: 'normal', fontSize: '14px', color: 'black' }}> {datas.version} </span> </pre>
                                        <pre style={{ fontSize: '16px', color: '#172996' }}>Build No : <span style={{ fontStyle: 'normal', fontSize: '14px', color: 'black' }}> {datas.buildNo} </span> </pre>
                                        <pre style={{ fontSize: '16px', color: '#172996' }}>Build Type : <span style={{ fontStyle: 'normal', fontSize: '14px', color: 'black' }}> {datas.buildType} </span> </pre>

                                        <div className='mt-1'>
                                            <p> If you want to edit any information you can do so now, <button onClick={() => prev()} className='btn btn-primary' style={{ fontSize: '12px', padding: '2px 5px' }}>Back</button> </p>
                                        </div>
										
										<div lassName="mt-2">
											<span> Base App :  </span>
											<Switch
												checked={datas?.isBaseAppService == true ? true : datas?.isBaseAppService}
												onChange={(e) => setdatas({ ...datas, isBaseAppService: e })}
											/>
										</div>
										
                                        <div className="mt-3">
                                            <span> Forced Update : </span>
                                            <Switch
                                                checked={datas.isForcetoUpdate}
                                                onChange={(e) => setdatas({ ...datas, isForcetoUpdate: e })} />
                                        </div>
                                    </div>
                                </div>
                    }
                    <div className="steps-action container" style={{ textAlign: 'right' }}>
                        {current > 0 && (
                            <Button style={{ margin: '0 8px' }} onClick={() => prev()} hidden={current == 3 ? true : false}>
                                Previous
                            </Button>
                        )}
                        {current == 0 && (
                            <Button style={{ margin: '0 8px' }} onClick={() => history.goBack()}>
                                Cancel
                            </Button>
                        )}
                        {current == 0 ? <Button type="primary"
                            disabled={(datas.serviceName == '' || datas.serviceType == '' || datas.serviceCategory == '' || datas.developerName == '' || datas.country == '' || datas.serviceKey == '')}
                            onClick={() => next()}>
                            Next
                        </Button> : ''}

                        {current == 1 ? <Button type="primary"

                            onClick={() => next()}>
                            Next
                        </Button> : ''}
                        {current == 2 ? <Button type="primary"

                            onClick={() => next()}>
                            Next
                        </Button> : ''}
                        {current == 3 ? <Button type="primary"
                            onClick={async () => {
                                setLoading(true);
                                await createRole();
                                setLoading(false);
                            }}
                        >
                            Update
                            {/* {(datas && datas.StoreStatus && datas.StoreStatus == 'Published') ? 'Update' : 'Publish'} */}
                        </Button> : ''}
                    </div>
                </div>
            </div>
            <div className="container enroll-button text-start">
                {/* <Buttons /> */}
            </div>
            <NxFooter ExtraContent={[
                { name: 'Terms of Service' },
                { name: 'privacy' },
            ]} />
        </div>
    )
}