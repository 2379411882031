import React from 'react';
import {Doughnut , PolarArea} from 'react-chartjs-2';


export default function NxDoughnutCharts() {
    
    return (
        <div>
            <PolarArea
                data={{
                    labels: ['Sales Team','Web-Site','Microsoft'],
                    datasets: [
                      {
                        data: [12, 19, 10],
                        backgroundColor: [
                          'rgba(196, 105, 192, 0.5)',
                          'rgba(206, 210, 225,0.5)',
                          '#b5651d',
                        ],
                        borderColor: [
                          'rgba(196, 105, 192)',
                          'rgb(206, 210, 225)',
                          'rgba(146, 144, 136, 0.1)',
                        ],
                        borderWidth: 2
                      },
                    ],
                  }}
                  height={200}
                  width={200}
                  options={{
                    maintainAspectRatio: false,
                    responsve:true,
                    }}
            />
        </div>
    )
}