import React, { useEffect, useState } from "react";
import './ManageUsers.css';
import { useHistory } from 'react-router-dom';
import { NxLoader, NxBreadcrumb, NxTitleTab, NxCustomTab, NxGridList, NxActionTab, NxRightDrawer, NxFooter } from '@afiplfeed/itr-app';
import { ItrApiService, ItrAuthService } from "@afiplfeed/itr-ui";
import Headers from "../../Screens/Headers/Headers";
import ResetPasswordModal from "./ResetPasswordModal";
import ActiveUser from "./ActiveUser";
import InActiveUser from "./InActiveUser";



export default function ManageUsers() {

    let history = useHistory();
    const [load, setLoad] = useState(false);

    return (
        <div>
            <NxLoader state={load} />
            <Headers />
            <div style={{ flex: 1 }}>

                <NxTitleTab className="title-tab" title="Manage IAM" onCloseClick={() => {
                    history.goBack();
                }} />
                <div className="d-flex justify-content-between align-items-center p-4 pb-0">
                    <div></div>
                    <button className="btn btn-sm" style={{ backgroundColor: '#73a562', color: 'white', fontSize: '14px', borderRadius: '0' }}
                        onClick={() => history.push('/access/manage-user/add')}>Add New User</button>
                </div>

                <NxCustomTab
                    enableSearch={true}
                    autoSearch={true}
                    enableAutoPagination={true}
                    showShowing={true}
                    data={[
                        {
                            name: 'Active',
                            Components: () => {
                                return (
                                    <ActiveUser history={history} setLoad={setLoad} load={load} />
                                )
                            }
                        },
                        {
                            name: 'In Active',
                            Components: () => {
                                return (
                                    <div className="container-form mb-5 mt-3">
                                        <InActiveUser history={history} setLoad={setLoad} load={load} />
                                    </div>
                                )
                            }
                        },
                    ]} />

            </div>

            <NxFooter companyName="© 2022 Ithred Solutions" ExtraContent={[
                { name: 'Terms of Service' },
                { name: 'Privacy' },
            ]} />
        </div>
    )
}


