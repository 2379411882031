import { ItrApiService } from '@afiplfeed/itr-ui';
import { faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useState,useEffect } from 'react';
import './Style.css'
function Pricing({ pricingModule, setPricingModule, storeConnectPricings, setStoreConnectPricings }) {
    const [priceTabs, setPriceTabs] = useState({
        userFrom: '',
        userTo: "",
        amount: ''
    });

    const [datas, setDatas] = useState({});

    useEffect(() => {
        ItrApiService.GET({ url: 'RevOps/BillerInformation' }).then(res => {
            // setLoader(false);
            if (res.Success == true) {
                if (res.result.isSuccess == true) {
                    if (res.result.data) {
                        setDatas(res.result.data);
                    }
                }
            }
        });
    }, [])



    return (
        <div className='p-3'>
            <div className='f-virision-pad-static'>
                <div className='f-app-div-box-main'>
                    <div className="f-app-div-box">
                        <label htmlFor="pricingModule" className='f-badge-title'>Pricing Module (All Pricing in {datas.currencyName && datas.currencyName.split("-")[0]}) </label>
                        <select className="form-select form-select-sm mt-1" id="pricingModule" value={pricingModule} onChange={(e) => {
                            setPricingModule(e.target.value);
                            setStoreConnectPricings([]);
                        }}>
                            <option value='' selected disabled>--Please select--</option>
                            <option value='Quantity' >Quantity</option>
                            <option value='Volume' >Volume</option>
                            <option value='Tiered' >Tiered</option>
                            <option value='Step-Up' >Step-Up</option>
                        </select>
                    </div>
                </div>
                {/* <div className="card mt-3">
                <div className="card-body">
                    <div class="table-wrapper-scroll-y my-custom-scrollbar rtbl">
                        <table class="table table-bordered mb-0">
                            <thead>
                                <tr>
                                    <th scope="col">Role Code</th>
                                    <th scope="col">Role Name</th>
                                </tr>
                            </thead>
                            <tbody>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div> */}
                <div className='f-app-div-box mt-4'>
                    {pricingModule != '' && <label className='f-badge-title'>Pricing Structure</label>}
                    {pricingModule == 'Quantity' ? <div>
                        <label className='mt-3'>Cost per user <input type='number' value={(storeConnectPricings && storeConnectPricings.length > 0) ? storeConnectPricings[0].amount : ''} onChange={(e) => {
                            if (storeConnectPricings.length == 0) {
                                setStoreConnectPricings([
                                    {
                                        "storeConnectPricingID": 0,
                                        "storeConnectID": 0,
                                        "pricingModule": pricingModule,
                                        "amount": parseInt(e.target.value),
                                        "from": 0,
                                        "to": 0,
                                        "userCount": 0,
                                        "isExpiry": false,
                                        "revisionDate": moment().format(),
                                        "expiryDate": null,
                                        "isActive": true
                                    }
                                ])
                            } else {
                                let arr = [...storeConnectPricings];
                                arr[0].amount = parseInt(e.target.value);
                                setStoreConnectPricings(arr)
                            }
                        }} /> INR</label>
                    </div> : (pricingModule == 'Volume' || pricingModule == 'Tiered') ? <div class="table-wrapper-scroll-y my-custom-scrollbar mt-2">
                        <table class="table table-bordered mb-0">
                            <thead>
                                <tr>
                                    <th scope="col">From</th>
                                    <th scope="col">To</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {storeConnectPricings.map((item, index) => {
                                    return <tr key={index}>
                                        <td> {item.from} </td>
                                        <td> {item.to} </td>
                                        <td> {item.amount} </td>
                                        <td align="center">
                                            <button className="btn" onClick={() => {
                                                // let arr = []
                                                // let arr = [...storeConnectPricings]
                                                // arr = storeConnectPricings.splice(index, index + 1);
                                                // setStoreConnectPricings(arr);
                                                let newArray = [...storeConnectPricings]
                                                newArray.splice(index, 1)
                                                setStoreConnectPricings(newArray)
                                            }} >
                                                <FontAwesomeIcon size="sm" icon={faMinusCircle} color="red" className="d-flex align-items-center justify-content-center" />
                                            </button>
                                        </td>
                                    </tr>
                                })}
                                <tr>
                                    <td>
                                        <input className="form-control form-control-sm" value={priceTabs.userFrom} id="roleName" type='number'
                                            onChange={(e) => setPriceTabs({ ...priceTabs, userFrom: e.target.value })}
                                        />
                                    </td>

                                    <td>
                                        <input className="form-control form-control-sm" value={priceTabs.userTo} id="roleCode" type='number'
                                            onChange={(e) => setPriceTabs({ ...priceTabs, userTo: e.target.value })}
                                        />
                                    </td>
                                    <td>
                                        <input className="form-control form-control-sm" value={priceTabs.amount} id="roleCode" type='number'
                                            onChange={(e) => setPriceTabs({ ...priceTabs, amount: e.target.value })}
                                        />
                                    </td>
                                    <td align="center" className="d-flex align-items-center justify-content-center">
                                        <button className="btn" onClick={() => {
                                            if (priceTabs.userTo == '' || priceTabs.userFrom == '' || priceTabs.amount == '') {
                                            } else {
                                                setStoreConnectPricings([...storeConnectPricings,
                                                {
                                                    "storeConnectPricingID": 0,
                                                    "storeConnectID": 0,
                                                    "pricingModule": pricingModule,
                                                    "amount": parseInt(priceTabs.amount),
                                                    "from": parseInt(priceTabs.userFrom),
                                                    "to": parseInt(priceTabs.userTo),
                                                    "userCount": 0,
                                                    "isExpiry": false,
                                                    "revisionDate": moment().format(),
                                                    "expiryDate": null,
                                                    "isActive": true
                                                }
                                                ])
                                                setPriceTabs({
                                                    userFrom: parseInt(priceTabs.userTo) + 1,
                                                    userTo: 0,
                                                    amount: 0
                                                })
                                            }
                                        }}>
                                            <FontAwesomeIcon icon={faPlusCircle} size="sm" color="green" />
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div> : (pricingModule == 'Step-Up') && <div class="table-wrapper-scroll-y my-custom-scrollbar mt-2">
                        <table class="table table-bordered mb-0">
                            <thead>
                                <tr>
                                    <th scope="col">User</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {storeConnectPricings.map((item, index) => {
                                    return <tr>
                                        <td> {item.userCount} </td>
                                        <td> {item.amount} </td>
                                        <td align="center">
                                            <button className="btn" onClick={() => {
                                                // let arr = [...storeConnectPricings]
                                                // arr = storeConnectPricings.splice(index, 1);
                                                let newArray1 = [...storeConnectPricings]
                                                newArray1.splice(index, 1)
                                                setStoreConnectPricings(newArray1)
                                                // setStoreConnectPricings(arr.splice(index, 1))
                                            }}>
                                                <FontAwesomeIcon size="sm" icon={faMinusCircle} color="red" className="d-flex align-items-center justify-content-center" />
                                            </button>
                                        </td>
                                    </tr>
                                })}
                                <tr>
                                    <td>
                                        <input className="form-control form-control-sm" value={priceTabs.userTo} id="roleCode" type='number'
                                            onChange={(e) => setPriceTabs({ ...priceTabs, userTo: e.target.value })}
                                        />
                                    </td>
                                    <td>
                                        <input className="form-control form-control-sm" value={priceTabs.amount} id="roleCode" type='number'
                                            onChange={(e) => setPriceTabs({ ...priceTabs, amount: e.target.value })}
                                        />
                                    </td>
                                    <td align="center" className="d-flex align-items-center justify-content-center">
                                        <button className="btn" onClick={() => {
                                            if (priceTabs.userTo == '' || priceTabs.amount == '') {
                                            } else {
                                                setStoreConnectPricings([...storeConnectPricings,
                                                {
                                                    "storeConnectPricingID": 0,
                                                    "storeConnectID": 0,
                                                    "pricingModule": pricingModule,
                                                    "amount": parseInt(priceTabs.amount),
                                                    "from": 0,
                                                    "to": 0,
                                                    "userCount": parseInt(priceTabs.userTo),
                                                    "isExpiry": false,
                                                    "revisionDate": moment().format(),
                                                    "expiryDate": null,
                                                    "isActive": true
                                                }
                                                ])
                                                setPriceTabs({
                                                    userFrom: 0,
                                                    userTo: parseInt(priceTabs.userTo) + 1,
                                                    amount: 0
                                                })
                                            }
                                        }}>
                                            <FontAwesomeIcon icon={faPlusCircle} size="sm" color="green" />
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>}
                </div>
            </div>
        </div>
    );
}

export default Pricing;