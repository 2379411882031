import { NxFooter, NxLoader, NxTitleTab } from '@afiplfeed/itr-app';
import { ItrApiService } from '@afiplfeed/itr-ui';
import React, { useEffect, useState, } from 'react';
import { NotificationManager } from 'react-notifications';
import { useHistory, useLocation } from 'react-router-dom'
import Headers from '../../../Screens/Headers/Headers';

function EditPartnerManagement() {
    // country
    const [allCountries, setAllCountries] = useState([]);
    const getCountry = async () => {
        let result = await ItrApiService.GET({
            url: 'RevOps/Country/GetCountries'
        });
        if (result.Success == true) {
            if (result.result.isSuccess == true) {
                let getCountryDetails = result.result.data;
                if (result.result.data) {
                    setAllCountries(getCountryDetails);
                }
            }
            else if (result.result.isSuccess == false) {
                NotificationManager.error(result.result.message);
            }
        } else {
            NotificationManager.error(result.message);
        }
    }
	// CommissionPlan
    const [allCommissionPlans, setAllCommissionPlans] = useState([]);
    const getCommissionPlan = async () => {
        let result = await ItrApiService.GET({
            url: 'RevOps/CommissionPlan/GetAllCommissionPlan'
        });
        if (result.Success == true) {
            if (result.result.isSuccess == true) {
                let getCommissionPlans = result.result.data;
                if (result.result.data) {
                    setAllCommissionPlans(getCommissionPlans);
                }
            }
            else if (result.result.isSuccess == false) {
                NotificationManager.error(result.result.message);
            }
        } else {
            NotificationManager.error(result.message);
        }
    }

    const history = useHistory();
    const location = useLocation();
    const [loader, setLoader] = useState(false);

    const [datas, setPartnerManagement] = useState({
        "partnerInformationID": 0,
        "partnerCode": "",
        "partnerName": "",
        "supportEmail": "",
        "supportPhone": "",
        "partnerWebsite": "",
        "partnerLogo": "",
        "addressLine1": "",
        "addressLine2": "",
        "city": "",
        "state": "",
        "pinCode": "",
        "country": 0,
        "cin": "",
        "pan": "",
        "gst": "",
        "tan": "",
		"commissionPlanID": 0
    });

    useEffect(() => {
        if (!location.state || !location.state.partnerInformationID) {
            history.replace('/partner-management')
        } else {
            setPartnerManagement(location.state);
        }
        getCountry();
		getCommissionPlan();
    }, [])
    return (
        <div>
            <Headers />
            <NxLoader state={loader} />
            <NxTitleTab className="title-tab" title="Edit Partner Setup" onCloseClick={() => history.goBack()} />
            <div className="card password-policy-main mt-0">
                <div className="card-body p-0 mb-5" >
                    <div className="row p-3 mx-1">
                        <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-2'>
                            <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2'>
                                <label htmlFor="billerName" className='f-badge-title'>Partner Name</label>
                                <input className="form-control form-control-sm mt-1" type="text" id='billerName' value={datas.partnerName} onChange={(e) => {
                                    setPartnerManagement({ ...datas, partnerName: e.target.value })
                                }} />
                            </div>

                            <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12  mt-2'>
                                <label htmlFor="email" className='f-badge-title'>Support Email</label>
                                <input className="form-control form-control-sm mt-1" type="email" id='email' value={datas.supportEmail} onChange={(e) => {
                                    setPartnerManagement({ ...datas, supportEmail: e.target.value })
                                }} />
                            </div>

                            <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12  mt-2'>
                                <label htmlFor="phNo" className='f-badge-title' >Support Phone Number</label>
                                <input className="form-control form-control-sm mt-1" type="tel" id='phNo' value={datas.supportPhone} onChange={(e) => {
                                    setPartnerManagement({ ...datas, supportPhone: e.target.value })
                                }} />
                            </div>

                            <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12  mt-2'>
                                <label htmlFor="website" className='f-badge-title'>Partner Website</label>
                                <input className="form-control form-control-sm mt-1" type="url" id='website' value={datas.partnerWebsite} onChange={(e) => {
                                    setPartnerManagement({ ...datas, partnerWebsite: e.target.value })
                                }} />
                            </div>

                            <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12  mt-2'>
                                <label htmlFor="logo" className='f-badge-title'>Upload Logo</label>
                                <input className="form-control form-control-sm mt-1" type="file" id='logo' onChange={(e) => {
                                    let img = e.target.files[0];
                                    const fileReader = new FileReader();
                                    fileReader.readAsDataURL(img)
                                    fileReader.onload = async () => {
                                        setPartnerManagement({ ...datas, partnerLogo: fileReader.result })
                                    }
                                    fileReader.onerror = async () => {
                                        return false
                                    }
                                }} />
                            </div>
                        </div>

                        <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-2'>
                            <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12  mt-2'>
                                <label htmlFor="address1" className='f-badge-title'>Address Line 1</label>
                                <textarea className="form-control form-control-sm mt-1" id='address1' rows="1" value={datas.addressLine1} onChange={(e) => {
                                    setPartnerManagement({ ...datas, addressLine1: e.target.value })
                                }} />
                            </div>

                            <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12  mt-2'>
                                <label htmlFor="address2" className='f-badge-title'>Address Line 2</label>
                                <textarea className="form-control form-control-sm mt-1" id='address2' rows="1" value={datas.addressLine2} onChange={(e) => {
                                    setPartnerManagement({ ...datas, addressLine2: e.target.value })
                                }} />
                            </div>

                            <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2'>
                                <div className='row'>
                                    <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6'>
                                        <label htmlFor="state" className='f-badge-title'>State</label>
                                        <input className="form-control form-control-sm mt-1" type="text" id='state' value={datas.state} onChange={(e) => {
                                            setPartnerManagement({ ...datas, state: e.target.value })
                                        }} />
                                    </div>

                                    <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6'>
                                        <label htmlFor="zip" className='f-badge-title'>Zip</label>
                                        <input className="form-control form-control-sm mt-1" type='tel' id='zip' value={datas.pinCode} onChange={(e) => {
                                            setPartnerManagement({ ...datas, pinCode: e.target.value })
                                        }} />
                                    </div>
                                </div>

                            </div>

                            <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2'>
                                <label htmlFor="state" className='f-badge-title'>City</label>
                                <input className="form-control form-control-sm mt-1" type="text" id='city' value={datas.city} onChange={(e) => {
                                    setPartnerManagement({ ...datas, city: e.target.value })
                                }} />
                            </div>

                            <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2'>
                                <label htmlFor="country" className='f-badge-title'>Country</label>
                                <select className="form-select form-select-sm mt-1"
                                    value={datas.country} onChange={(e) => {
                                        setPartnerManagement({ ...datas, country: e.target.value })
                                    }}
                                >
                                    <option value="" selected disabled> -- Please Select -- </option>
                                    {allCountries.map((country, index) => {
                                        return <option key={index} value={country.countryID}> {country.countryName} </option>
                                    })}
                                </select>
                            </div>


                        </div>

                        <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-2'>
                            <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2'>
                                <label htmlFor="cin" className='f-badge-title'>Company identification number (CIN)</label>
                                <input className="form-control form-control-sm mt-1" type="text" id='cin' value={datas.cin} onChange={(e) => {
                                    setPartnerManagement({ ...datas, cin: e.target.value })
                                }} />
                            </div>
                            <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2'>
                                <label htmlFor="cin" className='f-badge-title'>Partner PAN (Permanent Account Number)</label>
                                <input className="form-control form-control-sm mt-1" type="text" id='cin' value={datas.pan} onChange={(e) => {
                                    setPartnerManagement({ ...datas, pan: e.target.value })
                                }} />
                            </div>
                            <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2'>
                                <label htmlFor="gst" className='f-badge-title'>Goods & Services Tax (GST) Identification number</label>
                                <input className="form-control form-control-sm mt-1" type="text" id='gst' value={datas.gst} onChange={(e) => {
                                    setPartnerManagement({ ...datas, gst: e.target.value })
                                }} />
                            </div>

                            <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2'>
                                <label htmlFor="tan" className='f-badge-title'>Tax deduction account number (TAN)</label>
                                <input className="form-control form-control-sm mt-1" type="text" id='tan' value={datas.tan} onChange={(e) => {
                                    setPartnerManagement({ ...datas, tan: e.target.value })
                                }} />
                            </div>

                            <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2'>
                                <label htmlFor="tan" className='f-badge-title'>Commission Plan</label>
                                <select className="form-select form-select-sm mt-1"
                                    value={datas.commissionPlanID}
                                    onChange={(e) => {
                                        setPartnerManagement({ ...datas, commissionPlanID: e.target.value })
                                    }}
                                >
									<option value="0" selected > -- Please Select -- </option>
                                    {allCommissionPlans && allCommissionPlans.map((data, index) => {
                                        return <option key={index} value={data.commissionPlanID}> {data.commissionPlanName} </option>
                                    })}
                                </select>
                            </div>
                        </div>

                        <div className='text-end'>
                            <button className='btn btn-primary mt-4 mb-5' onClick={() => {
                                setLoader(true);
                                ItrApiService.POST({ data: datas, url: 'RevOps/PartnerInformation' }).then(res => {
                                    if (res.Success == true) {
                                        if (res.result.isSuccess == true) {
                                            NotificationManager.success('Updated!');
                                            history.goBack();
                                            setLoader(false);
                                        }
                                        else {
                                            setLoader(false);
                                            NotificationManager.error(res.result.message);
                                        }
                                    }
                                    else {
                                        setLoader(false);
                                        NotificationManager.error(res.message);
                                    }
                                })
                            }}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
            <NxFooter companyName="© 2022 Ithred Solutions" ExtraContent={[
                { name: 'Terms of Service' },
                { name: 'Privacy' },
            ]} />
        </div>
    );
}

export default EditPartnerManagement;