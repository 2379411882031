import { NxTitleTab, NxFooter, NxCustomTab, NxGridList } from '@afiplfeed/itr-app';
import React from 'react';
import { useHistory } from 'react-router-dom';
import Headers from '../../../Screens/Headers/Headers';

export default function Audit() {

    let history = useHistory();

    return (
        <div>
            <Headers />
            <NxTitleTab className="title-tab" title='Log Analytics' onCloseClick={() => history.push('/configuration')} />

            <NxCustomTab
                data={[
                    {
                        name: 'User Audit',
                        Components: () => {
                            return (
                                <div className="container-form mb-5 mt-3">
                                    <NxGridList
                                        enableSearch={true}
                                        autoSearch={true}
                                        enableAutoPagination={true}
                                        showShowing={true}
                                        header={[
                                            {
                                                name: 'First Name',
                                                sort: true,
                                                search: true
                                            },
                                            {
                                                name: 'Email/Username',
                                                search: true
                                            },
                                            {
                                                name: 'First Login',
                                                sort: true
                                            },
                                            {
                                                name: 'Last Login',
                                                sort: true
                                            },
                                            {
                                                name: '2FA Enabled'
                                            },
                                            {
                                                name: 'Min Duration'
                                            },
                                            {
                                                name: 'Max Duration'
                                            },
                                            {
                                                name: 'Avg Duration'
                                            },
                                            {
                                                name: 'Last Session'
                                            },

                                        ]} />
                                </div>
                            )
                        }
                    },
                    {
                        name: 'Access Log',
                        Components: () => {
                            return (
                                <div className="container-form mb-5 mt-3">
                                    <NxGridList enableSearch={false} header={[
                                        {
                                            name: 'Login Date',
                                            sort: true
                                        },
                                        {
                                            name: 'Total Session Duration',
                                        },
                                        {
                                            name: '#Sessions / Login',
                                        },
                                        {
                                            name: 'Status',
                                        },
                                    ]} />
                                </div>
                            )
                        }
                    },
                    {
                        name: 'Device Management',
                        Components: () => {
                            return (
                                <div className="container-form mb-5 mt-3">
                                    <NxGridList enableSearch={false} header={[
                                        {
                                            name: 'First Name',
                                            sort: true
                                        },
                                        {
                                            name: 'Email / Username',
                                        },
                                        {
                                            name: 'Device Name',
                                        },
                                        {
                                            name: 'Device Type',
                                        },
                                        {
                                            name: 'MAC ID',
                                        },
                                        {
                                            name: 'Operating Sys',
                                        },
                                        {
                                            name: 'Browser',
                                        },
                                        {
                                            name: 'Status',
                                        },
                                        {
                                            name: 'Action',
                                        },
                                    ]} />
                                </div>
                            )
                        }
                    },
                ]} />
            {/* <div className="card m-4 mt-0">
                <div className="card-body p-0">
                    <div className="container-form framework-container">
                        <h3>Coming Soon</h3>
                    </div>
                </div>
            </div> */}

            <NxFooter ExtraContent={[
                { name: 'Terms of Service' },
                { name: 'privacy' },
            ]} />
        </div>
    )
}
