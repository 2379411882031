import React, { useEffect, useState } from "react";
import './GitRepo.css';
import { useHistory } from 'react-router-dom';
import { NxLoader, NxTitleTab, NxActionTab, NxGridList, NxFooter } from '@afiplfeed/itr-app';
import { ItrApiService } from '@afiplfeed/itr-ui';


const splits = async (dests, setData, setExport) => {
    let arr = [];
    let arr2 = [];
    for (let value of dests) {
        let data = [value.gitRepoAccountName, value.pat];
        let obj = { sendItem: value, data: data };
        let obj2 = {
            'Repo identifier name': value.gitRepoAccountName,
            'Access Token': value.pat
        }
        arr2 = [...arr2, obj2];
        arr = [...arr, obj]
    }
    setExport(arr2);
    setData(arr);
}


const splits2 = async (dests, setData) => {
    let arr = [];
    let arr2 = []
    // for (let value of dests) {
    let data = [dests.gitRepoAccountName, dests.pat];
    let obj = { sendItem: dests, data: data };
    let obj2 = {
        'Repo identifier name': dests.gitRepoAccountName,
        'Access Token': dests.pat
    }
    arr = [...arr, obj]
    // }
    setData(arr)
}

export default function GitRepo() {
    let history = useHistory();
    const [load, setLoad] = useState(false);
    const [exportVal, setExport] = useState([]);
    const [handel, setHandel] = useState(false);
    useEffect(() => {
        setLoad(true)
        ItrApiService.GET({ url: 'CloudConnect/Configuration/GitRepo' }).then(res => {
            if (res.Success == true) {
                if (res.result.isSuccess == true) {
                    // splits2(res.result.data, setData);
                    if (res.result.data) {
                        splits(res.result.data, setData, setExport);
                    }
                }
            }
            setLoad(false)
        })
    }, []);



    const [vals, setVale] = useState([])
    const [data, setData] = useState([]);
    return (
        <div>
            <NxLoader state={load} />
            {/* <Headers /> */}
            {/* <VxBreadcrumb className="navigation-breadcrumb" data={[
                {
                    name: 'Home',
                    onClick: () => {
                        history.push('/partner-board');
                    }
                }
            ]} /> */}
            <div className="customer-dynamic-pages pt-0">
                {/* <NxTitleTab className="title-tab page-title" title='Azure Connect' onCloseClick={() => {
                    history.goBack();
                }} /> */}

                <div className="onboarding-main mt-1">
                    <div className="d-flex justify-content-between align-items-center">
                        <NxActionTab className="resource-groups-list-action-tab px-0"
                            exportValue={exportVal}
                            fileName='Git repo'
                            onRefreshClick={() => {
                                setLoad(true)
                                ItrApiService.GET({ url: 'CloudConnect/Configuration/GitRepo' }).then(res => {
                                    if (res.Success == true) {
                                        if (res.result.isSuccess == true) {
                                            // splits2(res.result.data, setData);
                                            if (res.result.data) {
                                                splits(res.result.data, setData, setExport);
                                            }
                                            else {
                                                setData([])
                                            }
                                        }
                                    }
                                    setLoad(false)
                                })
                            }}
                            onNewEntryClick={() => {
                                history.push('/git-repo/add')
                            }}
                            enableNewEntry={false}
                        />
                        <button className='btn btn-sm' style={{ backgroundColor: 'rgb(82, 196, 26)', color: 'white', fontSize: '14px' }} onClick={() => history.push('/git-repo/add')}>Add GIT Repo </button>
                    </div>

                    <div className="mb-5">
                        <NxGridList
                            enableSearch={true}
                            autoSearch={true}
                            enableAutoPagination={true}
                            showShowing={true}
                            header={[
                                {
                                    name: 'Repo identifier name',
                                    search: true
                                },
                                {
                                    name: 'Access Token',
                                },

                            ]}
                            rowClick={(data) => {
                                history.push({
                                    pathname: '/git-repo/edit',
                                    state: data
                                })
                            }}
                            data={data}
                        />
                    </div>
                </div>
            </div>

            {/* <NxFooter className="verscio-footer" companyName="© 2022 Ithred Solutions" ExtraContent={[
                { name: 'Terms of Service' },
                { name: 'Privacy' },
            ]} /> */}
        </div>
    )
}