import { NxFooter, NxLoader, NxTitleTab } from '@afiplfeed/itr-app';
import { ItrApiService } from '@afiplfeed/itr-ui';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Countries } from '../../../general/general';
import Headers from '../../../Screens/Headers/Headers';

function AddTaxation() {
    const [cityData, setCityData] = useState(Countries);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const [cked, setCked] = useState(false);
    const [cityVal, setCityVal] = useState([]);
    const [data, setData] = useState([]);
    const [orginCity, setOrginCity] = useState(0);


    useEffect(() => {
        setLoading(true)
        ItrApiService.GET({ url: "RevOps/Country/GetCountries" }).then(res => {
            if (res.Success == true) {
                if (res.result.isSuccess == true) {
                    if (res.result.data && res.result.data.length > 0) {
                        setData(res.result.data);
                        let arr = res.result.data;
                        const arrWithValue = arr.map(object => {
                            return { taxationID: 0, taxationMapID: 0, billDestinationCountryID: object.countryID, countryName: object.countryName, tax: 0 };
                        });
                        setCityVal(arrWithValue);
                    }
                }
            }
            setLoading(false)
        })
    }, [])

    return (
        <div className="mb-5" style={{ backgroundColor: '#fff' }}>
            <Headers />
            <NxLoader state={loading} />
            <NxFooter />

            <div className="card1 mx-1 mt-0 taxation-main">

                <div className="card-body1" >
                    <div className="container-form mb-5">
                        <div>
                            <NxTitleTab title="Add 3D Taxation" className="title-tab px-0" onCloseClick={() => { history.goBack() }} />
                            <div className="col-12 col-sm-4 col-md-6 col-lg-6 col-xl-6 mt-4 px-4">
                                <label className='fw-bold f-badge-title' style={{ fontSize: '16px' }}>Billing Origination</label>
                                <select className="form-select form-select-sm" value={orginCity} onChange={(e) => {
                                    setOrginCity(parseInt(e.target.value));
                                }}>
                                    <option value={0} selected disabled> -- Please Select -- </option>
                                    {data.map((item, index) => {
                                        return <option value={item.countryID}>{item.countryName}</option>
                                    })}
                                </select>
                            </div>

                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-5 px-4">
                                <div className='table-hover table-wrapper-scroll-y table-responsive my-custom-scrollbar'>
                                    <table class="table table-bordered  mb-0 w-75">
                                        <thead>
                                            <tr>
                                                <th scope="col" style={{ fontSize: '16px' }}>Billing Destination</th>
                                                <th scope="col" style={{ fontSize: '16px' }}>
                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        <span> Tax % </span>
                                                        <div className='d-flex align-items-center'>
                                                            <span className='text-primary' style={{ fontSize: '14px' }}> Copy to All </span>
                                                            <input type="checkbox" className='form-checkbox mx-2' checked={cked} onChange={(e) => {
                                                                setCked(e.target.checked);
                                                                let vals = cityVal[0].tax;
                                                                if (e.target.checked == true) {
                                                                    let newArrs = cityVal.map(obj => {
                                                                        return { ...obj, tax: vals };
                                                                    });
                                                                    setCityVal(newArrs);
                                                                }
                                                            }} />
                                                        </div>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {cityVal.map((data, index) => <tr className=''>
                                                <td style={{ fontSize: '14px' }}>{data.countryName}</td>
                                                <td>
                                                    <input value={data.tax} style={{ fontSize: '14px' }} type='tel' className='form-control form-control-sm' onChange={(e) => {
                                                        if (cked == false) {
                                                            let newArr = [...cityVal];
                                                            newArr[index].tax = e.target.value != '' ? parseFloat(e.target.value) : null;
                                                            setCityVal(newArr);
                                                        } else {
                                                            let newArrs = cityVal.map(obj => {
                                                                return { ...obj, tax: e.target.value != '' ? parseFloat(e.target.value) : null };
                                                            });
                                                            setCityVal(newArrs);
                                                        }

                                                    }} />
                                                </td>
                                            </tr>)}
                                        </tbody>
                                    </table>
                                </div>
                                <div className='mt-2 mb-5 text-end'>
                                    <button className='btn btn-primary btn-sm' onClick={() => {
                                        let arr = cityVal;
                                        const newArr = arr.map(object => {
                                            delete object.countryName
                                            return object;
                                        });
                                        setLoading(true);
                                        ItrApiService.POST({
                                            url: 'RevOps/Taxation/SaveTaxation',
                                            data: {
                                                "taxationID": 0,
                                                "billOriginCountryID": orginCity,
                                                "taxationMaps": newArr
                                            }
                                        }).then(rest => {
                                            setLoading(false);
                                            if (rest.Success == true) {
                                                if (rest.result.isSuccess == true) {
                                                    history.goBack()
                                                }
                                            }
                                        })
                                    }}> Save </button>
                                </div>
                            </div>
                        </div>
                        <div className='container'>
                            <div className="row mt-4">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddTaxation;