import React, { useState, useEffect } from "react";
import './style.css';
import { useHistory } from 'react-router-dom';
import { ItrApiService } from '@afiplfeed/itr-ui';
import { NxBreadcrumb, NxTitleTab, NxCustomTab, NxLoader, NxFooter } from '@afiplfeed/itr-app';
import Headers from "../../Screens/Headers/Headers";
import { message } from 'antd';
import { NotificationManager } from "react-notifications";
import { emailValid } from '../../config/Validation';

export default function UserEntry() {

    const Buttons = () => {
        return <div className="form-button nx-enquiry-buttons mb-5">
            <button className="btn btn-primary rounded-pill buttons" onClick={() => {
                if (enquiry.email == '' || vald1.email == false || enquiry.companyName == '' || enquiry.companyName == false || !enquiry.phone || enquiry.phone == '' || !enquiry.fullName || enquiry.fullName == '') {
                    let obj = {
                        email: true,
                        companyName: true,
                        phone: true,
                        fullName: true
                    }
                    if (enquiry.email == '' || vald1.email == false) {
                        obj = { ...obj, email: false };
                    }
                    if (enquiry.companyName == '' || enquiry.companyName == false) {
                        obj = { ...obj, companyName: false };
                    }
                    if (!enquiry.phone || enquiry.phone == '') {
                        obj = { ...obj, phone: false };
                    }
                    if (!enquiry.fullName || enquiry.fullName == '') {
                        obj = { ...obj, fullName: false }
                    }
                    setVald(obj);
                } else {
                    setVald({ email: true, companyName: true });
                    setLoading(true);
                    ItrApiService.POST({
                        url: 'RevOps/Enquiry/SaveEnquiry',
                        data: enquiry
                    }).then((res) => {
                        if (res.Success == true) {
                            if (res.result.isSuccess == true) {
                                message.success('Successfully Created')
                                history.push('/customers')
                            }
                        }
                        setLoading(false)
                    })
                }
            }}> Save</button>
            <button className="btn close btn-outline-primary rounded-pill" onClick={() => history.goBack()}> Close</button>
        </div>
    }

    const [loading, setLoading] = useState(false);

    // cities
    const [allCities, setAllCities] = useState([]);
    const getCities = async () => {
        let result = await ItrApiService.GET({
            url: 'Platform/City/GetAllCities'
        });
        if (result.result.isSuccess == true) {
            let getCustomerDetails = result.result.data;
            setAllCities(getCustomerDetails);
        }
        else if (result.result.isSuccess == false) {
            NotificationManager.error(result.result.message);
            // sessionStorage.clear();
            // history.push('/')
        }
    }


    // states
    const [allStates, setAllStates] = useState([]);
    const getStates = async () => {
        let result = await ItrApiService.GET({
            url: 'Platform/State/GetAllStates'
        });
        if (result.result.isSuccess == true) {
            let getCustomerDetails = result.result.data;
            setAllStates(getCustomerDetails);
        }
        else if (result.result.isSuccess == false) {
            NotificationManager.error(result.result.message);
            // sessionStorage.clear();
            // history.push('/')
        }
    }


    // country
    const [allCountries, setAllCountries] = useState([]);
    const getCountry = async () => {
        let result = await ItrApiService.GET({
            url: 'Platform/Country/GetAllCountries'
        });
        if (result.result.isSuccess == true) {
            let getCustomerDetails = result.result.data;
            setAllCountries(getCustomerDetails);
        }
        else if (result.result.isSuccess == false) {
            NotificationManager.error(result.result.message);
            // sessionStorage.clear();
            // history.push('/')
        }
    }


    // pincode
    const [allPinCode, setAllPinCode] = useState([]);
    const getZipCodes = async () => {
        let result = await ItrApiService.GET({
            url: 'Platform/ZipCode/GetAllZipCodes'
        });
        if (result.result.isSuccess == true) {
            let getCustomerDetails = result.result.data;
            setAllPinCode(getCustomerDetails);
        }
        else if (result.result.isSuccess == false) {
            NotificationManager.error(result.result.message);
            // sessionStorage.clear();
            // history.push('/')
        }
    }


    // AllUDo`s
    let UdoData = ["Enquiry Status", "Nature Of Business", "Industry", "Ownership", "Source"];
    const [allUdo, setAllUdo] = useState([]);
    const getUdo = async () => {
        let result = await ItrApiService.POST({
            url: 'Platform/UserDefined/AllUDOs',
            data: UdoData
        });
        if (result.result.isSuccess == true) {
            let getCustomerDetails = result.result.data;
            let getOptions = getCustomerDetails[1].Options;
            setAllUdo(getOptions);
        }
        else if (result.result.isSuccess == false) {
            NotificationManager.error(result.result.message);
            // sessionStorage.clear();
            // history.push('/')
        }
    }

    // Partners
    const [allPartners, setAllPartners] = useState([]);
    const getPartners = async () => {
        let result = await ItrApiService.GET({
            url: 'RevOps/PartnerInformation'
        });
        if (result.result.isSuccess == true) {
            let getPartnerDetails = result.result.data;
            setAllPartners(getPartnerDetails);
        }
        else if (result.result.isSuccess == false) {
            NotificationManager.error(result.result.message);
        }
    }

    const initiate = async () => {
        setLoading(true);
        await getUdo();
        await getPartners();
        // await getCities();
        // await getCountry();
        // await getZipCodes();
        // await getStates();
        setLoading(false);
    }


    useEffect(() => {
        initiate()
    }, []);


    const [enquiry, setEnquiry] = useState({
        enquiryID: 0,
        enquiryCode: "",
        fullName: "",
        companyName: "",
        email: "",
        phone: "",
        sourcedBy: "",
        timeToBuy: "",
        role: "",
        status: 0,
        partnerID: 0,
        feedback: "",
        isMovedToOnboarding: false,
        isActive: true,
        statusName: "",
        partnerName: ""
    });



    const [vald1, setVald] = useState({
        email: true,
        companyName: true,
        phone: true,
        fullName: true
    })







    let history = useHistory();
    const [handel, setHandel] = useState(false);
    return (
        <div>
            <NxLoader state={loading} />
            <Headers />
            {/* <NxBreadcrumb className="navigation-breadcrumb" data={[
                {
                    name: 'Home',
                    onClick: () => {
                        history.push('/partner-board');
                    }
                },
                {
                    name: 'Enquiry List',
                    onClick: () => {
                        history.push('/enquiry');
                    }
                }
            ]} /> */}
            <NxTitleTab className="title-tab" title='Add Enquiry' onCloseClick={() => {
                history.goBack();
            }} />
            <NxCustomTab
                data={[
                    {
                        name: "Basic Information",
                        Components: () => {
                            return (
                                <div className="container-form">
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <div className="row">
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                    <label htmlFor="firstName">Full Name</label>
                                                    <input className="form-control form-control-sm" id="firstName" type="text" value={enquiry.fullName}
                                                        onChange={(e) => setEnquiry({ ...enquiry, fullName: e.target.value })} />
                                                    <div class={vald1.fullName == false ? "invalid-data" : 'd-none'}>
                                                        Please Enter Full Name.
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                    <label htmlFor="lastName">Company Name</label>
                                                    <input className="form-control form-control-sm" id="lastName" type="text" value={enquiry.companyName}
                                                        onChange={(e) => setEnquiry({ ...enquiry, companyName: e.target.value })} />
                                                    <div class={vald1.companyName == false ? "invalid-data" : 'd-none'}>
                                                        Please Enter Company Name.
                                                    </div>

                                                </div>

                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                    <label htmlFor="Email">Email</label>
                                                    <input className="form-control form-control-sm" id="Email" type="text" value={enquiry.email}
                                                        onChange={(e) => {
                                                            setEnquiry({ ...enquiry, email: e.target.value })
                                                            emailValid(e.target.value).then(res => {
                                                                if (!res) {
                                                                    setVald({
                                                                        ...vald1, email: false
                                                                    })
                                                                } else {
                                                                    setVald({
                                                                        ...vald1, email: true
                                                                    })
                                                                }
                                                            });
                                                        }} />
                                                    <div class={vald1.email == false ? "invalid-data" : 'd-none'}>
                                                        Enter the correct Email Formate.
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                    <label htmlFor="Phone">Phone</label>
                                                    <input className="form-control form-control-sm" id="Phone" type="text" value={enquiry.phone}
                                                        minLength="10" maxLength="15"
                                                        onChange={(e) => { setEnquiry({ ...enquiry, phone: e.target.value }); }} />
                                                    <div class={vald1.phone == false ? "invalid-data" : 'd-none'}>
                                                        Please Enter Phone Name.
                                                    </div>
                                                </div>

                                                {/* <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                    <label htmlFor="aboutus">Where did you hear about us?</label>
                                                    <select class="form-select form-select-sm" id="aboutus">
                                                        <option value="" selected hidden></option>
                                                        <option disabled>--Please-select--</option>
                                                        <option value="Google" >Google</option>
                                                        <option value="Events" >Events</option>
                                                        <option value="Conferance" >Conferance</option>
                                                        <option value="Blog" >Blog</option>
                                                        <option value="Referance" >Referance</option>
                                                    </select>
                                                </div> */}

                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                    <label htmlFor="role">Role</label>
                                                    <select class="form-select form-select-sm" aria-label="Default select example" id="role" value={enquiry.role}
                                                        onChange={(e) => setEnquiry({ ...enquiry, role: e.target.value })}>
                                                        <option value="" selected hidden></option>
                                                        <option disabled>--Please-select--</option>
                                                        <option value="Consultant" >Consultant</option>
                                                        <option value="Influencer" >Influencer</option>
                                                        <option value="Decision maker" >Decision maker</option>
                                                        <option value="Approver" >Approver</option>
                                                        <option value="Sponser" >Sponser</option>
                                                    </select>
                                                </div>

                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                    <label htmlFor="timeToBuy">Time to Buy</label>
                                                    <select class="form-select form-select-sm" id="timeToBuy" value={enquiry.timeToBuy}
                                                        onChange={(e) => setEnquiry({ ...enquiry, timeToBuy: e.target.value })}>
                                                        <option value="" selected hidden></option>
                                                        <option disabled>--Please-select--</option>
                                                        <option value="Immediately" >Immediately</option>
                                                        <option value="Within 30 days" >Within 30 days</option>
                                                        <option value="Within 60 days" >Within 60 days</option>
                                                        <option value="Within 90 days" >Within 90 days</option>
                                                        <option value="Just enquiring" >Just Enquiring</option>
                                                    </select>
                                                </div>

                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                    <label htmlFor="firstName">Source</label>
                                                    <select class="form-select form-select-sm" aria-label="Default select example" value={enquiry.sourcedBy}
                                                        onChange={(e) => setEnquiry({ ...enquiry, sourcedBy: e.target.value })}>
                                                        <option value="" selected hidden></option>
                                                        <option disabled>--Please-select--</option>
                                                        <option value="Advertisement" >Advertisement</option>
                                                        <option value="Cold Call" >Cold Call</option>
                                                        <option value="Employee Referal" >Employee Referal</option>
                                                        <option value="External Referal" >External Referal</option>
                                                        <option value="Online" >Online</option>
                                                        <option value="Partner" >Partner</option>
                                                        <option value="Public Relations" >Public Relations</option>
                                                        <option value="Sales Mail" >Sales Mail</option>
                                                        <option value="Seminar Partner" >Seminar Partner</option>
                                                        <option value="Seminar Internal" >Seminar Internal</option>
                                                        <option value="Trade Show" >Trade Show</option>
                                                        <option value="Database" >Database</option>
                                                    </select>
                                                </div>

                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                    <label htmlFor="firstName">Status</label>
                                                    <select class="form-select form-select-sm" aria-label="Default select example" value={enquiry.status}
                                                        onChange={(e) => setEnquiry({ ...enquiry, status: e.target.value })}>
                                                        <option value="" selected hidden></option>
                                                        <option disabled>--Please-select--</option>
                                                        {allUdo && allUdo.map((data, index) => {
                                                            return <option key={index} hidden={data.UDOptionName == 'Acquired'} value={data.UDOptionID}> {data.UDOptionName} </option>
                                                        })}
                                                    </select>
                                                </div>

                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2" hidden={enquiry?.sourcedBy == 'Partner' ? false : true}>
                                                    <label htmlFor="partner">Partner</label>
                                                    <select class="form-select form-select-sm" aria-label="Default select example" value={enquiry.partnerID}
                                                        onChange={(e) => setEnquiry({ ...enquiry, partnerID: e.target.value })}>
                                                        <option value="" selected hidden></option>
                                                        <option disabled>--Please-select--</option>
                                                        {allPartners && allPartners.map((data, index) => {
                                                            return <option key={index} value={data.partnerInformationID}> {data.partnerName} </option>
                                                        })}
                                                    </select>
                                                </div>

                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                                                    <label htmlFor="feedback">Feedback</label>
                                                    <textarea className="form-control form-control-sm" id="feedback" value={enquiry.feedback}
                                                        onChange={(e) => setEnquiry({ ...enquiry, feedback: e.target.value })} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />

                                    <Buttons />
                                </div>
                            )
                        }
                    }
                    //                     {
                    //                         name: "Organization",
                    //                         Components: () => <div className="container-form">
                    //                         <div className="row">
                    //                             <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-2">
                    //                                 <label htmlFor="Account-Name">Account Name</label>
                    //                                 <input className="form-control mt-1" id="Account-Name" type="text" />
                    //                             </div>
                    // 
                    //                             <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-2">
                    //                                 <label htmlFor="firstName">Ownership</label>
                    //                                 <select class="form-select mt-1" aria-label="Default select example" placeholder="">
                    //                                     <option value="" selected hidden></option>
                    //                                     <option value="" >--Please-select--</option>
                    //                                 </select>
                    //                             </div>
                    // 
                    //                             <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-2">
                    //                                 <label htmlFor="Address-Line-1">Address Line 1</label>
                    //                                 <input className="form-control mt-1" id="Address-Line-1" type="text" />
                    //                             </div>
                    // 
                    //                             <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-2">
                    //                                 <label htmlFor="firstName">Parent Account</label>
                    //                                 <select class="form-select mt-1" aria-label="Default select example" placeholder="">
                    //                                     <option value="" selected hidden></option>
                    //                                     <option value="" >--Please-select--</option>
                    //                                 </select>
                    //                             </div>
                    // 
                    //                             <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-2">
                    //                                 <label htmlFor="firstName">Industry</label>
                    //                                 <select class="form-select mt-1" aria-label="Default select example" placeholder="">
                    //                                     <option value="" selected hidden></option>
                    //                                     <option value="" >--Please-select--</option>
                    //                                 </select>
                    //                             </div>
                    // 
                    //                             <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-2">
                    //                                 <label htmlFor="Address-Line-2">Address Line 2</label>
                    //                                 <input className="form-control mt-1" id="Address-Line-2" type="text" />
                    //                             </div>
                    // 
                    //                             <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-2">
                    //                                 <label htmlFor="firstName">Source</label>
                    //                                 <select class="form-select mt-1" aria-label="Default select example" placeholder="">
                    //                                     <option value="" selected hidden></option>
                    //                                     <option disabled>--Please-select--</option>
                    //                                     <option value="Advertisement" >Advertisement</option>
                    //                                     <option value="Cold Call" >Cold Call</option>
                    //                                     <option value="Employee Referal" >Employee Referal</option>
                    //                                     <option value="External Referal" >External Referal</option>
                    //                                     <option value="Online" >Online</option>
                    //                                     <option value="Partner" >Partner</option>
                    //                                     <option value="Public Relations" >Public Relations</option>
                    //                                     <option value="Sales Mail" >Sales Mail</option>
                    //                                     <option value="Seminar Partner" >Seminar Partner</option>
                    //                                     <option value="Seminar Internal" >Seminar Internal</option>
                    //                                     <option value="Trade Show" >Trade Show</option>
                    //                                     <option value="Database" >Database</option>
                    // 
                    //                                 </select>
                    //                             </div>
                    // 
                    //                             <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-2">
                    //                                 <label htmlFor="firstName">Nature Of Business</label>
                    //                                 <select class="form-select mt-1" aria-label="Default select example" placeholder="">
                    //                                     <option value="" selected hidden></option>
                    //                                     <option disabled>--Please-select--</option>
                    //                                     <option value="Business 01" >Business 01</option>
                    //                                     <option value="Business 02" >Business 02</option>
                    //                                 </select>
                    //                             </div>
                    // 
                    //                             <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-2">
                    //                                 <div className="row">
                    //                                     <div className="col-6 col-sm-6 col-lg-6 col-xl-6 col-md-6">
                    //                                         <label htmlFor="firstName">City</label>
                    //                                         <select class="form-select mt-1" aria-label="Default select example" placeholder="">
                    //                                             <option value="" selected hidden></option>
                    //                                             <option value="" >--Please-select--</option>
                    //                                             {allCities && allCities.map((data,index) => {
                    //                                                 return <option key={index} value={data.cityName}> {data.cityName} </option> 
                    //                                             })}
                    //                                         </select>
                    //                                     </div>
                    // 
                    //                                     <div className="col-6 col-sm-6 col-lg-6 col-xl-6 col-md-6">
                    //                                         <label htmlFor="firstName">State</label>
                    //                                         <select class="form-select mt-1" aria-label="Default select example" placeholder="">
                    //                                             <option value="" selected hidden></option>
                    //                                             <option value="" >--Please-select--</option>
                    //                                             {allStates && allStates.map((data,index) => {
                    //                                                 return <option key={index} value={data.stateName}> {data.stateName} </option> 
                    //                                             })}
                    //                                         </select>
                    //                                     </div>
                    //                                 </div>
                    //                             </div>
                    // 
                    //                             <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-2">
                    //                                 <label htmlFor="firstName">No Of Employees</label>
                    //                                 <input className="form-control mt-1" id="firstName" type="text" />
                    //                             </div>
                    // 
                    //                             <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-2">
                    //                                 <label htmlFor="firstName">Annual Revenue</label>
                    //                                 <input className="form-control mt-1" id="firstName" type="text" />
                    //                             </div>
                    // 
                    //                             <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-2">
                    //                                 <div className="row">
                    //                                     <div className="col-6 col-sm-6 col-lg-6 col-xl-6 col-md-6">
                    //                                         <label htmlFor="firstName">Country</label>
                    //                                         <select class="form-select mt-1" aria-label="Default select example">
                    //                                             <option value="" selected hidden></option>
                    //                                             <option value="" >--Please-select--</option>
                    //                                             {allCountries && allCountries.map((data,index) => {
                    //                                                 return <option key={index} value={data.countryName}> {data.countryName} </option> 
                    //                                             })}
                    //                                         </select>
                    //                                     </div>
                    // 
                    //                                     <div className="col-6 col-sm-6 col-lg-6 col-xl-6 col-md-6">
                    //                                         <label htmlFor="firstName">Pin Code</label>
                    //                                         <select class="form-select mt-1" aria-label="Default select example">
                    //                                             <option value="" selected hidden></option>
                    //                                             <option value="" >--Please-select--</option>
                    //                                             {allPinCode && allPinCode.map((data,index) => {
                    //                                                 return <option key={index} value={data.zipcode}> {data.zipcode} </option> 
                    //                                             })}
                    //                                         </select>
                    //                                     </div>
                    //                                 </div>
                    //                             </div>
                    //                         </div>
                    // 
                    //                         <div className="row mt-2 d-flex align-items-start">
                    //                                 <div className="col-12 col-sm-3 col-md-3 col-xl-4 col-lg-4">
                    //                                     <label htmlFor="firstName">Description</label>
                    //                                     <textarea className="form-control mt-1" id="firstName" />
                    //                                 </div>
                    // 
                    //                                 <div className="col-12 col-sm-3 col-md-3 col-xl-4 col-lg-4">
                    //                                     <label htmlFor="firstName">Website</label>
                    //                                     <input type="text" className="form-control mt-1" id="firstName" />
                    //                                 </div>
                    // 
                    //                                 <div className="col-12 col-sm-3 col-md-3 col-xl-4 col-lg-4">
                    //                                     
                    //                                 </div>
                    // 
                    //                                 <div className="col-12 col-sm-3 col-md-3 col-xl-4 col-lg-4">
                    //                                     
                    //                                 </div>
                    //                             </div>
                    //                         <hr/>
                    // 
                    //                         <Buttons/>
                    //                     </div>
                    //                     },
                    //                     {
                    //                         name: "Contacts",
                    //                         Components: () => <div className="container-form enquiry-contacts">
                    //                             <div className="mt-4">
                    //                                 <label className="contacts1 contacts">Administrative Contact</label>
                    //                         <div className="row pt-2">
                    //                             <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-2">
                    //                                 <label htmlFor="firstName">First Name</label>
                    //                                 <input className="form-control mt-1" id="firstName" type="text" />
                    //                             </div>
                    // 
                    //                             <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-2">
                    //                                 <label htmlFor="firstName">Designation</label>
                    //                                 <input className="form-control mt-1" id="firstName" type="text" />
                    //                             </div>
                    // 
                    //                             <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-2">
                    //                                 <div className="row">
                    //                                     <div className="col-6 col-sm-6 col-lg-6 col-xl-6 col-md-6">
                    //                                         <label htmlFor="firstName">Phone</label>
                    //                                         <input className="form-control mt-1" id="firstName" type="text" />
                    //                                     </div>
                    // 
                    //                                     <div className="col-6 col-sm-6 col-lg-6 col-xl-6 col-md-6">
                    //                                         <label htmlFor="firstName">Mobile</label>
                    //                                         <input className="form-control mt-1" id="firstName" type="text" />
                    //                                     </div>
                    //                                 </div>
                    //                             </div>
                    // 
                    //                             <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-2">
                    //                                 <label htmlFor="firstName">Last Name</label>
                    //                                 <select class="form-select mt-1" aria-label="Default select example" placeholder="">
                    //                                     <option value="" selected hidden></option>
                    //                                     <option value="" >--Please-select--</option>
                    //                                 </select>
                    //                             </div>
                    // 
                    //                             <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-2">
                    //                                 <label htmlFor="firstName">Department</label>
                    //                                 <input className="form-control mt-1" id="firstName" type="text" />
                    //                             </div>
                    // 
                    //                             <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-2">
                    //                                 <label htmlFor="firstName">Email</label>
                    //                                 <input className="form-control mt-1" id="firstName" type="text" />
                    //                             </div>
                    //                         </div>
                    //                         </div>
                    // 
                    //                             <div className="mt-4">
                    //                                 <label className="contacts2 contacts">Technical Contact</label>
                    //                         <div className="row pt-2">
                    //                             <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-2">
                    //                                 <label htmlFor="firstName">First Name</label>
                    //                                 <input className="form-control mt-1" id="firstName" type="text" />
                    //                             </div>
                    // 
                    //                             <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-2">
                    //                                 <label htmlFor="firstName">Designation</label>
                    //                                 <input className="form-control mt-1" id="firstName" type="text" />
                    //                             </div>
                    // 
                    //                             <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-2">
                    //                                 <div className="row">
                    //                                     <div className="col-6 col-sm-6 col-lg-6 col-xl-6 col-md-6">
                    //                                         <label htmlFor="firstName">Phone</label>
                    //                                         <input className="form-control mt-1" id="firstName" type="text" />
                    //                                     </div>
                    // 
                    //                                     <div className="col-6 col-sm-6 col-lg-6 col-xl-6 col-md-6">
                    //                                         <label htmlFor="firstName">Mobile</label>
                    //                                         <input className="form-control mt-1" id="firstName" type="text" />
                    //                                     </div>
                    //                                 </div>
                    //                             </div>
                    // 
                    //                             <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-2">
                    //                                 <label htmlFor="firstName">Last Name</label>
                    //                                 <select class="form-select mt-1" aria-label="Default select example" placeholder="">
                    //                                     <option value="" selected hidden></option>
                    //                                     <option value="" >--Please-select--</option>
                    //                                 </select>
                    //                             </div>
                    // 
                    //                             <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-2">
                    //                                 <label htmlFor="firstName">Department</label>
                    //                                 <input className="form-control mt-1" id="firstName" type="text" />
                    //                             </div>
                    // 
                    //                             <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-2">
                    //                                 <label htmlFor="firstName">Email</label>
                    //                                 <input className="form-control mt-1" id="firstName" type="text" />
                    //                             </div>
                    //                         </div>
                    //                         </div>
                    // 
                    //                         <hr/>
                    //                         {/* buttons */}
                    //                         <Buttons/>
                    //                     </div>
                    //                     }
                ]}
            />
            <NxFooter ExtraContent={[
                { name: 'Terms of Service' },
                { name: 'privacy' },
            ]} />
        </div>
    )
}