import React, { useEffect, useState } from 'react';
import { Upload } from 'antd';
import './Style.css'

function FeaturedApps({ isFeaturedApp, setFeaturedApp, spfiles, setspfiles }) {
    const [data, setData] = useState('');


    const Fad = [
        {
            value: true,
            data: 'Yes'
        },
        {
            value: false,
            data: 'No'
        }
    ]

    useEffect(() => {
        setData(isFeaturedApp)
    }, [isFeaturedApp])

    return (
        <div className='p-3'>
            <div className='f-virision-pad-static'>
                <div className='w-100 f-app-div-box-main'>
                    <div className="f-app-div-box" style={{}}>
                        <label htmlFor="FeaturedApp" className='f-badge-title'>Featured App</label>
                        <select className="form-select form-select-sm mt-1" value={data} onChange={(e) => {
                            setData(e.target.value);
                            if (e.target.value == 'true') {
                                setFeaturedApp(true)
                            } else {
                                setFeaturedApp(false)
                            }
                        }} id="FeaturedApp">
                            <option value='' selected disabled>--Please select--</option>
                            {Fad.map((item, index) => {
                                return <option value={item.value} >{item.data}</option>

                            })}
                            {/* // <option value={false} >No</option> */}
                        </select>
                    </div>
                    <div className="mt-2 f-app-div-box">
                    </div>
                </div>
                {isFeaturedApp == true && <div className='w-100 mt-4'>
                    <label className='f-badge-title'>Splash Screen</label>
                    <Upload
                        listType="picture-card"
                        maxCount={15}
                        className='Upload-data'
                        fileList={spfiles}
                        onChange={({ fileList }) => {
                            setspfiles(fileList)
                        }}
                    >
                        + upload
                    </Upload>
                </div>}
            </div>
        </div>
    );
}

export default FeaturedApps;