import { NxTitleTab, NxBreadcrumb, NxFooter } from "@afiplfeed/itr-app";
import React, { useState } from "react";
import { useHistory } from "react-router";
import Headers from "../../../Screens/Headers/Headers";
import { Switch } from 'antd';
import './AddPlatformUser.css';
import { ItrApiService } from "@afiplfeed/itr-ui";



export default function AddPlatformUser() {
    const history = useHistory();
    const [datas, setDatas] = useState({
        firstname: "",
        lastName: "",
        displayName: "",
        email: "",
        gender: "",
        phone: "",
        allowAccess: false
    });

    const [data, setData] = useState({
        partnerUserID: 0,
        partnerID: 0,
        userCode: null,
        userType: null,
        firstName: "",
        lastName: "",
        displayName: "",
        designation: "",
        department: "",
        phoneNo: "",
        mobile: "",
        emailID: "",
        partnerUserContacts: [],
        isActive: true
    })
    return <div className="platform-user-tab">
        <Headers />
        {/* <NxBreadcrumb data={[
            {
                name: 'Home',
                onClick: () => {
                    history.push('/partner-board');
                }
            },
        ]} /> */}
        <NxTitleTab className="title-tab" title='Platform User' onCloseClick={() => {
            history.goBack();
        }} />
        <div className="card m-4 ">
            <div className="card-body  p-0 mb-5">
                <div className="row p-3 ">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <h6>Personal Information</h6>

                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                            <label htmlFor="firstName">First Name</label>
                            <input className="form-control" id="firstName" value={data.firstName} type="text"
                                onChange={(e) => setData({ ...data, firstName: e.target.value })}
                            />
                        </div>

                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                            <label htmlFor="lastName">Last Name</label>
                            <input className="form-control" id="lastName" value={data.lastName} type="text"
                                onChange={(e) => setData({ ...data, lastName: e.target.value })}
                            />
                        </div>

                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                            <label htmlFor="displayName">Display Name</label>
                            <input className="form-control" id="displayName" value={data.displayName} type="text"
                                onChange={(e) => setData({ ...data, displayName: e.target.value })}
                            />
                        </div>

                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                            {/* <label htmlFor="gender">Gender</label>
                            <select className="form-select" id="gender" value={data.gender}
                                onChange={(e) => setData({ ...data, gender: e.target.value })}
                            >
                                <option value="" selected>--Please select--</option>
                                <option value="Male" >Male</option>
                                <option value="Female" >Female</option>
                            </select> */}
                        </div>

                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                            <label htmlFor="allowAccess" className='mt-2'>Allow Access</label>
                            <br />
                            <Switch defaultChecked={data.isActive} onChange={(e) => {
                                setData({ ...data, isActive: e })
                            }} className='mt-2' />
                        </div>
                    </div>

                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <h6>Contact</h6>

                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                            <label htmlFor="email">Email</label>
                            <input className="form-control" id="email" value={data.emailID} type="text"
                                onChange={(e) => setData({ ...data, emailID: e.target.value })}
                            />
                        </div>

                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                            <label htmlFor="phone">Phone Number</label>
                            <input className="form-control" id="phone" minLength="10" maxLength="10" value={data.mobile} type="text"
                                onChange={(e) => setData({ ...data, mobile: e.target.value })}
                            />
                        </div>
                    </div>


                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-3 bt-class-partner-platform">
                        <button className="btn btn-primary rounded-pill buttons mt-2" onClick={() => {
                            ItrApiService.POST({
                                url: 'Platform/Partner/SavePartnerUser',
                                data: data
                            }).then(res => {
                                
                            })
                        }} >Submit</button>
                    </div>
                </div>
            </div>
        </div>
        <NxFooter ExtraContent={[
            { name: 'Terms of Service' },
            { name: 'privacy' },
        ]} />
    </div>
}

