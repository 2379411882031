import React, { useState, useEffect } from "react";
import './CustomerSummary.css';
import { useHistory, useLocation } from 'react-router-dom';
import { ItrApiService, ItrAuthService } from '@afiplfeed/itr-ui';
import { NxBreadcrumb, NxTitleTab, NxCustomTab, NxLoader, NxFooter, NxGridList } from '@afiplfeed/itr-app';
import Headers from "../../Screens/Headers/Headers";
import { Switch } from 'antd';
import { NotificationManager } from "react-notifications";
import InvoiceData from "../../customerBoard/nexTabs/InvoiceData/InvoiceData";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faEye } from "@fortawesome/free-solid-svg-icons";

const splits = async (dests, setData, setIsModalVisible, setSelectedVal) => {
    let arr = [];
    for (let value of dests) {
        value.status = 'New';
        let data = [moment(value.invoicedDate).format('DD/MM/YYYY'), value.entityName, value.invoiceNumber, moment(value.dueDate).format('MM YYYY'), `${value.totalBillAmountWithGST} /-`, moment(value.dueDate).format('DD/MM/YYYY'), '-',
        <div>
            <button className='btn btn-sm' style={{ fontSize: '11px', borderRadius: '0px', color: value.status != 'Partially Paid' ? 'white' : 'black', backgroundColor: value.status == 'New' ? '#99ccff' : value.status == 'Pending' ? '#ff944d' : value.status == 'Paid' ? '#00e74d' : value.status == 'Partially Paid' ? '#ffff66' : value.status == 'Write-Off' ? 'red' : 'gray' }}>
                {value.status.toUpperCase()}
            </button>
        </div>,
        <div style={{ display: 'flex', alignItems: '' }}>
            <span onClick={() => {
                setSelectedVal(value)
                setIsModalVisible(true)
            }}>
                <FontAwesomeIcon icon={faEye} color="black" />
            </span>
        </div>
        ];
        let obj = { sendItem: value, data: data };
        arr = [...arr, obj]
    }
    setData(arr);
}

export default function CustomerSummary({ }) {
    const [loading, setLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    let location = useLocation();
    const [selectedVal, setSelectedVal] = useState({})
    const [summary, setSummary] = useState({});
    const [loader, setLoader] = useState(false);
    const [data, setData] = useState([]);
    const [dataVal, setDataVal] = useState([]);
    const [allUdo, setAllUdo] = useState([]);
    //     const getUdo = async () => {
    //         let result = await ItrApiService.POST({
    //             url: 'Platform/UserDefined/AllUDOs',
    //             data: UdoData
    //         });
    //         if (result.result.isSuccess == true) {
    //             let getCustomerDetails = result.result.data;
    //             let getOptions = getCustomerDetails[1].Options;
    //             for (let val of getCustomerDetails) {
    //                 if (val.CategoryName == 'Industry') setAllUdoIndustry(val.Options);
    //                 if (val.CategoryName == 'Ownership') setAllUdoOwnership(val.Options);
    //             }
    //             setAllUdo(getOptions);
    // 
    //         }
    //         else if (result.result.isSuccess == false) {
    //             NotificationManager.error(result.result.message);
    //             // sessionStorage.clear();
    //             // history.push('/')
    //         }
    //     }
    const [datas, setDatas] = useState({
        "billerInfoID": 0,
        "businessName": "",
        "supportEmail": "",
        "supportPhone": "",
        "businessWebsite": "",
        "businessLogo": "",
        "addressLine1": "",
        "addressLine2": "",
        "city": "",
        "state": "",
        "pinCode": "",
        "country": "",
        "cin": "",
        "pan": "",
        "gst": "",
        "tan": ""
    })

    // const [userData, setuserData] = useState([]);
    // const getCustomerData = async () => {
    //     ItrAuthService.GetUserInfo().then(res => {
    //         setuserData(res.result);
    //     });
    // }

    useEffect(() => {
        setLoading(true);
        // await getUdo();
        let params = null;
        let value = localStorage.getItem('passData');
        if (location.state && location.state.passData) {
            params = location.state.passData
            setSummary(location.state.passData);
        }
        else if (value) {
            params = JSON.parse(value);
            setSummary(JSON.parse(value));
        }
        else {
            history.goBack();
        }
        // await getCustomerData();
         ItrApiService.GET({ url: `RevOps/ServiceBilling/ListAllServiceBilling/${params?.entityCode}` }).then(res => {
            setLoader(false);
            ItrApiService.GET({ url: 'RevOps/BillerInformation' }).then(res1 => {
                if (res1.Success == true) {
                    if (res1.result.isSuccess == true) {
                        if (res1.result.data) {
                            setDatas(res1.result.data);
                        }
                    }
                }
            })
            if (res.Success == true) {
                if (res.result.isSuccess == true) {
                    if (res.result.data) {
                        setDataVal(res.result.data)
                        splits(res.result.data, setData, setIsModalVisible, setSelectedVal);
                    }
                }
            }
        });
        // await getCities();
        // await getCountry();
        // await getZipCodes();
        // await getStates();
        setLoading(false);
    }, []);


    let history = useHistory();
    const [handel, setHandel] = useState(false);

    const [platformAcc, setPlatformAcc] = useState(false);

    const platformAccess = (e) => {
        setLoading(true);
        ItrApiService.POST({
            url: 'RevOps/Entity/PlatformAccess',
            data: {
                entityID: summary.entityID,
                entityCode: summary.entityCode,
                isAccess: e
            }
        }).then(res => {
            if (res.Success == true) {
                if (res.result.isSuccess == true) {
                    setLoading(false);
                    NotificationManager.success('Successfully Updated');
                    history.push('/customers');
                }
                else {
                    setPlatformAcc(false);
                    setLoading(false);
                    NotificationManager.error(res.result.message);
                }
            }
            else {
                setPlatformAcc(false);
                setLoading(false);
                NotificationManager.error(res.message);
            }
        })
    }

    return (
        <div>
            <NxLoader state={loading} />
            <Headers />
            {/* <NxBreadcrumb className="navigation-breadcrumb" data={[
                {
                    name: 'Home',
                    onClick: () => {
                        history.push('/partner-board');
                    }
                },
                {
                    name: 'Enquiry List',
                    onClick: () => {
                        history.push('/enquiry');
                    }
                }
            ]} /> */}
            <NxTitleTab className="title-tab" title={summary?.companyName} onCloseClick={() => {
                history.goBack();
                localStorage.removeItem('passData');
            }} />

            <div className="mx-5 text-end d-flex justify-content-end align-items-center">
                <div className="mt-2 mx-3" >
                    <button className="btn btn-sm btn-primary"
                        onClick={() => history.push({
                            pathname: '/customer/summary/edit',
                            state: location.state && location.state.passData
                        })
                        }
                    >Edit</button>
                </div>
                <div>
                    <span> Platform Access :  </span>
                    <Switch
                        checked={summary?.isActive == true ? true : platformAcc}
                        onChange={(e) => {
                            setPlatformAcc(e);
                            platformAccess(e);
                        }}
                    />
                </div>
            </div>

            <NxCustomTab
                className="mb-5"
                data={[
                    {
                        name: "Company Information",
                        Components: () => {
                            return (
                                <div className="container-form">
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-8 col-lg-7 col-xl-6">
                                            <div className="row">
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                    <label htmlFor="lastName">Company Name</label>
                                                    <p className="summary-data">  {summary.companyName}</p>
                                                </div>

                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                    <label htmlFor="firstName">Full Name</label>
                                                    <p className="summary-data">  {summary.fullName}</p>
                                                </div>

                                                <div className="col-12 col-sm-6 col-md-6 col-lg-8 col-xl-6 mt-2">
                                                    <label htmlFor="Email">Email</label>
                                                    <p className="summary-data">  {summary.email}</p>
                                                </div>

                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                    <label htmlFor="Phone">Phone</label>
                                                    <p className="summary-data">  {summary.phone}</p>
                                                </div>

                                                {/* role */}
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                    <label htmlFor="Phone">Role</label>
                                                    <p className="summary-data">  {summary.role}</p>
                                                </div>

                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                    <label htmlFor="Phone">Time to Buy</label>
                                                    <p className="summary-data">  {summary.timeToBuy}</p>
                                                </div>

                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                    <label htmlFor="Phone">Source</label>
                                                    <p className="summary-data">  {summary.sourcedBy}</p>
                                                </div>

                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                    <label htmlFor="Phone">Status</label>
                                                    {/* <select class="form-select form-select-sm" aria-label="Default select example" value={editOnboarding.status}
                                                        onChange={(e) => setEditOnboarding({ ...editOnboarding, status: e.target.value })}>
                                                        <option disabled>--Please-select--</option>
                                                        {allUdo && allUdo.map((data, index) => {
                                                            return <option key={index} value={data.UDOptionName}> {data.UDOptionName} </option>
                                                        })}
                                                    </select> */}
                                                    {/* {allUdo && allUdo.map((data, index) => {
                                                        return <p className="summary-data">  {data.UDOptionName}</p>
                                                    })} */}
                                                    {/* <p className="summary-data">  {summary.status}</p> */}
                                                    <p className="summary-data">  {summary.isActive == true ? "Active" : "In-Active"} </p>
                                                </div>

                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2 mb-5" hidden={summary.sourcedBy == 'Partner' ? false : true}>
                                                    <label htmlFor="partner">Partner</label>
                                                    <p className="summary-data">  {summary.partnerName ? summary.partnerName : '-'} </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    },
                    {
                        name: "Contact Information",
                        Components: () => <div className="container-form">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-2">
                                    <div className="d-flex justify-content-between align-content-center">
                                        <label className="contacts1 contacts fw-bold">Technical Contact</label>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                            <label htmlFor="firstName">First Name</label>
                                            <p> {summary?.technicalContact?.firstName ? summary?.technicalContact?.firstName : '-'} </p>
                                        </div>

                                        <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                            <label htmlFor="lastName">Last Name</label>
                                            <p> {summary?.technicalContact?.lastName ? summary?.technicalContact?.lastName : '-'} </p>
                                        </div>

                                        <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                            <label htmlFor="firstName">Designation</label>
                                            <p> {summary?.technicalContact?.designation ? summary?.technicalContact?.designation : '-'} </p>
                                        </div>

                                        <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                            <label htmlFor="firstName">Department</label>
                                            <p> {summary?.technicalContact?.department ? summary?.technicalContact?.department : '-'} </p>
                                        </div>

                                        <div className="col-12 col-sm-6 col-lg-12 col-xl-12 col-md-12 mt-2">
                                            <label htmlFor="firstName">Phone</label>
                                            <p> {summary?.technicalContact?.phoneNo ? summary?.technicalContact?.phoneNo : '-'} </p>
                                        </div>

                                        <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                            <label htmlFor="firstName">Email</label>
                                            <p> {summary?.technicalContact?.emailID ? summary?.technicalContact?.emailID : '-'} </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-2">
                                    <div className="d-flex justify-content-between align-content-center">
                                        <label className="contacts1 contacts fw-bold">Administrative Contact</label>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                            <label htmlFor="firstName">First Name</label>
                                            <p> {summary?.administrativeContact?.firstName ? summary?.administrativeContact?.firstName : '-'} </p>
                                        </div>

                                        <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                            <label htmlFor="lastName">Last Name</label>
                                            <p> {summary?.administrativeContact?.lastName ? summary?.administrativeContact?.lastName : '-'} </p>
                                        </div>

                                        <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                            <label htmlFor="firstName">Designation</label>
                                            <p> {summary?.administrativeContact?.designation ? summary?.administrativeContact?.designation : '-'} </p>
                                        </div>

                                        <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                            <label htmlFor="firstName">Department</label>
                                            <p> {summary?.administrativeContact?.department ? summary?.administrativeContact?.department : '-'} </p>
                                        </div>

                                        <div className="col-12 col-sm-6 col-lg-12 col-xl-12 col-md-12 mt-2">
                                            <label htmlFor="firstName">Phone</label>
                                            <p> {summary?.administrativeContact?.phoneNo ? summary?.administrativeContact?.phoneNo : '-'} </p>
                                        </div>

                                        <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                            <label htmlFor="firstName">Email</label>
                                            <p> {summary?.administrativeContact?.emailID ? summary?.administrativeContact?.emailID : '-'} </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-2">
                                    <label className="contacts1 contacts fw-bold">Billing Contact</label>
                                    <div className="row">
                                        <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                            <label htmlFor="firstName">First Name</label>
                                            <p> {summary?.billingContact?.firstName ? summary?.billingContact?.firstName : '-'} </p>
                                        </div>

                                        <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                            <label htmlFor="lastName">Last Name</label>
                                            <p> {summary?.billingContact?.lastName ? summary?.billingContact?.lastName : '-'} </p>
                                        </div>

                                        <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                            <label htmlFor="firstName">Designation</label>
                                            <p> {summary?.billingContact?.designation ? summary?.billingContact?.designation : '-'} </p>
                                        </div>

                                        <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                            <label htmlFor="firstName">Department</label>
                                            <p> {summary?.billingContact?.department ? summary?.billingContact?.department : '-'} </p>
                                        </div>

                                        <div className="col-12 col-sm-6 col-lg-12 col-xl-12 col-md-12 mt-2">
                                            <label htmlFor="firstName">Phone</label>
                                            <p> {summary?.billingContact?.phoneNo ? summary?.billingContact?.phoneNo : '-'} </p>
                                        </div>

                                        <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2 mb-5">
                                            <label htmlFor="firstName">Email</label>
                                            <p> {summary?.billingContact?.emailID ? summary?.billingContact?.emailID : '-'} </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    },
                    {
                        name: "Commercial Information",
                        Components: () => <div className="container-form">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                                            <label >Company identification number (CIN)</label>
                                            <p className="summary-data">  {summary.cin}</p>
                                        </div>

                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                                            <label >Business PAN (Permanent Account Number)</label>
                                            <p className="summary-data">  {summary.pan}</p>
                                        </div>

                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                                            <label >Goods & Services Tax (GST) Identification number</label>
                                            <p className="summary-data">  {summary.gst}</p>
                                        </div>

                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                                            <label htmlFor="Phone">Tax deduction account number (TAN)</label>
                                            <p className="summary-data">  {summary.tan}</p>
                                        </div>

                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2 mb-5">
                                            <label htmlFor="Phone">Currency</label>
                                            <p className="summary-data"> {summary.currencyName}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                                            <label >Agreement</label>
                                            <p className="summary-data">
                                                {summary?.entityBoarding?.agreement ? <a href={summary?.entityBoarding?.agreement ? summary?.entityBoarding?.agreement : '-'} download="agreement" target="_blank">
                                                    Click to download <FontAwesomeIcon icon={faDownload} size='xs' />
                                                </a> : '-'}
                                            </p>
                                        </div>

                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                                            <label >NCNDA</label>
                                            <p className="summary-data">
                                                {summary?.entityBoarding?.ncnda ? <a href={summary?.entityBoarding?.ncnda ? summary?.entityBoarding?.ncnda : '-'} download="ncnda" target="_blank">
                                                    Click to download <FontAwesomeIcon icon={faDownload} size='xs' />
                                                </a> : '-'}
                                            </p>
                                        </div>

                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2 mb-5">
                                            <label >Terms of use</label>
                                            <p className="summary-data">
                                                {summary?.entityBoarding?.termsOfUse ? <a href={summary?.entityBoarding?.termsOfUse ? summary?.entityBoarding?.termsOfUse : '-'} download="terms of use" target="_blank">
                                                    Click to download <FontAwesomeIcon icon={faDownload} size='xs' />
                                                </a> : '-'}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    },
                    {
                        name: "Invoices",
                        Components: () => <div className="container-form">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-5">
                                    <div className="invoice-table">
                                        <NxGridList
                                            className='mt-3'
                                            enableSearch={true}
                                            autoSearch={true}
                                            enableAutoPagination={true}
                                            showShowing={true}
                                            header={[
                                                {
                                                    name: 'DATE',
                                                    sort: true,
                                                },
                                                {
                                                    name: 'COMPANY NAME',
                                                    sort: false,
                                                },
                                                {
                                                    name: 'INV.NUMBER',
                                                    sort: false,
                                                },
                                                {
                                                    name: 'BILLING PERIOD',
                                                    sort: false,
                                                },
                                                {
                                                    name: 'AMOUNT',
                                                    // sort: true,
                                                },
                                                {
                                                    name: 'DUE BY',
                                                    search: true,
                                                },
                                                {
                                                    name: 'PAID ON',
                                                    sort: false,
                                                },
                                                {
                                                    name: 'STATUS',
                                                    // sort: true,
                                                },

                                            ]}
                                            rowClick={(e) => {
                                            }}
                                            data={data}
                                        />
                                        <InvoiceData companyData={datas} invoiceData={selectedVal} isModalVisible={isModalVisible}
                                            handleOk={() => {
                                                setIsModalVisible(false);
                                            }}
                                            handleCancel={() => {
                                                setIsModalVisible(false);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                ]}
            />
            <NxFooter companyName="© 2022 Ithred Solutions" ExtraContent={[
                { name: 'Terms of Service' },
                { name: 'privacy' },
            ]} />
        </div>
    )
}