import React, { useEffect, useState } from 'react';
import { Drawer } from 'antd';
import './CustomerSidebar.css';
import { NavLink } from 'react-router-dom';
import '../../partnerBoard/dashboard/dashboard.css';
import '../../components/header/header.css';
// new-icons
import dashboard from '../../assets/dashboard.png';
import users from '../../assets/user.png';
import performance from '../../assets/performance.png';
import appsStore from '../../assets/appStore.png';
import billing from '../../assets/billing.png';
import support from '../../assets/support.png';
import settings from '../../assets/settings.png';
import customers from '../../assets/customers.png';
import codeCast from '../../assets/codeCast.png';
import Hotkeys from 'react-hot-keys';
import { NotificationManager } from "react-notifications";
import { ItrAuthService } from '@afiplfeed/itr-ui';
import { Tooltip } from '@material-ui/core';

// new-icons
import users1 from "../../assets/Icons/user.svg";
import deployment from "../../assets/deployment.svg";
import configuration from "../../assets/configuration.svg";
import marketPlace from "../../assets/Icons/marketPlace.svg";
import supports from "../../assets/Icons/support.svg";

import identity from "../../assets/Icons/identity.svg";
import enrollment from "../../assets/Icons/enrollment.svg";
import performances from "../../assets/Icons/Performance.svg";
import dashboards from "../../assets/Icons/dashboard.svg";
import services from "../../assets/Icons/services.svg";

export default function CustomerSidebar({ onClose, visible, onClickChange, accessData }) {

    const [accessType, setAccessType] = useState({});
    const getUserDetails = async () => {
        let result = await ItrAuthService.GetUserInfo();
        if (result.Success == true) {
            let getUserDetails = result.result;
            setAccessType(getUserDetails);
        }
        else if (result.Success == false) {
            NotificationManager.error(result.message);
            // sessionStorage.clear();
        }
    }

    const [output, setOutput] = useState('');

    useEffect(() => {
        getUserDetails();
    }, [])

    return (
        <div>
            <Hotkeys keyName="shiftKey + a">

                <div className="nx-sidebar-content">
                    <div className="pt-1">
                        <ul className="nx-sidebar-menu m-0 p-0 mb-5">
                            <li>
                                <NavLink to="/dashboard"
                                    className="nx-sidebar-hover" style={{ textDecoration: 'none', color: 'black' }}
                                    activeStyle={{ borderLeft: '5px solid #374bff', borderTopRightRadius: '50%' }}
                                >
                                    <Tooltip title='Dashboard' placement='right'>
                                        <img src={dashboards} width="25" />
                                        {/* <br/> */}
                                        {/* <span className="nx-sidebar-menu-name pt-2">Dashboard</span> */}
                                    </Tooltip>
                                </NavLink>
                            </li>
                            <li className="pt-0">
                                <NavLink to="/developers"
                                    className="nx-sidebar-hover" style={{ textDecoration: 'none', color: 'black' }}
                                    activeStyle={{ borderLeft: '5px solid #374bff' }}
                                >
                                    <Tooltip title='Developers' placement='right'>
                                        <img src={users1} width="25" />
                                        {/* <span className="nx-sidebar-menu-name pt-2">Developers</span> */}
                                    </Tooltip>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/administrator"
                                    className="nx-sidebar-hover" style={{ textDecoration: 'none', color: 'black' }}
                                    activeStyle={{ borderLeft: '5px solid #374bff' }}
                                >
                                    <Tooltip title='Deployment' placement='right'>
                                        <img src={deployment} width="25" />
                                        {/* <span className="nx-sidebar-menu-name pt-2">Configuration</span> */}
                                    </Tooltip>
                                </NavLink>
                            </li>


                            {/* // onClick={() => {
                                //     onClickChange('1')
                                // }}  */}
                            {/* // <li>
                            //     <NavLink 
                            //     to="/users" className="nx-sidebar-hover" style={{textDecoration:'none',color:'black'}}
                            //     activeStyle={{borderLeft:'6px solid #008ecc',borderTopRightRadius:'50%'}}
                            //     >
                            //         <img  src={users} width="25"  />
                            //         <span className="nx-sidebar-menu-name pt-2">IAM</span>
                            //     </NavLink>
                            // </li> */}

                            {/* <li>
                                    <NavLink
                                        to="/customers" className="nx-sidebar-hover" style={{ textDecoration: 'none', color: 'black' }}
                                        activeStyle={{ borderLeft: '5px solid #374bff', borderTopRightRadius: '50%' }}
                                    >
                                        <img src={customers} width="25" />
                                        <span className="nx-sidebar-menu-name pt-2">Customers</span>
                                    </NavLink>
                                </li> */}

                            <li
                            // hidden={accessType.accessType == "PRINCIPAL" ? false : true}
                            >
                                <NavLink to="/enrollment"
                                    // onClick={() => {
                                    //     onClickChange('3')
                                    // }}
                                    className="nx-sidebar-hover" style={{ textDecoration: 'none', color: 'black' }}
                                    activeStyle={{ borderLeft: '5px solid #374bff', borderTopRightRadius: '50%' }}>
                                    <Tooltip title='Enrollment' placement='right'>
                                        <img src={services} width="25" />
                                        {/* <span className="nx-sidebar-menu-name pt-2">Enrollment</span> */}
                                    </Tooltip>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/marketplace"
                                    className="nx-sidebar-hover" style={{ textDecoration: 'none', color: 'black' }}
                                    activeStyle={{ borderLeft: '5px solid #374bff' }}
                                >
                                    <Tooltip title='Marketplace' placement='right'>
                                        <img src={marketPlace} width="25" />
                                        {/* <span className="nx-sidebar-menu-name pt-2">Configuration</span> */}
                                    </Tooltip>
                                </NavLink>
                            </li>

                            {/* <li
                                hidden={accessType.accessType == "PRINCIPAL" ? false : true}
                            >
                                <NavLink to="/appservices"
                                    className="nx-sidebar-hover" style={{ textDecoration: 'none', color: 'black' }}
                                    activeStyle={{ borderLeft: '5px solid #374bff', borderTopRightRadius: '50%' }}>
                                    <Tooltip title='Services' placement='right'>
                                        <img src={services} width="25" />
                                        <span className="nx-sidebar-menu-name pt-2">App Store</span>
                                    </Tooltip>
                                </NavLink>
                            </li> */}

                            {/* <li>
                                    <NavLink to="/billing"
                                        className="nx-sidebar-hover" style={{ textDecoration: 'none', color: 'black' }}
                                        activeStyle={{ borderLeft: '5px solid #374bff', borderTopRightRadius: '50%' }}
                                    >
                                        <img src={billing} width="25" />
                                        <span className="nx-sidebar-menu-name pt-2">Billing</span>
                                    </NavLink>
                                </li> */}

                            <li>
                                <NavLink to="/performance"
                                    className="nx-sidebar-hover" style={{ textDecoration: 'none', color: 'black' }}
                                    activeStyle={{ borderLeft: '5px solid #374bff', borderTopRightRadius: '50%' }}
                                >
                                    <Tooltip title='Performance' placement='right'>
                                        <img src={performances} width="25" />
                                        {/* <span className="nx-sidebar-menu-name pt-2">Performance</span> */}
                                    </Tooltip>
                                </NavLink>
                            </li>



                            {/* <li>
                                <NavLink to="/configuration"
                                className="nx-sidebar-hover" style={{textDecoration:'none',color:'black'}}
                                activeStyle={{borderLeft:'6px solid #008ecc'}}
                                >
                                    <img  src={codeCast} width="25"  />
                                    <span className="nx-sidebar-menu-name pt-2">Code Cast</span>
                                </NavLink>
                            </li> */}
                            <li>
                                <NavLink to="/access"
                                    className="nx-sidebar-hover" style={{ textDecoration: 'none', color: 'black' }}
                                    activeStyle={{ borderLeft: '5px solid #374bff' }}
                                >
                                    <Tooltip title='Identity' placement='right'>
                                        <img src={identity} width="25" />
                                    </Tooltip>
                                    {/* <span className="nx-sidebar-menu-name pt-2">Identity</span> */}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/security"
                                    className="nx-sidebar-hover" style={{ textDecoration: 'none', color: 'black' }}
                                    activeStyle={{ borderLeft: '5px solid #374bff' }}
                                >
                                    <Tooltip title='Configuration' placement='right'>
                                        <img src={configuration} width="25" />
                                        {/* <span className="nx-sidebar-menu-name pt-2">Security</span> */}
                                    </Tooltip>
                                </NavLink>
                            </li>
                           
                            <li>
                                <NavLink to="/support"
                                    // onClick={() => {
                                    //     onClickChange('5')
                                    // }}
                                    className="nx-sidebar-hover" style={{ textDecoration: 'none', color: 'black' }}
                                    activeStyle={{ borderLeft: '5px solid #374bff', borderTopRightRadius: '50%' }}
                                >
                                    <Tooltip title='Support' placement='right'>
                                        <img src={supports} width="25" />
                                        {/* <span className="nx-sidebar-menu-name pt-2">Support</span> */}
                                    </Tooltip>
                                </NavLink>
                            </li>

                            {/* <li>
                                <NavLink to="javascript:void(0)" onClick={() => {
                                    onClickChange('8')
                                }} className="nx-sidebar-hover" style={{textDecoration:'none',color:'black'}}
                                activeStyle={{borderLeft:'6px solid #008ecc'}}
                                >
                                    <img  src={user} width="30"  />
                                    <br/>
                                    <span className="nx-sidebar-menu-name">Audit</span>
                                </NavLink>
                            </li> */}

                            {/* <li>
                                <NavLink to="javascript:void(0)" onClick={() => {
                                    onClickChange('9')
                                }} className="nx-sidebar-hover" style={{textDecoration:'none',color:'black'}}
                                activeStyle={{borderLeft:'6px solid #008ecc'}}
                                >
                                    <img  src={user} width="30"  />
                                    <br/>
                                    <span className="nx-sidebar-menu-name pt-2">Configuration</span>
                                </NavLink>
                            </li> */}
                        </ul>
                    </div>
                </div>
                {/* <div className="nx-sidebar-options">
                    <NavLink style={{textDecoration:'none'}} to="/" className="nx-sidebar-body-header"> Principal Board</NavLink>
                    <div className="con mt-2">
                        <ul style={{textAlign:'left',paddingLeft:'0'}}>
                            <label> 
                                <FontAwesomeIcon icon={faStar} color="#039be5" size="xs" border={false} />
                                <span className="nx-sidebar-list" style={{paddingLeft:'12px'}}>Operations</span>
                            </label>
                            <li>
                                <NavLink to="/enquiry" className="nx-sidebar-hover" style={{textDecoration:'none',color:'black'}}>
                                    <img  src={enquiry} width="18"  />
                                    <span className="nx-sidebar-list">Enquiry</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/customer" className="nx-sidebar-hover" style={{textDecoration:'none',color:'black'}}>
                                    <img  src={customer} width="18"  />
                                    <span className="nx-sidebar-list">Customer</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/onboarding" className="nx-sidebar-hover" style={{textDecoration:'none',color:'black'}}>
                                    <img  src={customer} width="18"  />
                                    <span className="nx-sidebar-list">On-Boarding</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/" className="nx-sidebar-hover" style={{textDecoration:'none',color:'black'}}>
                                    <img  src={subscription} width="18"  />
                                    <span className="nx-sidebar-list">Subscriptions</span>
                                </NavLink>
                            </li>

                            <li>
                                <NavLink to="/" className="nx-sidebar-hover" style={{textDecoration:'none',color:'black'}}>
                                    <img  src={vault} width="18"  />
                                    <span className="nx-sidebar-list">Key Vault</span>
                                </NavLink>
                            </li>

                            <li>
                                <NavLink to="/" className="nx-sidebar-hover" style={{textDecoration:'none',color:'black'}}>
                                    <img  src={billing} width="18"  />
                                    <span className="nx-sidebar-list">Billing</span>
                                </NavLink>
                            </li>

                            <li>
                                <NavLink to="/support" className="nx-sidebar-hover" style={{textDecoration:'none',color:'black'}}>
                                    <img  src={support} width="18"  />
                                    <span className="nx-sidebar-list">Support</span>
                                </NavLink>
                            </li>

                            <li>
                                <NavLink to="/feedback" className="nx-sidebar-hover" style={{textDecoration:'none',color:'black'}}>
                                    <img  src={feedback} width="18"  />
                                    <span className="nx-sidebar-list">Feedback</span>
                                </NavLink>
                            </li>

                            <li>
                                <NavLink to="/" className="nx-sidebar-hover" style={{textDecoration:'none',color:'black'}}>
                                    <img  src={notification} width="18"  />
                                    <span className="nx-sidebar-list">Notification</span>
                                </NavLink>
                            </li>

                            <li>
                                <NavLink to="/" className="nx-sidebar-hover" style={{textDecoration:'none',color:'black'}}>
                                    <img  src={widget} width="18"  />
                                    <span className="nx-sidebar-list">Widgets</span>
                                </NavLink>
                            </li>

                            <li>
                                <NavLink to="/partner-user" className="nx-sidebar-hover" style={{textDecoration:'none',color:'black'}}>
                                    <img  src={user} width="18"  />
                                    <span className="nx-sidebar-list">Users</span>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div> */}
                {/* </Drawer> */}
            </Hotkeys>
        </div>
    )
}
