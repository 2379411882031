import React from 'react';
import { Bar } from 'react-chartjs-2';
import { ChartColors } from '../../../general/general';

export default function NxCustomLineChart({
  label = ['Jan', '', 'Feb', '', 'Apr', '', 'June', '', 'Aug', '', 'Oct', '', 'Dec'],
  datasets = [
    {
      type: 'line',
      label: 'Line Dataset',
      data: [15, 13, 12, 10, 9, 8, 7, 6, 5, 4, 3, 2],
      backgroundColor: '#7bd72c',
      // fill:true
    }
  ]
}) {

  const labels = label;

  return (
    <div>
      <Bar
        data={{
          labels,
          datasets: datasets,
          // datasets: [
          //   {
          //     label:'New Accounts',
          //     data: [5, 3,10,8, 2,15,3, 7,8,1, 4,9,],
          //     backgroundColor:'#7bd72c'
          //   },
          // ],
        }}
        height={250}
        width={200}
        options={{
          plugins: {
            title: {
              display: false
            }
          },
          maintainAspectRatio: false,
          scales: {
            yAxes: [
              {
                ticks: {
                  // min:500,
                  // max:5000
                  beginAtZero: false,
                },
              },
            ],
          },
          responsve: true,
        }}
      />
    </div>
  )
}
