import React, { useState } from 'react';
import './PartnerPasswordPolicy.css'
import { NxLoader, NxFooter, NxTitleTab } from '@afiplfeed/itr-app';
import Headers from '../../../Screens/Headers/Headers';
import { useHistory } from 'react-router-dom';
import { Switch } from 'antd';
import { ItrApiService } from '@afiplfeed/itr-ui';


export default function PartnerPasswordPolicy() {
    let history = useHistory();
    const [loading, setLoading] = useState(false);

    const [passwordPolicy, setPasswordPolicy] = useState({
        passwordPolicyID: 0,
        enforcePasswordHistory: parseInt(3),
        maximumPasswordAge: parseInt(30),
        minimumPasswordLength: parseInt(7),
        maximumPasswordLength: parseInt(15),
        isPasswordMeetComplexity: true,
        isNotExceedTwoConsecutiveCharacters: false,
        isAtleastSixCharacters: false,
        isUpperCaseCharacters: false,
        isLowerCaseCharacters: false,
        isDigits: false,
        isNonAlphanumericCharacters: false,
        nonAlphanumericCharacters: ""
    })

    const validateAlphaNumeric = (e) => {
        var regex = new RegExp(/[a-zA-Z0-9]/);
        if (!regex.test(e.target.value)) {
            setPasswordPolicy({ ...passwordPolicy, nonAlphanumericCharacters: e.target.value })
        }
    }

    const updateParPasswordPollicy = async () => {
        setLoading(true);
        let res = await ItrApiService.POST({
            url: 'Platform/Configuration/PasswordPolicy',
            data: passwordPolicy
        });
        setLoading(false);
    }

    return (
        <div>
            <NxLoader state={loading} />
            <Headers />
            <NxTitleTab className="title-tab" title="Password Policy" onCloseClick={() => history.push('/partner-board')} />
            <div className="card m-4 mt-0">
                <div className="card-body p-0">
                    <div className="row p-3 mx-1 password-policy">
                        {/* <h4>Password Policy</h4> */}
                        <div className="col-12 col-md-12 col-lg-6 col-sm-6 col-xl-5 profile-card mt-3">
                            <div className="row title">
                                <div className="col-12 col-sm-12 col-lg-6 col-md-6">
                                    <label htmlFor="firstName">Enforce password history</label>
                                </div>
                                <div className="col-12 col-sm-12 col-lg-3 col-md-6">
                                    <input className="form-control form-control-sm mt-2" type="number" id="enforce"
                                        min="1" max="10" value={passwordPolicy.enforcePasswordHistory}
                                        onChange={(e) => setPasswordPolicy({ ...passwordPolicy, enforcePasswordHistory: parseInt(e.target.value) })} />
                                </div>
                            </div>

                            <div className="row title">
                                <div className="col-12 col-sm-12 col-lg-6 col-md-6">
                                    <label htmlFor="firstName">Maximum password age</label>
                                </div>
                                <div className="col-12 col-sm-12 col-lg-3 col-md-6">
                                    <select className="form-select  mt-2"
                                        value={passwordPolicy.maximumPasswordAge}
                                        onChange={(e) => setPasswordPolicy({ ...passwordPolicy, maximumPasswordAge: parseInt(e.target.value) })}>
                                        <option value="30" selected>30</option>
                                        <option value="60">60</option>
                                        <option value="90">90</option>
                                        <option value="120">120</option>
                                        <option value="Never">Never</option>
                                    </select>
                                </div>
                            </div>

                            <div className="row title">
                                <div className="col-12 col-sm-12 col-lg-6 col-md-6">
                                    <label htmlFor="firstName">Minimum password length</label>
                                </div>
                                <div className="col-12 col-sm-12 col-lg-3 col-md-6">
                                    <input className="form-control form-control-sm mt-2" type="number" id="enforce"
                                        min="7" max="42" value={passwordPolicy.minimumPasswordLength}
                                        onChange={(e) => setPasswordPolicy({ ...passwordPolicy, minimumPasswordLength: parseInt(e.target.value) })}
                                    />
                                </div>
                            </div>

                            <div className="row title">
                                <div className="col-12 col-sm-12 col-lg-6 col-md-6">
                                    <label htmlFor="firstName">Maximum password length</label>
                                </div>
                                <div className="col-12 col-sm-12 col-lg-3 col-md-6">
                                    <input className="form-control form-control-sm mt-2" type="number" id="enforce"
                                        min={passwordPolicy.minimumPasswordLength} max="42" value={passwordPolicy.maximumPasswordLength}
                                        onChange={(e) => setPasswordPolicy({ ...passwordPolicy, maximumPasswordLength: parseInt(e.target.value) })}
                                    />
                                </div>
                            </div>

                            <div className="row title mt-2">
                                <div className="col-12 col-sm-12 col-lg-6 col-md-6">
                                    <label htmlFor="firstName">Password must meet complexity </label>
                                </div>
                                <div className="col-12 col-sm-12 col-lg-3 col-md-6">
                                    <Switch checked={passwordPolicy.isPasswordMeetComplexity} className="mt-2"
                                        onChange={(e) => setPasswordPolicy({ ...passwordPolicy, isPasswordMeetComplexity: e })}
                                    />
                                </div>
                            </div>
                        </div>


                        <div className="col-12 col-md-12 col-lg-6 col-sm-6 col-xl-7 profile-card mt-3">
                            <div className="row title">
                                <div className="col-12 col-sm-12 col-lg-12 col-md-12 py-1 d-flex align-items-baseline">
                                    <Switch checked={passwordPolicy.isPasswordMeetComplexity == false ? false : passwordPolicy.isNotExceedTwoConsecutiveCharacters} id="terms" disabled={passwordPolicy.isPasswordMeetComplexity == false ? true : false}
                                        onChange={(e) => setPasswordPolicy({ ...passwordPolicy, isNotExceedTwoConsecutiveCharacters: e })} />
                                    <label className="p-0 px-2" htmlFor="terms">Not contain the user’s account name or parts of the user’s full name that exceed two consecutive characters </label>
                                </div>
                            </div>

                            <div className="row title">
                                <div className="col-12 col-sm-12 col-lg-12 col-md-12 py-1">
                                    <Switch checked={passwordPolicy.isPasswordMeetComplexity == false ? false : passwordPolicy.isAtleastSixCharacters} id="length" disabled={passwordPolicy.isPasswordMeetComplexity == false ? true : false}
                                        onChange={(e) => setPasswordPolicy({ ...passwordPolicy, isAtleastSixCharacters: e })} />
                                    <label className="px-2" htmlFor="length">Be at least <strong>six</strong> characters in length</label>
                                </div>
                            </div>

                            <div className="row title">
                                <div className="col-12 col-sm-12 col-lg-12 col-md-12 py-1" >
                                    <Switch checked={passwordPolicy.isPasswordMeetComplexity == false ? false : passwordPolicy.isUpperCaseCharacters} id="uppercase" disabled={passwordPolicy.isPasswordMeetComplexity == false ? true : false}
                                        onChange={(e) => setPasswordPolicy({ ...passwordPolicy, isUpperCaseCharacters: e })} />
                                    <label className="px-2" htmlFor="uppercase">English uppercase characters (<strong>A through Z</strong>) </label>
                                </div>
                            </div>

                            <div className="row title">
                                <div className="col-12 col-sm-12 col-lg-12 col-md-12 py-1" >
                                    <Switch checked={passwordPolicy.isPasswordMeetComplexity == false ? false : passwordPolicy.isLowerCaseCharacters} id="lowercase" disabled={passwordPolicy.isPasswordMeetComplexity == false ? true : false}
                                        onChange={(e) => setPasswordPolicy({ ...passwordPolicy, isLowerCaseCharacters: e })} />
                                    <label className="px-2" htmlFor="lowercase">English lowercase characters (<strong>a through z</strong>) </label>
                                </div>
                            </div>

                            <div className="row title">
                                <div className="col-12 col-sm-12 col-lg-12 col-md-12 py-1">
                                    <Switch checked={passwordPolicy.isPasswordMeetComplexity == false ? false : passwordPolicy.isDigits} id="digits" disabled={passwordPolicy.isPasswordMeetComplexity == false ? true : false}
                                        onChange={(e) => setPasswordPolicy({ ...passwordPolicy, isDigits: e })} />
                                    <label className="px-2" htmlFor="digits">Base 10 digits (<strong>0 through 9</strong>) </label>
                                </div>
                            </div>

                            <div className="row title">
                                <div className="col-12 col-sm-12 col-lg-12 col-md-12 py-1">
                                    <Switch checked={passwordPolicy.isPasswordMeetComplexity == false ? false : passwordPolicy.isNonAlphanumericCharacters} id="Non-alphabetic" disabled={passwordPolicy.isPasswordMeetComplexity == false ? true : false}
                                        onChange={(e) => setPasswordPolicy({ ...passwordPolicy, isNonAlphanumericCharacters: e })} />
                                    <label className="px-2" htmlFor="Non-alphabetic">Non-alphabetic characters (<strong>for example, !, $, #, %</strong>) </label>
                                </div>
                            </div>

                            <div className="row title">
                                <div className="col-12 col-sm-12 col-lg-12 col-md-12 py-1">
                                    <input type="text" className="form-control w-50" id="NonAlpha" value={passwordPolicy.nonAlphanumericCharacters}
                                        onChange={(e) => validateAlphaNumeric(e)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row p-3 mx-1">
                        <div className="mb-5 text-start">
                            <button className="btn btn-primary btn-sm"
                                onClick={updateParPasswordPollicy}
                            >
                                Update
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <NxFooter ExtraContent={[
                { name: 'Terms of Service' },
                { name: 'privacy' },
            ]} />
        </div>
    )
}
