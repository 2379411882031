import React, { useState, useEffect } from 'react';
import './smsGateway.css'
import { useHistory } from 'react-router';
import { NxTitleTab, NxLoader, NxFooter } from '@afiplfeed/itr-app';
import Headers from '../../../../Screens/Headers/Headers';
import { ItrApiService } from '@afiplfeed/itr-ui';
import AuthKeyImage from '../../../../assets/Authkey.png';
import { message } from 'antd';
import { NotificationManager } from 'react-notifications';

export default function SmsGateway() {
    let history = useHistory();

    const [loading, setLoading] = useState(false);
    // sms-gate-way
    const [gateway, setGateway] = useState({});

    const [gatewayData, setGatewayData] = useState({
        smsGatewayID: 0,
        url: "https://www.fast2sms.com/dev/bulkV2",
        apiKey: ""
    });

    // save-api
    const saveSmsGateway = async () => {
        setLoading(true);
        let result = await ItrApiService.POST({
            url: 'Platform/Configuration/SMSGateway',
            data: gatewayData
        });
        if (result.result.isSuccess == true) {
            NotificationManager.success(result.message);
        }
        else if (result.result.isSuccess == false) {
            NotificationManager.error(result.message);
        }
        setLoading(false);
    }

    const getSmsGateway = async () => {
        setLoading(true);
        let result = await ItrApiService.GET({
            url: 'Platform/Configuration/SMSGateway',
        });
        if (result.result.isSuccess == true) {
            let getGatewayDetails = result.result.data;
            if (result.result.data) {
                setGateway(getGatewayDetails);
                setGatewayData(getGatewayDetails);
            }
        }
        else if (result.result.isSuccess == false) {

        }
        setLoading(false);
    }

    useEffect(() => {
        getSmsGateway();
    }, [])
    return (
        <div>
            {/* <Headers /> */}
            <NxLoader state={loading} />
            {/* <NxTitleTab className="title-tab" title='SMS Gateway' onCloseClick={() => history.push('/configuration')} /> */}

            <div className="card mt-0 sms-gateway-main mx-0">
                <div className="card-body">
                    <div className="container-form mb-5">
                        <div>
                            <div className="row mt-4">
                                <h6>SMS Gateway API to send DLT Approved Mobile Verification Code for 2-Factor Authentication & Notifications. Contact software administrator for API End-Points & Notification Options.</h6>
                                <div className="col-12 col-sm-4 col-md-6 col-lg-6 col-xl-6 mt-4">
                                    <label>SMS Service Provider</label>
                                    <select className="form-select form-select-sm"
                                        value={gatewayData?.url}
                                        onChange={(e) => setGatewayData({ ...gatewayData, url: e.target.value })}
                                    >
                                        <option value=""> Select SMS Service</option>
                                        <option value="https://www.fast2sms.com/dev/bulkV2" selected>Fast2SMS (Recommended)</option>
                                        <option >Text Local</option>
                                        <option >2Factor</option>
                                        <option >TextGuru</option>
                                        <option >Exotel</option>
                                        <option >Knowlarity</option>
                                    </select>
                                </div>

                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                    {/* <label htmlFor="Organization"> User Name</label>
                                        <input className="form-control mt-1" id="Organization" type="text" /> */}
                                </div>

                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-4">
                                    <label htmlFor="Organization">API Authorization Key</label>
                                    <input className="form-control mt-1" id="Organization"
                                        value={gatewayData?.apiKey}
                                        onChange={(e) => setGatewayData({ ...gatewayData, apiKey: e.target.value })}
                                        type="text" />
                                </div>

                                {/* <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                        <label htmlFor="Organization">Url</label>
                                        <input className="form-control mt-1" id="Organization" type="text" value={gateway?.url} />
                                    </div> */}
                            </div>

                            <div className="form-button nx-enquiry-buttons mt-3 mb-5">
                                <button className="btn btn-primary buttons"
                                    onClick={() => saveSmsGateway()}
                                >Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <NxFooter companyName="© 2022 Ithred Solutions" ExtraContent={[
                { name: 'Terms of Service' },
                { name: 'Privacy' },
            ]} />
        </div>
    )
}
