import React, { useState, useEffect } from 'react';
import './customerBoardCounts.css';
import { Progress, DatePicker, Tag } from 'antd';
import Headers from '../../../../Screens/Headers/Headers';
import { NxFooter } from '@afiplfeed/itr-app';
import { BiExpandAlt } from "react-icons/bi";
import { AiFillDatabase } from "react-icons/ai";
import { SiKubernetes } from "react-icons/si";
import { IoIosApps } from "react-icons/io";
import { HiUsers } from "react-icons/hi";
import { ItrApiService } from '@afiplfeed/itr-ui';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import CustomerSidebar from '../../../../Screens/CustomerSidebar/CustomerSidebar';

export default function CustomerBoardCounts() {

    const { RangePicker } = DatePicker;
    let history = useHistory();

    const [keyPeriods, setKeyPeriods] = useState();

    const [indicatorsData, setIndicatorsData] = useState({
        enquiry: '',
        customers: '',
        onBoard: '',
        conversion: '',
        churnRatio: ''
    });


    const [acquistion, setAcquistion] = useState({
        newEnquiry: '',
        newCustomers: '',
        inactiveCustomers: '',
    });

    var monthFirstDay = moment().startOf('M').format()

    const [acquistionChart, setAcquistionChart] = useState({
        fromDate: monthFirstDay,
        toDate: moment().format()
    });


    const [chartData, setChartData] = useState([]);



    // const getAcquistionData = () => {
    //     ItrApiService.POST({
    //         url: 'RevOps/CustomerDashBoard/GetCustomerDashBoardSpecificPeriod',
    //         data: acquistionChart
    //     }).then(res => {
    //         if (res.Success == true) {
    //             if (res.result.isSuccess == true) {
    //                 setChartData(res.result.data.currentPeriodCount);
    //             }
    //         }
    //     })
    // }


    const getIndicators = () => {
        ItrApiService.GET({
            url: 'RevOps/CustomerDashBoard/GetCustomerDashBoard'
        }).then((res) => {
            if (res.Success == true) {
                if (res.result.isSuccess == true) {
                    if (res.result.data.currentPeriodCount) {
                        setChartData(res.result.data.currentPeriodCount)
                    }
                    let obj = { ...indicatorsData }
                    let obj1 = { ...acquistion }
                    for (let data of res.result.data.customerIndicatorCount) {
                        if (data.categoryName == 'TOTAL ENQUIRY') {
                            obj = { ...obj, enquiry: data.categoryCount }
                        }
                        else if (data.categoryName == 'TOTAL CUSTOMERS') {
                            obj = { ...obj, customers: data.categoryCount }
                        }
                        else if (data.categoryName == 'AVG. TIME TO ONBOARD') {
                            obj = { ...obj, onBoard: data.categoryCount }
                        }
                        else if (data.categoryName == 'E-2-C CONVERSION') {
                            obj = { ...obj, conversion: data.categoryCount }
                        }
                        else if (data.categoryName == 'CHURN RATIO') {
                            obj = { ...obj, churnRatio: data.categoryCount }
                        }
                    }
                    for (let data of res.result.data.customerAcquisionCount) {
                        if (data.categoryName == 'New Enquiries') {
                            obj1 = { ...obj1, newEnquiry: data.categoryCount }
                        }
                        else if (data.categoryName == 'New Customers') {
                            obj1 = { ...obj1, newCustomers: data.categoryCount }
                        }
                        else if (data.categoryName == 'InActive Customers') {
                            obj1 = { ...obj1, inactiveCustomers: data.categoryCount }
                        }
                    }
                    setAcquistion(obj1);
                    setIndicatorsData(obj);
                }
            }
        })
    }


    useEffect(() => {
        getIndicators();
        // getAcquistionData();
    }, []);



    return (
        <div className="container1">
            <Headers />
            <div className='d-flex custom-body-container'>
                <div className='body-sidebar'>
                    <CustomerSidebar visible={true}  />
                </div>
                <div className='body-workspace'>
                <div className='px-3 content-container'>
            <div className='row right-box'>               
                <div className='col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 mt-2'>
                    <div className='card box-1'>
                        <div className='card-body'>
                            <div className='pt-2'>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <IoIosApps fontSize="50px" color='white' />
                                </div>
                                <div className='pt-1'>
                                    <h5>Apps Deployed</h5>
                                    <h6 style={{ fontWeight: "bold" }}>3 / 5</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 mt-2'>
                    <div className='card box-2 h-100'>
                        <div className='card-body h-100'>
                            <div className='pt-2'>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <HiUsers fontSize="50px" color='white' />
                                </div>
                                <div className='pt-1'>
                                    <h5>5 Users Managed</h5>
                                    <h6 style={{ fontWeight: "bold" }}>5 / 5</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 mt-2'>
                    <div className='card box-3 h-100'>
                        <div className='card-body h-100'>
                            <div className='pt-2'>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <SiKubernetes fontSize="50px" color='white' />
                                </div>
                                <div className='pt-1'>
                                    <h5>Pods Provisioned</h5>
                                    <h6 style={{ fontWeight: "bold" }}>2 / 5</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 mt-2'>
                    <div className='card box-4 h-100'>
                        <div className='card-body h-100'>
                            <div className='pt-2'>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <AiFillDatabase fontSize="50px" color='white' />
                                </div>
                                <div className='pt-1'>
                                    <h5>MS SQL Database</h5>
                                    <h6 style={{ fontWeight: "bold" }}>1 / 5</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* </div>
                </div> */}
            </div>

            <div className='row mt-4'>
                <div className='col-12 col-sm-6 col-md-5 col-lg-4 col-xl-8 left-chart-box'>
                    <div className='card h-100'>
                        <div className='card-body h-100'>
                            <h4 className='text-dark text-center'>Resource Geo.</h4>
                            <div className='w-100'>
                                <img width="100%" src="https://maps.googleapis.com/maps/api/staticmap?center=Brooklyn+Bridge,New+York,NY&zoom=1&size=1000x300&maptype=roadmap
&markers=color:blue%7Clabel:S%7C40.702147,-74.015794&markers=color:green%7Clabel:G%7C40.711614,-74.012318
&markers=color:red%7Clabel:C%7C40.718217,-73.998284
&key=AIzaSyChpyA3F7UBA8bqlObUiUD1w7PViOkxFKA" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-sm-6 col-md-7 col-lg-8 col-xl-4 right-table-box'>
                    <div className='card h-100 '>
                        <div className='card-body h-100'>
                            <h4 className='text-dark text-center'>Provider Info.</h4>
                            <div className='pie-chart pt-3'>
                                {/*<Doughnut data={data} options={config} width="10px" />*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row mt-4'>
                <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 right-table-box'>
                    <div className='card h-100'>
                        <div className='card-body h-100'>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <h4 className='text-dark'>User Status</h4>
                                <BiExpandAlt fontSize={"20px"} color="black"
                                    onClick={() => navigate("/home/user-access")}
                                />
                            </div>
                            <hr style={{ color: "gray" }} />
                            <div className='table-responsive mt-2 last-file-table'>
                                <table className="table table-hover" id='orderTable'>
                                    <tbody>
                                        <tr style={{ cursor: 'pointer' }} >
                                            <td> Santhosh</td>
                                            <td> Administrator</td>
                                            <td> <Tag color="var(--STATUS-GREEN)">Active</Tag></td>
                                        </tr>
                                        <tr style={{ cursor: 'pointer' }} >
                                            <td>Selva Kumar</td>
                                            <td>App User</td>
                                            <td> <Tag color="var(--STATUS-GREEN)">Active</Tag></td>
                                        </tr>
                                        <tr style={{ cursor: 'pointer' }} >
                                            <td>  David</td>
                                            <td>App User</td>
                                            <td> <Tag color="var(--STATUS-GREEN)">Active</Tag></td>
                                        </tr>
                                        <tr style={{ cursor: 'pointer' }} >
                                            <td> Austin</td>
                                            <td>App User</td>
                                            <td> <Tag color="var(--STATUS-BLUE)">Not Logged</Tag></td>
                                        </tr>
                                        <tr style={{ cursor: 'pointer' }} >
                                            <td> Venkat</td>
                                            <td>App User</td>
                                            <td> <Tag color="var(--STATUS-RED)">Inactive</Tag></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 right-table-box'>
                    <div className='card h-100'>
                        <div className='card-body h-100'>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <h4 className='text-dark'>Apps Status</h4>
                                <BiExpandAlt fontSize={"20px"} color="black"
                                    onClick={() => navigate("/home/app-enrollment")}
                                />
                            </div>
                            <hr style={{ color: "gray" }} />
                            <div className='table-responsive mt-2 last-file-table'>
                                <table className="table table-hover" id='orderTable'>
                                    <tbody>
                                        <tr style={{ cursor: 'pointer' }} >
                                            <td>Leave Management</td>
                                            <td>Production</td>
                                            <td> <Tag color="var(--STATUS-GREEN)">Running</Tag></td>
                                        </tr>
                                        <tr style={{ cursor: 'pointer' }} >
                                            <td>Time Sheet</td>
                                            <td>Production</td>
                                            <td> <Tag color="var(--STATUS-RED)">Stopped</Tag></td>
                                        </tr>
                                        <tr style={{ cursor: 'pointer' }} >
                                            <td>Appraisal System  </td>
                                            <td>Production</td>
                                            <td> <Tag color="var(--STATUS-AMBER)">Warning</Tag></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 right-table-box'>
                    <div className='card h-100'>
                        <div className='card-body h-100'>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <h4 className='text-dark'>Recent Events</h4>
                                <BiExpandAlt fontSize={"20px"} color="black" />
                            </div>
                            <hr style={{ color: "gray" }} />
                            <div className='table-responsive mt-2 last-file-table'>
                                <table className="table table-hover" id='orderTable'>
                                    <tbody>
                                        <tr style={{ cursor: 'pointer' }} >
                                            <td>
                                                <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                                    <span className='dots'></span>
                                                    <span>Pod created</span>
                                                </div>
                                            </td>
                                            <td> {moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}</td>
                                        </tr>
                                        <tr style={{ cursor: 'pointer' }} >
                                            <td>
                                                <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                                    <span className='dots1'></span>
                                                    <span>Policy violation</span>
                                                </div>
                                            </td>
                                            <td>{moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}</td>
                                        </tr>
                                        <tr style={{ cursor: 'pointer' }} >
                                            <td>
                                                <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                                    <span className='dots2'></span>
                                                    <span>App enrolled</span>
                                                </div>
                                            </td>
                                            <td>{moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}</td>
                                        </tr>
                                        <tr style={{ cursor: 'pointer' }} >
                                            <td>
                                                <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                                    <span className='dots3'></span>
                                                    <span>User added</span>
                                                </div>
                                            </td>
                                            <td>{moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}</td>
                                        </tr>
                                        <tr style={{ cursor: 'pointer' }} >
                                            <td>
                                                <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                                    <span className='dots4'></span>
                                                    <span>Database pending</span>
                                                </div>
                                            </td>
                                            <td>{moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
                </div>
            </div>
            <NxFooter companyName="© 2023 Altascio Technologies (I) Pvt. Ltd.," ExtraContent={[
                { name: 'Terms of Service' },
                { name: 'Privacy' },
            ]} />
        </div>
    )
}
