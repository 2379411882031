import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import NxDoughnutCharts from '../../../components/nxCharts/nxDoughnutCharts';
import NxLineCharts from '../../../components/nxCharts/nxLineCharts';
import { NxLoader } from '@afiplfeed/itr-app';
import { ItrApiService } from '@afiplfeed/itr-ui';
import { NotificationManager } from "react-notifications";

export default function MonitoringTab() {

    const [customers, setCustomers] = useState([]);
    const [loading, setLoading] = useState(false);

    const getData = async (tenetcode) => {
        setLoading(true);
        let result = await ItrApiService.GET({
            url: 'TenantPlatform/Entity/GetEntities',
        });
        if (result.result.isSuccess == true) {
            setLoading(false);
            let getCustomerDetails = result.result.data;
            if (getCustomerDetails === null || !getCustomerDetails.length) {
                NotificationManager.error(result.result.message);
            }
            else {
                setLoading(false);
                setCustomers(getCustomerDetails);
            }
        }
        else if (result.result.isSuccess == false) {
            setLoading(false);
            NotificationManager.error(result.result.message);
            // sessionStorage.clear();
            // history.push('/')
        }
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <>
            <NxLoader state={loading} />
            <div className="container1">

                <div className="row">
                    <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-2">
                        <label>Customers</label>
                        <select className="form-select-sm form-select mt-1">
                            {customers.map((data, index) => {
                                return <option key={index} value={data.entityCode}> {data.entityName} </option>
                            })}
                        </select>
                    </div>
                </div>

                <div className="counters mt-4">
                    <div className="row nx-monitoring-counts nx-monitoring">
                        <div className="col-12 col-sm-6 col-md-3 col-lg-3 counts count-one mt-2">
                            <div className="card">
                                <div className="card-body">
                                    <label className="badge-title">Deployed Resources</label>
                                    <div className="row">
                                        <div className="col-6 col-sm-6 col-md-6 col-lg-6 mt-2">
                                            <h6>25</h6>
                                            <p className="badge-one">24 running</p>
                                        </div>

                                        {/* <div className="col-6 col-sm-6 col-md-6 col-lg-6 mt-2">
                                        <h6>222</h6>
                                        <label className='badge-two'>API Gateway</label>
                                    </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 col-md-3 col-lg-3 counts count-one count-two mt-2">
                            <div className="card">
                                <div className="card-body">
                                    <label className="badge-title">Deployed Services</label>
                                    <div className="row">
                                        <div className="col-6 col-sm-6 col-md-6 col-lg-6 mt-2">
                                            <h6>45</h6>
                                            <p className="badge-one">2-issues</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 col-md-3 col-lg-3 counts  count-three mt-2">
                            <div className="card">
                                <div className="card-body">
                                    <label className="badge-title">API Gateway</label>
                                    <div className="row">
                                        <div className="col-6 col-sm-6 col-md-6 col-lg-6 mt-4">
                                            <h6>60%</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 col-md-3 col-lg-3 counts count-four mt-2">
                            <div className="card">
                                <div className="card-body">
                                    <label className="badge-title">Agent Health</label>
                                    <div className="row">
                                        <div className="col-6 col-sm-6 col-md-6 col-lg-6 mt-4">
                                            <h6>60%</h6>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-3 nx-monitoring-chart nx-monitoring">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 mt-2">
                            <div className="card">
                                <div className="card-body">
                                    <label className="badge-title" style={{ textAlign: 'left' }}>Network Performance</label>
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <div className="mt-4">
                                                <FontAwesomeIcon icon={faExclamationTriangle} color="#e0742a" size="2x" />
                                                <p>Network Monitoring Requires Attention</p>

                                                <p> 3 <FontAwesomeIcon icon={faExclamationCircle} color="#b8491f" size="sm" /><br />
                                                    of 14 Service Connectivity Tests Unhealthy</p>
                                            </div>
                                        </div>

                                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-center">
                                            <NxDoughnutCharts />
                                        </div>
                                    </div>
                                    <div className="mt-4 mb-5">
                                        <div class="table-wrapper-scroll-y table-responsive my-custom-scrollbar">
                                            <table class="table table-bordered mb-0">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Resource</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Efficiency</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">Assesment</th>
                                                        <td>Completed <span className="dot"> </span>
                                                        </td>
                                                        <td>100%</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Assesment</th>
                                                        <td>Completed <span className="dot1"> </span></td>
                                                        <td>100%</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 mb-5 mt-2">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 mt-4 text-center nx-monitor-chart">
                                            <NxLineCharts />
                                        </div>

                                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 mt-4 text-left nx-monitor-agent">
                                            <div>
                                                <h6>Agent Health</h6>
                                                <label>38</label>
                                            </div>
                                            <div>
                                                <h6>Total count of agents</h6>
                                                <label>0</label>
                                            </div>
                                            <div>
                                                <h6>Count of unresponsive agents in last 24 hours</h6>
                                            </div>
                                        </div>

                                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 mt-4 text-center">
                                            <NxLineCharts />
                                        </div>

                                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 mt-4 text-center">
                                            <NxDoughnutCharts />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
