import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'antd';
import './AdminPinGenerator.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import OtpInput from 'react-otp-input';
import { ItrApiService, ItrAuthService } from '@afiplfeed/itr-ui';
import { NxLoader } from '@afiplfeed/itr-app';
import { NotificationManager } from "react-notifications";


const AdminPinGenerator = ({ getProfileDetails, profileDetails, isModalVisible, handleOk, handleCancel, changer }) => {
    const [step, setStep] = useState(1);
    const [number, setNumber] = useState('+91 ');
    const [otp, setOtp] = useState('');
    const [pin, setPin] = useState('');
    const [userCode, setUserCode] = useState('');
    const [userName, setUserName] = useState('');
    useEffect(() => {
        ItrAuthService.GetUserInfo().then(res => {
            if (res.Success == true) {
                let getUserDetails = res.result;
                setUserCode(getUserDetails.userCode);
                setUserName(getUserDetails.userName);
            }
            else if (res.Success == false) {
                NotificationManager.error(res.message);
                // sessionStorage.clear();
            }
        })
    }, []);

    const [phone, setPhone] = useState('');
    useEffect(() => {
        setOtp('');
        setPin('');
        setStep(1);
        setPhone(profileDetails)
    }, [changer])
    const nextStep = () => {
        if (step < 2) {
            // Api
            setStep(step + 1)
        }
        else {
            setStep(1);
            // Api
            handleOk();
        }
    }
    const prevStep = () => {
        if (step > 1) {
            setStep(step - 1)
        }
    }

    const [loader, setLoader] = useState(false);

    return (
        <>
            <NxLoader state={loader} />
            <Modal
                footer={false}
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <div className="twoFactorModal">
                    <div className="header">
                        <h4>
                            Set up Admin Pin
                        </h4>
                        <p>You will need your Password and a Phone Verification code to setup Admin Pin

                        </p>
                    </div>
                    <div className="steps">

                        <div>
                            <FontAwesomeIcon icon={faCircle} style={{ color: "green", display: step != 1 && "none" }} />
                            <FontAwesomeIcon icon={faCheckCircle} style={{ color: "green", display: step == 1 && "none" }} />
                            Admin Pin
                        </div>
                        <div>
                            <FontAwesomeIcon icon={faCircle} style={{ color: step == 1 ? "grey" : "green" }} />
                            Confirm
                        </div>
                    </div>
                    {step == 1 &&
                        <div>
                            <div className="content">
                                <p>
                                    <span>
                                        Enter The Admin Pin to Set
                                    </span>
                                </p>
                            </div>
                            <div className="feilds2" style={{ display: 'flex', justifyContent: 'center' }}>
                                <div>
                                    <label>Enter the Pin</label>
                                    {/* <input type='number' /> */}
                                    <OtpInput
                                        containerStyle={{}}
                                        value={pin}
                                        onChange={(ot) => { setPin(ot) }}
                                        numInputs={6}
                                        separator={<span> - </span>}
                                    />
                                </div>
                            </div>
                            {/* <div className="bottom">
                                <p>Did not get the code Resend Now</p>
                            </div> */}
                        </div>
                    }
                    {step == 2 &&
                        <div>
                            <div className="content">
                                <p>
                                    <span>
                                        Confirm that it works!
                                    </span>
                                    <br />
                                    Please enter the Phone Verification Code sent to your mobile number.
                                    <br />
                                </p>
                            </div>
                            <div className="feilds2" style={{ display: 'flex', justifyContent: 'center' }}>
                                <div>
                                    <label>Enter the code</label>
                                    {/* <input type='number' /> */}
                                    <OtpInput
                                        containerStyle={{}}
                                        value={otp}
                                        onChange={(ot) => { setOtp(ot) }}
                                        numInputs={6}
                                        separator={<span> - </span>}
                                    />
                                </div>
                            </div>
                            <div className="bottom">
                                <p>Did not get the code Resend Now</p>
                            </div>
                        </div>
                    }
                    <div className="footer-profile-tab">
                        <button className='btn'
                            onClick={() => {
                                if (step == 1) {
                                    handleCancel()
                                } else {
                                    prevStep()
                                }
                            }}
                        >
                            {step == 1 ? "Close" : "Back"}
                        </button>

                        <button
                            className='btn btn-primary'
                            onClick={() => {
                                if (step == 1) {
                                    // setLoader(true)
                                    // ItrApiService.POST({
                                    //     url: 'PAM/SendSMSVerification',
                                    //     data: {
                                    //         userCode,
                                    //         userName,
                                    //         phonenumbers: number.toString()
                                    //     }
                                    // }).then(res => {
                                    //     setLoader(false)
                                    //     if (res.Success == true) {
                                    //         if (res.result.isSuccess == true) {
                                    //             nextStep()
                                    //         } else {
                                    //             NotificationManager.error(res.result.message)
                                    //         }
                                    //     } else {
                                    //         NotificationManager.error(res.message)
                                    //     }
                                    // })
                                    nextStep()
                                } else {
                                    ItrApiService.POST({
                                        url: 'PAM/SetAdminPin',
                                        data: {
                                            userCode,
                                            userName,
                                            phonenumbers: profileDetails.phoneNumber,
                                            otpKey: otp,
                                            isEnable2FA: true,
                                            adminPin: pin
                                        }
                                    }).then(res => {
                                        setLoader(false)
                                        if (res.Success == true) {
                                            if (res.result.isSuccess == true) {
                                                handleOk();
                                                getProfileDetails();
                                                setOtp('');

                                                NotificationManager.success(res.result.message)
                                            } else {
                                                NotificationManager.error(res.result.message)
                                            }
                                        } else {
                                            NotificationManager.error(res.message)
                                        }
                                    })
                                    handleOk();
                                }
                            }}
                        >
                            {step == 1 ? "Continue" : "Turn On"}
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
};


export default AdminPinGenerator