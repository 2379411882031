import React from 'react';
import { useHistory } from 'react-router-dom';
import { NxCard } from '@afiplfeed/itr-app';
// icons
import enquiry from '../../../assets/Enquiry.svg';
import customer from '../../../assets/Customer.svg';


export default function CustomerTab() {

    let history = useHistory();

    return (
        <div className="conatiner">
            <NxCard className="card-tab" datas={[
                {
                    data: [
                        {
                            image: enquiry, heding: 'Enquiry', info: 'Monitor your apps and infrastructure', onClick: () => {
                                sessionStorage.setItem('ike', '1');
                                history.push('/enquiry')
                            }
                        },
                        {
                            image: customer, heding: 'Customer', info: 'Monitor your apps and infrastructure', onClick: () => {
                                sessionStorage.setItem('ike', '1');
                                history.push('/customer')
                            }
                        },
                        {
                            image: customer, heding: 'On-Boarding', info: 'Monitor your apps and infrastructure', onClick: () => {
                                sessionStorage.setItem('ike', '1');
                                history.push('/onboarding')
                            }
                        },
                    ]
                },
            ]} />
        </div>
    )
}
