import { NxFooter, NxLoader, NxTitleTab } from '@afiplfeed/itr-app';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tabs } from 'antd';
import APIEndPoints from '../../nexTabs/support/apiendpoints';
import HelpDocs from '../../nexTabs/support/helpdocs';
import { faTicketAlt } from '@fortawesome/free-solid-svg-icons';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Headers from '../../../Screens/Headers/Headers';
import CustomerSidebar from '../../../Screens/CustomerSidebar/CustomerSidebar';
import installationrunbook from '../../../assets/helpdocs/Adap Installation Runbook.pdf';
import devGuidelines from '../../../assets/helpdocs/Adap SDK & Dev Guidelines.pdf';
import endusermanual from '../../../assets/helpdocs/Adap EndUser Manual.pdf';

export default function OnlineHelpHome(){
    const [activeString, setActiveString] = useState('1');
    const [loading, setLoading] = useState(false);
    const { TabPane } = Tabs;
    let history = useHistory();
    useEffect(() => {
        let s = sessionStorage.getItem('sp');
        if (s) {
            setActiveString(s)
        }
    }, []);
    return (<>
        <Headers />
        <div className='d-flex custom-body-container'>
            <div className='body-sidebar'>
                <CustomerSidebar visible={true}  />
            </div>
            <div className='body-workspace'>
                <NxTitleTab className="title-tab" title="Online Help" onCloseClick={() => {
                    sessionStorage.removeItem('sp');
                    history.push('/developers');
                }} />
                <div className='support-main'>
                    <NxLoader state={loading} />
                    <div className="mt-0 support-main">
                        <div className='row'>
                            <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>

                            </div>
                        </div>

                        <Tabs activeKey={activeString} onChange={(e) => {
                            setActiveString(e);
                            sessionStorage.setItem('sp', e)
                        }}>
                        <TabPane tab={
                            <div className="d-flex justify-content-center">
                               
                                <span style={{ fontSize: '16px' }} className='mx-2'>  API Endpoints</span>
                            </div>
                        } key="1">
                            <APIEndPoints />
                        </TabPane>
                        <TabPane tab={
                            <div className="d-flex justify-content-center">                                
                                <span style={{ fontSize: '16px' }} className='mx-2'>  Installation Runbook</span>
                            </div>
                        } key="2">
                            <iframe src={installationrunbook} width={'100%'} height={'470px'} className="frame-content" ></iframe>
                        </TabPane>
                        <TabPane tab={
                            <div className="d-flex justify-content-center">                                
                                <span style={{ fontSize: '16px' }} className='mx-2'>  SDK Developer Guide</span>
                            </div>
                        } key="3">
                            <iframe src={devGuidelines} width={'100%'} height={'470px'} className="frame-content"></iframe>
                        </TabPane>
                        <TabPane tab={
                            <div className="d-flex justify-content-center">                                
                                <span style={{ fontSize: '16px' }} className='mx-2'>  Portal End-User Manual</span>
                            </div>
                        } key="4">
                             <iframe src={endusermanual} width={'100%'} height={'470px'} className="frame-content"></iframe>
                        </TabPane>
                        </Tabs>
                    </div>
                </div>
            </div>
        </div>
        <NxFooter className="verscio-footer" companyName="© 2023 Altascio Technologies (I) Pvt. Ltd.," ExtraContent={[
            { name: 'Terms of Service' },
            { name: 'Privacy' },
        ]} />
    </>)
}