import React, { useState, useEffect } from "react";
import './EditCustomerSummary.css';
import { useHistory, useLocation } from 'react-router-dom';
import { ItrApiService, ItrAuthService } from '@afiplfeed/itr-ui';
import { NxBreadcrumb, NxTitleTab, NxCustomTab, NxLoader, NxFooter, NxGridList } from '@afiplfeed/itr-app';
import Headers from "../../Screens/Headers/Headers";
import { message, Switch } from 'antd';
import { NotificationManager } from "react-notifications";
import InvoiceData from "../../customerBoard/nexTabs/InvoiceData/InvoiceData";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faEye } from "@fortawesome/free-solid-svg-icons";

export default function EditCustomerSummary({ }) {
    const [copyTo, setCopyTo] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    let location = useLocation();
    const [selectedVal, setSelectedVal] = useState({})
    const [summary, setSummary] = useState({});
    const [loader, setLoader] = useState(false);
    const [data, setData] = useState([]);
    const [dataVal, setDataVal] = useState([]);
    const [allUdo, setAllUdo] = useState([]);
    const [allUdoIndustry, setAllUdoIndustry] = useState([]);
    const [allUdoOwnership, setAllUdoOwnership] = useState([]);

    const [vald1, setVald] = useState({
        tcFirstName: true,
        tcLastName: true,
        tcPhone: true,
        tcDesignation: true,
        tcDepartment: true,
        tcEmail: true,

        acFirstName: true,
        acLastName: true,
        acPhone: true,
        acDesignation: true,
        acDepartment: true,
        acEmail: true,

        bcFirstName: true,
        bcLastName: true,
        bcPhone: true,
        bcDesignation: true,
        bcDepartment: true,
        bcEmail: true,
    })

    let UdoData = ["Enquiry Status", "Nature Of Business", "Industry", "Ownership", "Source"];
    const getUdo = async () => {
        let result = await ItrApiService.POST({
            url: 'Platform/UserDefined/AllUDOs',
            data: UdoData
        });
        if (result.result.isSuccess == true) {
            let getCustomerDetails = result.result.data;
            let getOptions = getCustomerDetails[1].Options;
            for (let val of getCustomerDetails) {
                if (val.CategoryName == 'Industry') setAllUdoIndustry(val.Options);
                if (val.CategoryName == 'Ownership') setAllUdoOwnership(val.Options);
            }
            setAllUdo(getOptions);

        }
        else if (result.result.isSuccess == false) {
            NotificationManager.error(result.result.message);
            // sessionStorage.clear();
            // history.push('/')
        }
    }

    // Partners
    const [allPartners, setAllPartners] = useState([]);
    const getPartners = async () => {
        let result = await ItrApiService.GET({
            url: 'RevOps/PartnerInformation'
        });
        if (result.result.isSuccess == true) {
            let getPartnerDetails = result.result.data;
            setAllPartners(getPartnerDetails);
        }
        else if (result.result.isSuccess == false) {
            NotificationManager.error(result.result.message);
        }
    }
    const [datas, setDatas] = useState({
        "billerInfoID": 0,
        "businessName": "",
        "supportEmail": "",
        "supportPhone": "",
        "businessWebsite": "",
        "businessLogo": "",
        "addressLine1": "",
        "addressLine2": "",
        "city": "",
        "state": "",
        "pinCode": "",
        "country": "",
        "cin": "",
        "pan": "",
        "gst": "",
        "tan": ""
    });

    const [currencyData, setCurrencyData] = useState([]);

    const getCurrency = () => {
        ItrApiService.GET({
            url: 'RevOps/CurrencyExchangeRate/GetAllCurrencyExchangeRate'
        }).then(res => {
            if (res.Success == true) {
                if (res.result.isSuccess == true) {
                    if (res.result.data) {
                        setCurrencyData(res.result.data);
                    }
                } else {
                    NotificationManager.error(res.result.message);
                }
            } else {
                NotificationManager.error(res.message);
            }
            setLoading(false);
        });
    }

    useEffect(() => {
        setLoading(true);
        getPartners();
        getCurrency();
        getUdo();
        let params = null;
        let value = localStorage.getItem('passData');
        if (location.state && location.state.passData) {
            params = location.state.passData
            setSummary(location.state.passData);
        }
        else if (value) {
            params = JSON.parse(value);
            setSummary(JSON.parse(value));
            // setSaveEditCustomer(JSON.parse(value));
        }
        else {
            history.goBack();
        }
        // await getCities();
        // await getCountry();
        // await getZipCodes();
        // await getStates();
        setLoading(false);
    }, []);


    let history = useHistory();
    const [handel, setHandel] = useState(false);

    const [platformAcc, setPlatformAcc] = useState(false);

    const platformAccess = (e) => {
        setLoading(true);
        ItrApiService.POST({
            url: 'RevOps/Entity/PlatformAccess',
            data: {
                entityID: summary.entityID,
                entityCode: summary.entityCode,
                isAccess: e
            }
        }).then(res => {
            if (res.Success == true) {
                if (res.result.isSuccess == true) {
                    setLoading(false);
                    NotificationManager.success('Successfully Updated');
                    history.push('/customers');
                }
                else {
                    setPlatformAcc(false);
                    setLoading(false);
                    NotificationManager.error(res.result.message);
                }
            }
            else {
                setPlatformAcc(false);
                setLoading(false);
                NotificationManager.error(res.message);
            }
        })
    }

    return (
        <div>
            <NxLoader state={loading} />
            <Headers />
            {/* <NxBreadcrumb className="navigation-breadcrumb" data={[
                {
                    name: 'Home',
                    onClick: () => {
                        history.push('/partner-board');
                    }
                },
                {
                    name: 'Enquiry List',
                    onClick: () => {
                        history.push('/enquiry');
                    }
                }
            ]} /> */}
            <NxTitleTab className="title-tab" title={summary?.companyName} onCloseClick={() => {
                history.goBack();
                localStorage.removeItem('passData');
            }} />

            <div className="mx-5 text-end">
                <div className="mt-2 d-flex justify-content-end">
                    <button className="btn btn-sm btn-primary"
                        onClick={() => {
                            let obj = {
                                tcFirstName: true,
                                tcLastName: true,
                                tcDesignation: true,
                                tcDepartment: true,
                                tcPhone: true,
                                tcEmail: true,

                                acFirstName: true,
                                acLastName: true,
                                acDesignation: true,
                                acDepartment: true,
                                acPhone: true,
                                acEmail: true,

                                bcFirstName: true,
                                bcLastName: true,
                                bcDesignation: true,
                                bcDepartment: true,
                                bcPhone: true,
                                bcEmail: true
                            }

                            if (!summary.technicalContact) {
                                obj = {
                                    tcFirstName: false,
                                    tcLastName: false,
                                    tcDesignation: false,
                                    tcDepartment: false,
                                    tcPhone: false,
                                    tcEmail: false,

                                    acFirstName: false,
                                    acLastName: false,
                                    acDesignation: false,
                                    acDepartment: false,
                                    acPhone: false,
                                    acEmail: false,

                                    bcFirstName: false,
                                    bcLastName: false,
                                    bcDesignation: false,
                                    bcDepartment: false,
                                    bcPhone: false,
                                    bcEmail: false
                                }
                                setVald({ ...vald1, ...obj })
                            } else {
                                if (!summary.technicalContact.firstName
                                    || summary.technicalContact.firstName == ''
                                    || !summary.technicalContact.lastName
                                    || summary.technicalContact.lastName == ''
                                    || !summary.administrativeContact.designation
                                    || summary.administrativeContact.designation == ''
                                    || !summary.administrativeContact.department
                                    || summary.administrativeContact.department == ''
                                    || !summary.technicalContact.phoneNo
                                    || summary.technicalContact.phoneNo == ''
                                    || !summary.technicalContact.emailID
                                    || summary.technicalContact.emailID == ''
                                    || !summary.administrativeContact.firstName
                                    || summary.administrativeContact.firstName == ''
                                    || !summary.administrativeContact.lastName
                                    || summary.administrativeContact.lastName == ''
                                    || !summary.administrativeContact.designation
                                    || summary.administrativeContact.designation == ''
                                    || !summary.administrativeContact.department
                                    || summary.administrativeContact.department == ''
                                    || !summary.administrativeContact.phoneNo
                                    || summary.administrativeContact.phoneNo == ''
                                    || !summary.administrativeContact.emailID
                                    || summary.administrativeContact.emailID == ''

                                    || !summary.billingContact.firstName
                                    || summary.billingContact.firstName == ''
                                    || !summary.billingContact.lastName
                                    || summary.billingContact.lastName == ''
                                    || !summary.billingContact.designation
                                    || summary.billingContact.designation == ''
                                    || !summary.billingContact.department
                                    || summary.billingContact.department == ''
                                    || !summary.billingContact.phoneNo
                                    || summary.billingContact.phoneNo == ''
                                    || !summary.billingContact.emailID
                                    || summary.billingContact.emailID == ''
                                ) {
                                    if (!summary.technicalContact.firstName || summary.technicalContact.firstName == '') obj = { ...obj, tcFirstName: false }
                                    if (!summary.technicalContact.lastName || summary.technicalContact.lastName == '') obj = { ...obj, tcLastName: false }
                                    if (!summary.technicalContact.designation || summary.technicalContact.designation == '') obj = { ...obj, tcDesignation: false }
                                    if (!summary.technicalContact.department || summary.technicalContact.department == '') obj = { ...obj, tcDepartment: false }
                                    if (!summary.technicalContact.phoneNo || summary.technicalContact.phoneNo == '') obj = { ...obj, tcPhone: false }
                                    if (!summary.technicalContact.emailID || summary.technicalContact.emailID == '') obj = { ...obj, tcEmail: false }

                                    if (!summary.administrativeContact.firstName || summary.administrativeContact.firstName == '') obj = { ...obj, acFirstName: false }
                                    if (!summary.administrativeContact.lastName || summary.administrativeContact.lastName == '') obj = { ...obj, acLastName: false }
                                    if (!summary.administrativeContact.designation || summary.administrativeContact.designation == '') obj = { ...obj, acDesignation: false }
                                    if (!summary.administrativeContact.department || summary.administrativeContact.department == '') obj = { ...obj, acDepartment: false }
                                    if (!summary.administrativeContact.phoneNo || summary.administrativeContact.phoneNo == '') obj = { ...obj, acPhone: false }
                                    if (!summary.administrativeContact.emailID || summary.administrativeContact.emailID == '') obj = { ...obj, acEmail: false }

                                    if (!summary.billingContact.firstName || summary.billingContact.firstName == '') obj = { ...obj, bcFirstName: false }
                                    if (!summary.billingContact.lastName || summary.billingContact.lastName == '') obj = { ...obj, bcLastName: false }
                                    if (!summary.billingContact.designation || summary.billingContact.designation == '') obj = { ...obj, bcDesignation: false }
                                    if (!summary.billingContact.department || summary.billingContact.department == '') obj = { ...obj, bcDepartment: false }
                                    if (!summary.billingContact.phoneNo || summary.billingContact.phoneNo == '') obj = { ...obj, bcPhone: false }
                                    if (!summary.billingContact.emailID || summary.billingContact.emailID == '') {
                                        obj = { ...obj, bcEmail: false }
                                    }
                                    setVald({ ...vald1, ...obj });
                                } else {
                                    setVald({ ...vald1, ...obj });
                                }
                            }

                            if (obj.tcFirstName == true && obj.tcLastName == true && obj.tcDesignation == true && obj.tcDepartment == true && obj.tcPhone == true && obj.tcEmail == true &&
                                obj.acFirstName == true && obj.acLastName == true && obj.acDesignation == true && obj.acDepartment == true && obj.acPhone == true && obj.acEmail == true &&
                                obj.bcFirstName == true && obj.bcLastName == true && obj.bcDesignation == true && obj.bcDepartment == true && obj.bcPhone == true && obj.bcEmail == true) {
                                ItrApiService.POST({
                                    url: 'RevOps/Entity/SaveEntity',
                                    data: summary
                                }).then((res) => {
                                    if (res.Success == true) {
                                        if (res.result.isSuccess == true) {
                                            setLoading(false);
                                            message.success('Successfully Updated');
                                            history.push('/customers');
                                        }
                                        else {
                                            setLoading(false);
                                            message.warning(res.result.message);
                                        }
                                    }
                                    else {
                                        message.warning(res.message);
                                        setLoading(false);
                                    }
                                    setLoading(false);
                                })
                            }

                        }}
                    >Update</button>
                </div>
            </div>

            <NxCustomTab
                className="mb-5"
                data={[
                    {
                        name: "Company Information",
                        Components: () => {
                            return (
                                <div className="container-form">
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <div className="row">
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                    <label htmlFor="lastName">Company Name</label>
                                                    <input className="form-control form-control-sm"
                                                        value={summary.companyName}
                                                        onChange={(e) => setSummary({ ...summary, companyName: e.target.value })}
                                                    />
                                                    {/* <p className="summary-data">  {summary.companyName}</p> */}
                                                </div>

                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                    <label htmlFor="firstName">Full Name</label>
                                                    <input className="form-control form-control-sm"
                                                        value={summary.fullName}
                                                        onChange={(e) => setSummary({ ...summary, fullName: e.target.value })}
                                                    />
                                                    {/* <p className="summary-data">  {summary.fullName}</p> */}
                                                </div>

                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                    <label htmlFor="Email">Email</label>
                                                    <input className="form-control form-control-sm"
                                                        value={summary.email}
                                                        onChange={(e) => setSummary({ ...summary, email: e.target.value })}
                                                    />
                                                    {/* <p className="summary-data">  {summary.email}</p> */}
                                                </div>

                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                    <label htmlFor="Phone">Phone</label>
                                                    <input className="form-control form-control-sm"
                                                        minLength="10" maxLength="15"
                                                        value={summary.phone}
                                                        onChange={(e) => {
                                                            var reg = new RegExp('^[0-9]*$');
                                                            if (e.target.value != '' && reg.test(e.target.value) != false) {
                                                                setSummary({ ...summary, phoneNo: e.target.value })
                                                            }
                                                            if (e.target.value == '') {
                                                                setSummary({ ...summary, phoneNo: e.target.value })
                                                            }
                                                        }}
                                                    // onChange={(e) => setSummary({ ...summary, phone: e.target.value })}
                                                    />
                                                    {/* <p className="summary-data">  {summary.phone}</p> */}
                                                </div>

                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                    <label htmlFor="Phone">Role</label>
                                                    <input className="form-control form-control-sm"
                                                        value={summary.role}
                                                        onChange={(e) => setSummary({ ...summary, role: e.target.value })}
                                                    />
                                                    {/* <p className="summary-data">  {summary.role}</p> */}
                                                </div>

                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                    <label htmlFor="Phone">Time to Buy</label>
                                                    <select class="form-select form-select-sm" id="timeToBuy"
                                                        value={summary.timeToBuy}
                                                        onChange={(e) => setSummary({ ...summary, timeToBuy: e.target.value })}
                                                    >
                                                        <option value="" disabled>--Please-select--</option>
                                                        <option value="Immediately" >Immediately</option>
                                                        <option value="Within 30 days" >Within 30 days</option>
                                                        <option value="Within 60 days" >Within 60 days</option>
                                                        <option value="Within 90 days" >Within 90 days</option>
                                                        <option value="Just enquiring" >Just Enquiring</option>
                                                    </select>
                                                    {/* <p className="summary-data">  {summary.timeToBuy}</p> */}
                                                </div>

                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                    <label htmlFor="Phone">Source</label>
                                                    <select class="form-select form-select-sm" aria-label="Default select example"
                                                        disabled
                                                        value={summary.sourcedBy}
                                                        onChange={(e) => setSummary({ ...summary, sourcedBy: e.target.value })}
                                                    >
                                                        <option disabled>--Please-select--</option>
                                                        <option value="Advertisement" >Advertisement</option>
                                                        <option value="Cold Call" >Cold Call</option>
                                                        <option value="Employee Referal" >Employee Referal</option>
                                                        <option value="External Referal" >External Referal</option>
                                                        <option value="Online" >Online</option>
                                                        <option value="Partner" >Partner</option>
                                                        <option value="Public Relations" >Public Relations</option>
                                                        <option value="Sales Mail" >Sales Mail</option>
                                                        <option value="Seminar Partner" >Seminar Partner</option>
                                                        <option value="Seminar Internal" >Seminar Internal</option>
                                                        <option value="Trade Show" >Trade Show</option>
                                                        <option value="Database" >Database</option>
                                                    </select>
                                                    {/* <p className="summary-data">  {summary.sourcedBy}</p> */}
                                                </div>

                                                <div className={`col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2 ${summary.sourcedBy == 'Partner' ? 'mb-0' : 'mb-5'}`}>
                                                    <label htmlFor="Phone">Status</label>
                                                    <select class="form-select form-select-sm" aria-label="Default select example"
                                                        value={summary.role}
                                                        disabled
                                                        onChange={(e) => setSummary({ ...summary, role: e.target.value })}
                                                    >
                                                        <option disabled>--Please-select--</option>
                                                        {allUdo && allUdo.map((data, index) => {
                                                            return <option key={index} value={data.UDOptionName}> {data.UDOptionName} </option>
                                                        })}
                                                    </select>
                                                    {/* {allUdo && allUdo.map((data, index) => {
                                                        return <p className="summary-data">  {data.UDOptionName}</p>
                                                    })} */}
                                                    {/* <p className="summary-data">  {summary.status}</p> */}
                                                    {/* <p className="summary-data">  Active </p> */}
                                                </div>

                                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2 mb-5" hidden={summary.sourcedBy == 'Partner' ? false : true}>
                                                    <label htmlFor="partner">Partner</label>
                                                    <select class="form-select form-select-sm" aria-label="Default select example"
                                                        disabled
                                                        value={summary.partnerID}
                                                        onChange={(e) => setSummary({ ...summary, partnerID: e.target.value })}
                                                    >
                                                        <option value="" selected hidden></option>
                                                        <option disabled>--Please-select--</option>
                                                        {allPartners && allPartners.map((data, index) => {
                                                            return <option key={index} value={data.partnerInformationID}> {data.partnerName} </option>
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    },
                    {
                        name: "Contact Information",
                        Components: () => <div className="container-form">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-2">
                                    <div className="d-flex justify-content-between align-content-center">
                                        <label className="contacts1 contacts fw-bold">Technical Contact <span style={{ color: 'red' }}> * </span></label>
                                        <div className="d-flex align-items-center copy-content">
                                            <span style={{ fontSize: '11px' }} className="text-primary">Copy to All</span>
                                            <input type="checkbox" className="mx-1 chx form-check-input" checked={copyTo}
                                                onChange={(e) => {
                                                    setCopyTo(e.target.checked);
                                                    let vals = summary.technicalContact;
                                                    if (e.target.checked == true) {
                                                        setSummary({
                                                            ...summary, administrativeContact: {
                                                                ...summary.administrativeContact, firstName: summary.technicalContact.firstName,
                                                                lastName: summary.technicalContact.lastName, designation: summary.technicalContact.designation, department: summary.technicalContact.department,
                                                                phoneNo: summary.technicalContact.phoneNo, emailID: summary.technicalContact.emailID
                                                            }, billingContact: {
                                                                ...summary.billingContact, firstName: summary.technicalContact.firstName,
                                                                lastName: summary.technicalContact.lastName, designation: summary.technicalContact.designation, department: summary.technicalContact.department,
                                                                phoneNo: summary.technicalContact.phoneNo, emailID: summary.technicalContact.emailID
                                                            }

                                                        })

                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                            <label htmlFor="firstName">First Name</label>
                                            <input className="form-control form-control-sm"
                                                value={summary.technicalContact?.firstName}
                                                onChange={(e) => setSummary({ ...summary, technicalContact: { ...summary.technicalContact, firstName: e.target.value } })}
                                            />
                                            <div className={vald1.tcFirstName == false ? "invalid-data" : 'd-none'}>
                                                Please Enter First Name.
                                            </div>
                                            {/* <p> {summary?.technicalContact?.firstName ? summary?.technicalContact?.firstName : '-'} </p> */}
                                        </div>

                                        <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                            <label htmlFor="lastName">Last Name</label>
                                            <input className="form-control form-control-sm"
                                                value={summary.technicalContact?.lastName}
                                                onChange={(e) => setSummary({ ...summary, technicalContact: { ...summary.technicalContact, lastName: e.target.value } })}
                                            />
                                            <div className={vald1.tcLastName == false ? "invalid-data" : 'd-none'}>
                                                Please Enter Last Name.
                                            </div>
                                            {/* <p> {summary?.technicalContact?.lastName ? summary?.technicalContact?.lastName : '-'} </p> */}
                                        </div>

                                        <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                            <label htmlFor="firstName">Designation</label>
                                            <input className="form-control form-control-sm"
                                                value={summary.technicalContact?.designation}
                                                onChange={(e) => setSummary({ ...summary, technicalContact: { ...summary.technicalContact, designation: e.target.value } })}
                                            />
                                            <div className={vald1.tcDesignation == false ? "invalid-data" : 'd-none'}>
                                                Please Enter Designation.
                                            </div>
                                            {/* <p> {summary?.technicalContact?.designation ? summary?.technicalContact?.designation : '-'} </p> */}
                                        </div>

                                        <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                            <label htmlFor="firstName">Department</label>
                                            <input className="form-control form-control-sm"
                                                value={summary.technicalContact?.department}
                                                onChange={(e) => setSummary({ ...summary, technicalContact: { ...summary.technicalContact, department: e.target.value } })}
                                            />
                                            <div className={vald1.tcDepartment == false ? "invalid-data" : 'd-none'}>
                                                Please Enter Department.
                                            </div>
                                            {/* <p> {summary?.technicalContact?.department ? summary?.technicalContact?.department : '-'} </p> */}
                                        </div>

                                        <div className="col-12 col-sm-6 col-lg-12 col-xl-12 col-md-12 mt-2">
                                            <label htmlFor="firstName">Phone</label>
                                            <input className="form-control form-control-sm"
                                                minLength="10" maxLength="15"
                                                value={summary.technicalContact?.phoneNo}
                                                onChange={(e) => {
                                                    var reg = new RegExp('^[0-9]*$');
                                                    if (e.target.value != '' && reg.test(e.target.value) != false) {
                                                        setSummary({ ...summary, technicalContact: { ...summary.technicalContact, phoneNo: e.target.value } })
                                                    }
                                                    if (e.target.value == '') {
                                                        setSummary({ ...summary, technicalContact: { ...summary.technicalContact, phoneNo: e.target.value } })
                                                    }
                                                }}
                                            // onChange={(e) => setSummary({ ...summary, technicalContact: { ...summary.technicalContact, phoneNo: e.target.value } })}
                                            />
                                            <div className={vald1.tcPhone == false ? "invalid-data" : 'd-none'}>
                                                Please Enter Phone.
                                            </div>
                                            {/* <p> {summary?.technicalContact?.phoneNo ? summary?.technicalContact?.phoneNo : '-'} </p> */}
                                        </div>

                                        <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                            <label htmlFor="firstName">Email</label>
                                            <input className="form-control form-control-sm"
                                                value={summary.technicalContact?.emailID}
                                                onChange={(e) => setSummary({ ...summary, technicalContact: { ...summary.technicalContact, emailID: e.target.value } })}
                                            />
                                            <div className={vald1.tcEmail == false ? "invalid-data" : 'd-none'}>
                                                Please Enter Email.
                                            </div>
                                            {/* <p> {summary?.technicalContact?.emailID ? summary?.technicalContact?.emailID : '-'} </p> */}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-2">
                                    <div className="d-flex justify-content-between align-content-center">
                                        <label className="contacts1 contacts fw-bold">Administrative Contact</label>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                            <label htmlFor="firstName">First Name</label>
                                            <input className="form-control form-control-sm"
                                                value={summary.administrativeContact?.firstName}
                                                onChange={(e) => setSummary({ ...summary, administrativeContact: { ...summary.administrativeContact, firstName: e.target.value } })}
                                            />
                                            <div className={vald1.acFirstName == false ? "invalid-data" : 'd-none'}>
                                                Please Enter First Name.
                                            </div>
                                            {/* <p> {summary?.administrativeContact?.firstName ? summary?.administrativeContact?.firstName : '-'} </p> */}
                                        </div>

                                        <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                            <label htmlFor="lastName">Last Name</label>
                                            <input className="form-control form-control-sm"
                                                value={summary.administrativeContact?.lastName}
                                                onChange={(e) => setSummary({ ...summary, administrativeContact: { ...summary.administrativeContact, lastName: e.target.value } })}
                                            />
                                            <div className={vald1.acLastName == false ? "invalid-data" : 'd-none'}>
                                                Please Enter Last Name.
                                            </div>
                                            {/* <p> {summary?.administrativeContact?.lastName ? summary?.administrativeContact?.lastName : '-'} </p> */}
                                        </div>

                                        <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                            <label htmlFor="firstName">Designation</label>
                                            <input className="form-control form-control-sm"
                                                value={summary.administrativeContact?.designation}
                                                onChange={(e) => setSummary({ ...summary, administrativeContact: { ...summary.administrativeContact, designation: e.target.value } })}
                                            />
                                            <div className={vald1.acDesignation == false ? "invalid-data" : 'd-none'}>
                                                Please Enter Designation.
                                            </div>
                                            {/* <p> {summary?.administrativeContact?.designation ? summary?.administrativeContact?.designation : '-'} </p> */}
                                        </div>

                                        <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                            <label htmlFor="firstName">Department</label>
                                            <input className="form-control form-control-sm"
                                                value={summary.administrativeContact?.department}
                                                onChange={(e) => setSummary({ ...summary, administrativeContact: { ...summary.administrativeContact, department: e.target.value } })}
                                            />
                                            <div className={vald1.acDepartment == false ? "invalid-data" : 'd-none'}>
                                                Please Enter Department.
                                            </div>
                                            {/* <p> {summary?.administrativeContact?.department ? summary?.administrativeContact?.department : '-'} </p> */}
                                        </div>

                                        <div className="col-12 col-sm-6 col-lg-12 col-xl-12 col-md-12 mt-2">
                                            <label htmlFor="firstName">Phone</label>
                                            <input className="form-control form-control-sm"
                                                minLength="10" maxLength="15"
                                                value={summary.administrativeContact?.phoneNo}
                                                onChange={(e) => {
                                                    var reg = new RegExp('^[0-9]*$');
                                                    if (e.target.value != '' && reg.test(e.target.value) != false) {
                                                        setSummary({ ...summary, administrativeContact: { ...summary.administrativeContact, phoneNo: e.target.value } })
                                                    }
                                                    if (e.target.value == '') {
                                                        setSummary({ ...summary, administrativeContact: { ...summary.administrativeContact, phoneNo: e.target.value } })
                                                    }
                                                }
                                                    // setSummary({...summary, administrativeContact: {...summary.administrativeContact, phoneNo: e.target.value } })
                                                }
                                            />
                                            <div className={vald1.acPhone == false ? "invalid-data" : 'd-none'}>
                                                Please Enter Phone.
                                            </div>
                                            {/* <p> {summary?.administrativeContact?.phoneNo ? summary?.administrativeContact?.phoneNo : '-'} </p> */}
                                        </div>

                                        <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                            <label htmlFor="firstName">Email</label>
                                            <input className="form-control form-control-sm"
                                                value={summary.administrativeContact?.emailID}
                                                onChange={(e) => setSummary({ ...summary, administrativeContact: { ...summary.administrativeContact, emailID: e.target.value } })}
                                            />
                                            <div className={vald1.acEmail == false ? "invalid-data" : 'd-none'}>
                                                Please Enter Email.
                                            </div>
                                            {/* <p> {summary?.administrativeContact?.emailID ? summary?.administrativeContact?.emailID : '-'} </p> */}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-2">
                                    <label className="contacts1 contacts fw-bold">Billing Contact</label>
                                    <div className="row">
                                        <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                            <label htmlFor="firstName">First Name</label>
                                            <input className="form-control form-control-sm"
                                                value={summary.billingContact?.firstName}
                                                onChange={(e) => setSummary({ ...summary, billingContact: { ...summary.billingContact, firstName: e.target.value } })}
                                            />
                                            <div className={vald1.bcFirstName == false ? "invalid-data" : 'd-none'}>
                                                Please Enter First Name.
                                            </div>
                                            {/* <p> {summary?.billingContact?.firstName ? summary?.billingContact?.firstName : '-'} </p> */}
                                        </div>

                                        <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                            <label htmlFor="lastName">Last Name</label>
                                            <input className="form-control form-control-sm"
                                                value={summary.billingContact?.lastName}
                                                onChange={(e) => setSummary({ ...summary, billingContact: { ...summary.billingContact, lastName: e.target.value } })}
                                            />
                                            <div className={vald1.bcLastName == false ? "invalid-data" : 'd-none'}>
                                                Please Enter Last Name.
                                            </div>
                                            {/* <p> {summary?.billingContact?.lastName ? summary?.billingContact?.lastName : '-'} </p> */}
                                        </div>

                                        <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                            <label htmlFor="firstName">Designation</label>
                                            <input className="form-control form-control-sm"
                                                value={summary.billingContact?.designation}
                                                onChange={(e) => setSummary({ ...summary, billingContact: { ...summary.billingContact, designation: e.target.value } })}
                                            />
                                            <div className={vald1.bcDesignation == false ? "invalid-data" : 'd-none'}>
                                                Please Enter Designation.
                                            </div>
                                            {/* <p> {summary?.billingContact?.designation ? summary?.billingContact?.designation : '-'} </p> */}
                                        </div>

                                        <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                            <label htmlFor="firstName">Department</label>
                                            <input className="form-control form-control-sm"
                                                value={summary.billingContact?.department}
                                                onChange={(e) => setSummary({ ...summary, billingContact: { ...summary.billingContact, department: e.target.value } })}
                                            />
                                            <div className={vald1.bcDepartment == false ? "invalid-data" : 'd-none'}>
                                                Please Enter Department.
                                            </div>
                                            {/* <p> {summary?.billingContact?.department ? summary?.billingContact?.department : '-'} </p> */}
                                        </div>

                                        <div className="col-12 col-sm-6 col-lg-12 col-xl-12 col-md-12 mt-2">
                                            <label htmlFor="firstName">Phone</label>
                                            <input className="form-control form-control-sm"
                                                minLength="10" maxLength="15"
                                                value={summary.billingContact?.phoneNo}
                                                onChange={(e) => {
                                                    var reg = new RegExp('^[0-9]*$');
                                                    if (e.target.value != '' && reg.test(e.target.value) != false) {
                                                        setSummary({ ...summary, billingContact: { ...summary.billingContact, phoneNo: e.target.value } })
                                                    }
                                                    if (e.target.value == '') {
                                                        setSummary({ ...summary, billingContact: { ...summary.billingContact, phoneNo: e.target.value } })
                                                    }
                                                }}
                                            // onChange={(e) => setSummary({ ...summary, billingContact: { ...summary.billingContact, phoneNo: e.target.value } })}
                                            />
                                            <div className={vald1.bcPhone == false ? "invalid-data" : 'd-none'}>
                                                Please Enter Phone.
                                            </div>
                                            {/* <p> {summary?.billingContact?.phoneNo ? summary?.billingContact?.phoneNo : '-'} </p> */}
                                        </div>

                                        <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2 mb-5">
                                            <label htmlFor="firstName">Email</label>
                                            <input className="form-control form-control-sm"
                                                value={summary.billingContact?.emailID}
                                                onChange={(e) => setSummary({ ...summary, billingContact: { ...summary.billingContact, emailID: e.target.value } })}
                                            />
                                            <div className={vald1.bcEmail == false ? "invalid-data" : 'd-none'}>
                                                Please Enter Email.
                                            </div>
                                            {/* <p> {summary?.billingContact?.emailID ? summary?.billingContact?.emailID : '-'} </p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    },
                    {
                        name: "Commercial Information",
                        Components: () => <div className="container-form">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                                            <label >Company identification number (CIN)</label>
                                            <input className="form-control form-control-sm"
                                                value={summary.cin}
                                                onChange={(e) => setSummary({ ...summary, cin: e.target.value })}
                                            />
                                            {/* <p className="summary-data">  {summary.cin}</p> */}
                                        </div>

                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                                            <label >Business PAN (Permanent Account Number)</label>
                                            <input className="form-control form-control-sm"
                                                value={summary.pan}
                                                onChange={(e) => setSummary({ ...summary, pan: e.target.value })}
                                            />
                                            {/* <p className="summary-data">  {summary.pan}</p> */}
                                        </div>

                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                                            <label >Goods & Services Tax (GST) Identification number</label>
                                            <input className="form-control form-control-sm"
                                                value={summary.gst}
                                                onChange={(e) => setSummary({ ...summary, gst: e.target.value })}
                                            />
                                            {/* <p className="summary-data">  {summary.gst}</p> */}
                                        </div>

                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                                            <label htmlFor="Phone">Tax deduction account number (TAN)</label>
                                            <input className="form-control form-control-sm"
                                                value={summary.tan}
                                                onChange={(e) => setSummary({ ...summary, tan: e.target.value })}
                                            />
                                            {/* <p className="summary-data">  {summary.tan}</p> */}
                                        </div>

                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2 mb-5">
                                            <label htmlFor="Phone">Currency</label>
                                            <select className="form-select form-select-sm"
                                                value={summary.currency}
                                                onChange={(e) => setSummary({ ...summary, currency: e.target.value })}
                                            >
                                                <option value="0" selected>--Please-select--</option>
                                                {currencyData && currencyData.map((data, index) => {
                                                    return <option key={index} value={data.currency.currencyID}> {data.currency.currencyName} </option>
                                                })}
                                            </select>
                                            {/* <p className="summary-data"> {summary.currencyName}</p> */}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                                            <label >Agreement</label>
                                            <input className="form-control form-control-sm" id="firstName" type="file"
                                                onChange={(e) => {
                                                    let img = e.target.files[0];
                                                    const fileReader = new FileReader();
                                                    fileReader.readAsDataURL(img)
                                                    fileReader.onload = async () => {
                                                        setSummary({ ...summary, entityBoarding: { ...summary.entityBoarding, agreement: fileReader.result } })
                                                        return fileReader.result
                                                    }
                                                    fileReader.onerror = async () => {
                                                        return false
                                                    }
                                                }}
                                            />
                                            {/* <p className="summary-data">
                                                {summary?.entityBoarding?.agreement ? <a href={summary?.entityBoarding?.agreement ? summary?.entityBoarding?.agreement : '-'} download target="_blank">
                                                    Click to download <FontAwesomeIcon icon={faDownload} size='xs' />
                                                </a> : '-'}
                                            </p> */}
                                        </div>

                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                                            <label >NCNDA</label>
                                            <input className="form-control form-control-sm" id="firstName" type="file"
                                                onChange={(e) => {
                                                    let img = e.target.files[0];
                                                    const fileReader = new FileReader();
                                                    fileReader.readAsDataURL(img)
                                                    fileReader.onload = async () => {
                                                        setSummary({ ...summary, entityBoarding: { ...summary.entityBoarding, ncnda: fileReader.result } })
                                                        return fileReader.result
                                                    }
                                                    fileReader.onerror = async () => {
                                                        return false
                                                    }
                                                }}
                                            />
                                            {/* <p className="summary-data">
                                                {summary?.entityBoarding?.ncnda ? <a href={summary?.entityBoarding?.ncnda ? summary?.entityBoarding?.ncnda : '-'} download target="_blank">
                                                    Click to download <FontAwesomeIcon icon={faDownload} size='xs' />
                                                </a> : '-'}
                                            </p> */}
                                        </div>

                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2 mb-5">
                                            <label >Terms of use</label>
                                            <input className="form-control form-control-sm" id="firstName" type="file"
                                                onChange={(e) => {
                                                    let img = e.target.files[0];
                                                    const fileReader = new FileReader();
                                                    fileReader.readAsDataURL(img)
                                                    fileReader.onload = async () => {
                                                        setSummary({ ...summary, entityBoarding: { ...summary.entityBoarding, termsOfUse: fileReader.result } })
                                                        return fileReader.result
                                                    }
                                                    fileReader.onerror = async () => {
                                                        return false
                                                    }
                                                }}
                                            />
                                            {/* <p className="summary-data">
                                                {summary?.entityBoarding?.termsOfUse ? <a href={summary?.entityBoarding?.termsOfUse ? summary?.entityBoarding?.termsOfUse : '-'} download target="_blank">
                                                    Click to download <FontAwesomeIcon icon={faDownload} size='xs' />
                                                </a> : '-'}
                                            </p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                ]}
            />
            <NxFooter companyName="© 2022 Ithred Solutions" ExtraContent={[
                { name: 'Terms of Service' },
                { name: 'privacy' },
            ]} />
        </div>
    )
}