import React, { useState, useEffect } from 'react';
import './partnerSubscriptionTab.css';
import { useHistory } from 'react-router-dom';
import AppStore from '../../../assets/appStore.png'
import Subscriptions from '../../../assets/subscriptions.png';
import Assignments from '../../../assets/assignments.png';
import { Tabs, Switch, Rate } from 'antd';
import { ItrApiService } from '@afiplfeed/itr-ui'
import { NxLoader, NxFooter, NxTitleTab } from '@afiplfeed/itr-app';
import Headers from '../../../Screens/Headers/Headers';
import { NotificationManager } from "react-notifications";

export default function PartnerSubscriptionTab({ data = [{ image: '', heding: '', info: '' }] }) {

    const { TabPane } = Tabs;

    let history = useHistory();

    // subscription 
    const [filtersubscrption, setFiltersubscrption] = useState([
        { id: 1, name: "all", isChecked: true },
        { id: 2, name: "available", isChecked: false },
        { id: 3, name: "subscribed", isChecked: false },
    ])

    const handleChange = (e, index, id) => {
        if (e == true) {
            if (id == 1) {
                setFiltersubscrption([
                    { id: 1, name: "all", isChecked: true },
                    { id: 2, name: "available", isChecked: false },
                    { id: 3, name: "subscribed", isChecked: false },
                ])
            } else if (id == 2) {
                setFiltersubscrption([
                    { id: 1, name: "all", isChecked: false },
                    { id: 2, name: "available", isChecked: true },
                    { id: 3, name: "subscribed", isChecked: false },
                ])
            } else {
                setFiltersubscrption([
                    { id: 1, name: "all", isChecked: false },
                    { id: 2, name: "available", isChecked: false },
                    { id: 3, name: "subscribed", isChecked: true },
                ])
            }
        }
    }

    const [subscriptionData, setSubscriptionData] = useState([]);
    const [subscriptionId, setSubscriptionId] = useState('');
    const [clientId, setClientid] = useState('');
    const [enrollmentId, setEnrollmentId] = useState('');
    const [loader, setLoader] = useState(false);

    // list-Subscription-Api
    const getData = async () => {
        let result = await ItrApiService.GET({
            url: 'DevOps/Enrollment/EnrollmentWithSubscription'
        });
        if (result.result.isSuccess == true) {
            let data = result.result.data;
            for (let id of data) {
                setSubscriptionId(id.clientSubscriptionID);
                setClientid(id.clientID);
                setEnrollmentId(id.id);
            }
            if (data === null || !data.length) {
                // NotificationManager.error(result.result.message);
            }
            else {
                setSubscriptionData(data);
                // await splits(data,setDatas);
                // await setpassCustomers(getCustomerDetails);
            }
            // setCustomers(getCustomerDetails);
        }
        else if (result.result.isSuccess == false) {
            // NotificationManager.error(result.result.message);
            // sessionStorage.clear();
            // history.push('/')
        }
    }

    const [noOfUnit, setNoOfUnit] = useState();

    const [handel, setHandel] = useState(false);
    const [handel2, setHandel2] = useState(false);

    var saveSubscribe = {
        clientSubscriptionID: subscriptionId,
        enrollmentID: enrollmentId,
        clientID: clientId,
        noofUnit: parseInt(noOfUnit)
    }

    var unSubscribe = {
        clientSubscriptionID: subscriptionId,
        clientID: clientId
    }

    useEffect(() => {
        setLoader(true);
        getData();
        setLoader(false);
    }, [])

    return (
        <>
            <NxLoader state={loader} />
            <Headers />
            <NxTitleTab className="title-tab" title="Subscription" onCloseClick={() => {
                history.push('/partner-board');
            }} />

            <div className="card m-3 mt-0">
                <div className="card-body">
                    <div className="container1 mt-0 nx-subscription-page mb-5">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-9 mt-2">
                                <div className="row">
                                    <div className="col-12 col-sm-6 sol-md-4 col-lg-6 col-xl-4 mt-2 subscriptions">
                                        <div className="card">
                                            <div className="card-body">
                                                <img src={AppStore} width="70" />
                                                <div className="apps-store-descriptions">
                                                    <p className="m-0 count text-success">2</p>
                                                    <p className="m-0 title">Apps Store</p>
                                                    <h6 className="descriptions">No of Apps in store​</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-6 sol-md-4 col-lg-6 col-xl-4 mt-2 subscriptions">
                                        <div className="card">
                                            <div className="card-body">
                                                <img src={Subscriptions} width="70" />
                                                <div className="apps-store-descriptions">
                                                    <p className="m-0 count text-success">2 - <span>10</span></p>
                                                    <p className="m-0 title">Subscriptions</p>
                                                    <h6 className="descriptions">Apps subscribed / License Issued​​</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-6 sol-md-4 col-lg-6 col-xl-4 mt-2 subscriptions">
                                        <div className="card">
                                            <div className="card-body">
                                                <img src={Assignments} width="70" />
                                                <div className="apps-store-descriptions">
                                                    <p className="m-0 count text-success">2 - <span className="text-danger">10</span></p>
                                                    <p className="m-0 title">Assignments</p>
                                                    <h6 className="descriptions">License Assigned / License Available​​</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="nexap-subscriptions mt-3">
                                        <ul>
                                            {filtersubscrption.map((data, index) => {
                                                return <li key={index}>
                                                    <Switch key={data.id} defaultChecked={data.isChecked} onClick={(e) => {
                                                        handleChange(e, index, data.id)
                                                    }} checked={data.isChecked} />
                                                    <label htmlFor="all"> {data.name} </label>
                                                </li>
                                            })}
                                        </ul>
                                    </div>

                                    <div className="nx-card mb-5">
                                        <div className="nx-card-body mt-2 nx-subscription-card">
                                            <div className="row justify-content-start">
                                                {subscriptionData.map((data, index) => {
                                                    return <div key={index} className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-3 nx-subscription-test flip-card" onClick={() => setHandel(true)}>
                                                        <div className="card flip-card-inner">
                                                            <div className="card-front flip-card-front">
                                                                <div className='card-body'>
                                                                    <div className="nx-subscription-img">
                                                                        <img src={AppStore} width="40" />
                                                                    </div>

                                                                    <div className="nx-subscription-titles">
                                                                        <label>{data.serviceName}</label>
                                                                        <div className="nx-subscription-info">
                                                                            <p> {data.publisher} </p>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="nx-subscription-info mt-5 container">
                                                                    <hr />
                                                                    <div className="row">
                                                                        <div className="col-3 col-lg-3 col-sm-3 col-md-3 col-xl-3 text-center">
                                                                            <label>License</label>
                                                                            <p> {data.totalLicense} </p>
                                                                        </div>

                                                                        <div className="col-3 col-lg-3 col-sm-3 col-md-3 col-xl-3 text-center">
                                                                            <label>Assigned</label>
                                                                            <p> {data.assigned} </p>
                                                                        </div>

                                                                        <div className="col-3 col-lg-3 col-sm-3 col-md-3 col-xl-3 text-center">
                                                                            <label>Open</label>
                                                                            <p> {data.unassigned} </p>
                                                                        </div>

                                                                        <div className="col-3 col-lg-3 col-sm-3 col-md-3 col-xl-3 text-center">
                                                                            <label>Cost</label>
                                                                            <p> {data.castUnit} </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="flip-card-back">
                                                                <div className="nx-card-subscription-details ">
                                                                    <Tabs>
                                                                        <TabPane tab="Description" key="1">
                                                                            <div className="subscription-card-body">
                                                                                {data.description}
                                                                            </div>
                                                                        </TabPane>
                                                                        <TabPane tab="About Us" key="2">
                                                                            <div className="subscription-card-body">
                                                                                <h6> {data.overview} </h6>
                                                                            </div>
                                                                        </TabPane>
                                                                        <TabPane tab="Developer" key="3">
                                                                            <div className="subscription-card-body">
                                                                                {data.publisher}<br />
                                                                                {data.aboutthePublisher}
                                                                            </div>
                                                                        </TabPane>
                                                                    </Tabs>
                                                                    <div className="nx-card-subscription-button">
                                                                        {data.clientSubscriptionID == 0 ?
                                                                            <>
                                                                                <div className="row p-3 justify-content-center align-items-center">
                                                                                    <div className="col-6 col-sm-6 col-lg-6 col-xl-6 col-lg-6">
                                                                                        <input className="form-control form-control-sm" placeholder="No of unit" value={noOfUnit}
                                                                                            onChange={(e) => setNoOfUnit(e.target.value)} />
                                                                                    </div>
                                                                                    <div className="col-6 col-sm-6 col-lg-6 col-xl-6 col-lg-6">
                                                                                        <button className="btn btn-primary" onClick={() => {
                                                                                            ItrApiService.POST({
                                                                                                url: 'EntityPlatform/ClientSubscription/SaveSubscription',
                                                                                                data: saveSubscribe
                                                                                            })
                                                                                        }}>Subscribe</button>
                                                                                    </div>
                                                                                </div>
                                                                            </> :
                                                                            <>
                                                                                <button className="btn btn-primary" onClick={() => {
                                                                                    ItrApiService.POST({
                                                                                        url: 'EntityPlatform/ClientSubscription/SaveUnsubscription',
                                                                                        data: unSubscribe
                                                                                    })
                                                                                }}>Unsubscribe</button>
                                                                                <button className="btn btn-primary">License Details</button>
                                                                            </>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-3 mt-3">
                                <div className="card">
                                    <h5 className="m-4 fw-bold">Feedback</h5>
                                    <div className="card-body">
                                        <div className="apps-store-sort">
                                            <select className="form-select" placeholder="Sort By">
                                                <option selected hidden>Sory By</option>
                                                <option>Maximum Stars</option>
                                                <option>Minimum Stars</option>
                                            </select>
                                        </div>

                                        <div className="card mt-3">
                                            <div className="card-body apps-store-list d-flex align-items-start">
                                                <div className="apps-list-image">
                                                    <img src={AppStore} width="50" />
                                                </div>
                                                <div className="apps-store-details text-start px-3">
                                                    <h6>App 01</h6>
                                                    <h6 className="mb-0">3.4</h6>
                                                    <Rate disabled value={3} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card mt-3">
                                            <div className="card-body apps-store-list d-flex align-items-start">
                                                <div className="apps-list-image">
                                                    <img src={AppStore} width="50" />
                                                </div>
                                                <div className="apps-store-details text-start px-3">
                                                    <h6>App 02</h6>
                                                    <h6 className="mb-0">3.4</h6>
                                                    <Rate disabled value={4} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card mt-3">
                                            <div className="card-body apps-store-list d-flex align-items-start">
                                                <div className="apps-list-image">
                                                    <img src={AppStore} width="50" />
                                                </div>
                                                <div className="apps-store-details text-start px-3">
                                                    <h6>App 03</h6>
                                                    <h6 className="mb-0">3.4</h6>
                                                    <Rate disabled value={2} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <NxFooter ExtraContent={[
                { name: 'Terms of Service' },
                { name: 'privacy' },
            ]} />
            {/* <NxRightDrawer onClose={() => setHandel(false)} show={handel}  ondrawer1Close={() => { setHandel2(false) }} 
            drawerContent={() => <div className="qwerty">
                </div>} 
            /> */}






            {/* <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 mt-3 nx-subscription-test flip-card">
                            <div className="card flip-card-inner">
                                <div className="card-front flip-card-front">
                                    <div className='card-body'>
                                        <div className="nx-subscription-img">
                                            <img src={userWizard} width="40" />
                                        </div>

                                        <div className="nx-subscription-titles">
                                            <label>Test App One</label>
                                            <div className="nx-subscription-info">
                                                <p>test Publisher</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="nx-subscription-info mt-5 container">
                                        <hr/>
                                        <div className="row">
                                            <div className="col-2 col-lg-2 col-sm-2 col-md-2 col-xl-3 text-center">
                                                <label>License</label>
                                                <p>0</p>
                                            </div>

                                            <div className="col-2 col-lg-2 col-sm-2 col-md-2 col-xl-3 text-center">
                                                <label>Assigned</label>
                                                <p>0</p>
                                            </div>

                                            <div className="col-2 col-lg-2 col-sm-2 col-md-2 col-xl-3 text-center">
                                                <label>Open</label>
                                                <p>0</p>
                                            </div>

                                            <div className="col-2 col-lg-2 col-sm-2 col-md-2 col-xl-3 text-center">
                                                <label>Cost</label>
                                                <p>0</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="flip-card-back">
                                    <div className="nx-card-subscription-details ">
                                        <Tabs>
                                            <TabPane tab="Description" key="1">
                                                <div className="subscription-card-body">
                                                    description
                                                </div>
                                            </TabPane>
                                            <TabPane tab="About Us" key="2">
                                                <div className="subscription-card-body">
                                                    About us
                                                </div>
                                            </TabPane>
                                            <TabPane tab="Developer" key="3">
                                                <div className="subscription-card-body">
                                                    developer
                                                </div>
                                            </TabPane>
                                        </Tabs>
                                        <div className="nx-card-subscription-button">
                                            <button className="btn btn-primary">Unsubscribe</button>
                                            <button className="btn btn-primary">License Details</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                        <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 mt-3 nx-subscription-test flip-card">
                            <div className="card flip-card-inner">
                                <div className="card-front flip-card-front">
                                    <div className='card-body'>
                                        <div className="nx-subscription-img">
                                            <img src={userWizard} width="40" />
                                        </div>

                                        <div className="nx-subscription-titles">
                                            <label>Test App One</label>
                                            <div className="nx-subscription-info">
                                                <p>test Publisher</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="nx-subscription-info mt-5 container">
                                        <hr/>
                                        <div className="row">
                                            <div className="col-2 col-lg-2 col-sm-2 col-md-2 col-xl-3 text-center">
                                                <label>License</label>
                                                <p>0</p>
                                            </div>

                                            <div className="col-2 col-lg-2 col-sm-2 col-md-2 col-xl-3 text-center">
                                                <label>Assigned</label>
                                                <p>0</p>
                                            </div>

                                            <div className="col-2 col-lg-2 col-sm-2 col-md-2 col-xl-3 text-center">
                                                <label>Open</label>
                                                <p>0</p>
                                            </div>

                                            <div className="col-2 col-lg-2 col-sm-2 col-md-2 col-xl-3 text-center">
                                                <label>Cost</label>
                                                <p>0</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="flip-card-back">
                                    <div className="nx-card-subscription-details ">
                                        <Tabs>
                                            <TabPane tab="Description" key="1">
                                                <div className="subscription-card-body">
                                                    description
                                                </div>
                                            </TabPane>
                                            <TabPane tab="About Us" key="2">
                                                <div className="subscription-card-body">
                                                    About us
                                                </div>
                                            </TabPane>
                                            <TabPane tab="Developer" key="3">
                                                <div className="subscription-card-body">
                                                    developer
                                                </div>
                                            </TabPane>
                                        </Tabs>
                                        <div className="nx-card-subscription-button">
                                            <button className="btn btn-primary">Unsubscribe</button>
                                            <button className="btn btn-primary">License Details</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 mt-3 nx-subscription-test flip-card">
                            <div className="card flip-card-inner">
                                <div className="card-front flip-card-front">
                                    <div className='card-body'>
                                        <div className="nx-subscription-img">
                                            <img src={userWizard} width="40" />
                                        </div>

                                        <div className="nx-subscription-titles">
                                            <label>Test App One</label>
                                            <div className="nx-subscription-info">
                                                <p>test Publisher</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="nx-subscription-info mt-5 container">
                                        <hr/>
                                        <div className="row">
                                            <div className="col-2 col-lg-2 col-sm-2 col-md-2 col-xl-3 text-center">
                                                <label>License</label>
                                                <p>0</p>
                                            </div>

                                            <div className="col-2 col-lg-2 col-sm-2 col-md-2 col-xl-3 text-center">
                                                <label>Assigned</label>
                                                <p>0</p>
                                            </div>

                                            <div className="col-2 col-lg-2 col-sm-2 col-md-2 col-xl-3 text-center">
                                                <label>Open</label>
                                                <p>0</p>
                                            </div>

                                            <div className="col-2 col-lg-2 col-sm-2 col-md-2 col-xl-3 text-center">
                                                <label>Cost</label>
                                                <p>0</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="flip-card-back">
                                    <div className="nx-card-subscription-details ">
                                        <Tabs>
                                            <TabPane tab="Description" key="1">
                                                <div className="subscription-card-body">
                                                    description
                                                </div>
                                            </TabPane>
                                            <TabPane tab="About Us" key="2">
                                                <div className="subscription-card-body">
                                                    About us
                                                </div>
                                            </TabPane>
                                            <TabPane tab="Developer" key="3">
                                                <div className="subscription-card-body">
                                                    developer
                                                </div>
                                            </TabPane>
                                        </Tabs>
                                        <div className="nx-card-subscription-button">
                                            <button className="btn btn-primary">Unsubscribe</button>
                                            <button className="btn btn-primary">License Details</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}

        </>
    )
}
