import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import './ResetPasswordForm.css'
import LinearProgress from '@material-ui/core/LinearProgress';
import { NxLoader } from '@afiplfeed/itr-app';
import { useHistory, useLocation } from 'react-router-dom'
import { NotificationManager } from 'react-notifications';
import { ItrApiService, ItrAuthService } from '@afiplfeed/itr-ui';
import ithredLogo from '../../assets/iTHRED.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

function ResetPasswordForm(props) {
    const [state, setState] = useState({
        load: false,
        userName: '',
        userToken: '',
        newPassword: '',
        confirmPassword: '',
    });

    const [showPass, setShowPass] = useState(false);
    const visiblePassword = () => {
        if (showPass == false) {
            setShowPass(true);
        }
        else {
            setShowPass(false);
        }
    }

    const [loader, setLoader] = useState(false);
    const history = useHistory();
    const location = useLocation();
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const q = params.get('token');
        if (q) {
            setState({ ...state, userToken: q });
        }

        // ItrApiService.GET({
        //     url: 'PasswordPolicy',
        //     appCode:"Configuration" 
        // })
    }, [])
    return (
        <>
            <div className="fixed-background"></div>
            <NxLoader state={loader} />
            <main>
                <div className="container resetpasswordform">
                    <div className="row h-100">
                        <div className="col-12 col-md-10 mx-auto my-auto">
                            <div className="card auth-card">
                                <div className="position-relative image-side d-flex align-items-end">
                                    <p className="copy-rights">© 2022 Ithred Solutions.com</p>
                                </div>


                                <div className="form-side">
                                    {/* <div hidden={showLogin}> */}
                                    <a>
                                        <span className="logo-single">
                                            <img src={ithredLogo} width="30%" alt="nexap-logo" />
                                        </span>
                                    </a>

                                    {/* login-update-form */}
                                    <form className="mt-4">
                                        <div className="mt-4">
                                            <h6>Update Your Password</h6>
                                        </div>

                                        <label className="form-group has-float-label mb-2">
                                            <span className="labels">New Password</span>
                                            <input className="form-control" type={showPass ? 'text' : 'password'} placeholder="" id="new" value={state.newPassword} onChange={(e) => {
                                                setState({ ...state, newPassword: e.target.value });
                                            }} />
                                        </label>

                                        <label className="form-group has-float-label mb-2">
                                            <span className="labels">Confirm Password</span>
                                            <input className="form-control" type={showPass ? 'text' : 'password'} placeholder="" id="confirm" value={state.confirmPassword} onChange={(e) => {
                                                setState({ ...state, confirmPassword: e.target.value });
                                            }} />
                                        </label>
                                        {/* <span class="input-group-text passwordIcon" id="basic-addon1" onClick={visiblePassword}>
                                            {showPass ? <FontAwesomeIcon icon={faEye} size="xs" color="gray" /> : <FontAwesomeIcon icon={faEyeSlash} size="xs" color="gray" />}
                                        </span> */}
                                        <div className="d-flex justify-content-end align-items-center action-footer mt-3" >
                                            <button className="btn btn-primary btn-lg btn-shadow" type="submit"
                                                onClick={() => {
                                                    if (state.newPassword == '') {
                                                        NotificationManager.error('Enter the Password')
                                                    } else {
                                                        if (state.newPassword == state.confirmPassword) {
                                                            setLoader(true);
                                                            ItrApiService.ResetPassword({
                                                                data: {
                                                                    userToken: state.userToken,
                                                                    password: state.newPassword,
                                                                    loginType: ''
                                                                }
                                                            }).then(res => {
                                                                if (res.Success == true) {
                                                                    if (res.result.isSuccess == true) {
                                                                        setLoader(false);
                                                                        window.location.replace('/');
                                                                        NotificationManager.success('Successfully Updated');
                                                                    } else {
                                                                        NotificationManager.error(res.result.message);
                                                                        setLoader(false);
                                                                    }
                                                                } else {
                                                                    NotificationManager.error(res.message);
                                                                    setLoader(false);
                                                                }
                                                            });
                                                        } else {
                                                            NotificationManager.error("password Missmatches!");
                                                        }
                                                    }
                                                }}
                                            >Update</button>
                                        </div>
                                    </form>
                                    {/* </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}

export default ResetPasswordForm;