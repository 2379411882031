import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import './GitRepoAdd.css';
import { NxLoader, NxTitleTab, NxFooter } from '@afiplfeed/itr-app';
import { ItrApiService } from '@afiplfeed/itr-ui';
import Headers from '../../../../../Screens/Headers/Headers';

export default function GitRepoAdd() {
    let history = useHistory();
    const [loading, setLoading] = useState(false);

    // const [gateway, setGateway] = useState({});
    const [data, setData] = useState({
        gitRepoID: 0,
        gitRepoAccountName: '',
        pat: ''
    });

    return (
        <div>
            <NxLoader state={loading} />
            <Headers />
            <NxTitleTab className="title-tab" title='Add Git Repo' onCloseClick={() => history.goBack()} />
            <div className="card mt-0 sms-gateway-main">
                <div className="card-body">
                    <div className="container-form mb-3">
                        <div>
                            <div className="row mt-2">
                                {/* <h6>Payment Gateway API to send DLT Approved Mobile Verification Code for 2-Factor Authentication & Notifications. Contact software administrator for API End-Points & Notification Options.</h6> */}
                                <div className="col-12 col-sm-4 col-md-6 col-lg-6 col-xl-6 mt-4">
                                    <label className='f-badge-title'>Repo Identifier</label>
                                    <input className="form-control mt-1" id="Organization" value={data.gitRepoAccountName} type="text" onChange={(e) => {
                                        setData({ ...data, gitRepoAccountName: e.target.value })
                                    }} />
                                </div>

                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                    {/* <label htmlFor="Organization"> User Name</label>
                                        <input className="form-control mt-1" id="Organization" type="text" /> */}
                                </div>

                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-4">
                                    <label htmlFor="Organization" className='f-badge-title'>Access Token</label>
                                    <input className="form-control mt-1" id="Organization" value={data.pat} type="text" onChange={(e) => {
                                        setData({ ...data, pat: e.target.value })
                                    }} />
                                </div>

                                {/* <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                        <label htmlFor="Organization">Url</label>
                                        <input className="form-control mt-1" id="Organization" type="text" value={gateway?.url} />
                                    </div> */}
                            </div>

                            <div className="form-button nx-enquiry-buttons mt-5 mb-3">
                                <button className="btn btn-primary buttons" onClick={() => {
                                    setLoading(true);
                                    ItrApiService.POST({ url: 'CloudConnect/Configuration/GitRepo', data: data }).then(res => {
                                        if (res.Success == true) {
                                            if (res.result.isSuccess == true) {
                                                history.goBack();
                                            }
                                        }
                                        setLoading(false);
                                    })
                                }}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <NxFooter companyName="© 2022 Ithred Solutions" ExtraContent={[
                { name: 'Terms of Service' },
                { name: 'Privacy' },
            ]} />
        </div>
    )
}
