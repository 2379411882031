import React, { useEffect, useState } from "react";
import './DatabaseServerEdit.css';
import { useHistory } from 'react-router-dom';
import { NxLoader, NxTitleTab } from '@afiplfeed/itr-app';
import { ItrApiService, ItrAuthService } from '@afiplfeed/itr-ui';
import Headers from "../../Screens/Headers/Headers";
import { NotificationManager } from "react-notifications";
import { useLocation } from "react-router-dom";


export default function DatabaseServerEdit() {
    const Buttons = () => {
        return <div className="form-button nx-enquiry-buttons mt-4 mb-4">
            <button className="btn btn-sm btn-primary buttons" onClick={async () => {
                ItrApiService.POST({
                    data: { ...data },
                    url: 'CloudConnect/Configuration/DatabaseServer'
                }).then(res => {
                    if (res.Success == true) {
                        if (res.result.isSuccess == true) {
                            history.goBack()
                        } else {
                            NotificationManager.error(res.result.message);
                        }
                    } else {
                        NotificationManager.error(res.message);
                    }
                })
            }} > Save</button>
            <button className="btn btn-sm close btn-outline-primary" onClick={() => history.goBack()}> Close</button>
        </div>
    }

    var loginUser = () => {
        return {
            pin: document.getElementById('adminPin').value
        }
    }

    const [data, setData] = useState({
        databaseServerID: 0,
        databaseServerName: "",
        userName: "",
        password: "",
        trusted_Connection: true,
        databaseServerDescription: ""
    })

    const location = useLocation()
    useEffect(() => {
        if (!location.state || !location.state.databaseServerID) {
            history.replace('/docker-connect')
        } else {
            setData({ ...data, ...location.state });
        }
    }, []);
    const getUser = async () => {
        let result = await ItrAuthService.GetUserInfo();
        if (result.Success == true) {
        } else {
            NotificationManager.error(result.message);
        }
    }

    useEffect(() => {
        getUser()
    }, [])


    let history = useHistory();
    const [loading, setLoading] = useState(false)
    return (
        <div>
            <NxLoader state={loading} />
            <Headers />
            <div className="customer-dynamic-pages pt-4 ">
                <NxTitleTab className="title-tab page-title" title='Edit Database Server' onCloseClick={() => {
                    history.goBack();
                }} />
                <div className="resource-groups-add-main-form mt-2">
                    <div className="card resource-groups-add-card-form">
                        <div className="card-body">
                            <div className="container-form">
                                <div className="row">
                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                        <div className="row">

                                            {/* <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2"> */}
                                            <label htmlFor="name" className='f-badge-title mt-2'>Database Server Name</label>
                                            <div className="px-2">

                                                <input className="form-control form-control-sm" id="name" type="text" value={data.databaseServerName} onChange={(e) => {
                                                    setData({ ...data, databaseServerName: e.target.value })
                                                }} />
                                            </div>
                                            {/* </div> */}

                                            {/* <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2"> */}
                                            <label htmlFor="adminPin" className='f-badge-title mt-2'>Username</label>
                                            <div className="px-2">
                                                <input className="form-control form-control-sm" type="text" value={data.userName} onChange={(e) => {
                                                    setData({ ...data, userName: e.target.value })
                                                }} />
                                            </div>
                                            {/* </div> */}

                                            {/* <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2"> */}
                                            <label htmlFor="SubscriptionId" className='f-badge-title mt-2'>Password</label>
                                            <div className="px-2">

                                                <input className="form-control form-control-sm" id="SubscriptionId" type="password" value={data.password} onChange={(e) => {
                                                    setData({ ...data, password: e.target.value })
                                                }} />
                                            </div>
                                            {/* </div> */}

                                            {/* <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2"> */}
                                            {/* <label htmlFor="ClientId" className='f-badge-title mt-2'>Trusted Connection</label> */}
                                            {/* </div> */}

                                            {/* <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2"> */}
                                            <label htmlFor="clientSecret" className='f-badge-title mt-2'>Database Server Discription</label>
                                            <div className="px-2">

                                                <textarea className="form-control form-control-sm" id="clientSecret" value={data.databaseServerDescription} onChange={(e) => {
                                                    setData({ ...data, databaseServerDescription: e.target.value })
                                                }} />
                                            </div>
                                            <div className="px-2 mt-2">
                                                <input className="" id="ClientId" type="checkbox" checked={data.trusted_Connection} onChange={(e) => {
                                                    setData({ ...data, trusted_Connection: e.target.checked })
                                                }} />
                                                <label htmlFor="ClientId" className='f-badge-title mt-2 px-2'>Trusted Connection</label>

                                            </div>
                                            {/* </div> */}
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2"></div>
                                    {/* <hr /> */}
                                    <Buttons />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}