import { NxFooter, NxLoader, NxTitleTab } from '@afiplfeed/itr-app';
import { ItrApiService } from '@afiplfeed/itr-ui';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { useHistory } from 'react-router-dom'
import Headers from '../../../Screens/Headers/Headers';

function AddBillingRules() {
    const history = useHistory();
    const [loader, setLoader] = useState(false);

    const [newBillingRules, setNewBillingRules] = useState({
        billingTemplateName: '',
        monthlyDate: '',
        selectDay: '',
        isRecurringJob: '',
        cancellationCustomer: '',
        invoiceReminders: '',
		creditPeriod: '',
        dunningPeriod: '',
        dataErasePeriod: '',
        dataCreated: moment().format(),
        author: "",
        noOfCustomer: 0,
    })


    useEffect(() => {

    }, [])
    return (
        <div>
            <Headers />
            <NxLoader state={loader} />
            <NxTitleTab className="title-tab" title="Add Billing Template" onCloseClick={() => history.goBack()} />
            <div className="card password-policy-main mt-0">
                <div className="card-body p-0 mb-5" >
                    <div className="row p-3 mx-1">
                        <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-6'>
                            <div className="row">
                                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2'>
                                    <div className='row'>
                                        <div className='col-12 col-sm-12 col-md-5 col-lg-5 col-xl-4'>
                                            <label className='f-badge-title'>Billing Template</label>
                                        </div>

                                        <div className='col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 flex-start' >
                                            <input className="form-control form-control-sm" type="text" value={newBillingRules.billingTemplateName}
                                                onChange={(e) => setNewBillingRules({ ...newBillingRules, billingTemplateName: e.target.value })} />
                                        </div>
                                    </div>
                                </div>

                                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2'>
                                    <div className='row'>
                                        <div className='col-12 col-sm-12 col-md-5 col-lg-5 col-xl-4'>
                                            <label className='f-badge-title'>Monthly Billing Date</label>
                                        </div>

                                        <div className='col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 flex-start' >
                                            <select className='form-select form-select-sm' value={newBillingRules.monthlyDate}
                                                onChange={(e) => setNewBillingRules({ ...newBillingRules, monthlyDate: e.target.value })}>
                                                <option value="" selected disabled> -- Please Select -- </option>
                                                <option value="Select Date"> Select Date </option>
                                                <option value="firstDay"> First day of the Month  </option>
                                                <option value="lastDay"> Last day of the Month </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2' hidden={newBillingRules.monthlyDate == 'Select Date' ? false : true}>
                                    <div className='row'>
                                        <div className='col-12 col-sm-12 col-md-5 col-lg-5 col-xl-4'>
                                            <label className='f-badge-title'>Select Date</label>

                                        </div>

                                        <div className='col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 flex-start' >
                                            <select className='form-select form-select-sm' value={newBillingRules.selectDay}
                                                onChange={(e) => setNewBillingRules({ ...newBillingRules, selectDay: e.target.value })}>
                                                <option value="" selected disabled> -- Please Select -- </option>
                                                <option value='1'>1</option>
                                                <option value='2'>2</option>
                                                <option value='3'>3</option>
                                                <option value='4'>4</option>
                                                <option value='5'>5</option>
                                                <option value='6'>6</option>
                                                <option value='7'>7</option>
                                                <option value='8'>8</option>
                                                <option value='9'>9</option>
                                                <option value='10'>10</option>
                                                <option value='11'>11</option>
                                                <option value='12'>12</option>
                                                <option value='13'>13</option>
                                                <option value='14'>14</option>
                                                <option value='15'>15</option>
                                                <option value='16'>16</option>
                                                <option value='17'>17</option>
                                                <option value='18'>18</option>
                                                <option value='19'>19</option>
                                                <option value='20'>20</option>
                                                <option value='21'>21</option>
                                                <option value='22'>22</option>
                                                <option value='23'>23</option>
                                                <option value='24'>24</option>
                                                <option value='25'>25</option>
                                                <option value='26'>26</option>
                                                <option value='27'>27</option>
                                                <option value='28'>28</option>
                                                <option value='29'>29</option>
                                                <option value='30'>30</option>
                                                <option value='31'>31</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2'>
                                    <div className='row'>
                                        <div className='col-12 col-sm-12 col-md-5 col-lg-5 col-xl-4'>
                                            <label className='f-badge-title'>Recurring Job</label>

                                        </div>

                                        <div className='col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 flex-start' >
                                            <select className='form-select form-select-sm' value={newBillingRules.isRecurringJob}
                                                onChange={(e) => {
                                                    if (e.target.value == 'true') {
                                                        setNewBillingRules({ ...newBillingRules, isRecurringJob: true })
                                                    }
                                                    else {
                                                        setNewBillingRules({ ...newBillingRules, isRecurringJob: false })
                                                    }
                                                }}
                                                >
                                                <option value="" selected disabled> -- Please Select -- </option>
                                                <option value={true}> Yes </option>
                                                <option value={false}> No  </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2'>
                                    <div className='row'>
                                        <div className='col-12 col-sm-12 col-md-5 col-lg-5 col-xl-4'>
                                            <label className='f-badge-title'>Cancellation Customers</label>
                                        </div>

                                        <div className='col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 flex-start' >
                                            <select className='form-select form-select-sm' value={newBillingRules.cancellationCustomer}
                                                onChange={(e) => setNewBillingRules({ ...newBillingRules, cancellationCustomer: e.target.value })}>
                                                <option value="" selected disabled> -- Please Select -- </option>
                                                <option value="Bill as per Monthly date"> Bill as per Monthly date </option>
                                                <option value="Bill on subscription cancellation date"> Bill on subscription cancellation date  </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2'>
                                    <div className='row'>
                                        <div className='col-12 col-sm-12 col-md-5 col-lg-5 col-xl-4'>
                                            <label className='f-badge-title'>Invoice Reminders - Every</label>

                                        </div>

                                        <div className='col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 flex-start' >
                                            <select className='form-select form-select-sm' value={newBillingRules.invoiceReminders}
                                                onChange={(e) => setNewBillingRules({ ...newBillingRules, invoiceReminders: e.target.value })}>
                                                <option value="" selected disabled> -- Please Select -- </option>
                                                <option value="5"> 5 Days </option>
                                                <option value="10"> 10 Days  </option>
                                                <option value="15"> 15 Days </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2'>
                                    <div className='row'>
                                        <div className='col-12 col-sm-12 col-md-5 col-lg-5 col-xl-4'>
                                            <label className='f-badge-title'>Credit Period</label>
                                        </div>

                                        <div className='col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 flex-start' >
                                            <select className='form-select form-select-sm' value={newBillingRules.creditPeriod}
                                                onChange={(e) => setNewBillingRules({ ...newBillingRules, creditPeriod: e.target.value })}>
                                                <option value="" selected disabled> -- Please Select -- </option>
                                                <option value="5"> 5 Days </option>
                                                <option value="10"> 10 Days  </option>
                                                <option value="15"> 15 Days </option>
                                                <option value="20"> 20 Days  </option>
                                                <option value="25"> 25 Days </option>
                                                <option value="30"> 30 Days  </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2'>
                                    <div className='row'>
                                        <div className='col-12 col-sm-12 col-md-5 col-lg-5 col-xl-4'>
                                            <label className='f-badge-title'>Dunning Period</label>

                                        </div>

                                        <div className='col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 flex-start' >
                                            <select className='form-select form-select-sm' value={newBillingRules.dunningPeriod}
                                                onChange={(e) => setNewBillingRules({ ...newBillingRules, dunningPeriod: e.target.value })}>
                                                <option value="" selected disabled> -- Please Select -- </option>
                                                <option value="15"> 15 Days </option>
                                                <option value="30"> 30 Days  </option>
                                                <option value="45"> 45 Days </option>
                                                <option value="60"> 60 Days  </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2'>
                                    <div className='row'>
                                        <div className='col-12 col-sm-12 col-md-5 col-lg-5 col-xl-4'>
                                            <label className='f-badge-title'>Data Erase Period</label>

                                        </div>

                                        <div className='col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 flex-start' >
                                            <select className='form-select form-select-sm' value={newBillingRules.dataErasePeriod}
                                                onChange={(e) => setNewBillingRules({ ...newBillingRules, dataErasePeriod: e.target.value })}>
                                                <option value="" selected disabled> -- Please Select -- </option>
                                                <option value="30"> 30 Days </option>
                                                <option value="60"> 60 Days  </option>
                                                <option value="90"> 90 Days </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4'>
                            <div className="row">
                            </div>
                        </div>

                        <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4'>
                            <div className="row">
                            </div>
                        </div>




                        <div className='text-end'>
                            <button className='btn btn-primary btn-sm mt-4 mb-1' onClick={() => {
                                let dataVal = { ...newBillingRules, selectDay: newBillingRules.selectDay == '' ? 0 : parseInt(newBillingRules.selectDay), isRecurringJob: newBillingRules.isRecurringJob};
                                setLoader(true);
                                ItrApiService.POST({ url: 'RevOps/BillingRule/SaveBillingRule', data: dataVal }).then(res => {
                                    if (res.Success == true) {
                                        if (res.result.isSuccess == true) {
                                            setLoader(false);
                                            history.goBack();
                                        } else {
                                            NotificationManager.error(res.result.message);
                                        }
                                    } else {
                                        NotificationManager.error(res.message);
                                    }
                                })
                            }}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
            <NxFooter
                className="verscio-footer" companyName="© 2022 Ithred Solutions" ExtraContent={[
                    { name: 'Terms of Service' },
                    { name: 'Privacy' },
                ]} />
        </div>
    );
}

export default AddBillingRules;