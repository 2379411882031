import './App.css';
import Routes from './routes/routes';
import '@afiplfeed/itr-app/dist/index.css'
import { ItrApiService } from '@afiplfeed/itr-ui';
import { NxConfigs } from '@afiplfeed/itr-app';
import { useEffect } from 'react';
import { NotificationManager, NotificationContainer } from "react-notifications";

function App() {
  NxConfigs('theme1');
  useEffect(() => {
    ItrApiService.CONFIG(process.env.react_app_env, process.env.react_app_baseurl, process.env.react_app_site)
  }, [])
  return (
    <div>
      <NotificationContainer />
      <Routes />
    </div>
  );
}

export default App;
