import React, { useEffect, useState } from "react";
import './OnBoarding.css';
import { useHistory } from 'react-router-dom';
import { NxLoader, NxBreadcrumb, NxTitleTab, NxActionTab, NxGridList, NxRightDrawer, NxFooter } from '@afiplfeed/itr-app';
import { ItrApiService } from '@afiplfeed/itr-ui';
import Headers from "../../Screens/Headers/Headers";
import { NotificationManager } from "react-notifications";

const splits = async (dests, setEnquiry, setExport) => {
    let arr = [];
    let arr2 = [];
    for (let value of dests) {
        let data = [
            value?.entityCode ? value.entityCode : '-',
            value?.entityName ? value.entityName : '-',
            value?.noOfEmployees ? value.noOfEmployees : '-',
            value?.entityBoarding?.customerType ? value?.entityBoarding?.customerType : '-',
            value?.entityBoarding?.hostingType ? value?.entityBoarding?.hostingType : '-',
        ];
        let obj = { sendItem: value, data: data };
        let obj2 = {
            "Entity Code": value?.entityCode ? value.entityCode : '-',
            "Entity Name": value?.entityName ? value.entityName : '-',
            "No Of Employees": value?.noOfEmployees ? value.noOfEmployees : '-',
            "Customer Type": value?.entityBoarding?.customerType ? value?.entityBoarding?.customerType : '-',
            "Hosting Type": value?.entityBoarding?.hostingType ? value?.entityBoarding?.hostingType : '-',
        }
        arr2 = [...arr2, obj2];
        arr = [...arr, obj]
    }
    setExport(arr2);
    setEnquiry(arr);
}

export default function OnBoarding() {
    let history = useHistory();
    const [load, setLoad] = useState(false);
    const [handel, setHandel] = useState(false);
    const [handel2, setHandel2] = useState(false);


    const [customers, setCustomers] = useState([]);
    const [passCustomers, setpassCustomers] = useState();
    const [loading, setLoading] = useState(false);



    const getData = async () => {
        setLoad(true);
        let result = await ItrApiService.GET({
            url: 'RevOps/Entity/GetEntities/false',
        });
        if (result.result.isSuccess == true) {
            setLoad(false);
            let getCustomerDetails = result.result.data;
            if (getCustomerDetails === null || !getCustomerDetails.length) {
                // NotificationManager.error(result.result.message);

            }
            else {
                await splits(getCustomerDetails, setCustomers, setExport);
                await setpassCustomers(getCustomerDetails);
            }
            // setCustomers(getCustomerDetails);
        }
        else if (result.result.isSuccess == false) {
            setLoad(false);
            NotificationManager.error(result.result.message);
            // sessionStorage.clear();
            // history.push('/')
        }
    }

    const [exportVal, setExport] = useState([]);
    useEffect(() => {
        getData();
        // await splits(dest, setEnquiry);
    }, [])

    return (
        <div>
            <NxLoader state={load} />
            {/* <Headers /> */}
            {/* <NxBreadcrumb className="navigation-breadcrumb" data={[
                {
                    name: 'Home',
                    onClick: () => {
                        history.push('/partner-board');
                    }
                }
            ]} /> */}
            <div style={{ flex: 1 }}>
                {/* <NxTitleTab className="title-tab" title='Customer On-boarding' onCloseClick={() => {
                    history.push('/customers');
                }} /> */}
                <NxActionTab
                    className="enquiry-action-tab px-0"
                    exportValue={exportVal}
                    fileName='On Board'
                    onColumnsClick={() => { setHandel(true) }}
                    onRefreshClick={() => {
                        setLoad(true)
                        setTimeout(function () {
                            setLoad(false)
                        }, 2000);
                    }}
                    onNewEntryClick={() => { history.push('/onboarding/add') }}
                    enableNewEntry={false}
                />
                <div className="px-0 pt-0">
                    <NxGridList
                        enableSearch={true}
                        autoSearch={true}
                        enableAutoPagination={true}
                        showShowing={true}
                        header={[
                            {
                                name: 'Entity Code',
                                search: true,
                                sort: true,
                            },
                            {
                                name: 'Entity Name',
                                sort: true,
                            },
                            {
                                name: 'No Of Employees',
                                // sort: true,
                            },
                            {
                                name: 'Customer Type',
                                // sort: true,
                            },
                            {
                                name: 'Hosting Type',
                            },
                        ]}
                        rowClick={(data) => {
                            history.push({
                                pathname: '/onboarding/edit',
                                state: {
                                    passData: data
                                }
                            });
                        }}
                        data={customers} />
                </div>
                <NxRightDrawer onClose={() => setHandel(false)} show={handel} drawerContent={() => <div>

                </div>} ondrawer1Close={() => { setHandel2(false) }} />
            </div>

            <NxFooter ExtraContent={[
                { name: 'Terms of Service' },
                { name: 'privacy' },
            ]} />
        </div>
    )
}