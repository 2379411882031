import React, { useState, useEffect } from 'react';
import './licenseManagement.css';
import { Navigate } from 'react-router-dom';
import { Tabs, Switch, Rate, Modal, PageHeader, Tag, Button, Tooltip, Alert, Space, message } from 'antd';
import { AppstoreOutlined, DashboardOutlined } from '@ant-design/icons';
import { ItrApiService } from '@afiplfeed/itr-ui'
import { NxLoader, NxTitleTab, NxFooter } from '@afiplfeed/itr-app';
import Headers from '../../Screens/Headers/Headers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard, faChartLine, faCode, faDollarSign, faIdBadge, faInfo, faInfoCircle, faStore, faTachometerAlt, faTicketAlt, faUserTag } from '@fortawesome/free-solid-svg-icons';
import SubModal from './SubModal';
import CancelModal from './CancelModal';
import moment from 'moment';
import AddLicenseModel from './AddLicenseModel';
import { NotificationManager } from 'react-notifications';
import CustomerSidebar from '../../Screens/CustomerSidebar/CustomerSidebar';
import { useHistory } from 'react-router-dom';

export default function LicenseManagement({ data = [{ image: '', heding: '', info: '' }] }) {
    const { TabPane } = Tabs;
    let history = useHistory();
    const [cancelModals, setCancelModals] = useState(false);
    const [cancelModals2, setCancelModals2] = useState(false);

    const [subscriptionData, setSubscriptionData] = useState([]);
    const [subscriptionId, setSubscriptionId] = useState('');
    const [clientId, setClientid] = useState('');
    const [enrollmentId, setEnrollmentId] = useState('');
    const [loader, setLoader] = useState(false);
    const [subIds, setSubIds] = useState()

    // list-Subscription-Api
    const getData = async () => {
        let result = await ItrApiService.GET({
            url: 'RevOps/EntitySubscription/AllSubscriptionLicense'
        });
        if (result.result.isSuccess == true) {
            let dataVal = result.result.data;
            // console.log(dataVal);
            // for (let id of dataVal) {
            //     setSubscriptionId(id.clientSubscriptionID);
            //     setClientid(id.clientID);
            //     setEnrollmentId(id.id);
            // }
            if (dataVal === null || !dataVal.length) {
                setSubscriptionData([]);
            }
            else {
                // setData()
                // splits(dataVal, setData);
                setShowLicenseDetails(false)
                setSubscriptionData(dataVal);
                // await splits(data,setDatas);
            }
            // setCustomers(getCustomerDetails);
        }
        else if (result.result.isSuccess == false) {
            // alert(result.result.message);
            // sessionStorage.clear();
            // Navigate('/')
        }
    }

    const [cancelSub, setCancelSub] = useState({
        entitySubscriptionID: 0,
        entityCode: "",
        subscriptionCode: "",
        serviceCode: "",
        serviceName: "",
        subscribedDate: "2022-01-12T17:15:47.421Z",
        unSubscribedDate: "2022-01-12T17:15:47.421Z",
        totalUnits: 0,
        totalSubscribedUnits: 0,
        totalAssignedUnits: 0,
        isActive: true
    });

    const [noOfUnit, setNoOfUnit] = useState();

    const [handel, setHandel] = useState(false);
    const [handel2, setHandel2] = useState(false);

    var saveSubscribe = {
        clientSubscriptionID: subscriptionId,
        enrollmentID: enrollmentId,
        clientID: clientId,
        noofUnit: parseInt(noOfUnit)
    }

    var unSubscribe = {
        clientSubscriptionID: subscriptionId,
        clientID: clientId
    }

    useEffect(() => {
        setLoader(true);
        getData();
        setLoader(false);
    }, []);

    const [sCode, setSCode] = useState()


    const [showLicenseDetails, setShowLicenseDetails] = useState(false);

    const [selectedData, setSelectedData] = useState({});
    const [licenseData, setLicenseData] = useState([])
    const showLicenseTable = (data) => {
        setLoader(true)
        setSCode(data);
        ItrApiService.GET({ url: `RevOps/EntitySubscription/AllLicense/${data.ServiceCode}` }).then(res => {
            // console.log(res)
            if (res.Success == true) {
                if (res.result.isSuccess == true) {
                    setSelectedData(data);
                    setLicenseData(res.result.data);
                    setShowLicenseDetails(true);
                }
            }
            setLoader(false)
        })
    }

    const [removeVal, setRemoveVal] = useState([]);
    const [modalState, setModalState] = useState(false);
    const [selectedValue, setSelectedValue] = useState({});
    return (
        <> 
            <Headers />
            <div className='d-flex custom-body-container'>
                <div className='body-sidebar'>
                    <CustomerSidebar visible={true}  />
                </div>
                <div className='body-workspace'>
                    <NxTitleTab className="title-tab" title="Subscriptions" onCloseClick={() => history.push('/marketplace')} />
                    <AddLicenseModel subIds={subIds} modalState={modalState} setModalState={setModalState} selectedValue={selectedValue} refreshFunction={async () => {
                        setLoader(true)
                        await getData();
                        setLoader(false)
                    }} />
                    {/* <NxTitleTab className="title-tab" title={<div>License Management &nbsp; <button className='btn btn-sm' style={{ backgroundColor: 'red', color: 'white' }} onClick={() => {
                        ItrApiService.GET({
                            url: 'PAM/IsRequiredAdminPinVerification',
                        }).then(res => {
                            console.log(res);
                            if (res.Success == true) {
                                if (res.result.isSuccess == true) {
                                    if (res.result.data.isRequiredAdminPinVerification == true) {
                                        setCancelModals2(true);
                                    }
                                    else {
                                        setLoader(true);
                                        ItrApiService.POST({
                                            url: 'RevOps/EntitySubscription/CancelAllSubscription',
                                        }).then(res => {
                                            console.log(res)
                                            if (res.Success == true) {
                                                if (res.result.isSuccess == true) {
                                                    NotificationManager.success('All Subscriptions are Canceled');
                                                } else {
                                                    NotificationManager.error(res.result.message);
                                                }
                                            } else {
                                                NotificationManager.error(res.message);
                                            }
                                        })
                                    }
                                }
                            }
                        })
                    }}
                    >Cancel All Subscriptions</button> </div>} onCloseClick={() => Navigate('/launch-pad')} /> */}
                    <div className="mt-0 apps-store-main " >
                        <div className="container1 mt-1 nx-subscription-page mb-5">
                            <div className='table-responsive'>
                                <table className='table table-sm table-wrapper-scroll'>
                                    <thead className='table-secondary'>
                                        <tr>
                                            <th >Service Name</th>
                                            <th >Version Number</th>
                                            <th >Developer</th>
                                            <th >Total Subscribed</th>
                                            <th >Assigned</th>
                                            <th >Remaining</th>
                                            <th >Compliance Status</th>
                                            <th >Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {subscriptionData.map((data, index) => {
                                            return <tr key={index} style={{ cursor: 'pointer' }} onClick={() => showLicenseTable(data)}>
                                                <td align='left' className='text-primary'>{data.ServiceName}</td>
                                                <td align='left'> {data.VersionNo} </td>
                                                <td align='left'> {data.DeveloperName} </td>
                                                <td align='left'> {data.TotalSubscribed} </td>
                                                <td align='left'> {data.TotalAssigned || '0'} </td>
                                                <td align='left'> {data.Remaining} </td>
                                                <td align='left'>
                                                    {(data.Remaining && data.Remaining > 0) ? <span className='dot1 pl-0'></span> : <span className='dot1 pl-0' style={{ backgroundColor: 'blue', }}></span>}
                                                    {(data.Remaining && data.Remaining > 0) ? ' Over Subscribed' : ' Right Subscribed'}
                                                </td>
                                                <td align='right'>
                                                    <div className='d-flex flex-row'>
                                                        <button className='btn btn-sm btn-outline-danger mr-1' style={{ fontSize: '13px' }} onClick={(e) => {
                                                            e.stopPropagation();
                                                            setCancelSub({ ...cancelModals, entitySubscriptionID: data.SubscriberId });
                                                            // ItrApiService.POST({
                                                            //     url: 'RevOps/EntitySubscription/CancelSubscription',
                                                            //     data: cancelSub
                                                            // }).then(async res => {
                                                            //     if (res.Success == true) {
                                                            //         if (res.result.isSuccess == true) {
                                                            //             setLoader(false)
                                                            //             await getData();
                                                            //             setCancelModals(false);
                                                            //             NotificationManager.success('Successfully Updated');
                                                            //         }
                                                            //         else {
                                                            //             setLoader(false);
                                                            //             NotificationManager.success(res.result.message);
                                                            //         }
                                                            //     }
                                                            //     else {
                                                            //         setLoader(false);
                                                            //         NotificationManager.success(res.result.message);
                                                            //     }
                                                            // })

                                                            // 
                                                            ItrApiService.GET({
                                                                url: 'PAM/IsRequiredAdminPinVerification',
                                                            }).then(res => {
                                                                console.log(res);
                                                                if (res.Success == true) {
                                                                    if (res.result.isSuccess == true) {
                                                                        if (res.result.data.isRequiredAdminPinVerification == true) {
                                                                            setCancelModals(true);
                                                                        }
                                                                        else {
                                                                            setLoader(true)
                                                                            ItrApiService.POST({
                                                                                url: 'RevOps/EntitySubscription/CancelSubscription',
                                                                                data: cancelSub
                                                                            }).then(async res => {
                                                                                if (res.Success == true) {
                                                                                    if (res.result.isSuccess == true) {
                                                                                        setLoader(false)
                                                                                        await getData();
                                                                                        setCancelModals(false);
                                                                                        NotificationManager.success('Successfully Updated');
                                                                                    }
                                                                                    else {
                                                                                        setLoader(false);
                                                                                        NotificationManager.success(res.result.message);
                                                                                    }
                                                                                }
                                                                                else {
                                                                                    setLoader(false);
                                                                                    NotificationManager.success(res.result.message);
                                                                                }
                                                                            })
                                                                        }
                                                                    }
                                                                }
                                                            })

                                                            // setCancelModals(true);
                                                            // ItrApiService.POST({
                                                            //     url: 'RevOps/EntitySubscription/CancelSubscription',
                                                            //     data: cancelSub
                                                            // })
                                                        }}> Cancel Subscription </button>
                                                        <button className='btn btn-sm btn-outline-success mx-1' style={{ fontSize: '13px' }} onClick={(e) => {
                                                            e.stopPropagation();
                                                            setLoader(true);
                                                            ItrApiService.GET({ url: `DevOps/Enrollment/GetByEnrollmentID/${data.EnrollmentId}` }).then(res => {
                                                                if (res.Success == true) {
                                                                    if (res.result.isSuccess == true) {
                                                                        setSelectedValue(res.result.data);
                                                                        setModalState(true);
                                                                        setSubIds(data.SubscriberId)
                                                                        setLoader(false);
                                                                    }
                                                                }
                                                            })
                                                        }} > Add License </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="container1 mt-1 nx-subscription-page mb-5 mt-2" hidden={showLicenseDetails == false ? true : false}>
                            {/* Buttons */}
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>
                                    <button className='btn btn-sm btn-outline-secondary ml-1' style={{ fontSize: '13px' }} onClick={() => {
                                    setLoader(true);
                                        ItrApiService.POST({ url: 'RevOps/EntitySubscription/RemoveLicense', data: removeVal }).then(res => {
                                            if (res.Success == true) {
                                                if (res.result.isSuccess == true) {
                                                    message.success('Removed!');
                                                    setRemoveVal([])
                                                    setLoader(true);
                                                    getData();
                                                    ItrApiService.GET({ url: `RevOps/EntitySubscription/AllLicense/${sCode.ServiceCode}` }).then(res => {
                                                        // console.log(res)
                                                        if (res.Success == true) {
                                                            if (res.result.isSuccess == true) {
                                                                setSelectedData(sCode);
                                                                setLicenseData(res.result.data);
                                                                setShowLicenseDetails(true);
                                                            }
                                                        }
                                                        setLoader(false)
                                                    })
                                                }
                                            }
                                            setLoader(false);
                                        })
                                    }}> Remove Selected License </button>
                                    <button className='btn btn-sm btn-outline-secondary mx-2' style={{ fontSize: '13px' }}> Remove Un-Used License </button>
                                </div>
                                <div style={{ textAlign: 'right' }}>
                                    <button className='btn btn-sm btn-outline-secondary ml-1' style={{ fontSize: '13px' }} onClick={() => {
                                        setShowLicenseDetails(false);
                                    }}> Close </button>
                                    {/* <button className='btn btn-sm btn-outline-secondary mx-2' style={{ fontSize: '13px' }}> Remove Un-Used License </button> */}
                                </div>
                            </div>
                            {/* Buttons */}
                            <div className='table-responsive mt-3'>
                                <table className='table table-wrapper-scroll'>
                                    <thead className='table-secondary'>
                                        <tr>
                                            <th></th>
                                            <th >Service Name</th>
                                            <th >License Number</th>
                                            <th >Subscription Date</th>
                                            <th >Status</th>
                                            <th >Assigned To</th>
                                            <th >Assigned Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {licenseData.map((item, index) => <tr style={{ cursor: 'pointer' }}>
                                            <td>
                                                <input class="form-check-input" type="checkbox" checked={removeVal.find((element) => {
                                                    return element.licenseKeyID == item.licenseKeyID;
                                                })} id="flexCheckDefault" onChange={(e) => {
                                                    if (e.target.checked == true) {
                                                        let arr = [...removeVal];

                                                        arr.push({
                                                            "licenseKeyID": item.licenseKeyID,
                                                            "licenseKey": item.licenseKey,
                                                        });
                                                        setRemoveVal(arr)
                                                    } else {
                                                        let array = [...removeVal];
                                                        var index1 = array.findIndex(vals => vals.licenseKeyID === item.licenseKeyID);
                                                        array.splice(index1, 1)
                                                        setRemoveVal(array);
                                                    }
                                                }} />
                                            </td>
                                            <td align='left' className='text-primary'>{selectedData.ServiceName}</td>
                                            <td align='left'>{item.licenseKey}</td>
                                            <td align='left'> {moment().format('DD/MM/YYYY')}</td>
                                            <td align='left'> {item.isAssigned == false ? 'Unassigned' : 'Assigned'} </td>
                                            <td align='left'> {item.isAssigned == false ? 'N/A' : item.assignedUser} </td>
                                            <td align='left'> {item.isAssigned == false ? 'N/A' : ''} </td>
                                        </tr>)}
                                        {/* {subscriptionData.map((data,index) => {
                                                return <tr style={{ cursor: 'pointer' }}>
                                                    <td align='left' className='text-primary'></td>
                                                    <td align='left'>1123234</td>
                                                    <td align='left'> 31/12/2021 </td>
                                                    <td align='left'> Pending </td>
                                                    <td align='left'> Test2 Name </td>
                                                    <td align='left'> 31/12/2021 </td>
                                                    <td align='left'>
                                                        <button className='btn btn-sm btn-outline-secondary ml-1' style={{ fontSize: '13px' }}> Remove License </button>
                                                    </td>
                                                </tr>})} */}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <CancelModal
                            title={<h5>Cancel Subscription</h5>}
                            cancelVisible={cancelModals} onCanceled={() => {
                                setCancelModals(false);
                            }} onOk={() => {
                                setLoader(true)
                                ItrApiService.POST({
                                    url: 'RevOps/EntitySubscription/CancelSubscription',
                                    data: cancelSub
                                }).then(async res => {
                                    await getData();
                                    setLoader(false)
                                    setCancelModals(false);
                                })
                            }} />
                        <CancelModal
                            title={<h5>Cancel Subscription</h5>}
                            cancelVisible={cancelModals2} onCanceled={() => {
                                setCancelModals2(false);
                            }} onOk={() => {
                                setLoader(true)
                                ItrApiService.POST({
                                    url: 'RevOps/EntitySubscription/CancelAllSubscription',
                                    data: {}
                                }).then(async res => {
                                    console.log(res)
                                    if (res.Success == true) {
                                        if (res.result.isSuccess == true) {
                                            NotificationManager.success('All Subscriptions are Canceled');
                                            await getData();
                                            setLoader(false)
                                            setCancelModals2(false);
                                        } else {
                                            NotificationManager.error(res.result.message);
                                        }
                                    } else {
                                        NotificationManager.error(res.message);
                                    }
                                })
                            }} />
                    </div>
                </div>
            </div>
            <NxFooter companyName="© 2023 Ithred Solutions" ExtraContent={[
                { name: 'Terms of Service' },
                { name: 'Privacy' },
            ]} />
        </>
    )
}
