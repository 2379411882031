import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import './Headers.css';
import { NxHeader } from '@afiplfeed/itr-app';
import pinwheelLogo from '../../assets/logopin.png';
import avatar from '../../assets/avatar.png';
import left from '../../assets/leftmenu.png';
import right from '../../assets/rightmenu.png';
import userprofile from '../../assets/profile.png';
import logOff from '../../assets/powerOff.png';
import { NotificationManager } from "react-notifications";


import Logo from '../../assets/adap_logo.svg';
import { faBell, faCog, faUserEdit, faPowerOff, faBullhorn, faQuestionCircle, faQuestion, faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ItrApiService, ItrAuthService } from '@afiplfeed/itr-ui';
import { Dropdown, Menu, Popover } from 'antd';
import Sidebar from '../../Screens/sidebar/sidebar';
import SidebarTheme from '../sidebarTheme/sidebarTheme';
import CustomerSidebar from '../CustomerSidebar/CustomerSidebar';
import defaultProfile from '../../assets/defaultProfile.png';



export default function Headers({ onClickChange, isCustomer }) {

    const [visible, setVisible] = useState(true);
    const showDrawer = () => {
        visible == false ? setVisible(true) : setVisible(false);
    };

    const onClose = () => {
        setVisible(false);
    };

    const [profileData,setProfileData] = useState({});

    let history = useHistory();
    let location = useLocation();
    let getCurrentUrl = location.pathname;
    //     // logout function
    //     const logOut = () => {
    //         // setLoading(true);
    //         sessionStorage.clear();
    //         history.push('/');
    //         // setLoading(false);
    //     }

    const [userInfo, setUserInfo] = useState({
        displayName: "",
        userName: ""
    });



    const [accessType,setAccessType] = useState({});
    const getUserDetails = async () => {
        let result = await ItrAuthService.GetUserInfo();
        if (result.Success == true) {
            let getUserDetails = result.result;
            setAccessType(getUserDetails);
        }
        else if (result.Success == false) {
            NotificationManager.error(result.message);
            // sessionStorage.clear();
        }
    }

    const logOut = async () => {
        let result = await ItrAuthService.logout();
        if (result.Success == true) {
            history.push('/');
        }
        else if (result.Success == false) {
            NotificationManager.error(result.message);
            // sessionStorage.clear();
        }
    }

    // user-popOver
    const menu = (
        <>
            {/* <div className="profile p-2">
                    <div className="nxProfileHead" >
                        <div className="nxName">
                        <img src={pinwheel} width="80" />
                        </div>
                        <div className="nxLogout" onClick={logOut}>
                            <p>Signout</p>
                        </div>
                    </div>

                    <div className="nxProfileBody">
                        <div className="nxProfileImg">
                            <div className="text-center">{userInfo.displayName.charAt(0).toUpperCase()}</div>
                        </div>
                        <div className="nxProfileName">
                            <label>{userInfo.displayName}</label>
                            <p>{userInfo.userName}</p>
                        </div>
                    </div>
                </div> */}

            <div className="nxProfileBody">
                {/* <div class="user">
                            <div class="right mt-2 mb-2">
                                <a class="dropdown-item">Account</a>
                                <a class="dropdown-item" onClick={logOut}>Sign out</a>
                            </div>
                        </div> */}

                <div className="user" style={{ borderRadius: '5px', width: '300px', height: '350px' }}>
                    <div className="right bg-primary" style={{ position: 'relative', borderTopLeftRadius: '5px', borderTopRightRadius: '5px', width: '100%', height: '120px' }}></div>
                    <div className="img-avatar">
                        <div style={{ position: 'absolute', width: '100px', height: '100px', borderRadius: '50%', top: '80px', left: '100px', right: '100px', backgroundColor: 'white' }} className="rounded-circle">
                            <img src={profileData?.photo ? profileData?.photo : defaultProfile} width="100%" height="100%" className="rounded-circle" />
                        </div>
                    </div>
                    <div className="right bg-white" style={{ borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px', width: '100%', height: '220px' }}>
                        <div className="row" style={{ marginTop: '50px' }}>
                            <div className="col-12 text-center">
                                <p className="p-0 m-0 fw-bold" style={{ fontSize: '16px' }}> {userInfo?.displayName} </p>
                                <p className="p-0 m-0" style={{ fontSize: '12px' }}>Chennai, India</p>
                                <p className="p-0 m-0" style={{ fontSize: '14px' }}>Administrator</p>
                            </div>
                            <div className="col-12 text-center">

                            </div>
                        </div>


                        <div className="row position-absolute mx-0 " style={{ bottom: '0', marginBottom: '20px', width: '100%' }}>
                            <div className="col-12 text-center mx-0 ">
                                <div style={{ fontSize: '18px', justifyContent: 'space-evenly', display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', alignContent: 'space-around', alignItems: 'center' }}>
                                    {/* <div className="rounded-circle" style={{backgroundColor:'grey',width:'40px',height:'40px',color:'white',display:'flex',justifyContent:'center',alignItems:'center'}}>
                                            <FontAwesomeIcon icon={faUserEdit} style={{width:'20px'}} />
                                        </div>
                                        <div className="rounded-circle" style={{backgroundColor:'grey',width:'40px',height:'40px',color:'white',display:'flex',justifyContent:'center',alignItems:'center'}} >
                                            <FontAwesomeIcon icon={faPowerOff} style={{width:'20px'}} />
                                        </div> */}
                                    <img style={{ cursor: 'pointer' }} src={userprofile} width="40" onClick={() => {
                                        history.push('/profile')
                                    }} />
                                    <img style={{ cursor: 'pointer' }} src={logOff} width="40" onClick={logOut} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );


    const getProfileDetails = async () => {
        let result = await ItrApiService.GET({
            url: 'PAM/ProfileDetails',
        });
        if (result.result.isSuccess == true) {
            let getPfofileDetails = result.result.data;
            if (result.result.data) {
                setProfileData(getPfofileDetails);
            }
        }
        else if (result.result.isSuccess == false) {
        }
    }

    useEffect(() => {
        getUserDetails();
        getProfileDetails();
    }, [])

    return (
        <div>
            <NxHeader className="right-side-menus"
                DrawerIcon={() => <div className="d-flex align-items-center justify-content-evenly">

                    {/* <div onClick={showDrawer} style={{ paddingLeft: '8px' }}>
                       
                        <FontAwesomeIcon icon={faBars} color="#78787894" />
                    </div> */}

                    <div style={{ paddingLeft: '12px' }}>
                        <img src={Logo} width="60%" />
                    </div>
                    {/* <div className="">
                    <p className=" ml-3 mb-0">Customer Name</p>
                </div> */}
                </div>}
                PortalHerderTitle={() =>
                    // <label style={{paddingLeft:'20px',fontSize:'15px',fontWeight:'600'}}>
                    //     2022 Ithred Solutions
                    // </label>
                    <>
                    </>
                }
                rightElements={[
                    // {
                    //     elements: () => {
                    //         return <div className="icons-hover-main d-flex justify-content-center align-items-center" title="Broadcast">
                    //             <FontAwesomeIcon className="icons-hover"  icon={faBullhorn} style={{width:'20px',color:'#78787894'}}  />
                    //         </div> 
                    //     },
                    // },
                    {
                        elements: () => {
                            return <div className="icons-hover-main d-flex justify-content-center align-items-center" title="Notification">
                                <FontAwesomeIcon className="icons-hover" icon={faBell} style={{ width: '20px', color: '#78787894' }} />
                            </div>
                        },
                    },
                    // {
                    //     elements: () => {
                    //         return <div className="icons-hover-main d-flex justify-content-center align-items-center" title="Help">
                    //             <FontAwesomeIcon className="icons-hover"  icon={faQuestion} style={{width:'20px',color:'#78787894'}} />
                    //         </div> 
                    //     },
                    // },
                    {
                        elements: () => {
                            return <Popover onPopupAlign="center" content={menu} trigger={['click']}>
                                <div className="img-avatar" title="User Card">
                                    <div className="d-flex justify-content-center" style={{ width: '40px', height: '40px',backgroundColor:'white', borderRadius: '50%', textAlign: 'center', color: 'white' }}>
                                        <img src={profileData?.photo ? profileData?.photo : defaultProfile} width="100%" height="100%" className="rounded-circle" />
                                    </div>
                                    {/* <img src={pinwheel} className="rounded-circle" style={{width:'25px'}} /> */}
                                </div>
                                {/* <div> */}
                                {/* <span className="users-name" style={{fontSize:'15px',paddingRight:'10px'}}>{userInfo.userName}</span> */}
                                {/* <FontAwesomeIcon title="profile" icon={faUserCircle} fontSize="20px" /> */}
                                {/* </div> */}
                            </Popover>
                            // return <div style={{display:'flex',alignItems:'center'}} onClick={handleClick}>
                            //     <span style={{color:'white',fontSize:'15px',paddingRight:'10px'}}>developer@gmail.com</span>
                            //     <FontAwesomeIcon icon={faUserCircle} color="white" size="md" />
                            // </div> 
                        }
                    }
                ]}
            />
            {/* {isCustomer == true ? <SidebarTheme onClose={onClose} visible={visible} onClickChange={onClickChange} /> :
                <CustomerSidebar onClose={onClose} visible={visible} onClickChange={onClickChange} />} */}
        </div>
    )
}
