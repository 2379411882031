import React, { useEffect, useState } from 'react';
import './ManageUsers.css';
import ResetPasswordModal from './ResetPasswordModal';
import { NxLoader, NxBreadcrumb, NxTitleTab, NxCustomTab, NxGridList, NxActionTab, NxRightDrawer, NxFooter } from '@afiplfeed/itr-app';
import { ItrApiService } from "@afiplfeed/itr-ui";
import { NotificationManager } from 'react-notifications';

function ActiveUser({ history, setLoad, load }) {
    const [datas, setDatas] = useState([]);
    const [exportVal, setExport] = useState([]);
    const [sea, setSea] = useState('');
    const [selectedUser, setSelectedUser] = useState({});
    const [modvis, setModVis] = useState(false);
    // const [disableData, setDisableDate] = useState({
    //     "userID": 0,
    //     "userCode": "string",
    //     "emailID": "string",
    //     "loginUserName": "string",
    //     "isActive": true
    // })
    const splits = async (dests, setDatas, history) => {
        let arr = [];
        let arr2 = [];
        for (let value of dests) {
            let data = [value.firstName, value.lastName, value.emailID, value.mobile,
            <div className="d-flex align-items-center">
                <button className="btn btn-sm btn-primary" style={{ fontSize: '14px', borderRadius: '0' }}
                    onClick={(e) => {
                        sessionStorage.setItem('editUserId', value.userID);
                        e.stopPropagation();
                        history.push('/access/manage-user/edit');
                    }}>Edit User</button>
                <button className="btn btn-sm mx-2 btn-danger" style={{ color: 'white', fontSize: '14px', borderRadius: '0' }} onClick={(e) => {
                    // sessionStorage.setItem('editUserId', value.userCode);
                    e.stopPropagation();
                    setSelectedUser(value);
                    setModVis(true)
                    // history.push('/reset-users');
                }}>Reset Password</button>
                <button className="btn btn-sm mr-2 btn-danger" style={{ color: 'white', fontSize: '14px', borderRadius: '0' }} onClick={(e) => {
                    // sessionStorage.setItem('editUserId', value.userCode);
                    e.stopPropagation();
                    console.log(value);
                    setLoad(true);
                    ItrApiService.POST({
                        url: 'Catalog/Users/EnableDisableUser',
                        data: {
                            "userID": value.userID,
                            "userCode": value.userCode,
                            "emailID": value.emailID,
                            "loginUserName": value.loginUserName,
                            "isActive": false
                        }
                    }).then(async (res) => {
                        console.log(res);
                        if (res.Success == true) {
                            if (res.result.isSuccess == false) {
                                NotificationManager.error(res.result.message);
                            } else {
                                // NotificationManager.success("Updated");
                                await getIdentity();
                                setLoad(false);
                            }
                        } else {
                            NotificationManager.error(res.message)
                        }
                        setLoad(false);
                    })
                    // setSelectedUser(value);
                    // setModVis(true)
                    // history.push('/reset-users');
                }}>Disable User</button>
            </div>
            ];
            let obj = { sendItem: value, data: data };
            let obj2 = {
                'first Name': value.firstName,
                'Last Name': value.lastName,
                'Email': value.emailID,
                'Mobile': value.mobile
            }
            arr2 = [...arr2, obj2];
            arr = [...arr, obj];
        }
        setExport(arr2);
        setDatas(arr);
    }


    const getIdentity = async () => {
        let result = await ItrApiService.GET({
            url: 'Catalog/Users/GetUsers'
        });
        // console.log(result)
        if (result.Success == true) {
            let IdentityAccess = result.result.data;
            if (IdentityAccess === null || !IdentityAccess.activeUsers.length) {
                // NotificationManager.error(result.result.message);
                setExport([]);
                setDatas([]);

            }
            else {
                await splits(IdentityAccess.activeUsers, setDatas, history);
            }
            // setEnquiry(getEnquiryDetails);
        }
        else if (result.result.isSuccess == false) {
            NotificationManager.error(result.result.message);
            // sessionStorage.clear();
            // history.push('/')
        }
    }

    useEffect(() => {
        setLoad(true);

        getIdentity();
        setLoad(false);
    }, []);

    return (
        <div className="container-form mb-4 mt-0">
            <ResetPasswordModal onOk={() => {
                setModVis(false);
                setSelectedUser({});
            }} datavalue={selectedUser} visible={modvis} onCanceled={() => {
                setModVis(false);
                setSelectedUser({});
            }} title={selectedUser.displayName} />
            <div>
                <div className="d-flex justify-content-between align-items-center p-1 pb-0">
                    <NxActionTab className="users-action-tab px-0"
                        onColumnsClick={() => { setHandel(true) }}
                        exportValue={exportVal}
                        fileName='IAM Active Users'
                        onRefreshClick={async () => {
                            setLoad(true);
                            await getIdentity();
                            setLoad(false);
                        }}
                        // onNewEntryClick={() => { history.push('/framework/iam/add') }}
                        enableNewEntry={false}
                    />
                </div>
                <div className="p-1 pt-0">
                    <NxGridList
                        enableSearch={true}
                        autoSearch={true}
                        enableAutoPagination={true}
                        showShowing={true}
                        header={[
                            {
                                name: 'First Name',
                                sort: true,
                                search: true
                            },
                            {
                                name: 'Last Name',
                                sort: true,
                                search: true
                            },
                            {
                                name: 'Email',
                                search: true
                                // sort: true,
                            },
                            {
                                name: 'Phone',
                                sort: false,
                            },
                            {
                                name: 'Action',
                            }
                        ]}
                        searchValue={sea}
                        onChangeText={(e) => setSea(e)}
                        rowClick={(e) => {
                            // sessionStorage.setItem('editUserId', e.userCode);
                            // console.log(e.userCode, 'passsssssss');
                            // history.push('/framework/iam/edit');
                        }}
                        data={datas}
                    />
                </div>
            </div>
        </div>
    );
}

export default ActiveUser;