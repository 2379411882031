import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'antd';
import './ChangePassword.scss';
import { ItrApiService, ItrAuthService } from '@afiplfeed/itr-ui'
import { NxLoader } from '@afiplfeed/itr-app';
import { NotificationManager } from "react-notifications";
const ChangePassword = ({ getProfileDetails, isModalVisible, handleOk, handleCancel }) => {
    const [step, setStep] = useState(1);
    const [currentPassword, setCurrentPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [userName, setUserName] = useState('');
    const [userCode, setUserCode] = useState('');
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        ItrAuthService.GetUserInfo().then(res => {
            if (res.Success == true) {
                setUserName(res.result.userName);
                setUserCode(res.result.userCode)
            }
        })
    }, [])

    return (
        <>
            <NxLoader state={loader} />
            <Modal
                footer={false}
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <div className="ChangePasswordModal">
                    <div className="header">
                        <h4>
                            Change Password
                        </h4>
                    </div>
                    <div>
                        <div className="feilds">
                            <div style={{ width: '100%' }}>
                                <label>Old Password</label>
                                <input style={{ width: "95%" }} type='password' className='form-control' value={currentPassword} onChange={(e) => {
                                    setCurrentPassword(e.target.value);
                                }} />
                            </div>

                            <div style={{ width: '100%' }}>
                                <label>New Password</label>
                                <input style={{ width: "95%" }} type='password' className='form-control' value={newPassword} onChange={(e) => {
                                    setNewPassword(e.target.value);
                                }} />
                            </div>

                            <div style={{ width: '100%' }}>
                                <label>Confirm Password</label>
                                <input style={{ width: "95%" }} type='password' className='form-control' value={confirmPassword} onChange={(e) => {
                                    setConfirmPassword(e.target.value);
                                }} />
                            </div>
                        </div>
                        <div className="bottom">
                            {/* <p>Adshares will use this password for furture authentication</p> */}
                        </div>
                    </div>
                    <div className="footer-profile-tab">
                        <button className='btn'
                            onClick={() => handleCancel()}
                        >
                            Close
                        </button>
                        <button className='btn btn-primary'
                            onClick={() => {
                                if (confirmPassword == newPassword) {
                                    setLoader(true);
                                    ItrApiService.POST({
                                        data: {
                                            userCode: userCode,
                                            userName: userName,
                                            password: newPassword,
                                            currentPassword: currentPassword,
                                            loginType: ""
                                        },
                                        url: 'PAM/ChangePassword'
                                    }).then(res => {
                                        if (res.Success == true) {
                                            if (res.result.isSuccess == true) {
                                                setLoader(false);
                                                handleOk();
                                                setNewPassword('');
                                                setConfirmPassword('');
                                                setCurrentPassword('');
                                                getProfileDetails();
                                                NotificationManager.success('Password Changed Successfully');
                                            }
                                            else {
                                                NotificationManager.error(res.result.message);
                                                setLoader(false);
                                            }
                                        }
                                        else {
                                            NotificationManager.error(res.result.message);
                                            setLoader(false);
                                            // handleOk();
                                        }
                                    })
                                } else {
                                    setLoader(false);
                                    NotificationManager.error('Password Missmatches!');
                                }
                            }}
                        >
                            Change
                        </button>

                    </div>
                </div>
            </Modal>
        </>
    );
};


export default ChangePassword