import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import './identityAccess.css';
import { NxBreadcrumb, NxTitleTab, NxFooter, NxLoader, NxGridList } from '@afiplfeed/itr-app';
import Headers from "../../../Screens/Headers/Headers";
import { Switch, Steps, Button, Select, message } from 'antd';
import { ItrApiService, ItrAuthService } from "@afiplfeed/itr-ui";
import { NotificationManager, NotificationContainer } from "react-notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faMinusCircle, faPlus, faPlusCircle, faUserCheck } from "@fortawesome/free-solid-svg-icons";
import { requireValid, emailValid } from "../../Validator";



// const splits = async (dests, setDatas) => {
//     let arr = [];
//     for (let value of dests) {
//         let data = [value.firstName, value.lastName, value.emailID, value.mobile];
//         let obj = { sendItem: value, data: data };
//         arr = [...arr, obj]
//     }
//     setDatas(arr);
// }



export default function IdentityAccess() {
    const { Option } = Select;

    // const Buttons = ({elements}) => {
    //     return <div className="form-button container-fluid nx-enquiry-buttons mb-5 px-4">
    //         <button className="btn btn-primary rounded-pill buttons" onClick={() => {
    //             console.log(elements);
    //             ItrApiService.POST({
    //                 data: elements,
    //                 url: "EntityPlatform/EntityUser/SaveEntityUser",
    //             })
    //         }}> Save</button>
    //         <button className="btn close btn-outline-primary rounded-pill" onClick={() =>{
    //             history.push('/framework/iam');
    //         }}> Close</button>
    //     </div>
    // }

    const blockChar = () => {
        $("input[name='numonly']").on('input', function (e) {
            $(this).val($(this).val().replace(/[^0-9]/g, ''));
        });
    }

    let history = useHistory();

    const { Step } = Steps;
    const [current, setCurrent] = useState(0);
    const [loading, setLoading] = useState(false);

    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    const header = [
        { title: 'Personal' },
        { title: 'Access' },
        { title: 'Privileges' },
        // { title: 'Review' },
    ]

    const [roles, setRoles] = useState([{
        rollCode: '',
        rollName: '',
        description: '',
        additionalInfo: ''
    }]);

    const [datas, setDatas] = useState([]);

    const [department, setDepartment] = useState([]);
    const [reporting, setReporting] = useState([]);
    const [unitMaster, setUnitMaster] = useState([]);
    // const [reportingList,setReportingList] = useState([]);

    // console.log(department,'/////////////////////////////////////')


    const getDepartmentList = async () => {
        setLoading(true);
        let result = await ItrApiService.GET({
            url: 'Catalog/Users/GetUserDepartmentList'
        });
        if (result.Success == true) {
            if (result.result.isSuccess == true) {
                let data = result.result.data;
                if (data == null) {

                }
                else {
                    console.log(data, 'units');
                    if (data.unitMasters && data.unitMasters.length > 0) {
                        setUnitMaster(data.unitMasters)
                    }
                    setDepartment(data.departments);
                    if (data.users) {
                        // console.log(data.users)
                        setReporting(data.users);
                    }
                }
            }
        }
        else if (result.result.isSuccess == false) {
            return ''
        }
        setLoading(false);
    }


    const [code, setCode] = useState()
    const getData = async () => {
        let result = await ItrApiService.GET({
            url: 'RevOps/EntitySubscription/AllSubscriptionLicense'
        });
        if (result.result.isSuccess == true) {
            let data = result.result.data;

            // console.log(data, 'entityUserRoleGrid')
            if (data === null) {
                // alert(result.result.message);
            } else if (!data.length) {

            } else {
                let array = []
                for (let role of data) {
                    setCode(role.ServiceCode);
                    var rolesData = []
                    let result1 = await ItrApiService.GET({
                        url: `DevOps/Enrollment/GetAllEnrollmentRoleByServiceCode/${role.ServiceCode}`
                    });
                    if (result1.result.isSuccess == true) {
                        let data = result1.result.data;
                        console.log(data, 'entityUserRoleList', role.ServiceCode)
                        if (data === null || !data.length) {
                            // alert(result.result.message);
                        }
                        else {
                            console.log('lllllllllllllllllllllllllllll', data)
                            rolesData = data;
                        }
                    }
                    // console.log(role.ServiceCode, 'kkkkkkkkkk')

                    var keysData = [];
                    let result2 = await ItrApiService.GET({
                        url: `RevOps/EntitySubscription/ActiveLicense/${role.ServiceCode}`
                    });
                    if (result2.result.isSuccess == true) {
                        let data = result2.result.data;
                        // console.log(data, 'entityUserRoleList')
                        if (data === null || !data.length) {
                            // alert(result.result.message);
                        }
                        else {
                            keysData = data
                        }
                    }
                    let obj = { ...role, rolesData: rolesData, keys: keysData }
                    array = [...array, obj]
                }
                // console.log(array);
                setDatas(array);
            }
            // setCustomers(getCustomerDetails);
        }
        else if (result.result.isSuccess == false) {
            // alert(result.result.message);
            // sessionStorage.clear();
            // history.push('/')
        }
    }

    // RoleList
    const [roleData, setRoleData] = useState([]);
    const getRoleList = async () => {

    }

    // keyList
    const [keyData, setKeyData] = useState([]);
    const getKeyList = async () => {

    }

    const createIdentityAccess = async () => {
        setLoading(true);
        // console.log(elements)
        let result = await ItrApiService.POST({
            data: elements,
            url: "Catalog/Users/SaveUser",
        })
        // console.log(result,'identity&Access');
        if (result.Success == true) {
            if (result.result.isSuccess == true) {
                message.success('Successfully Created');
                history.push('/manage-users');
            }
        }
        else {
            alert('Failed to update')
        }
        setLoading(false);
    }

    // getCustomerDetails
    const [userData, setuserData] = useState([]);
    const getCustomerData = async () => {
        ItrAuthService.GetUserInfo().then(res => {
            // console.log(res.result,'nnnnnnnnnnnn');
            setElements({ ...elements, entityCode: res.result.entityCode, userCode: res.result.userCode, userType: res.result.accessType })
            setuserData(res.result);
        });
    }

    const [entity, setEntity] = useState('');
    const [entityU, setEntityU] = useState([])

    const [entityUnit, setEntityUnit] = useState('');

    const [entityUnits, setEntityUnits] = useState([])



    const [elements, setElements] = useState({
        UserID: 0,
        // entityID: 0,
        entityCode: "",
        userCode: "",
        userType: "",
        firstName: "",
        lastName: "",
        displayName: "",
        loginUserName: "",
        designation: "",
        department: "",
        reportingUserCode: "",
        employeeType: "",
        gender: 0,
        mobile: "",
        emailID: "",
        isPortalAccess: false,
        isAccess: false,
        isActive: true,
        locationCode: "",
        // entityUserContacts: [
        //     {
        //         isAccess: true,
        //     }
        // ],
        userAppRoles: [],
        userLocations: [],
        userUnits: []
    });

    // const [elements, setElements] = useState({
    //     "userID": 0,
    //     "userCode": "string",
    //     "userType": "string",
    //     "firstName": "string",
    //     "lastName": "string",
    //     "displayName": "string",
    //     "designation": "string",
    //     "department": "string",
    //     "gender": 0,
    //     "mobile": "string",
    //     "emailID": "string",
    //     "isPortalAccess": true,
    //     "isAccess": true,
    //     "locationCode": "string",
    //     "loginUserName": "string",
    //     "reportingUserCode": "string",
    //     "employeeType": "string",
    //     "userContacts": [],
    //     "userAppRoles": [],
    //     "userLocations": [],
    //     "entityCode": "string",
    //     "isActive": true
    // })


    useEffect(() => {
        setLoading(true);
        getData();
        getRoleList();
        getKeyList();
        getCustomerData();
        getDepartmentList();
        setLoading(false)
    }, []);

    const [vald1, setVald1] = useState({
        firstName: true,
        displayName: true,
        userName: true,
        emailID: true,
        mobile: true,
        department: true,
        employeeType: true,
        locationCode: true
    });


    const [handel, setHandel] = useState(false);

    const [showAvailable, setShowAvailable] = useState('');

    return (
        <div className="identity-Access">
            <NxLoader state={loading} />
            <Headers />
            {/* <NxBreadcrumb className="navigation-breadcrumb" data={[
                {
                    name: 'Customer Board',
                    onClick: () => {
                        history.push('/customer-board');
                    }
                },
                {
                    name: 'Identity List',
                    onClick: () => {
                        history.push('/framework/iam');
                    }
                }
            ]} /> */}
            <NxTitleTab className="title-tab" title='Add User' onCloseClick={() => { history.goBack() }} />


            <div className="card m-4 mt-0">
                <div className="card-body p-0">
                    <NotificationContainer />

                    <Steps current={current} labelPlacement="vertical" responsive={true} size="small" >
                        {header.map((title, index) => {
                            return <Step key={index} icon={current == index ? <div style={{ backgroundColor: '#1890ff', padding: 5, borderRadius: '24px', height: '24px', width: '24px', fontSize: '12px', display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white' }}>{index + 1}</div>
                                : current > index && <div style={{ backgroundColor: '#73b504', padding: 5, borderRadius: '24px', height: '24px', width: '24px', fontSize: '12px', display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white' }}>{index + 1}</div>}
                                title={title.title} />
                        })}
                    </Steps>

                    {current == 0 ? <div className="container-form framework-container">
                        <div className="row justify-content-start container-fluid w-100 mb-4">
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <h6>Personal Information</h6>
                                <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">
                                    <label htmlFor="firstName" for="firstName">First Name</label>
                                    <input className="form-control form-control-sm mt-0" required id="firstName" type="text" value={elements.firstName} onChange={(e) => {
                                        setElements({ ...elements, firstName: e.target.value })
                                    }} />
                                    <div class={vald1.firstName == false ? "invalid-data" : 'd-none'}>
                                        Please choose a First Name.
                                    </div>
                                </div>

                                <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">
                                    <label htmlFor="lastName">Last Name</label>
                                    <input className="form-control form-control-sm mt-0" id="lastName" type="text" value={elements.lastName} onChange={(e) => {
                                        setElements({ ...elements, lastName: e.target.value })
                                    }} />
                                </div>

                                <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">
                                    <label htmlFor="displayName">Display Name</label>
                                    <input className="form-control form-control-sm mt-0" id="displayName" type="text" value={elements.displayName} onChange={(e) => {
                                        setElements({ ...elements, displayName: e.target.value })
                                    }} />
                                    <div class={vald1.displayName == false ? "invalid-data" : 'd-none'}>
                                        Please choose a Display Name.
                                    </div>
                                </div>

                                <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <label htmlFor="displayName">User Name</label>
                                        <a className={showAvailable && showAvailable.result.isSuccess == true ? 'text-success' : 'text-danger'}>
                                            {showAvailable && showAvailable.result.isSuccess == true ? 'Available' : showAvailable && showAvailable.result.isSuccess == false ? 'Please Use Different UserName' : ''}
                                        </a>
                                    </div>
                                    <div className="row align-items-center">
                                        <div className="col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11">
                                            <input className="form-control form-control-sm mt-0" id="displayName" type="text" value={elements.loginUserName} onChange={(e) => {
                                                setElements({ ...elements, loginUserName: e.target.value })
                                            }} />
                                            <div class={vald1.userName == false ? "invalid-data" : 'd-none'}>
                                                Please choose a User Name.
                                            </div>
                                        </div>
                                        <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1" role="button" onClick={async () => {
                                            let check = await ItrApiService.GET({
                                                url: `Catalog/Users/CheckUserAvailability/${elements.loginUserName}`
                                            })
                                            // console.log(check);
                                            setShowAvailable(check);

                                        }}>
                                            <FontAwesomeIcon icon={faUserCheck} color="gray" />
                                        </div>
                                    </div>

                                    {/* <button className="btn btn-primary btn-sm mt-1" onClick={ async() => {
                                            let check = await ItrApiService.GET({
                                                url:`EntityPlatform/EntityUser/CheckUserAvailability/${elements.loginUserName}`
                                            })
                                            // console.log(check);
                                            setShowAvailable(check);
                                            
                                        }}>Check Availability</button> */}
                                    &nbsp;
                                    {/* <a className={ showAvailable && showAvailable.result.isSuccess == true ? 'text-success' : 'text-danger'}>
                                        { showAvailable &&  showAvailable.result.isSuccess == true ? 'Available' : showAvailable && showAvailable.result.isSuccess == false ? 'Please Use Different UserName' : ''}
                                    </a> */}
                                </div>

                            </div>

                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <h6>Contact</h6>
                                <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">
                                    <label htmlFor="emailId">Email Address</label>
                                    <input className="form-control form-control-sm mt-0" id="emailId" type="text" value={elements.emailID} onChange={(e) => {
                                        setElements({ ...elements, emailID: e.target.value })
                                    }} />
                                    <div class={vald1.emailID == false ? "invalid-data" : 'd-none'}>
                                        Enter the Correct Email Formate.
                                    </div>
                                </div>

                                <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">
                                    <label htmlFor="mobile">Mobile</label>
                                    <input className="form-control form-control-sm mt-0 cls-111-ff" id="mobile-number" type="number" maxLength="15" value={elements.mobile} name="numonly" pattern="[{0-9}]" onChange={(e) => {
                                        setElements({ ...elements, mobile: e.target.value })
                                    }} />
                                    <div class={vald1.mobile == false ? "invalid-data" : 'd-none'}>
                                        Please choose a Mobile.
                                    </div>
                                </div>

                                <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">
                                    <label htmlFor="mobile">Department</label>
                                    <select className="form-select form-select-sm" value={elements.department} onChange={(e) => setElements({ ...elements, department: e.target.value })}>
                                        <option value="" selected disabled> -- Please Select --</option>
                                        {department.map((item, index) => <option key={index} value={item.departmentCode}> {item.departmentName} </option>)}
                                    </select>
                                    <div class={vald1.department == false ? "invalid-data" : 'd-none'}>
                                        Please choose a Department.
                                    </div>
                                </div>

                                <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">
                                    <label htmlFor="mobile">Employee Type</label>
                                    <select className="form-select form-select-sm" value={elements.employeeType} onChange={(e) => setElements({ ...elements, employeeType: e.target.value })}>
                                        <option value="" selected disabled> -- Please Select --</option>
                                        <option value="Internal">Internal</option>
                                        <option value="External">External</option>
                                        <option value="Trainee">Trainee</option>
                                        <option value="Intern">Intern</option>
                                    </select>
                                    <div class={vald1.employeeType == false ? "invalid-data" : 'd-none'}>
                                        Please choose a Employee type.
                                    </div>
                                </div>

                                <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">
                                    <label htmlFor="mobile">Reporting</label>
                                    <select className="form-select form-select-sm" value={elements.reportingUserCode} onChange={(e) => setElements({ ...elements, reportingUserCode: e.target.value })}>
                                        <option value="" selected disabled> -- Please Select --</option>
                                        {reporting.map((userName, index) => {
                                            return <option key={index} value={userName.userCode}> {userName.displayName} </option>
                                        })}
                                    </select>
                                </div>
                            </div>








                            {/* <div className="col-6 col-md-6 col-sm-6 col-lg-6 col-xl-6 mt-2">
                                        <label htmlFor="LastName">Entity</label>
                                        <select className="form-select">
                                            <option>--please-select--</option>
                                        </select>
                                    </div> */}

                            {/* <div className="col-12 col-md-6 col-sm-6 col-lg-4 col-xl-4 mt-2">
                                        <div className="row justify-content-center align-items-center">
                                            <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-1">
                                                <label htmlFor="portalAccess">Portal Access</label><br />
                                                <Switch checked={elements.isPortalAccess} onChange={(checked, e) => {
                                                    setElements({ ...elements, isPortalAccess: checked })
                                                }} /> &nbsp;
                                            </div>

                                            <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-1">
                                                <label htmlFor="access">Access</label><br />
                                                <Switch checked={elements.isAccess} onChange={(checked, e) => {
                                                    setElements({ ...elements, isAccess: checked })
                                                }} /> &nbsp;
                                            </div>
                                        </div>
                                    </div> */}
                        </div>
                    </div> :
                        current == 1 ? <div className="container-form framework-container">
                            <div className="row justify-content-start container-fluid w-100 mb-4">
                                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <h6>Portal & Platform Access</h6>
                                    <div>
                                        <div className="row align-items-center">
                                        <div className="col-6 col-md-12 col-sm-12 col-lg-10 col-xl-10 mt-2">
                                                    <label htmlFor="firstName">Platform Access</label> &nbsp;
                                                    <Switch checked={elements.isAccess} onChange={(checked, e) => {
                                                        setElements({ ...elements, isAccess: checked })
                                                    }} />
                                                </div>

                                                <div className="col-10 col-md-12 col-sm-10 col-lg-10 col-xl-10 mt-2">
                                                    <label htmlFor="access">Portal Access</label> &nbsp;
                                                    <Switch checked={elements.isPortalAccess} onChange={(checked, e) => {
                                                        setElements({ ...elements, isPortalAccess: checked })
                                                    }} />
                                                </div>

                                                <div className="col-10 col-md-10 col-sm-10 col-lg-10 col-xl-10 mt-2">
                                                    <label htmlFor="access">Unit</label><br />
                                                    <select className="form-select"
                                                        value={entityUnit}
                                                        onChange={(e) => { setEntityUnit(e.target.value) }}
                                                    >
                                                        <option value="" disabled selected>--Please Select--</option>
                                                        {unitMaster.map((item, index) => {
                                                            return <option key={index} value={item.uCode}> {item.uName} </option>
                                                        })}
                                                    </select>
                                                    {/* <div class={vald1.locationCode == false ? "invalid-data" : 'd-none'}>
                                            Please choose a Location.
                                        </div> */}
                                                </div>

                                                <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 mt-4" onClick={() => {
                                                    setEntityUnits([...entityUnit, { name: entityUnit }]);
                                                    if (entityUnit != '') {
                                                        let obj = elements.userUnits.find(o => o.uCode == entityUnit);
                                                        // console.log(obj)
                                                        if (!obj) {
                                                            setElements({
                                                                ...elements, userUnits: [...elements.userUnits, {
                                                                    userUnitID: 0,
                                                                    userID: 1,
                                                                    uCode: entityUnit
                                                                }]
                                                            })
                                                            setEntityUnit('');
                                                        }
                                                    }
                                                }} >
                                                    <FontAwesomeIcon icon={faPlusCircle} color="black" role="button" />
                                                    {/* <button type="button" className="btn btn-outline-light btn-sm mt-1" onClick={() => {
                                                    setEntityU([...entityU, {name: entity}]);
                                                    setElements({...elements, userLocations: [...elements.userLocations, {
                                                        entityUserLocationID:0,
                                                        entityUserID:1,
                                                        locationCode: entity
                                                    }]})
                                                    setEntity('');
                                                }} > */}
                                                    {/* </button> */}
                                                </div>
                                        </div>
                                    </div>
                            

                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-4">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="col-6 col-md-12 col-sm-12 col-lg-12 col-xl-12">
                                                    <div class="table-wrapper-scroll-y my-custom-scrollbar">
                                                        <table class="table table-hover mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" className="border-bottom-0">
                                                                        <span style={{ fontSize: '13px' }}>Unit Name</span>
                                                                    </th>
                                                                    <th scope="col" className="border-bottom-0">
                                                                        <span style={{ fontSize: '13px' }}>Action</span>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {elements.userUnits.map((data, index) => {
                                                                    return <tr>
                                                                        <td style={{ fontSize: '13px' }} key={index}> {data.uCode} </td>
                                                                        <td style={{ fontSize: '13px' }} > <FontAwesomeIcon icon={faMinusCircle} color="red" onClick={() => {
                                                                            let index = elements.userUnits.findIndex(o => o.uCode == data.uCode);
                                                                            // console.log(index)
                                                                            let arr = [];
                                                                            arr = elements.userUnits;
                                                                            let eindex = index + 1
                                                                            // console.log(arr)
                                                                            arr.splice(index, eindex);
                                                                            setElements({ ...elements, uCode: arr });
                                                                        }} /> </td>
                                                                    </tr>
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        {/* <NxGridList header={[
                                            {
                                                name: 'location Name',
                                            },
                                            {
                                                name: 'Action',
                                            },
                                        ]}
                                            rowClick={(e) => {
                                                // console.log(e,'passsssssss');
                                            }}
                                            data={datas} 
                                        /> */}
                                    </div>
                                </div>

                                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <h6>Location Access</h6>
                                    <div className="col-6 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">

                                    </div>


                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        {/* <label htmlFor="access">Entity</label><br /> */}
                                        <div className="row align-items-center">

                                            <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10">
                                                <label htmlFor="access">Base Location</label><br />
                                                <select className="form-select" value={elements.locationCode} onChange={(e) => { setElements({ ...elements, locationCode: e.target.value }) }}>
                                                    <option value="" disabled selected>--Please Select--</option>
                                                    <option value="IND">India</option>
                                                    <option value="BAN">Bangladesh</option>
                                                    <option value="JOR">Jordan</option>
                                                </select>
                                                <div class={vald1.locationCode == false ? "invalid-data" : 'd-none'}>
                                                    Please choose a Location.
                                                </div>
                                            </div>

                                            <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10">
                                                <label>Additional Location</label>
                                                {/* <Select className="form-select" mode="multiple" value={entity} onChange={(e) => {
                                                    setEntity(e);
                                                }}>
                                                    <Option selected value='' disabled>--Please Select--</Option>
                                                    <Option value="India">India</Option>
                                                    <Option value="Bangladesh" >Bangladesh</Option>
                                                    <Option value="Jordan">Jordan</Option>
                                                </Select> */}
                                                <select className="form-select" value={entity} onChange={(e) => {
                                                    setEntity(e.target.value);
                                                }}>
                                                    <option selected value='' disabled>--Please Select--</option>
                                                    <option value="IND" style={{
                                                        backgroundColor: elements.userLocations.findIndex(o => o.locationCode == 'India') != -1 ? '#1890ff' : "#fff",
                                                        color: elements.userLocations.findIndex(o => o.locationCode == 'India') != -1 ? 'white' : '#000'
                                                    }} >India</option>
                                                    <option value="BAN" style={{
                                                        backgroundColor: elements.userLocations.findIndex(o => o.locationCode == 'Bangladesh') != -1 ? '#1890ff' : "#fff",
                                                        color: elements.userLocations.findIndex(o => o.locationCode == 'Bangladesh') != -1 ? 'white' : '#000'
                                                    }} >Bangladesh</option>
                                                    <option value="JOR"
                                                        style={{
                                                            backgroundColor: elements.userLocations.findIndex(o => o.locationCode == 'Jordan') != -1 ? '#1890ff' : "#fff",
                                                            color: elements.userLocations.findIndex(o => o.locationCode == 'Jordan') != -1 ? 'white' : '#000'
                                                        }}
                                                    >Jordan</option>
                                                </select>
                                            </div>

                                            <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 mt-4" onClick={() => {
                                                setEntityU([...entityU, { name: entity }]);
                                                if (entity != '') {
                                                    let obj = elements.userLocations.find(o => o.locationCode == entity);
                                                    // console.log(obj)
                                                    if (!obj) {
                                                        setElements({
                                                            ...elements, userLocations: [...elements.userLocations, {
                                                                entityUserLocationID: 0,
                                                                entityUserID: 1,
                                                                locationCode: entity
                                                            }]
                                                        })
                                                        setEntity('');
                                                    }
                                                }
                                            }} >
                                                <FontAwesomeIcon icon={faPlusCircle} color="black" role="button" />
                                                {/* <button type="button" className="btn btn-outline-light btn-sm mt-1" onClick={() => {
                                                    setEntityU([...entityU, {name: entity}]);
                                                    setElements({...elements, userLocations: [...elements.userLocations, {
                                                        entityUserLocationID:0,
                                                        entityUserID:1,
                                                        locationCode: entity
                                                    }]})
                                                    setEntity('');
                                                }} > */}
                                                {/* </button> */}
                                            </div>


                                        </div>

                                    </div>

                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-4">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="col-6 col-md-12 col-sm-12 col-lg-12 col-xl-12">
                                                    <div class="table-wrapper-scroll-y my-custom-scrollbar">
                                                        <table class="table table-hover mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" className="border-bottom-0">
                                                                        <span style={{ fontSize: '13px' }}>Location Name</span>
                                                                    </th>
                                                                    <th scope="col" className="border-bottom-0">
                                                                        <span style={{ fontSize: '13px' }}>Action</span>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {elements.userLocations.map((data, index) => {
                                                                    return <tr>
                                                                        <td style={{ fontSize: '13px' }} key={index}> {data.locationCode} </td>
                                                                        <td style={{ fontSize: '13px' }} > <FontAwesomeIcon icon={faMinusCircle} color="red" onClick={() => {
                                                                            let index = elements.userLocations.findIndex(o => o.locationCode == data.locationCode);
                                                                            // console.log(index)
                                                                            let arr = [];
                                                                            arr = elements.userLocations;
                                                                            let eindex = index + 1
                                                                            // console.log(arr)
                                                                            arr.splice(index, eindex);
                                                                            setElements({ ...elements, locationCode: arr });
                                                                        }} /> </td>
                                                                    </tr>
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        {/* <NxGridList header={[
                                            {
                                                name: 'location Name',
                                            },
                                            {
                                                name: 'Action',
                                            },
                                        ]}
                                            rowClick={(e) => {
                                                // console.log(e,'passsssssss');
                                            }}
                                            data={datas} 
                                        /> */}
                                    </div>
                                </div>



                                {/* <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">
                                    <div className="row">
                                            <header className="fw-bold">Additional Entity Location</header>
                                    </div>
                                </div> */}

                                {/* <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                        {elements.userLocations.map((item, index) => {
                                            return <>
                                                <p key={index}>{item.locationCode} &nbsp;
                                                    <button className="btn btn-outline-light btn-sm">
                                                        <FontAwesomeIcon icon={faMinusCircle} color="red"/>
                                                    </button>
                                                </p>
                                            </>
                                        })}
                                    </div>
                                </div>
                            </div> */}
                            </div>
                        </div> :
                            current == 2 ?
                                <div className="container-form framework-container">
                                    <div className="row justify-content-start w-100 mb-4">
                                        <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div class="table-wrapper-scroll-y my-custom-scrollbar">
                                                        <table class="table table-hover mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" className="border-bottom-0"><span style={{ fontSize: '13px' }}>App Name</span></th>
                                                                    <th scope="col" className="border-bottom-0"><span style={{ fontSize: '13px' }}>Access</span></th>
                                                                    <th scope="col" className="border-bottom-0"><span style={{ fontSize: '13px' }}>Role</span></th>
                                                                    <th scope="col" className="border-bottom-0"><span style={{ fontSize: '13px' }}>Subscription Key</span></th>
                                                                    {/* <th scope="col">Action</th> */}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {datas.map((item, index) => {
                                                                    return <tr className='user-table' key={index}>
                                                                        <td className="pt-2">{item.ServiceName}</td>
                                                                        <td className="pt-2">
                                                                            <Switch checked={elements.userAppRoles.isAccess}
                                                                                onChange={(e) => {

                                                                                    if (e == true) {
                                                                                        let obj = {
                                                                                            entityUserAppRoleID: 0,
                                                                                            entityUserID: 0,
                                                                                            enrollmentID: item.EnrollmentId,
                                                                                            isAccess: true,
                                                                                            roleID: 0,
                                                                                            isActive: true,
                                                                                            licenseKeyID: 0,
                                                                                            licenseKey: ""
                                                                                        }
                                                                                        let arr = elements.userAppRoles;
                                                                                        arr = [...arr, obj];
                                                                                        setElements({ ...elements, userAppRoles: arr })
                                                                                    } else {
                                                                                        let array = [...elements.userAppRoles];
                                                                                        var index1 = array.findIndex(vals => vals.enrollmentID === item.EnrollmentId);
                                                                                        array.splice(index1, 1)
                                                                                        setElements({ ...elements, userAppRoles: array })
                                                                                        // setRemoveVal(array);
                                                                                    }
                                                                                    // arr[0].isAccess = e
                                                                                    // console.log(arr);
                                                                                }} />
                                                                        </td>
                                                                        <td className="pt-2">
                                                                            <select className="form-select" value={elements.userAppRoles.roleID}
                                                                                onChange={(e) => {
                                                                                    let array = [...elements.userAppRoles];
                                                                                    var index1 = array.findIndex(vals => vals.enrollmentID === item.EnrollmentId);
                                                                                    if (index1 != -1) {
                                                                                        array[index1].roleID = parseInt(e.target.value);
                                                                                    }
                                                                                    setElements({ ...elements, userAppRoles: array })
                                                                                }}>
                                                                                <option selected disabled value=''>--please select--</option>
                                                                                {item.rolesData.map((data, index) => {
                                                                                    return <option key={index} value={data.id}> {data.roleName} </option>
                                                                                })}
                                                                            </select>
                                                                        </td>
                                                                        <td className="pt-2" align="center">
                                                                            <select className="form-select" value={elements.userAppRoles.licenseKey} disabled={elements.userAppRoles.isAccess === false ? true : false}
                                                                                onChange={(e) => {
                                                                                    let array = [...elements.userAppRoles];
                                                                                    var index1 = array.findIndex(vals => vals.enrollmentID === item.EnrollmentId);
                                                                                    if (index1 != -1) {
                                                                                        if (e.target.value == '') {

                                                                                        } else {
                                                                                            let vals = JSON.parse(e.target.value)
                                                                                            array[index1].licenseKeyID = vals.licenseKeyID;
                                                                                            array[index1].licenseKey = vals.licenseKey;
                                                                                        }
                                                                                    }
                                                                                    setElements({ ...elements, userAppRoles: array })
                                                                                }}
                                                                            >
                                                                                <option value=''>--please select--</option>
                                                                                {item.keys.map((data, index) => {
                                                                                    return <option key={index} id={data.licenseKey} value={JSON.stringify(data)}> {data.licenseKey} </option>
                                                                                })}
                                                                            </select>
                                                                        </td>
                                                                        {/* <td>
                                                            <button className="btn btn-primary btn-sm">Add +</button>
                                                        </td> */}
                                                                    </tr>
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        {/* <div class="table-wrapper-scroll-y my-custom-scrollbar">
                                        <table class="table table-bordered mb-0">
                                            <thead>
                                                <tr>
                                                <th scope="col">App Name</th>
                                                <th scope="col">Access</th>
                                                <th scope="col">Role</th>
                                                <th scope="col">Subscription Key</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {}
                                            </tbody>
                                        </table>
                                    </div> */}


                                    </div>
                                </div>
                                : ''
                        //                                 <div className="container-form framework-container">
                        //                                     <div className="row justify-content-start container-fluid w-100 mb-4">
                        //                                         <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        //                                             <h6>Personal Information</h6>
                        // 
                        //                                             <div className="col-6 col-md-4 col-sm-4 col-lg-3 col-xl-2 mt-2">
                        //                                                 <label htmlFor="firstName">First Name</label>
                        //                                                 <input className="form-control-plaintext form-control-sm mt-0 bg-white" id="firstName" type="text" value={elements.firstName ? elements.firstName : '-'} disabled />
                        //                                             </div>
                        // 
                        //                                             <div className="col-6 col-md-4 col-sm-4 col-lg-3 col-xl-2 mt-2">
                        //                                                 <label htmlFor="lastName">Last Name</label>
                        //                                                 <input className="form-control-plaintext fw-lighter form-control-sm mt-0 bg-white" id="lastName" type="text" value={elements.lastName ? elements.lastName : '-'} disabled />
                        //                                             </div>
                        // 
                        //                                             <div className="col-12 col-md-4 col-sm-4 col-lg-3 col-xl-2 mt-2">
                        //                                                 <label htmlFor="displayName">Display Name</label>
                        //                                                 <input className="form-control-plaintext fw-lighter form-control-sm mt-0 bg-white" id="displayName" type="text" value={elements.displayName ? elements.displayName : '-'} disabled />
                        //                                             </div>
                        // 
                        //                                             <div className="col-12 col-md-4 col-sm-4 col-lg-3 col-xl-3 mt-2">
                        //                                                 <label htmlFor="displayName">UserName</label>
                        //                                                 <input className="form-control-plaintext fw-lighter form-control-sm mt-0 bg-white" id="displayName" type="text" value={elements.loginUserName ? elements.loginUserName : '-'} disabled />
                        //                                             </div>
                        //                                         </div>
                        // 
                        // 
                        //                                         <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        //                                             <h6>Contact</h6>
                        // 
                        //                                             <div className="col-12 col-md-4 col-sm-4 col-lg-3 col-xl-3 mt-2">
                        //                                                 <label htmlFor="emailId">Email Address</label>
                        //                                                 <input className="form-control-plaintext fw-lighter form-control-sm mt-0 bg-white" id="emailId" type="text" value={elements.emailID ? elements.emailID : '-'} disabled />
                        //                                             </div>
                        // 
                        //                                             <div className="col-12 col-md-4 col-sm-4 col-lg-3 col-xl-3 mt-2">
                        //                                                 <label htmlFor="mobile">Mobile</label>
                        //                                                 <input className="form-control-plaintext fw-lighter form-control-sm mt-0 bg-white" id="mobile" type="text" maxLength="15" value={elements.mobile ? elements.mobile : '-'} disabled />
                        //                                             </div>
                        //                                         </div>
                        //                                     </div>
                        // 
                        //                                     <div className="row justify-content-start container-fluid w-100 mb-4">
                        //                                         <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        //                                             <h6>Access</h6>
                        // 
                        //                                             <div className="col-6 col-md-4 col-sm-4 col-lg-3 col-xl-2 mt-2">
                        //                                                 <label htmlFor="displayName">Platform Access</label><br />
                        //                                                 <Switch className="mt-0" checked={elements.isAccess} />
                        //                                             </div>
                        // 
                        //                                             <div className="col-6 col-md-4 col-sm-4 col-lg-3 col-xl-2 mt-2">
                        //                                                 <label htmlFor="displayName">Portal Access</label><br />
                        //                                                 <Switch className="mt-0" checked={elements.isPortalAccess} />
                        //                                             </div>
                        // 
                        //                                             <div className="col-12 col-md-4 col-sm-4 col-lg-3 col-xl-2 mt-2">
                        //                                                 <label htmlFor="displayName">Base Location</label>
                        //                                                 <input className="form-control-plaintext fw-lighter form-control-sm mt-0 bg-white" id="displayName" type="text" value={elements.locationCode ? elements.locationCode : '-'} disabled />
                        //                                             </div>
                        //                                         </div>
                        // 
                        //                                         <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        //                                             <h6>Additional Location</h6>
                        //                                             <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-4">
                        //                                                 <div className="card">
                        //                                                     <div className="card-body">
                        //                                                         <div className="col-6 col-md-12 col-sm-12 col-lg-12 col-xl-12">
                        //                                                             <div class="table-wrapper-scroll-y my-custom-scrollbar">
                        //                                                                 <table class="table table-hover mb-0">
                        //                                                                     <thead>
                        //                                                                         <tr>
                        //                                                                             <th scope="col" className="border-bottom-0">
                        //                                                                                 <span style={{ fontSize: '13px' }}>Location Name</span>
                        //                                                                             </th>
                        //                                                                         </tr>
                        //                                                                     </thead>
                        //                                                                     <tbody>
                        //                                                                         {elements.userLocations.map((data, index) => {
                        //                                                                             return <tr>
                        //                                                                                 <td style={{ fontSize: '13px' }} key={index}> {data.locationCode} </td>
                        //                                                                             </tr>
                        //                                                                         })}
                        //                                                                     </tbody>
                        //                                                                 </table>
                        //                                                             </div>
                        // 
                        //                                                         </div>
                        //                                                     </div>
                        //                                                 </div>
                        //                                             </div>
                        //                                         </div>
                        //                                     </div>
                        // 
                        // 
                        //                                     <div className="row justify-content-start container-fluid w-100 mb-4">
                        //                                         <h6>App Access</h6>
                        //                                         <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">
                        //                                             <div className="card">
                        //                                                 <div className="card-body">
                        //                                                     <div class="table-wrapper-scroll-y my-custom-scrollbar">
                        //                                                         <table class="table table-hover mb-0">
                        //                                                             <thead>
                        //                                                                 <tr>
                        //                                                                     <th scope="col" className="border-bottom-0"><span style={{ fontSize: '13px' }}>App Name</span></th>
                        //                                                                     <th scope="col" className="border-bottom-0"><span style={{ fontSize: '13px' }}>Access</span></th>
                        //                                                                     <th scope="col" className="border-bottom-0"><span style={{ fontSize: '13px' }}>Role</span></th>
                        //                                                                     <th scope="col" className="border-bottom-0"><span style={{ fontSize: '13px' }}>Subscription Key</span></th>
                        //                                                                 </tr>
                        //                                                             </thead>
                        //                                                             <tbody>
                        //                                                                 {datas.map((item, index) => {
                        //                                                                     return <tr className='user-table' key={index}>
                        //                                                                         <td className="pt-2">{item.enrollment.serviceName}</td>
                        //                                                                         <td className="pt-2">
                        //                                                                             <Switch checked={elements.userAppRoles.isAccess}
                        //                                                                                 onChange={(e) => {
                        //                                                                                     let arr = elements.userAppRoles;
                        //                                                                                     arr[0].isAccess = e
                        //                                                                                     console.log(arr);
                        //                                                                                     setElements({ ...elements, userAppRoles: arr })
                        //                                                                                 }} />
                        //                                                                         </td>
                        //                                                                         <td className="pt-2">
                        //                                                                             <select className="form-select" value={elements.userAppRoles.roleID} onChange={(e) => {
                        //                                                                                 let arr = elements.userAppRoles;
                        //                                                                                 arr[0].roleID = e.target.value
                        //                                                                                 console.log(arr);
                        //                                                                                 setElements({ ...elements, userAppRoles: arr })
                        //                                                                             }}>
                        //                                                                                 <option>--please select--</option>
                        //                                                                                 {item.enrollment.enrollmentRoles.map((data, index) => {
                        //                                                                                     return <option key={index}> {data.role} </option>
                        //                                                                                 })}
                        //                                                                             </select>
                        //                                                                         </td>
                        //                                                                         <td className="pt-2" align="center">
                        //                                                                             <select className="form-select" value={elements.userAppRoles.licenseKey}
                        //                                                                                 onChange={(e) => {
                        //                                                                                     let arr = elements.userAppRoles;
                        //                                                                                     arr[0].licenseKey = e.target.value
                        //                                                                                     console.log(arr);
                        //                                                                                     setElements({ ...elements, userAppRoles: arr })
                        //                                                                                 }}
                        //                                                                             >
                        //                                                                                 <option>--please select--</option>
                        //                                                                                 {item.licenseKeys.map((data, index) => {
                        //                                                                                     return <option key={index} > {data.licenseKey} </option>
                        //                                                                                 })}
                        //                                                                             </select>
                        //                                                                         </td>
                        //                                                                     </tr>
                        //                                                                 })}
                        //                                                             </tbody>
                        //                                                         </table>
                        //                                                     </div>
                        //                                                 </div>
                        //                                             </div>
                        //                                         </div>
                        //                                     </div>
                        // 
                        //                                 </div>
                    }

                    <div className="steps-action container" style={{ textAlign: 'right' }}>
                        {current > 0 && (<Button style={{ margin: '0 8px' }} type="primary" onClick={() => prev()}> Previous </Button>)}

                        {/* {current == 0 ? <Button style={{ margin: '0 8px' }} onClick={() => history.push('/customer-board')}> Cancel </Button> : ''} */}

                        {current == 0 ? <Button type="primary" onClick={async () => {

                            let [ck1, ck2, ck3, ck4, ck5, ck6, ck7] = await Promise.all([
                                requireValid(elements.firstName),
                                requireValid(elements.displayName),
                                requireValid(elements.loginUserName),
                                emailValid(elements.emailID),
                                requireValid(elements.mobile),
                                requireValid(elements.department),
                                requireValid(elements.employeeType),
                            ])
                            // let ck1 = await ;
                            setVald1({ ...vald1, firstName: ck1, displayName: ck2, userName: ck3, emailID: ck4, mobile: ck5, employeeType: ck7 });
                            // if (ck1 && ck2 && ck3 && ck4 && ck5 && ck6 && ck7) {
                            // }
                            next()
                        }}> Next </Button> : ''}

                        {current == 1 ? <Button type="primary" onClick={async () => {
                            let ck = await requireValid(elements.locationCode);
                            setVald1({ ...vald1, locationCode: ck })
                            if (ck) {
                                next()
                            }
                        }}> Next </Button> : ''}

                        {current == 2 ? <Button style={{ backgroundColor: '#73a562', color: 'white' }} onClick={createIdentityAccess} >
                            {loading ? <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                : 'Add User'}
                        </Button> : ''}
                    </div>



                    {/* {current === header.length - 1 && (
                                <Button type="primary" disabled={loading} 
                                onClick={async () => {
                                    setLoading(true);
                                    let create = await createUser(firstName,lastName,email,gender,business,salary,addressInfo,verification);
                                    setLoading(false);
                                }}
                                >
                                    {loading ? <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    :  'Submit' }
                                </Button>
                            )} */}
                </div>
            </div>







            {/* <NxCustomTab
                data={[
                    {
                        name: 'Perosnal Information',
                        Components: () => {
                            return <div className="container-form framework-container">
                                <div className="row justify-content-start container-fluid w-100 mb-4">
                                    <div className="col-6 col-md-6 col-sm-6 col-lg-6 col-xl-6 mt-2">
                                        <label htmlFor="firstName">First Name</label>
                                        <input className="form-control mt-0" id="firstName" type="text" value={elements.firstName} onChange={(e) => {
                                            setElements({ ...elements, firstName: e.target.value })
                                        }} />
                                    </div>

                                    <div className="col-6 col-md-6 col-sm-6 col-lg-6 col-xl-6 mt-2">
                                        <label htmlFor="lastName">Last Name</label>
                                        <input className="form-control mt-0" id="lastName" type="text" value={elements.lastName} onChange={(e) => {
                                            setElements({ ...elements, lastName: e.target.value })
                                        }} />
                                    </div>

                                    <div className="col-6 col-md-6 col-sm-6 col-lg-6 col-xl-6 mt-2">
                                        <label htmlFor="emailId">Email Address</label>
                                        <input className="form-control mt-0" id="emailId" type="text" value={elements.emailID} onChange={(e) => {
                                            setElements({ ...elements, emailID: e.target.value })
                                        }} />
                                    </div>

                                    <div className="col-6 col-md-6 col-sm-6 col-lg-6 col-xl-6 mt-2">
                                        <label htmlFor="mobile">Phone No</label>
                                        <input className="form-control mt-0" id="mobile" type="text" value={elements.mobile} onChange={(e) => {
                                            setElements({ ...elements, mobile: e.target.value })
                                        }} />
                                    </div>

                                    <div className="col-6 col-md-6 col-sm-6 col-lg-12 col-xl-12 mt-2">
                                        <label htmlFor="displayName">Display Name</label>
                                        <input className="form-control mt-0" id="displayName" type="text" value={elements.displayName} onChange={(e) => {
                                            setElements({ ...elements, displayName: e.target.value })
                                        }} />
                                    </div>

                                    <div className="col-6 col-md-6 col-sm-6 col-lg-12 col-xl-12 mt-2">
                                        <label htmlFor="displayName">UserName</label>
                                        <input className="form-control mt-0" id="displayName" type="text" value={elements.displayName} onChange={(e) => {
                                            setElements({ ...elements, displayName: e.target.value })
                                        }} />
                                    </div>

                                    <div className="col-12 col-md-6 col-sm-6 col-lg-4 col-xl-4 mt-2">
                                        <div className="row justify-content-center align-items-center">
                                            <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-1">
                                                <label htmlFor="portalAccess">Portal Access</label><br />
                                                <Switch checked={elements.isPortalAccess} onChange={(checked, e) => {
                                                    setElements({ ...elements, isPortalAccess: checked })
                                                }} /> &nbsp;
                                            </div>

                                            <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-1">
                                                <label htmlFor="access">Access</label><br />
                                                <Switch checked={elements.isAccess} onChange={(checked, e) => {
                                                    setElements({ ...elements, isAccess: checked })
                                                }} /> &nbsp;
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    },
                    {
                        name:'Platform & Entity',
                        Components:() => {
                            return <div className="row justify-content-start container-fluid w-100 mb-4">
                                <div className="col-6 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">
                                        <label htmlFor="access">Platform Access</label><br />
                                        <Switch checked={elements.isAccess} onChange={(checked, e) => {
                                            setElements({ ...elements, isAccess: checked })
                                        }} /> &nbsp;
                                </div>

                                <div className="col-6 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">
                                        <label htmlFor="access">Portal Access</label><br />
                                        <Switch checked={elements.isAccess} onChange={(checked, e) => {
                                            setElements({ ...elements, isAccess: checked })
                                        }} /> &nbsp;
                                </div>

                                <div className="col-6 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">
                                        <label htmlFor="access">Base Entity</label><br />
                                        <select className="form-select w-50">
                                            <option>--Please Select--</option>
                                            <option>India</option>
                                            <option>SriLanka</option>
                                        </select>
                                </div>

                                <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                            <label htmlFor="access">Entity</label><br/>
                                            <input className="form-control" value={entity} onChange={(e) => {
                                                setEntity(e.target.value)
                                            }} />
                                        </div>

                                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                            <input type="button" className="btn btn-primary btn-sm mt-3" value="Add" onClick={() => {
                                                setEntityU([...entityU, {name: entity}]);
                                                setEntity('')
                                            }} />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                            <label htmlFor="access">Entity Name</label><br/>
                                            {entityU.map((item, index) => {
                                                return <p key={index}>{item.name}</p>
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    },
                    {
                        name: 'App Access',
                        Components: () => {
                            return <div className="row justify-content-start container-fluid w-100 mb-4">
                                <div className="col-6 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">
                                    <div class="table-wrapper-scroll-y my-custom-scrollbar">
                                        <table class="table table-hover mb-0">
                                            <thead>
                                                <tr>
                                                    <th scope="col">App Name</th>
                                                    <th scope="col">Access</th>
                                                    <th scope="col">Role</th>
                                                    <th scope="col">Subscription Key</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {roles.map((item, index) => {
                                                    return <tr className='user-table' key={index}>
                                                        <td className="pt-2" align="center">Demo App</td>
                                                        <td className="pt-2" align="center">
                                                            <Switch />
                                                        </td>
                                                        <td className="pt-2" align="center">
                                                            <select className="form-select">
                                                                <option>--please select--</option>
                                                            </select>
                                                        </td>
                                                        <td className="pt-2" align="center">
                                                            <select className="form-select">
                                                                <option>--please select--</option>
                                                            </select>
                                                        </td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        },
                    },
                ]} /> */}
            {/* {
                                    name: 'Entity',
                                    Components: () => {
                                        return <div className="row justify-content-start container-fluid w-100 mb-4">
                                            <div className="col-6 col-md-12 col-sm-12 col-lg-12 col-xl-3 mt-2">
            
                                                <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">
                                                    <select className="form-select">
                                                        <option>--Please select--</option>
                                                        <option>India</option>
                                                        <option>Malaysia</option>
                                                        <option>Singapore</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    },
                                } */}

            {/* <hr/> */}
            {/* <Buttons elements={elements} /> */}
            )

            <NxFooter companyName="© 2022 Ithred Solutions" ExtraContent={[
                { name: 'Terms of Service' },
                { name: 'Privacy' },
            ]} />
        </div >
    )
}