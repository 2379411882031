import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Tabs, Switch, Rate, Modal, Button, PageHeader, Tag, Alert, Space, Tooltip } from 'antd';
import { ItrApiService } from '@afiplfeed/itr-ui'
import { NxLoader, NxTitleTab, NxFooter } from '@afiplfeed/itr-app';
import Headers from '../../Screens/Headers/Headers';
import { AppstoreOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign, faIdBadge, faInfo, faTicketAlt, faInfoCircle, faCode } from '@fortawesome/free-solid-svg-icons';
import Enrollment from '../enrollment';
import EnrollmentEntry from '../enrollmentEntry';
import { DashboardOutlined, DollarOutlined, ForkOutlined } from "@ant-design/icons";
import StoreConnectList from '../StoreConnectList/StoreConnectList';
import { NotificationManager } from "react-notifications";
import CustomerSidebar from '../../Screens/CustomerSidebar/CustomerSidebar';
import LicenseManagement from '../LicenseManagement/licenseManagement';
import AppStores from '../AppStore/appStore';

export default function EnrollmentHome({ data = [{ image: '', heding: '', info: '' }] }) {

    const { TabPane } = Tabs;

    let history = useHistory();

    // subscription 
    const [filtersubscrption, setFiltersubscrption] = useState([
        { id: 1, name: "All Apps", isChecked: true },
        { id: 2, name: "Subscribed Apps", isChecked: false },
        { id: 3, name: "Available Apps", isChecked: false },
        { id: 4, name: "Unsubscribed Apps", isChecked: false },
    ])

    const handleChange = (e, index, id) => {
        if (e == true) {
            if (id == 1) {
                setFiltersubscrption([
                    { id: 1, name: "All Apps", isChecked: true },
                    { id: 2, name: "Available Apps", isChecked: false },
                    { id: 3, name: "Subscribed Apps", isChecked: false },
                    { id: 4, name: "Unsubscribed Apps", isChecked: false },
                ])
            } else if (id == 2) {
                setFiltersubscrption([
                    { id: 1, name: "All Apps", isChecked: false },
                    { id: 2, name: "Available Apps", isChecked: true },
                    { id: 3, name: "Aubscribed Apps", isChecked: false },
                    { id: 4, name: "Unsubscribed Apps", isChecked: false },
                ])
            }
            else if (id == 3) {
                setFiltersubscrption([
                    { id: 1, name: "All Apps", isChecked: false },
                    { id: 2, name: "Available Apps", isChecked: false },
                    { id: 3, name: "Aubscribed Apps", isChecked: true },
                    { id: 4, name: "Unsubscribed Apps", isChecked: false },
                ])
            } else {
                setFiltersubscrption([
                    { id: 1, name: "All Apps", isChecked: false },
                    { id: 2, name: "Available Apps", isChecked: false },
                    { id: 3, name: "Aubscribed Apps", isChecked: false },
                    { id: 4, name: "Unsubscribed Apps", isChecked: true },
                ])
            }
        }
    }

    const [subscriptionData, setSubscriptionData] = useState([]);
    const [subscriptionId, setSubscriptionId] = useState('');
    const [clientId, setClientid] = useState('');
    const [enrollmentId, setEnrollmentId] = useState('');
    const [loader, setLoader] = useState(false);

    // list-Subscription-Api
    const getData = async () => {
        let result = await ItrApiService.GET({
            url: 'DevOps/Enrollment/EnrollmentWithSubscription'
        });
        if (result.result.isSuccess == true) {
            let data = result.result.data;
            if (data) {
                for (let id of data) {
                    setSubscriptionId(id.clientSubscriptionID);
                    setClientid(id.clientID);
                    setEnrollmentId(id.id);
                }
            }
            if (data === null || !data.length) {
                NotificationManager.error(result.result.message);
            }
            else {
                setSubscriptionData(data);
                // await splits(data,setDatas);
                // await setpassCustomers(getCustomerDetails);
            }
            // setCustomers(getCustomerDetails);
        }
        else if (result.result.isSuccess == false) {
            NotificationManager.error(result.result.message);
            // sessionStorage.clear();
            // history.push('/')
        }
    }

    const [noOfUnit, setNoOfUnit] = useState();

    const [handel, setHandel] = useState(false);
    const [handel2, setHandel2] = useState(false);

    var saveSubscribe = {
        clientSubscriptionID: subscriptionId,
        enrollmentID: enrollmentId,
        clientID: clientId,
        noofUnit: parseInt(noOfUnit)
    }

    var unSubscribe = {
        clientSubscriptionID: subscriptionId,
        clientID: clientId
    }
    const [appModaldetails, setAppModalDetails] = useState(false);
    const [showLicenseDetails, setShowLicenseDetails] = useState(false);
    const [moveSubscribeTab, setMoveSubscibeTab] = useState('1');
    const [confirmSubscribe, setConfirmSubscribe] = useState(false);
    const [appPolicydetails, setAppPolicyDetails] = useState(false);
    const [reloads, setReloads] = useState(true);


    const showLicenseTable = () => {
        if (showLicenseDetails == true) {
            setShowLicenseDetails(false);
        }
        else {
            setShowLicenseDetails(true);
        }
    }

    const [appsData, setAppsData] = useState({
        available: '',
        subscribed: '',
        purchased: '',
        unassigned: '',
    })

    const getAppStoreData = () => {
        ItrApiService.GET({
            url: 'RevOps/StoreConnect/GetAppHubDashBoard'
        }).then((res => {
            if (res.Success == true) {
                if (res.result.isSuccess == true) {
                    let obj = { ...appsData }
                    for (let data of res.result.data) {
                        if (data.categoryName == 'Available Apps') {
                            obj = { ...obj, available: data.categoryCount }
                        }
                        else if (data.categoryName == 'Subscribed Apps') {
                            obj = { ...obj, subscribed: data.categoryCount }
                        }
                        else if (data.categoryName == 'Purchased Licenses') {
                            obj = { ...obj, purchased: data.categoryCount }
                        }
                        else if (data.categoryName == 'Unassigned Licenses') {
                            obj = { ...obj, unassigned: data.categoryCount }
                        }
                    }
                    setAppsData(obj);
                }
            }
        }))
    }


    const [activeString, setActiveString] = useState('1')
    useEffect(() => {
        let s = sessionStorage.getItem('ahss');
        if (s) {
            setActiveString(s)
        }
        getAppStoreData();

    }, []);

    // useEffect(async () => {
    //     setLoader(true);
    //     await getData();
    //     setLoader(false);
    // }, [])

    return (
        <>
            <NxLoader state={loader} />
            <Headers />
            <div className='d-flex custom-body-container'>
                <div className='body-sidebar'>
                    <CustomerSidebar visible={true} />
                </div>
                <div className='body-workspace'>
                    <NxTitleTab className="title-tab" title="Enrollments" onCloseClick={() => history.push('/dashboard')} />
                    <div className="billing-tabs billing-main">
                        <Tabs defaultActiveKey="1" type="card" activeKey={activeString} onChange={(e) => {
                            setActiveString(e);
                            if (e == '1') {
                                setReloads(!reloads)
                            }
                            sessionStorage.setItem('ahss', e)
                        }}>
                            <TabPane tab={
                                <div className="d-flex justify-content-center">
                                    {/* <span ><DashboardOutlined /></span> */}
                                    <span style={{ fontSize: '16px', paddingLeft: "12px", paddingRight: "12px" }} >App Version</span>
                                </div>
                            } key="1">
                                <div className="row">
                                    <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mt-1 counts count-two">
                                        <div className="card">
                                            <div className="card-body">
                                                <label className="nx-badge-title">Available Apps</label>
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-6 mt-1">
                                                    <h6> {appsData.available} </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mt-1 counts count-two">
                                        <div className="card">
                                            <div className="card-body">
                                                <label className="nx-badge-title">Subscribed Apps</label>
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-6 mt-1">
                                                    <h6> {appsData.subscribed} </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mt-1 counts count-two">
                                        <div className="card">
                                            <div className="card-body">
                                                <label className="nx-badge-title">Purchased Licenses</label>
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-6 mt-1">
                                                    <h6> {appsData.purchased} </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mt-1 counts count-two">
                                        <div className="card">
                                            <div className="card-body">
                                                <label className="nx-badge-title">Unassigned Licenses</label>
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-6 mt-1">
                                                    <h6> {appsData.unassigned} </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="container1 mt-1 nx-subscription-page mb-5">
                                    <div className="row justify-content-end" >
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3">
                                            <div className="card">
                                                <h5 className="m-4 fw-bold recent-app-title">Service Listing</h5>
                                                <Enrollment reloads={reloads} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <Enquiry /> */}
                            </TabPane>
                            <TabPane tab={
                                <div className="d-flex justify-content-center">
                                    {/* <span ><DashboardOutlined /></span> */}
                                    <span style={{ fontSize: '16px', paddingLeft: "12px", paddingRight: "12px" }} >App Enrollment</span>
                                </div>
                            } key="2">
                                <EnrollmentEntry />
                            </TabPane>
                            <TabPane tab={<div className="d-flex justify-content-center">
                                {/* <span ><ForkOutlined /></span> */}
                                <span style={{ fontSize: '16px', paddingLeft: "12px", paddingRight: "12px" }}>Store Connect</span>
                            </div>} key="3">
                                <StoreConnectList />
                            </TabPane>
                            {/* <TabPane tab={<div className="d-flex justify-content-center">
                                <span ><ForkOutlined /></span>
                                <span style={{ fontSize: '16px', paddingLeft: "12px", paddingRight: "12px" }}>Storefront</span>
                            </div>} key="4">
                                <StoreConnectList />
                                <AppStores />
                            </TabPane> */}
                            {/* <TabPane tab={<div className="d-flex justify-content-center">
                                <span ><ForkOutlined /></span>
                                <span style={{ fontSize: '16px',paddingLeft:"12px",paddingRight:"12px" }}>License Management</span>
                            </div>} key="4">
                                <LicenseManagement />
                            </TabPane> */}
                            {/* <TabPane tab={<div className="d-flex justify-content-center">
                                <span ><DollarOutlined /></span>
                                <span style={{ fontSize: '16px' }}>Customers</span>
                            </div>} key="4">
                                <Customer />
                                
                            </TabPane> */}
                        </Tabs>
                    </div>
                </div>
            </div>
            <NxFooter companyName="© 2023 Altascio Technologies (I) Pvt. Ltd.," ExtraContent={[
                { name: 'Terms of Service' },
                { name: 'Privacy' },
            ]} />
        </>
    )
}
