import React, { useEffect, useState } from 'react';
import { NxLoader, NxTitleTab, } from '@afiplfeed/itr-app';
import { useHistory } from 'react-router-dom';
import { ItrApiService, ItrAuthService } from '@afiplfeed/itr-ui';
import Headers from '../../../Screens/Headers/Headers';

function AddSupport(props) {
    const [load, setLoad] = useState(false);
    const [addSupport, setAddSupport] = useState({
        subject: '',
        apps: '',
        priority: '',
        supportType: '',
        status: '',
        feedback: '',
        Attachments: ''
    });

    const [userApps, setUserApps] = useState([])

    let history = useHistory();
    useEffect(() => {
        ItrApiService.GET({ url: 'DevOps/Enrollment/ListAllApp' }).then(res => {
            if (res.Success == true) {
                if (res.result.isSuccess == true) {
                    if (res.result.data) {
                        setUserApps(res.result.data)
                    }
                }
            }
        })
    }, []);

    return (
        <div >
            <NxLoader state={load} />
            <Headers />
            <div>
                <NxTitleTab title='New Support' onCloseClick={() => {
                    history.goBack();
                }} />
                <div className="px-5">
                    <div className="row" style={{ display: 'flex' }}>
                        <div className="left-form" style={{ width: '50%' }}>
                            <div className="mt-2">
                                <label htmlFor="subject">Subject</label>
                                <input type="text" className="form-control" id="subject"
                                    onChange={(e) => setAddSupport({ ...addSupport, subject: e.target.value })} />
                            </div>

                            <div className="mt-2">
                                <label htmlFor="apps">Apps</label>
                                <select className="form-select" id="prority"
                                    onChange={(e) => setAddSupport({ ...addSupport, apps: e.target.value })}>
                                    <option value="" disabled selected>--please select--</option>
                                    {userApps.map((item, index) => {
                                        return <option key={index} value={item.serviceName}>{item.serviceName}</option>
                                    })}
                                </select>
                            </div>

                            <div className="mt-2">
                                <label htmlFor="prority">Prority</label>
                                <select className="form-select" id="prority"
                                    onChange={(e) => setAddSupport({ ...addSupport, priority: e.target.value })}>
                                    <option value="" disabled selected>--please select--</option>
                                    <option value="Critical">Critical</option>
                                    <option value="High">High</option>
                                    <option value="Low">Low</option>
                                    <option value="Medium">Medium</option>
                                </select>
                            </div>

                            <div className="mt-2">
                                <label htmlFor="support-type">Support Type</label>
                                <select className="form-select" id="support-type"
                                    onChange={(e) => setAddSupport({ ...addSupport, supportType: e.target.value })}>
                                    <option value="" disabled selected>--please select--</option>
                                    <option value="Bug">Bug</option>
                                    <option value="Change Request">Change Request</option>
                                    <option value="Enhancement">Enhancement</option>
                                    <option value="New Requirement">New Requirement</option>
                                    <option value="Service Request">Service Request</option>
                                    <option value="Story">Story</option>
                                    <option value="Task">Task</option>
                                </select>
                            </div>

                            <div className="mt-2">
                                <label htmlFor="status">Status</label>
                                <select className="form-select" id="status"
                                    onChange={(e) => setAddSupport({ ...addSupport, status: e.target.value })}>
                                    <option value="" disabled selected>--please select--</option>
                                    <option value="Open">Open</option>
                                    <option value="InProgress">InProgress</option>
                                    <option value="Close">Close</option>
                                </select>
                            </div>

                            <div className="mt-2">
                                <label htmlFor="feedback">Feedback</label>
                                <textarea rows="3" className="form-control" id="feedback"
                                    onChange={(e) => setAddSupport({ ...addSupport, feedback: e.target.value })} />
                            </div>
                        </div>

                        <div className="right-form" style={{ width: '50%' }}>
                            <div className="mt-2">
                                <label htmlFor="attachments">Attachments</label>
                                <input type="file" className="form-control" id="attachments" />
                            </div>
                        </div>
                    </div>
                    <hr />
                </div>
                <div style={{ display: 'flex' }} className="px-5">
                    <button className="btn btn-primary buttons" onClick={() => {
                        setLoad(true);
                        ItrAuthService.GetUserInfo().then(data => {
                            let send = {
                                "supportID": 0,
                                "raisedDate": "2021-10-20T10:08:05.586Z",
                                "entityUserCode": data.result.entityCode,
                                "isActive": true,
                                "subject": addSupport.subject,
                                "priority": addSupport.priority,
                                "appCode": addSupport.apps,
                                "status": addSupport.status,
                                "supportType": addSupport.supportType,
                                "assignee": "String",
                                "reporterTo": "String",
                                "feedback": addSupport.feedback,
                                "supportAttachments": [],
                                "supportComments": []
                            }
                            // "{  \"isActive\": true,  \"supportID\": 0,  \"raisedDate\": \"2021-10-20T10:08:05.586Z\",  \"entityUserCode\": \"string\",  \"subject\": \"string\",  \"priority\": \"string\",  \"appID\": 0,  \"status\": \"string\",  \"supportType\": \"string\",  \"assignee\": \"string\",  \"reporterTo\": \"string\",  \"dueDate\": \"2021-10-20T10:08:05.586Z\",  \"feedback\": \"string\" }"
                            ItrApiService.POST({ data: send, url: "ITOps/Support/SaveSupport" }).then(resp => {
                                setAddSupport({
                                    subject: '',
                                    apps: '',
                                    priority: '',
                                    supportType: '',
                                    status: '',
                                    feedback: '',
                                    Attachments: ''
                                });
                                setLoad(false);
                                history.goBack();
                            })
                        })
                    }}> Save</button>
                    <button className="btn close btn-outline-light" style={{ marginLeft: '20px' }} > close</button>
                </div>
            </div>
        </div>
    );
}

export default AddSupport;