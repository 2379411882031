import React from 'react';
import './feedback.css';
import { useHistory } from 'react-router-dom';
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NxGridList, NxTitleTab } from '@afiplfeed/itr-app';
import Headers from '../../../Screens/Headers/Headers';

const datas = [
    {
        sendItem: '1',
        data: [
            'demo, user',
            <div>
                <FontAwesomeIcon icon={faStar} color="orange" />
                <FontAwesomeIcon icon={faStar} color="orange" />
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
            </div>,
            'This is for test'
        ]
    }, {
        sendItem: '1',
        data: [
            'demo, user',
            <div>
                <FontAwesomeIcon icon={faStar} color="orange" />
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
                <FontAwesomeIcon icon={faStar} />
            </div>,
            'This is for test'
        ]
    }
]

export default function Feedback() {

    let history = useHistory();

    return (
        <div className='feedback mb-4'>
            <Headers />
            <NxTitleTab className="title-tab" title="Feedback" onCloseClick={() => history.push('/customer-board')} />
            <div className="m-4 mt-0">
                <NxGridList
                    enableSearch={true}
                    autoSearch={true}
                    enableAutoPagination={true}
                    showShowing={true}
                    className='grid'
                    header={[
                        {
                            name: 'Name',
                            sort: true,
                            search: true
                        },
                        {
                            name: 'FeedBack',
                            sort: true,
                        },
                        {
                            name: 'Discription',
                            // sort: true,
                        },
                    ]} data={datas} />
            </div>
        </div>
    )
}
