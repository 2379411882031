import React, { useEffect, useState } from 'react';
import './ManageCluster.css';
import { useHistory } from 'react-router';
import { NxTitleTab, NxFooter } from '@afiplfeed/itr-app';
import { DatePicker, Tabs } from 'antd';
import { DashboardOutlined, FormOutlined } from '@ant-design/icons';
import Headers from '../../../../../Screens/Headers/Headers';
import Cluster from '../Cluster/Cluster';

const { RangePicker } = DatePicker;



export default function ManageCluster() {
    const { TabPane } = Tabs;
    let history = useHistory();

    const [activeString, setActiveString] = useState('1')
    useEffect(() => {
        let s = sessionStorage.getItem('rc');
        if (s) {
            setActiveString(s)
        }
    }, []);
    return (
        <>
            <Headers />
            <NxTitleTab className="title-tab" title="Cluster" onCloseClick={() => {
                sessionStorage.removeItem('rc');
                history.goBack()
            }} />
            <div className="billing-tabs billing-main">
                <Tabs defaultActiveKey="1" type="card" activeKey={activeString} onChange={(e) => {
                    setActiveString(e);
                    sessionStorage.setItem('rc', e)
                }}>
                    <TabPane tab={<div className="d-flex justify-content-center px-2">
                        {/* <span ><FormOutlined /></span> */}
                        <span style={{ fontSize: '16px', paddingLeft: "12px", paddingRight: "12px" }}>AKS Cluster</span>
                    </div>} key="1">
                        <Cluster />
                    </TabPane>
                </Tabs>
            </div>
            <NxFooter companyName="© 2022 Ithred Solutions" ExtraContent={[
                { name: 'Terms of Service' },
                { name: 'Privacy' },
            ]} />
        </>
    )
}
