export const ChartColors = {
    Sunshine: '#f0c518',
    Squash: '#ed741c',
    Confetti: '#f04356',
    Splash: '#52c5d9',
    Sky: '#277cc2',
    Moonshine: '#272f6b'
}


export const Countries = [
    { value: '', countryName: "Afghanistan" },
    { value: '', countryName: "Albania" },
    { value: '', countryName: "Algeria" },
    { value: '', countryName: "Andorra" },
    { value: '', countryName: "Angola" },
    { value: '', countryName: "Antigua and Barbuda" },
    { value: '', countryName: "Argentina" },
    { value: '', countryName: "Armenia" },
    { value: '', countryName: "Australia" },
    { value: '', countryName: "Austria" },
    { value: '', countryName: "Azerbaijan" },
    { value: '', countryName: "Bahamas" },
    { value: '', countryName: "Bahrain" },
    { value: '', countryName: "Bangladesh" },
    { value: '', countryName: "Barbados" },
    { value: '', countryName: "Belarus" },
    { value: '', countryName: "Belgium" },
    { value: '', countryName: "Belize" },
    { value: '', countryName: "Benin" },
    { value: '', countryName: "Bhutan" },
    { value: '', countryName: "Bolivia" },
    { value: '', countryName: "Bosnia and Herzegovina" },
    { value: '', countryName: "Botswana" },
    { value: '', countryName: "Brazil" },
    { value: '', countryName: "Brunei" },
    { value: '', countryName: "Bulgaria" },
    { value: '', countryName: "Burkina Faso" },
    { value: '', countryName: "Burundi" },
    { value: '', countryName: "Côte d'Ivoire" },
    { value: '', countryName: "Cabo Verde" },
    { value: '', countryName: "Cambodia" },
    { value: '', countryName: "Cameroon" },
    { value: '', countryName: "Canada" },
    { value: '', countryName: "Central African Republic" },
    { value: '', countryName: "Chad" },
    { value: '', countryName: "Chile" },
    { value: '', countryName: "China" },
    { value: '', countryName: "Colombia" },
    { value: '', countryName: "Comoros" },
    { value: '', countryName: "Congo (Congo-Brazzaville)" },
    { value: '', countryName: "Costa Rica" },
    { value: '', countryName: "Croatia" },
    { value: '', countryName: "Cuba" },
    { value: '', countryName: "Cyprus" },
    { value: '', countryName: "Czechia (Czech Republic)" },
    { value: '', countryName: "Democratic Republic of the Congo" },
    { value: '', countryName: "Denmark" },
    { value: '', countryName: "Djibouti" },
    { value: '', countryName: "Dominica" },
    { value: '', countryName: "Dominican Republic" },
    { value: '', countryName: "Ecuador" },
    { value: '', countryName: "Egypt" },
    { value: '', countryName: "El Salvador" },
    { value: '', countryName: "Equatorial Guinea" },
    { value: '', countryName: "Eritrea" },
    { value: '', countryName: "Estonia" },
    { value: '', countryName: "Eswatini (fmr. Swaziland)" },
    { value: '', countryName: "Ethiopia" },
    { value: '', countryName: "Fiji" },
    { value: '', countryName: "Finland" },
    { value: '', countryName: "France" },
    { value: '', countryName: "Gabon" },
    { value: '', countryName: "Gambia" },
    { value: '', countryName: "Georgia" },
    { value: '', countryName: "Germany" },
    { value: '', countryName: "Ghana" },
    { value: '', countryName: "Greece" },
    { value: '', countryName: "Grenada" },
    { value: '', countryName: "Guatemala" },
    { value: '', countryName: "Guinea" },
    { value: '', countryName: "Guinea-Bissau" },
    { value: '', countryName: "Guyana" },
    { value: '', countryName: "Haiti" },
    { value: '', countryName: "Holy See" },
    { value: '', countryName: "Honduras" },
    { value: '', countryName: "Hungary" },
    { value: '', countryName: "Iceland" },
    { value: '', countryName: "India" },
    { value: '', countryName: "Indonesia" },
    { value: '', countryName: "Iran" },
    { value: '', countryName: "Iraq" },
    { value: '', countryName: "Ireland" },
    { value: '', countryName: "Israel" },
    { value: '', countryName: "Italy" },
    { value: '', countryName: "Jamaica" },
    { value: '', countryName: "Japan" },
    { value: '', countryName: "Jordan" },
    { value: '', countryName: "Kazakhstan" },
    { value: '', countryName: "Kenya" },
    { value: '', countryName: "Kiribati" },
    { value: '', countryName: "Kuwait" },
    { value: '', countryName: "Kyrgyzstan" },
    { value: '', countryName: "Laos" },
    { value: '', countryName: "Latvia" },
    { value: '', countryName: "Lebanon" },
    { value: '', countryName: "Lesotho" },
    { value: '', countryName: "Liberia" },
    { value: '', countryName: "Libya" },
    { value: '', countryName: "Liechtenstein" },
    { value: '', countryName: "Lithuania" },
    { value: '', countryName: "Luxembourg" },
    { value: '', countryName: "Madagascar" },
    { value: '', countryName: "Malawi" },
    { value: '', countryName: "Malaysia" },
    { value: '', countryName: "Maldives" },
    { value: '', countryName: "Mali" },
    { value: '', countryName: "Malta" },
    { value: '', countryName: "Marshall Islands" },
    { value: '', countryName: "Mauritania" },
    { value: '', countryName: "Mauritius" },
    { value: '', countryName: "Mexico" },
    { value: '', countryName: "Micronesia" },
    { value: '', countryName: "Moldova" },
    { value: '', countryName: "Monaco" },
    { value: '', countryName: "Mongolia" },
    { value: '', countryName: "Montenegro" },
    { value: '', countryName: "Morocco" },
    { value: '', countryName: "Mozambique" },
    { value: '', countryName: "Myanmar (formerly Burma)" },
    { value: '', countryName: "Namibia" },
    { value: '', countryName: "Nauru" },
    { value: '', countryName: "Nepal" },
    { value: '', countryName: "Netherlands" },
    { value: '', countryName: "New Zealand" },
    { value: '', countryName: "Nicaragua" },
    { value: '', countryName: "Niger" },
    { value: '', countryName: "Nigeria" },
    { value: '', countryName: "North Korea" },
    { value: '', countryName: "North Macedonia" },
    { value: '', countryName: "Norway" },
    { value: '', countryName: "Oman" },
    { value: '', countryName: "Pakistan" },
    { value: '', countryName: "Palau" },
    { value: '', countryName: "Palestine State" },
    { value: '', countryName: "Panama" },
    { value: '', countryName: "Papua New Guinea" },
    { value: '', countryName: "Paraguay" },
    { value: '', countryName: "Peru" },
    { value: '', countryName: "Philippines" },
    { value: '', countryName: "Poland" },
    { value: '', countryName: "Portugal" },
    { value: '', countryName: "Qatar" },
    { value: '', countryName: "Romania" },
    { value: '', countryName: "Russia" },
    { value: '', countryName: "Rwanda" },
    { value: '', countryName: "Saint Kitts and Nevis" },
    { value: '', countryName: "Saint Lucia" },
    { value: '', countryName: "Saint Vincent and the Grenadines" },
    { value: '', countryName: "Samoa" },
    { value: '', countryName: "San Marino" },
    { value: '', countryName: "Sao Tome and Principe" },
    { value: '', countryName: "Saudi Arabia" },
    { value: '', countryName: "Senegal" },
    { value: '', countryName: "Serbia" },
    { value: '', countryName: "Seychelles" },
    { value: '', countryName: "Sierra Leone" },
    { value: '', countryName: "Singapore" },
    { value: '', countryName: "Slovakia" },
    { value: '', countryName: "Slovenia" },
    { value: '', countryName: "Solomon Islancitiesds" },
    { value: '', countryName: "Somalia" },
    { value: '', countryName: "South Africa" },
    { value: '', countryName: "South Korea" },
    { value: '', countryName: "South Sudan" },
    { value: '', countryName: "Spain" },
    { value: '', countryName: "Sri Lanka" },
    { value: '', countryName: "Sudan" },
    { value: '', countryName: "Suriname" },
    { value: '', countryName: "Sweden" },
    { value: '', countryName: "Switzerland" },
    { value: '', countryName: "Syria" },
    { value: '', countryName: "Tajikistan" },
    { value: '', countryName: "Tanzania" },
    { value: '', countryName: "Thailand" },
    { value: '', countryName: "Timor-Leste" },
    { value: '', countryName: "Togo" },
    { value: '', countryName: "Tonga" },
    { value: '', countryName: "Trinidad and Tobago" },
    { value: '', countryName: "Tunisia" },
    { value: '', countryName: "Turkey" },
    { value: '', countryName: "Turkmenistan" },
    { value: '', countryName: "Tuvalu" },
    { value: '', countryName: "Uganda" },
    { value: '', countryName: "Ukraine" },
    { value: '', countryName: "United Arab Emirates" },
    { value: '', countryName: "United Kingdom" },
    { value: '', countryName: "United States of America" },
    { value: '', countryName: "Uruguay" },
    { value: '', countryName: "Uzbekistan" },
    { value: '', countryName: "Vanuatu" },
    { value: '', countryName: "Venezuela" },
    { value: '', countryName: "Vietnam" },
    { value: '', countryName: "Yemen" },
    { value: '', countryName: "Zambia" },
    { value: '', countryName: "Zimbabwe" },
]
