import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import './style.css';
import { NxLoader, NxRightDrawer, NxActionTab, NxGridList, NxTitleTab, NxBreadcrumb, NxHeader, NxFooter } from '@afiplfeed/itr-app';
import { ItrApiService } from '@afiplfeed/itr-ui';
import Headers from "../../Screens/Headers/Headers";
import Footers from "../../Screens/Footers/Footers";
import moment from "moment";
import { NotificationManager } from "react-notifications";

export default function StoreConnectList() {
    const [loader, setLoader] = useState(false);
    const [enrollment, setEnrollment] = useState([]);
    useEffect(() => {

    }, [])

    const splits = async (dests, setEnquiry) => {
        let arr = [];
        for (let value of dests) {
            let data = [value.ServiceName, value.VersionNo, value.DeveloperName, moment(value.EnrollmentDate).format('MM/DD/YYYY'), <div>
                {value.StoreStatus == "Available" ? <button className='btn btn-sm' style={{ fontSize: '11px', borderRadius: '0px', color: 'white', backgroundColor: '#99ccff', }}>
                    {value.StoreStatus}
                </button> : value.StoreStatus == 'Published' ? <button className='btn btn-sm' style={{ fontSize: '11px', borderRadius: '0px', color: 'white', backgroundColor: '#00e74d' }} >
                    {/* Published */}
                    {value.StoreStatus}
                </button> : <button className='btn btn-sm' style={{ fontSize: '11px', borderRadius: '0px', color: 'white', backgroundColor: 'red' }} >
                    {/* Retired */}
                    {value.StoreStatus}
                </button>}
            </div>, <div>
                <button className="btn btn-primary btn-sm p-1" onClick={() => {
                    // history.push({
                    //     pathname: '/apps-store/store-connect/add',
                    //     state: { editVal: value }
                    // });
                    history.push({
                        pathname: '/apps-store/service-details',
                        state: { editVal: value }
                    });
                }}>
                    Store Connect
                </button>
            </div>];
            let obj = { sendItem: value, data: data };
            arr = [...arr, obj]
        }
        setEnquiry(arr)
    }

    const getEnrollment = async () => {
        setLoader(true);
        let result = await ItrApiService.GET({
            url: 'RevOps/StoreConnect/AllStoreConnect'
        });


        if (result.Success == true) {
            if (result.result.isSuccess == true) {
                let getCustomerDetails = result.result.data;
                if (result.result.data) {
                    await splits(getCustomerDetails, setEnrollment)
                }
            }
            // setEnquiry(getCustomerDetails);
        }
        else if (result.Success == false) {
            // NotificationManager.error(result.message);
            // sessionStorage.clear();
            // history.push('/')
        }
        setLoader(false);
    }

    useEffect(() => {
        getEnrollment();
        // await splits(dest, setEnquiry);
    }, [])

    let history = useHistory();
    const [load, setLoad] = useState(false);
    const [handel, setHandel] = useState(false);
    const [handel2, setHandel2] = useState(false);
    return (
        <div>
            <NxLoader state={load} />
            {/* <Headers /> */}
            <NxLoader state={loader} />
            {/* <NxBreadcrumb className="navigation-breadcrumb" data={[
                {
                    name: 'Home',
                    onClick: () => {
                        history.push('/customer-board')
                    }
                }
            ]} /> */}
            {/* <NxTitleTab className="title-tab page-title" title='Store Connect' onCloseClick={() => {
                history.push('/apps-store');
            }} /> */}
            <div className="mx-0 mt-0">
                {/* <NxActionTab
                    className="p-0 pt-0 mt-0 enquiry-action-tab"
                    onColumnsClick={() => { setHandel(true) }}
                    onRefreshClick={() => {
                        setLoad(true)
                        setTimeout(function () {
                            setLoad(false)
                        }, 2000);
                    }}
                    onNewEntryClick={() => { history.push('/distribution/appsenrollment/add') }}
                    enableNewEntry={false}
                /> */}
                <div className="pt-0 mb-5">
                    <NxGridList
                        enableSearch={true}
                        autoSearch={true}
                        enableAutoPagination={true}
                        showShowing={true}
                        header={[
                            {
                                name: 'Service Name',
                                sort: true,
                                search: true
                            },
                            {
                                name: 'Version No',
                                sort: false,
                            },
                            {
                                name: 'Developer',
                                search: true
                                // sort: true,
                            },
                            {
                                name: 'Enrolment Date',
                                sort: true

                            },
                            {
                                name: 'Service Status',
                            },
                            {
                                name: 'Action'
                            }
                        ]}
                        rowClick={(data) => {

                        }}
                        data={enrollment && enrollment} />
                </div>

                <NxRightDrawer onClose={() => setHandel(false)} show={handel} drawerContent={() => { }} ondrawer1Close={() => { setHandel2(false) }} />
            </div>
            <NxFooter ExtraContent={[
                { name: 'Terms of Service' },
                { name: 'privacy' },
            ]} />
        </div>
    )
}