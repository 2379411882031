import React, { useState, useEffect } from "react";
import './AddPartnerUser.css';
import { useHistory } from 'react-router-dom';
import { ItrApiService } from '@afiplfeed/itr-ui';
import { NxHeader, NxBreadcrumb, NxTitleTab, NxCustomTab, NxLoader, NxFooter, NxGridList } from '@afiplfeed/itr-app';
import Headers from "../../Screens/Headers/Headers";
import Footers from "../../Screens/Footers/Footers";
import { NotificationManager } from "react-notifications";

export default function AddPartnerUser() {

    const Buttons = () => {
        return <div className="form-button nx-enquiry-buttons partner-user-form-row mb-5">
            <hr />
            <button className="btn btn-primary rounded-pill buttons mb-5"> Save</button>
            <button className="btn btn-primary rounded-pill buttons mb-5"> Onboard</button>
            <button className="btn close btn-outline-primary rounded-pill mb-5" onClick={() => history.goBack()}> close</button>
        </div>
    }

    const [loading, setLoading] = useState(false);

    // cities
    const [allCities, setAllCities] = useState([]);
    const getCities = async () => {
        let result = await ItrApiService.GET({
            url: 'Platform/City/GetAllCities'
        });
        if (result.result.isSuccess == true) {
            let getCustomerDetails = result.result.data;
            if (result.result.data) {
                setAllCities(getCustomerDetails);
            }
        }
        else if (result.result.isSuccess == false) {
            NotificationManager.error(result.result.message);
            // sessionStorage.clear();
            // history.push('/')
        }
    }


    // states
    const [allStates, setAllStates] = useState([]);
    const getStates = async () => {
        let result = await ItrApiService.GET({
            url: 'Platform/State/GetAllStates'
        });
        if (result.result.isSuccess == true) {
            let getCustomerDetails = result.result.data;
            if (result.result.data) {
                setAllStates(getCustomerDetails);
            }
        }
        else if (result.result.isSuccess == false) {
            NotificationManager.error(result.result.message);
            // sessionStorage.clear();
            // history.push('/')
        }
    }


    // country
    const [allCountries, setAllCountries] = useState([]);
    const getCountry = async () => {
        let result = await ItrApiService.GET({
            url: 'Platform/Country/GetAllCountries'
        });
        if (result.result.isSuccess == true) {
            let getCustomerDetails = result.result.data;
            if (result.result.data) {
                setAllCountries(getCustomerDetails);
            }
        }
        else if (result.result.isSuccess == false) {
            NotificationManager.error(result.result.message);
            // sessionStorage.clear();
            // history.push('/')
        }
    }


    // pincode
    const [allPinCode, setAllPinCode] = useState([]);
    const getZipCodes = async () => {
        let result = await ItrApiService.GET({
            url: 'Platform/ZipCode/GetAllZipCodes'
        });
        if (result.result.isSuccess == true) {
            let getCustomerDetails = result.result.data;
            if (result.result.data) {
                setAllPinCode(getCustomerDetails);
            }
        }
        else if (result.result.isSuccess == false) {
            NotificationManager.error(result.result.message);
            // sessionStorage.clear();
            // history.push('/')
        }
    }


    // AllUDo`s
    let UdoData = ["Enquiry Status", "Nature Of Business", "Industry", "Ownership", "Source"];
    const [allUdo, setAllUdo] = useState([]);
    const getUdo = async () => {
        let result = await ItrApiService.POST({
            url: 'Platform/UserDefined/AllUDOs',
            data: UdoData
        });
        if (result.result.isSuccess == true) {
            let getCustomerDetails = result.result.data;
            let getOptions = getCustomerDetails[1].Options;
            setAllUdo(getOptions);
        }
        else if (result.result.isSuccess == false) {
            NotificationManager.error(result.result.message);
            // sessionStorage.clear();
            // history.push('/')
        }
    }

    const initiate = async () => {
        setLoading(true);
        await getUdo();
        await getCities();
        await getCountry();
        await getZipCodes();
        await getStates();
        setLoading(false);
    }


    useEffect( () => {
        initiate()
    }, []);


    let history = useHistory();
    const [handel, setHandel] = useState(false);
    return (
        <div>
            <NxLoader state={loading} />
            <Headers />
            <NxBreadcrumb className="navigation-breadcrumb" data={[
                {
                    name: 'Home',
                    onClick: () => {
                        history.push('/partner-board');
                    }
                },
                {
                    name: 'Partner Users',
                    onClick: () => {
                        history.push('/partner-user');
                    }
                }
            ]} />
            <NxTitleTab className="title-tab" title='Add Partner User' onCloseClick={() => {
                history.push('/partner-user');
            }} />


            <div className="container-form partner-user-form">
                <div className="row partner-user-form-row p-3 pt-0">
                    <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                        <label htmlFor="Account-Name">First Name</label>
                        <input className="form-control" id="Account-Name" type="text" />
                    </div>

                    <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                        <label htmlFor="firstName">Address Line 1</label>
                        <input className="form-control" id="Account-Name" type="text" />
                        {/* <select class="form-select" aria-label="Default select example" placeholder="">
                                    <option value="" selected hidden></option>
                                    <option value="" >--Please-select--</option>
                                </select> */}
                    </div>

                    <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                        <label htmlFor="Address-Line-1">Address Line 2</label>
                        <input className="form-control" id="Address-Line-1" type="text" />
                    </div>

                    <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                        <label htmlFor="Account-Name">Last Name</label>
                        <input className="form-control" id="Account-Name" type="text" />
                    </div>

                    <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                        <div className="row">
                            <div className="col-6 col-sm-6 col-lg-6 col-xl-6 col-md-6">
                                <label htmlFor="firstName">City</label>
                                <select class="form-select" aria-label="Default select example" placeholder="">
                                    <option value="" selected hidden></option>
                                    <option value="" >--Please-select--</option>
                                    {allCities && allCities.map((data, index) => {
                                        return <option key={index} value={data.cityName}> {data.cityName} </option>
                                    })}
                                </select>
                            </div>

                            <div className="col-6 col-sm-6 col-lg-6 col-xl-6 col-md-6">
                                <label htmlFor="firstName">State</label>
                                <select class="form-select" aria-label="Default select example" placeholder="">
                                    <option value="" selected hidden></option>
                                    <option value="" >--Please-select--</option>
                                    {allStates && allStates.map((data, index) => {
                                        return <option key={index} value={data.stateName}> {data.stateName} </option>
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-2">
                        <div className="row">
                            <div className="col-6 col-sm-6 col-lg-6 col-xl-6 col-md-6">
                                <label htmlFor="firstName">Country</label>
                                <select class="form-select" aria-label="Default select example">
                                    <option value="" selected hidden></option>
                                    <option value="" >--Please-select--</option>
                                    {allCountries && allCountries.map((data, index) => {
                                        return <option key={index} value={data.countryName}> {data.countryName} </option>
                                    })}
                                </select>
                            </div>

                            <div className="col-6 col-sm-6 col-lg-6 col-xl-6 col-md-6">
                                <label htmlFor="firstName">Pin Code</label>
                                <select class="form-select" aria-label="Default select example">
                                    <option value="" selected hidden></option>
                                    <option value="" >--Please-select--</option>
                                    {allPinCode && allPinCode.map((data, index) => {
                                        return <option key={index} value={data.zipcode}> {data.zipcode} </option>
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-2">
                        <label htmlFor="firstName">Display Name</label>
                        <select class="form-select" aria-label="Default select example" placeholder="">
                            <option value="" selected hidden></option>
                            <option value="" >--Please-select--</option>
                        </select>
                    </div>

                    <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-2">

                    </div>

                    <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-2 text-end">
                        <button className="btn btn-primary btn-sm">Add</button>
                        <button className="btn btn-light btn-sm">Cancel</button>
                    </div>

                    <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-2">
                        <label htmlFor="firstName">Gender</label>
                        <select class="form-select" aria-label="Default select example" placeholder="">
                            <option value="" selected hidden></option>
                            <option disabled>--Please-select--</option>
                            <option value="Male" >Male</option>
                            <option value="Female" >Female</option>
                        </select>
                    </div>

                    <div className="col-12 col-sm-6 col-md-6 col-lg-8 mt-2">
                        <NxGridList
                            enableSearch={false}
                            autoSearch={false}
                            enableAutoPagination={false}
                            showShowing={false}
                            header={[
                                {
                                    name: 'Address Line 1',
                                },
                                {
                                    name: 'City',
                                    sort: true,
                                },
                                {
                                    name: 'State',
                                    sort: true,
                                },
                                {
                                    name: 'Zip Code',
                                    // sort: true,
                                },
                            ]}
                            rowClick={(data) => {
                                // history.push({
                                //     pathname:'/customer/add',
                                //     state:{
                                //         passData:data
                                //     }
                                // });
                            }}
                        // data={customers}
                        />
                    </div>

                    <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-2">
                        <label htmlFor="firstName">Phone No</label>
                        <input className="form-control" id="firstName" type="text" />
                    </div>

                    <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-2">

                    </div>

                    <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-2">

                    </div>

                    <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-2">
                        <label htmlFor="firstName">Email Address</label>
                        <input className="form-control" id="firstName" type="text" />
                    </div>

                    <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-2">

                    </div>

                    <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-2">

                    </div>
                </div>

                <Buttons />
            </div>
            <NxFooter ExtraContent={[
                { name: 'Terms of Service' },
                { name: 'privacy' },
            ]} />
        </div>
    )
}