import React, { useState, useEffect } from "react";
import './carouselTab.css';
import { useHistory } from 'react-router-dom';
import { NxLoader, NxRightDrawer, NxActionTab, NxGridList, NxTitleTab, NxBreadcrumb, NxHeader, NxFooter } from '@afiplfeed/itr-app';
import { ItrApiService } from '@afiplfeed/itr-ui';
import Headers from "../../../Screens/Headers/Headers";
import { NotificationManager } from "react-notifications";

export default function CarouselTab() {

    const dest = [
        {
            "enquiryid": 1,
            "partnerid": 1,
            "enquirycode": "eq0001",
            "firstname": "mike",
            "lastname": "charles",
            "officialemail": "mikecharles@gmail.com",
            "phone": "98746652321",
            "organization": "MC solutions ",
            "role": "consultant",
            "timetobuy": "immediately",
            "hearedaboutus": "google",
            "status": 13,
        },
        {
            "enquiryid": 2,
            "partnerid": 1,
            "enquirycode": "eq0001",
            "firstname": "mike",
            "lastname": "charles",
            "officialemail": "mikecharles@gmail.com",
            "phone": "98746652321",
            "organization": "MC solutions ",
            "role": "consultant",
            "timetobuy": "immediately",
            "hearedaboutus": "google",
            "status": 13,
        },
        {
            "enquiryid": 3,
            "partnerid": 1,
            "enquirycode": "eq0001",
            "firstname": "mike",
            "lastname": "charles",
            "officialemail": "mikecharles@gmail.com",
            "phone": "98746652321",
            "organization": "MC solutions ",
            "role": "consultant",
            "timetobuy": "immediately",
            "hearedaboutus": "google",
            "status": 13,
        }
    ]

    const [enrollment, setEnrollment] = useState([]);

    const splits = async (dests, setEnquiry) => {
        let arr = [];
        for (let value of dests) {
            let data = [value.serviceCode, value.serviceName, value.publisher, value.versionNo];
            let obj = { sendItem: value.enquiryid, data: data };
            arr = [...arr, obj]
        }
        setEnquiry(arr)
    }

    const getEnrollment = async () => {
        setLoad(true);
        let result = await ItrApiService.GET({
            url: 'DevOps/Enrollment/ListAllEnrollment'
        });


        if (result.Success == true) {
            let getCustomerDetails = result.result.data;
            if (result.result.data) {
                setLoad(false);
                await splits(getCustomerDetails, setEnrollment)
            }
            // setEnquiry(getCustomerDetails);
        }
        else if (result.Success == false) {
            setLoad(false);
            // NotificationManager.error(result.message);
            // sessionStorage.clear();
            // history.push('/')
        }
        setLoad(false);
    }

    useEffect(() => {
        getEnrollment(dest, setEnrollment);
        // await splits(dest, setEnquiry);
    }, [])

    let history = useHistory();
    const [load, setLoad] = useState(false);
    const [handel, setHandel] = useState(false);
    const [handel2, setHandel2] = useState(false);
    return (
        <div className="mb-5">
            <NxLoader state={load} />
            <Headers />
            <div style={{ flex: 1 }}>
                <NxTitleTab className="title-tab" title="Carousel" onCloseClick={() => {
                    history.push('/partner-board');
                }} />
                {/* <NxActionTab
                    className="enrollment-action-tab"
                    onColumnsClick={() => { setHandel(true) }}
                    onRefreshClick={() => {
                        setLoad(true)
                        setTimeout(function () {
                            setLoad(false)
                        }, 2000);
                    }}
                    onNewEntryClick={() => { history.push('enrollment/add') }}
                    enableNewEntry={true}
                /> */}
                <div className="p-4 pt-0">
                    <NxGridList
                        enableSearch={true}
                        autoSearch={true}
                        enableAutoPagination={true}
                        showShowing={true}
                        header={[
                            {
                                name: 'Service Code',
                                sort: true,
                            },
                            {
                                name: 'Service Name',
                                sort: true,
                                search: true
                            },
                            {
                                name: 'Publisher',
                                // sort: true,
                            },
                            {
                                name: 'Version No',
                                sort: false,
                            },
                        ]}
                        rowClick={(e) => {
                            history.push('/enrollment/edit');
                        }}
                        data={enrollment && enrollment} />
                </div>
                <NxFooter ExtraContent={[
                    { name: 'Terms of Service' },
                    { name: 'privacy' },
                ]} />
            </div>
            <NxRightDrawer onClose={() => setHandel(false)} show={handel} drawerContent={() => { }} ondrawer1Close={() => { setHandel2(false) }} />
        </div>
    )
}
