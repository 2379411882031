import React, { useEffect, useState } from "react";
// import './DatabaseServer.css';
import { useHistory } from 'react-router-dom';
import { NxLoader, NxTitleTab, NxActionTab, NxGridList, NxFooter } from '@afiplfeed/itr-app';
import { ItrApiService } from '@afiplfeed/itr-ui';
// import Headers from "../../Screens/Headers/Headers";


const splits = async (dests, setData, setExport) => {
    let arr = [];
    let arr2 = [];
    for (let value of dests) {
        let data = [value.databaseServerName, value.userName, value.trusted_Connection, value.isActive == true ? 'Active' : 'inActive'];
        let obj = { sendItem: value, data: data };
        let obj2 = {
            'Subscription Name': value.name,
            'Client ID': value.clientID,
            'Subscription ID': value.azureID,
            'Status': value.isActive == true ? 'Active' : 'inActive'
        }
        arr2 = [...arr2, data]
        arr = [...arr, obj]
    }
    setExport(arr2);
    setData(arr)
}

const splits1 = async (dests, setData, setExport,history) => {
    let arr = [];
    let arr2 = []
    for (let value of dests) {
        let data = [value.databaseServerName, value.userName, value.databaseServerDescription, value.isActive == true ? 'Active' : 'inActive',
        <button className="btn btn-sm btn-primary" style={{ fontSize: '14px', borderRadius: '0' }}
            onClick={(e) => {
                sessionStorage.setItem('editSupportId', value.databaseServerID);
                e.stopPropagation();
                history.push({
                    pathname : '/databaseserver/edit',
                    state: value
                });
            }}>Edit</button>];
        let obj = { sendItem: dests, data: data };
        // let obj2 = {
        //     'Subscription Name': dests.name,
        //     'Client ID': dests.clientID,
        //     'Subscription ID': dests.azureID,
        //     'Status': dests.isActive == true ? 'Active' : 'inActive'
        // }
        // arr2 = [...arr2, obj2];
        arr = [...arr, obj]
    }
    setExport(arr2);
    setData(arr)
}


export default function VirtualMachine() {
    let history = useHistory();
    const [load, setLoad] = useState(false);
    const [exportVal, setExport] = useState([]);
    const [handel, setHandel] = useState(false);
    useEffect(() => {
        setLoad(true)
        ItrApiService.GET({
            url: 'CloudConnect/Configuration/DatabaseServer/GetAllConfidentialDatabaseServer'
        }).then(res => {
            if (res.Success == true) {
                if (res.result.isSuccess == true) {
                    if (res.result.data) {
                        setVale(res.result.data);
                        splits1(res.result.data, setData, setExport,history)
                    }
                }
            }
            setLoad(false)
        })
    }, []);



    const [vals, setVale] = useState([])
    const [data, setData] = useState([]);
    return (
        <div>
            <NxLoader state={load} />
            {/* <Headers /> */}
            {/* <VxBreadcrumb className="navigation-breadcrumb" data={[
                {
                    name: 'Home',
                    onClick: () => {
                        history.push('/partner-board');
                    }
                }
            ]} /> */}
            <div className="customer-dynamic-pages pt-0">
                {/* <NxTitleTab className="title-tab page-title" title='Azure Connect' onCloseClick={() => {
                    history.goBack();
                }} /> */}

                <div className="onboarding-main mt-1">
                    <div className="d-flex justify-content-between align-items-center">
                        <NxActionTab className="px-0"
                            exportValue={exportVal}
                            fileName='Azure Cloud'
                            // onColumnsClick={() => { setHandel(true) }}
                            onRefreshClick={() => {
                                setLoad(true)
                                setTimeout(function () {
                                    setLoad(false)
                                }, 2000);
                            }}
                            onNewEntryClick={() => {
                                history.push('/databaseserver/add')
                            }}
                            enableNewEntry={false}
                        />
                        <button className='btn btn-sm' style={{ backgroundColor: 'rgb(82, 196, 26)', color: 'white', fontSize: '14px' }} onClick={() => history.push('/virtual-machine/add')}>Add </button>
                    </div>
                    <div className="mb-5">
                        <NxGridList
                            enableSearch={true}
                            autoSearch={true}
                            enableAutoPagination={true}
                            showShowing={true}
                            header={[
                                {
                                    name: 'VM Name',
                                    sort: false,
                                    search: true
                                },
                                {
                                    name: 'DNS Name',
                                    sort: false,
                                    search: true
                                },
                                {
                                    name: 'PrivatePublic IP',
                                    sort: false,
                                },
                                {
                                    name: 'Operating System',
                                    sort: false,
                                },

                            ]}
                            // rowClick={(data) => {
                            //     history.push({
                            //         pathname: '/databaseserver/edit',
                            //         state: data
                            //     })
                            // }}
                            data={[]}
                        />
                    </div>
                </div>
            </div>

            {/* <NxFooter className="verscio-footer" companyName="© 2022 Ithred Solutions" ExtraContent={[
                { name: 'Terms of Service' },
                { name: 'Privacy' },
            ]} /> */}
        </div>
    )
}