import React, { useEffect, useState } from 'react';
import './Performance.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationCircle, faExclamationTriangle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import NxDoughnutCharts from '../../../components/nxCharts/nxDoughnutCharts';
import NxLineCharts from '../../../components/nxCharts/nxLineCharts';
import { NxLoader, NxTitleTab, NxFooter, NxGridList } from '@afiplfeed/itr-app';
import { ItrApiService } from '@afiplfeed/itr-ui'
import Headers from '../../../Screens/Headers/Headers';
import { useHistory } from 'react-router';
import NxLineChartCom from '../../../components/nxCharts/dashboardCharts/nxLineChartCom';
import NxSpeedometer from '../../../components/nxCharts/dashboardCharts/nxSpeedometer';
import moment from 'moment';
import NxCustomLineChart from '../../../components/nxCharts/CustomCharts/NxCustomLineChart';
import CustomerSidebar from '../../../Screens/CustomerSidebar/CustomerSidebar';


export default function Performance() {
    let history = useHistory();
    const [loader, setLoader] = useState(false);
    const [memRes, setMemres] = useState([]);
    const [memUtilization, setMemUtilization] = useState([]);
    const [cpuChartData, setCpuChartData] = useState([]);
    const [cpuChartLabel, setCpuChartLabe] = useState([]);
    const [cpuChartDataSet, setCpuChartDataSet] = useState([]);
    const [memChartDataSet, setMemChartDataSet] = useState([]);
    const [serviceUtilization, setServiceUtilization] = useState([]);
    const [dbInstanceMetric, setDBInstanceMetric] = useState([]);
    const [dbQryExecutionMetric, setDBExecutionMetric] = useState([]);
    const [dbChartData, setDBChartData] = useState([]);
    const [dbChartLabel, setDBChartLabel] = useState([]);
    const [customers, setCustomers] = useState([]);

    function getRandomColor() {
        var letters = '0123456789ABCDEF'.split('');
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.round(Math.random() * 15)];
        }
        return color;
    }
    function dateUTCtoLocal(dt, format){
        const loadUTC = moment(dt).utc().format();
        return moment(dt).utc(loadUTC).local().format(format);
    }

    const getRecentSystemMetics = async () => {
        await ItrApiService.GET({ url: 'ITOps/PerfAgent/GetRecentSystemMetrics' }).then((resMe) => {
            if (resMe.Success == true) {
                if (resMe.result.isSuccess == true) {
                    if (resMe.result.data) {
                        setMemres(resMe.result.data);
                    }
                }
            }
        });
    };

    const getRecentServiceMetics = async () => {
        await ItrApiService.GET({ url: 'ITOps/PerfAgent/GetRecentServiceMetrics' }).then((resMeu) => {
            if (resMeu.Success == true) {
                if (resMeu.result.isSuccess == true) {
                    if (resMeu.result.data) {
                        setMemUtilization(resMeu.result.data);
                    }
                }
            }
        });

    };

    const getLastOneMinSystemMetrics = async () => {
        await ItrApiService.GET({ url: 'ITOps/PerfAgent/GetLastOneMinSystemMetrics' }).then((resCpu) => {
            if (resCpu.Success == true) {
                if (resCpu.result.isSuccess == true) {
                    setCpuChartData(resCpu.result.data);
                    let datasets = [];
                    let memdatasets = [];
                    let label = [];
                    if (resCpu.result.data) {
                        for (let val of resCpu.result.data) {
                            let rand = getRandomColor();
                            let rand2 = getRandomColor();
                            let obj = {
                                type: 'line',
                                label: val.hostName,
                                backgroundColor: rand,
                            }
                            let obj2 = {
                                type: 'line',
                                label: val.hostName,
                                backgroundColor: rand2,
                            }
                            let data = [];
                            let data2 = []
                            for (let val2 of val.perfSystemMetrics) {
                                label.push(dateUTCtoLocal(val2.lastReadedDateTime,'h:mm:ss'));
                                data.push(val2.cpu);
                                data2.push(val2.mem);
                            }
                            obj.data = data;
                            obj2.data = data2;
                            datasets.push(obj);
                            memdatasets.push(obj2);
                        }
                    }
                    setCpuChartDataSet(datasets);
                    setMemChartDataSet(memdatasets)
                    setCpuChartLabe(label);
                }
            }
        });

    };

    const getRecentAPIMetrics = async () => {
        await ItrApiService.GET({ url: 'ITOps/PerfAgent/GetRecentAPIMetrics' }).then((resAPIMetric) => {
            if(resAPIMetric.Success == true){
                if(resAPIMetric.result.isSuccess == true){
                    if(resAPIMetric.result.data){
                        setServiceUtilization(resAPIMetric.result.data);
                    }
                }
            }
        });

    };

    const recentDBInstanceMetrics = async () => {
        await ItrApiService.GET({ url: 'ITOps/PerfAgent/RecentDBInstanceMetrics' }).then((resDBInstance) => {
            if (resDBInstance.Success == true) {
                if (resDBInstance.result.isSuccess == true) {
                    if (resDBInstance.result.data) {
                        const finalData = resDBInstance.result.data;
                        setDBInstanceMetric(finalData);
                        let datasets = [];
                        let label = [];
                        let obj = {
                            type: 'line',
                            label: finalData[0].instanceName,
                            backgroundColor: getRandomColor(),
                        }
                        let data = [];
                        for (let v of resDBInstance.result.data) {
                            label.push(dateUTCtoLocal(v.lastReadedDateTime,'h:mm:ss'));
                            data.push(v.totalCPU);
                        }
                        obj.data = data;
                        datasets.push(obj);
                        setDBChartData(datasets);
                        setDBChartLabel(label);
                    }
                }
            }
        });

    };

    const RecentDBQueryExecutionMetric = async () => {
        await ItrApiService.GET({ url: 'ITOps/PerfAgent/RecentDBQueryExecutionMetric' }).then((resDBQryExecution) => {
            if (resDBQryExecution.Success == true) {
                if (resDBQryExecution.result.isSuccess == true) {
                    if (resDBQryExecution.result.data) {
                        setDBExecutionMetric(resDBQryExecution.result.data);
                    }
                }
            }
        });

    };
    const getEntity = async () => {
        await ItrApiService.GET({ url: 'RevOps/Entity/GetEntities/true' }).then((resCustomers) => {
            if (resCustomers.Success == true) {
                if (resCustomers.result.isSuccess == true) {
                    if (resCustomers.result.data) {
                        setCustomers(resCustomers.result.data);
                    }
                }
            }
        });
    };


    const getAllOnLoad = async () => {
        getRecentServiceMetics();
        getRecentSystemMetics();
        getLastOneMinSystemMetrics();
        getRecentAPIMetrics();
        recentDBInstanceMetrics();
        RecentDBQueryExecutionMetric();
        getEntity();
        setLoader(false);
    }

    useEffect(() => {
        setLoader(true);
        getAllOnLoad();
    }, [])
    return (
        <>
            <NxLoader state={loader} />
            <Headers />
            <div className='d-flex custom-body-container'>
                <div className='body-sidebar'>
                    <CustomerSidebar visible={true}  />
                </div>
                <div className='body-workspace'>
                    <NxTitleTab className="title-tab" title="Performance" onCloseClick={() => history.push('/dashboard')} />

                    <div className="my-0 performance-main">

                        <div className="container1">
                            {/* <div className='row'>
                                <div className='col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3'>
                                    <select className='form-select form-select-sm' onChange={(e) => getAllOnLoad()}>
                                        <option value=''> All Customer</option>
                                        {customers && customers.map((data, index) => {
                                            return <option key={index} value={data.entityCode}> {data.entityName} </option>
                                        })}
                                    </select>
                                </div>
                            </div> */}
                            <div className="counters">
                            </div>
                            <div className='row'>
                                <div className='col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 mt-2'>
                                    <div className='card w-100 h-100'>
                                        <div className='card-body'>
                                            <div style={{ padding: 10, backgroundColor: 'rgb(204 204 204 / 46%)' }}>
                                                <span style={{ fontSize: 16 }}> Resource Utilization</span>
                                            </div>
                                            <div className='my-2'>
                                                <NxCustomLineChart label={cpuChartLabel} datasets={cpuChartDataSet} />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className='col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 mt-2'>
                                    <div className='card w-100 h-100'>
                                        <div className='card-body'>
                                            <div style={{ padding: 10, backgroundColor: 'rgb(204 204 204 / 46%)' }}>
                                                <span style={{ fontSize: 16 }}>Mem. Utilization by Resource</span>
                                            </div>
                                            <div className='mt-3 table-responsive'>
                                                <table class="table table-wrapper-scroll">
                                                    <thead>
                                                        <tr>
                                                            <th style={{ fontSize: 15 }}>Host Name</th>
                                                            <th style={{ fontSize: 15 }}>Cur</th>
                                                            <th style={{ fontSize: 15 }}>Min</th>
                                                            <th style={{ fontSize: 15 }}>Max</th>
                                                            <th style={{ fontSize: 15 }}>Avg</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {memRes.map((item, index) => {
                                                            return <tr>
                                                                <td style={{ fontSize: 12 }}>{item.hostName}</td>
                                                                <td style={{ fontSize: 12 }}>{item.mem ? parseFloat(item.mem).toFixed(2) : '-'}</td>
                                                                <td style={{ fontSize: 12, color: 'green' }}>{item.minMem ? parseFloat(item.minMem).toFixed(2) : '-'}</td>
                                                                <td style={{ fontSize: 12, color: 'red' }}>{item.maxMem ? parseFloat(item.maxMem).toFixed(2) : '-'}</td>
                                                                <td style={{ fontSize: 12, color: 'blue' }}>
                                                                    <div style={{ display: 'flex', }}>
                                                                        <div style={{ fontSize: '12px' }}>
                                                                            {item.avgMem ? parseFloat(item.avgMem).toFixed(2) : '-'}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Tab 2 */}
                                <div className='col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 mt-2'>
                                    <div className='card w-100 h-100'>
                                        <div className='card-body'>
                                            <div style={{ padding: 10, backgroundColor: 'rgb(204 204 204 / 46%)' }}>
                                                <span style={{ fontSize: 16 }}>Memory Utilization</span>
                                            </div>
                                            <div className='my-2'>
                                                <NxCustomLineChart label={cpuChartLabel} datasets={memChartDataSet} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 mt-2'>
                                    <div className='card w-100 h-100    '>
                                        <div className='card-body'>
                                            <div style={{ padding: 10, backgroundColor: 'rgb(204 204 204 / 46%)' }}>
                                                <span style={{ fontSize: 16 }}> Data Server Utilization</span>
                                            </div>
                                            <div className='my-2'>
                                                <NxCustomLineChart label={dbChartLabel} datasets={dbChartData} />
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                {/* new */}
                                <div className='col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 mt-2'>
                                    <div className='card w-100'>
                                        <div className='card-body'>
                                            <div style={{ padding: 10, backgroundColor: 'rgb(204 204 204 / 46%)' }}>
                                                <span style={{ fontSize: 16 }}>Service Utilization</span>
                                            </div>
                                            <div className='mt-3 table-responsive'>
                                                <table class="table table-wrapper-scroll">
                                                    <thead>
                                                        {/* <tr>
                                                            <th colSpan="3" style={{ fontSize: 15 }}></th>
                                                            <th colSpan="4" style={{ fontSize: 15 }}>Mem. Utilization by Service</th>
                                                            <th colSpan="8" style={{ fontSize: 15 }}>Response Time</th>
                                                        </tr> */}
                                                        <tr>
                                                            <th style={{ fontSize: 15 }}>Service Name</th>
                                                            {/* <th style={{ fontSize: 15 }}>Status</th> */}
                                                            <th style={{ fontSize: 15 }}>Updated</th>

                                                            <th style={{ fontSize: 15 }}>No Of Request</th>
                                                            <th style={{ fontSize: 15 }}>CPU</th>
                                                            <th style={{ fontSize: 15 }}>Memory</th>
                                                            <th style={{ fontSize: 15 }}>Response Time(ms)</th>
                                                            {/* <th style={{ fontSize: 15 }}>Cur</th>
                                                            <th style={{ fontSize: 15 }}>Min</th>
                                                            <th style={{ fontSize: 15 }}>Max</th>
                                                            <th style={{ fontSize: 15 }}>Avg</th> */}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {serviceUtilization.map((item, index) => {
                                                            return <tr key={index}>
                                                                <td style={{ fontSize: 13.5 }}>{item.serviceName}</td>
                                                                {/* <td style={{ fontSize: 13.5 }}><FontAwesomeIcon icon={item.healthStatus == 'Healthy' ? faCheckCircle : faTimesCircle} color={item.healthStatus == 'Healthy' ? 'green' : 'red'} /> {item.healthStatus}</td> */}
                                                                <td style={{ fontSize: 13.5 }}>{dateUTCtoLocal(item.lastReadedDateTime,'DD-MM-YYYY hh:mm:ss a')}</td>
                                                                <td style={{ fontSize: 13.5 }}>{item.noOfRequests}</td>
                                                                <td style={{ fontSize: 13.5 }}>{item.avgCPU}</td>
                                                                <td style={{ fontSize: 13.5 }}>{item.avgMemory}</td>
                                                                <td style={{ fontSize: 13.5 }}>{item.avgResponseTime}</td>
                                                            </tr>
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 mt-2'>
                                    <div className='card w-100'>
                                        <div className='card-body'>
                                            <div style={{ padding: 10, backgroundColor: 'rgb(204 204 204 / 46%)' }}>
                                                <span style={{ fontSize: 16 }}>Resource Snapshot</span>
                                            </div>
                                            <div className='mt-3 table-responsive'>
                                                <table class="table table-wrapper-scroll">
                                                    <thead>
                                                        <tr>
                                                            <th style={{ fontSize: 15 }}>Service Name</th>
                                                            <th style={{ fontSize: 15 }}>Status</th>
                                                            <th style={{ fontSize: 15 }}>Updated</th>
                                                            {/* <th style={{ fontSize: 15 }}>Memory</th>
                                                            <th style={{ fontSize: 15 }}>CPU</th> */}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {memUtilization.map((item, index) => {
                                                            /*if (index <= 4) {*/
                                                            return <tr key={index}>
                                                                <td style={{ fontSize: 13.5 }}>{item.serviceName}</td>
                                                                <td style={{ fontSize: 13.5 }}><FontAwesomeIcon icon={item.healthStatus == 'Healthy' ? faCheckCircle : faTimesCircle} color={item.healthStatus == 'Healthy' ? 'green' : 'red'} /> {item.healthStatus}</td>
                                                                <td style={{ fontSize: 13.5 }}>{dateUTCtoLocal(item.lastReadedDateTime,'DD-MM-YYYY hh:mm:ss a')}</td>
                                                                {/* <td style={{ fontSize: 13.5 }}>{item.mem ? item.mem : '-'}</td>
                                                                    <td style={{ fontSize: 13.5 }}>{item.cpu ? item.cpu : '-'}</td> */}
                                                            </tr>
                                                            /* }*/
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2'>
                                    <div className='card w-100'>
                                        <div className='card-body'>
                                            <div style={{ padding: 10, backgroundColor: 'rgb(204 204 204 / 46%)' }}>
                                                <span style={{ fontSize: 16 }}>DB Query Execution</span>
                                            </div>
                                            <div className='mt-3 table-responsive'>
                                                <table class="table table-wrapper-scroll">
                                                    <thead>
                                                        <tr>
                                                            <th style={{ fontSize: 15, width: '10%' }}>DB Name</th>
                                                            <th style={{ fontSize: 15, width: '10%' }}>Updated</th>
                                                            <th style={{ fontSize: 15, width: '10%' }}>No Of Executed</th>
                                                            <th style={{ fontSize: 15, width: '10%' }}>CPU</th>
                                                            <th style={{ fontSize: 15, width: '10%' }}>Memory</th>
                                                            <th style={{ fontSize: 15, width: '10%' }}>Elapsed Time(ms)</th>
                                                            <th style={{ fontSize: 15 }}>Query</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {dbQryExecutionMetric.map((item, index) => {
                                                            return <tr key={index}>
                                                                <td style={{ fontSize: 13.5 }}>{item.dbName}</td>
                                                                <td style={{ fontSize: 13.5 }}>{dateUTCtoLocal(item.recordedDateTime,'DD-MM-YYYY hh:mm:ss a')}</td>
                                                                <td style={{ fontSize: 13.5 }}>{item.executionCount}</td>
                                                                <td style={{ fontSize: 13.5 }}>{item.avgCPU}</td>
                                                                <td style={{ fontSize: 13.5 }}>{item.avgMemory}</td>
                                                                <td style={{ fontSize: 13.5 }}>{item.avgElapsed}</td>
                                                                <td style={{ fontSize: 13.5 }}>
                                                                    <textarea className="form-control form-control-sm" rows="3" cols="100">{item.queryText}</textarea>
                                                                </td>
                                                            </tr>
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* old */}

                                {/* <div className='col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 mt-2'>
                                    <div className='card w-100'>
                                        <div className='card-body'>
                                            <div style={{ padding: 10, backgroundColor: 'rgb(204 204 204 / 46%)' }}>
                                                <span style={{ fontSize: 16 }}>Service Snapshot</span>
                                            </div>
                                            <div className='mt-3 table-responsive'>
                                                <table class="table table-wrapper-scroll">
                                                    <thead>
                                                        <tr>
                                                            <th style={{ fontSize: 15 }}>Ser. Name</th>
                                                            <th style={{ fontSize: 15 }}>Status</th>
                                                            <th style={{ fontSize: 15 }}>Updated</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {memUtilization.map((item, index) => {
                                                            return <tr key={index}>
                                                                <td style={{ fontSize: 13.5 }}>{item.serviceName}</td>
                                                                <td style={{ fontSize: 13.5 }}><FontAwesomeIcon icon={item.healthStatus == 'Healthy' ? faCheckCircle : faTimesCircle} color={item.healthStatus == 'Healthy' ? 'green' : 'red'} /> {item.healthStatus}</td>
                                                                <td style={{ fontSize: 13.5 }}>{moment(item.lastReadedDateTime).format('hh:mm:ss')}</td>
                                                            </tr>
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 mt-2'>
                                    <div className='card w-100'>
                                        <div className='card-body'>
                                            <div style={{ padding: 10, backgroundColor: 'rgb(204 204 204 / 46%)' }}>
                                                <span style={{ fontSize: 16 }}>Mem. Utilization by Service </span>
                                            </div>
                                            <div className='mt-3 table-responsive'>
                                                <table class="table table-wrapper-scroll">
                                                    <thead>
                                                        <tr>
                                                            <th style={{ fontSize: 15 }}>Ser. Name</th>
                                                            <th style={{ fontSize: 15 }}>Cur</th>
                                                            <th style={{ fontSize: 15 }}>Min</th>
                                                            <th style={{ fontSize: 15 }}>Max</th>
                                                            <th style={{ fontSize: 15 }}>Avg</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {memUtilization.map((item, index) => {
                                                            return <tr key={index}>
                                                                <td style={{ fontSize: 13.5 }}>{item.serviceName}</td>
                                                                <td style={{ fontSize: 13.5 }}>...</td>
                                                                <td style={{ fontSize: 13.5 }}>{item.mem ? parseFloat(item.mem).toFixed(2) : '-'}</td>
                                                                <td style={{ fontSize: 13.5 }}>...</td>
                                                                <td style={{ fontSize: 13.5 }}>
                                                                    <div style={{ display: 'flex' }}>
                                                                        <div style={{}}>
                                                                            {item.mem ? parseFloat(item.mem).toFixed(2) : '-'}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 mt-2'>
                                    <div className='card w-100'>
                                        <div className='card-body'>
                                            <div style={{ padding: 10, backgroundColor: 'rgb(204 204 204 / 46%)' }}>
                                                <span style={{ fontSize: 16 }}>API Snapshot</span>
                                            </div>
                                            <div className='mt-3 table-responsive'>
                                                <table class="table table-wrapper-scroll">
                                                    <thead>
                                                        <tr>
                                                            <th style={{ fontSize: 15 }}>Ser. Name</th>
                                                            <th style={{ fontSize: 15 }}>Status</th>
                                                            <th style={{ fontSize: 15 }}>Updated</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {memUtilization.map((item, index) => {
                                                            return <tr key={index}>
                                                                <td style={{ fontSize: 13.5 }}>{item.serviceName}</td>
                                                                <td style={{ fontSize: 13.5 }}><FontAwesomeIcon icon={item.healthStatus == 'Healthy' ? faCheckCircle : faTimesCircle} color={item.healthStatus == 'Healthy' ? 'green' : 'red'} /> {item.healthStatus}</td>
                                                                <td style={{ fontSize: 13.5 }}>{moment(item.lastReadedDateTime).format('hh:mm:ss')}</td>
                                                            </tr>
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 mt-2'>
                                    <div className='card w-100'>
                                        <div className='card-body'>
                                            <div style={{ padding: 10, backgroundColor: 'rgb(204 204 204 / 46%)' }}>
                                                <span style={{ fontSize: 16 }}>API Ping Time </span>
                                            </div>
                                            <div className='mt-3 table-responsive'>
                                                <table class="table table-wrapper-scroll">
                                                    <thead>
                                                        <tr>
                                                            <th style={{ fontSize: 15 }}>Ser. Name</th>
                                                            <th style={{ fontSize: 15 }}>Cur</th>
                                                            <th style={{ fontSize: 15 }}>Min</th>
                                                            <th style={{ fontSize: 15 }}>Max</th>
                                                            <th style={{ fontSize: 15 }}>Avg</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {memUtilization.map((item, index) => {
                                                            return <tr key={index}>
                                                                <td style={{ fontSize: 13.5 }}>{item.serviceName}</td>
                                                                <td style={{ fontSize: 13.5 }}>...</td>
                                                                <td style={{ fontSize: 13.5 }}>{item.mem ? parseFloat(item.mem).toFixed(2) : '-'}</td>
                                                                <td style={{ fontSize: 13.5 }}>...</td>
                                                                <td style={{ fontSize: 13.5 }}>
                                                                    <div style={{ display: 'flex' }}>
                                                                        <div style={{}}>
                                                                            {item.mem ? parseFloat(item.mem).toFixed(2) : '-'}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                                {/* <div className='col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 mt-2'>
                                    <div className='card w-100'>
                                        <div className='card-body'>
                                            <div style={{ padding: 10, backgroundColor: 'rgb(204 204 204 / 46%)' }}>
                                                <span style={{ fontSize: 16 }}>Database Performance</span>
                                            </div>
                                            <div className='mt-4'>
                                                <NxSpeedometer />
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-2'>
                                    <div className='card w-100'>
                                        <div className='card-body'>
                                            <div style={{ padding: 10, backgroundColor: 'rgb(204 204 204 / 46%)' }}>
                                                <span style={{ fontSize: 16 }}>SQL Query Analytics</span>
                                            </div>
                                            <div className='mt-3 table-responsive'>
                                                <table class="table table-wrapper-scroll">
                                                    <thead>
                                                        <tr>
                                                            <th style={{ fontSize: 15 }}>Query Name</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ fontSize: 13.5 }}>
                                                                <span className='sub-single-line-cls' style={{ fontWeight: 'bold' }}>
                                                                    Realtek RTER+1227 + Fast Element NIC #3-Ncard Pack
                                                                </span>
                                                                <span className='sub-single-line-cls'>
                                                                    OPM-123212
                                                                </span>
                                                                <span className='sub-single-line-cls'>
                                                                    Error: 0 | Discards: 81.28G
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ fontSize: 13.5 }}>
                                                                <span className='sub-single-line-cls' style={{ fontWeight: 'bold' }}>
                                                                    Realtek RTER+1227 + Fast Element NIC #3-Ncard Pack
                                                                </span>
                                                                <span className='sub-single-line-cls'>
                                                                    OPM-123212
                                                                </span>
                                                                <span className='sub-single-line-cls'>
                                                                    Error: 0 | Discards: 81.28G
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ fontSize: 13.5 }}>
                                                                <span className='sub-single-line-cls' style={{ fontWeight: 'bold' }}>
                                                                    Realtek RTER+1227 + Fast Element NIC #3-Ncard Pack
                                                                </span>
                                                                <span className='sub-single-line-cls'>
                                                                    OPM-123212
                                                                </span>
                                                                <span className='sub-single-line-cls'>
                                                                    Error: 0 | Discards: 81.28G
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}



                            </div>
                        </div>
                    </div>
                    <div style={{ height: '40px' }}>

                    </div>
                </div>
            </div>
            <NxFooter companyName="© 2023 Altascio Technologies (I) Pvt. Ltd.," ExtraContent={[
                { name: 'Terms of Service' },
                { name: 'Privacy' },
            ]} />
        </>
    )
}
