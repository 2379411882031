import React, { useState, useEffect } from "react";
import './OnBoardingAdd.css';
import { useHistory } from 'react-router-dom';
import { ItrApiService, NxApiService } from '@afiplfeed/itr-ui';
import { NxBreadcrumb, NxTitleTab, NxCustomTab, NxLoader, NxFooter } from '@afiplfeed/itr-app';
import Headers from "../../Screens/Headers/Headers";
import { NotificationManager } from "react-notifications";

export default function OnboardingAdd() {

    const Buttons = () => {
        return <div className="form-button nx-enquiry-buttons mt-3 mb-5">
            <button className="btn btn-sm btn-primary buttons" onClick={() => {
                ItrApiService.POST({
                    url: 'TenantPlatform/Entity/SaveEntity',
                    data: onboarding
                })
            }}> Save</button>
            <button className="btn btn-sm btn-primary buttons"> Onboard</button>
            <button className="btn btn-sm btn-primary buttons"> Previous</button>
            <button className="btn btn-sm btn-primary buttons"> Next</button>
            <button className="btn btn-sm close btn-outline-primary" onClick={() => history.goBack()}> Cancel</button>
        </div>
    }

    const [loading, setLoading] = useState(false);

    // cities
    const [allCities, setAllCities] = useState([]);
    const getCities = async () => {
        let result = await VxApiService.GET({
            url: 'Platform/City/GetAllCities'
        });
        if (result.result.isSuccess == true) {
            let getCustomerDetails = result.result.data;
            setAllCities(getCustomerDetails);
        }
        else if (result.result.isSuccess == false) {
            NotificationManager.error(result.result.message);
            // sessionStorage.clear();
            // history.push('/')
        }
    }


    // states
    const [allStates, setAllStates] = useState([]);
    const getStates = async () => {
        let result = await VxApiService.GET({
            url: 'Platform/State/GetAllStates'
        });
        if (result.result.isSuccess == true) {
            let getCustomerDetails = result.result.data;
            setAllStates(getCustomerDetails);
        }
        else if (result.result.isSuccess == false) {
            NotificationManager.error(result.result.message);
            // sessionStorage.clear();
            // history.push('/')
        }
    }


    // country
    const [allCountries, setAllCountries] = useState([]);
    const getCountry = async () => {
        let result = await VxApiService.GET({
            url: 'Platform/Country/GetAllCountries'
        });
        if (result.result.isSuccess == true) {
            let getCustomerDetails = result.result.data;
            setAllCountries(getCustomerDetails);
        }
        else if (result.result.isSuccess == false) {
            NotificationManager.error(result.result.message);
            // sessionStorage.clear();
            // history.push('/')
        }
    }


    // pincode
    const [allPinCode, setAllPinCode] = useState([]);
    const getZipCodes = async () => {
        let result = await VxApiService.GET({
            url: 'Platform/ZipCode/GetAllZipCodes'
        });
        if (result.result.isSuccess == true) {
            let getCustomerDetails = result.result.data;
            setAllPinCode(getCustomerDetails);
        }
        else if (result.result.isSuccess == false) {
            NotificationManager.error(result.result.message);
            // sessionStorage.clear();
            // history.push('/')
        }
    }

    const [customers, setCustomers] = useState([]);
    const getparent = async () => {
        await ItrApiService.GET({ url: 'RevOps/Entity/GetEntities/true' }).then((resCustomers) => {
            if (resCustomers.Success == true) {
                if (resCustomers.result.isSuccess == true) {
                    if (resCustomers.result.data) {
                        setCustomers(resCustomers.result.data);
                    }
                }
            }
        });

    };


    // AllUDo`s
    let UdoData = ["Enquiry Status", "Nature Of Business", "Industry", "Ownership", "Source"];
    const [allUdo, setAllUdo] = useState([]);
    const getUdo = async () => {
        setLoading(true);
        let result = await ItrApiService.POST({
            url: 'Platform/UserDefined/AllUDOs',
            data: UdoData
        });
        if (result.result.isSuccess == true) {
            setLoading(false);
            let getCustomerDetails = result.result.data;
            let getOptions = getCustomerDetails[1].Options;
            setAllUdo(getOptions);
        }
        else if (result.result.isSuccess == false) {
            setLoading(false);
            NotificationManager.error(result.result.message);
            // sessionStorage.clear();
            // history.push('/')
        }
    }


    useEffect(() => {
        getUdo();
        // await getCities();
        // await getCountry();
        // await getZipCodes();
        // await getStates();
    }, []);



    const [onboarding, setOnboarding] = useState({
        entityID: 0,
        entityCode: "",
        entityName: "",
        partnerID: 0,
        enquiryID: 0,
        ownership: 0,
        industry: 0,
        natureOfBusiness: 0,
        noOfEmployees: 0,
        annualRevenue: "",
        additionalDetails: "",
        officialWebSite: "",
        fullName: "",
        companyName: "",
        email: "",
        phone: "",
        sourcedBy: "",
        timeToBuy: "",
        role: "",
        status: 0,
        feedback: "",
        administrativeContactID: 0,
        administrativeContact: {
            entityContactID: 0,
            entityID: 0,
            firstName: "",
            lastName: "",
            displayName: "",
            designation: "",
            department: "",
            phoneNo: "",
            emailID: ""
        },
        technicalContactID: 0,
        technicalContact: {
            entityContactID: 0,
            entityID: 0,
            firstName: "",
            lastName: "",
            displayName: "",
            designation: "",
            department: "",
            phoneNo: "",
            emailID: ""
        },
        billingContactID: 0,
        billingContact: {
            entityContactID: 0,
            entityID: 0,
            firstName: "",
            lastName: "",
            displayName: "",
            designation: "",
            department: "",
            phoneNo: "",
            emailID: ""
        },
        isSameContact: false,
        entityBoardingID: 0,
        entityBoarding: {
            entityBoardingID: 0,
            entityID: 0,
            agreement: "",
            ncnda: "",
            termsOfUse: "",
            customerType: "",
            hostingType: "",
            isActive: true
        },
        isOnboardingCompleted: true,
        isActive: true
    })


    let history = useHistory();
    const [handel, setHandel] = useState(false);
    return (
        <div>
            <NxLoader state={loading} />
            <Headers />
            <div className="customer-dynamic-pages pt-4 ">
                <NxTitleTab className="title-tab page-title" title='Onboard Customer' onCloseClick={() => {
                    history.goBack();
                }} />
                <NxCustomTab
                    data={[
                        {
                            name: "Enquiry Details",
                            Components: () => {
                                return (
                                    <div className="container-form">
                                        <div className="row">
                                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                <div className="row">
                                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                        <label >Full Name</label>
                                                        <input className="form-control form-control-sm" id="firstName" type="text" value={onboarding.fullName}
                                                            onChange={(e) => setOnboarding({ ...onboarding, fullName: e.target.value })} enterKeyHint="search" />
                                                    </div>

                                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                        <label htmlFor="lastName">Company Name</label>
                                                        <input className="form-control form-control-sm" id="lastName" type="text" value={onboarding.companyName}
                                                            onChange={(e) => setOnboarding({ ...onboarding, companyName: e.target.value })} />
                                                    </div>

                                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                        <label htmlFor="Email">Email</label>
                                                        <input className="form-control form-control-sm" id="Email" type="text" value={onboarding.email}
                                                            onChange={(e) => setOnboarding({ ...onboarding, email: e.target.value })} />
                                                    </div>

                                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                        <label htmlFor="Phone">Phone</label>
                                                        <input className="form-control form-control-sm" id="Phone" type="text" value={onboarding.phone}
                                                            onChange={(e) => setOnboarding({ ...onboarding, phone: e.target.value })} />
                                                    </div>

                                                    {/* <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                    <label htmlFor="aboutus">Where did you hear about us?</label>
                                                    <select class="form-select form-select-sm" id="aboutus">
                                                        <option value="" selected hidden></option>
                                                        <option disabled>--Please-select--</option>
                                                        <option value="Google" >Google</option>
                                                        <option value="Events" >Events</option>
                                                        <option value="Conferance" >Conferance</option>
                                                        <option value="Blog" >Blog</option>
                                                        <option value="Referance" >Referance</option>
                                                    </select>
                                                </div> */}

                                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                        <label >Source</label>
                                                        <select class="form-select form-select-sm" aria-label="Default select example" value={onboarding.sourcedBy}
                                                            onChange={(e) => setOnboarding({ ...onboarding, sourcedBy: e.target.value })}>
                                                            <option value="" selected hidden></option>
                                                            <option disabled>--Please-select--</option>
                                                            <option value="Advertisement" >Advertisement</option>
                                                            <option value="Cold Call" >Cold Call</option>
                                                            <option value="Employee Referal" >Employee Referal</option>
                                                            <option value="External Referal" >External Referal</option>
                                                            <option value="Online" >Online</option>
                                                            <option value="Partner" >Partner</option>
                                                            <option value="Public Relations" >Public Relations</option>
                                                            <option value="Sales Mail" >Sales Mail</option>
                                                            <option value="Seminar Partner" >Seminar Partner</option>
                                                            <option value="Seminar Internal" >Seminar Internal</option>
                                                            <option value="Trade Show" >Trade Show</option>
                                                            <option value="Database" >Database</option>
                                                        </select>
                                                    </div>

                                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                        <label htmlFor="timeToBuy">Time to Buy</label>
                                                        <select class="form-select form-select-sm" id="timeToBuy" value={onboarding.timeToBuy}
                                                            onChange={(e) => setOnboarding({ ...onboarding, timeToBuy: e.target.value })}>
                                                            <option value="" selected hidden></option>
                                                            <option disabled>--Please-select--</option>
                                                            <option value="Immediately" >Immediately</option>
                                                            <option value="Within 30 days" >Within 30 days</option>
                                                            <option value="Within 60 days" >Within 60 days</option>
                                                            <option value="Within 90 days" >Within 90 days</option>
                                                            <option value="Just enquiring" >Just Enquiring</option>
                                                        </select>
                                                    </div>

                                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                        <label htmlFor="role">Role</label>
                                                        <select class="form-select form-select-sm" aria-label="Default select example" id="role" value={onboarding.role}
                                                            onChange={(e) => setOnboarding({ ...onboarding, role: e.target.value })}>
                                                            <option value="" selected hidden></option>
                                                            <option disabled>--Please-select--</option>
                                                            <option value="Consultant" >Consultant</option>
                                                            <option value="Influencer" >Influencer</option>
                                                            <option value="Decision maker" >Decision maker</option>
                                                            <option value="Approver" >Approver</option>
                                                            <option value="Sponser" >Sponser</option>
                                                        </select>
                                                    </div>

                                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                        <label >Status</label>
                                                        <select class="form-select form-select-sm" aria-label="Default select example" value={onboarding.status}
                                                            onChange={(e) => setOnboarding({ ...onboarding, status: e.target.value })}>
                                                            <option value="" selected hidden></option>
                                                            <option disabled>--Please-select--</option>
                                                            {allUdo && allUdo.map((data, index) => {
                                                                return <option key={index} value={data.UDOptionName}> {data.UDOptionName} </option>
                                                            })}
                                                        </select>
                                                    </div>

                                                    <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                                        <label htmlFor="feedback">Feedback</label>
                                                        <textarea className="form-control" id="feedback" value={onboarding.feedback}
                                                            onChange={(e) => setOnboarding({ ...onboarding, feedback: e.target.value })} />
                                                    </div>
                                                    <div>
                                                    </div>

                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <Buttons />
                                    </div>
                                )
                            }
                        },
                        {
                            name: "Company",
                            Components: () => <div className="container-form">
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <div className="row">
                                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                <label htmlFor="Account-Name">Company Name</label>
                                                <input className="form-control form-control-sm" id="Account-Name" type="text" value={onboarding.companyName}
                                                    onChange={(e) => setOnboarding({ ...onboarding, companyName: e.target.value })} />
                                            </div>

                                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                <label htmlFor="Account-Name">Website</label>
                                                <input className="form-control form-control-sm" id="Account-Name" type="text" value={onboarding.officialWebSite}
                                                    onChange={(e) => setOnboarding({ ...onboarding, officialWebSite: e.target.value })} />
                                            </div>

                                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                <label >Parent Account</label>
                                                <select class="form-select form-select-sm" aria-label="Default select example" >
                                                    <option value="" >--Please-select--</option>
                                                    {customers && customers.map((data, index) => {
                                                        return <option key={index} value={data.entityCode}> {data.entityName} </option>
                                                    })}
                                                </select>
                                            </div>

                                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                <label >Ownership Type</label>
                                                <select class="form-select form-select-sm" aria-label="Default select example" value={onboarding.ownership}
                                                    onChange={(e) => setOnboarding({ ...onboarding, ownership: e.target.value })}>
                                                    <option value="" selected hidden></option>
                                                    <option value="" >--Please-select--</option>
                                                </select>
                                            </div>

                                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                <label >Industry</label>
                                                <select class="form-select form-select-sm" aria-label="Default select example" value={onboarding.industry}
                                                    onChange={(e) => setOnboarding({ ...onboarding, industry: e.target.value })}>
                                                    <option value="" selected hidden></option>
                                                    <option value="" >--Please-select--</option>
                                                </select>
                                            </div>

                                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                <label >Nature Of Business</label>
                                                <select class="form-select form-select-sm" aria-label="Default select example" value={onboarding.natureOfBusiness}
                                                    onChange={(e) => setOnboarding({ ...onboarding, natureOfBusiness: e.target.value })}>
                                                    <option value="" selected hidden></option>
                                                    <option disabled>--Please-select--</option>
                                                    <option value="Business 01" >Business 01</option>
                                                    <option value="Business 02" >Business 02</option>
                                                </select>
                                            </div>

                                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                <label >No Of Employees</label>
                                                <input className="form-control form-control-sm" id="firstName" type="text" value={onboarding.noOfEmployees}
                                                    onChange={(e) => setOnboarding({ ...onboarding, noOfEmployees: e.target.value })} />
                                            </div>

                                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                <label >Annual Revenue</label>
                                                <input className="form-control form-control-sm" id="firstName" type="text" value={onboarding.annualRevenue}
                                                    onChange={(e) => setOnboarding({ ...onboarding, annualRevenue: e.target.value })} />
                                            </div>

                                            <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                                <label >Additional Details</label>
                                                <textarea className="form-control" id="firstName" value={onboarding.additionalDetails}
                                                    onChange={(e) => setOnboarding({ ...onboarding, additionalDetails: e.target.value })} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-2'>
                                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12  mt-2'>
                                            <label htmlFor="address1" className='f-badge-title'>Address Line 1</label>
                                            <textarea className="form-control form-control-sm mt-1" id='address1' rows="1"
                                            // value={datas.addressLine1} onChange={(e) => {
                                            //     setDatas({ ...datas, addressLine1: e.target.value })
                                            // }} 
                                            />
                                        </div>

                                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12  mt-2'>
                                            <label htmlFor="address2" className='f-badge-title'>Address Line 2</label>
                                            <textarea className="form-control form-control-sm mt-1" id='address2' rows="1"
                                            //  value={datas.addressLine2} onChange={(e) => {
                                            //     setDatas({ ...datas, addressLine2: e.target.value })
                                            // }} 
                                            />
                                        </div>

                                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2'>
                                            <div className='row'>
                                                <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6'>
                                                    <label htmlFor="state" className='f-badge-title'>State</label>
                                                    <input className="form-control form-control-sm mt-1" type="text" id='state'
                                                    // value={datas.state} onChange={(e) => {
                                                    //     setDatas({ ...datas, state: e.target.value })
                                                    // }} 
                                                    />
                                                </div>

                                                <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6'>
                                                    <label htmlFor="zip" className='f-badge-title'>Zip</label>
                                                    <input className="form-control form-control-sm mt-1" type='tel' id='zip'
                                                    //  value={datas.pinCode} onChange={(e) => {
                                                    //     setDatas({ ...datas, pinCode: e.target.value })
                                                    // }} 
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2'>
                                            <label htmlFor="state" className='f-badge-title'>City</label>
                                            <input className="form-control form-control-sm mt-1" type="text" id='city'
                                            // value={datas.city} onChange={(e) => {
                                            //     setDatas({ ...datas, city: e.target.value })
                                            // }} 
                                            />
                                        </div>

                                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2'>
                                            <label htmlFor="country" className='f-badge-title'>Country</label>
                                            <input className="form-control form-control-sm mt-1" type="text" id='country'
                                            // value={datas.country} onChange={(e) => {
                                            //     setDatas({ ...datas, country: e.target.value })
                                            // }} 
                                            />
                                        </div>
                                    </div>

                                    {/* <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-2">
                                <div className="row">
                                    <div className="col-6 col-sm-6 col-lg-6 col-xl-6 col-md-6">
                                        <label >City</label>
                                        <select class="form-select mt-1" aria-label="Default select example" placeholder="">
                                            <option value="" selected hidden></option>
                                            <option value="" >--Please-select--</option>
                                            {allCities && allCities.map((data,index) => {
                                                return <option key={index} value={data.cityName}> {data.cityName} </option> 
                                            })}
                                        </select>
                                    </div>

                                    <div className="col-6 col-sm-6 col-lg-6 col-xl-6 col-md-6">
                                        <label >State</label>
                                        <select class="form-select mt-1" aria-label="Default select example" placeholder="">
                                            <option value="" selected hidden></option>
                                            <option value="" >--Please-select--</option>
                                            {allStates && allStates.map((data,index) => {
                                                return <option key={index} value={data.stateName}> {data.stateName} </option> 
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div> */}



                                    {/* <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-2">
                                <div className="row">
                                    <div className="col-6 col-sm-6 col-lg-6 col-xl-6 col-md-6">
                                        <label >Country</label>
                                        <select class="form-select mt-1" aria-label="Default select example">
                                            <option value="" selected hidden></option>
                                            <option value="" >--Please-select--</option>
                                            {allCountries && allCountries.map((data,index) => {
                                                return <option key={index} value={data.countryName}> {data.countryName} </option> 
                                            })}
                                        </select>
                                    </div>

                                    <div className="col-6 col-sm-6 col-lg-6 col-xl-6 col-md-6">
                                        <label >Pin Code</label>
                                        <select class="form-select mt-1" aria-label="Default select example">
                                            <option value="" selected hidden></option>
                                            <option value="" >--Please-select--</option>
                                            {allPinCode && allPinCode.map((data,index) => {
                                                return <option key={index} value={data.zipcode}> {data.zipcode} </option> 
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div> */}
                                </div>
                                <Buttons />
                            </div>

                        },
                        {
                            name: "Tax Information",
                            Components: () => <div className="container-form">
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <div className="row">
                                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-8 mt-2">
                                                <label htmlFor="Account-Name">Company identification number (CIN)</label>
                                                <input className="form-control form-control-sm" id="Account-Name" type="text" />
                                            </div>

                                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">

                                            </div>

                                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-8 mt-2">
                                                <label >Business PAN (Permanent Account Number)</label>
                                                <input className="form-control form-control-sm" id="Account-Name" type="text" />
                                            </div>

                                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">

                                            </div>

                                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-8 mt-2">
                                                <label >Goods & Services Tax (GST) Identification number</label>
                                                <input className="form-control form-control-sm" id="Account-Name" type="text" />
                                            </div>

                                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">

                                            </div>

                                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-8 mt-2">
                                                <label >Tax deduction account number (TAN)</label>
                                                <input className="form-control form-control-sm" id="Account-Name" type="text" />
                                            </div>

                                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">

                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">

                                    </div>

                                    {/* <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-2">
                            <div className="row">
                                <div className="col-6 col-sm-6 col-lg-6 col-xl-6 col-md-6">
                                    <label >City</label>
                                    <select class="form-select mt-1" aria-label="Default select example" placeholder="">
                                        <option value="" selected hidden></option>
                                        <option value="" >--Please-select--</option>
                                        {allCities && allCities.map((data,index) => {
                                            return <option key={index} value={data.cityName}> {data.cityName} </option> 
                                        })}
                                    </select>
                                </div>

                                <div className="col-6 col-sm-6 col-lg-6 col-xl-6 col-md-6">
                                    <label >State</label>
                                    <select class="form-select mt-1" aria-label="Default select example" placeholder="">
                                        <option value="" selected hidden></option>
                                        <option value="" >--Please-select--</option>
                                        {allStates && allStates.map((data,index) => {
                                            return <option key={index} value={data.stateName}> {data.stateName} </option> 
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div> */}



                                    {/* <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-2">
                            <div className="row">
                                <div className="col-6 col-sm-6 col-lg-6 col-xl-6 col-md-6">
                                    <label >Country</label>
                                    <select class="form-select mt-1" aria-label="Default select example">
                                        <option value="" selected hidden></option>
                                        <option value="" >--Please-select--</option>
                                        {allCountries && allCountries.map((data,index) => {
                                            return <option key={index} value={data.countryName}> {data.countryName} </option> 
                                        })}
                                    </select>
                                </div>

                                <div className="col-6 col-sm-6 col-lg-6 col-xl-6 col-md-6">
                                    <label >Pin Code</label>
                                    <select class="form-select mt-1" aria-label="Default select example">
                                        <option value="" selected hidden></option>
                                        <option value="" >--Please-select--</option>
                                        {allPinCode && allPinCode.map((data,index) => {
                                            return <option key={index} value={data.zipcode}> {data.zipcode} </option> 
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div> */}
                                </div>
                                <Buttons />
                            </div>

                        },

                        {
                            name: "Contacts",
                            Components: () => <div className="container-form enquiry-contacts">
                                <div className="mt-3">
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-2">
                                            <div className="d-flex justify-content-between align-content-center">
                                                <label className="contacts1 contacts">Administrative Contact</label>
                                                <div className="d-flex align-items-center copy-content">
                                                    <span style={{ fontSize: '11px' }} className="text-primary">Copy to All</span>
                                                    <input type="checkbox" className="mx-1 chx form-check-input" onChange={(e) => { setOnboarding({ ...onboarding, isSameContact: e.target.checked }) }} />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                                    <label >First Name</label>
                                                    <input className="form-control form-control-sm" id="firstName" type="text" value={onboarding.administrativeContact.firstName}
                                                        onChange={(e) => setOnboarding({ ...onboarding, administrativeContact: { ...onboarding.administrativeContact, firstName: e.target.value } })} />
                                                </div>

                                                <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                                    <label htmlFor="lastName">Last Name</label>
                                                    <input className="form-control form-control-sm " id="lastName" type="text" value={onboarding.administrativeContact.lastName}
                                                        onChange={(e) => setOnboarding({ ...onboarding, administrativeContact: { ...onboarding.administrativeContact, lastName: e.target.value } })} />
                                                </div>

                                                <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                                    <label >Designation</label>
                                                    <input className="form-control form-control-sm" id="firstName" type="text" value={onboarding.administrativeContact.designation}
                                                        onChange={(e) => setOnboarding({ ...onboarding, administrativeContact: { ...onboarding.administrativeContact, designation: e.target.value } })} />
                                                </div>

                                                <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                                    <label >Department</label>
                                                    <input className="form-control form-control-sm" id="firstName" type="text" value={onboarding.administrativeContact.department}
                                                        onChange={(e) => setOnboarding({ ...onboarding, administrativeContact: { ...onboarding.administrativeContact, department: e.target.value } })} />
                                                </div>

                                                <div className="col-12 col-sm-6 col-lg-12 col-xl-12 col-md-12 mt-2">
                                                    <label >Phone</label>
                                                    <input className="form-control form-control-sm" id="firstName" type="text" value={onboarding.administrativeContact.phoneNo}
                                                        onChange={(e) => setOnboarding({ ...onboarding, administrativeContact: { ...onboarding.administrativeContact, phoneNo: e.target.value } })} />
                                                </div>

                                                <div className="col-12 col-sm-6 col-md-12 col-lg-12 col-xl-12 mt-2">
                                                    <label >Email</label>
                                                    <input className="form-control form-control-sm" id="firstName" type="text" value={onboarding.administrativeContact.emailID}
                                                        onChange={(e) => setOnboarding({ ...onboarding, administrativeContact: { ...onboarding.administrativeContact, emailID: e.target.value } })} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-2">
                                            <label className="contacts1 contacts">Technical Contact</label>
                                            <div className="row">
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                                    <label >First Name</label>
                                                    <input className="form-control form-control-sm" id="firstName" type="text" value={onboarding.technicalContact.firstName}
                                                        onChange={(e) => setOnboarding({ ...onboarding, technicalContact: { ...onboarding.technicalContact, firstName: e.target.value } })} />
                                                </div>

                                                <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                                    <label htmlFor="lastName">Last Name</label>
                                                    <input className="form-control form-control-sm " id="lastName" type="text" value={onboarding.technicalContact.lastName}
                                                        onChange={(e) => setOnboarding({ ...onboarding, technicalContact: { ...onboarding.technicalContact, lastName: e.target.value } })} />
                                                </div>

                                                <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                                    <label >Designation</label>
                                                    <input className="form-control form-control-sm" id="firstName" type="text" value={onboarding.technicalContact.designation}
                                                        onChange={(e) => setOnboarding({ ...onboarding, technicalContact: { ...onboarding.technicalContact, designation: e.target.value } })} />
                                                </div>

                                                <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                                    <label >Department</label>
                                                    <input className="form-control form-control-sm" id="firstName" type="text" value={onboarding.technicalContact.department}
                                                        onChange={(e) => setOnboarding({ ...onboarding, technicalContact: { ...onboarding.technicalContact, department: e.target.value } })} />
                                                </div>

                                                <div className="col-12 col-sm-6 col-lg-12 col-xl-12 col-md-12 mt-2">
                                                    <label >Phone</label>
                                                    <input className="form-control form-control-sm" id="firstName" type="text" value={onboarding.technicalContact.phoneNo}
                                                        onChange={(e) => setOnboarding({ ...onboarding, technicalContact: { ...onboarding.technicalContact, phoneNo: e.target.value } })} />
                                                </div>

                                                <div className="col-12 col-sm-6 col-md-12 col-lg-12 col-xl-12 mt-2">
                                                    <label >Email</label>
                                                    <input className="form-control form-control-sm" id="firstName" type="text" value={onboarding.technicalContact.emailID}
                                                        onChange={(e) => setOnboarding({ ...onboarding, technicalContact: { ...onboarding.technicalContact, emailID: e.target.value } })} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-2">
                                            <label className="contacts1 contacts">Billing Contact</label>
                                            <div className="row">
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                                    <label >First Name</label>
                                                    <input className="form-control form-control-sm" id="firstName" type="text" value={onboarding.billingContact.firstName}
                                                        onChange={(e) => setOnboarding({ ...onboarding, billingContact: { ...onboarding.billingContact, firstName: e.target.value } })} />
                                                </div>

                                                <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                                    <label htmlFor="lastName">Last Name</label>
                                                    <input className="form-control form-control-sm " id="lastName" type="text" value={onboarding.billingContact.lastName}
                                                        onChange={(e) => setOnboarding({ ...onboarding, billingContact: { ...onboarding.billingContact, lastName: e.target.value } })} />
                                                </div>

                                                <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                                    <label >Designation</label>
                                                    <input className="form-control form-control-sm" id="firstName" type="text" value={onboarding.billingContact.designation}
                                                        onChange={(e) => setOnboarding({ ...onboarding, billingContact: { ...onboarding.billingContact, designation: e.target.value } })} />
                                                </div>

                                                <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                                    <label >Department</label>
                                                    <input className="form-control form-control-sm" id="firstName" type="text" value={onboarding.billingContact.department}
                                                        onChange={(e) => setOnboarding({ ...onboarding, billingContact: { ...onboarding.billingContact, department: e.target.value } })} />
                                                </div>

                                                <div className="col-12 col-sm-6 col-lg-12 col-xl-12 col-md-12 mt-2">
                                                    <label >Phone</label>
                                                    <input className="form-control form-control-sm" id="firstName" type="text" value={onboarding.technicalContact.phoneNo}
                                                        onChange={(e) => setOnboarding({ ...onboarding, technicalContact: { ...onboarding.technicalContact, phoneNo: e.target.value } })} />
                                                </div>

                                                <div className="col-12 col-sm-6 col-md-12 col-lg-12 col-xl-12 mt-2">
                                                    <label >Email</label>
                                                    <input className="form-control form-control-sm" type="text" value={onboarding.technicalContact.emailID}
                                                        onChange={(e) => setOnboarding({ ...onboarding, technicalContact: { ...onboarding.technicalContact, emailID: e.target.value } })} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* buttons */}
                                <Buttons />
                            </div>
                        },
                        {
                            name: "Onboarding",
                            Components: () => <div className="container-form enquiry-contacts">
                                <div className="mt-3">
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-2">
                                            <div className="row">
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                                    <label >Agreement</label>
                                                    <input className="form-control form-control-sm" type="file"
                                                        onChange={(e) => {
                                                            let img = e.target.files[0];
                                                            const fileReader = new FileReader();
                                                            fileReader.readAsDataURL(img)
                                                            fileReader.onload = async () => {
                                                                setOnboarding({ ...onboarding, entityBoarding: { ...onboarding.entityBoarding, agreement: fileReader.result } })
                                                            }
                                                            fileReader.onerror = async () => {
                                                                return false
                                                            }
                                                        }} />
                                                    {/* onChange={(e) => setOnboarding({ ...onboarding,technicalContact: {...onboarding.technicalContact, agreement:  e.target.value} })} */}
                                                </div>

                                                <div className="col-12 col-sm-6 col-md-12 col-lg-12 col-xl-12 mt-2">
                                                    <label >NCNDA</label>
                                                    <input className="form-control form-control-sm" type="file"
                                                        onChange={(e) => {
                                                            let img = e.target.files[0];
                                                            const fileReader = new FileReader();
                                                            fileReader.readAsDataURL(img)
                                                            fileReader.onload = async () => {
                                                                setOnboarding({ ...onboarding, entityBoarding: { ...onboarding.entityBoarding, ncnda: fileReader.result } })
                                                            }
                                                            fileReader.onerror = async () => {
                                                                return false
                                                            }
                                                        }}
                                                    />
                                                    {/* // onChange={(e) => setOnboarding({ ...onboarding, entityBoarding: { ...onboarding.entityBoarding, ncnda: e.target.value } })} /> */}
                                                </div>

                                                <div className="col-12 col-sm-6 col-lg-12 col-xl-12 col-md-12 mt-2">
                                                    <label>Terms of use</label>
                                                    <input className="form-control form-control-sm" type="file"
                                                        onChange={(e) => {
                                                            let img = e.target.files[0];
                                                            const fileReader = new FileReader();
                                                            fileReader.readAsDataURL(img)
                                                            fileReader.onload = async () => {
                                                                setOnboarding({ ...onboarding, entityBoarding: { ...onboarding.entityBoarding, termsOfUse: fileReader.result } })
                                                            }
                                                            fileReader.onerror = async () => {
                                                                return false
                                                            }
                                                        }} />
                                                    {/* onChange={(e) => setOnboarding({ ...onboarding, entityBoarding: { ...onboarding.entityBoarding, termsOfUse: e.target.value } })} /> */}
                                                </div>

                                                <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                                    <label className="contacts1 contacts">Customer Type</label>
                                                    <select class="form-select form-select-sm" value={onboarding.entityBoarding.customerType}
                                                        onChange={(e) => setOnboarding({ ...onboarding, entityBoarding: { ...onboarding.entityBoarding, customerType: e.target.value } })}>
                                                        <option value="" selected hidden></option>
                                                        <option disabled>--Please-select--</option>
                                                        <option value="Paid Customer" >Paid Customer</option>
                                                        <option value="30 Days Trail" >30 Days Trail</option>
                                                        <option value="Demo Access" > Demo Access </option>
                                                    </select>
                                                </div>

                                                <div className="col-12 col-sm-6 col-md-6 col-lg-12 col-xl-12 mt-2">
                                                    <label >Hosting Type</label>
                                                    <select class="form-select form-select-sm" value={onboarding.entityBoarding.hostingType}
                                                        onChange={(e) => setOnboarding({ ...onboarding, entityBoarding: { ...onboarding.entityBoarding, hostingType: e.target.value } })}>
                                                        <option value="" selected hidden></option>
                                                        <option disabled>--Please-select--</option>
                                                        <option value="Public Cloud" >Public Cloud</option>
                                                        <option value="Private Cloud" >Private Cloud</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* buttons */}
                                <Buttons />
                            </div>
                        },
                    ]}
                />
            </div>
            <NxFooter className="verscio-footer" companyName="© 2022 Ithred Solutions" ExtraContent={[
                { name: 'Terms of Service' },
                { name: 'Privacy' },
            ]} />
        </div>
    )
}