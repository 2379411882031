import React from 'react';
import {Bar} from 'react-chartjs-2';
import {ChartColors} from '../../general/general';


export default function NxBarCharts() {

  const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July','Aug','Sep','Oct','Nov','Dec'];
    
    return (
        <div>
            <Bar
                data={{
                    labels,
                    datasets: [
                      {
                        // label:'New Sale',/
                        label:'Open',
                        data: [5, 18,12,5, 18,12,5, 18,12,5, 18,12,],
                        backgroundColor: [
                          ChartColors.Splash,
                          ChartColors.Splash,
                          ChartColors.Splash
                        ],
                      },
                      {
                        // label:'Renewals',
                        label:'Inprogress',
                        data: [10, 20, 25, 18,12,5, 18,12,5, 18,12,],
                        backgroundColor: [
                          ChartColors.Sky,
                          ChartColors.Sky,
                          ChartColors.Sky
                        ],
                      },
                      {
                        // label:'Receivables',
                        label:'Close',
                        data: [6, 9, 18,5, 18,12,5, 18,12,5, 18,12,],
                        backgroundColor: [
                          ChartColors.Moonshine,
                          ChartColors.Moonshine,
                          ChartColors.Moonshine
                        ],
                      },
                      
                    ],
                  }}
                  height={250}
                  width={200}
                  options={{
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [
                          {
                            ticks: {
                              // min:500,
                              // max:5000
                              beginAtZero: false,
                            },
                          },
                        ],
                      },
                    responsve:true,
                    }}
            />
        </div>
    )
}
