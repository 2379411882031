import React, { useEffect, useState } from 'react';
import './accessLog.css';
import Headers from '../../../../Screens/Headers/Headers';
import { NxTitleTab, NxGridList, NxFooter, NxLoader } from '@afiplfeed/itr-app';
import { useHistory, useLocation } from 'react-router';
import { ItrApiService, ItrAuthService } from '@afiplfeed/itr-ui';
import moment from 'moment';
import { NotificationManager } from "react-notifications";
export default function AccessLog() {
    let history = useHistory();
    let location = useLocation();
    const [loader, setLoader] = useState(false);
    useEffect(() => {
        setLoader(true);
        ItrAuthService.GetUserInfo().then(result => {
            if (result.Success == true) {
                ItrApiService.GET({ url: `Platform/Configuration/LogAnalytics/UserAccessLog/${location.state.userCode || result.result.userCode}` }).then(res => {
                    setLoader(false);
                    if (res.Success == true) {
                        if (res.result.isSuccess == true) {
                            if (res.result.data) {
                                splits(res.result.data, setData);
                            }
                        } else {
                            NotificationManager.error(res.result.message);
                        }
                    } else {
                        NotificationManager.error(res.message);
                    }
                })
            } else {
                NotificationManager.error(res.message);
            }
        })
    }, []);

    const splits = async (dests, setDatas) => {
        let arr = [];
        for (let value of dests) {
            let data = [value.loginDate ? moment(value.loginDate).format('MMM Do YYYY') : '-', value.totalSessionDuration || '-', value.sessionsLogin || '-'];
            let obj = { sendItem: value, data: data };
            arr = [...arr, obj]
        }
        setDatas(arr);
    }

    const [data, setData] = useState([])
    return (
        <div>
            <NxLoader state={loader} />
            <Headers />
            <NxTitleTab className="title-tab" title='Access Log' onCloseClick={() => history.goBack()} />
            <div className="mt-0 access-main">
                <NxGridList
                    enableSearch={true}
                    autoSearch={true}
                    enableAutoPagination={true}
                    showShowing={true}
                    header={[
                        {
                            name: 'Login Date',
                            sort: true
                        },
                        {
                            name: 'Total Session Duration',
                        },
                        {
                            name: '#Sessions / Login',
                            search: true
                        }
                    ]}
                    data={data}
                />
            </div>
            <NxFooter companyName="© 2022 Ithred Solutions" ExtraContent={[
                { name: 'Terms of Service' },
                { name: 'Privacy' },
            ]} />
        </div>
    )
}
