import { faCheckCircle, faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, Switch } from 'antd';
import React, { useState } from 'react'
import "./SecurityTwoFactor.scss";
import Headers from '../../Screens/Headers/Headers';
import { NxTitleTab } from '@afiplfeed/itr-app';
import { useHistory } from "react-router-dom";

export default function SecurityTwoFactor({ isModalVisible, handleOk, handleCancel }) {
    let history = useHistory()
    const [step, setStep] = useState(1);
    const [number, setNumber] = useState('');
    const [otp, setOtp] = useState('');
    const [loader, setLoader] = useState(false);
    const [enableFactor, setEnableFactor] = useState(false);

    return (
        <div>
            <Headers />
            <NxTitleTab className="title-tab" title="2-Factor Authentication" onCloseClick={() => history.goBack()} />
            {/* <Modal
                footer={false}
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
            > */}
            <div className="card password-policy-main mt-0">
                <div className="card-body p-0">
                    <div className="twoFactorModal">
                        <div className="header security-two-factor">
                            <div>
                                <h4 className='m-0 p-0'>
                                    Enable 2-Factor Authentication
                                </h4>
                                <p>2F/MF Auth can be enabled only for non-federated IAM</p>
                            </div>
                            <div>
                                <Switch checked={enableFactor} onChange={(e) => { setEnableFactor(e) }} />
                            </div>
                        </div>
                        {enableFactor && <div className='row'>
                            <div className='col-12 col-md-8 col-sm-12 col-lg-6 xol-xl-6'>
                                <div className='security-factor-authentication-forms'>
                                    <div className='mt-2 form-label'>
                                        <label>Select Comms. Channel</label>
                                        <select className='form-select form-select-sm'>
                                            <option value="Email">Email</option>
                                            <option value="SMS">SMS</option>
                                            <option value="WhatsApp">WhatsApp</option>
                                        </select>
                                    </div>
                                    <div className='mt-2 form-label'>
                                        <label>Pin length</label>
                                        <select className='form-select form-select-sm'>
                                            <option value="4">4</option>
                                            <option value="6">6</option>
                                        </select>
                                    </div>
                                    <div className='mt-2 form-label'>
                                        <label>Pin Type</label>
                                        <select className='form-select form-select-sm'>
                                            <option value="Alpha">Alpha</option>
                                            <option value="Numeric">Numeric</option>
                                            <option value="Alpha-Numeric">Alpha-Numeric</option>
                                        </select>
                                    </div>
                                    <div className='mt-2 form-label'>
                                        <label>Expiry Duration<span className='sec-label ps-1'>(Sec)</span></label>
                                        <div className='duration-label'>
                                            <input className='form-control form-control-sm' value={""} />
                                        </div>
                                    </div>
                                    <div className='mt-2 form-label'>
                                        <label>Resend Duration<span className='sec-label ps-1'>(Sec)</span></label>
                                        <div className='duration-label'>
                                            <input className='form-control form-control-sm' value={""} />
                                        </div>
                                    </div>

                                    <div>
                                        <div className="footer-profile-tab">
                                            <button className='btn'
                                                onClick={() => {
                                                    history.goBack();
                                                }}
                                            >
                                                Cancel
                                            </button>

                                            <button
                                                disabled={loader}
                                                className='btn btn-primary'
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                        {/* <div>
                        <div className="steps">
                            <div>
                                <FontAwesomeIcon icon={faCircle} style={{ color: "green", display: step != 1 && "none" }} />
                                <FontAwesomeIcon icon={faCheckCircle} style={{ color: "green", display: step == 1 && "none" }} />
                                Phone Number
                            </div>
                            <div>
                                <FontAwesomeIcon icon={faCircle} style={{ color: step == 1 ? "grey" : "green" }} />
                                Confirm
                            </div>
                        </div>
                        {step == 1 &&
                            <div>
                                <div className="content">
                                    <p>
                                        <span>
                                            Let's set up you phone!
                                        </span>
                                        <br />
                                    </p>
                                </div>
                                <div className="feilds">
                                    <div>
                                        <label > Country </label>
                                        <select className>
                                            <option>India</option>
                                        </select>
                                    </div>
                                    <div>
                                        <label>Phone Number</label>
                                        <input type='tel' value={number} className='form-control' onChange={(e) => {
                                            setNumber(e.target.value);
                                        }} />
                                    </div>
                                </div>
                                <div className="bottom">
                                    <p>Your Phone number will be used for account security purpose only!</p>
                                </div>
                            </div>
                        }
                        {step == 2 &&
                            <div>
                                <div className="content">
                                    <p>
                                        <span>
                                            Confirm that it works!
                                        </span>
                                        <br />
                                        Please enter the Phone Verification Code sent to your mobile number.
                                        <br />
                                    </p>
                                </div>
                                <div className="feilds2" style={{ display: 'flex', justifyContent: 'center' }}>
                                    <div>
                                        <label>Enter the code</label>
                                        <OtpInput
                                            containerStyle={{}}
                                            value={otp}
                                            onChange={(ot) => { setOtp(ot) }}
                                            numInputs={6}
                                            separator={<span> - </span>}
                                        />
                                    </div>
                                </div>
                                <div className="bottom">
                                    <p>Did not get the code Resend Now</p>
                                </div>
                            </div>
                        }
                        <div className="footer-profile-tab">
                            <button className='btn'
                                onClick={() => {
                                    if (step == 1) {
                                        handleCancel();
                                        setStep(1)
                                        setNumber('');
                                        setOtp('');
                                    } else {
                                        prevStep()
                                    }
                                }}
                            >
                                {step == 1 ? "Close" : "Back"}
                            </button>

                            <button
                                disabled={loader}
                                className='btn btn-primary'
                                onClick={() => {
                                    if (phone.phoneNumber == number) {
                                        if (step == 1) {
                                            setLoader(true)
                                            ItrApiService.POST({
                                                url: 'PAM/SendSMSVerification',
                                                data: {
                                                    userCode,
                                                    userName,
                                                    phonenumbers: number.toString()
                                                }
                                            }).then(res => {
                                                setLoader(false)
                                                if (res.Success == true) {
                                                    if (res.result.isSuccess == true) {
                                                        nextStep()
                                                    } else {
                                                        NotificationManager.error(res.result.message)
                                                    }
                                                } else {
                                                    NotificationManager.error(res.message)
                                                }
                                            })
                                        } else {
                                            setLoader(true)
                                            ItrApiService.POST({
                                                url: 'PAM/Enable2FA',
                                                data: {
                                                    userCode,
                                                    userName,
                                                    phonenumbers: number,
                                                    otpKey: otp,
                                                    isEnable2FA: phone.portalAccess.isEnable2FA == true ? false : true
                                                }
                                            }).then(res => {
                                                setLoader(false)
                                                if (res.Success == true) {
                                                    if (res.result.isSuccess == true) {
                                                        handleOk();
                                                        setStep(1)
                                                        setNumber('');
                                                        setOtp('');
                                                        getProfileDetails()
                                                        NotificationManager.success(res.result.message)

                                                    } else {
                                                        NotificationManager.error(res.result.message)
                                                    }
                                                } else {
                                                    NotificationManager.error(res.message)
                                                }
                                            })
                                        }

                                    }
                                    else {
                                        NotificationManager.error('Invalid Mobile Number')
                                    }
                                }}
                            >
                                {step == 1 ? "Continue" : phone.portalAccess.isEnable2FA == true ? "Turn Off" : "Turn On"}
                            </button>
                        </div>
                    </div> */}
                    </div>
                </div>
            </div>
            {/* </Modal> */}
        </div>
    )
}
