import React, { useState, useEffect } from 'react';
import './CommisionPlan.css';
import { useHistory } from 'react-router';
import Headers from '../../../Screens/Headers/Headers';
import { NxTitleTab, NxFooter, NxGridList, NxLoader } from '@afiplfeed/itr-app';
import { DatePicker, Progress, Tabs } from 'antd';
import { DashboardOutlined, FormOutlined, DollarOutlined } from '@ant-design/icons';
import { ItrApiService } from '@afiplfeed/itr-ui';
import moment from 'moment';
import { NotificationManager } from 'react-notifications';
import CustomerSidebar from '../../../Screens/CustomerSidebar/CustomerSidebar';

const splits = async (dests, setData) => {
    let arr2 = [];
    let arr = [];
    for (let value of dests) {
        let data = [value.commissionPlanName, value.commissionStructure];
        let obj = { sendItem: value, data: data };
        let obj2 = {
            'Billing Template': value.billingTemplateName,
            'Saved Date': moment(value.dataCreated).format('DD/MM/YYYY'),
            'Author': value.author || '',
            'No of Customers applied': value.noOfCustomer
        }
        arr2 = [...arr2, obj2];
        arr = [...arr, obj]
    }
    setData(arr)
}

export default function CommisionPlan() {

    let history = useHistory();
    const [loading, setLoading] = useState(false);

    useEffect( () => {
        setLoading(true);
        ItrApiService.GET({ url: 'RevOps/CommissionPlan/GetAllCommissionPlan' }).then(res => {
            if (res.Success == true) {
                if (res.result.isSuccess == true) {
                    if (res.result.data) {
                        splits(res.result.data, setData)
                    }
                } else {
                    NotificationManager.error(res.result.message);
                }
            } else {
                NotificationManager.error(res.message);
            }
            setLoading(false);
        })
    }, []);

    const [data, setData] = useState([]);

    return (
        <>
            <Headers />
            <NxLoader state={loading} />
            <div className='d-flex custom-body-container'>
                <div className='body-sidebar'>
                    <CustomerSidebar visible={true}  />
                </div>
                <div className='body-workspace'>
                    <NxTitleTab className="title-tab" title="Commision Plan" onCloseClick={() => history.push('/marketplace')} />

                    <div className="card1 mt-0 mx-3">
                        <div className="card-body1">
                            <div className="container-form mb-5">

                                <div className='d-flex justify-content-end align-items-center'>
                                    <button className='btn btn-sm' style={{ backgroundColor: 'rgb(82, 196, 26)', color: 'white', fontSize: '14px' }} onClick={() => history.push('/commision-plan/add')}> + Add Commision Template </button>
                                </div>
                                <div className="row mt-0 mx-0 mt-2">
                                    <NxGridList
                                        header={[
                                            {
                                                name: 'Commision Plan Name',
                                                sort: true,
                                            },
                                            {
                                                name: 'Commision Structure',
                                                search: true,
                                            },
                                        ]}
                                        data={data}
                                        enableSearch={true}
                                        autoSearch={true}
                                        enableAutoPagination={true}
                                        showShowing={true}
                                        rowClick={(data) => {
                                            history.push({
                                                pathname: '/commision-plan/edit',
                                                state: data
                                            });
                                        }}
                                    />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <NxFooter companyName="© 2023 Altascio Technologies (I) Pvt. Ltd.," ExtraContent={[
                { name: 'Terms of Service' },
                { name: 'Privacy' },
            ]} />
        </>
    )
}
