import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { NxTitleTab, NxCustomTab, NxLoader, NxFooter } from '@afiplfeed/itr-app';
import Headers from '../../../Screens/Headers/Headers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import AuthKeyImage from '../../../assets/Authkey.png';
import { ItrApiService } from '@afiplfeed/itr-ui';

export default function Notification() {
    const [loading, setLoading] = useState(false);
    // sms-gate-way
    const [gateway, setGateway] = useState({});
    const getSmsGateway = async () => {
        setLoading(true);
        let result = await ItrApiService.GET({
            url: 'Platform/Configuration/SMSGateway',
        });
        if (result.result.isSuccess == true) {
            let getGatewayDetails = result.result.data;
            if (result.result.data) {
                setGateway(getGatewayDetails);
            }
        }
        else if (result.result.isSuccess == false) {

        }
        setLoading(false);
    }

    // create-email-setup
    const createEmailSetup = async () => {
        setLoading(true);
        let res = await ItrApiService.POST({
            url: 'Platform/Configuration/Email',
            data: emailInfo
        });
        setLoading(false);
    }

    // Email-setup
    const [emailInfo, setEmailInfo] = useState({
        emailID: 0,
        mail: "",
        password: "",
        host: "",
        port: '',
        type: ""
    });

    const getEmailSetup = async () => {
        setLoading(true);
        let result = await ItrApiService.GET({
            url: 'Platform/Configuration/Email',
        });
        if (result.result.isSuccess == true) {
            let getEmailDetails = result.result.data;
            if (result.result.data) {
                setEmailInfo(getEmailDetails);
            }
        }
        else if (result.result.isSuccess == false) {
        }
        setLoading(false);
    }

    let history = useHistory();



    useEffect(() => {
        getEmailSetup()
        getSmsGateway();
    }, [])

    return (
        <div>
            <NxLoader state={loading} />
            <Headers />
            <NxTitleTab className="title-tab" title='Notification' onCloseClick={() => {
                history.push('/configuration');
            }} />
            <NxCustomTab
                data={[
                    // {
                    //     name:'Notification Policy',
                    //     Components:() => {
                    //         return (
                    //             <div className="container-form mb-5">
                    //                 <h4>Coming Soon</h4>
                    //             </div>
                    //         )
                    //     }
                    // },
                    {
                        name: 'Email Setup',
                        Components: () => {
                            return (
                                <div className="container-form mb-5">
                                    <div className="row">
                                        {/* <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"> */}
                                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                            <label htmlFor="firstName">Email</label>
                                            <input className="form-control mt-1" id="firstName" type="email"
                                                value={emailInfo.mail}
                                                onChange={(e) => setEmailInfo({ ...emailInfo, mail: e.target.value })} />
                                        </div>

                                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                            <label htmlFor="Organization">Password</label>
                                            <input className="form-control mt-1" id="Organization" type="password"
                                                value={emailInfo.password} onChange={(e) => setEmailInfo({ ...emailInfo, password: e.target.value })} />
                                        </div>

                                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                            <label htmlFor="Organization">Host</label>
                                            <input className="form-control mt-1" id="Organization" type="text"
                                                value={emailInfo.host}
                                                onChange={(e) => setEmailInfo({ ...emailInfo, host: e.target.value })} />
                                        </div>

                                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                            <label htmlFor="Organization">Port</label>
                                            <input className="form-control mt-1" id="Organization" type="number"
                                                value={emailInfo.port} onChange={(e) => { setEmailInfo({ ...emailInfo, port: parseInt(e.target.value) }); }} />
                                        </div>
                                        {/* </div> */}
                                        {/* 
                                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        </div> */}
                                    </div>

                                    <div className="form-button nx-enquiry-buttons mt-3 mb-5">
                                        <button className="btn btn-primary rounded-pill buttons" onClick={createEmailSetup}> Save</button>
                                    </div>
                                </div>
                            )
                        }
                    },
                    {
                        name: 'SMS Gateway',
                        Components: () => {
                            return (
                                <div className="container-form mb-5">
                                    <div>
                                        <div className="text-center">
                                            <h6 className="fw-bold">API Authorization Key</h6>
                                        </div>
                                        <div className="text-center mt-3">
                                            <img src={AuthKeyImage} width="100" />
                                            {/* <FontAwesomeIcon icon={faPlusCircle} size="5x" fontSize="20px" /> */}
                                        </div>
                                        <div className="row mt-4">
                                            {/* <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                <label htmlFor="Organization"> User Name</label>
                                                <input className="form-control mt-1" id="Organization" type="text" />
                                            </div> */}

                                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                <label htmlFor="Organization">Api Key</label>
                                                <input className="form-control mt-1" id="Organization" value={gateway?.apiKey} type="text" />
                                            </div>

                                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                <label htmlFor="Organization">Url</label>
                                                <input className="form-control mt-1" id="Organization" type="url" value={gateway?.url} />
                                            </div>
                                        </div>

                                        <div className="form-button nx-enquiry-buttons mt-3 mb-5">
                                            <button className="btn btn-primary rounded-pill buttons">Save</button>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    }
                ]} />
            <NxFooter ExtraContent={[
                { name: 'Terms of Service' },
                { name: 'privacy' },
            ]} />
        </div>
    )
}
