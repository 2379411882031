import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import './IdleTimeout.css';
import { NxLoader, NxTitleTab, NxFooter } from '@afiplfeed/itr-app';
import Headers from '../../../Screens/Headers/Headers';
import { ItrApiService } from '@afiplfeed/itr-ui';
import { Switch, message } from 'antd';
import { NotificationManager } from 'react-notifications';

export default function IdleTimeout() {
    let history = useHistory();
    const [loading, setLoading] = useState(false);

    const [gateway, setGateway] = useState({});
    const getIdleApi = async () => {
        setLoading(true);
        let result = await ItrApiService.GET({
            url: 'Platform/Configuration/IdleSessionSignOut',
        });
        if (result.Success == true) {
            if (result.result.isSuccess == true) {
                let vals = result.result.data;
                if (result.result.data) {
                    setData(vals);
                }
            } else {
                NotificationManager.error(result.result.message);
            }
        } else {
            NotificationManager.error(result.message);
        }
        setLoading(false);
    }

    const [data, setData] = useState({
        idleSessionSignOutID: 0,
        isIdleSessionSignOut: false,
        userSignOutTime: 0,
        userNoticeTime: 0
    });


    useEffect(() => {
        getIdleApi();
    }, [])
    return (
        <div>
            <NxLoader state={loading} />
            <Headers />
            <NxTitleTab className="title-tab" title='Session Management' onCloseClick={() => history.goBack()} />
            <div className="card mt-0 sms-gateway-main">
                <div className="card-body">
                    <div className="container-form mb-5">
                        <div>
                            <div className="row mt-4">
                                <span className='f-badge-title'>Idle Session Sign-Out.</span>
                                <span style={{ fontSize: 15, marginTop: 9 }}>Warn and then sign out users on unmanaged device after a period of inactivity. this setting applies when users don't select to stay signed in.</span><br />
                                <span style={{ fontWeight: 'bold', marginTop: 20, fontSize: 15 }} >Sign Out inactive users automatically</span>
                                <div className="col-12 col-sm-4 col-md-6 col-lg-6 col-xl-6  ">
                                    <div className='row'>
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-4">
                                            <Switch checked={data.isIdleSessionSignOut} onChange={(e) => setData({ ...data, isIdleSessionSignOut: e })} /> &nbsp; <label> On </label>
                                        </div>

                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-4">
                                            <label htmlFor="Organization"> Sign Out users after</label>
                                            {/* <input className="form-control mt-1" id="Organization" type="text" /> */}
                                            <select className="form-select form-select-sm" value={data.userSignOutTime} onChange={(e) => { setData({ ...data, userSignOutTime: e.target.value }) }} >
                                                <option value={0} selected disabled> -- Please Select -- </option>
                                                <option value={60}>1 hour</option>
                                                <option value={120}>2 hour</option>
                                                <option value={240}>4 hour</option>
                                            </select>
                                        </div>

                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-4">
                                            <label htmlFor="Organization">Give Users this much notice before signing them out</label>
                                            {/* <input className="form-control mt-1" id="Organization" value={gateway?.apiKey} type="text" /> */}
                                            <select className="form-select form-select-sm" value={data.userNoticeTime} onChange={(e) => { setData({ ...data, userNoticeTime: e.target.value }) }}>
                                                <option value={0} selected disabled> -- Please Select -- </option>
                                                <option value={5}>5 minutes</option>
                                                <option value={15}>15 minutes</option>
                                                <option value={30}>30 minutes</option>
                                                <option value={45}>45 minutes</option>
                                                <option value={60}>60 minutes</option>
                                            </select>
                                        </div>

                                        {/* <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                        <label htmlFor="Organization">Url</label>
                                        <input className="form-control mt-1" id="Organization" type="text" value={gateway?.url} />
                                    </div> */}

                                    </div>
                                </div>
                            </div>


                            <div className="form-button nx-enquiry-buttons mt-3 mb-5">
                                <button className="btn btn-primary btn-sm buttons" onClick={() => {
                                    let body = { ...data, userNoticeTime: parseInt(data.userNoticeTime), userSignOutTime: parseInt(data.userSignOutTime) };
                                    setLoading(true);
                                    ItrApiService.POST({ url: 'Platform/Configuration/IdleSessionSignOut', data: body }).then(res => {
                                        if (res.Success == true) {
                                            if (res.result.isSuccess == true) {
                                                message.success('Updated Successfully!')
                                            }
                                        }
                                        setLoading(false);
                                    })
                                }}>Save</button>
                                <button className="btn btn-primary btn-sm buttons mx-1" onClick={() => { history.goBack() }} >Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <NxFooter companyName="© 2022 Ithred Solutions" ExtraContent={[
                { name: 'Terms of Service' },
                { name: 'Privacy' },
            ]} />
        </div>
    )
}
