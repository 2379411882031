import React, { useEffect, useState } from 'react';
import './RepoConnect.css';
import { useHistory } from 'react-router';
import { NxTitleTab, NxFooter } from '@afiplfeed/itr-app';
import { DatePicker, Tabs } from 'antd';
import { DashboardOutlined, FormOutlined } from '@ant-design/icons';
import AzureRepo from './AzureRepo/AzureRepo';
import GitRepo from './GitRepo/GitRepo';
import Headers from '../../../../Screens/Headers/Headers';
import DockerHub from '../../../DockerHub/DockerHub';
import AzureContainerRegister from '../../../AzureContainerRegister/AzureContainerRegister';
import DatabaseServer from '../../../DatabaseServer/DatabaseServer';
import VirtualMachine from '../ManageResources/VirtualMachine/VirtualMachine';
import Storage from '../ManageResources/Storage/Storage';

const { RangePicker } = DatePicker;



export default function RepoConnect() {
    const { TabPane } = Tabs;
    let history = useHistory();

    const [activeString, setActiveString] = useState('1')
    useEffect(() => {
        let s = sessionStorage.getItem('rc');
        if (s) {
            setActiveString(s)
        }
    }, []);
    return (
        <>
            <Headers />
            <NxTitleTab className="title-tab" title="Resources" onCloseClick={() => {
                sessionStorage.removeItem('rc');
                history.goBack()
            }} />
            <div className="billing-tabs billing-main">
                <Tabs defaultActiveKey="1" type="card" activeKey={activeString} onChange={(e) => {
                    setActiveString(e);
                    sessionStorage.setItem('rc', e)
                }}>
                    <TabPane tab={<div className="d-flex justify-content-center px-2">
                        {/* <span ><FormOutlined /></span> */}
                        <span style={{ fontSize: '16px', paddingLeft: "12px", paddingRight: "12px" }}>Virtual Machines</span>
                    </div>} key="1">
                        <VirtualMachine />
                    </TabPane>
                    <TabPane tab={<div className="d-flex justify-content-center px-2">
                        {/* <span ><FormOutlined /></span> */}
                        <span style={{ fontSize: '16px', paddingLeft: "12px", paddingRight: "12px" }}>Storage Resources</span>
                    </div>} key="2">
                        <Storage />
                    </TabPane>
                    {/* <TabPane tab={
                        <div className="d-flex justify-content-center px-2">
                            <span ><DashboardOutlined /></span>
                            <span style={{ fontSize: '16px' }} >Azure Repo</span>
                        </div>
                    } key="1">
                        <AzureRepo />
                    </TabPane>
                    <TabPane tab={<div className="d-flex justify-content-center px-2">
                        <span ><FormOutlined /></span>
                        <span style={{ fontSize: '16px',paddingLeft:"12px",paddingRight:"12px" }}>GIT Repo</span>
                    </div>} key="2">
                        <GitRepo />
                    </TabPane>
                    <TabPane tab={<div className="d-flex justify-content-center px-2">
                        <span ><FormOutlined /></span>
                        <span style={{ fontSize: '16px',paddingLeft:"12px",paddingRight:"12px" }}>Azure Container Registry</span>
                    </div>} key="4">
                        <AzureContainerRegister />docker-connect
                    </TabPane>
                    <TabPane tab={<div className="d-flex justify-content-center px-2">
                        <span ><FormOutlined /></span>
                        <span style={{ fontSize: '16px' }}>Database Server</span>
                    </div>} key="5">
                        <DatabaseServer />
                    </TabPane> */}
                </Tabs>
            </div>
            <NxFooter companyName="© 2022 Ithred Solutions" ExtraContent={[
                { name: 'Terms of Service' },
                { name: 'Privacy' },
            ]} />
        </>
    )
}
