import React, { useState, useEffect } from 'react';
import './CompanyLicenseManagement.css';
import { useHistory } from 'react-router';
import { Tabs, Switch, Rate, Modal, PageHeader, Tag, Button, Tooltip, Alert, Space } from 'antd';
import { AppstoreOutlined, DashboardOutlined } from '@ant-design/icons';
import { ItrApiService } from '@afiplfeed/itr-ui'
import { NxLoader, NxTitleTab, NxFooter, NxGridList } from '@afiplfeed/itr-app';
import Headers from '../../../../../Screens/Headers/Headers';
import moment from 'moment';
import { NotificationManager } from "react-notifications";


const splits = async (dests, setData) => {
    let arr = [];
    for (let value of dests) {
        let data = [value.EntityName, value.ServiceName, value.VersionNo, value.TotalSubscribed, value.TotalAssigned || '0', value.Remaining];
        let obj = { sendItem: value, data: data };
        arr = [...arr, obj]
    }
    setData(arr)
}


export default function CompanyLicenseManagement() {
    const { TabPane } = Tabs;
    let history = useHistory();
    // subscription 

    const [subscriptionData, setSubscriptionData] = useState([]);
    const [subscriptionId, setSubscriptionId] = useState('');
    const [clientId, setClientid] = useState('');
    const [enrollmentId, setEnrollmentId] = useState('');
    const [loader, setLoader] = useState(false);

    // list-Subscription-Api
    const getData = async () => {
        let result = await ItrApiService.GET({
            url: 'RevOps/EntitySubscription/CompanyWiseSubscriptionList'
        });
        if (result.result.isSuccess == true) {
            let dataVal = result.result.data;
            if (dataVal === null || !dataVal.length) {
                // NotificationManager.error(result.result.message);
            }
            else {
                // setData()
                splits(dataVal, setData);
                setSubscriptionData(dataVal);
            }
        }
        else if (result.result.isSuccess == false) {
            NotificationManager.error(result.result.message);
        }
    }

    const initiate = async () => {
        setLoader(true);
        await getData();
        setLoader(false);
    }

    useEffect(() => {
        initiate();
    }, [])

    const [data, setData] = useState([])


    return (
        <>
            <NxLoader state={loader} />
            {/* <Headers /> */}
            {/* <NxTitleTab className="title-tab" title="License Vault" onCloseClick={() => history.goBack()} /> */}
            <div className="mt-0 apps-store-main">
                <div className="container1 mt-1 nx-subscription-page mb-5">
                    <div className='table-responsive'>
                        <NxGridList
                            enableSearch={true}
                            autoSearch={true}
                            enableAutoPagination={true}
                            showShowing={true}
                            header={[
                                {
                                    name: 'Company Name',
                                    sort: true,
                                    search: true
                                },
                                {
                                    name: 'Service Name',
                                    sort: true,
                                    search: true
                                },
                                {
                                    name: 'Version Number',
                                    sort: false,
                                },
                                {
                                    name: 'Total Subscribed',
                                    sort: false,
                                },
                                {
                                    name: 'Assigned'
                                },
                                {
                                    name: 'Remaining'
                                }
                            ]}
                            rowClick={(datas) => {
                                showLicenseTable(datas);
                            }}
                            data={data}
                        />
                    </div>
                </div>
                <div style={{ height: 30 }} />

            </div >

            <NxFooter companyName="© 2022 Ithred Solutions" ExtraContent={[
                { name: 'Terms of Service' },
                { name: 'Privacy' },
            ]} />
        </>
    )
}
