import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { NxLoader, NxActionTab, NxTitleTab, NxGridList } from '@afiplfeed/itr-app'
import { ItrApiService, ItrAuthService } from '@afiplfeed/itr-ui';
import moment from 'moment';
import { NotificationManager } from "react-notifications";

const splits = async (dests, setEnquiry, setExport,history) => {
    let arr = [];
    let arr2 = [];
    ItrApiService.userApp().then(data => {
    })
    for (let value of dests) {
        let data = [moment(value.createdDate).format('DD/MM/YYYY'), value.subject, value.appCode, value.status,
        <button className="btn btn-sm btn-primary" style={{ fontSize: '14px', borderRadius: '0' }}
            onClick={(e) => {
                sessionStorage.setItem('editSupportId', value.supportID);
                e.stopPropagation();
                history.push('/support/edit');
            }}>Edit</button>];
        let obj = { sendItem: value, data: data };
        let obj2 = {
            'Date': moment(value.createdDate).format('DD/MM/YYYY'),
            'Subject': value.subject,
            'App Name': value.appCode,
            'Status': value.status
        }
        arr2 = [...arr2, obj2];
        arr = [...arr, obj]
    }
    setEnquiry(arr);
    setExport(arr2)
}





function TicketsList() {

    let history = useHistory();
    const [exportVal, setExport] = useState([]);
    const [enquiry, setEnquiry] = useState([]);

    useEffect(() => {
        setLoad(true)
        getEnquiry();
    }, [])

    const getEnquiry = async () => {
        let result = await ItrApiService.GET({
            url: 'ITOps/Support'
        });
        if (result.Success == true) {
            let getCustomerDetails = result.result.data;
            if (getCustomerDetails === null || !getCustomerDetails.length) {
                // NotificationManager.error(result.result.message);
            }
            else {
                await splits(getCustomerDetails, setEnquiry, setExport,history);
                // await setEnquiryEdit(getCustomerDetails);
            }
        }
        else if (result.result.isSuccess == false) {
            NotificationManager.error(result.result.message);
            // sessionStorage.clear();
            // history.push('/')
        }
        setLoad(false)
    }
    const [load, setLoad] = useState(false);
    return (
        <>
            <NxLoader state={load} />
            <div>
                <div className="d-flex justify-content-between align-items-center">
                    <NxActionTab
                        exportValue={exportVal}
                        fileName='Tickets'
                        className='resource-groups-list-action-tab p-0'
                        onColumnsClick={() => { }}
                        onRefreshClick={() => {
                            setLoad(true)
                            setTimeout(function () {
                                setLoad(false)
                            }, 2000);
                        }}
                        onNewEntryClick={() => {
                            history.push('/support/add');
                        }}
                        enableNewEntry={false}
                    />
                    <button className='btn btn-sm' style={{ backgroundColor: 'rgb(82, 196, 26)', color: 'white', fontSize: '14px' }} onClick={() => history.push('/support/add')}>New Request</button>
                </div>
                <NxGridList
                    enableSearch={true}
                    autoSearch={true}
                    enableAutoPagination={true}
                    showShowing={true}
                    header={[
                        {
                            name: 'Date',
                            sort: true,
                        },
                        {
                            name: 'Subject',
                            sort: true,
                            search:true
                        },
                        {
                            name: 'App Name',
                            search: true
                            // sort: true,
                        },
                        {
                            name: 'Status',
                            sort: false,
                            search:true
                        },
                        {
                            name: 'Action',
                            sort: false,
                        }
                    ]}
                    rowClick={(e) => {
                    }}
                    data={enquiry} />
            </div>
        </>
    );
}

export default TicketsList;
