import React, { useEffect, useState } from "react";
import './AzureContainerRegisterAdd.css';
import { useHistory } from 'react-router-dom';
import { NxLoader, NxTitleTab } from '@afiplfeed/itr-app';
import { ItrApiService, ItrAuthService } from '@afiplfeed/itr-ui';
import Headers from "../../Screens/Headers/Headers";


export default function AzureContainerRegisterAdd() {
    const Buttons = () => {
        return <div className="form-button nx-enquiry-buttons mt-4 mb-4">
            <button className="btn btn-sm btn-primary buttons" onClick={async () => {
                setLoading(true)
                ItrApiService.POST({
                    url: 'CloudConnect/Configuration/AzureContainerRegistry',
                    data: data
                }).then(res => {
                    if (res.Success == true) {
                        if (res.result.isSuccess == true) {
                            history.goBack();
                        }
                    }
                    setLoading(false);
                })
            }} > Save</button>
            <button className="btn btn-sm close btn-outline-primary" onClick={() => history.goBack()}> Close</button>
        </div>
    }

    const [data, setData] = useState({
        acrName: '',
        osType: '',
        sku: '',
        crType: ''
    })

    useEffect(() => {

    }, [])


    let history = useHistory();
    const [loading, setLoading] = useState(false)
    return (
        <div>
            <NxLoader state={loading} />
            <Headers />
            <div className="customer-dynamic-pages pt-4 ">
                <NxTitleTab className="title-tab page-title" title='Add Azure Container Registry' onCloseClick={() => {
                    history.goBack();
                }} />
                <div className="resource-groups-add-main-form mt-2">
                    <div className="card resource-groups-add-card-form">
                        <div className="card-body">
                            <div className="container-form">
                                <div className="row">
                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                        <div className="row">

                                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                <label htmlFor="name" className='f-badge-title'>ACR Name</label>
                                                <input className="form-control form-control-sm" id="name" type="text" value={data.acrName} onChange={(e) => {
                                                    setData({ ...data, acrName: e.target.value })
                                                }} />
                                            </div>

                                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                <label htmlFor="adminPin" className='f-badge-title'>OS Type</label>
                                                <select className='form-select form-select-sm' value={data.osType}
                                                    onChange={(e) => setData({ ...data, osType: e.target.value })}>
                                                    <option value="" selected disabled> -- Please Select -- </option>
                                                    <option value="Windows"> Windows </option>
                                                    <option value="Linux"> Linux  </option>
                                                </select>
                                            </div>

                                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-3 mb-4">
                                                <label htmlFor="SubscriptionId" className='f-badge-title'>SKU</label>
                                                <select className='form-select form-select-sm' value={data.sku}
                                                    onChange={(e) => setData({ ...data, sku: e.target.value })}>
                                                    <option value="" selected disabled> -- Please Select -- </option>
                                                    <option value="Basic"> Basic </option>
                                                    <option value="Standard"> Standard  </option>
                                                    <option value="Premium"> Premium  </option>
                                                </select>
                                            </div>

                                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-3 mb-4">
                                                <label htmlFor="ClientId" className='f-badge-title'>CR Type</label>
                                                <select className='form-select form-select-sm' value={data.crType}
                                                    onChange={(e) => setData({ ...data, crType: e.target.value })}>
                                                    <option value="" selected disabled> -- Please Select -- </option>
                                                    <option value="Public"> Public </option>
                                                    <option value="Private"> Private  </option>
                                                </select>
                                            </div>

                                            {/* <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                <label htmlFor="clientSecret" className='f-badge-title'>Client Secret</label>
                                                <input className="form-control form-control-sm" id="clientSecret" type='password' value={data.clientSecret} onChange={(e) => {
                                                    setData({ ...data, clientSecret: e.target.value })
                                                }} />
                                            </div>

                                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2">
                                                <label htmlFor="TenantId" className='f-badge-title'>Tenant ID</label>
                                                <input className="form-control form-control-sm" id="TenantId" type="text" value={data.tenantID} onChange={(e) => {
                                                    setData({ ...data, tenantID: e.target.value })
                                                }} />
                                            </div> */}


                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2"></div>
                                    <hr />
                                    <Buttons />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}