import React, { useEffect, useState } from 'react';
import './userAudit.css';
import { NxGridList, NxTitleTab, NxFooter, NxLoader } from '@afiplfeed/itr-app';
import Headers from '../../../../Screens/Headers/Headers';
import { useHistory } from 'react-router';
import { ItrApiService } from '@afiplfeed/itr-ui';
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { NotificationManager } from "react-notifications";
export default function userAudit() {
    let history = useHistory();
    useEffect(() => {
        setLoader(true);
        ItrApiService.GET({ data: {}, url: 'Platform/Configuration/LogAnalytics/UserAudit' }).then(res => {
            setLoader(false)
            if (res.Success == true) {
                if (res.result.isSuccess == true) {
                    if (res.result.data) {
                        splits(res.result.data, setData);
                    }
                } else {
                    NotificationManager.error(res.result.message);
                }
            } else {
                NotificationManager.error(res.message);
            }
        })
    }, []);

    const splits = async (dests, setDatas) => {
        let arr = [];
        for (let value of dests) {
            let data = [
                value.entityName || '',
                value.displayName || '-',
                value.emailAddress || '-',
                value.firstLogin ? moment(value.firstLogin).format('MMM Do YYYY') : '-',
                value.lastLogin ? moment(value.lastLogin).format('MMM Do YYYY') : '-',
                value.isEnable2FA == true ? 'Active' : 'Not Set',
                value.minDuration || '-',
                value.maxDuration || '-',
                value.maxDuration || '-',
                value.lastSession || '-',
                <div>
                    <FontAwesomeIcon icon={faEye} onClick={() => {
                        history.push({
                            pathname: '/access-log',
                            state: {
                                userCode: value.userCode
                            }
                        })
                    }} />
                </div>,
            ];
            let obj = { sendItem: value, data: data };
            arr = [...arr, obj]
        }
        setDatas(arr);
    }

    const [data, setData] = useState([]);
    const [loader, setLoader] = useState(false);


    return (
        <div>
            <Headers />
            <NxLoader state={loader} />
            <NxTitleTab className="title-tab" title='User Audit' onCloseClick={() => history.goBack()} />
            <div className="mt-0 audit-main">
                <NxGridList
                    enableSearch={true}
                    autoSearch={true}
                    enableAutoPagination={true}
                    showShowing={true}
                    header={[
                        {
                            name: 'Customer Name',
                            sort: true,
                            search: true,
                        },
                        {
                            name: 'First Name',
                            sort: true,
                            search: true,
                        },
                        {
                            name: 'Email/Username',
                            search: true,
                        },
                        {
                            name: 'First Login',
                            sort: true,
                        },
                        {
                            name: 'Last Login',
                            sort: true,
                        },
                        {
                            name: '2FA Enabled',
                            sort: true,
                        },
                        {
                            name: 'Min Duration',
                            sort: true
                        },
                        {
                            name: 'Max Duration',
                            sort: true,
                        },
                        {
                            name: 'Avg Duration',
                            sort: true,
                        },
                        {
                            name: 'Last Session',
                            sort: true,
                        },
                        {
                            name: 'Action'
                        }

                    ]}
                    data={data}

                />
            </div>
            <NxFooter companyName="© 2022 Ithred Solutions" ExtraContent={[
                { name: 'Terms of Service' },
                { name: 'Privacy' },
            ]} />
        </div>
    )
}
