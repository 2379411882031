import React, { useEffect, useState } from "react";
// import './style.css';
import { useHistory } from 'react-router-dom';
import { NxLoader, NxBreadcrumb, NxTitleTab, NxActionTab, NxGridList, NxRightDrawer, NxFooter } from '@afiplfeed/itr-app';
import { ItrApiService } from '@afiplfeed/itr-ui';
import Headers from "../../../Screens/Headers/Headers";
import { NotificationManager } from "react-notifications";
const splits = async (dests, setEnquiry) => {
    let arr = [];
    for (let value of dests) {
        let data = [value.firstName, value.lastName, value.officialEmail, value.phone, value.organization, value.status];
        let obj = { sendItem: value, data: data };
        arr = [...arr, obj]
    }
    setEnquiry(arr);
}

export default function AddPlatformUser() {
    let history = useHistory();
    const [load, setLoad] = useState(false);
    const [handel, setHandel] = useState(false);
    const [handel2, setHandel2] = useState(false);


    // const [enquiry,setEnquiry] = useState([]);
    // const [enquiryEdit,setEnquiryEdit] = useState('');

    //     const getEnquiry = async() => {
    //         let result = await ItrApiService.GET({
    //             url:'TenantPlatform/Enquiry/GetEnquires',
    //             customHeaders:[]
    //         });
    //         if(result.Success == true){
    //             let getEnquiryDetails = result.result.data;
    //             if(getEnquiryDetails === null || !getEnquiryDetails.length){
    //                 // NotificationManager.error(result.result.message);
    //             }
    //             else{
    //                 await splits(getEnquiryDetails, setEnquiry);
    //                 await setEnquiryEdit(getEnquiryDetails)
    //             }
    //             // setEnquiry(getEnquiryDetails);
    //         }
    //         else if(result.result.isSuccess == false) {
    //             NotificationManager.error(result.result.message);
    //             // sessionStorage.clear();
    //             // history.push('/')
    //         }
    //     }
    // 
    //     useEffect( async() => {
    //         setLoad(true);
    //         await getEnquiry();
    //         setLoad(false);
    //         // await splits(dest, setEnquiry);
    //     },[])

    return (
        <div>
            <NxLoader state={load} />
            <Headers />
            {/* <NxBreadcrumb className="navigation-breadcrumb" data={[
                {
                    name: 'Home',
                    onClick: () => {
                        history.push('/partner-board');
                    }
                }
            ]} /> */}
            <div style={{ flex: 1 }}>
                <NxTitleTab className="title-tab" title='Platform User' onCloseClick={() => {
                    history.push('/partner-board');
                }} />
                <NxActionTab className="enquiry-action-tab"
                    onColumnsClick={() => { setHandel(true) }}
                    onRefreshClick={() => {
                        setLoad(true)
                        setTimeout(function () {
                            setLoad(false)
                        }, 2000);
                    }}
                    onNewEntryClick={() => { history.push('/platformUser/add') }}
                    enableNewEntry={true}
                />
                <div className="p-4 pt-0">
                    <NxGridList
                        enableSearch={true}
                        autoSearch={true}
                        enableAutoPagination={true}
                        showShowing={true}
                        header={[
                            {
                                name: 'First Name',
                                sort: true,
                                search: true
                            },
                            {
                                name: 'Last Name',
                                sort: true,
                                search: true
                            },
                            {
                                name: 'Email',
                                sort: false,
                            },
                            {
                                name: 'Phone',
                                sort: false,
                            },
                        ]}
                        rowClick={(data) => {

                            history.push({
                                pathname: '/enquiry/edit',
                                state: {
                                    editData: data
                                }
                            });
                        }}
                    // data={enquiry}
                    // {enquiry}
                    // {[
                    //     { sendItem: '1', data: ['test', 'user', 'ss@mail.com user', '9002000201', 'bytes', 'active'] },
                    //     // { sendItem: '2', data: ['test1', 'user1', 'ss1@mail.com user', '9002000201', 'bytes', 'active'] }
                    // ]} 
                    />
                </div>
                <NxRightDrawer onClose={() => setHandel(false)} show={handel} drawerContent={() => <div>

                </div>} ondrawer1Close={() => { setHandel2(false) }} />
            </div>

            <NxFooter ExtraContent={[
                { name: 'Terms of Service' },
                { name: 'privacy' },
            ]} />
        </div>
    )
}