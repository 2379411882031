import { NxGridList } from '@afiplfeed/itr-app';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import './Style.css'
function VersionHistory({ vh }) {
    const splits = async (dests, setEnquiry) => {
        let arr = [];
        if (dests && dests.versionHistories && dests.versionHistories.length > 0) {
            for (let value of dests.versionHistories) {
                let data = [value.appVersion, moment(value.publishedDate).format('MM/DD/YYYY')];
                let obj = { sendItem: value, data: data };
                arr = [...arr, obj]
            }
            setEnquiry(arr)
        } else {
            setEnquiry([{ sendItem: '0', data: ['-', '-'] }])
        }
    }
    const [data, setData] = useState([])
    useEffect(() => {
        splits(vh, setData);
    }, [vh]);
    return (
        <NxGridList
            enableSearch={false}
            enableAutoPagination={false}
            showShowing={false}
            className='gr-pad p-0'
            header={[
                {
                    name: 'Version',
                },
                {
                    name: 'Published Date',
                    sort: true,
                },
            ]}
            data={data}
        />
    );
}

export default VersionHistory;