import React, { useEffect, useState } from 'react';
import './support.css';
import { useHistory } from 'react-router-dom';
import NxCustomLineChart from '../../../components/nxCharts/CustomCharts/NxCustomLineChart';
import {ChartColors} from '../../../general/general';
import NxBarCharts from '../../../components/nxCharts/nxBarCharts'
import { NxGridList, NxLoader, NxTitleTab, NxFooter } from '@afiplfeed/itr-app';
import { DashboardOutlined } from '@ant-design/icons';
import { ItrApiService } from '@afiplfeed/itr-ui';

// chart-guage
//import 'zingchart/es6';
// import 'zingchart-react/dist/modules-es6/zingchart-depth.min.js';
//import ZingChart from 'zingchart-react';
import Headers from '../../../Screens/Headers/Headers';
import { Tabs, Switch, Rate, Modal, PageHeader, Tag, Button, Tooltip, Alert, Space } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTicketAlt } from '@fortawesome/free-solid-svg-icons';
import TicketsList from '../TicketsList/TicketsList';
import ReactSpeedometer from 'react-d3-speedometer';
import { NotificationManager } from 'react-notifications';
import CustomerSidebar from '../../../Screens/CustomerSidebar/CustomerSidebar';
import APIEndPoints from './apiendpoints';
import HelpDocs from './helpdocs';

export default function Support() {

    const { TabPane } = Tabs;

    const [support, setSupport] = useState([]);
    const [loading, setLoading] = useState(false);
    const [casCountLabel, setCaseCountLabel] = useState([]);
    const [caseCountDataset, setCaseCountDataset] = useState([]);

    const splits = async (dests, setEnquiry) => {
        let arr = [];
        for (let value of dests) {
            let data = [value.feedback, value.priority, value.reporterTo, value.status, value.subject,];
            let obj = { sendItem: value.enquiryid, data: data };
            arr = [...arr, obj]
        }
        setEnquiry(arr)
    }

    const Csat = {
        config: {
            type: 'gauge',
            height: '240',
            width: '100%',
            series: [{
                values: [92],
                text: "CSAT",
                'background-color': "red",
                csize: "5%",     //Needle Width
                size: "92%",
            },
            {
                'scale-r': {
                    aperture: 20,     //Specify your scale range.
                    values: "0:100:20" //Provide min/max/step scale values.
                }
            },
            ],
            plot: {
                'value-box': { //Value Boxes
                    placement: "center",  //Specify placement at "center", "tip", or "edge".
                    text: "%v%",
                    'font-color': "blue"
                }
            },
        }
    }

    const NPS = {
        config: {
            type: 'gauge',
            height: 240,
            width: '100%',
            series: [{
                values: [98],
                text: "NPS",
                'background-color': "red",
                csize: "5%",     //Needle Width
                size: "92%",
            },
            {
                'scale-r': {
                    aperture: 20,     //Specify your scale range.
                    values: "0:100:20" //Provide min/max/step scale values.
                }
            }],
            plot: {
                'value-box': { //Value Boxes
                    placement: "center",  //Specify placement at "center", "tip", or "edge".
                    text: "%v%",
                    'font-color': "blue"
                }
            },
        }
    }

    const getSupport = async () => {
        setLoading(true);
        let result = await ItrApiService.GET({
            url: 'ITOps/Support'
        });
        if (result.Success == true) {
            if (result.result.isSuccess == true) {
                let getSupportDetails = result.result.data;
                if (result.result.data) {
                    await splits(getSupportDetails, setSupport)
                }
                // setEnquiry(getCustomerDetails);
            } else {
                NotificationManager.error(result.result.message)
            }
        }
        else if (result.Success == false) {
            NotificationManager.error(result.message);
            // sessionStorage.clear();
            // history.push('/')
        }
        setLoading(false);
    }

    // useEffect( async() => {
    //     setLoading(true);
    //     await getSupport(setSupport);
    //     setLoading(false);
    // }, [])

    let history = useHistory();


    const [activeString, setActiveString] = useState('1');


    const [supportData, setSupportData] = useState({
        critical: '',
        high: '',
        low: '',
        medium: '',
    });

    const [severityData, setSeverityData] = useState({
        open: '',
        inProgress: '',
        close: '',
    });

    const getIndicators = () => {
        ItrApiService.GET({
            url: 'ITOps/Support/GetSupportDashBoardCount'
        }).then((res) => {
            if (res.Success == true) {
                if (res.result.isSuccess == true) {
                    let caseCountChartLabel = [];
                    let caseCountChartDataSet = [];
                    let obj = { ...severityData }
                    let obj1 = { ...supportData }
                    let dataObj = {};
                    if(res.result.data) {
                        for (let data of res.result.data.supportStatus) {
                            if (data.categoryName == 'Open') {
                                obj = { ...obj, open: data.categoryCount }
                            }
                            else if (data.categoryName == 'InProgress') {
                                obj = { ...obj, inProgress: data.categoryCount }
                            }
                            else if (data.categoryName == 'Close') {
                                obj = { ...obj, close: data.categoryCount }
                            }
                        }
                        for (let data of res.result.data.supportPrority) {
                            if (data.categoryName == 'Critical') {
                                obj1 = { ...obj1, critical: data.categoryCount }
                            }
                            else if (data.categoryName == 'High') {
                                obj1 = { ...obj1, high: data.categoryCount }
                            }
                            else if (data.categoryName == 'Low') {
                                obj1 = { ...obj1, medium: data.categoryCount }
                            }
                            else if (data.categoryName == 'Medium') {
                                obj1 = { ...obj1, low: data.categoryCount }
                            }
                        }
                        setSeverityData(obj);
                        setSupportData(obj1);
                        let tmpChartColors = [ChartColors.Splash, ChartColors.Moonshine, ChartColors.Sky];
                        let chartColorCnt = 0;
                        for(let caseCount of res.result.data.periodCount){
                            caseCountChartLabel.push(caseCount.periodName);
                            for(let catCnt of caseCount.currentPeriodCount){
                                if(dataObj.hasOwnProperty(catCnt.categoryName)){
                                    dataObj[catCnt.categoryName].data.push(catCnt.categoryCount);
                                } else{
                                    dataObj[catCnt.categoryName] = {
                                        label:catCnt.categoryName,
                                        data:[],
                                        backgroundColor: [
                                            tmpChartColors[chartColorCnt],
                                            tmpChartColors[chartColorCnt],
                                            tmpChartColors[chartColorCnt]
                                          ]
                                    };
                                    chartColorCnt++;
                                    if(chartColorCnt > tmpChartColors.length) chartColorCnt = 0;
                                    dataObj[catCnt.categoryName].data.push(catCnt.categoryCount);
                                }
                            }
                        }
                        if(res.result.data.periodCount.length > 0){
                            let firstCaseCount = res.result.data.periodCount[0];
                            for(let catCnt of firstCaseCount.currentPeriodCount){
                                caseCountChartDataSet.push(dataObj[catCnt.categoryName]);
                            }
                            setCaseCountLabel(caseCountChartLabel);
                            setCaseCountDataset(caseCountChartDataSet);
                        }
                    }
                }
            }
        })
    }





    useEffect(() => {
        let s = sessionStorage.getItem('sp');

        if (s) {
            setActiveString(s)
        }

        getIndicators();
    }, []);
    // sizzq4cbukpghkgzkylg7tanu42ui4rvyqzuuf4b5slm3pnzpbvq
    // c2l6enE0Y2J1a3BnaGtnemt5bGc3dGFudTQydWk0cnZ5cXp1dWY0YjVzbG0zcG56cGJ2cQ==

    return (
        <>
            <Headers />
            <div className='d-flex custom-body-container'>
                <div className='body-sidebar'>
                    <CustomerSidebar visible={true}  />
                </div>
                <div className='body-workspace'>
                    <NxTitleTab className="title-tab" title="Support" onCloseClick={() => {
                        sessionStorage.removeItem('sp');
                        history.push('/dashboard');
                    }} />
                    <div className='support-main'>
                        <NxLoader state={loading} />
                        <div className="mt-0 support-main">
                            <div className='row'>
                                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>

                                </div>
                            </div>

                            <Tabs activeKey={activeString} onChange={(e) => {
                                setActiveString(e);
                                sessionStorage.setItem('sp', e)
                            }}>
                                <TabPane tab={
                                    <div className="d-flex justify-content-center">
                                        <span ><DashboardOutlined /></span>
                                        <span style={{ fontSize: '16px' }} >Dashboard</span>
                                    </div>
                                } key="1">
                                    <div className="nx-support">
                                        <div className="nx-support-body">
                                            <div className="row">
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 counts count-one">
                                                    {/* <label className="nx-badge-title">CURRENT</label> */}
                                                    <div className="row">
                                                        <div className="col-12 col-sm-4 col-md-4 col-lg-4 mt-4 text-center customer-support" title="New Cases">
                                                            <div className="card">
                                                                <div className="card-body">
                                                                    <label className='fw-bold'> Open </label>
                                                                    {/* <label className='fw-bold'>New support tickets <br /> Created</label> */}
                                                                    <h3 className='supports-counters'> {severityData.open} </h3>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-sm-4 col-md-4 col-lg-4 mt-4 text-center customer-support" title="Working Cases">
                                                            <div className="card">
                                                                <div className="card-body">
                                                                    <label className='fw-bold'> InProgress </label>
                                                                    {/* <label className='fw-bold'>Support tickets Resolved <br /> First-pass</label> */}
                                                                    <h3 className='supports-counters'> {severityData.inProgress} </h3>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-sm-4 col-md-4 col-lg-4 mt-4 text-center customer-support" title="Cases">
                                                            <div className="card">
                                                                <div className="card-body">
                                                                    <label className='fw-bold'> Close </label>
                                                                    {/* <label className='fw-bold'>Support tickets <br /> Re-Opened</label> */}
                                                                    <h3 className='supports-counters'> {severityData.close} </h3>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2'>
                                                            <div className="nx-support-cases mt-5">
                                                                <h6 className='text-center'> Open Cases By Severity </h6>
                                                                <div className="nx-progress mt-4">
                                                                    <div class="progress">
                                                                        <div class="progress-bar" role="progressbar" style={{ width: '20%', background: '#d36a67' }} aria-valuenow="50" aria-valuemin="50" aria-valuemax="100">
                                                                            Critical ( {supportData.critical} )
                                                                        </div>

                                                                        <div class="progress-bar" role="progressbar" style={{ width: '45%', background: '#e6994c' }} aria-valuenow="45" aria-valuemin="45" aria-valuemax="100">
                                                                            High ( {supportData.high} )
                                                                        </div>

                                                                        <div class="progress-bar" role="progressbar" style={{ width: '15%', background: '#e8cd67' }} aria-valuenow="15" aria-valuemin="15" aria-valuemax="100">
                                                                            Medium ( {supportData.medium} )
                                                                        </div>

                                                                        <div class="progress-bar" role="progressbar" style={{ width: '20%', background: '#73a562' }} aria-valuenow="20" aria-valuemin="20" aria-valuemax="100">
                                                                            Low ( {supportData.low} )
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2 mb-4">
                                                            <h6 className='text-center'> Case Count By Month </h6>
                                                            <div className="card mt-2">
                                                                <div className="card-body">
                                                                <NxCustomLineChart label={casCountLabel} height="320" width="200" datasets={caseCountDataset} />
                                                                {/* <NxBarCharts height="320" width="200" />*/}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 counts count-one text-center">
                                                    {/* <label className="nx-support-counts-title">Performance Metrics</label> */}
                                                    {/* <div className="row mt-3">
                                                        <div className="col-12 col-sm-12 col-md-6 col-lg-12 col-xl-6 mt-2 text-center customer-support" title="Customer Satisfaction">
                                                            <div className="card">
                                                                <div className="card-body">
                                                                    <label className='fw-bold'>Customer Satisfaction</label>
                                                                    <ReactSpeedometer
                                                                        value={475}
                                                                        needleColor="black"
                                                                        needleTransitionDuration={4000}
                                                                        needleTransition="easeElastic"
                                                                        currentValueText="CSI: ${value}"
                                                                        textColor='#172996'
                                                                        startColor='#ed0022'
                                                                        endColor='#009a60'
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-sm-12 col-md-6 col-lg-12 col-xl-6 mt-2 text-center customer-support" title="Net Promoter Score">
                                                            <div className="card">
                                                                <div className="card-body">
                                                                    <label className='fw-bold'>Net Promoter Score</label>
                                                                    <ReactSpeedometer
                                                                        value={680}
                                                                        needleColor="black"
                                                                        needleTransitionDuration={4000}
                                                                        needleTransition="easeElastic"
                                                                        currentValueText="NPS: ${value}"
                                                                        textColor='#172996'
                                                                        startColor='#ed0022'
                                                                        endColor='#009a60'
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-sm-4 col-md-2 col-lg-4 mt-2 text-center customer-support" title="Feedback"                                         >
                                                            <div className="card">
                                                                <div className="card-body">
                                                                    <label>Avg. CSAT</label>
                                                                    <h5>24</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}

                                                    {/* <div className="row mt-3">
                                                        <h6> Performance Indicator </h6>
                                                        <div className="col-12 col-sm-4 col-md-2 col-lg-6 col-xl-6 mt-2 text-center customer-support" title="Customer Satisfaction">
                                                            <div className="card">
                                                                <div className="card-body">
                                                                    <label className='fw-bold'>Average First Response Time</label>
                                                                    <h3 className='supports-counters'>8.7 Mins</h3>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-sm-4 col-md-2 col-lg-6 col-xl-6 mt-2 text-center customer-support" title="Net Promoter Score">
                                                            <div className="card">
                                                                <div className="card-body">
                                                                    <label className='fw-bold'>Average Resolution Time</label>
                                                                    <h3 className='supports-counters'>4 Hrs</h3>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3 ">
                                                            <h6> Leader Board </h6>
                                                            <div class="table-wrapper-scroll-y table-responsive my-custom-scrollbar">
                                                                <table class="table table-bordered mb-0">
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col">Agent</th>
                                                                            <th scope="col">Res %</th>
                                                                            <th scope="col">Open Cases</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <th scope="row">Harshal Mathur</th>
                                                                            <td>
                                                                                <div class="progress table-progress">
                                                                                    <div class="progress-bar" role="progressbar" style={{ width: "70%", height: '100%', background: '#d36a67' }} aria-valuenow="15" aria-valuemin="0" aria-valuemax="100">70</div>
                                                                                </div>
                                                                            </td>
                                                                            <td>10</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th scope="row">Manish Arora</th>
                                                                            <td>
                                                                                <div class="progress table-progress">
                                                                                    <div class="progress-bar" role="progressbar" style={{ width: "60%", background: '#d36a67' }} aria-valuenow="15" aria-valuemin="0" aria-valuemax="100">60</div>
                                                                                </div>
                                                                            </td>
                                                                            <td>10</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>

                                            <div className="nx-support-chart mt-3 mb-0">
                                                <div className="row nx-support-case">



                                                </div>
                                            </div>

                                            {/* support-details-table */}
                                            {/* <div className="mt-3 mb-5">
                                    <h5> Cases Details </h5>
                                        <NxGridList className="mb-5" enableSearch={false} header={[
                                        {
                                            name: 'Feedback',
                                        },
                                        {
                                            name:'Priority'
                                        },
                                        {
                                            name:'Reported To'
                                        },
                                        {
                                            name:'Status',
                                        },
                                        {
                                            name:'Subject'
                                        }
                                        ]} 
                                        data={support && support}
                                        />
                                    </div> */}
                                        </div>
                                    </div>
                                </TabPane>
                                <TabPane tab={
                                    <div className="d-flex justify-content-center">
                                        <span ><FontAwesomeIcon icon={faTicketAlt} /></span>
                                        <span style={{ fontSize: '16px' }} className='mx-2'>  Request</span>
                                    </div>
                                } key="2">
                                    <TicketsList />
                                </TabPane>
                                {/* <TabPane tab={
                                    <div className="d-flex justify-content-center">
                                        <span ><FontAwesomeIcon icon={faTicketAlt} /></span>
                                        <span style={{ fontSize: '16px' }} className='mx-2'>  API Endpoints</span>
                                    </div>
                                } key="3">
                                    <APIEndPoints />
                                </TabPane>
                                <TabPane tab={
                                    <div className="d-flex justify-content-center">
                                        <span ><FontAwesomeIcon icon={faTicketAlt} /></span>
                                        <span style={{ fontSize: '16px' }} className='mx-2'>  Help Docs</span>
                                    </div>
                                } key="4">
                                    <HelpDocs />
                                </TabPane> */}
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
            <NxFooter className="verscio-footer" companyName="© 2023 Altascio Technologies (I) Pvt. Ltd.," ExtraContent={[
                { name: 'Terms of Service' },
                { name: 'Privacy' },
            ]} />

        </>
    )
}
