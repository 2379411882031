import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import './editIdentityAccess.css';
import { NxBreadcrumb, NxTitleTab, NxFooter, NxCustomTab, NxLoader } from '@afiplfeed/itr-app';
import Headers from "../../../Screens/Headers/Headers";
import { Switch, Steps, Button, message } from 'antd';
import { ItrApiService } from "@afiplfeed/itr-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faMinusCircle, faPlus, faPlusCircle, faUserCheck } from "@fortawesome/free-solid-svg-icons";

// const splits = async (dests, setDatas) => {
//     let arr = [];
//     for (let value of dests) {
//         let data = [value.firstName, value.lastName, value.emailID, value.mobile];
//         let obj = { sendItem: value, data: data };
//         arr = [...arr, obj]
//     }
//     setDatas(arr);
// }



export default function EditIdentityAccess() {

    // const Buttons = ({elements}) => {
    //     return <div className="form-button container-fluid nx-enquiry-buttons mb-5 px-4">
    //         <button className="btn btn-primary rounded-pill buttons" onClick={() => {
    //             console.log(elements);
    //             ItrApiService.POST({
    //                 data: elements,
    //                 url: "EntityPlatform/EntityUser/SaveEntityUser",
    //             })
    //         }}> Save</button>
    //         <button className="btn close btn-outline-primary rounded-pill" onClick={() =>{
    //             history.push('/framework/iam');
    //         }}> Close</button>
    //     </div>
    // }

    let history = useHistory();

    const { Step } = Steps;
    const [current, setCurrent] = useState(0);

    const [loading, setLoading] = useState(false);

    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    const header = [
        { title: 'Personal' },
        { title: 'Access' },
        { title: 'Privileges' },
        // { title: 'Review' },
    ]

    const [roles, setRoles] = useState([{
        rollCode: '',
        rollName: '',
        description: '',
        additionalInfo: ''
    }]);

    const [lKey, setLKey] = useState([])

    const blockChar = () => {
        $("input[name='numonly']").on('input', function (e) {
            $(this).val($(this).val().replace(/[^0-9]/g, ''));
        });
    }

    const [datas, setDatas] = useState([]);
    const [showAvailable, setShowAvailable] = useState('');

    const [code, setCode] = useState()
    const getData = async (dataVal) => {
        let result = await ItrApiService.GET({
            url: 'RevOps/EntitySubscription/AllSubscriptionLicense'
        });
        if (result.result.isSuccess == true) {
            let data = result.result.data;

            // console.log(data, 'entityUserRoleGrid')
            if (data === null || !data.length) {
                // alert(result.result.message);
            }
            else {
                let array = []
                for (let role of data) {
                    setCode(role.ServiceCode);
                    var rolesData = []
                    let result1 = await ItrApiService.GET({
                        url: `DevOps/Enrollment/GetAllEnrollmentRoleByServiceCode/${role.ServiceCode}`
                    });
                    if (result1.result.isSuccess == true) {
                        let data = result1.result.data;
                        // console.log(data, 'entityUserRoleList', role.ServiceCode)
                        if (data === null || !data.length) {
                            // alert(result.result.message);
                        }
                        else {
                            // console.log('lllllllllllllllllllllllllllll')
                            rolesData = data;
                        }
                    }
                    // console.log(role.ServiceCode, 'kkkkkkkkkk')

                    var keysData = [];
                    let result2 = await ItrApiService.GET({
                        url: `RevOps/EntitySubscription/ActiveLicense/${role.ServiceCode}`
                    });
                    if (result2.result.isSuccess == true) {
                        let data = result2.result.data;
                        if (data === null || !data.length) {
                            // alert(result.result.message);
                        }
                        else {
                            let keysDatas = data
                            if (dataVal && dataVal.userAppRoles && dataVal.userAppRoles.length > 0) {
                                for (let value of dataVal.userAppRoles) {
                                    console.log(role.EnrollmentId)
                                    console.log(value.enrollmentID)
                                    if (role.EnrollmentId == value.enrollmentID) {
                                        keysDatas = [...keysDatas, { licenseKeyID: value.licenseKeyID, licenseKey: value.licenseKey }]
                                    }
                                }
                            }
                            keysData = keysDatas;
                            setLKey(keysData);
                        }
                    }
                    let obj = { ...role, rolesData: rolesData, keys: keysData }
                    array = [...array, obj]
                }
                // console.log(array);
                setDatas(array);
            }
            // setCustomers(getCustomerDetails);
        }
        else if (result.result.isSuccess == false) {
            // alert(result.result.message);
            // sessionStorage.clear();
            // history.push('/')
        }
    }



    // edit-user-list
    const getEditData = async (entityId) => {
        // console.log(entityId, 'Enenen')
        if (!entityId) {
            history.push('/manage-users');
            return false;
        }
        let result = await ItrApiService.GET({
            url: `Catalog/Users/GetUserById/${entityId}`,
        });
        if (result.result.isSuccess == true) {
            let data = result.result.data;
            // console.log(data,'editIAMData')
            if (data === null) {
                // alert(result.result.message);
                return { success: false, data: {} };
            }
            else {
                console.log(data);
                let arr = data;
                // if (data && data.userAppRoles && data.userAppRoles.length > 0) {
                //     let ur = []
                //     for (let val of data.userAppRoles) {
                //         let obj = { ...val, licenseKeydata: '' }
                //         let arrs = [];
                //         for (let dd of datas) {
                //             if (dd.enrollmentID == val.enrollmentID) {
                //                 let objs = { ...dd };
                //                 var index1 = dd.keys.findIndex(vals => vals.licenseKeyID == val.licenseKeyID);
                //                 console.log(index1, '###########')
                //                 if (index1 != -1) {
                //                     console.log(JSON.stringify(dd.keys[index1]))
                //                     obj = { ...obj, licenseKeydata: JSON.stringify(dd.keys[index1]) }
                //                     arrs = [...arrs, dd]
                //                 } else {
                //                     let iik = {
                //                         licenseKey: val.licenseKey,
                //                         licenseKeyID: val.licenseKeyID
                //                     }
                //                     obj = { ...obj, licenseKeydata: JSON.stringify(iik) }
                //                 }
                //             } else {
                //                 arr = [...arr, dd]
                //             }
                //         }
                //         ur = [...ur, obj]
                //     }
                //     arr.userAppRoles = ur
                // }
                console.log('222222222222 => ', arr);
                setElements(arr);
                return { success: true, data: arr };
            }
            // setCustomers(getCustomerDetails);
        }
        else if (result.result.isSuccess == false) {
            // alert(result.result.message);
            // sessionStorage.clear();
            // history.push('/')
            return { success: false, data: {} };
        }
        return { success: false, data: {} };
    }

    const sppirs = async (data) => {
        let arr = data;
        if (data && data.userAppRoles && data.userAppRoles.length > 0) {
            let ur = []
            for (let val of data.userAppRoles) {
                let obj = { ...val, licenseKeydata: JSON.stringify({ licenseKeyID: val.licenseKeyID, licenseKey: val.licenseKey }) }
                ur = [...ur, obj]
            }
            arr = { ...arr, userAppRoles: ur };
            console.log(arr);
            setElements(arr);
        }
    }
    // console.log(editUserList,'editDataList');


    const [department, setDepartment] = useState([]);
    const [reporting, setReporting] = useState([]);
    const [unitMaster, setUnitMaster] = useState([]);

    const getDepartmentList = async () => {
        setLoading(true);
        let result = await ItrApiService.GET({
            url: 'Catalog/Users/GetUserDepartmentList'
        });
        console.log("==========>", result);
        if (result.Success == true) {
            if (result.result.isSuccess == true) {
                let data = result.result.data;
                if (data == null) {
                    setLoading(false);
                }
                else {
                    // console.
                    if (data.unitMasters && data.unitMasters.length > 0) {
                        setUnitMaster(data.unitMasters)
                    }
                    setDepartment(data.departments);
                    if (data.users) {
                        // console.log(data.users)
                        setReporting(data.users);
                    }
                    setLoading(false);
                }
                return true
            } else {
                setLoading(false);
                return true;
            }
        }
        else if (result.result.isSuccess == false) {
            setLoading(false);
            return false
        } else {
            setLoading(false);
            return false
        }
    }

    useEffect(() => {
        setLoading(true);
        let entityIds = sessionStorage.getItem('editUserId');
        getDepartmentList();
        let rep = getEditData(entityIds);
        setUid(entityIds)
        getData(rep.data);
        sppirs(rep.data)
        setLoading(false);
    }, []);

    const [entity, setEntity] = useState('');

    const [entityU, setEntityU] = useState([]);

    const [entityUnit, setEntityUnit] = useState('');

    const [entityUnits, setEntityUnits] = useState([])

    const [uId, setUid] = useState(0)

    const [elements, setElements] = useState({
        UserID: 1,
        // entityID: 0,
        entityCode: "",
        employeeType: '',
        userCode: "",
        userType: "",
        firstName: "",
        lastName: "",
        displayName: "",
        userName: "",
        designation: "",
        department: "",
        gender: 0,
        mobile: "",
        emailID: "",
        isPortalAccess: false,
        isAccess: false,
        isActive: true,
        locationCode: "",
        userAppRoles: [],
        userLocations: [],
        userUnits: []
    })

    const editIdentityAccess = async () => {
        setLoading(true);
        let result = await ItrApiService.POST({
            data: { ...elements, UserID: uId },
            url: "Catalog/Users/SaveUser",
        })
        // console.log(result, 'identity&Access');
        if (result.Success == true) {
            if (result.result.isSuccess == true) {
                message.success('Successfully Updated');
                sessionStorage.removeItem('editUserId');
                history.goBack();
            }
        }
        else {
            alert('Failed to update');
        }
        setLoading(false);
    }

    return (
        <div className="identity-Access">
            <NxLoader state={loading} />
            <Headers />
            <NxBreadcrumb className="navigation-breadcrumb" data={[
                {
                    name: 'Customer Board',
                    onClick: () => {
                        history.push('/customer-board');
                    }
                },
                // {
                //     name: 'Identity List',
                //     onClick: () => {
                //         history.push('/framework/iam');
                //     }
                // }
            ]} />
            <NxTitleTab className="title-tab" title='Edit User' onCloseClick={() => {
                history.goBack();
                sessionStorage.removeItem('editUserId');
            }} />


            <div className="card m-4">
                <div className="card-body p-0">

                    <Steps current={current} responsive={true} labelPlacement="vertical" size="small" >
                        {header.map((title, index) => {
                            return <Step key={index} title={title.title}
                                icon={current == index ? <div style={{ backgroundColor: '#1890ff', padding: 5, borderRadius: '24px', height: '24px', width: '24px', fontSize: '12px', display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white' }}>{index + 1}</div>
                                    : current > index && <div style={{ backgroundColor: '#73b504', padding: 5, borderRadius: '24px', height: '24px', width: '24px', fontSize: '12px', display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white' }}>{index + 1}</div>} />
                        })}
                    </Steps>

                    {current == 0 ? <div className="container-form framework-container">
                        <div className="row justify-content-start container-fluid w-100 mb-4">
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <h6>Personal Information</h6>
                                <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">
                                    <label htmlFor="firstName">First Name</label>
                                    <input className="form-control form-control-sm mt-0" id="firstName" type="text" value={elements.firstName} onChange={(e) => {
                                        setElements({ ...elements, firstName: e.target.value })
                                    }} />
                                </div>

                                <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">
                                    <label htmlFor="lastName">Last Name</label>
                                    <input className="form-control form-control-sm mt-0" id="lastName" type="text" value={elements.lastName} onChange={(e) => {
                                        setElements({ ...elements, lastName: e.target.value })
                                    }} />
                                </div>

                                <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">
                                    <label htmlFor="displayName">Display Name</label>
                                    <input className="form-control form-control-sm mt-0" id="displayName" type="text" value={elements.displayName} onChange={(e) => {
                                        setElements({ ...elements, displayName: e.target.value })
                                    }} />
                                </div>

                                <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">
                                    {/* <div className="d-flex justify-content-between align-items-center"> */}
                                    <label htmlFor="displayName">User Name</label>
                                    <input className="form-control bg-white form-control-sm mt-0" id="displayName" type="text" value={elements.loginUserName} readOnly />
                                    {/* </div> */}
                                </div>
                                {/* <div className="row align-items-center">
                                            <div className="col-11 col-sm-11 col-md-11 col-lg-11 col-xl-12">
                                            </div>
                                        </div> */}

                                {/* <button className="btn btn-primary btn-sm mt-1" onClick={ async() => {
                                            let check = await ItrApiService.GET({
                                                url:`EntityPlatform/EntityUser/CheckUserAvailability/${elements.loginUserName}`
                                            })
                                            // console.log(check);
                                            setShowAvailable(check);
                                            
                                        }}>Check Availability</button> */}
                                &nbsp;
                                {/* <a className={ showAvailable && showAvailable.result.isSuccess == true ? 'text-success' : 'text-danger'}>
                                        { showAvailable &&  showAvailable.result.isSuccess == true ? 'Available' : showAvailable && showAvailable.result.isSuccess == false ? 'Please Use Different UserName' : ''}
                                    </a> */}

                            </div>

                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <h6>Contact</h6>
                                <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">
                                    <label htmlFor="emailId">Email Address</label>
                                    <input className="form-control form-control-sm mt-0" id="emailId" type="text" value={elements.emailID} onChange={(e) => {
                                        setElements({ ...elements, emailID: e.target.value })
                                    }} />
                                </div>

                                <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">
                                    <label htmlFor="mobile">Mobile</label>
                                    <input className="form-control form-control-sm mt-0" id="mobilenumber" type="number" maxLength="15" name="numonly" value={elements.mobile} pattern="[{0-9}]" onChange={(e) => {
                                        // console.log(e.target.value)
                                        setElements({ ...elements, mobile: e.target.value });
                                    }} />
                                </div>
                                <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">
                                    <label htmlFor="mobile">Department</label>
                                    <select className="form-select form-select-sm" value={elements.department} onChange={(e) => setElements({ ...elements, department: e.target.value })}>
                                        <option value="" selected disabled> -- Please Select --</option>
                                        {department.map((item, index) => <option key={index} value={item.departmentCode}> {item.departmentName} </option>)}
                                    </select>
                                    {/* <div class={vald1.department == false ? "invalid-data" : 'd-none'}>
                                        Please choose a Department.
                                    </div> */}
                                </div>

                                <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">
                                    <label htmlFor="mobile">Employee Type</label>
                                    <select className="form-select form-select-sm" value={elements.employeeType} onChange={(e) => setElements({ ...elements, employeeType: e.target.value })}>
                                        <option value="" selected disabled> -- Please Select --</option>
                                        <option value="Internal">Internal</option>
                                        <option value="External">External</option>
                                        <option value="Trainee">Trainee</option>
                                        <option value="Intern">Intern</option>
                                    </select>
                                    {/* <div class={vald1.employeeType == false ? "invalid-data" : 'd-none'}>
                                        Please choose a Employee type.
                                    </div> */}
                                </div>

                                <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">
                                    <label htmlFor="mobile">Reporting</label>
                                    <select className="form-select form-select-sm" value={elements.reportingUserCode} onChange={(e) => setElements({ ...elements, reportingUserCode: e.target.value })}>
                                        <option value="" selected disabled> -- Please Select --</option>
                                        {reporting.map((userName, index) => {
                                            return <option key={index} value={userName.userCode}> {userName.displayName} </option>
                                        })}
                                    </select>
                                </div>
                            </div>




                            {/* <div className="col-6 col-md-6 col-sm-6 col-lg-6 col-xl-6 mt-2">
                                        <label htmlFor="LastName">Entity</label>
                                        <select className="form-select">
                                            <option>--please-select--</option>
                                        </select>
                                    </div> */}

                            {/* <div className="col-12 col-md-6 col-sm-6 col-lg-4 col-xl-4 mt-2">
                                        <div className="row justify-content-center align-items-center">
                                            <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-1">
                                                <label htmlFor="portalAccess">Portal Access</label><br />
                                                <Switch checked={elements.isPortalAccess} onChange={(checked, e) => {
                                                    setElements({ ...elements, isPortalAccess: checked })
                                                }} /> &nbsp;
                                            </div>

                                            <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-1">
                                                <label htmlFor="access">Access</label><br />
                                                <Switch checked={elements.isAccess} onChange={(checked, e) => {
                                                    setElements({ ...elements, isAccess: checked })
                                                }} /> &nbsp;
                                            </div>
                                        </div>
                                    </div> */}
                        </div>
                    </div> :
                        current == 1 ? <div className="container-form framework-container">
                            <div className="row justify-content-start container-fluid w-100 mb-4">
                                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <h6>Portal & Platform Access</h6>
                                    <div className="col-6 col-sm-12 col-md-12 col-lg-6 col-xl-6 mt-2">
                                        <div>
                                            <div className="row align-items-center">
                                                <div className="col-6 col-md-12 col-sm-12 col-lg-10 col-xl-10 mt-2">
                                                    <label htmlFor="firstName">Platform Access</label> &nbsp;
                                                    <Switch checked={elements.isAccess} onChange={(checked, e) => {
                                                        setElements({ ...elements, isAccess: checked })
                                                    }} />
                                                </div>

                                                <div className="col-10 col-md-12 col-sm-10 col-lg-10 col-xl-10 mt-2">
                                                    <label htmlFor="access">Portal Access</label> &nbsp;
                                                    <Switch checked={elements.isPortalAccess} onChange={(checked, e) => {
                                                        setElements({ ...elements, isPortalAccess: checked })
                                                    }} />
                                                </div>

                                                <div className="col-10 col-md-10 col-sm-10 col-lg-10 col-xl-10 mt-2">
                                                    <label htmlFor="access">Unit</label><br />
                                                    <select className="form-select"
                                                        value={entityUnit}
                                                        onChange={(e) => { setEntityUnit(e.target.value) }}
                                                    >
                                                        <option value="" disabled selected>--Please Select--</option>
                                                        {unitMaster.map((item, index) => {
                                                            return <option key={index} value={item.uCode}> {item.uName} </option>
                                                        })}
                                                    </select>
                                                    {/* <div class={vald1.locationCode == false ? "invalid-data" : 'd-none'}>
                                            Please choose a Location.
                                        </div> */}
                                                </div>

                                                <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 mt-4" onClick={() => {
                                                    setEntityUnits([...entityUnit, { name: entityUnit }]);
                                                    if (entityUnit != '') {
                                                        let obj = elements.userUnits.find(o => o.uCode == entityUnit);
                                                        // console.log(obj)
                                                        if (!obj) {
                                                            setElements({
                                                                ...elements, userUnits: [...elements.userUnits, {
                                                                    userUnitID: 0,
                                                                    userID: 1,
                                                                    uCode: entityUnit
                                                                }]
                                                            })
                                                            setEntityUnit('');
                                                        }
                                                    }
                                                }} >
                                                    <FontAwesomeIcon icon={faPlusCircle} color="black" role="button" />
                                                    {/* <button type="button" className="btn btn-outline-light btn-sm mt-1" onClick={() => {
                                                    setEntityU([...entityU, {name: entity}]);
                                                    setElements({...elements, userLocations: [...elements.userLocations, {
                                                        entityUserLocationID:0,
                                                        entityUserID:1,
                                                        locationCode: entity
                                                    }]})
                                                    setEntity('');
                                                }} > */}
                                                    {/* </button> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-4">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="col-6 col-md-12 col-sm-12 col-lg-12 col-xl-12">
                                                    <div class="table-wrapper-scroll-y my-custom-scrollbar">
                                                        <table class="table table-hover mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" className="border-bottom-0">
                                                                        <span style={{ fontSize: '13px' }}>Unit Name</span>
                                                                    </th>
                                                                    <th scope="col" className="border-bottom-0">
                                                                        <span style={{ fontSize: '13px' }}>Action</span>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {elements.userUnits.map((data, index) => {
                                                                    return <tr>
                                                                        <td style={{ fontSize: '13px' }} key={index}> {data.uCode} </td>
                                                                        <td style={{ fontSize: '13px' }} > <FontAwesomeIcon icon={faMinusCircle} color="red" onClick={() => {
                                                                            let index = elements.userUnits.findIndex(o => o.uCode == data.uCode);
                                                                            // console.log(index)
                                                                            let arr = [];
                                                                            arr = elements.userUnits;
                                                                            let eindex = index + 1
                                                                            // console.log(arr)
                                                                            arr.splice(index, eindex);
                                                                            setElements({ ...elements, uCode: arr });
                                                                        }} /> </td>
                                                                    </tr>
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        {/* <NxGridList header={[
                                            {
                                                name: 'location Name',
                                            },
                                            {
                                                name: 'Action',
                                            },
                                        ]}
                                            rowClick={(e) => {
                                                // console.log(e,'passsssssss');
                                            }}
                                            data={datas} 
                                        /> */}
                                    </div>
                                </div>

                                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <h6>Location Access</h6>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        {/* <label htmlFor="access">Entity</label><br /> */}
                                        <div className="row align-items-center">
                                            <div className="col-6 col-md-12 col-sm-12 col-lg-10 col-xl-10 mt-2">
                                                <label htmlFor="access">Base Location</label><br />
                                                <select className="form-select" value={elements.locationCode} onChange={(e) => { setElements({ ...elements, locationCode: e.target.value }) }}>
                                                    <option disabled selected>--Please Select--</option>
                                                    <option value="IND">India</option>
                                                    <option value="BAN">Bangladesh</option>
                                                    <option value="JOR">Jordan</option>
                                                </select>
                                            </div>

                                            <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10">
                                                <label> Additional Location </label>
                                                <select className="form-select" value={entity} onChange={(e) => {
                                                    // console.log(e.target.value)
                                                    setEntity(e.target.value);
                                                }}>
                                                    <option value="" disabled selected>--Please Select--</option>
                                                    <option value="IND">India</option>
                                                    <option value="BAN">Bangladesh</option>
                                                    <option value="JOR">Jordan</option>
                                                </select>
                                            </div>

                                            <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 mt-4" onClick={() => {
                                                // setEntityU([...entityU, {name: entity}]);
                                                if (entity != '') {
                                                    let obj = elements.userLocations.find(o => o.locationCode == entity);
                                                    // console.log(obj)
                                                    if (!obj) {
                                                        setElements({
                                                            ...elements, userLocations: [...elements.userLocations, {
                                                                entityUserLocationID: 0,
                                                                UserID: 1,
                                                                locationCode: entity
                                                            }]
                                                        })
                                                        setEntity('');
                                                    }
                                                }
                                            }} >
                                                <FontAwesomeIcon icon={faPlusCircle} color="black" role="button" />
                                                {/* <button type="button" className="btn btn-outline-light btn-sm mt-1" onClick={() => {
                                                   setEntityU([...entityU, {name: entity}]);
                                                   setElements({...elements, userLocations: [...elements.userLocations, {
                                                       entityUserLocationID:0,
                                                       UserID:1,
                                                       locationCode: entity
                                                   }]})
                                                   setEntity('');
                                               }} > */}
                                                {/* </button> */}
                                            </div>


                                        </div>



                                        {/* <input className="form-control" value={entity} onChange={(e) => {
                                           setEntity(e.target.value)
                                       }} /> */}
                                    </div>

                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-4">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="col-6 col-md-12 col-sm-12 col-lg-12 col-xl-12">
                                                    <div class="table-wrapper-scroll-y my-custom-scrollbar">
                                                        <table class="table table-hover mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" className="border-bottom-0">
                                                                        <span style={{ fontSize: '13px' }}>Location Name</span>
                                                                    </th>
                                                                    <th scope="col" className="border-bottom-0">
                                                                        <span style={{ fontSize: '13px' }}>Action</span>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {elements.userLocations.map((data, index) => {
                                                                    return <tr>
                                                                        <td style={{ fontSize: '13px' }} key={index}> {data.locationCode} </td>
                                                                        <td style={{ fontSize: '13px' }} > <FontAwesomeIcon icon={faMinusCircle} color="red" onClick={() => {
                                                                            let index = elements.userLocations.findIndex(o => o.locationCode == data.locationCode);
                                                                            //    console.log(index)
                                                                            let arr = [];
                                                                            arr = elements.userLocations;
                                                                            let eindex = index + 1
                                                                            // console.log(arr)
                                                                            arr.splice(index, eindex);
                                                                            setElements({ ...elements, userLocations: arr });
                                                                        }} /> </td>
                                                                    </tr>
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        {/* <NxGridList header={[
                                           {
                                               name: 'location Name',
                                           },
                                           {
                                               name: 'Action',
                                           },
                                       ]}
                                           rowClick={(e) => {
                                               console.log(e,'passsssssss');
                                           }}
                                           data={datas} 
                                       /> */}
                                    </div>
                                </div>



                                {/* <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">
                                   <div className="row">
                                           <header className="fw-bold">Additional Entity Location</header>
                                   </div>
                               </div> */}

                                {/* <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">
                               <div className="row">
                                   <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                       {elements.userLocations.map((item, index) => {
                                           return <>
                                               <p key={index}>{item.locationCode} &nbsp;
                                                   <button className="btn btn-outline-light btn-sm">
                                                       <FontAwesomeIcon icon={faMinusCircle} color="red"/>
                                                   </button>
                                               </p>
                                           </>
                                       })}
                                   </div>
                               </div>
                           </div> */}
                            </div>
                        </div> :
                            <div className="container-form framework-container">
                                <div className="row justify-content-start w-100 mb-4">
                                    <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">
                                        <div className="card">
                                            <div className="card-body">
                                                <div class="table-wrapper-scroll-y my-custom-scrollbar">
                                                    <table class="table table-hover mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col" className="border-bottom-0"><span style={{ fontSize: '13px' }}>App Name</span></th>
                                                                <th scope="col" className="border-bottom-0"><span style={{ fontSize: '13px' }}>Access</span></th>
                                                                <th scope="col" className="border-bottom-0"><span style={{ fontSize: '13px' }}>Role</span></th>
                                                                <th scope="col" className="border-bottom-0"><span style={{ fontSize: '13px' }}>Subscription Key</span></th>
                                                                {/* <th scope="col">Action</th> */}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {datas.map((item, index) => {
                                                                let ws = [...elements.userAppRoles];
                                                                var its = ws.findIndex(vals => vals.enrollmentID === item.EnrollmentId);
                                                                return <tr className='user-table' key={index}>
                                                                    <td className="pt-2">{item.ServiceName}</td>
                                                                    <td className="pt-2">
                                                                        <Switch checked={its == -1 ? elements.userAppRoles.isAccess : elements.userAppRoles[its].isAccess}
                                                                            onChange={(e) => {
                                                                                // let ck = [...elements.userAppRoles];
                                                                                // var is = ck.findIndex(vals => vals.enrollmentID === item.EnrollmentId);
                                                                                if (its == -1) {
                                                                                    let obj = {
                                                                                        entityUserAppRoleID: 0,
                                                                                        UserID: 0,
                                                                                        enrollmentID: item.EnrollmentId,
                                                                                        isAccess: e,
                                                                                        roleID: 0,
                                                                                        isActive: e,
                                                                                        licenseKeyID: 0,
                                                                                        licenseKey: ""
                                                                                    }
                                                                                    let arr = elements.userAppRoles;
                                                                                    arr = [...arr, obj];
                                                                                    setElements({ ...elements, userAppRoles: arr });
                                                                                } else {
                                                                                    ws[its].isAccess = e;
                                                                                    setElements({ ...elements, userAppRoles: ws });
                                                                                }
                                                                                // else {
                                                                                //     let array = [...elements.userAppRoles];
                                                                                //     var index1 = array.findIndex(vals => vals.enrollmentID === item.EnrollmentId);
                                                                                //     array.splice(index1, 1)
                                                                                //     setElements({ ...elements, userAppRoles: array })
                                                                                //     // setRemoveVal(array);
                                                                                // }
                                                                                // arr[0].isAccess = e
                                                                                // console.log(arr);
                                                                            }} />
                                                                    </td>
                                                                    <td className="pt-2">
                                                                        <select className="form-select" value={its == -1 ? elements.userAppRoles.roleID : elements.userAppRoles[its].roleID}
                                                                            onChange={(e) => {
                                                                                let array = [...elements.userAppRoles];
                                                                                var index1 = array.findIndex(vals => vals.enrollmentID === item.EnrollmentId);
                                                                                if (index1 == -1) {
                                                                                    let obj = {
                                                                                        entityUserAppRoleID: 0,
                                                                                        UserID: 0,
                                                                                        enrollmentID: item.EnrollmentId,
                                                                                        isAccess: false,
                                                                                        roleID: parseInt(e.target.value),
                                                                                        isActive: false,
                                                                                        licenseKeyID: 0,
                                                                                        licenseKey: ""
                                                                                    }
                                                                                    let arr = elements.userAppRoles;
                                                                                    arr = [...arr, obj];
                                                                                    setElements({ ...elements, userAppRoles: arr });
                                                                                } else {
                                                                                    // ws[its].isAccess = e;
                                                                                    array[index1].roleID = parseInt(e.target.value);
                                                                                    setElements({ ...elements, userAppRoles: array });
                                                                                }
                                                                                // setElements({ ...elements, userAppRoles: array })
                                                                            }}>
                                                                            <option selected disabled value=''>--please select--</option>
                                                                            {item.rolesData.map((data, index) => {
                                                                                return <option key={index} value={data.id}> {data.roleName} </option>
                                                                            })}
                                                                        </select>
                                                                    </td>
                                                                    <td className="pt-2" align="center">
                                                                        <select className="form-select" value={its == -1 ? elements.userAppRoles.licenseKey : elements.userAppRoles[its].licenseKeydata}
                                                                            onChange={(e) => {
                                                                                let array = [...elements.userAppRoles];
                                                                                var index1 = array.findIndex(vals => vals.enrollmentID === item.EnrollmentId);
                                                                                let vals = JSON.parse(e.target.value);
                                                                                console.log(vals)
                                                                                if (index1 != -1) {
                                                                                    if (e.target.value == '') {
                                                                                        let obj = {
                                                                                            entityUserAppRoleID: 0,
                                                                                            UserID: 0,
                                                                                            enrollmentID: item.EnrollmentId,
                                                                                            isAccess: false,
                                                                                            roleID: 0,
                                                                                            isActive: false,
                                                                                            licenseKeyID: vals.licenseKeyID,
                                                                                            licenseKeydata: e.target.value,
                                                                                            licenseKey: vals.licenseKey
                                                                                        }
                                                                                        let arr = elements.userAppRoles;
                                                                                        arr = [...arr, obj];
                                                                                        setElements({ ...elements, userAppRoles: arr });
                                                                                    } else {
                                                                                        let vals = JSON.parse(e.target.value)
                                                                                        console.log(e.target.value)
                                                                                        array[index1].licenseKeyID = vals.licenseKeyID;
                                                                                        array[index1].licenseKey = vals.licenseKey;
                                                                                        array[index1].licenseKeydata = e.target.value;
                                                                                        setElements({ ...elements, userAppRoles: array });
                                                                                    }
                                                                                }
                                                                                // setElements({ ...elements, userAppRoles: array })
                                                                            }}
                                                                        >
                                                                            <option value=''>--please select--</option>
                                                                            {item.keys.map((data, index) => {
                                                                                return <option key={index} id={data.licenseKey} value={JSON.stringify(data)}> {data.licenseKey} </option>
                                                                            })}
                                                                        </select>
                                                                    </td>
                                                                    {/* <td>
                                                            <button className="btn btn-primary btn-sm">Add +</button>
                                                        </td> */}
                                                                </tr>
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    {/* <div class="table-wrapper-scroll-y my-custom-scrollbar">
                                        <table class="table table-bordered mb-0">
                                            <thead>
                                                <tr>
                                                <th scope="col">App Name</th>
                                                <th scope="col">Access</th>
                                                <th scope="col">Role</th>
                                                <th scope="col">Subscription Key</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {}
                                            </tbody>
                                        </table>
                                    </div> */}


                                </div>
                            </div>}

                    {/* <div className="container-form framework-container">
                                    <div className="row justify-content-start container-fluid w-100 mb-4">
                                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <h6>Personal Information</h6>

                                            <div className="col-6 col-md-4 col-sm-4 col-lg-3 col-xl-2 mt-2">
                                                <label htmlFor="firstName">First Name</label>
                                                <input className="form-control-plaintext form-control-sm mt-0 bg-white" id="firstName" type="text" value={elements.firstName ? elements.firstName : '-'} disabled />
                                            </div>

                                            <div className="col-6 col-md-4 col-sm-4 col-lg-3 col-xl-2 mt-2">
                                                <label htmlFor="lastName">Last Name</label>
                                                <input className="form-control-plaintext fw-lighter form-control-sm mt-0 bg-white" id="lastName" type="text" value={elements.lastName ? elements.lastName : '-'} disabled />
                                            </div>

                                            <div className="col-12 col-md-4 col-sm-4 col-lg-3 col-xl-2 mt-2">
                                                <label htmlFor="displayName">Display Name</label>
                                                <input className="form-control-plaintext fw-lighter form-control-sm mt-0 bg-white" id="displayName" type="text" value={elements.displayName ? elements.displayName : '-'} disabled />
                                            </div>

                                            <div className="col-12 col-md-4 col-sm-4 col-lg-3 col-xl-3 mt-2">
                                                <label htmlFor="displayName">UserName</label>
                                                <input className="form-control-plaintext fw-lighter form-control-sm mt-0 bg-white" id="displayName" type="text" value={elements.loginUserName ? elements.loginUserName : '-'} disabled />
                                            </div>
                                        </div>


                                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <h6>Contact</h6>

                                            <div className="col-12 col-md-4 col-sm-4 col-lg-3 col-xl-3 mt-2">
                                                <label htmlFor="emailId">Email Address</label>
                                                <input className="form-control-plaintext fw-lighter form-control-sm mt-0 bg-white" id="emailId" type="text" value={elements.emailID ? elements.emailID : '-'} disabled />
                                            </div>

                                            <div className="col-12 col-md-4 col-sm-4 col-lg-3 col-xl-3 mt-2">
                                                <label htmlFor="mobile">Mobile</label>
                                                <input className="form-control-plaintext fw-lighter form-control-sm mt-0 bg-white" id="mobile" type="text" maxLength="15" value={elements.mobile ? elements.mobile : '-'} disabled />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row justify-content-start container-fluid w-100 mb-4">
                                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <h6>Access</h6>

                                            <div className="col-6 col-md-4 col-sm-4 col-lg-3 col-xl-2 mt-2">
                                                <label htmlFor="displayName">Platform Access</label><br />
                                                <Switch className="mt-0" checked={elements.isAccess} />
                                            </div>

                                            <div className="col-6 col-md-4 col-sm-4 col-lg-3 col-xl-2 mt-2">
                                                <label htmlFor="displayName">Portal Access</label><br />
                                                <Switch className="mt-0" checked={elements.isPortalAccess} />
                                            </div>

                                            <div className="col-12 col-md-4 col-sm-4 col-lg-3 col-xl-2 mt-2">
                                                <label htmlFor="displayName">Base Location</label>
                                                <input className="form-control-plaintext fw-lighter form-control-sm mt-0 bg-white" id="displayName" type="text" value={elements.locationCode ? elements.locationCode : '-'} disabled />
                                            </div>
                                        </div>

                                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <h6>Additional Location</h6>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-4">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="col-6 col-md-12 col-sm-12 col-lg-12 col-xl-12">
                                                            <div class="table-wrapper-scroll-y my-custom-scrollbar">
                                                                <table class="table table-hover mb-0">
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col" className="border-bottom-0">
                                                                                <span style={{ fontSize: '13px' }}>Location Name</span>
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {elements.userLocations.map((data, index) => {
                                                                            return <tr>
                                                                                <td style={{ fontSize: '13px' }} key={index}> {data.locationCode} </td>
                                                                            </tr>
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row justify-content-start container-fluid w-100 mb-4">
                                        <h6>App Access</h6>

                                        <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div class="table-wrapper-scroll-y my-custom-scrollbar">
                                                        <table class="table table-hover mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" className="border-bottom-0"><span style={{ fontSize: '13px' }}>App Name</span></th>
                                                                    <th scope="col" className="border-bottom-0"><span style={{ fontSize: '13px' }}>Access</span></th>
                                                                    <th scope="col" className="border-bottom-0"><span style={{ fontSize: '13px' }}>Role</span></th>
                                                                    <th scope="col" className="border-bottom-0"><span style={{ fontSize: '13px' }}>Subscription Key</span></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {datas.map((item, index) => {
                                                                    return <tr className='user-table' key={index}>
                                                                        <td style={{ fontSize: '13px' }} className="pt-2">{item.enrollment.serviceName}</td>
                                                                        <td className="pt-2">
                                                                            <Switch />
                                                                        </td>
                                                                        <td className="pt-2">
                                                                            <select className="form-select">
                                                                                <option>--please select--</option>
                                                                                {item.enrollment.enrollmentRoles.map((data, index) => {
                                                                                    return <option key={index}> {data.role} </option>
                                                                                })}
                                                                            </select>
                                                                        </td>
                                                                        <td className="pt-2" align="center">
                                                                            <select className="form-select" value={elements.userAppRoles.licenseKey}
                                                                            onChange={(e) => {
                                                                                let arr = elements.userAppRoles;
                                                                                arr[0].licenseKey = e.target.value
                                                                                // console.log(arr);
                                                                                setElements({...elements, userAppRoles: arr })
                                                                            }}
                                                                            >
                                                                                <option>--please select--</option>
                                                                                {item.licenseKeys.map((data, index) => {
                                                                                    return <option key={index}> {data.licenseKey} </option>
                                                                                })}
                                                                            </select>
                                                                        </td>

                                                                    </tr>
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}



                    <div className="steps-action mt-5 container" style={{ textAlign: 'right' }}>
                        {current > 0 && (<Button style={{ margin: '0 8px' }} onClick={() => prev()}> Previous </Button>)}

                        {/* {current == 0 ? <Button style={{ margin: '0 8px' }} onClick={() => history.goBack()}> Cancel </Button> : ''} */}

                        {current == 0 ? <Button type="primary" onClick={() => next()}> Next </Button> : ''}

                        {current == 1 ? <Button type="primary" onClick={() => next()}> Next </Button> : ''}

                        {current == 2 ? <Button type="primary" onClick={editIdentityAccess} >
                            {loading ? <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                : 'Update User'}
                        </Button> : ''}

                        {/* {current == 3 ?  : ''} */}
                    </div>


                    {/* {current === header.length - 1 && (
                                <Button type="primary" disabled={loading} 
                                onClick={async () => {
                                    setLoading(true);
                                    let create = await createUser(firstName,lastName,email,gender,business,salary,addressInfo,verification);
                                    setLoading(false);
                                }}
                                >
                                    {loading ? <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    :  'Submit' }
                                </Button>
                            )} */}
                </div>
            </div>







            {/* <NxCustomTab
                data={[
                    {
                        name: 'Perosnal Information',
                        Components: () => {
                            return <div className="container-form framework-container">
                                <div className="row justify-content-start container-fluid w-100 mb-4">
                                    <div className="col-6 col-md-6 col-sm-6 col-lg-6 col-xl-6 mt-2">
                                        <label htmlFor="firstName">First Name</label>
                                        <input className="form-control mt-0" id="firstName" type="text" value={elements.firstName} onChange={(e) => {
                                            setElements({ ...elements, firstName: e.target.value })
                                        }} />
                                    </div>

                                    <div className="col-6 col-md-6 col-sm-6 col-lg-6 col-xl-6 mt-2">
                                        <label htmlFor="lastName">Last Name</label>
                                        <input className="form-control mt-0" id="lastName" type="text" value={elements.lastName} onChange={(e) => {
                                            setElements({ ...elements, lastName: e.target.value })
                                        }} />
                                    </div>

                                    <div className="col-6 col-md-6 col-sm-6 col-lg-6 col-xl-6 mt-2">
                                        <label htmlFor="emailId">Email Address</label>
                                        <input className="form-control mt-0" id="emailId" type="text" value={elements.emailID} onChange={(e) => {
                                            setElements({ ...elements, emailID: e.target.value })
                                        }} />
                                    </div>

                                    <div className="col-6 col-md-6 col-sm-6 col-lg-6 col-xl-6 mt-2">
                                        <label htmlFor="mobile">Phone No</label>
                                        <input className="form-control mt-0" id="mobile" type="text" value={elements.mobile} onChange={(e) => {
                                            setElements({ ...elements, mobile: e.target.value })
                                        }} />
                                    </div>

                                    <div className="col-6 col-md-6 col-sm-6 col-lg-12 col-xl-12 mt-2">
                                        <label htmlFor="displayName">Display Name</label>
                                        <input className="form-control mt-0" id="displayName" type="text" value={elements.displayName} onChange={(e) => {
                                            setElements({ ...elements, displayName: e.target.value })
                                        }} />
                                    </div>

                                    <div className="col-6 col-md-6 col-sm-6 col-lg-12 col-xl-12 mt-2">
                                        <label htmlFor="displayName">UserName</label>
                                        <input className="form-control mt-0" id="displayName" type="text" value={elements.displayName} onChange={(e) => {
                                            setElements({ ...elements, displayName: e.target.value })
                                        }} />
                                    </div>

                                    <div className="col-12 col-md-6 col-sm-6 col-lg-4 col-xl-4 mt-2">
                                        <div className="row justify-content-center align-items-center">
                                            <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-1">
                                                <label htmlFor="portalAccess">Portal Access</label><br />
                                                <Switch checked={elements.isPortalAccess} onChange={(checked, e) => {
                                                    setElements({ ...elements, isPortalAccess: checked })
                                                }} /> &nbsp;
                                            </div>

                                            <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-1">
                                                <label htmlFor="access">Access</label><br />
                                                <Switch checked={elements.isAccess} onChange={(checked, e) => {
                                                    setElements({ ...elements, isAccess: checked })
                                                }} /> &nbsp;
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    },
                    {
                        name:'Platform & Entity',
                        Components:() => {
                            return <div className="row justify-content-start container-fluid w-100 mb-4">
                                <div className="col-6 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">
                                        <label htmlFor="access">Platform Access</label><br />
                                        <Switch checked={elements.isAccess} onChange={(checked, e) => {
                                            setElements({ ...elements, isAccess: checked })
                                        }} /> &nbsp;
                                </div>

                                <div className="col-6 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">
                                        <label htmlFor="access">Portal Access</label><br />
                                        <Switch checked={elements.isAccess} onChange={(checked, e) => {
                                            setElements({ ...elements, isAccess: checked })
                                        }} /> &nbsp;
                                </div>

                                <div className="col-6 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">
                                        <label htmlFor="access">Base Entity</label><br />
                                        <select className="form-select w-50">
                                            <option>--Please Select--</option>
                                            <option>India</option>
                                            <option>SriLanka</option>
                                        </select>
                                </div>

                                <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                            <label htmlFor="access">Entity</label><br/>
                                            <input className="form-control" value={entity} onChange={(e) => {
                                                setEntity(e.target.value)
                                            }} />
                                        </div>

                                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                            <input type="button" className="btn btn-primary btn-sm mt-3" value="Add" onClick={() => {
                                                setEntityU([...entityU, {name: entity}]);
                                                setEntity('')
                                            }} />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                            <label htmlFor="access">Entity Name</label><br/>
                                            {entityU.map((item, index) => {
                                                return <p key={index}>{item.name}</p>
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    },
                    {
                        name: 'App Access',
                        Components: () => {
                            return <div className="row justify-content-start container-fluid w-100 mb-4">
                                <div className="col-6 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">
                                    <div class="table-wrapper-scroll-y my-custom-scrollbar">
                                        <table class="table table-hover mb-0">
                                            <thead>
                                                <tr>
                                                    <th scope="col">App Name</th>
                                                    <th scope="col">Access</th>
                                                    <th scope="col">Role</th>
                                                    <th scope="col">Subscription Key</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {roles.map((item, index) => {
                                                    return <tr className='user-table' key={index}>
                                                        <td className="pt-2" align="center">Demo App</td>
                                                        <td className="pt-2" align="center">
                                                            <Switch />
                                                        </td>
                                                        <td className="pt-2" align="center">
                                                            <select className="form-select">
                                                                <option>--please select--</option>
                                                            </select>
                                                        </td>
                                                        <td className="pt-2" align="center">
                                                            <select className="form-select">
                                                                <option>--please select--</option>
                                                            </select>
                                                        </td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        },
                    },
                ]} /> */}
            {/* {
                                    name: 'Entity',
                                    Components: () => {
                                        return <div className="row justify-content-start container-fluid w-100 mb-4">
                                            <div className="col-6 col-md-12 col-sm-12 col-lg-12 col-xl-3 mt-2">
            
                                                <div className="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 mt-2">
                                                    <select className="form-select">
                                                        <option>--Please select--</option>
                                                        <option>India</option>
                                                        <option>Malaysia</option>
                                                        <option>Singapore</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    },
                                } */}

            {/* <hr/> */}
            {/* <Buttons elements={elements} /> */}
            )

            <NxFooter ExtraContent={[
                { name: 'Terms of Service' },
                { name: 'privacy' },
            ]} />
        </div >
    )
}