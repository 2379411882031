import React, { useEffect, useState } from "react";
import './PartnerUsers.css';
import { useHistory } from 'react-router-dom';
import { NxLoader, NxBreadcrumb, NxTitleTab, NxActionTab, NxGridList, NxRightDrawer, NxFooter, NxCustomTab } from '@afiplfeed/itr-app';
import { ItrApiService } from '@afiplfeed/itr-ui';
import Headers from "../../Screens/Headers/Headers";
import { NotificationManager } from "react-notifications";
const splits = async (dests, setEnquiry) => {
    let arr = [];
    for (let value of dests) {
        let data = [value.entityCode, value.entityName, value.noOfEmployees, value.source];
        let obj = { sendItem: value, data: data };
        arr = [...arr, obj]
    }
    setEnquiry(arr);
}

export default function PartnerUsers() {
    let history = useHistory();
    const [load, setLoad] = useState(false);
    const [handel, setHandel] = useState(false);
    const [handel2, setHandel2] = useState(false);


    const [customers, setCustomers] = useState([]);
    const [passCustomers, setpassCustomers] = useState();
    const [loading, setLoading] = useState(false);



    const getData = async () => {
        let result = await ItrApiService.GET({
            url: 'TenantPlatform/Entity/GetEntities',
            customHeaders: []
        });
        if (result.result.isSuccess == true) {
            let getCustomerDetails = result.result.data;
            if (getCustomerDetails === null || !getCustomerDetails.length) {
                // NotificationManager.error(result.result.message);
            }
            else {
                await splits(getCustomerDetails, setCustomers);
                await setpassCustomers(getCustomerDetails);
            }
            // setCustomers(getCustomerDetails);
        }
        else if (result.result.isSuccess == false) {
            NotificationManager.error(result.result.message);
            // sessionStorage.clear();
            // history.push('/')
        }
    }

    useEffect( () => {
        // setLoad(true);
        // await getData();
        // setLoad(false);
        // await splits(dest, setEnquiry);
    }, [])

    return (
        <div>
            <NxLoader state={load} />
            <Headers />
            <NxBreadcrumb className="navigation-breadcrumb" data={[
                {
                    name: 'Home',
                    onClick: () => {
                        history.push('/partner-board');
                    }
                }
            ]} />
            <div style={{ flex: 1 }}>
                <NxTitleTab className="title-tab" title='Partner User' onCloseClick={() => {
                    history.push('/partner-board');
                }} />

                <NxCustomTab
                    data={[
                        {
                            name: "General",
                            Components: () => {
                                return (
                                    <div className="container-form">
                                        <div className="row pt-0">

                                            <NxActionTab
                                                onColumnsClick={() => { setHandel(true) }}
                                                onRefreshClick={() => {
                                                    setLoad(true)
                                                    setTimeout(function () {
                                                        setLoad(false)
                                                    }, 2000);
                                                }}
                                                onNewEntryClick={() => { history.push('/partner-user/add') }}
                                                enableNewEntry={true}
                                            />

                                            <div className="p-3 pt-0">
                                                <NxGridList
                                                    enableSearch={true}
                                                    autoSearch={true}
                                                    enableAutoPagination={true}
                                                    showShowing={true}
                                                    header={[
                                                        {
                                                            name: 'User Code',
                                                            sort: true,
                                                        },
                                                        {
                                                            name: 'First Name',
                                                            search: true,
                                                            sort: true,
                                                        },
                                                        {
                                                            name: 'Last Name',
                                                            search: true,
                                                            // sort: true,
                                                        },
                                                        {
                                                            name: 'Mobile',
                                                            // sort: true,
                                                        },
                                                        {
                                                            name: 'Email',
                                                            search: true,
                                                        },
                                                    ]}
                                                    rowClick={(data) => {
                                                        history.push({
                                                            pathname: '/customer/add',
                                                            state: {
                                                                passData: data
                                                            }
                                                        });
                                                    }}
                                                    data={customers} />
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        },
                        {
                            name: "Access",
                            Components: () => {
                                return (
                                    <div className="container-form">
                                        <div className="row p-3 pt-0">
                                            <NxGridList
                                                enableSearch={true}
                                                autoSearch={true}
                                                enableAutoPagination={true}
                                                showShowing={true}
                                                header={[
                                                    {
                                                        name: 'User Code',
                                                        sort: true,
                                                    },
                                                    {
                                                        name: 'First Name',
                                                        sort: true,
                                                        search: true
                                                    },
                                                    {
                                                        name: 'Last Name',
                                                        search: true,
                                                    },
                                                    {
                                                        name: 'Mobile',
                                                        // sort: true,
                                                    },
                                                    {
                                                        name: 'Email',
                                                        search: true,
                                                    },
                                                ]}
                                                rowClick={(data) => {
                                                    // history.push({
                                                    //     pathname:'/customer/add',
                                                    //     state:{
                                                    //         passData:data
                                                    //     }
                                                    // });
                                                }}
                                                data={customers} />
                                        </div>
                                    </div>
                                )
                            }
                        }
                    ]}
                />
                <NxRightDrawer onClose={() => setHandel(false)} show={handel} drawerContent={() => <div>

                </div>} ondrawer1Close={() => { setHandel2(false) }} />
            </div>

            <NxFooter ExtraContent={[
                { name: 'Terms of Service' },
                { name: 'privacy' },
            ]} />
        </div>
    )
}