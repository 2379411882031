import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import './style.css';
import { NxLoader, NxRightDrawer, NxActionTab, NxGridList, NxTitleTab, NxBreadcrumb, NxHeader, NxFooter } from '@afiplfeed/itr-app';
import { ItrApiService } from '@afiplfeed/itr-ui';
import Headers from "../../Screens/Headers/Headers";
import Footers from "../../Screens/Footers/Footers";

export default function Enrollment({
    reloads
}) {

    const [loader, setLoader] = useState(false);


    const [enrollment, setEnrollment] = useState([]);

    const splits = async (dests, setEnquiry, setExport) => {
        let arr = [];
        let arr2 = [];
        for (let value of dests) {
            let data = [value.serviceCode, value.serviceName, value.developerName, value.version];
            let obj2 = {
                "Service Code": value.serviceCode,
                "Service Name": value.serviceName,
                "Publisher": value.developerName,
                "Version No": value.version
            }
            let obj = { sendItem: value, data: data };
            arr = [...arr, obj]
            arr2 = [...arr2, obj2]
        }
        setExport(arr2)
        setEnquiry(arr)
    }
    const [exportVal, setExport] = useState([]);

    const getEnrollment = async () => {
        setLoader(true);
        let result = await ItrApiService.GET({
            url: 'DevOps/Enrollment/ListAllEnrollment'
        });
        if (result.Success == true) {
            if (result.result.isSuccess == true) {
                setLoader(false);
                let getCustomerDetails = result.result.data;
                if (result.result.data) {
                    await splits(getCustomerDetails, setEnrollment, setExport)
                }
            }
            setLoader(false);
            // setEnquiry(getCustomerDetails);
        }
        else if (result.Success == false) {
            // NotificationManager.error(result.message);
            // sessionStorage.clear();
            // history.push('/')
        }
        setLoader(false);
        return true

    }

    // useEffect(async () => {
    //     setLoader(true);
    //     await getEnrollment();
    //     setLoader(false);
    //     // await splits(dest, setEnquiry);
    // }, []);

    useEffect(() => {
        getEnrollment();
        // await splits(dest, setEnquiry);
    }, [reloads]);


    let history = useHistory();
    const [load, setLoad] = useState(false);
    const [handel, setHandel] = useState(false);
    const [handel2, setHandel2] = useState(false);
    return (
        <div>
            <NxLoader state={load} />
            {/* <Headers /> */}
            <NxLoader state={loader} />
            {/* <NxBreadcrumb className="navigation-breadcrumb" data={[
                {
                    name: 'Home',
                    onClick: () => {
                        history.push('/customer-board')
                    }
                }
            ]} /> */}
            {/* <NxTitleTab className="title-tab page-title" title='Apps Enrollment' onCloseClick={() => {
                history.goBack()
            }} /> */}
            <div className="mx-3 mb-5 mt-0">
                <NxActionTab
                    exportValue={exportVal}
                    fileName='SERVICE LISTING'
                    className="p-0 pt-0 mt-0 enquiry-action-tab"
                    onColumnsClick={() => { setHandel(true) }}
                    onRefreshClick={async () => {
                        setLoader(true);
                        await getEnrollment();
                        setLoader(false);
                    }}
                    onNewEntryClick={() => { history.push('/distribution/appsenrollment/add') }}
                    enableNewEntry={false}
                />
                <div className="pt-0">
                    <NxGridList
                        enableSearch={true}
                        autoSearch={true}
                        enableAutoPagination={true}
                        showShowing={true}
                        header={[
                            {
                                name: 'Service Code',
                                sort: true,
                                search: true
                            },
                            {
                                name: 'Service Name',
                                sort: true,
                                search: true
                            },
                            {
                                name: 'Publisher',
                                search: true
                                // sort: true,
                            },
                            {
                                name: 'Version No',
                                sort: false,
                            },
                            {
                                name: 'Status',
                                sort: false,
                            },
                        ]}
                        rowClick={(data) => {
                            history.push({
                                pathname: '/enrollment/edit',
                                state: { editVal: data }
                            });
                        }}
                        data={enrollment && enrollment} />
                </div>

                <NxRightDrawer onClose={() => setHandel(false)} show={handel} drawerContent={() => { }} ondrawer1Close={() => { setHandel2(false) }} />
            </div>
            <NxFooter ExtraContent={[
                { name: 'Terms of Service' },
                { name: 'privacy' },
            ]} />
        </div>
    )
}