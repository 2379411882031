import React, { useEffect, useState } from 'react';
import './BillngConfiguration.css';
import { useHistory } from 'react-router';
import Headers from '../../../../Screens/Headers/Headers';
import { NxTitleTab, NxFooter } from '@afiplfeed/itr-app';
import { DatePicker, Progress, Tabs } from 'antd';
import { DashboardOutlined, FormOutlined, DollarOutlined } from '@ant-design/icons';
import SmsGateway from '../../notification/smsGateway/smsGateway';
import EmailSetup from '../../notification/emailSetup/emailSetup';
import Taxation from '../../Taxation/Taxation';
import BillingRule from '../../BillingRule/BillingRule';
import CurrencyConv from '../../CurrencyConv/CurrencyConv';

const { RangePicker } = DatePicker;


export default function BillngConfiguration() {

    const { TabPane } = Tabs;

    let history = useHistory();

    const [keyPeriods, setKeyPeriods] = useState();

    const [activeString, setActiveString] = useState('1')
    useEffect(() => {
        let s = sessionStorage.getItem('bc');
        if (s) {
            setActiveString(s)
        }
    }, []);

    return (
        <>

            <Headers />
            <NxTitleTab className="title-tab" title="Billing Configuration" onCloseClick={() => {
                sessionStorage.removeItem('bc');
                history.goBack()
            }} />

            <div className="billing-tabs billing-main">
                <Tabs type="card" activeKey={activeString} onChange={(e) => {
                    setActiveString(e);
                    sessionStorage.setItem('bc', e)
                }}>

                    <TabPane tab={<div className="d-flex justify-content-center">
                        <span ><FormOutlined /></span>
                        <span style={{ fontSize: '16px' }}>Currency Conversion</span>
                    </div>} key="1">
                        <CurrencyConv />
                    </TabPane>

                    <TabPane tab={<div className="d-flex justify-content-center">
                        <span ><FormOutlined /></span>
                        <span style={{ fontSize: '16px' }}>Taxation</span>
                    </div>} key="2">
                        <Taxation />
                    </TabPane>

                    <TabPane tab={
                        <div className="d-flex justify-content-center">
                            <span ><DashboardOutlined /></span>
                            <span style={{ fontSize: '16px' }} >Billing Template</span>
                        </div>
                    } key="3">
                        <BillingRule />
                    </TabPane>

                </Tabs>
            </div>


            <NxFooter companyName="© 2022 Ithred Solutions" ExtraContent={[
                { name: 'Terms of Service' },
                { name: 'Privacy' },
            ]} />
        </>
    )
}
