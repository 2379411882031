import React from 'react';
import { useHistory } from 'react-router-dom';
import { NxCard,NxFooter } from '@afiplfeed/itr-app';
// icons
import enquiry from '../../../assets/Enquiry.svg';
import customer from '../../../assets/Customer.svg';


export default function AppStoreTab() {

    let history = useHistory();

    return (
        <div className="conatiner">
            <NxCard className="card-tab" datas={[
                {
                    data: [
                        {
                            image: enquiry, heding: 'Enrollment', info: 'Monitor your apps and infrastructure', onClick: () => {
                                sessionStorage.setItem('ike', '2');
                                history.push('/enrollment')
                            }
                        },
                        {
                            image: customer, heding: 'Carousel', info: 'Monitor your apps and infrastructure', onClick: () => {
                                sessionStorage.setItem('ike', '2');
                                history.push('/carousel')
                            }
                        },
                        {
                            image: customer, heding: 'Subscription', info: 'Monitor your apps and infrastructure', onClick: () => {
                                sessionStorage.setItem('ike', '2');
                                history.push('/subscription')
                            }
                        },
                    ]
                },
            ]} />

            
        </div>
    )
}
