import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { ChartColors } from '../../general/general';


export default function NxBarBillingCharts({
  chartData = []
}) {

  const [labels, setLables] = useState([]);
  const [enqueryData, setEnquiryData] = useState([]);
  const [cusData, setCusData] = useState([]);
  const [incData, setIncData] = useState([]);
  useEffect(() => {
    if (chartData && chartData.length > 0) {
      let lArr = [];
      let eArr = [];
      let cArr = [];
      let iArr = [];
      for (let vv of chartData) {
        lArr = [...lArr, vv.periodName];
        let eq = 0;
        let cq = 0;
        let iq = 0;
        for (let dd of vv.periodCount) {
          if (dd.categoryName == "Billed") {
            eq = dd.categoryCount
          }
          if (dd.categoryName == "Received") {
            cq = dd.categoryCount
          }
          if (dd.categoryName == 'Outstanding') {
            iq = dd.categoryCount
          }
        }
        eArr = [...eArr, eq];
        cArr = [...cArr, cq];
        iArr = [...iArr, iq];
      }
      setLables(lArr);
      setEnquiryData(eArr);
      setCusData(cArr);
      setIncData(iArr);
    }
  }, [chartData])

  // const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July','Aug','Sep','Oct','Nov','Dec'];

  return (
    <div>
      <Bar
        data={{
          labels,
          datasets: [
            {
              label: "Billed",
              data: enqueryData,
              backgroundColor: [
                ChartColors.Splash,
                ChartColors.Splash,
                ChartColors.Splash
              ],
            },
            {
              label: 'Received',
              data: cusData,
              backgroundColor: [
                ChartColors.Sky,
                ChartColors.Sky,
                ChartColors.Sky
              ],
            },
            {
              label: 'Outstanding',
              data: incData,
              backgroundColor: [
                ChartColors.Moonshine,
                ChartColors.Moonshine,
                ChartColors.Moonshine
              ],
            },

          ],
        }}
        height={250}
        width={200}
        options={{
          maintainAspectRatio: false,
          scales: {
            yAxes: [
              {
                ticks: {
                  // min:500,
                  // max:5000
                  beginAtZero: false,
                },
              },
            ],
          },
          responsve: true,
        }}
      />
    </div>
  )
}
