import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Tabs, Switch, Rate, Modal, Button, PageHeader, Tag, Alert, Space, Tooltip } from 'antd';
import { ItrApiService } from '@afiplfeed/itr-ui'
import { NxLoader, NxTitleTab, NxFooter } from '@afiplfeed/itr-app';
import Headers from '../../Screens/Headers/Headers';
import { DashboardOutlined, DollarOutlined, ForkOutlined } from "@ant-design/icons";
import { NotificationManager } from "react-notifications";
import CustomerSidebar from '../../Screens/CustomerSidebar/CustomerSidebar';
import LicenseManagement from '../LicenseManagement/licenseManagement';
import AppStores from '../AppStore/appStore';

export default function AppServiceHome({ data = [{ image: '', heding: '', info: '' }] }) {

    const { TabPane } = Tabs;

    let history = useHistory();

    // subscription 
    const [filtersubscrption, setFiltersubscrption] = useState([
        { id: 1, name: "All Apps", isChecked: true },
        { id: 2, name: "Subscribed Apps", isChecked: false },
        { id: 3, name: "Available Apps", isChecked: false },
        { id: 4, name: "Unsubscribed Apps", isChecked: false },
    ])

    const handleChange = (e, index, id) => {
        if (e == true) {
            if (id == 1) {
                setFiltersubscrption([
                    { id: 1, name: "All Apps", isChecked: true },
                    { id: 2, name: "Available Apps", isChecked: false },
                    { id: 3, name: "Subscribed Apps", isChecked: false },
                    { id: 4, name: "Unsubscribed Apps", isChecked: false },
                ])
            } else if (id == 2) {
                setFiltersubscrption([
                    { id: 1, name: "All Apps", isChecked: false },
                    { id: 2, name: "Available Apps", isChecked: true },
                    { id: 3, name: "Aubscribed Apps", isChecked: false },
                    { id: 4, name: "Unsubscribed Apps", isChecked: false },
                ])
            }
            else if (id == 3) {
                setFiltersubscrption([
                    { id: 1, name: "All Apps", isChecked: false },
                    { id: 2, name: "Available Apps", isChecked: false },
                    { id: 3, name: "Aubscribed Apps", isChecked: true },
                    { id: 4, name: "Unsubscribed Apps", isChecked: false },
                ])
            } else {
                setFiltersubscrption([
                    { id: 1, name: "All Apps", isChecked: false },
                    { id: 2, name: "Available Apps", isChecked: false },
                    { id: 3, name: "Aubscribed Apps", isChecked: false },
                    { id: 4, name: "Unsubscribed Apps", isChecked: true },
                ])
            }
        }
    }

    const [subscriptionData, setSubscriptionData] = useState([]);
    const [subscriptionId, setSubscriptionId] = useState('');
    const [clientId, setClientid] = useState('');
    const [enrollmentId, setEnrollmentId] = useState('');
    const [loader, setLoader] = useState(false);

    // list-Subscription-Api
    const getData = async () => {
        let result = await ItrApiService.GET({
            url: 'DevOps/Enrollment/EnrollmentWithSubscription'
        });
        if (result.result.isSuccess == true) {
            let data = result.result.data;
            if (data) {
                for (let id of data) {
                    setSubscriptionId(id.clientSubscriptionID);
                    setClientid(id.clientID);
                    setEnrollmentId(id.id);
                }
            }
            if (data === null || !data.length) {
                NotificationManager.error(result.result.message);
            }
            else {
                setSubscriptionData(data);
                // await splits(data,setDatas);
                // await setpassCustomers(getCustomerDetails);
            }
            // setCustomers(getCustomerDetails);
        }
        else if (result.result.isSuccess == false) {
            NotificationManager.error(result.result.message);
            // sessionStorage.clear();
            // history.push('/')
        }
    }

    const [noOfUnit, setNoOfUnit] = useState();

    const [handel, setHandel] = useState(false);
    const [handel2, setHandel2] = useState(false);

    var saveSubscribe = {
        clientSubscriptionID: subscriptionId,
        enrollmentID: enrollmentId,
        clientID: clientId,
        noofUnit: parseInt(noOfUnit)
    }

    var unSubscribe = {
        clientSubscriptionID: subscriptionId,
        clientID: clientId
    }
    const [appModaldetails, setAppModalDetails] = useState(false);
    const [showLicenseDetails, setShowLicenseDetails] = useState(false);
    const [moveSubscribeTab, setMoveSubscibeTab] = useState('1');
    const [confirmSubscribe, setConfirmSubscribe] = useState(false);
    const [appPolicydetails, setAppPolicyDetails] = useState(false);
    const [reloads, setReloads] = useState(true);


    const showLicenseTable = () => {
        if (showLicenseDetails == true) {
            setShowLicenseDetails(false);
        }
        else {
            setShowLicenseDetails(true);
        }
    }

    const [appsData, setAppsData] = useState({
        available: '',
        subscribed: '',
        purchased: '',
        unassigned: '',
    })

    const getAppStoreData = () => {
        ItrApiService.GET({
            url: 'RevOps/StoreConnect/GetAppHubDashBoard'
        }).then((res => {
            if (res.Success == true) {
                if (res.result.isSuccess == true) {
                    let obj = { ...appsData }
                    for (let data of res.result.data) {
                        if (data.categoryName == 'Available Apps') {
                            obj = { ...obj, available: data.categoryCount }
                        }
                        else if (data.categoryName == 'Subscribed Apps') {
                            obj = { ...obj, subscribed: data.categoryCount }
                        }
                        else if (data.categoryName == 'Purchased Licenses') {
                            obj = { ...obj, purchased: data.categoryCount }
                        }
                        else if (data.categoryName == 'Unassigned Licenses') {
                            obj = { ...obj, unassigned: data.categoryCount }
                        }
                    }
                    setAppsData(obj);
                }
            }
        }))
    }


    const [activeString, setActiveString] = useState('1')
    useEffect(() => {
        let s = sessionStorage.getItem('ahss');
        if (s) {
            setActiveString(s)
        }
        getAppStoreData();

    }, []);

    return (
        <>
            <NxLoader state={loader} />
            <Headers />
            <div className='d-flex custom-body-container'>
                <div className='body-sidebar'>
                    <CustomerSidebar visible={true}  />
                </div>
                <div className='body-workspace'>
                    <NxTitleTab className="title-tab" title="Services" onCloseClick={() => history.push('/dashboard')} />
                    <div className="billing-tabs billing-main">
                        <Tabs defaultActiveKey="1" type="card" activeKey={activeString} onChange={(e) => {
                            setActiveString(e);
                            if (e == '1') {
                                setReloads(!reloads)
                            }
                            sessionStorage.setItem('ahss', e)
                        }}>
                            <TabPane tab={
                                <div className="d-flex justify-content-center">
                                    {/* <span ><DashboardOutlined /></span> */}
                                    <span style={{ fontSize: '16px' }} >Storefront</span>
                                </div>
                            } key="1">
                                <AppStores />
                            </TabPane>
                            <TabPane tab={
                                <div className="d-flex justify-content-center">
                                    {/* <span ><DashboardOutlined /></span> */}
                                    <span style={{ fontSize: '16px' }} >Marketplace</span>
                                </div>
                            } key="2">
                                {/* <EnrollmentEntry /> */}
                            </TabPane>
                            {/* <TabPane tab={<div className="d-flex justify-content-center">
                                <span ><ForkOutlined /></span>
                                <span style={{ fontSize: '16px' }}>License</span>
                            </div>} key="3">
                                <LicenseManagement />
                            </TabPane> */}
                        </Tabs>
                    </div>
                </div>
            </div>            
            <NxFooter companyName="© 2023 Altascio Technologies (I) Pvt. Ltd.," ExtraContent={[
                { name: 'Terms of Service' },
                { name: 'Privacy' },
            ]} />
        </>
    )
}
