import React, { useState, useEffect } from "react";
import './AddCustomers.css';
import { useHistory } from 'react-router-dom';
import { ItrApiService } from '@afiplfeed/itr-ui';
import { NxHeader, NxBreadcrumb, NxTitleTab, NxCustomTab, NxLoader, NxFooter } from '@afiplfeed/itr-app';
import Headers from "../../Screens/Headers/Headers";
import Footers from "../../Screens/Footers/Footers";
import { NotificationManager } from "react-notifications";

export default function AddCustomers() {

    const Buttons = () => {
        return <div className="form-button nx-enquiry-buttons mb-5">
            <button className="btn btn-primary rounded-pill buttons"> Save</button>
            <button className="btn btn-primary rounded-pill buttons"> Onboard</button>
            <button className="btn close btn-outline-primary rounded-pill" onClick={() => history.goBack()}> close</button>
        </div>
    }

    const [loading, setLoading] = useState(false);

    // cities
    const [allCities, setAllCities] = useState([]);
    const getCities = async () => {
        let result = await ItrApiService.GET({
            url: 'Platform/City/GetAllCities'
        });
        if (result.result.isSuccess == true) {
            let getCustomerDetails = result.result.data;
            if (result.result.data) {
                setAllCities(getCustomerDetails);
            }
        }
        else if (result.result.isSuccess == false) {
            NotificationManager.error(result.result.message);
            // sessionStorage.clear();
            // history.push('/')
        }
    }


    // states
    const [allStates, setAllStates] = useState([]);
    const getStates = async () => {
        let result = await ItrApiService.GET({
            url: 'Platform/State/GetAllStates'
        });
        if (result.result.isSuccess == true) {
            let getCustomerDetails = result.result.data;
            if (result.result.data) {
                setAllStates(getCustomerDetails);
            }
        }
        else if (result.result.isSuccess == false) {
            NotificationManager.error(result.result.message);
            // sessionStorage.clear();
            // history.push('/')
        }
    }


    // country
    const [allCountries, setAllCountries] = useState([]);
    const getCountry = async () => {
        let result = await ItrApiService.GET({
            url: 'Platform/Country/GetAllCountries'
        });
        if (result.result.isSuccess == true) {
            let getCustomerDetails = result.result.data;
            if (result.result.data) {
                setAllCountries(getCustomerDetails);
            }
        }
        else if (result.result.isSuccess == false) {
            NotificationManager.error(result.result.message);
            // sessionStorage.clear();
            // history.push('/')
        }
    }


    // pincode
    const [allPinCode, setAllPinCode] = useState([]);
    const getZipCodes = async () => {
        let result = await ItrApiService.GET({
            url: 'Platform/ZipCode/GetAllZipCodes'
        });
        if (result.result.isSuccess == true) {
            let getCustomerDetails = result.result.data;
            if (result.result.data) {
                setAllPinCode(getCustomerDetails);
            }
        }
        else if (result.result.isSuccess == false) {
            NotificationManager.error(result.result.message);
            // sessionStorage.clear();
            // history.push('/')
        }
    }

    const [customers, setCustomers] = useState([]);
    const getparent = async () => {
        await ItrApiService.GET({ url: 'RevOps/Entity/GetEntities/true' }).then((resCustomers) => {
            if (resCustomers.Success == true) {
                if (resCustomers.result.isSuccess == true) {
                    if (resCustomers.result.data) {
                        setCustomers(resCustomers.result.data);
                    }
                }
            }
        });

    };


    // AllUDo`s
    let UdoData = ["Enquiry Status", "Nature Of Business", "Industry", "Ownership", "Source"];
    const [allUdo, setAllUdo] = useState([]);
    const getUdo = async () => {
        let result = await ItrApiService.POST({
            url: 'Platform/UserDefined/AllUDOs',
            data: UdoData
        });
        if (result.result.isSuccess == true) {
            let getCustomerDetails = result.result.data;
            let getOptions = getCustomerDetails[1].Options;
            setAllUdo(getOptions);
        }
        else if (result.result.isSuccess == false) {
            NotificationManager.error(result.result.message);
            // sessionStorage.clear();
            // history.push('/')
        }
    }

    const initiate = async () => {
        setLoading(true);
        await getUdo();
        await getparent();
        await getCities();
        await getCountry();
        await getZipCodes();
        await getStates();
        setLoading(false);
    }


    useEffect( () => {
        initiate()
    }, []);


    let history = useHistory();
    const [handel, setHandel] = useState(false);
    return (
        <div>
            <NxLoader state={loading} />
            <Headers />
            <NxBreadcrumb className="navigation-breadcrumb" data={[
                {
                    name: 'Home',
                    onClick: () => {
                        history.push('/partner-board');
                    }
                },
                {
                    name: 'Customer List',
                    onClick: () => {
                        history.push('/customer');
                    }
                }
            ]} />
            <NxTitleTab className="title-tab" title='Add Customer' onCloseClick={() => {
                history.push('/customer');
            }} />
            <NxCustomTab
                data={[
                    {
                        name: "Organization",
                        Components: () => <div className="container-form">
                            <div className="row">
                                <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                                    <label htmlFor="Account-Name">Entity Code</label>
                                    <input className="form-control mt-2" id="Account-Name" type="text" disabled />
                                </div>

                                <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                                    <label htmlFor="firstName">Ownership</label>
                                    <select class="form-select" aria-label="Default select example" placeholder="">
                                        <option value="" selected hidden></option>
                                        <option value="" >--Please-select--</option>
                                    </select>
                                </div>

                                <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                                    <label htmlFor="Address-Line-1">Address Line 1</label>
                                    <input className="form-control mt-2" id="Address-Line-1" type="text" />
                                </div>

                                <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                                    <label htmlFor="Account-Name">Entity Name</label>
                                    <input className="form-control mt-2" id="Account-Name" type="text" />
                                </div>

                                <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-2">
                                    <label htmlFor="firstName">Industry</label>
                                    <select class="form-select" aria-label="Default select example" placeholder="">
                                        <option value="" selected hidden></option>
                                        <option value="" >--Please-select--</option>
                                    </select>
                                </div>

                                <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-2">
                                    <label htmlFor="Address-Line-2">Address Line 2</label>
                                    <input className="form-control mt-2" id="Address-Line-2" type="text" />
                                </div>

                                <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-2">
                                    <label htmlFor="firstName">Parent Account</label>
                                    <select class="form-select" aria-label="Default select example" placeholder="">
                                        <option value="" >--Please-select--</option>
                                        {customers && customers.map((data, index) => {
                                            return <option key={index} value={data.entityCode}> {data.entityName} </option>
                                        })}
                                    </select>
                                </div>

                                <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-2">
                                    <label htmlFor="firstName">Nature Of Business</label>
                                    <select class="form-select" aria-label="Default select example" placeholder="">
                                        <option value="" selected hidden></option>
                                        <option disabled>--Please-select--</option>
                                        <option value="Business 01" >Business 01</option>
                                        <option value="Business 02" >Business 02</option>
                                    </select>
                                </div>

                                <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-2">
                                    <div className="row">
                                        <div className="col-6 col-sm-6 col-lg-6 col-xl-6 col-md-6">
                                            <label htmlFor="firstName">City</label>
                                            <select class="form-select" aria-label="Default select example" placeholder="">
                                                <option value="" selected hidden></option>
                                                <option value="" >--Please-select--</option>
                                                {allCities && allCities.map((data, index) => {
                                                    return <option key={index} value={data.cityName}> {data.cityName} </option>
                                                })}
                                            </select>
                                        </div>

                                        <div className="col-6 col-sm-6 col-lg-6 col-xl-6 col-md-6">
                                            <label htmlFor="firstName">State</label>
                                            <select class="form-select" aria-label="Default select example" placeholder="">
                                                <option value="" selected hidden></option>
                                                <option value="" >--Please-select--</option>
                                                {allStates && allStates.map((data, index) => {
                                                    return <option key={index} value={data.stateName}> {data.stateName} </option>
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-2">
                                    <label htmlFor="firstName">Source</label>
                                    <select class="form-select" aria-label="Default select example" placeholder="">
                                        <option value="" selected hidden></option>
                                        <option disabled>--Please-select--</option>
                                        <option value="Advertisement" >Advertisement</option>
                                        <option value="Cold Call" >Cold Call</option>
                                        <option value="Employee Referal" >Employee Referal</option>
                                        <option value="External Referal" >External Referal</option>
                                        <option value="Online" >Online</option>
                                        <option value="Partner" >Partner</option>
                                        <option value="Public Relations" >Public Relations</option>
                                        <option value="Sales Mail" >Sales Mail</option>
                                        <option value="Seminar Partner" >Seminar Partner</option>
                                        <option value="Seminar Internal" >Seminar Internal</option>
                                        <option value="Trade Show" >Trade Show</option>
                                        <option value="Database" >Database</option>
                                    </select>
                                </div>

                                <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-2">
                                    <label htmlFor="firstName">Annual Revenue</label>
                                    <input className="form-control mt-2" id="firstName" type="text" />
                                </div>

                                <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-2">
                                    <div className="row">
                                        <div className="col-6 col-sm-6 col-lg-6 col-xl-6 col-md-6">
                                            <label htmlFor="firstName">Country</label>
                                            <select class="form-select" aria-label="Default select example">
                                                <option value="" selected hidden></option>
                                                <option value="" >--Please-select--</option>
                                                {allCountries && allCountries.map((data, index) => {
                                                    return <option key={index} value={data.countryName}> {data.countryName} </option>
                                                })}
                                            </select>
                                        </div>

                                        <div className="col-6 col-sm-6 col-lg-6 col-xl-6 col-md-6">
                                            <label htmlFor="firstName">Pin Code</label>
                                            <select class="form-select" aria-label="Default select example">
                                                <option value="" selected hidden></option>
                                                <option value="" >--Please-select--</option>
                                                {allPinCode && allPinCode.map((data, index) => {
                                                    return <option key={index} value={data.zipcode}> {data.zipcode} </option>
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-2">
                                    <label htmlFor="firstName">No Of Employees</label>
                                    <input className="form-control mt-2" id="firstName" type="text" />
                                </div>

                                <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-2">

                                </div>

                                <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-2">

                                </div>
                            </div>



                            <div className="row mt-2 d-flex align-items-start">
                                <div className="col-12 col-sm-3 col-md-3 col-xl-4 col-lg-4">
                                    <label htmlFor="firstName">Description</label>
                                    <textarea className="form-control mt-2" id="firstName" />
                                </div>

                                <div className="col-12 col-sm-3 col-md-3 col-xl-4 col-lg-4">
                                    <label htmlFor="firstName">Website</label>
                                    <input type="text" className="form-control mt-2" id="firstName" />
                                </div>

                                <div className="col-12 col-sm-3 col-md-3 col-xl-4 col-lg-4">

                                </div>

                                <div className="col-12 col-sm-3 col-md-3 col-xl-4 col-lg-4">

                                </div>
                            </div>
                            <hr />

                            <Buttons />
                        </div>
                    },
                    {
                        name: "Contacts",
                        Components: () => <div className="container-form">
                            <div>
                                <strong>Administrative Contact</strong>
                                <div className="row contact-form">
                                    <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                                        <label htmlFor="firstName">First Name</label>
                                        <input className="form-control mt-2" id="firstName" type="text" />
                                    </div>

                                    <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                                        <label htmlFor="firstName">Designation</label>
                                        <input className="form-control mt-2" id="firstName" type="text" />
                                    </div>

                                    <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-2">
                                        <div className="row">
                                            <div className="col-6 col-sm-6 col-lg-6 col-xl-6 col-md-6">
                                                <label htmlFor="firstName">Phone</label>
                                                <input className="form-control mt-2" id="firstName" type="text" />
                                            </div>

                                            <div className="col-6 col-sm-6 col-lg-6 col-xl-6 col-md-6">
                                                <label htmlFor="firstName">Mobile</label>
                                                <input className="form-control mt-2" id="firstName" type="text" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-2">
                                        <label htmlFor="firstName">Last Name</label>
                                        <select class="form-select" aria-label="Default select example" placeholder="">
                                            <option value="" selected hidden></option>
                                            <option value="" >--Please-select--</option>
                                        </select>
                                    </div>

                                    <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-2">
                                        <label htmlFor="firstName">Department</label>
                                        <input className="form-control mt-2" id="firstName" type="text" />
                                    </div>

                                    <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-2">
                                        <label htmlFor="firstName">Email</label>
                                        <input className="form-control mt-2" id="firstName" type="text" />
                                    </div>
                                </div>
                            </div>

                            <div>
                                <strong>Technical Contact</strong>
                                <div className="row contact-form">
                                    <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                                        <label htmlFor="firstName">First Name</label>
                                        <input className="form-control mt-2" id="firstName" type="text" />
                                    </div>

                                    <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                                        <label htmlFor="firstName">Designation</label>
                                        <input className="form-control mt-2" id="firstName" type="text" />
                                    </div>

                                    <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-2">
                                        <div className="row">
                                            <div className="col-6 col-sm-6 col-lg-6 col-xl-6 col-md-6">
                                                <label htmlFor="firstName">Phone</label>
                                                <input className="form-control mt-2" id="firstName" type="text" />
                                            </div>

                                            <div className="col-6 col-sm-6 col-lg-6 col-xl-6 col-md-6">
                                                <label htmlFor="firstName">Mobile</label>
                                                <input className="form-control mt-2" id="firstName" type="text" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-2">
                                        <label htmlFor="firstName">Last Name</label>
                                        <select class="form-select" aria-label="Default select example" placeholder="">
                                            <option value="" selected hidden></option>
                                            <option value="" >--Please-select--</option>
                                        </select>
                                    </div>

                                    <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-2">
                                        <label htmlFor="firstName">Department</label>
                                        <input className="form-control mt-2" id="firstName" type="text" />
                                    </div>

                                    <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-2">
                                        <label htmlFor="firstName">Email</label>
                                        <input className="form-control mt-2" id="firstName" type="text" />
                                    </div>
                                </div>
                            </div>

                            <hr />
                            {/* buttons */}
                            <Buttons />
                        </div>
                    }
                ]}
            />
            <NxFooter ExtraContent={[
                { name: 'Terms of Service' },
                { name: 'privacy' },
            ]} />
        </div>
    )
}