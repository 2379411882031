import { NxFooter, NxLoader, NxTitleTab } from '@afiplfeed/itr-app';
import { ItrApiService } from '@afiplfeed/itr-ui';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { useHistory } from 'react-router-dom'
import Headers from '../../../Screens/Headers/Headers';

function AddCurrencyConv() {
    const history = useHistory();
    const [loader, setLoader] = useState(false);

    const [saveCurrency, setSaveCurrency] = useState({
        currencyExchangeRateID: 0,
        currencyID: 0,
        exchangeRate: 0,
        currencyValue: "",
        currency: {
            currencyID: 0,
            currencyCode: "",
            currencyName: "",
            currencyNamePlural: "",
            currencySymbol: "",
            currencySymbolNative: "",
            currencyDecimalDigits: 0,
            currencyRounding: 0
        },
    });

    // currency
    const [allCurrency, setAllCurrency] = useState([]);
    const getCurrency = async () => {
        let result = await ItrApiService.GET({
            url: 'RevOps/Currency/GetCurrencies'
        });
        if (result.Success == true) {
            if (result.result.isSuccess == true) {
                let getCustomerDetails = result.result.data;
                if (result.result.data) {
                    setAllCurrency(getCustomerDetails);
                }
                else {
                }
            }
            else if (result.result.isSuccess == false) {
                NotificationManager.error(result.result.message);
            }
        } else {
            NotificationManager.error(result.message);
        }
    }

    const [datas, setDatas] = useState({});


    useEffect(() => {
        getCurrency();
         ItrApiService.GET({ url: 'RevOps/BillerInformation' }).then(res => {
            setLoader(false);
            if (res.Success == true) {
                if (res.result.isSuccess == true) {
                    if (res.result.data) {
                        setDatas(res.result.data);
                    }
                }
            }
        });
    }, [])
    return (
        <div>
            <Headers />
            <NxLoader state={loader} />
            <NxTitleTab className="title-tab" title="Add Currency Conversion" onCloseClick={() => history.goBack()} />
            <div className="card password-policy-main mt-0">
                <div className="card-body p-0 mb-5" >
                    <div className="row p-3 mx-1">
                        <div className='col-12 col-sm-12 col-md-8 col-lg-8 col-xl-6'>
                            <div className="row">
                                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2'>
                                    <div className='row'>
                                        <div className='col-12 col-sm-12 col-md-5 col-lg-5 col-xl-4'>
                                            <label className='f-badge-title'>Choose Your Currency</label>
                                        </div>

                                        <div className='col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 flex-start' >
                                            <select className='form-select form-select-sm'
                                                value={saveCurrency.currencyValue}
                                                onChange={(e) => setSaveCurrency({ ...saveCurrency, currencyValue: e.target.value, currencyID: JSON.parse(e.target.value).currencyID })}
                                            >
                                                <option value="" selected disabled> -- Please Select -- </option>
                                                {allCurrency && allCurrency.map((data, index) => {
                                                    return <option key={index} value={JSON.stringify(data)}> {data.currencyName} </option>
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2'>
                                    <div className='row align-items-center'>
                                        <div className='col-12 col-sm-12 col-md-5 col-lg-5 col-xl-4'>
                                            <label className='f-badge-title'>Exchange Rate For Reporting</label>
                                        </div>

                                        <div className='col-12 col-sm-12 col-md-7 col-lg-7 col-xl-8 flex-start' >
                                            <div className='row'>
                                                <div className='col-4 col-sm-4 col-md-4 col-lg-3 col-xl-3' >
                                                    <label style={{fontSize:'16px'}}>1 {datas.currencyName && datas.currencyName.split("-")[0]} = </label>
                                                </div>
                                                <div className='col-3 col-sm-4 col-md-4 col-lg-6 col-xl-6 ' >
                                                    <input className="form-control form-control-sm" type="text"
                                                        value={saveCurrency.exchangeRate}
                                                        onChange={(e) => setSaveCurrency({ ...saveCurrency, exchangeRate: e.target.value })} />
                                                </div>
                                                <div className='col-4 col-sm-4 col-md-4 col-lg-3 col-xl-3 flex-start' >
                                                    <label style={{fontSize:'16px'}}>{saveCurrency.currencyValue && saveCurrency.currencyValue != '' ? JSON.parse(saveCurrency.currencyValue).currencyCode : ''}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className='text-end'>
                            <button className='btn btn-primary btn-sm mt-4 mb-1' onClick={() => {
                                // let dataVal = { ...newBillingRules, selectDay: newBillingRules.selectDay == '' ? 0 : parseInt(newBillingRules.selectDay), isRecurringJob: newBillingRules.isRecurringJob == '' ? false : newBillingRules.isRecurringJob == 'true' ? true : false };
                                setLoader(true);
                                let data = { ...saveCurrency, currencyID: parseInt(saveCurrency.currencyID), exchangeRate: parseFloat(saveCurrency.exchangeRate) };
                                delete data.currencyValue;
                                delete data.currency
                                ItrApiService.POST({
                                    url: 'RevOps/CurrencyExchangeRate/SaveCurrencyExchangeRate',
                                    data: data
                                }).then(res => {
                                    if (res.Success == true) {
                                        if (res.result.isSuccess == true) {
                                            setLoader(false);
                                            history.goBack();
                                            NotificationManager.success('Successfully Created');
                                        } else {
                                            setLoader(false);
                                            NotificationManager.error(res.result.message);
                                        }
                                    } else {
                                        setLoader(false);
                                        NotificationManager.error(res.message);
                                    }
                                })
                            }}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
            <NxFooter
                className="verscio-footer" companyName="© 2022 Ithred Solutions" ExtraContent={[
                    { name: 'Terms of Service' },
                    { name: 'Privacy' },
                ]} />
        </div>
    );
}

export default AddCurrencyConv;