import { faArrowDown, faCreditCard, faEye, faHistory, faRedo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NxFooter, NxGridList, NxLoader, NxTitleTab } from '@afiplfeed/itr-app';
import { ItrApiService } from '@afiplfeed/itr-ui';
import moment from 'moment';
import './Invoice.css';
import React, { useEffect, useState } from 'react';
import InvoiceData from '../InvoiceData/InvoiceData';
import { Button, Modal, DatePicker } from 'antd';
import { NotificationManager } from 'react-notifications';
import CustomerSidebar from '../../../Screens/CustomerSidebar/CustomerSidebar';
import Headers from '../../../Screens/Headers/Headers';
import { useHistory } from 'react-router-dom';



const splits = async (dests, setData, setIsModalVisible, setSelectedVal, showModal, getReconcilationData) => {
    let arr = [];
    for (let value of dests) {
        //value.status = 'Open';
        // value.status = 'open';
        let data = [moment(value.invoicedDate).format('DD/MM/YYYY'), value.entityName, value.invoiceNumber, moment(value.billDateFrom).format('DD/MM/YYYY') + " TO " + moment(value.billDateTo).format('DD/MM/YYYY'), `${value.totalBillAmountWithGST} /-`, moment(value.dueDate).format('DD/MM/YYYY'),
        value.totalBillPaid == null || "" ? "-" : value.totalBillPaid +  '/-', value.lastBillPaid == null || "" ? "-" : moment(value.lastBillPaid).format('DD/MM/YYYY'), <div>
            <button className='btn btn-sm' style={{ fontSize: '11px', borderRadius: '0px', color: value.billStatus != 'Partially Paid' ? 'white' : 'black', backgroundColor: value.billStatus == 'Open' ? 'blue' : value.billStatus == 'Pending' ? '#ff944d' : value.billStatus == 'Paid' ? 'green' : value.billStatus == 'Partially Paid' ? '#ffff66' : value.billStatus == 'Write-Off' ? 'red' : 'black' }}>
                {value.billStatus.toUpperCase()}
            </button>
        </div>,
        <div style={{ display: 'flex', alignItems: '' }}>
            <span title="View" onClick={() => {
                setSelectedVal(value)
                setIsModalVisible(true)
            }}>
                <FontAwesomeIcon icon={faEye} color="blue" />
            </span>

            <span title="Reconciliation" className='px-3' onClick={() => {
                showModal(value);
                getReconcilationData(value)
            }}>
                <FontAwesomeIcon icon={faHistory} color="green" />
            </span>
        </div>
        ];
        let obj = { sendItem: value, data: data };
        arr = [...arr, obj]
    }
    setData(arr);
}

function Invoice() {

    const [openReconc, setOpenReconc] = useState(false);
    const showModal = () => {
        setOpenReconc(true);
    };

    let history = useHistory();
    const handleCancel = () => {
        setOpenReconc(false);
    };

    const [selectedVal, setSelectedVal] = useState({});

    const [serviceId, setServiceId] = useState();
    const [totalPaid, setTotalPaid] = useState(0);


    const [saveReconc, setSaveReconc] = useState({
        serviceBillingID: serviceId,
        entityCode: "",
        paymentMode: "",
        amountPaid: 0,
        paidOn: "",
        paymentReference: ""
    });

    const [reconcilationData, setReconcilationData] = useState([]);

    const getReconcilationData = (value) => {
        setLoader(true);
        ItrApiService.GET({
            url: `RevOps/ServiceBilling/GetReconciliationByServiceBillingID/${value.serviceBillingID}`,
        }).then(res => {
            if (res.Success == true) {
                if (res.result.isSuccess == true) {
                    setLoader(false);
                    setServiceId(value.serviceBillingID);
                    setTotalPaid(value.totalBillPaid);
                    setReconcilationData(res.result.data);
                } else {
                    setLoader(false);
                    NotificationManager.error(res.result.message);
                }
            } else {
                setLoader(false);
                NotificationManager.error(res.message);
            }
        })
    };

    const handleOk = () => {
        setLoader(true);
        ItrApiService.POST({
            url: 'RevOps/ServiceBilling/ServiceBillingReconciliation',
            data: { ...saveReconc, paidOn: moment(saveReconc.paidOn).format(''), serviceBillingID: serviceId }
        }).then(res => {
            if (res.Success == true) {
                if (res.result.isSuccess == true) {
                    setLoader(false);
                    setOpenReconc(false);
                    NotificationManager.success("Successfully Created");
                    setSaveReconc({
                        serviceBillingID: 0,
                        entityCode: "",
                        paymentMode: "",
                        amountPaid: 0,
                        paidOn: "",
                        paymentReference: ""
                    });
                    getBillingData();
                } else {
                    setLoader(false);
                    NotificationManager.error(res.result.message);
                }
            } else {
                setLoader(false);
                NotificationManager.error(res.message);
            }
        })
    };

    const [loader, setLoader] = useState(false);
    const [dataVal, setDataVal] = useState([]);
    const [data, setData] = useState([]);
    const [datas, setDatas] = useState({
        "billerInfoID": 0,
        "businessName": "",
        "supportEmail": "",
        "supportPhone": "",
        "businessWebsite": "",
        "businessLogo": "",
        "addressLine1": "",
        "addressLine2": "",
        "city": "",
        "state": "",
        "pinCode": "",
        "country": "",
        "cin": "",
        "pan": "",
        "gst": "",
        "tan": ""
    })
    const [isModalVisible, setIsModalVisible] = useState(false);
    const getBillingData = () => {
        setLoader(true);
        ItrApiService.GET({ url: 'RevOps/ServiceBilling/ListAllBilling' }).then(res => {
            setLoader(false);
            ItrApiService.GET({ url: 'RevOps/BillerInformation' }).then(res1 => {
                if (res1.Success == true) {
                    if (res1.result.isSuccess == true) {
                        if (res1.result.data) {
                            setDatas(res1.result.data);
                        }
                    }
                }
            })
            if (res.Success == true) {
                if (res.result.isSuccess == true) {
                    if (res.result.data) {
                        setDataVal(res.result.data)
                        splits(res.result.data, setData, setIsModalVisible, setSelectedVal, showModal, getReconcilationData);
                    }
                }
            }
        });
    };
    let { RangePicker } = DatePicker

    useEffect(() => {
        getBillingData();
    }, [])
    return (
        <>
            <Headers />
            <div className='d-flex custom-body-container'>
                <div className='body-sidebar'>
                    <CustomerSidebar visible={true}  />
                </div>
                <div className='body-workspace'>
                    <div className="nx-billing-invoice mb-5">
                        <NxLoader state={loader} />
                        <NxTitleTab className="title-tab" title='Invoice' onCloseClick={() =>  history.push('/marketplace')} />

                        <div className="container-fluid invoice-table">
                            <NxGridList
                                className='mt-3'
                                enableSearch={true}
                                autoSearch={true}
                                enableAutoPagination={true}
                                showShowing={true}
                                header={[
                                    {
                                        name: 'DATE',
                                        sort: true,
                                    },
                                    {
                                        name: 'COMPANY NAME',
                                        sort: true,
                                    },
                                    {
                                        name: 'INV.NUMBER',
                                        sort: true,
                                    },
                                    {
                                        name: 'BILLING PERIOD',
                                        sort: false,
                                    },
                                    {
                                        name: 'AMOUNT',
                                        // sort: true,
                                    },
                                    {
                                        name: 'DUE BY',
                                        search: true,
                                    },
                                    {
                                        name: 'PAID AMOUNT',
                                        search: true,
                                    },
                                    {
                                        name: 'PAID ON',
                                        sort: true,
                                    },
                                    {
                                        name: 'STATUS',
                                        // sort: true,
                                    },
                                    {
                                        name: 'ACTION',
                                        // sort: true,
                                    },

                                ]}
                                rowClick={(e) => {
                                }}
                                data={data}
                            />
                            <InvoiceData companyData={datas} invoiceData={selectedVal} isModalVisible={isModalVisible}
                                handleOk={() => {
                                    setIsModalVisible(false);
                                }}
                                handleCancel={() => {
                                    setIsModalVisible(false);
                                }}
                            />
                        </div>

                        <div className='container-fluid recon-title-main' id='recon-title-main'>
                            <Modal
                                title={
                                    <div>
                                        <p className='recon-title my-0' style={{ fontSize: '20px', fontWeight: 'bold' }}>Reconcilation</p>
                                    </div>
                                }
                                centered
                                footer={[
                                    <Button key="submit" type="primary" onClick={handleOk} loading={loader}>
                                        Save
                                    </Button>,
                                    <Button key="back" onClick={handleCancel} loading={loader}>
                                        Close
                                    </Button>]}
                                visible={openReconc}
                                onOk={handleOk}
                                onCancel={handleCancel}
                            // width='75%'
                            >
                                <div style={{ display: 'flex' }} >
                                    <div>
                                        <div className='mt-2 invoice row'>
                                            <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                                <div className='row'>
                                                    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2 align-items-start '>
                                                        <div className='row'>
                                                            <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-5 '>
                                                                <span className='my-0 invoice-details fw-bold'> Payment Mode </span>
                                                            </div>

                                                            <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-7 '>
                                                                <select className='form-select form-select-sm'
                                                                    value={saveReconc.paymentMode}
                                                                    onChange={(e) => setSaveReconc({ ...saveReconc, paymentMode: e.target.value })}
                                                                >
                                                                    <option value="" selected disabled> --Please Select-- </option>
                                                                    <option value="Cash">Cash</option>
                                                                    <option value="Bank Transfer"> Bank Transfer </option>
                                                                    <option value="Credit Card"> Credit Card </option>
                                                                    <option value="Adjustments"> Adjustments </option>
                                                                    <option value="Others"> Others </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2 align-items-start'>
                                                        <div className='row'>
                                                            <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-5'>
                                                                <span className='my-0 invoice-details fw-bold'> Amount </span>
                                                            </div>

                                                            <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-7 '>
                                                                <input className='form-control form-control-sm'
                                                                    type="number"
                                                                    value={saveReconc.amountPaid}
                                                                    onChange={(e) => setSaveReconc({ ...saveReconc, amountPaid: e.target.value })}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2 align-items-start'>
                                                        <div className='row'>
                                                            <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-5'>
                                                                <span className='my-0 invoice-details fw-bold'> Payment Date </span>
                                                            </div>

                                                            <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-7 '>
                                                                <input className='form-control form-control-sm'
                                                                    type="date"
                                                                    value={saveReconc.paidOn}
                                                                    onChange={(e) => setSaveReconc({ ...saveReconc, paidOn: e.target.value })}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2 align-items-start'>
                                                        <div className='row'>
                                                            <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-5'>
                                                                <span className='my-0 invoice-details fw-bold'> Reference Note </span>
                                                            </div>

                                                            <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-7 '>
                                                                <textarea className='form-control form-control-sm'
                                                                    value={saveReconc.paymentReference}
                                                                    onChange={(e) => setSaveReconc({ ...saveReconc, paymentReference: e.target.value })}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2'>
                                                        <div className='row'>
                                                            <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-5'>
                                                                <span className='my-0 invoice-details fw-bold'>  </span>
                                                            </div>

                                                            <div className='col-6 col-sm-6 col-md-6 col-lg-6 col-xl-7 text-end'>
                                                                <button className='btn btn-sm btn-success'>+ Add</button>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>

                                            <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-5 mt-3'>
                                                <table class="table table-bordered table-hover table-responsive" >
                                                    <thead>
                                                        <tr className='thds'>
                                                            <th scope="col">Date</th>
                                                            <th scope="col">Mode</th>
                                                            <th scope="col">Note</th>
                                                            <th scope="col">Amount</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {reconcilationData.map((item, index) => {
                                                            return <tr className='table-data' key={index}>
                                                                <td> {moment(item.paidOn).format('DD/MM/YYYY')} </td>
                                                                <td> {item.paymentMode} </td>
                                                                <td> {item.paymentReference} </td>
                                                                <td> {item.amountPaid} </td>
                                                            </tr>
                                                        })}
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td colspan='3'>Total Payment</td>
                                                            <td>{totalPaid}</td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal >
                        </div>

                    </div >
                </div>
            </div>
            <NxFooter companyName="© 2023 Altascio Technologies (I) Pvt. Ltd.," ExtraContent={[
                { name: 'Terms of Service' },
                { name: 'Privacy' },
            ]} />
        </>
       
    );
}

export default Invoice;

