import React, { useState, useEffect } from 'react';
import NxBarCharts from '../../../components/nxCharts/nxBarCharts';
import './billing.css';
import { useHistory } from 'react-router';
import { faCreditCard, faEye, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Headers from '../../../Screens/Headers/Headers';
import { NxTitleTab, NxFooter } from '@afiplfeed/itr-app';
import { ChartColors } from '../../../general/general';
import { Bar } from 'react-chartjs-2';
import { DatePicker, Progress, Tabs } from 'antd';
import { DashboardOutlined, FormOutlined, DollarOutlined } from '@ant-design/icons';
import Invoice from '../Invoice/Invoice';
import AccountReceivable from '../AccountReceivable/AccountReceivable';
import { ItrApiService } from '@afiplfeed/itr-ui';
import moment from 'moment';
import NxBarBillingCharts from '../../../components/nxCharts/nxBarBillingCharts';
import CustomerSidebar from '../../../Screens/CustomerSidebar/CustomerSidebar';

const { RangePicker } = DatePicker;


export default function Billing() {

    const { TabPane } = Tabs;

    let history = useHistory();

    const [keyPeriods, setKeyPeriods] = useState();

    const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const data = {
        labels,
        datasets: [
            {
                label: 'Dataset 1',
                data: { min: 500, max: 5000 },
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            {
                label: 'Dataset 2',
                data: { min: 500, max: 5000 },
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top'
            },
        },
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: false,
                    },
                },
            ],
        },
    };
    const [chartData, setChartData] = useState([]);
    var monthFirstDay = moment().startOf('M').format()

    const [acquistionChart, setAcquistionChart] = useState({
        fromDate: monthFirstDay,
        toDate: moment().format()
    });

    const [acquistion, setAcquistion] = useState({
        totalBilled: '',
        totalReceived: '',
        totalOutstanding: '',
    });

    const [mainData, setMainData] = useState({
        totalBilled: '',
        received: '',
        outStanding: '',
        pastDue: '',
        grossProfit: '',
        opex: '',
        operationProfit: '',
        netProfit: ''
    });

    useEffect(() => {
        ItrApiService.GET({
            url: 'RevOps/ServiceBilling/GetServiceBillingDashBoard',
        }).then(res => {
            if (res.Success == true) {
                if (res.result.isSuccess == true) {
                    if (res.result.data.currentPeriodCount) {
                        setChartData(res.result.data.currentPeriodCount)
                    }
                    let obj = { ...mainData }
                    let obj1 = { ...acquistion }
                    for (let data of res.result.data.financiaIndicatorCount) {
                        if (data.categoryName == 'TOTAL BILLED') {
                            obj = { ...obj, totalBilled: data.categoryCount }
                        }
                        else if (data.categoryName == 'RECEIVED') {
                            obj = { ...obj, received: data.categoryCount }
                        }
                        else if (data.categoryName == 'CURRENT OUTSTANDING') {
                            obj = { ...obj, outStanding: data.categoryCount }
                        }
                        else if (data.categoryName == 'PAST DUE') {
                            obj = { ...obj, pastDue: data.categoryCount }
                        }
                        else if (data.categoryName == 'GROSS PROFIT MARGIN') {
                            obj = { ...obj, grossProfit: data.categoryCount }
                        }
                        else if (data.categoryName == 'OPEX RATIO') {
                            obj = { ...obj, opex: data.categoryCount }
                        }
                        else if (data.categoryName == 'OPERATING PROFIT MARGIN') {
                            obj = { ...obj, operationProfit: data.categoryCount }
                        }
                        else if (data.categoryName == 'NET PROFIT MARGIN') {
                            obj = { ...obj, netProfit: data.categoryCount }
                        }
                    }
                    for (let data of res.result.data.financialPerformanceCount) {
                        if (data.categoryName == 'Total Billed') {
                            obj1 = { ...obj1, totalBilled: data.categoryCount }
                        }
                        else if (data.categoryName == 'Total Received') {
                            obj1 = { ...obj1, totalReceived: data.categoryCount }
                        }
                        else if (data.categoryName == 'Total Outstanding') {
                            obj1 = { ...obj1, totalOutstanding: data.categoryCount }
                        }
                    }
                    setMainData(obj);
                    setAcquistion(obj1);
                }
            }
        })
    }, [])



    return (
        <>
            <Headers />            
            <div className='d-flex custom-body-container'>
                <div className='body-sidebar'>
                    <CustomerSidebar visible={true}  />
                </div>
                <div className='body-workspace'>
                    <NxTitleTab className="title-tab" title="Billing" onCloseClick={() => history.push('/dashboard')} />

                    <div className="billing-tabs billing-main">
                        <Tabs defaultActiveKey="1" type="card">
                            <TabPane tab={
                                <div className="d-flex justify-content-center">
                                    <span ><DashboardOutlined /></span>
                                    <span style={{ fontSize: '16px' }} >Dashboard</span>
                                </div>
                            } key="1">
                                <div className="mt-0">
                                    <div className="container1">
                                        <div className="nx-body nx-billing mt-1">
                                            <div className="row">
                                                <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mt-1 counts count-two">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <label className="nx-badge-title">TOTAL BILLED</label>
                                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 mt-1">
                                                                <h6> {mainData.totalBilled} </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mt-1 counts count-two">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <label className="nx-badge-title">RECEIVED</label>
                                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 mt-1">
                                                                <h6>{mainData.received}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mt-1 counts count-two">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <label className="nx-badge-title">CURRENT OUTSTANDING</label>
                                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 mt-1">
                                                                <h6>{mainData.outStanding}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mt-1 counts count-two">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <label className="nx-badge-title">PAST DUE</label>
                                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 mt-1">
                                                                <h6> {mainData.pastDue} </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* <div className="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-5 mt-1 counts count-two">
                                    <div className='card'>
                                        <div className="card-body">
                                        <label className="nx-badge-title">OVERDUE</label>
                                            <div className="row">
                                                <div className="col-6 col-sm-6 col-md-6 col-lg-6 mt-2">
                                                    <h6>$ 38452</h6>
                                                    <label>1 - 15 Days</label>
                                                </div>

                                                <div className="col-6 col-sm-6 col-md-6 col-lg-6 mt-2">
                                                    <h6>$ 740.00</h6>
                                                    <label>16 - 30 Days</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-5 mt-1 counts count-two">
                                    <div className="card">
                                        <div className="card-body">
                                        <label className="nx-badge-title">DAYS PAST DUE</label>
                                    <div className="row">
                                        <div className="col-6 col-sm-6 col-md-6 col-lg-6 mt-2">
                                            <h6>$ 740.00</h6>
                                            <label>31 - 45 Days</label>
                                        </div>

                                        <div className="col-6 col-sm-6 col-md-6 col-lg-6 mt-1">
                                            <h6>$ 740.00</h6>
                                            <label>Above 45 Days</label>
                                        </div>
                                    </div>
                                        </div>
                                    </div>
                                </div> */}
                                            </div>

                                            {/* <div className="mt-3">
                                                <div className="nx-title title text-left">
                                                    <h2>TOTAL RECEIVABLE $ 1,00,000</h2>
                                                </div>

                                                <div className="nx-progress mt-4">
                                                    <div class="progress" style={{ height: '30px' }}>
                                                        <div class="progress-bar" role="progressbar" style={{ width: "30%", background: '#f0c518', color: 'black' }} aria-valuenow="30" aria-valuemin="0" aria-valuemax="100">30 DPD ($30,000)</div>
                                                        <div class="progress-bar" role="progressbar" style={{ width: "40%", background: '#ed741c', color: 'black' }} aria-valuenow="40" aria-valuemin="0" aria-valuemax="100">60 DPD ($40,000)</div>
                                                        <div class="progress-bar" role="progressbar" style={{ width: "30%", background: '#f04356', color: 'black' }} aria-valuenow="30" aria-valuemin="0" aria-valuemax="100">90 DPD ($30,000)</div>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>

                                        {/* <div className="nx-bar-chart nx-billing-chart mt-5 mb-5">
                                            <div className="row">
                                                <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 ">
                                                    <h4>Key Financial Indicators</h4>
                                                </div>
                                            </div>

                                            <div className="card ">
                                                <div className="card-body">
                                                    <div className="row text-center">
                                                        <div className="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                                                            <Progress type="circle" percent={mainData.grossProfit} strokeWidth={15} gapDegree={30} gapPosition="bottom" /> <br />
                                                            <label className="key-financial-label mt-3">GROSS PROFIT MARGIN</label>
                                                        </div>

                                                        <div className="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                                                            <Progress type="circle" percent={mainData.opex} strokeWidth={15} gapDegree={30} gapPosition="bottom" /> <br />
                                                            <label className="key-financial-label mt-3">OPEX RATIO</label>
                                                        </div>

                                                        <div className="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                                                            <Progress type="circle" percent={mainData.operationProfit} strokeWidth={15} gapDegree={30} gapPosition="bottom" /><br />
                                                            <label className="key-financial-label mt-3">OPERATING PROFIT MARGIN</label>
                                                        </div>

                                                        <div className="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                                                            <Progress type="circle" percent={mainData.netProfit} strokeWidth={15} gapDegree={30} gapPosition="bottom" /><br />
                                                            <label className="key-financial-label mt-3">NET PROFIT MARGIN</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}

                                        <div className="nx-bar-chart nx-billing-chart mt-5 mb-5">
                                            <div className="row">
                                                <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 ">
                                                    <h4>Financial Performance</h4>
                                                </div>

                                                <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-end">
                                                    <label>Last</label>
                                                    <select value={keyPeriods} onChange={(e) => {
                                                        let fromDate = false;
                                                        let toDate = false;
                                                        if (e.target.value == '1') {
                                                            fromDate = moment().subtract(12, 'months').format();
                                                            toDate = moment().format();
                                                        }
                                                        if (e.target.value == '2') {
                                                            fromDate = moment().subtract(6, 'months').format();
                                                            toDate = moment().format();
                                                        }
                                                        if (e.target.value == '3') {
                                                            fromDate = moment().subtract(3, 'months').format();
                                                            toDate = moment().format();
                                                        }
                                                        if (e.target.value == '4') {
                                                            fromDate = moment().startOf('year').format();
                                                            toDate = moment().format();
                                                        }
                                                        if (e.target.value == '5') {
                                                            fromDate = moment().startOf('months').format();
                                                            toDate = moment().format();
                                                        }
                                                        if (e.target.value == '6') {
                                                            fromDate = moment().startOf('W').format();
                                                            toDate = moment().format();
                                                        }
                                                        if (fromDate != false && toDate != false) {
                                                        
                                                            ItrApiService.POST({
                                                                url: 'RevOps/ServiceBilling/GetServiceBillingDashBoardSpecificPeriod',
                                                                data: { fromDate, toDate }
                                                            }).then(res => {
                                                                if (res.Success == true) {
                                                                    if (res.result.isSuccess == true) {
                                                                        setChartData(res.result.data.currentPeriodCount);
                                                                        let obj1 = { ...acquistion }
                                                                        for (let data of res.result.data.financialPerformanceCount) {
                                                                            if (data.categoryName == 'Total Billed') {
                                                                                obj1 = { ...obj1, totalBilled: data.categoryCount }
                                                                            }
                                                                            else if (data.categoryName == 'Total Received') {
                                                                                obj1 = { ...obj1, totalReceived: data.categoryCount }
                                                                            }
                                                                            else if (data.categoryName == 'Total Outstanding') {
                                                                                obj1 = { ...obj1, totalOutstanding: data.categoryCount }
                                                                            }
                                                                        }
                                                                        setAcquistion(obj1);
                                                                    }
                                                                }
                                                            })
                                                            setAcquistionChart({
                                                                fromDate: fromDate,
                                                                toDate: toDate
                                                            })
                                                        }
                                                        setKeyPeriods(e.target.value);
                                                    }}>
                                                        <option value="1">12 Months</option>
                                                        <option value="2">6 Months</option>
                                                        <option value="3">3 Months</option>
                                                        <option value="4">YTD</option>
                                                        <option value="5" selected>MTD</option>
                                                        <option value="6">Week</option>
                                                        <option value="7">Period</option>
                                                    </select><br />
                                                    <div className="p-1">
                                                        {keyPeriods == '7' ? <RangePicker onChange={(e, e1) => {
                                                            if (e1.length == 2) {
                                                                let fromDate = false;
                                                                let toDate = false;
                                                                let s = 1;
                                                                for (let val of e1) {
                                                                    if (s == 1) {
                                                                        fromDate = moment(val, 'YYYY-MM-DD').format();
                                                                    } else {
                                                                        toDate = moment(val, 'YYYY-MM-DD').format();
                                                                    }
                                                                    s = s + 1;
                                                                }
                                                                if (fromDate != false && toDate != false) {
                                                                    ItrApiService.POST({
                                                                        url: 'RevOps/ServiceBilling/GetServiceBillingDashBoardSpecificPeriod',
                                                                        data: { fromDate, toDate }
                                                                    }).then(res => {
                                                                        if (res.Success == true) {
                                                                            if (res.result.isSuccess == true) {
                                                                                setChartData(res.result.data.currentPeriodCount);
                                                                                let obj1 = { ...acquistion }
                                                                                for (let data of res.result.data.financialPerformanceCount) {
                                                                                    if (data.categoryName == 'Total Billed') {
                                                                                        obj1 = { ...obj1, totalBilled: data.categoryCount }
                                                                                    }
                                                                                    else if (data.categoryName == 'Total Received') {
                                                                                        obj1 = { ...obj1, totalReceived: data.categoryCount }
                                                                                    }
                                                                                    else if (data.categoryName == 'Total Outstanding') {
                                                                                        obj1 = { ...obj1, totalOutstanding: data.categoryCount }
                                                                                    }
                                                                                }
                                                                                setAcquistion(obj1);
                                                                            }
                                                                        }
                                                                    })
                                                                    setAcquistionChart({ ...acquistion, fromDate: fromDate, toDate: toDate });
                                                                }
                                                            }
                                                        }} /> : false}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card ">
                                                <div className="card-body">
                                                    <div className="row mb-5">

                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                            <NxBarBillingCharts chartData={chartData} height="500" width="100%" />
                                                            {/* <Bar width={200} height={40} options={options} data={data} /> */}
                                                        </div>

                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-end nx-sales mt-5" >
                                                            <div className="row">
                                                                <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                                                    <div className="nx-sales-report pt-3 d-flex justify-content-evenly align-items-center" >
                                                                        <h6 className="nx-sales-report-1" style={{ backgroundColor: ChartColors.Splash, padding: '8px', color: 'white', fontWeight: 'bold' }}>Total Billed</h6>
                                                                        <h5 className="key-financial-counts"> {acquistion.totalBilled} </h5>
                                                                    </div>
                                                                </div>

                                                                <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                                                    <div className="nx-sales-report pt-3 d-flex justify-content-evenly align-items-center" >
                                                                        <h6 className="nx-sales-report-1" style={{ backgroundColor: ChartColors.Sky, padding: '8px', color: 'white', fontWeight: 'bold' }}>Total Received</h6>
                                                                        <h5 className="key-financial-counts">{acquistion.totalReceived}</h5>
                                                                    </div>
                                                                </div>

                                                                <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 ">
                                                                    <div className="nx-sales-report pt-3 d-flex justify-content-evenly align-items-center" >
                                                                        <h6 className="nx-sales-report-1" style={{ backgroundColor: ChartColors.Moonshine, padding: '8px', color: 'white', fontWeight: 'bold' }}>Total Outstanding</h6>
                                                                        <h5 className="key-financial-counts">{acquistion.totalOutstanding}</h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <div className="nx-billing-invoice mb-5">
                            <div className="billing">
                                <div>
                                    <h4>Invoices</h4>
                                </div>
                                <div className="invoice-title">
                                    <button className="btn btn-success btn-sm" type="button">Create Invoice</button>
                                </div>
                            </div>

                            <div className="mt-4">
                                <label>show </label>
                                <select className="mx-1">
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                            </div>

                            <div className="invoice-table">
                                <div class="table-wrapper-scroll-y table-responsive my-custom-scrollbar ">
                                    <table class="table table-hover mb-5">
                                        <thead>
                                            <tr>
                                            <th scope="col">Inv#</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Client</th>
                                            <th scope="col">Invoice Title</th>
                                            <th scope="col">Due Date</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Invoice Amount</th>
                                            <th scope="col">Balance Due</th>
                                            <th scope="col">Action</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <td>a quo magni similique perferendis</td>
                                                <td>30</td>
                                                <td>30</td>
                                                <td>30</td>
                                                <td>30</td>
                                                <td> <span className="dot-status"> </span>30</td>
                                                <td>30</td>
                                                <td>30</td>
                                                <td>
                                                    <div style={{display:'flex',justifyContent:'space-around',alignItems:'center'}}>
                                                        <a> <FontAwesomeIcon icon={faEye} color="palevioletred" size="sm" /></a>
                                                        <a> <FontAwesomeIcon icon={faCreditCard} color="black" size="sm" className="billing-card" /></a>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>ad iusto omnis odit dolor voluptatibus	</td>
                                                <td>30</td>
                                                <td>30</td>
                                                <td>30</td>
                                                <td>30</td>
                                                <td> <span className="dot-status"> </span>30</td>
                                                <td>30</td>
                                                <td>30</td>
                                                <td >
                                                    <div style={{display:'flex',justifyContent:'space-around',alignItems:'center'}}>
                                                        <a > <FontAwesomeIcon icon={faEye} color="palevioletred" size="sm" /></a>
                                                        <a> <FontAwesomeIcon icon={faCreditCard} color="black" size="sm"  className="billing-card"/> </a>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div> */}
                                    </div>
                                </div>
                            </TabPane>

                            <TabPane tab={<div className="d-flex justify-content-center">
                                <span ><FormOutlined /></span>
                                <span style={{ fontSize: '16px' }}>Invoice</span>
                            </div>} key="2">
                                <Invoice />
                            </TabPane>

                            {/* <TabPane tab={<div className="d-flex justify-content-center">
                                <span ><DollarOutlined /></span>
                                <span style={{ fontSize: '16px' }}>Account Receivable</span>
                            </div>} key="3">
                                <AccountReceivable />
                            </TabPane>

                            <TabPane tab={<div className="d-flex justify-content-center">
                                <span ><DollarOutlined /></span>
                                <span style={{ fontSize: '16px' }}>Dunning</span>
                            </div>} key="4">
                            </TabPane> */}
                        </Tabs>
                    </div>
                </div>
            </div>
            <NxFooter companyName="© 2023 Altascio Technologies (I) Pvt. Ltd.," ExtraContent={[
                { name: 'Terms of Service' },
                { name: 'Privacy' },
            ]} />
        </>
    )
}
